import {
  getCoursesForProgram,
  getCurricularUnitById,
  getSkillCounts,
  searchBenchmarks
} from 'services/curricularSkills';
import { QueryClient } from 'react-query';
import { keyBy } from 'lodash';
import { fetchRelatedOccupations } from 'services/similarity';
import { getProgramSkillInfo } from 'utils/curricularSkills';
import { jpaFacetLookupById, getRankingByFacet, fetchJPATotals } from 'services/jpa';
import { fetchSkillsById } from 'services/skills';
import { getLightcastOccupationJPAFilter } from './utils';
import {
  EnrichedBenchmarks,
  EnrichedCustomBenchmarks,
  EnrichedLightcastOccupation,
  InstitutionalizedJPAResponseBucketWithSkill,
  JPABenchmarkWithSalary,
  JPAResponseBucketWithSkill,
  MarketAlignmentFilters
} from './types';
import { LOT_SKILL_FETCH_COUNT } from './constants';

export async function enrichLightcastOccupations(
  queryClient: QueryClient,
  lotAssociations: LightcastOccupationAssociation[],
  nation: JPANation
) {
  return await Promise.all(
    lotAssociations.map(async occupation => {
      const occupationNames = await queryClient.fetchQuery([occupation.type, occupation.id], () =>
        jpaFacetLookupById(nation, occupation.type, [occupation.id]).then(({ data }) =>
          data.map(entry => ({ name: entry.name }))
        )
      );
      const occupationName = occupationNames[0]?.name;

      const jpaOptionsSkills = getLightcastOccupationJPAFilter(occupation, 'skills_name');
      const skills = await queryClient.fetchQuery(
        ['jpaRankings', nation, 'skills_name', jpaOptionsSkills, nation],
        async () =>
          getRankingByFacet(nation, 'skills_name', jpaOptionsSkills).then(
            response => response.buckets
          )
      );

      const jpaOptionsTitles = getLightcastOccupationJPAFilter(occupation, 'title_name');
      const titles = await queryClient.fetchQuery(
        ['jpaRankings', nation, 'title_name', jpaOptionsTitles, nation],
        async () =>
          getRankingByFacet(nation, 'title_name', jpaOptionsTitles).then(
            response => response.buckets
          )
      );

      const jpaOptionsCompanies = getLightcastOccupationJPAFilter(occupation, 'company_name');
      const companies = await queryClient.fetchQuery(
        ['jpaRankings', nation, 'company_name', jpaOptionsCompanies, nation],
        async () =>
          getRankingByFacet(nation, 'company_name', jpaOptionsCompanies).then(
            response => response.buckets
          )
      );

      const { median_salary: salary } = await queryClient.fetchQuery(
        ['jpaTotals', nation, getLightcastOccupationJPAFilter(occupation).filter],
        () =>
          fetchJPATotals(nation, getLightcastOccupationJPAFilter(occupation).filter, [
            'median_salary'
          ])
      );

      return {
        occupation,
        occupationName,
        skills,
        titles,
        companies,
        salary
      };
    })
  );
}

export async function enrichProgramBenchmarks(
  queryClient: QueryClient,
  site: string,
  nation: JPANation,
  skillIds: {
    learningOutcomesSkills: string[];
    requiredSkills: string[];
    skillsInProgram: string[];
  },
  marketAlignmentFilters: MarketAlignmentFilters,
  { lightcastOccupations, custom }: SavedBenchmarks
): Promise<EnrichedBenchmarks> {
  let enrichedCustomBenchmarks: Awaited<ReturnType<typeof enrichCustomBenchmarks>> = {
    textBenchmarks: [],
    jpaBenchmarks: [],
    skillsForTaughtAndSought: []
  };
  let enrichedLightcastOccupations: EnrichedLightcastOccupation[] = [];

  const hasRequiredSkills = skillIds.requiredSkills.length > 0;
  const selectedCustomBenchmarkIds = custom
    .filter(benchmark => benchmark.selected)
    .map(bm => bm.id);

  if (selectedCustomBenchmarkIds.length) {
    enrichedCustomBenchmarks = await enrichCustomBenchmarks(
      queryClient,
      selectedCustomBenchmarkIds,
      site,
      nation,
      marketAlignmentFilters
    );
  }

  if (!lightcastOccupations.length && selectedCustomBenchmarkIds.length) {
    enrichedLightcastOccupations = [];
  } else if (!lightcastOccupations.length) {
    const defaultOccupations = await getDefaultRelatedLightcastOccupation(
      queryClient,
      hasRequiredSkills ? skillIds.requiredSkills : skillIds.skillsInProgram
    );

    const convertedDefaultOccs = defaultOccupations.data.map(occ => ({
      id: occ.id,
      type: 'occupation' as LOTLevel,
      selected: false
    }));

    enrichedLightcastOccupations = await enrichLightcastOccupations(
      queryClient,
      convertedDefaultOccs,
      nation
    );
  } else {
    enrichedLightcastOccupations = await enrichLightcastOccupations(
      queryClient,
      lightcastOccupations,
      nation
    );
  }

  const taughtVsSoughtSkills = await fetchTaughtVsSoughtSkills(
    queryClient,
    nation,
    site,
    enrichedLightcastOccupations,
    enrichedCustomBenchmarks.skillsForTaughtAndSought,
    marketAlignmentFilters,
    skillIds.skillsInProgram,
    skillIds.learningOutcomesSkills
  );

  return {
    customBenchmarks: {
      textBenchmarks: enrichedCustomBenchmarks.textBenchmarks,
      jpaBenchmarks: enrichedCustomBenchmarks.jpaBenchmarks
    },
    lightcastOccupations: enrichedLightcastOccupations,
    taughtAndSoughtSkills: taughtVsSoughtSkills
  };
}

async function enrichCustomBenchmarks(
  queryClient: QueryClient,
  selectedCustomBenchmarkIds: string[],
  site: string,
  nation: JPANation,
  marketAlignmentFilters: MarketAlignmentFilters
) {
  const customProgramBenchmarks = await queryClient.fetchQuery(
    ['custom-benchmarks', selectedCustomBenchmarkIds],
    () =>
      searchBenchmarks({
        filter: {
          site: { in: [site] },
          ids: { in: selectedCustomBenchmarkIds }
        },
        limit: 100
      })
  );

  const [textBenchmarks, jpaBenchmarks] = segregateCustomBenchmarks(customProgramBenchmarks.data);
  const textBenchmarkFilterQueries = textBenchmarks.map(bm => ({
    filter: getJPAFilterBody(
      {
        filter: marketAlignmentFilters.jpaFilters,
        rank: { include: bm.facets.skills, limit: bm.facets.skills.length }
      },
      marketAlignmentFilters.regionFilters
    ),
    benchmark: bm
  }));

  const textBenchmarkTaughtVsSought = await Promise.all(
    textBenchmarkFilterQueries.map(query => fetchTextBenchmarkSkills(queryClient, nation, query))
  ).then(datum => datum.flat());

  const jpaBenchmarkFilterQueries = jpaBenchmarks.map(bm => ({
    benchmark: bm,
    filter: getJPAFilterBody({
      filter: { ...bm.facets },
      rank: { limit: 20 }
    })
  }));

  const jpaBenchmarksWithSalary = await Promise.all(
    jpaBenchmarkFilterQueries.map(query => fetchJpaBenchmarkInformation(queryClient, nation, query))
  );

  const jpaBenchmarkTaughtVsSought = await Promise.all(
    jpaBenchmarkFilterQueries.map(query =>
      fetchJpaBenchmarkSkills(queryClient, nation, query.filter, marketAlignmentFilters)
    )
  ).then(datum => datum.flat());

  return {
    textBenchmarks,
    jpaBenchmarks: jpaBenchmarksWithSalary,
    skillsForTaughtAndSought: jpaBenchmarkTaughtVsSought.concat(textBenchmarkTaughtVsSought)
  };
}

async function fetchTaughtVsSoughtSkills(
  queryClient: QueryClient,
  nation: JPANation,
  site: string,
  lightcastBenchmarks: EnrichedLightcastOccupation[],
  customBenchmarkTaughtAndSought: JPAResponseBucketWithSkill[],
  marketAlignmentFilters: MarketAlignmentFilters,
  skillsInProgram: string[],
  learningOutcomesSkills: string[]
) {
  const { chartSettings } = marketAlignmentFilters;
  const lotBenchmarkSkills = await fetchTvSLotBenchmarkSkills(
    queryClient,
    nation,
    lightcastBenchmarks,
    marketAlignmentFilters
  );

  const combinedSkills = lotBenchmarkSkills.concat(customBenchmarkTaughtAndSought);

  let levelsFilteredSkills: JPAResponseBucketWithSkill[] = [];
  if (chartSettings.selectedSkillLevels.length) {
    chartSettings.selectedSkillLevels.forEach(selectedSkillLevel => {
      combinedSkills.forEach(skill => {
        if (selectedSkillLevel === 'Software Skill' && skill.isSoftware) {
          levelsFilteredSkills.push(skill);
        }
        if (skill.type?.name === selectedSkillLevel && !levelsFilteredSkills.includes(skill)) {
          levelsFilteredSkills.push(skill);
        }
      });
    });
  } else {
    levelsFilteredSkills = combinedSkills;
  }

  let learningOutcomeFilteredSkills: JPAResponseBucketWithSkill[] = [];
  if (chartSettings.skillTagFilter === 'isLearningObjective') {
    levelsFilteredSkills.forEach(skill => {
      if (learningOutcomesSkills.includes(skill.id)) {
        learningOutcomeFilteredSkills.push(skill);
      }
    });
  } else {
    learningOutcomeFilteredSkills = levelsFilteredSkills;
  }

  const [taughtSkills, soughtSkills] = getTaughtAndSoughtSkills(
    skillsInProgram,
    learningOutcomeFilteredSkills
  );
  const categorizedSoughtSkills = await segregateSoughtSkills(queryClient, site, soughtSkills);

  return {
    taughtSkills,
    soughtSkills: categorizedSoughtSkills
  };
}

async function fetchJpaBenchmarkInformation(
  queryClient: QueryClient,
  nation: JPANation,
  query: { benchmark: JPABenchmark; filter: JPAOptions }
) {
  const medianSalary = await fetchJpaBenchmarkSalary(queryClient, nation, query.benchmark);
  const benchmarkFacets = await fetchJpaBenchmarkFacets(queryClient, nation, query.benchmark);

  return {
    ...query.benchmark,
    facets: benchmarkFacets,
    medianSalary: medianSalary.median_salary
  } as JPABenchmarkWithSalary;
}

async function segregateSoughtSkills(
  queryClient: QueryClient,
  site: string,
  soughtSkills: JPAResponseBucketWithSkill[]
): Promise<InstitutionalizedJPAResponseBucketWithSkill[]> {
  const skillIds = soughtSkills.map(bucket => bucket.id);
  if (!skillIds.length) {
    return [];
  }
  const skillInCourseCounts = await queryClient.fetchQuery([], () =>
    getSkillCounts('course', skillIds, site)
  );
  return soughtSkills.map(bucket => ({
    ...bucket,
    isInstitutionSkill: !!skillInCourseCounts.data.counts?.[bucket.id]
  }));
}

async function fetchTvSLotBenchmarkSkills(
  queryClient: QueryClient,
  nation: JPANation,
  lightcastBenchmarks: EnrichedLightcastOccupation[],
  marketAlignmentFilters: MarketAlignmentFilters
): Promise<JPAResponseBucketWithSkill[]> {
  const lotFilterQueries = lightcastBenchmarks.map(occ => ({
    benchmark: occ,
    filter: getJPAFilterBody({
      filter: { [occ.occupation.type]: [occ.occupation.id] },
      rank: { limit: LOT_SKILL_FETCH_COUNT, by: 'significance' }
    })
  }));

  const topRankedLotSkillIds = await Promise.all(
    lotFilterQueries.map(query =>
      queryClient.fetchQuery([nation, 'skills', query.filter], () =>
        fetchTopRankedJpaSkillIds(nation, query.filter)
      )
    )
  );

  const uniqueSkillIds = [...new Set(topRankedLotSkillIds.flat())];

  if (!uniqueSkillIds.length) {
    return [];
  }

  const jpaFilterBody = getJPAFilterBody(
    {
      rank: { include: uniqueSkillIds, limit: uniqueSkillIds.length },
      filter: marketAlignmentFilters.jpaFilters
    },
    marketAlignmentFilters.regionFilters
  );

  const lightcastOccRankings = await queryClient.fetchQuery(
    ['jpaRanking', nation, 'skills', jpaFilterBody, nation],
    async () =>
      getRankingByFacet(nation, 'skills', jpaFilterBody).then(response => response.buckets)
  );

  const lightcastOccupationSkills = await fetchSkillsById(uniqueSkillIds, [
    'id',
    'name',
    'category',
    'subcategory',
    'type',
    'isSoftware'
  ]);

  const keyedSkills = keyBy(lightcastOccupationSkills, 'id');

  return lightcastOccRankings.map(skill => ({
    ...skill,
    id: skill.name,
    name: keyedSkills?.[skill.name]?.name,
    type: keyedSkills?.[skill.name]?.type,
    isSoftware: keyedSkills?.[skill.name]?.isSoftware,
    category: keyedSkills?.[skill.name]?.category,
    subcategory: keyedSkills?.[skill.name]?.subcategory
  }));
}

async function fetchTopRankedJpaSkillIds(nation: JPANation, filter: JPAOptions) {
  const { buckets, totals } = await getRankingByFacet(nation, 'skills', filter);
  return buckets
    .filter(
      bucket =>
        totals.unique_postings && (bucket.unique_postings / totals.unique_postings) * 100 >= 10
    )
    .map(bucket => bucket.name);
}

function fetchJpaBenchmarkSalary(
  queryClient: QueryClient,
  nation: JPANation,
  benchmark: JPABenchmark
) {
  return queryClient.fetchQuery(['jpaTotals', nation, benchmark.facets, 'median_salary'], () =>
    fetchJPATotals(nation, benchmark.facets, ['median_salary'])
  );
}

async function fetchJpaBenchmarkFacets(
  queryClient: QueryClient,
  nation: JPANation,
  benchmark: JPABenchmark
) {
  const populatedFacets = await Promise.all(
    Object.entries(benchmark.facets).map(async ([facet, values]) =>
      queryClient.fetchQuery([facet, values], () => jpaFacetLookupById(nation, facet, values))
    )
  ).then(responses => {
    return responses.reduce<Omit<JPAResponse, 'score'>[]>(
      (final, { data }) => [...final, ...data],
      []
    );
  });

  return Object.entries(benchmark.facets).reduce<Record<string, string[]>>(
    (newFacets, [facet, ids]): Record<string, string[]> => {
      const facetNames = ids.reduce<string[]>((names, facetId) => {
        const foundFacet = populatedFacets.find(populatedFacet => populatedFacet.id === facetId);
        if (foundFacet) {
          names.push(foundFacet.name);
        }
        return names;
      }, []);

      newFacets[facet] = facetNames;
      return newFacets;
    },
    {}
  );
}

async function fetchJpaBenchmarkSkills(
  queryClient: QueryClient,
  nation: JPANation,
  filter: JPAOptions,
  marketAlignmentFilters: MarketAlignmentFilters
) {
  const topTwentySkills = await queryClient.fetchQuery(
    ['jpaRanking', nation, 'skills', filter, nation],
    () => getRankingByFacet(nation, 'skills', filter).then(response => response.buckets)
  );

  const topTwentySkillIds = topTwentySkills.map(skill => skill.name);
  const topFilter = getJPAFilterBody(
    {
      rank: { include: topTwentySkillIds, limit: 20 }
    },
    marketAlignmentFilters.regionFilters
  );

  const rankings = await queryClient.fetchQuery(
    ['jpaRanking', nation, 'skills', topFilter, nation],
    () => getRankingByFacet(nation, 'skills', topFilter)
  );
  const skillIds = topTwentySkills.flat().map(skill => skill.name);
  const skills = await fetchSkillsById(skillIds, ['id', 'name', 'category', 'subcategory']);

  return skills.reduce<JPAResponseBucketWithSkill[]>((acc, currentSkill) => {
    const foundSkill = rankings.buckets.find(skill => skill.name === currentSkill.id);
    const foundSkillName = skills.find(skill => skill.id === currentSkill.id);
    if (foundSkill && foundSkillName) {
      acc.push({
        ...foundSkill,
        id: foundSkillName.id,
        name: foundSkillName.name,
        category: foundSkillName.category,
        subcategory: foundSkillName.subcategory
      });
    }
    return acc;
  }, []);
}

async function fetchTextBenchmarkSkills(
  queryClient: QueryClient,
  nation: JPANation,
  textBenchmarkFilterQuery: { benchmark: SkillsFromTextBenchmark; filter: JPAOptions }
) {
  const skillRankings = await queryClient.fetchQuery(
    ['skills', textBenchmarkFilterQuery.filter],
    () =>
      getRankingByFacet(nation, 'skills', textBenchmarkFilterQuery.filter).then(
        response => response.buckets
      )
  );
  const skillIds = skillRankings.flat().map(skill => skill.name);
  const skills = await fetchSkillsById(skillIds, ['id', 'name', 'category', 'subcategory']);

  return skills.reduce<JPAResponseBucketWithSkill[]>((acc, currentSkill) => {
    const foundSkill = skillRankings.find(skill => skill.name === currentSkill.id);
    const foundSkillName = skills.find(skill => skill.id === currentSkill.id);
    if (foundSkill && foundSkillName) {
      acc.push({
        ...foundSkill,
        id: foundSkillName.id,
        name: foundSkillName.name,
        category: foundSkillName.category,
        subcategory: foundSkillName.subcategory
      });
    }
    return acc;
  }, []);
}

function segregateCustomBenchmarks(
  customBenchmarks: EnrichedCustomBenchmarks
): [SkillsFromTextBenchmark[], JPABenchmark[]] {
  return customBenchmarks.reduce<[SkillsFromTextBenchmark[], JPABenchmark[]]>(
    ([text, jpa], { attributes }) => {
      if (attributes.type === 'skillsFromTextBenchmark') {
        text.push(attributes);
      } else {
        jpa.push(attributes);
      }
      return [text, jpa];
    },
    [[], []]
  );
}

const getJPAFilterBody = (
  initialBody: JPAOptions,
  regionFilters?: { filterType: { value: string }; regions: JPARegionData[] }
) => {
  if (regionFilters && regionFilters.filterType.value !== 'nation') {
    initialBody.filter = {
      ...initialBody.filter,
      [regionFilters.filterType.value]: regionFilters.regions.map(region => region.id)
    };
  }

  return initialBody;
};

export async function getDefaultRelatedLightcastOccupation(
  queryClient: QueryClient,
  skills: string[]
) {
  const data = await queryClient.fetchQuery(['occupation', skills, 4], () =>
    fetchRelatedOccupations('occupation', skills, 4)
  );
  return { data };
}

export async function getProgramAndCourseData(
  queryClient: QueryClient,
  programId: string,
  site: string
) {
  const program = await queryClient.fetchQuery(['program', programId], () =>
    getCurricularUnitById(programId)
  );
  const courses = await queryClient.fetchQuery(['courses', programId], () =>
    getCoursesForProgram(programId, site)
  );

  const skills = getProgramSkillInfo({
    courses: courses.data,
    program: program.data,
    learningOutcomesOnly: false
  });

  const loSkills = getProgramSkillInfo({
    courses: courses.data,
    program: program.data,
    learningOutcomesOnly: true
  }).skillsInProgram.map(skill => skill.id);

  const programSkillsMetaArray = await queryClient.fetchQuery(
    ['skills', skills.skillsInProgram, ['id', 'name', 'type', 'category', 'subcategory']],
    () =>
      fetchSkillsById(
        [...new Set(skills.skillsInProgram.map(skill => skill.id))],
        ['id', 'name', 'type', 'category', 'subcategory']
      )
  );

  const combinedSkills = programSkillsMetaArray
    .map(skill => ({
      ...skill,
      isRequired: skills.requiredSkillsInProgram.some(
        requiredSkill => requiredSkill.id === skill.id
      ),
      isLearningObjective: loSkills.includes(skill.id),
      frequency: skills.skillFrequency[skill.id]
    }))
    .sort((a, b) => b.frequency - a.frequency);
  const skillIdsToNames = combinedSkills.reduce<Record<string, string>>((acc, cur) => {
    acc[cur.id] = cur.name;
    return acc;
  }, {});
  return {
    program: program.data,
    courses: courses.data,
    skills: {
      ...skills,
      skillIdsToNames,
      skillsWithMetaData: combinedSkills
    }
  };
}

function getTaughtAndSoughtSkills(
  programSkillIds: string[],
  programBenchmarkSkills: JPAResponseBucketWithSkill[]
) {
  const [taughtFinal, soughtFinal] = programBenchmarkSkills.reduce<
    [JPAResponseBucketWithSkill[], JPAResponseBucketWithSkill[], Record<string, boolean>]
  >(
    ([taught, sought, seen], skill) => {
      if (!seen[skill.id]) {
        seen[skill.id] = true;
        if (programSkillIds.includes(skill.id)) {
          taught.push(skill);
        } else {
          sought.push(skill);
        }
      }
      return [taught, sought, seen];
    },
    [[], [], {}]
  );
  return [taughtFinal.sort(sortByPostings), soughtFinal.sort(sortByPostings)];
}

function sortByPostings(a: JPAResponseBucketWithSkill, b: JPAResponseBucketWithSkill) {
  return b.unique_postings - a.unique_postings;
}
