import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import ModalButton from 'components/molecules/ModalButton';
import ProgramTypeClassDropdown from 'components/atoms/ProgramTypeClassDropdown';
import TextAreaSkillSearch from 'components/organisms/TextAndSkillFilters';
import DeleteCourseModal from 'components/molecules/DeleteCourseModal';
import DeleteProgramModal from 'components/molecules/DeleteProgramModal';
import BulkPublishFlyout from 'components/organisms/BulkPublishFlyout';
import ProgramTypeDropdownFlyout from 'components/organisms/ProgramTypeDropdownFlyout';

import { ReactComponent as Tags } from 'images/categoriesTags.svg';
import { ReactComponent as Trash } from 'images/trash.svg';
import { useTableState, useTableDispatch } from 'store/tableStore';
import { hasSiteAccessLevel, useProfileState } from 'store/profileStore';
import { DropdownSelectable } from 'hooks';
import { white, borderGray, darkGray } from 'utils/colors';

const StyledTags = styled(Tags)`
  stroke: ${darkGray};
  margin-right: 1rem;
`;

const filterButtonStyles = css`
  background: ${white};
  border: 1px solid ${borderGray};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3rem;
  padding: 1rem 1.1rem;
  display: flex;
  color: ${darkGray};
  margin-left: 2.4rem;

  transform: scale(1);
  :active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: scale(1);

    & ~ ul {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  padding: 3rem 3rem 0 3rem;
  background: ${white};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem 0.4rem 0 0;
  border-bottom: none;
`;

const StyledTrash = styled(Trash)`
  stroke: ${darkGray};
`;

const StyledProgramTypeClassDropdown = styled(ProgramTypeClassDropdown)`
  ${filterButtonStyles};
  width: 23rem;
`;

const TableFilters: React.FC<{
  dataCount?: number;
  tableType: 'Course' | 'Program' | 'ProgramType';
  isRequesting?: boolean;
  onProgramTypeClassSelect?: (selection: DropdownSelectable) => unknown;
  onProgramTypeSelect?: (selection: DropdownSelectable) => void;
  selectedProgramTypeClass?: DropdownSelectable;
  selectedProgramType?: DropdownSelectable;
  locationStateKey: string;
}> = ({
  dataCount,
  tableType,
  isRequesting,
  selectedProgramTypeClass,
  selectedProgramType,
  onProgramTypeClassSelect,
  onProgramTypeSelect,
  locationStateKey
}) => {
  const tableDispatch = useTableDispatch();
  const { selectedOrDeselected, mode } = useTableState();
  const profileState = useProfileState();
  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  const clearTableSelection = () => tableDispatch({ type: 'DESELECT_ALL' });

  const selectedCount =
    mode === 'deselecting' && dataCount
      ? dataCount - selectedOrDeselected.length
      : selectedOrDeselected.length;

  return (
    <Wrapper>
      <TextAreaSkillSearch
        curricularType={tableType}
        isRequesting={isRequesting}
        locationStateKey={locationStateKey}
      >
        {hasEditorPrivileges && tableType === 'Course' && (
          <>
            <ModalButton
              css={filterButtonStyles}
              aria-label="Delete Multiple Courses"
              disabled={!selectedCount}
              buttonText={<StyledTrash />}
            >
              {closeModal => (
                <DeleteCourseModal
                  closeModal={closeModal}
                  courseIds={selectedOrDeselected}
                  mode={mode}
                  selectedCount={selectedCount}
                  onDelete={clearTableSelection}
                />
              )}
            </ModalButton>
            <BulkPublishFlyout
              publishType="Course"
              curricularIds={selectedOrDeselected}
              mode={mode}
              selectedCount={selectedCount}
              onUpdate={clearTableSelection}
            />
          </>
        )}
        {tableType === 'Program' && (
          <>
            {hasEditorPrivileges && (
              <>
                <ModalButton
                  css={filterButtonStyles}
                  aria-label="Delete Multiple Programs"
                  disabled={!selectedCount}
                  buttonText={<StyledTrash />}
                >
                  {closeModal => (
                    <DeleteProgramModal
                      closeModal={closeModal}
                      programIds={selectedOrDeselected}
                      mode={mode}
                      selectedCount={selectedCount}
                      onDelete={clearTableSelection}
                    />
                  )}
                </ModalButton>
                <BulkPublishFlyout
                  publishType="Program"
                  curricularIds={selectedOrDeselected}
                  mode={mode}
                  selectedCount={selectedCount}
                  onUpdate={clearTableSelection}
                />
              </>
            )}
            <ProgramTypeDropdownFlyout
              allowSelectAll
              css={filterButtonStyles}
              selected={selectedProgramType}
              onChange={onProgramTypeSelect}
            />
          </>
        )}
        {tableType === 'ProgramType' && (
          <StyledProgramTypeClassDropdown
            allowSelectAll
            icon={<StyledTags />}
            selected={selectedProgramTypeClass}
            onSelect={onProgramTypeClassSelect}
          />
        )}
      </TextAreaSkillSearch>
    </Wrapper>
  );
};

export default TableFilters;
