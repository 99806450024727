import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import FlyoutMenu, { MenuItem } from 'components/atoms/FlyoutMenu';

import { ReactComponent as Caret } from 'images/caret.svg';
import { ReactComponent as DownloadIcon } from 'images/cloudDownload.svg';

import { useExport } from 'hooks';
import { darkSaphire, white } from 'utils/colors';
import { CoursePdfExport } from 'helpers/pdfExport';
import { usePdfExport } from 'hooks/pdfExport';
import { useCoursePdfData } from 'hooks/coursePdfData';
import { formatCourseExportData } from 'helpers/exports/exportCourse';
import { CourseInputs } from 'components/organisms/CourseForm';
import { useHookEnabler } from 'hooks/hookEnabler';

export interface CourseExportProps {
  courseId: string;
  inputs: CourseInputs;
}

export const CourseExports: React.FC<CourseExportProps> = ({ courseId, inputs }) => {
  const { start: exportPdf, isLoading: pdfIsExporting } = useHookEnabler({
    useHook({ enabled }) {
      const { isLoading } = usePdfExport({
        params: { courseId },
        filename: `${inputs.title}.pdf`,
        enabled,
        component: CoursePdfExport,
        hook: useCoursePdfData
      });

      return { isLoading, data: null };
    }
  });

  const exportDataFn = useCallback(() => {
    return formatCourseExportData({
      formValues: inputs,
      selectedSkills: inputs.skills
    });
  }, []);

  const [exportCourseExcel, { exportIsLoading: excelExportIsLoading }] = useExport({
    filename: `${inputs.title}`,
    exportDataFn,
    isPreloading: false
  });

  const buttonContents = (
    <>
      <StyledDownloadIcon />
      Export
      <StyledCaret />
    </>
  );

  return (
    <>
      <StyledFlyoutMenu
        ariaLabel="Export"
        renderCustomButton={props => (
          <LoadingButton
            {...props}
            type="button"
            isLoading={pdfIsExporting || excelExportIsLoading}
          >
            {buttonContents}
          </LoadingButton>
        )}
      >
        {({ getItemProps, handleClose }) => (
          <>
            <MenuItem
              {...getItemProps(
                { firstOrLastPosition: 'first' },
                {
                  onClick() {
                    handleClose();
                    exportPdf();
                  }
                }
              )}
            >
              <StyledButton type="button">PDF Export</StyledButton>
            </MenuItem>
            <MenuItem
              {...getItemProps(
                { firstOrLastPosition: 'last' },
                {
                  onClick() {
                    handleClose();
                    exportCourseExcel();
                  }
                }
              )}
            >
              <StyledButton type="button">Excel Export</StyledButton>
            </MenuItem>
          </>
        )}
      </StyledFlyoutMenu>
    </>
  );
};

const StyledButton = styled(Button)`
  color: ${darkSaphire};
`;

const StyledCaret = styled(Caret)`
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-bottom: 0.3rem;
  stroke: ${white};
`;

const StyledFlyoutMenu = styled(FlyoutMenu)`
  border-bottom: 3px solid transparent;
  width: max-content;

  .flyoutMenu {
    width: max-content;
    right: 0;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;

  path {
    stroke: ${white};
  }
`;
