import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useQueryString } from 'hooks';
import AuthorizationForm from 'components/organisms/AuthorizationForm';
import FormInput from 'components/molecules/FormInput';

import { useAuthDispatch, authResetPassword } from 'store/authStore';

interface Inputs {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm: React.FC = () => {
  const authDispatch = useAuthDispatch();
  const searchVars = useQueryString();
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm<Inputs>({
    shouldFocusError: false,
    mode: 'onTouched'
  });

  const onSubmit: SubmitHandler<Inputs> = ({ password }) => {
    const { email, code } = searchVars;
    authResetPassword(authDispatch, { email, code, password })
      .then(() => {
        reset();
        setSubmissionError(undefined);
      })
      .catch(err => {
        setSubmissionError(err.message);
      });
  };

  return (
    <AuthorizationForm
      headerText="Password Reset"
      submitButtonText="Submit"
      linkText="Cancel"
      linkTo="/"
      onSubmit={handleSubmit(onSubmit)}
      errorMessage={submissionError}
    >
      <FormInput
        type="password"
        labelText="New Password"
        placeholder="Enter new password..."
        {...register('password', { required: 'Required' })}
        error={errors.password}
        required
      />
      <FormInput
        type="password"
        labelText="Confirm Password"
        placeholder="Re-enter new password..."
        {...register('confirmPassword', {
          required: 'Required',
          validate: {
            matchesPreviousPassword: (value: string) => {
              const { password } = getValues();
              return password === value || 'Passwords do not match';
            }
          }
        })}
        error={errors.confirmPassword}
        required
      />
    </AuthorizationForm>
  );
};

export default ResetPasswordForm;
