import React, { useContext } from 'react';

import { DataSet } from '../barChart';

import { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { PresentationalDataFilters } from 'helpers/dataFilters/format';
import { PresentationalBenchmark } from 'helpers/benchmarks';

export interface ProgramPdfExportData {
  taughtSkills: DataSet[];
  soughtSkills: DataSet[];
  siteId: string;
  siteName: string;
  benchmarks: PresentationalBenchmark[];
  groupTypeName: string | null;
  programName: string;
  programSummary: string;
  programLearningOutcomes: string;
  cipCodeLabel: string | null;
  programCourses: CurricularData<Course>;
  skillCounts: Record<string, number>;
  skillsInProgram: Record<string, string>;
  categorizedSkills: CategorizedSkills;
  dataFilters: PresentationalDataFilters;
}

const ProgramContext = React.createContext<ProgramPdfExportData>({} as ProgramPdfExportData);

export const ProgramDataProvider: React.FC<{ data: ProgramPdfExportData }> = ({
  data,
  children
}) => {
  return <ProgramContext.Provider value={data}>{children}</ProgramContext.Provider>;
};

export function useProgramData() {
  return useContext(ProgramContext);
}
