import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
/** @jsxImportSource @emotion/react */

import PopupComponent, { PopupOptions, PopupProps } from 'components/atoms/Popup';

interface PopupState {
  currentPopup: symbol | null;
  setPopup: (id: symbol) => void;
  hidePopup: (id?: symbol) => void;
  togglePopup: (id: symbol) => boolean;
  popupZindex?: number;
}

interface PopupProviderProps {
  popupZindex?: number;
}

interface PopupWrapperProps extends Omit<PopupProps, 'dimensions' | 'onClosePopup'> {
  dimensions?: PopupProps['dimensions'];
  onClosePopup?: PopupProps['onClosePopup'];
}

const popupContext = createContext({} as PopupState);

export const PopupProvider: React.FC<PopupProviderProps> = ({ children, popupZindex }) => {
  const [currentPopup, setCurrentPopup] = useState<symbol | null>(null);

  const setPopup = useCallback((id: symbol) => {
    setCurrentPopup(id);
  }, []);

  const hidePopup = useCallback((popup?: symbol) => {
    setCurrentPopup(oldPopup => (!popup || popup === oldPopup ? null : oldPopup));
  }, []);

  const togglePopup = useCallback((popup: symbol) => {
    let next = false;
    setCurrentPopup(oldPopup => {
      next = !oldPopup || oldPopup !== popup;
      return next ? popup : null;
    });
    return next;
  }, []);

  const val = useMemo(
    () => ({ currentPopup, setPopup, hidePopup, togglePopup, popupZindex }),
    [currentPopup, setPopup, hidePopup, togglePopup, popupZindex]
  );

  return <popupContext.Provider value={val}>{children}</popupContext.Provider>;
};

export const usePopup = (options: PopupOptions = {}) => {
  const [id] = useState(Symbol());

  const {
    popupZindex,
    currentPopup,
    setPopup,
    hidePopup,
    togglePopup: togglePopupOnProvider
  } = useContext(popupContext);

  useEffect(() => {
    return () => closePopup();
  }, []);

  const openPopup = useCallback(() => setPopup(id), [setPopup, id]);

  const closePopup = useCallback(() => hidePopup(id), [hidePopup, id]);

  const togglePopup = useCallback(() => togglePopupOnProvider(id), [togglePopupOnProvider, id]);

  const closeAllPopups = useCallback(() => hidePopup(), [hidePopup]);

  const scrollListener = () => {
    closeAllPopups();
  };

  window.addEventListener('scroll', scrollListener, true);

  const WrappedPopup: React.FC<PopupWrapperProps> = ({
    children,
    onClosePopup,
    dimensions = { height: 'fit-content', width: 'fit-content' },
    ...props
  }) => {
    return createPortal(
      currentPopup === id && (
        <PopupComponent
          css={{ zIndex: popupZindex }}
          onClosePopup={() => {
            closePopup();
            onClosePopup && onClosePopup();
          }}
          dimensions={dimensions}
          {...props}
          {...options}
        >
          {children}
        </PopupComponent>
      ),
      document.body
    );
  };

  return [
    WrappedPopup,
    togglePopup,
    id === currentPopup,
    { openPopup, closePopup, closeAllPopups }
  ] as const;
};
