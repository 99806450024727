import React from 'react';
import styled from '@emotion/styled';

import Collapsible, { CollapsibleProps } from 'components/atoms/Collapsible';

interface SkillCategoryCollapsibleProps<T extends Skill = Skill> extends CollapsibleProps {
  subcategories: Record<string, T[]>;
  renderSkillContent: (skill: T, i: number) => React.ReactNode;
}

export const SkillCategoryCollapsible: React.FC<SkillCategoryCollapsibleProps> = ({
  buttonText,
  name,
  subcategories,
  isControllerOpen,
  renderSkillContent,
  className
}) => {
  return (
    <Collapsible
      {...{ buttonText, name, className }}
      className={`${className} category`}
      defaultIsOpen
      isControllerOpen={isControllerOpen}
    >
      {name === 'Uncategorized'
        ? Object.values(subcategories).map(skills => {
            return skills.map((skill, i) => (
              <StyledSubcategorySkills className="skill" key={skill.id}>
                {renderSkillContent(skill, i)}
              </StyledSubcategorySkills>
            ));
          })
        : Object.entries(subcategories).map(([subcategory, skills]) => (
            <Collapsible
              className="subcategory"
              buttonText={<span>{subcategory}</span>}
              name={subcategory}
              key={subcategory}
              defaultIsOpen
              isControllerOpen={isControllerOpen}
            >
              {skills.map((skill, i) => (
                <StyledSubcategorySkills className="skill" key={skill.id}>
                  {renderSkillContent(skill, i)}
                </StyledSubcategorySkills>
              ))}
            </Collapsible>
          ))}
    </Collapsible>
  );
};

const StyledSubcategorySkills = styled.div`
  display: flex;
`;

export default SkillCategoryCollapsible;
