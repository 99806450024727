import React from 'react';
import styled from '@emotion/styled';
import ExternalLink from 'components/atoms/ExternalLink';
import { ReactComponent as Logo } from 'images/lightcastBlack.svg';

import TOSText from 'components/atoms/TOSText';
import DisclaimerText from 'components/atoms/DisclaimerText';
import ModalButton from 'components/molecules/ModalButton';
import FooterModal from 'components/molecules/FooterModal';

import { lightGray, white, mediumGray } from 'utils/colors';
import footerLinks from 'utils/footerLinks';

const Footer = styled.footer`
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${lightGray};
  padding: 0.75rem 0;
  flex-wrap: wrap;
  color: ${mediumGray};
  background: ${white};

  & > * {
    margin: 0.75rem 0;
  }
`;

const StyledExternalLinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: inline-block;

  & > li {
    display: inline-block;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  font-size: 1em;
  color: ${mediumGray};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledModalButton = styled(ModalButton)`
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  height: 1.4rem;
  :hover {
    text-decoration: underline;
  }
`;

const StyledPipe = styled.span`
  margin: 0 1.5rem;
`;

const Lightcast = styled(Logo)`
  height: 1.7rem;
  margin-left: 0.5rem;
`;

const PoweredBy = styled.span`
  display: inline-flex;
`;

const GlobalFooter: React.FC = () => {
  return (
    <Footer>
      <StyledExternalLinkList>
        <li>
          <StyledModalButton type="button" buttonText="Terms of Use">
            {() => (
              <FooterModal titleText="Terms of Use">
                <TOSText />
              </FooterModal>
            )}
          </StyledModalButton>
          <StyledPipe>|</StyledPipe>
        </li>
        <li>
          <StyledExternalLink href={footerLinks.privacyPolicy.linkTo}>
            {footerLinks.privacyPolicy.linkText}
          </StyledExternalLink>
          <StyledPipe>|</StyledPipe>
        </li>
        <li>
          <StyledModalButton type="button" buttonText="Disclaimer">
            {() => (
              <FooterModal titleText="Lightcast Disclaimer">
                <DisclaimerText />
              </FooterModal>
            )}
          </StyledModalButton>
          <StyledPipe>|</StyledPipe>
        </li>
        <li>
          <StyledExternalLink href={footerLinks.checkStatus.linkTo}>
            {footerLinks.checkStatus.linkText}
          </StyledExternalLink>
          <StyledPipe>|</StyledPipe>
        </li>
      </StyledExternalLinkList>
      <span>
        <span>&#169;Copyright {new Date().getFullYear()}, All Rights Reserved.</span>
        <StyledPipe>|</StyledPipe>
        <PoweredBy>
          Powered by <Lightcast title="Lightcast Logo" role="img" />
        </PoweredBy>
      </span>
    </Footer>
  );
};

export default GlobalFooter;
