import React from 'react';
import { View, Link } from '@react-pdf/renderer';
import { CenteredHeader, Column, Container, ContainerPage, Paragraph, Row } from './components';

export interface ContentTableHeader {
  label: string;
  pageNumber: number;
  id: string;
  level: number;
}

export type RenderPurpose = 'outline' | 'present';

const stashedHeaders: ContentTableHeader[] = [];

let currentRenderPurpose: RenderPurpose = 'present';

export function addContentsHeader(header: ContentTableHeader) {
  if (currentRenderPurpose !== 'outline') {
    return;
  }

  if (stashedHeaders.find(stashedHeader => stashedHeader.id === header.id)) {
    return;
  }

  stashedHeaders.push(header);
}

export function setRenderPurpose(purpose: RenderPurpose) {
  if (purpose === 'outline') {
    while (stashedHeaders.length) {
      stashedHeaders.pop();
    }
  }

  currentRenderPurpose = purpose;
}

export const ContentsTable: React.FC = () => {
  if (currentRenderPurpose === 'outline') {
    return <ContainerPage />;
  }

  return (
    <ContainerPage omitFooter>
      <Column gap={20}>
        <CenteredHeader text="Table of Contents" id="table-of-contents" />
        <Column gap={10}>
          {stashedHeaders.map(header => (
            <Row key={header.id} gap={3}>
              <Container width={20 * header.level} />
              <Link style={{ textDecoration: 'none' }} src={`#${header.id}`}>
                <Paragraph text={header.label} />
              </Link>
              <View style={{ flexBasis: 0, flexGrow: 1, overflow: 'hidden' }}>
                <Paragraph text=".........................................................................................................................................................." />
              </View>

              <Paragraph text={header.pageNumber.toString()} />
            </Row>
          ))}
        </Column>
      </Column>
    </ContainerPage>
  );
};
