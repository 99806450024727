import React, { ReactNode, useEffect, useRef } from 'react';
import { ReactComponent as InfoIcon } from 'images/skillInfo.svg';
import styled from '@emotion/styled';
import { usePopup } from 'hooks/usePopup';

const Informative: React.FC<{
  renderContent(): ReactNode;
  shouldClose?: boolean;
  size?: string;
}> = ({ renderContent, shouldClose, size = '1.8rem' }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldClose) {
      closePopup();
    }
  }, [shouldClose]);

  const [Popup, toggleIsShowingDetail, isShowingDetail, { closePopup }] = usePopup();

  return (
    <div ref={ref}>
      <StyledInfoIcon onClick={toggleIsShowingDetail} size={size} />
      {isShowingDetail && <Popup anchorPointRef={ref}>{renderContent()}</Popup>}
    </div>
  );
};

export default Informative;

const StyledInfoIcon = styled(InfoIcon)<{ size: string }>`
  cursor: pointer;
  height: ${props => props.size};
  width: ${props => props.size};
`;
