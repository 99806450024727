import React, { useCallback, useRef } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useWatch, FieldError } from 'react-hook-form';

import { highlightParsedSkills, highlightRevealedSkill } from 'helpers/highlightParsedSkills';
import { PreprocessedSkill } from 'services/skills';

import {
  darkBlue,
  darkGray,
  bgLightGray,
  borderGray,
  contextBlue,
  highlightYellow,
  ahoy
} from 'utils/colors';

const Highlights = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
  background-color: ${bgLightGray};
  font-size: 1.4rem;
  border: 1px solid ${borderGray};
  color: transparent;
  padding: 1.2rem 1.5rem;
  border-radius: 0.3rem;
  position: absolute;
  line-height: 1.6;
  top: 0;
  left: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-y: none;
`;

// The linear gradient for the background is for hiding the second scrollbar on mac browsers
const TextArea = styled.textarea<{ error?: FieldError }>`
  flex-grow: 1;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(247, 250, 252, 1),
    rgba(247, 250, 252, 1) 15px,
    rgba(247, 250, 252, 0) 15px,
    rgba(247, 250, 252, 0)
  );
  overflow-y: auto;
  overflow-x: hidden;
  caret-color: ${darkBlue};
  padding: 1.2rem 1.5rem;
  font-size: 1.4rem;
  border: 1px solid ${({ error }) => (error ? ahoy : 'transparent')};
  border-radius: 0.3rem;
  line-height: 1.6;
  white-space: pre-wrap;
  overscroll-behavior-y: none;
  ::placeholder {
    color: ${darkGray};
    font-style: italic;
  }
  position: relative;
`;

const HighlightableWrapper = styled.div<{ textareaHeight?: string; textareaMargin?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${props => (props.textareaMargin ? props.textareaMargin : '2.4rem 0')};
  min-height: ${props => (props.textareaHeight ? props.textareaHeight : 'auto')};
`;

interface HighlightedTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  textareaHeight?: string;
  textareaMargin?: string;
  error?: FieldError;
  highlights: PreprocessedSkill[];
  resize?: string;
  name: string;
  dataCy?: string;
  revealedSkill?: string;
}

const Highlight = styled.span`
  background-color: ${highlightYellow};
`;

const Context = styled.span`
  background-color: ${contextBlue};
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: ${ahoy};
  position: absolute;
  top: calc(100% + 0.5rem);
`;

const highlightText = (text: string, key: string) => (
  <Highlight key={`highlight-${key}`}>{text}</Highlight>
);
const contextText = (text: string, key: string) => <Context key={`context-${key}`}>{text}</Context>;
const plainText = (text: string, key: string) => <span key={`plainText-${key}`}>{text}</span>;

const HighlightedTextArea = React.forwardRef<HTMLTextAreaElement, HighlightedTextAreaProps>(
  (
    {
      highlights,
      revealedSkill,
      textareaHeight,
      textareaMargin,
      resize = 'none',
      onChange,
      dataCy,
      error,
      ...props
    },
    ref
  ) => {
    const text = useWatch({
      name: props.name
    });

    const highlightNodes = React.useMemo(() => {
      if (text) {
        if (revealedSkill) {
          return highlightRevealedSkill(
            text,
            plainText,
            highlightText,
            contextText,
            revealedSkill,
            highlights
          );
        }
        return highlightParsedSkills(text, highlightText, plainText, highlights);
      }
      return [];
    }, [highlights, text, revealedSkill]);

    const highlightsRef = useRef<HTMLDivElement>(null);
    const synchronizeScroll = useCallback(
      (e: { currentTarget: HTMLElement }) => {
        if (highlightsRef.current) {
          highlightsRef.current.scrollTop = e.currentTarget.scrollTop;
        }
      },
      [highlightsRef]
    );

    return (
      <HighlightableWrapper {...{ textareaHeight, textareaMargin }} className="text-area-wrapper">
        <Highlights ref={highlightsRef}>{highlightNodes}</Highlights>
        <TextArea
          ref={ref}
          error={error}
          data-cy={dataCy}
          css={css`
            resize: ${resize};
          `}
          {...props}
          onScroll={synchronizeScroll}
          onChange={e => {
            onChange && onChange(e);
            synchronizeScroll(e);
          }}
        />
        {error && <Error>{error.message}</Error>}
      </HighlightableWrapper>
    );
  }
);

export default HighlightedTextArea;
