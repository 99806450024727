import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import Card from 'components/atoms/Card';
import TextAndSkillFilters from 'components/organisms/TextAndSkillFilters';
import SelectedItemsCard, { Item } from 'components/molecules/SelectedItemsCard';
import SelectableTile from 'components/molecules/SelectableTile';
import ProgramTile from 'components/molecules/ProgramTile';

import { lightGray, darkBlue, mediumGray } from 'utils/colors';
import { usePrograms } from 'hooks';
import { ReactComponent as CoursePlaceholder } from 'images/skillsByTextEmpty.svg';
import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';

const PAGE_SIZE = 100;

const Header = styled.h3`
  color: ${darkBlue};
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
`;

const StyledCard = styled(Card)`
  margin-top: 4.8rem;
  margin-bottom: 7rem;
  padding: 0;
  flex-direction: row;
`;

const SelectCoursesContainer = styled.div`
  border-right: 1px solid ${lightGray};
  width: 55%;
  padding: 3rem 3.2rem 2rem 3.2rem;
`;

const SelectedCoursesScrollContainer = styled.div`
  padding: 0.8rem 1rem 0 1rem;
  margin: 1.7rem -1rem 0 -1rem;
  height: 43rem;
  overflow-y: auto;
`;

const NoCoursesFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.4rem;
  color: ${mediumGray};
`;

const BoldText = styled.div`
  display: block;
  font-weight: 600;
  margin-top: 2rem;
`;

const StyledSelectedItemsCard = styled(SelectedItemsCard)`
  box-shadow: none;
`;

const buildProgramSearchPayload = ({ filter }: CurricularRouteSearchState<ProgramSortOption>) => {
  const filters: ProgramSearchFilters = {};

  if (filter.filterType === 'text' && filter.filterQuery.query.length > 1) {
    filter.filterQuery.fields = ['title'];
    filters.textFilter = filter.filterQuery;
  }

  if (filter.filterType === 'skills' && filter.filterQuery.length) {
    filters.skills = { in: filter.filterQuery.map(skill => skill.id) };
  }

  return filters;
};

type FormattedProgram = {
  id: string;
  title: string;
  courses: number;
  programType: string;
};

const SelectProgramForm: React.FC<{
  locationStateKey: string;
  className?: string;
  onChange?: (items: readonly Item[]) => void;
  defaultValue?: readonly Item[];
}> = ({ locationStateKey, className, onChange, defaultValue }) => {
  const [selectedPrograms, setSelectedPrograms] = useState<readonly Item[]>([]);
  const [page, setPage] = useState<number>(1);
  const location = useLocation<{
    [x: string]: CurricularRouteSearchState<ProgramSortOption> | undefined;
  }>();
  const state = location.state?.[locationStateKey];
  const [foundPrograms, setFoundPrograms] = useState<FormattedProgram[]>([]);
  const [totalAvailable, setTotalAvailable] = useState<number>(0);
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    setPage(1);
  }, [state]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedPrograms(defaultValue);
      onChange && onChange(defaultValue);
    }
  }, [defaultValue]);

  const { data, isLoading, isPreviousData } = usePrograms(
    {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filter: buildProgramSearchPayload(state || defaultCurricularRouteSearchState())
    },
    {
      staleTime: 1000 * 60 * 3
    }
  );

  const handleSelectedProgramChange = (program: { id: string; title: string }) => {
    const isProgramSelected = selectedPrograms.some(
      selectedCourse => selectedCourse.id === program.id
    );
    const newPrograms = isProgramSelected
      ? selectedPrograms.filter(selectedProgram => selectedProgram.id !== program.id)
      : [...selectedPrograms, { title: program.title, id: program.id }];
    setSelectedPrograms(newPrograms);
  };

  useEffect(() => onChange && onChange(selectedPrograms), [selectedPrograms]);

  const programs = React.useMemo<FormattedProgram[] | undefined>(
    () =>
      data?.data.map(({ id, attributes: { title, courses, groupType } }) => ({
        id,
        title,
        courses: courses.length,
        programType: groupType.label
      })),
    [data]
  );

  useEffect(() => {
    data && setTotalAvailable(data.meta.totalAvailable);
    programs && setFoundPrograms(page === 1 ? programs : foundPrograms.concat(programs));
  }, [programs]);

  const nextPageTrigger = useCallback(
    node => {
      if (!programs) {
        return;
      }
      if (isLoading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if ((page - 1) * PAGE_SIZE > totalAvailable) {
          return;
        }
        if (entries[0].isIntersecting) {
          setPage(page + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [programs]
  );

  return (
    <StyledCard className={className}>
      <SelectCoursesContainer>
        <Header>Compare Job Posting to Specific Programs</Header>
        <TextAndSkillFilters
          hideDivider
          curricularType="Program"
          isRequesting={isLoading || isPreviousData}
          trackHistory={false}
          locationStateKey={locationStateKey}
        />
        <SelectedCoursesScrollContainer>
          {foundPrograms.length ? (
            foundPrograms.map((program, i) => {
              const { id, title, programType, courses } = program;
              const shouldTriggerNextPage =
                i === foundPrograms.length - 1 && i !== totalAvailable - 1;
              return (
                <SelectableTile
                  key={`program-tile-${id}`}
                  newRef={shouldTriggerNextPage ? nextPageTrigger : undefined}
                  selected={selectedPrograms.some(selectedProgram => selectedProgram.id === id)}
                  onClick={() => handleSelectedProgramChange(program)}
                >
                  <ProgramTile title={title} programType={programType} numCourses={courses} />
                </SelectableTile>
              );
            })
          ) : (
            <NoCoursesFound>
              <CoursePlaceholder />
              <BoldText>No Programs found</BoldText>
              <div>Try adjusting your search query.</div>
            </NoCoursesFound>
          )}
        </SelectedCoursesScrollContainer>
      </SelectCoursesContainer>
      <StyledSelectedItemsCard
        headerText="Selected Programs"
        pluralLabel="Programs"
        selectedItems={selectedPrograms.map(selectedProgram => ({
          ...selectedProgram,
          remove: () =>
            setSelectedPrograms(prev => prev.filter(program => program.id !== selectedProgram.id))
        }))}
        removeAll={() => setSelectedPrograms([])}
        removeItem={item =>
          setSelectedPrograms(prev => prev.filter(program => program.id !== item.id))
        }
      />
    </StyledCard>
  );
};

export default SelectProgramForm;
