import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import MonitoredRoute from 'components/atoms/MonitoredRoute';
import RequireAuth from 'components/atoms/RequireAuth';
import Authorization from 'components/pages/Authorization';
import MainLayout from 'components/pages/MainLayout';

import { setCurrentUser, useAuthDispatch, useAuthState } from 'store/authStore';
import { setFeatureFlaggingUser } from 'utils/featureFlagging';
import { PopupProvider } from 'hooks/usePopup';

const App: React.FC = () => {
  const authDispatch = useAuthDispatch();
  const { isLoading, isLoggedIn } = useAuthState();
  const client = useLDClient();

  useEffect(() => {
    if (client && isLoggedIn) {
      setFeatureFlaggingUser(client);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setCurrentUser(authDispatch).catch(() => {
      // ignore these errors for now.  There will be an error generated if the user is not logged in.
    });
    // We really only want this to run the first time the page loads, so we don't
    // want to have any effect dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopupProvider>
      <ToastContainer role="alert" theme="colored" icon={false} />
      <Switch>
        <MonitoredRoute path="/" exact>
          {isLoading ? (
            <div />
          ) : (
            <RequireAuth target="/dashboard/courses">
              <Redirect to="/dashboard/courses" />
            </RequireAuth>
          )}
        </MonitoredRoute>
        {/* Auth routes don't use the standard header/footer format */}
        <MonitoredRoute path="/auth" component={Authorization} />
        <MonitoredRoute
          path="/"
          render={() => (
            <RequireAuth>
              <MainLayout />
            </RequireAuth>
          )}
        />
      </Switch>
    </PopupProvider>
  );
};

export default App;
