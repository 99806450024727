import React from 'react';
import { UniversalBenchmark } from 'helpers/benchmarks';
import CustomBenchmarkPopup from 'components/molecules/JpaBenchmarkPopup';
import LOTBenchmarkPopup from 'components/molecules/LOTBenchmarkPopup';

export const BenchmarkPopup: React.FC<{
  benchmark: UniversalBenchmark;
}> = ({ benchmark }) => {
  return benchmark.universalType === 'occupation' ? (
    <LOTBenchmarkPopup occupation={benchmark.attributes} />
  ) : (
    <CustomBenchmarkPopup benchmark={benchmark.attributes} isCarouselPopup />
  );
};
