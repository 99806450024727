import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import Card from 'components/atoms/Card';
import TextAndSkillFilters from 'components/organisms/TextAndSkillFilters';
import { Item } from 'components/molecules/SelectedItemsCard';
import SelectedCourseCard from 'components/molecules/SelectedCoursesCard';
import CourseCard from 'components/molecules/CourseCard';
import CourseSkillsCard from 'components/molecules/CourseSkillsCard';
import SelectableTile from 'components/molecules/SelectableTile';
import ModalButton from 'components/molecules/ModalButton';
import JobPostingSkillsModal from 'components/organisms/JobPostingSkillsModal';

import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import { lightGray, white, darkSaphire, darkBlue, mediumGray } from 'utils/colors';
import { useCourses } from 'hooks';
import { useEffectWithPreviousDep } from 'hooks/useEffectWithPeviousDep';
import { ReactComponent as CoursePlaceholder } from 'images/skillsByTextEmpty.svg';

import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';

const PAGE_SIZE = 100;

export type SoughtSkillObject = Record<
  string,
  {
    name: string;
    count?: number;
    color?: string;
  }
>;

interface SkillsCourse extends Pick<Course, 'title' | 'courseId' | 'credits'> {
  id: string;
  skills: readonly string[];
}

type AdditionalFilters = {
  associatedGroups?: string[];
};

const buildSearchPayload = (
  { filter }: CurricularRouteSearchState<CourseSortOption>,
  soughtSkills?: SoughtSkillObject,
  additionalFilters?: AdditionalFilters
) => {
  const filters: CourseSearchFilters = {};
  let soughtSkillIds: string[] = [];
  if (soughtSkills) {
    soughtSkillIds = Object.keys(soughtSkills);
  }
  if (soughtSkillIds.length) {
    filters.skills = { in: soughtSkillIds };
  }

  if (filter.filterType === 'text' && filter.filterQuery.query.length > 1) {
    filters.textFilter = filter.filterQuery;
  }
  if (filter.filterType === 'skills' && filter.filterQuery.length) {
    if (soughtSkillIds.length) {
      filters.skills = {
        and: [{ in: soughtSkillIds }, { in: filter.filterQuery.map(skill => skill.id) }]
      };
      return filters;
    }
    filters.skills = { in: filter.filterQuery.map(skill => skill.id) };
  }

  if (additionalFilters?.associatedGroups) {
    filters.associatedGroups = { in: additionalFilters.associatedGroups };
  }

  return filters;
};

const sortByNumberOfSoughtSkills = (a: SkillsCourse, b: SkillsCourse) => {
  return a.skills && b.skills ? b.skills?.length - a.skills?.length : 0;
};

interface RouteStateInitialValues {
  formValues?: { courses: string[] };
}

const valuesAreDifferent = (oldValue?: readonly Item[], newValue?: readonly Item[]): boolean => {
  if (typeof oldValue === 'undefined') {
    if (typeof newValue === 'undefined') {
      return false;
    }
    return true;
  }
  if (typeof newValue === 'undefined') {
    return true;
  }

  const oldById = Object.fromEntries(oldValue.map(item => [item.id, item]));
  const newById = Object.fromEntries(newValue.map(item => [item.id, item]));

  const missing = oldValue.map((item: Item) => !newById[item.id]);
  const added = newValue.map((item: Item) => !oldById[item.id]);

  return !!(missing.length || added.length);
};

interface Props {
  locationStateKey: string;
  className?: string;
  onChange?: (items: readonly Item[]) => void;
  defaultValues?: readonly Item[];
  additionalFilters?: AdditionalFilters;
  headers?: {
    left?: string;
    right?: string;
  };
  soughtSkills?: SoughtSkillObject;
  displaySkillsAddedByCoursesButton?: boolean;
}

const SelectCoursesProgramForm: React.FC<Props> = ({
  locationStateKey,
  className,
  onChange,
  defaultValues,
  additionalFilters,
  headers,
  soughtSkills,
  displaySkillsAddedByCoursesButton = false
}) => {
  const profileState = useProfileState();
  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  const [selectedCourses, setSelectedCourses] = useState<readonly Item[]>([]);
  const [page, setPage] = useState<number>(1);
  const location = useLocation<
    | (Partial<{ [x: string]: CurricularRouteSearchState<CourseSortOption> | undefined }> &
        RouteStateInitialValues)
    | undefined
  >();
  const state = {
    ...defaultCurricularRouteSearchState(),
    ...(location?.state?.[locationStateKey] || {})
  };

  const [foundCourses, setFoundCourses] = useState<SkillsCourse[]>([]);

  const { data, isLoading, isPreviousData } = useCourses(
    {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filter: buildSearchPayload(state, soughtSkills || {}, additionalFilters)
    },
    {
      staleTime: 1000 * 60 * 3
    }
  );

  const courses = React.useMemo(
    () =>
      data?.data.map(({ attributes: { title, courseId, skills, credits }, id }) => ({
        id,
        title,
        courseId,
        credits,
        skills:
          soughtSkills && skills
            ? skills.map(skill => soughtSkills[skill.id]?.name).filter(Boolean)
            : []
      })),
    [data]
  );

  const soughtAddedSkills = React.useMemo(() => {
    if (soughtSkills && courses && selectedCourses) {
      return selectedCourses
        .map(selectedCourse => {
          const matchedCourse = courses.find(course => course.id === selectedCourse.id);
          return {
            ...selectedCourse,
            skills: matchedCourse?.skills,
            courseId: matchedCourse?.courseId
          };
        })
        .reduce<Record<string, { title: string; courseId?: string }[]>>(
          (obj, { title, courseId, skills }) => {
            skills?.forEach(skill => {
              if (obj[skill]) {
                obj[skill].push({ title, courseId });
              } else {
                obj[skill] = [{ title, courseId }];
              }
            });
            return obj;
          },
          {}
        );
    }
    return {};
  }, [selectedCourses, soughtSkills, courses]);

  const soughtMissingSkills = React.useMemo(() => {
    if (soughtSkills && soughtAddedSkills) {
      const missingSkills = Object.values(soughtSkills)
        .map(skills => skills.name)
        .filter(value => !Object.keys(soughtAddedSkills).includes(value));
      return missingSkills;
    }
  }, [soughtSkills, soughtAddedSkills]);

  useEffect(() => {
    setPage(1);
  }, [location.state]);

  useEffectWithPreviousDep(
    deps => {
      const oldDefaultValues: readonly Item[] = deps?.[0] || [];

      if (!valuesAreDifferent(oldDefaultValues, selectedCourses)) {
        setSelectedCourses(defaultValues || []);
        onChange && onChange(defaultValues || []);
      }
    },
    [defaultValues]
  );

  const [totalAvailable, setTotalAvailable] = useState<number>(0);

  const observer = useRef<IntersectionObserver>();
  const isRequesting = isLoading || isPreviousData;

  useEffect(() => {
    data && setTotalAvailable(data.meta.totalAvailable);
    courses && setFoundCourses(page === 1 ? courses : foundCourses.concat(courses));
  }, [courses]);

  const handleSelectedCoursesChange = (course: SkillsCourse) => {
    const isCourseAlreadySelected = selectedCourses.some(
      selectedCourse => selectedCourse.id === course.id
    );
    const newCourses = isCourseAlreadySelected
      ? selectedCourses.filter(selectedCourse => selectedCourse.id !== course.id)
      : [
          ...selectedCourses,
          {
            title: course.title,
            id: course.id,
            subtitle: course.courseId
          }
        ];
    setSelectedCourses(newCourses);
  };

  useEffect(() => onChange && onChange(selectedCourses), [selectedCourses]);

  const nextPageTrigger = useCallback(
    node => {
      if (!courses) {
        return;
      }
      if (isLoading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if ((page - 1) * PAGE_SIZE > totalAvailable) {
          return;
        }
        if (entries[0].isIntersecting) {
          setPage(page + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [courses]
  );

  const shouldShowCourses =
    foundCourses.length && (!soughtSkills || Object.keys(soughtSkills).length);
  return (
    <StyledCard className={className}>
      {hasEditorPrivileges ? (
        <>
          <SelectCoursesContainer>
            <Header>{headers?.left || 'Select Courses for Your Program'}</Header>
            <TextAndSkillFilters
              hideDivider
              curricularType="Course"
              isRequesting={isRequesting}
              trackHistory={false}
              locationStateKey={locationStateKey}
            />
            <SelectedCoursesScrollContainer maxHeight={32}>
              {shouldShowCourses ? (
                (soughtSkills ? foundCourses.sort(sortByNumberOfSoughtSkills) : foundCourses).map(
                  (course, i) => {
                    const { skills, id, ...rest } = course;
                    const shouldTriggerNextPage =
                      i === foundCourses.length - 1 && i !== totalAvailable - 1;
                    return (
                      <SelectableTile
                        key={`course-tile-${id}-${i}`}
                        newRef={shouldTriggerNextPage ? nextPageTrigger : undefined}
                        selected={selectedCourses.some(selectedCourse => selectedCourse.id === id)}
                        dataCy={`select-courses-program-form_selectable-tile_${id}`}
                        {...rest}
                        onClick={() => handleSelectedCoursesChange(course)}
                      >
                        {soughtSkills ? (
                          <CourseSkillsCard soughtSkillsInCourse={skills} {...rest} />
                        ) : (
                          <StyledCourseCard skillCount={skills?.length} {...rest} />
                        )}
                      </SelectableTile>
                    );
                  }
                )
              ) : (
                <NoCoursesFound>
                  <CoursePlaceholder />
                  <BoldText>No courses found</BoldText>
                  <div>Try adjusting your search query.</div>
                </NoCoursesFound>
              )}
            </SelectedCoursesScrollContainer>
          </SelectCoursesContainer>
          <StyledContainer>
            <StyledSelectedCourseCard
              headerText={headers?.right || 'Added Courses'}
              pluralLabel="Courses"
              selectedCourses={selectedCourses}
              setSelectedCourses={setSelectedCourses}
              removeAll={() => setSelectedCourses([])}
              removeCourse={item =>
                setSelectedCourses(prev => prev.filter(course => course.id !== item.id))
              }
            />
            {displaySkillsAddedByCoursesButton && (
              <StyledModalButton
                buttonText={
                  <>
                    <StyledSpan>
                      {soughtSkills
                        ? `${Object.keys(soughtAddedSkills)?.length} / ${
                            Object.keys(soughtSkills).length
                          }`
                        : `0/0`}
                    </StyledSpan>
                    Job Posting Skills (See All)
                  </>
                }
                type="button"
              >
                {() => (
                  <JobPostingSkillsModal
                    addedSkills={soughtAddedSkills}
                    missingSkills={soughtMissingSkills}
                  />
                )}
              </StyledModalButton>
            )}
          </StyledContainer>
        </>
      ) : (
        <ReadOnlyCourseContainer>
          <TextAndSkillFilters
            hideDivider
            curricularType="Course"
            isRequesting={isRequesting}
            trackHistory={false}
            locationStateKey={locationStateKey}
          />
          {shouldShowCourses ? (
            <SelectedCoursesScrollContainer maxHeight={40}>
              {(soughtSkills ? foundCourses.sort(sortByNumberOfSoughtSkills) : foundCourses).map(
                (course, i) => {
                  const { skills, id, ...rest } = course;
                  const shouldTriggerNextPage =
                    i === foundCourses.length - 1 && i !== totalAvailable - 1;
                  return (
                    <ReadOnlyCourseTile
                      selectable={false}
                      key={`course-tile-${id}-${i}`}
                      newRef={shouldTriggerNextPage ? nextPageTrigger : undefined}
                      {...rest}
                    >
                      {soughtSkills ? (
                        <CourseSkillsCard soughtSkillsInCourse={skills} {...rest} />
                      ) : (
                        <StyledCourseCard skillCount={skills?.length} {...rest} />
                      )}
                    </ReadOnlyCourseTile>
                  );
                }
              )}
            </SelectedCoursesScrollContainer>
          ) : (
            <NoCoursesFound>
              <CoursePlaceholder />
              <BoldText>No courses found</BoldText>
              <div>Try adjusting your search query.</div>
            </NoCoursesFound>
          )}
        </ReadOnlyCourseContainer>
      )}
    </StyledCard>
  );
};

const Header = styled.h3`
  color: ${darkBlue};
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
`;

const StyledCard = styled(Card)`
  margin: 4.8rem 0;
  padding: 0;
  padding-bottom: 3rem;
  flex-direction: row;
`;

const SelectCoursesContainer = styled.div`
  border-right: 1px solid ${lightGray};
  width: 55%;
  padding: 3rem 3.2rem 0 3.2rem;
`;

const SelectedCoursesScrollContainer = styled.div<{ maxHeight?: number }>`
  height: calc(100% - 11rem);
  padding: 0.8rem 1rem 0 1rem;
  margin: 1.7rem -1rem 0 -1rem;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}rem` : 'none')};
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-mask-image: var(--mask-bottom-scroll-gradient);
`;

const StyledCourseCard = styled(CourseCard)`
  height: 100%;
`;

const NoCoursesFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: ${mediumGray};
  padding: 4rem;
`;

const BoldText = styled.div`
  display: block;
  font-weight: 600;
  margin-top: 2rem;
`;

const StyledSelectedCourseCard = styled(SelectedCourseCard)`
  box-shadow: none;

  ul {
    max-height: 32rem;
    -webkit-mask-image: var(--mask-bottom-scroll-gradient);
    padding-bottom: 2rem;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 45%;
`;

const StyledModalButton = styled(ModalButton)`
  position: absolute;
  margin: auto;
  bottom: 2rem;
  border-radius: 2rem;
  background: ${white};
  border: ${darkSaphire} solid 0.2rem;
  color: ${darkSaphire};
`;

const StyledSpan = styled.span`
  margin-right: 0.5rem;
  font-weight: bold;
`;

const ReadOnlyCourseContainer = styled.div`
  width: 100%;
  padding: 4rem 4rem 0 4rem;
`;

const ReadOnlyCourseTile = styled(SelectableTile)`
  box-shadow: none;
  border-bottom: 1px solid ${lightGray};
  border-radius: 0;
  & > button {
    padding: 0;
    box-shadow: none;

    margin-bottom: 2.5rem;
    &:hover {
      box-shadow: none;
    }
    cursor: auto;
  }
`;
export default SelectCoursesProgramForm;
