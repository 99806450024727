import React from 'react';
import styled from '@emotion/styled';

const VisuallyHidden = styled.span`
  height: 0;
  font-size: 0;
`;

const AriaAlert: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <VisuallyHidden role="alert" aria-live="polite">
    {children}
  </VisuallyHidden>
);

export default AriaAlert;
