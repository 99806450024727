import React from 'react';
import styled from '@emotion/styled';

const OccupationTaxonomyPopup: React.FC = () => {
  return (
    <InfoPopupContainer>
      <InfoPopupHeader>Lightcast Occupation Taxonomy</InfoPopupHeader>
      <div>
        The Lightcast Occupation Taxonomy (LOT) is composed of 4 different levels (Career Area,
        Occupation Group, Occupation, Specialized Occupation). This taxonomy has a 1-1 relationship
        between levels, allows users to see occupations at different levels of granularity. This
        taxonomy is also updated at least annually, making it especially useful for identifying
        emerging roles and occupations.
      </div>
    </InfoPopupContainer>
  );
};

export default OccupationTaxonomyPopup;

const InfoPopupHeader = styled.div`
  font-weight: bold;
`;

const InfoPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
