import React, { useEffect, useState } from 'react';

import UserForm, { UserFormDefaultValues } from 'components/organisms/UserForm';

import { useSites } from 'hooks/siteHooks';

export interface EditUserFormDefaultValues {
  id: string;
  personal: {
    name: string;
    email: string;
  };
  role: Role;
  accessLevels: AccessLevels;
  isInternalAdmin: boolean;
  isInternalOnly: boolean;
}

const USER_ROLE_MAPPINGS = [
  { value: 'editor', label: 'Editor' },
  { value: 'admin', label: 'Admin' },
  { value: 'viewer', label: 'Viewer' }
];

interface EditUserFormProps {
  closeModal: () => void;
  profileData: EditUserFormDefaultValues;
}

const EditUserForm: React.FC<EditUserFormProps> = ({ profileData, ...otherProps }) => {
  const [defaultValues, setDefaultValues] = useState<UserFormDefaultValues>();
  const { data: sitesObject } = useSites();

  useEffect(() => {
    const defaultUserRole = USER_ROLE_MAPPINGS.find(
      userRole => userRole.value === profileData.role
    );

    const defaultProfileValues: UserFormDefaultValues = {
      id: profileData.id,
      name: profileData.personal.name,
      email: profileData.personal.email,
      schools:
        (sitesObject &&
          Object.keys(profileData.accessLevels).reduce<School[]>((schools, school) => {
            if (sitesObject[school]) {
              schools.push({
                value: sitesObject[school].site,
                label: sitesObject[school].name
              });
            }
            return schools;
          }, [])) ||
        [],
      role: defaultUserRole || { value: 'editor', label: 'Editor' },
      accessLevels: profileData.accessLevels,
      isInternalOnly: profileData.isInternalOnly
    };

    if (profileData.isInternalAdmin) {
      defaultProfileValues.role = { value: 'admin', label: 'Lightcast Admin' };
    }

    setDefaultValues(defaultProfileValues);
  }, [profileData]);

  return defaultValues ? (
    <UserForm
      formTitleText="Edit User"
      submitButtonText="Save"
      defaultValues={defaultValues}
      isEditForm
      {...otherProps}
    />
  ) : (
    <div>Loading...</div>
  );
};

export default EditUserForm;
