import { fetchWithCognitoToken } from './cognito';

const domain = process.env.REACT_APP_PROXY_URL;

export interface DdnSkillsResponse {
  id: string;
  name: string;
  definingSkills: { name: string; id: string }[];
  distinguishingSkills: { name: string; id: string }[];
  necessarySkills: { name: string; id: string }[];
}

// Reference to the projected salary boosting skills endpoints
// https://api.lightcast.io/apis/ddn-api
export const fetchDdnSkills = async (
  occupationLevel: GranularLotLevel,
  occupationIds: string[]
): Promise<DdnSkillsResponse[]> => {
  const happyName = occupationLevel === 'occupation' ? 'lotocc' : 'lotspecocc';

  return await fetchWithCognitoToken(`${domain}/emsi-services/ddn/dimensions/${happyName}/bulk`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ ids: occupationIds })
  }).then(async res => {
    const json = await res.json();
    if (!res.ok) {
      throw new Error(
        `Failed to get salary boosting skills: ${json.message || json.errors?.[0]?.detail}`
      );
    }

    return json.data;
  });
};
