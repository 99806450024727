import React from 'react';
import styled from '@emotion/styled';

import TaughtSoughtBar from 'components/atoms/TaughtSoughtBar';
import { TaughtState } from 'hooks/programInsights';

interface TaughtSoughtLegendProps {
  show: TaughtState[];
  overrides?: Partial<Record<TaughtState, { color?: TaughtState; label?: string }>>;
}

const TaughtSoughtLegend: React.FC<TaughtSoughtLegendProps> = ({ show, overrides }) => {
  const LegendSection = (props: { state: TaughtState; label: string }) => {
    return (
      <KeyRow>
        <TaughtSoughtBar state={props.state} boxed />
        <span>{props.label}</span>
      </KeyRow>
    );
  };

  return (
    <LegendContainer>
      {[...new Set(show)].map((section, i) => {
        switch (section) {
          case 'taught':
            return (
              <LegendSection
                key={`taughtSoughtLegend-${i}`}
                state={overrides?.taught?.color || section}
                label={overrides?.taught?.label || 'Taught in this program'}
              />
            );
          case 'taught_other':
            return (
              <LegendSection
                key={`taughtSoughtLegend-${i}`}
                state={overrides?.taught_other?.color || section}
                label={overrides?.taught_other?.label || 'Taught in other programs'}
              />
            );
          case 'sought':
            return (
              <LegendSection
                key={`taughtSoughtLegend-${i}`}
                state={overrides?.sought?.color || section}
                label={overrides?.sought?.label || 'Not taught'}
              />
            );
          case 'hypothetical':
            return (
              <LegendSection
                key={`taughtSoughtLegend-${i}`}
                state={overrides?.hypothetical?.color || section}
                label={overrides?.hypothetical?.label || 'Hypothetical "what if" skills'}
              />
            );
          default:
            return null;
        }
      })}
    </LegendContainer>
  );
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const KeyRow = styled.div`
  font-size: 1.2rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export default TaughtSoughtLegend;
