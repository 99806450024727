import { CoursePdfExportData, ParagraphHighlight } from 'helpers/pdfExport';
import { useCourse, useSkillsByIds } from './curricularSkills';
import { useProfileState } from 'store/profileStore';

import useCategorizedSkills from './useCategorizedSkills';
import { useSites } from './siteHooks';
import { useSkillsInRecord } from './parseTexts';
import { PreprocessedSkill } from 'services/skills';
import { useMemo } from 'react';

export interface UseCoursePdfDataParams extends HookOptions {
  courseId: string;
}

export function useCoursePdfData(params: UseCoursePdfDataParams) {
  const {
    data: course,
    isLoading: courseIsLoading,
    error: courseError
  } = useCourse(params.courseId, true);

  const skillIds = useMemo(() => {
    if (!course?.data.attributes.skills) {
      return [];
    }

    return course.data.attributes.skills.map(skill => skill.id);
  }, [course]);

  const { skills, isLoading: skillsIsLoading, error: skillsError } = useSkillsByIds(skillIds);

  const categorizedSkills = useCategorizedSkills(skills);

  const { currentSite, isLoading: rawProfileStateIsLoading } = useProfileState();
  const { data: sitesObject, isLoading: sitesObjectIsLoading, error: siteObjectError } = useSites();
  const profileStateIsLoading = rawProfileStateIsLoading || false;

  const siteName = sitesObject?.[currentSite].name || '';
  const descriptionText = course?.data.attributes.description || '';
  const syllabusText = course?.data.attributes.syllabusText || '';
  const learningOutcomesText = course?.data.attributes.learningObjectiveText || '';

  const { data: skillsInRecord, isLoading: skillsInRecordIsLoading } = useSkillsInRecord({
    description: descriptionText,
    syllabus: syllabusText,
    learningOutcomes: learningOutcomesText
  });

  const {
    skills: skillsInProgram,
    isLoading: skillsInProgramIsLoading,
    error: skillsInProgramError
  } = useSkillsByIds(skillIds);

  const isLoading =
    courseIsLoading ||
    skillsIsLoading ||
    profileStateIsLoading ||
    sitesObjectIsLoading ||
    skillsInRecordIsLoading ||
    skillsInProgramIsLoading ||
    !course;

  const error = courseError || skillsError || siteObjectError || skillsInProgramError;

  if (isLoading || error) {
    return { data: undefined, isLoading, error };
  }

  const data: CoursePdfExportData = {
    categorizedSkills,
    courseName: course.data.attributes.title,
    institutionName: siteName,
    skillsInProgram,
    courseId: course.data.id,
    courseCode: course.data.attributes.courseId || null,
    skillsInRecord: skillsInRecord.description,
    description: {
      text: descriptionText,
      highlights: skillsToHighlights(skillsInRecord.description)
    },
    learningOutcomes: {
      text: learningOutcomesText,
      highlights: skillsToHighlights(skillsInRecord.learningOutcomes)
    },
    syllabus: {
      text: syllabusText,
      highlights: skillsToHighlights(skillsInRecord.syllabus)
    }
  };

  return { data, isLoading, error };
}

function skillsToHighlights(skills: PreprocessedSkill[]): ParagraphHighlight[] {
  return skills.flatMap(skill =>
    skill.highlights.map(highlight => ({
      start: highlight.sourceStart,
      end: highlight.sourceEnd
    }))
  );
}
