import { fetchWithCognitoToken } from './cognito';
const domain = process.env.REACT_APP_PROXY_URL;

export const fetchTitlesByTerm = async (
  query: string
): Promise<{ id: string; name: string; score: number }[]> => {
  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/profiles/taxonomies/title?q=${encodeURIComponent(query)}`
  );
  if (!response.ok) {
    throw new Error('Could not fetch job titles');
  }
  const { data } = await response.json();
  return data;
};

export const fetchSkillsByJobTitleRank = async (
  titleId: string
): Promise<{ name: string; profiles: number }[]> => {
  const response = await fetchWithCognitoToken(`${domain}/emsi-services/profiles/rankings/skills`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: {
        title: [titleId]
      },
      rank: {
        by: 'profiles',
        limit: 60,
        min_profiles: 1
      }
    })
  });

  if (!response.ok) {
    throw new Error('Could not fetch skills');
  }

  const {
    data: {
      ranking: { buckets }
    }
  } = await response.json();
  return buckets;
};
