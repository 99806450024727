import { View } from '@react-pdf/renderer';
import React from 'react';
import { lightGray } from 'utils/colors';

export const Box: React.FC<{ border?: boolean }> = ({ children }) => {
  return (
    <View wrap={false} style={{ borderColor: lightGray, borderWidth: 1 }}>
      {children}
    </View>
  );
};
