import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useController, useFormContext } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { PreprocessedSkill } from 'services/skills';
import { useCurrentSiteState } from 'store/currentSiteStore';
import { getToastFnThatWeTreatLikeHook } from 'hooks';

import Button from 'components/atoms/Button';
import Loading from 'components/atoms/Loading';
import {
  ContentWrapper,
  FormFooter,
  ModalSpan
} from 'components/molecules/CreateCustomBenchmarkModal';
import GeneralInfoForm from 'components/organisms/GeneralInfoForm';
import SkillsFromText from 'components/organisms/SkillsFromText';
import EmptySkillsCard from 'components/atoms/EmptySkillsCard';

interface Inputs {
  skills: CurricularSkill[];
  name: string;
  text: string;
}

interface SkillsFromTextBenchmarkFormProps {
  onCreateBenchmark: (
    benchmark: Omit<SkillsFromTextBenchmark, 'id' | 'deletedAt'>
  ) => Promise<void>;
  onCancel: () => void;
}

const SkillsFromTextBenchmarkForm: React.FC<SkillsFromTextBenchmarkFormProps> = ({
  onCancel,
  onCreateBenchmark
}) => {
  const { site } = useCurrentSiteState();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [revealedSkill, setRevealedSkill] = useState<string | undefined>(undefined);
  const [parsedSkills, setParsedSkills] = useState<PreprocessedSkill[]>([]);
  const toast = getToastFnThatWeTreatLikeHook();
  const { handleSubmit, reset } = useFormContext<Inputs>();

  const {
    field: { onChange, value: selectedSkills },
    fieldState: { error }
  } = useController<{ skills: readonly CurricularSkill[] }, 'skills'>({
    name: 'skills',
    defaultValue: [],
    rules: {
      required: {
        value: true,
        message: 'At least 1 selected skill required to create benchmark.'
      }
    }
  });

  useDeepCompareEffect(() => {
    onChange(
      selectedSkills.filter(selectedSkill =>
        parsedSkills.some(parsedSkill => parsedSkill.id === selectedSkill.id)
      )
    );
  }, [parsedSkills]);

  const onSubmit = async ({ skills, name, text }: Inputs) => {
    setIsSubmitting(true);
    const skillIds = skills.map(skill => skill.id);

    const skillsFromTextBenchmark: Omit<SkillsFromTextBenchmark, 'id' | 'deletedAt'> = {
      type: 'skillsFromTextBenchmark',
      site,
      title: name,
      text,
      facets: { skills: skillIds }
    };

    try {
      await onCreateBenchmark(skillsFromTextBenchmark);
      toast('Done! Benchmark created', 'success');
      reset();
    } catch (e) {
      toast('Unable to save benchmark. Try again?', 'error');
      setIsSubmitting(false);
    }
  };

  return (
    <form>
      <ContentWrapper>
        <ModalSpan>Create Custom Benchmark</ModalSpan>
        <DescriptionText>
          Extract skills from descriptive text in the section below to create a custom benchmark for
          this program.
        </DescriptionText>
        <Container>
          <StyledGeneralInfoForm
            className="info-form"
            onParseSkills={setParsedSkills}
            revealedSkill={revealedSkill}
            inputs={[
              {
                key: 'name',
                labelText: 'Name',
                placeholder: 'Name this benchmark...',
                registerOptions: {
                  required: {
                    value: true,
                    message: 'A name is required'
                  }
                },
                required: true
              }
            ]}
            textAreas={[
              {
                key: 'text',
                title: 'Paste text',
                placeholder: 'Enter text...',
                textareaHeight: '15rem',
                registerOptions: {
                  required: {
                    value: true,
                    message: 'Text is required'
                  },
                  maxLength: {
                    value: 10000,
                    message: 'Pasted text is limited up to 10,000 characters.'
                  }
                },
                required: true,
                'data-cy': 'skills-from-text-benchmark-form_text-area'
              }
            ]}
            showTitleInput={false}
          />
          <SkillsContainer>
            {parsedSkills.length ? (
              <SkillsFromText
                parsedSkills={parsedSkills}
                revealedSkillId={revealedSkill}
                onReveal={skillId =>
                  setRevealedSkill(revealedSkill === skillId ? undefined : skillId)
                }
                onChange={onChange}
                selectedSkills={selectedSkills}
                maxHeight={'40rem'}
                error={error}
              />
            ) : (
              <EmptySkillsCard
                cardHeadingText="Skills From Text"
                heavyText="No skills to show yet"
                subText="Start typing, or paste in text to see what skills you can add to your benchmark."
                error={error}
              />
            )}
          </SkillsContainer>
        </Container>
      </ContentWrapper>
      <FormFooter>
        <Button type="button" scheme="outline" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          data-cy="skills-from-text-benchmark-form_add-benchmark-button"
        >
          {!isSubmitting ? <StyledPlus title="plus icon" /> : <StyledLoading size={2.5} />}
          <div>Add to Benchmarks</div>
        </Button>
      </FormFooter>
    </form>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DescriptionText = styled.span`
  padding-top: 1rem;
  margin-bottom: 3rem;
  display: block;
`;

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
`;

const StyledLoading = styled(Loading)`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
`;

const SkillsContainer = styled.div`
  width: 48rem;
`;

const StyledGeneralInfoForm = styled(GeneralInfoForm)`
  width: 70rem;
  overflow-y: auto;
  max-height: 40rem;
  &.info-form {
    background: inherit;
    box-shadow: none;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    label {
      font-size: 1.2rem;
      font-weight: bold;
      color: #647893;
    }
    .text-area-wrapper {
      margin-top: 0;
    }
  }

  @media (max-width: 1366px) {
    width: 50rem;
  }
`;

export default SkillsFromTextBenchmarkForm;
