export function downloadUrl(url: string, filename: string) {
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  link.click();
  link.remove();
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);

  downloadUrl(url, filename);

  URL.revokeObjectURL(url);
}
