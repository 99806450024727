import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import AuthorizationForm from 'components/organisms/AuthorizationForm';
import FormInput from 'components/molecules/FormInput';

import { useQueryString } from 'hooks';
import { useAuthDispatch, authInitializePassword } from 'store/authStore';

interface Inputs {
  password: string;
  confirmPassword: string;
}

const InitialPasswordForm: React.FC<{ oldPassword?: string }> = ({ oldPassword = '' }) => {
  const authDispatch = useAuthDispatch();
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
  const searchVars = useQueryString();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm<Inputs>({
    shouldFocusError: false,
    mode: 'onTouched'
  });

  const onSubmit: SubmitHandler<Inputs> = ({ password }) => {
    oldPassword = searchVars['tmp-password'] || oldPassword;
    const email = searchVars.email || '';
    authInitializePassword(authDispatch, { email, oldPassword, newPassword: password })
      .then(() => {
        reset();
        setSubmissionError(undefined);
      })
      .catch(err => {
        authDispatch({ type: 'LOGIN_FAILURE' });
        setSubmissionError(err.message);
      });
  };

  return (
    <AuthorizationForm
      headerText="Set a Password"
      submitButtonText="Submit"
      linkText="Cancel"
      linkTo="/"
      onSubmit={handleSubmit(onSubmit)}
      errorMessage={submissionError}
    >
      <FormInput
        type="password"
        labelText="New Password"
        placeholder="Enter new password..."
        {...register('password', { required: 'Required' })}
        error={errors.password}
        required
      />
      <FormInput
        type="password"
        labelText="Confirm Password"
        placeholder="Re-enter new password..."
        {...register('confirmPassword', {
          required: 'Required',
          validate: {
            matchesPreviousPassword: (value: string) => {
              const { password } = getValues();
              return password === value || 'Passwords do not match';
            }
          }
        })}
        error={errors.confirmPassword}
        required
      />
    </AuthorizationForm>
  );
};

export default InitialPasswordForm;
