import React from 'react';
import styled from '@emotion/styled';

import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import FlyoutMenu, { MenuItem } from 'components/atoms/FlyoutMenu';

import { ReactComponent as Caret } from 'images/caret.svg';
import { ReactComponent as DownloadIcon } from 'images/cloudDownload.svg';

import { useExport } from 'hooks';
import getProgramExportData from 'helpers/exports/exportProgram';
import { darkSaphire, white } from 'utils/colors';
import { useProgramPdfData } from 'hooks/programPdfData';
import { ProgramPdfExport } from 'helpers/pdfExport';
import { usePdfExport } from 'hooks/pdfExport';
import { useProgramExcelData } from 'hooks/programExcelData';
import { useHookEnabler } from 'hooks/hookEnabler';

export const ProgramExports: React.FC<{
  program: SingleCurriculum<CurricularUnitResponse>;
  regionFilter: RegionFilter;
  jpaQuery: JPAOptionsFilter;
  currentTimeframe: number;
}> = ({ jpaQuery, program, regionFilter, currentTimeframe }) => {
  const programId = program.data.id;

  const { data: excelData, isLoading: excelDataIsLoading } = useProgramExcelData({
    currentTimeframe,
    jpaQuery,
    programId,
    regionFilter
  });

  const { start: exportPdf, isLoading: pdfIsExporting } = useHookEnabler({
    useHook({ enabled }) {
      const { isLoading } = usePdfExport({
        enabled,
        params: { programId, jpaQuery, regionFilter },
        filename: `${program.data.attributes.title}.pdf`,
        hook: useProgramPdfData,
        component: ProgramPdfExport
      });

      return { isLoading, data: null };
    }
  });

  const [csvExportProgram, { exportIsLoading: csvExportIsLoading }] = useExport({
    isPreloading: excelDataIsLoading,
    exportDataFn: getProgramExportData(excelData),
    filename: program.data.attributes.title
  });

  const buttonContents = (
    <>
      <StyledDownloadIcon />
      Export
      <StyledCaret />
    </>
  );

  return (
    <>
      <StyledFlyoutMenu
        ariaLabel="Export"
        renderCustomButton={props => (
          <LoadingButton {...props} type="button" isLoading={pdfIsExporting || csvExportIsLoading}>
            {buttonContents}
          </LoadingButton>
        )}
      >
        {({ getItemProps, handleClose }) => (
          <>
            <MenuItem
              {...getItemProps(
                { firstOrLastPosition: 'first' },
                {
                  onClick() {
                    handleClose();
                    exportPdf();
                  }
                }
              )}
            >
              <StyledButton type="button">PDF Export</StyledButton>
            </MenuItem>
            <MenuItem
              {...getItemProps(
                { firstOrLastPosition: 'last' },
                {
                  onClick() {
                    handleClose();
                    csvExportProgram();
                  }
                }
              )}
            >
              <StyledButton type="button">Excel Export</StyledButton>
            </MenuItem>
          </>
        )}
      </StyledFlyoutMenu>
    </>
  );
};

const StyledButton = styled(Button)`
  color: ${darkSaphire};
`;

const StyledCaret = styled(Caret)`
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-bottom: 0.3rem;
  stroke: ${white};
`;

const StyledFlyoutMenu = styled(FlyoutMenu)`
  border-bottom: 3px solid transparent;
  width: max-content;

  .flyoutMenu {
    width: max-content;
    right: 0;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;

  path {
    stroke: ${white};
  }
`;
