import React from 'react';
import { Text, View, StyleSheet, Canvas } from '@react-pdf/renderer';

import { paintChart } from './draw';

export interface DataSet {
  label: string;
  value: number;
}

export interface BarChartProps {
  ticks: number[];
  data: DataSet[];
  chartHeight: number;
  chartScale?: number;
  barColor?: string;
  append?: React.ReactNode;
}

export const LineBarChart: React.FC<BarChartProps> = ({
  data,
  ticks,
  barColor,
  chartHeight,
  append
}) => {
  const chartLeftMargin = 159;
  const yLabelOffset = 0.5;
  const yLabelSpacingRatio = 2.4; // Increasing this number will decrease the spacing between labels
  return (
    <View style={[styles.container, { flexWrap: 'wrap' }]}>
      {data.map((dataPoint, index) => (
        <View
          style={[styles.textContainer, { top: `${index / yLabelSpacingRatio + yLabelOffset}in` }]}
          key={index}
        >
          <Text key={index} style={styles.label}>
            {dataPoint.label}
          </Text>
        </View>
      ))}

      <Canvas
        style={styles.canvas}
        paint={(canvas, width) => {
          if (!width || !canvas) {
            return null;
          }

          paintChart({
            canvas,
            width,
            height: chartHeight,
            chartLeftMargin,
            barColor: barColor || 'black',
            data,
            ticks
          });

          return null;
        }}
      />
      {append}
      {/* <Footer /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 24,
    marginBottom: 20
  },
  textContainer: {
    position: 'absolute',
    maxWidth: '1.8in',
    right: '6.2in'
  },
  label: {
    fontFamily: 'Inter',
    fontSize: 11,
    maxLines: 2,
    textOverflow: 'ellipsis',
    textAlign: 'right'
  },
  canvas: {
    height: '100%',
    width: '100%'
  }
});

export default LineBarChart;
