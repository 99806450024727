import styled from '@emotion/styled';
import { white } from 'utils/colors';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${white};
  padding: 2.5rem;
  box-shadow: var(--gray-box-shadow);
  border-radius: 4px;
  flex: 1;
`;

export default Card;
