import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import Loading from 'components/atoms/Loading';
import NothingPlaceholder from 'components/atoms/NothingPlaceholder';
import BarChartCategoryCollapsible from 'components/molecules/BarChartCategoryCollapsible';
import CategoryBarChartRow from 'components/molecules/CategoryBarChartRow';
import { BarChartProps } from 'components/organisms/BarChart';
import useCategorizedSkills, {
  useCategoryPostingsBySkills,
  useSubcategoryPostingsSkills
} from 'hooks/useCategorizedSkills';

import { ariaFormattedLargeNumber } from 'utils/formatLargeNumber';
import { mediumGray } from 'utils/colors';
import { EnhancedSkill } from 'hooks/programInsights';

export const CategoryBarChart: React.FC<BarChartProps> = ({
  className,
  disabled,
  headingText,
  subheader,
  legend,
  data,
  isLoading: skillsIsLoading,
  columnHeaders,
  onRowClick,
  query,
  customChartErrorMessage
}) => {
  const skillsData = useMemo(() => Object.values(data), [data]);
  const categorizedSkills = useCategorizedSkills(skillsData);
  const { data: categoryData, isLoading: categoryDataIsLoading } = useCategoryPostingsBySkills(
    skillsData,
    query
  );
  const { data: subcategoryData, isLoading: subcategoryDataIsLoading } =
    useSubcategoryPostingsSkills(skillsData, query);

  const maxCategoryPostingsCount = Math.max(...Object.values(categoryData || {}));

  const [leftHeader, rightHeader] = columnHeaders;

  Object.values(categorizedSkills).forEach(subcategories => {
    Object.values(subcategories).forEach(skills =>
      skills.sort((a, b) => (b as EnhancedSkill).count - (a as EnhancedSkill).count)
    );
  });

  const isLoading = skillsIsLoading || categoryDataIsLoading || subcategoryDataIsLoading;

  return (
    <ChartWrapper className={className} aria-hidden={disabled} hidden={disabled}>
      <ChartHeadRows>
        <ChartTitleAndLedgerRow>
          <ChartTitleAndLedgerItem>
            <GraphCardHeading>{headingText}</GraphCardHeading>
            {subheader && <GraphCardSubheading>{subheader}</GraphCardSubheading>}
          </ChartTitleAndLedgerItem>

          {legend && <ChartTitleAndLedgerItem>{legend}</ChartTitleAndLedgerItem>}
        </ChartTitleAndLedgerRow>

        <HeaderRow>
          <HeaderItem>{leftHeader}</HeaderItem>
          <HeaderItem>{rightHeader}</HeaderItem>
        </HeaderRow>
      </ChartHeadRows>

      <Chart>
        {isLoading ? (
          <ChartContainer>
            <Loading />
          </ChartContainer>
        ) : Object.entries(skillsData).length ? (
          <div>
            {[...Object.entries(categoryData)]
              .sort(([, countA], [, countB]) => countB - countA)
              ?.map(([categoryName, value], i) => (
                <BarChartCategoryCollapsible
                  data-cy={`category-bar-chart_category-collapsible_${i}-${categoryName}`}
                  subcategoryPostingCounts={subcategoryData[categoryName]}
                  category={{ name: categoryName, postingCount: value }}
                  subcategories={categorizedSkills[categoryName]}
                  maxValue={maxCategoryPostingsCount}
                  renderSkillContent={(skill, index) => {
                    const ariaFormattedCount = ariaFormattedLargeNumber(skill.count);

                    return (
                      <StyledBarChartRow
                        data-cy={`category-bar-chart_skill-row_${index}-${skill.name}`}
                        count={skill.count}
                        maxValue={maxCategoryPostingsCount}
                        taughtState={skill.taughtState}
                        onClick={() => {
                          onRowClick && onRowClick({ id: skill.id, name: skill.name });
                        }}
                        aria-label={`${skill.name} skill. ${ariaFormattedCount} job postings.`}
                      >
                        <CategorizedRowLabel>{skill.name}</CategorizedRowLabel>
                      </StyledBarChartRow>
                    );
                  }}
                  buttonText={
                    <div>
                      <span>{categoryName}</span>
                    </div>
                  }
                  name={categoryName}
                  key={categoryName}
                />
              ))}
          </div>
        ) : (
          <NothingPlaceholder
            header={customChartErrorMessage?.headerText || 'No skills to show yet'}
            content={
              customChartErrorMessage?.contentText || 'Get started by selecting skills above.'
            }
          />
        )}
      </Chart>
    </ChartWrapper>
  );
};

const ChartHeadRows = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 0 2rem;
`;

const HeaderRow = styled.div`
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.27em;
  font-size: 1rem;
  font-weight: bold;
  color: ${mediumGray};
  display: flex;
  gap: 2rem;
`;

const ChartWrapper = styled.div`
  box-shadow: var(--gray-box-shadow);
  border-radius: 0.4rem;
  box-shadow: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ChartTitleAndLedgerRow = styled.div`
  display: flex;
  gap: 2rem;
`;

const ChartTitleAndLedgerItem = styled.div`
  flex: 1;
  min-width: 0;
`;

const GraphCardHeading = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const GraphCardSubheading = styled.div`
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
`;

const Chart = styled.div`
  overflow: auto;
  border-radius: 0 0 0.4rem 0.4rem;
  flex-grow: 1;
`;

const StyledBarChartRow = styled(CategoryBarChartRow)`
  &:hover span:first-of-type {
    text-decoration: underline;
  }
`;

const RowLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 1rem;
  font-size: 1.4rem;
`;

const CategorizedRowLabel = styled(RowLabel)`
  padding-left: 2rem;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  height: 100%;
`;

const HeaderItem = styled.div`
  flex-grow: 1;
  flex-basis: 1rem;
`;

export default CategoryBarChart;
