import ExcellentExport, { ConvertOptions, SheetOptions } from 'excellentexport';

const DEFAULT_OPTIONS: ConvertOptions = {
  openAsDownload: true,
  format: 'xlsx'
};

type ExtraConvertOptions = Partial<ConvertOptions> & { filename: string };

const exportExcel = (options: ExtraConvertOptions, sheets: SheetOptions[]): string | false => {
  const exportOptions = {
    ...DEFAULT_OPTIONS,
    ...options
  };

  return ExcellentExport.convert(exportOptions, sheets);
};

export default exportExcel;
