import { fetchWithCognitoToken } from './cognito';

const domain = process.env.REACT_APP_PROXY_URL;
const SIMILARITY_SKILL_MAX_LIMIT = 1000;

// Reference to the models similarity endpoint
// https://api.lightcast.io/apis/similarity
export const fetchRelatedOccupations = async (
  lotLevel: GranularLotLevel,
  skills: readonly string[],
  responseLimit: number
): Promise<SimilarityModelResponse[]> => {
  const model = lotLevel === 'occupation' ? 'skill-lotocc' : 'skill-lotspecocc';

  const slicedSkills = skills.slice(0, SIMILARITY_SKILL_MAX_LIMIT);
  const requestBody = {
    input: [...slicedSkills],
    limit: responseLimit
  };

  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/similarity/models/${model}`,
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(requestBody)
    }
  );

  const result = await response.json();

  return result.data;
};
