import { SheetOptions } from 'excellentexport';
import { QueryClient } from 'react-query';
import { getSkillCounts, searchCourses, searchPrograms } from 'services/curricularSkills';
import { fetchSkillsById } from 'services/skills';
import { depaginate } from 'utils/depaginate';

interface ProgramExportData {
  programs: unknown[][];
  skillCountsForCourses: SkillCountResponse;
  skillCountsForPrograms: SkillCountResponse;
}

const getAllProgramExportData = async (
  currentSite: string,
  queryClient: QueryClient,
  programsFilter?: ProgramSearchFilters
): Promise<ProgramExportData> => {
  const programs = await depaginate(
    queryClient,
    'programs',
    {
      limit: 100,
      filter: { site: { in: [currentSite] }, ...programsFilter }
    },
    searchPrograms
  ).catch(() => {
    throw new Error('Export Failed');
  });

  const courseIdsInPrograms = [
    ...new Set(
      programs.data.flatMap(program =>
        program.attributes.courses.reduce<string[]>((arr, course) => {
          // ID is a required field but somehow, there are courses with null IDs in the DB that will cause this export to fail if included
          if (course.id) {
            arr.push(course.id);
          }
          return arr;
        }, [])
      )
    )
  ];

  const skillsInAllPrograms = await depaginate(
    queryClient,
    'courses',
    {
      limit: 100,
      filter: { site: { in: [currentSite] }, ids: { in: courseIdsInPrograms } }
    },
    searchCourses
  )
    .then(courses => [...new Set(courses.data.flatMap(course => course.attributes.skills || []))])
    .catch(() => {
      throw new Error('Export Failed');
    });

  const skillIdsInAllPrograms = skillsInAllPrograms.map(skill => skill.id);

  const skillCountsForCourses = await queryClient.fetchQuery(
    ['course-skill-counts', skillsInAllPrograms, currentSite],
    () => getSkillCounts('course', skillIdsInAllPrograms, currentSite)
  );

  const skillCountsForPrograms = await queryClient.fetchQuery(
    ['program-skill-counts', skillsInAllPrograms, currentSite],
    () => getSkillCounts('program', skillIdsInAllPrograms, currentSite)
  );

  return {
    programs: programs.data.map(({ id, attributes }) => [
      id,
      attributes.title,
      attributes.groupType.label,
      attributes.url || '',
      ...attributes.courses
    ]),
    skillCountsForCourses,
    skillCountsForPrograms
  };
};

const formatProgramSheetData = (
  programs: unknown[][],
  courseAttribute: keyof CurricularIdAndTitleAndRequired
) =>
  programs.map(program => {
    const copiedProgram = [...program];
    const courses = copiedProgram.splice(4);
    const courseIdsOrTitles = (courses as CurricularIdAndTitleAndRequired[]).map(
      course => course[courseAttribute]
    );
    return [...copiedProgram, ...courseIdsOrTitles];
  });

export const exportAllPrograms =
  (currentSite: string, queryClient: QueryClient, programsFilter?: ProgramSearchFilters) =>
  async (): Promise<SheetOptions[]> => {
    const { programs, skillCountsForCourses, skillCountsForPrograms } =
      await getAllProgramExportData(currentSite, queryClient, programsFilter);
    const skillIds = Object.keys(skillCountsForCourses.data.counts);
    const skillsWithNames = await fetchSkillsById(skillIds);

    const programsWithCourseTitles = formatProgramSheetData(programs, 'title');
    const programsWithCourseIds = formatProgramSheetData(programs, 'id');
    const skillCountRows = skillsWithNames.map(skill => [
      skill.id,
      skill.name,
      skillCountsForCourses.data.counts[skill.id],
      skillCountsForPrograms.data.counts[skill.id]
    ]);
    return [
      {
        name: 'Programs with Course Names',
        from: {
          array: [
            ['UID', 'Program Name', 'Program Type', 'URL', 'Course Names'],
            ...programsWithCourseTitles
          ]
        }
      },
      {
        name: 'Programs with Course IDs',
        from: {
          array: [
            ['UID', 'Program Name', 'Program Type', 'URL', 'Course IDs'],
            ...programsWithCourseIds
          ]
        }
      },
      {
        name: 'Skills',
        from: {
          array: [
            ['Skill ID', 'Skill Name', 'Frequency in Courses', 'Frequency in Programs'],
            ...skillCountRows
          ]
        }
      }
    ];
  };
