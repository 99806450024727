import styled from '@emotion/styled';
import { SkillAreaData, VictoryCoordinate } from 'helpers/skillGapTrackerTable/graphDataFormatter';
import React from 'react';

export interface ChartTooltipProps {
  isOpen: boolean;
  skillAreaData: SkillAreaData | undefined;
  maxHeight?: number;
  position: VictoryCoordinate;
}

const ChartTooltip = React.forwardRef<HTMLDivElement, ChartTooltipProps>(
  ({ isOpen, skillAreaData, maxHeight, position }, ref) => {
    const condensedSkills =
      skillAreaData?.condensedSoughtSkills ||
      skillAreaData?.condensedTaughtSkills ||
      skillAreaData?.hypotheticalSkills ||
      [];

    if (!isOpen) {
      return <></>;
    }

    return (
      <Container ref={ref} data-test="tooltip-container">
        <FlyoutContainer
          position={position}
          maxHeight={maxHeight}
          totalHeight={window.innerHeight}
          data-test="flyout-container"
        >
          <Content>
            <Title data-test="tooltip-title">
              {skillAreaData?.skillName.substring(0, skillAreaData.skillName.length - 4)}
            </Title>
            <Divider />
            <List maxHeight={maxHeight} data-test="tooltip-list">
              {condensedSkills
                .map((item, index) => (
                  <ListItem key={index} data-test={`list-item-${index}`}>
                    {item.name}
                  </ListItem>
                ))
                .reverse()}
            </List>
          </Content>
          <Arrow data-test="tooltip-arrow" />
        </FlyoutContainer>
      </Container>
    );
  }
);

const Container = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlyoutContainer = styled.div<{
  position: VictoryCoordinate;
  maxHeight?: number;
  totalHeight: number;
}>`
  display: flex;
  position: absolute;
  width: 221px;
  left: ${props => props.position.x}px;
  bottom: ${props => props.totalHeight - props.position.y - 100}px;

  background: #204354;
  border-radius: 4px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 5px);
  height: calc(100% - 32px);
  padding-top: 5px;
  padding-left: 16px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 8px;
`;

const Divider = styled.hr`
  width: calc(100% - 10px);
  height: 1px;
  background-color: #d8d8d8;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
`;

const List = styled.ul<{ maxHeight?: number }>`
  width: inherit;
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : `200px`)};
  overflow-y: auto;
  list-style-type: none;
  padding-left: 0;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(99, 170, 204, 0.4);
    border-radius: 4px;
  }
`;

const ListItem = styled.li`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 0;
  color: #fff;
  font-size: 0.9em;
  text-align: left;

  &:last-child {
    border-bottom: none;
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #204354;
  bottom: -8px;
  left: 30%;
`;

export default ChartTooltip;
