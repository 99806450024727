import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useWatch } from 'react-hook-form';
import { SheetOptions } from 'excellentexport';

import LoadingButton from 'components/atoms/LoadingButton';

import { useProfileState } from 'store/profileStore';
import useExport from 'hooks/useExport';
import useCurrentNation from 'hooks/useCurrentNation';
import { fetchSkillsById } from 'services/skills';
import { getCoursesBySkills } from 'services/curricularSkills';
import { ReactComponent as Download } from 'images/cloudDownload.svg';
import { white } from 'utils/colors';

const StyledDownload = styled(Download)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;

  path {
    stroke: ${white};
  }
`;

interface ExportData extends ParsePostingExportButtonProps {
  postingText: string;
  currentSite: string;
  currentNation: 'us' | 'ca' | 'uk';
}

const formatExportData = async ({
  skillOverlapData,
  skillGapsData,
  chosenSkills,
  selectedPrograms,
  postingText,
  currentSite,
  chartSettings,
  regionFilter,
  jpaFilter
}: ExportData): Promise<SheetOptions[]> => {
  const [postingSkills, { data: coursesWithSkills }] = await Promise.all([
    fetchSkillsById(chosenSkills),
    getCoursesBySkills([...chosenSkills], currentSite)
  ]);

  const isSelectedSkillLevels = chartSettings.selectedSkillLevels?.length;
  const isSoftwareSelected = chartSettings.selectedSkillLevels?.includes('Software Skill');

  const overlapSkillRows = isSelectedSkillLevels
    ? Object.entries(skillOverlapData)
        .filter(skill => (isSoftwareSelected ? true : !skill[1].isSoftware))
        .filter(
          skill =>
            chartSettings.selectedSkillLevels?.includes(skill[1].type?.name || '') ||
            (isSoftwareSelected && skill[1].isSoftware)
        )
        .map(([id, { name, count }]) => [id, name, count])
    : Object.entries(skillOverlapData).map(([id, { name, count }]) => [id, name, count]);

  const gapSkillRows = isSelectedSkillLevels
    ? Object.entries(skillGapsData)
        .filter(skill => (isSoftwareSelected ? true : !skill[1].isSoftware))
        .filter(
          skill =>
            chartSettings.selectedSkillLevels?.includes(skill[1].type?.name || '') ||
            (isSoftwareSelected && skill[1].isSoftware)
        )
        .map(([id, { name, count }]) => [id, name, count])
    : Object.entries(skillGapsData).map(([id, { name, count }]) => [id, name, count]);

  const postingSkillsMap = postingSkills.reduce<{ [key: string]: { id: string; name: string } }>(
    (map, skill) => {
      map[skill.id] = skill;
      return map;
    },
    {}
  );

  const coursesWithSkillsRows = coursesWithSkills.map(
    ({ attributes: { title, courseId, skills } }) => {
      const skillNames = (skills || [])
        .filter(skill => chosenSkills.includes(skill.id))
        .map(skill => postingSkillsMap[skill.id]?.name);
      return [title, courseId, ...skillNames];
    }
  );

  const comparedPrograms = selectedPrograms.length
    ? selectedPrograms.map(program => program.title)
    : ['All Programs'];

  const allSavedRegions = regionFilter
    ? regionFilter.regions.map(region => region.name).join('|')
    : [];

  const educationLevelFilter = jpaFilter.edulevels_name
    ? jpaFilter.edulevels_name.join(' | ')
    : 'All Levels';

  const experienceRequiredFilter = jpaFilter?.min_years_experience
    ? jpaFilter?.min_years_experience.upper_bound === 1
      ? `${jpaFilter.min_years_experience.lower_bound} to ${jpaFilter.min_years_experience.upper_bound} year`
      : `${jpaFilter.min_years_experience.lower_bound} to ${jpaFilter.min_years_experience.upper_bound} years`
    : 'All years';

  const skillTypesFilter = chartSettings.selectedSkillLevels?.length
    ? chartSettings.selectedSkillLevels.join(' | ')
    : 'All Levels';

  const sheetsData = [
    {
      name: 'Filters',
      from: {
        array: [
          ['Region', 'Education Level', 'Experience Required', 'Skill Level', 'Compared Programs'],
          [
            allSavedRegions,
            educationLevelFilter,
            experienceRequiredFilter,
            skillTypesFilter,
            comparedPrograms
          ]
        ]
      }
    },
    {
      name: 'Job Posting Skills',
      from: {
        array: [
          ['Job Posting Description', '# of Skills', 'Skills ->'],
          [postingText, postingSkills.length, ...postingSkills.map(s => s.name)]
        ]
      }
    },
    {
      name: 'Job Posting Skill IDs',
      from: {
        array: [
          ['Job Posting Description', '# of Skills', 'Skill IDs -->'],
          [postingText, postingSkills.length, ...postingSkills.map(s => s.id)]
        ]
      }
    },
    {
      name: 'Skill Overlap',
      from: {
        array: [['Skill ID', 'Skill Name', '# of Job Postings With Skill'], ...overlapSkillRows]
      }
    },
    {
      name: 'Skill Gaps',
      from: {
        array: [['Skill ID', 'Skill Name', '# of Job Postings With Skill'], ...gapSkillRows]
      }
    },
    {
      name: 'Courses With Skills',
      from: {
        array: [
          ['Course Title', 'Course Code', 'Skills From Job Posting -->'],
          ...coursesWithSkillsRows
        ]
      }
    }
  ];

  return sheetsData;
};

interface ParsePostingExportButtonProps {
  skillOverlapData: {
    [key: string]: { name: string; count: number; type?: { name: string }; isSoftware?: boolean };
  };
  skillGapsData: {
    [key: string]: { name: string; count: number; type?: { name: string }; isSoftware?: boolean };
  };
  chosenSkills: readonly string[];
  selectedCourses: readonly { title: string; id: string }[];
  selectedPrograms: readonly { title: string; id: string }[];
  chartSettings: { selectedSkillLevels: string[] };
  regionFilter: RegionFilter | undefined;
  jpaFilter: JPAOptionsFilter;
}

const ParsePostingExportButton: React.FC<ParsePostingExportButtonProps> = props => {
  const postingText = useWatch({ name: 'postingText' });
  const { currentSite } = useProfileState();
  const [currentNation] = useCurrentNation();

  const exportDataFn = useCallback(() => {
    return formatExportData({
      postingText,
      currentSite,
      currentNation,
      ...props
    });
  }, [postingText, currentSite, currentNation, ...Object.values(props)]);

  const [exportCourse, { exportIsLoading }] = useExport({
    filename: `start_with_a_job_posting_export_${new Date().toLocaleDateString()}`,
    exportDataFn,
    isPreloading: false
  });

  return (
    <LoadingButton type="button" isLoading={exportIsLoading} onClick={() => exportCourse()}>
      <StyledDownload title="cloud download icon" />
      Export
    </LoadingButton>
  );
};

export default ParsePostingExportButton;
