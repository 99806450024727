import { fetchWithCognitoToken } from './cognito';
const domain = process.env.REACT_APP_PROXY_URL;

export const fetchSkillsByTerm = async (
  query: string,
  includeInfoUrl = false
): Promise<Skill[]> => {
  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/skills/versions/latest/skills?q=${encodeURIComponent(
      query
    )}&fields=id%2Cname${includeInfoUrl ? '%2CinfoUrl' : ''}`
  );
  if (!response.ok) {
    throw new Error('Could not fetch skills');
  }
  const { data } = await response.json();
  return data;
};

export const fetchRelatedSkills = async (
  skillIds: string[]
): Promise<{ data: { id: string; name: string; infoUrl?: string }[] }> => {
  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/skills/versions/latest/related?fields=id%2Cname`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ids: skillIds
      })
    }
  );

  if (!response.ok) {
    throw new Error('Error retrieving skills from Skills Classification API.');
  }

  return await response.json();
};

export const parseDocForSkills = async (doc: File, type?: string) => {
  let contentType;
  switch (type) {
    case 'docx':
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'pdf':
      contentType = 'application/pdf';
      break;
    case 'txt':
      contentType = 'text/plain';
      break;
    default:
      throw new Error('Error, detected an unsupported doc type');
  }

  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/skills/versions/latest/extract/trace?includeNormalizedText=true`,
    {
      method: 'POST',
      headers: {
        'Content-Type': contentType
      },
      body: doc
    }
  );
  if (!response.ok) {
    throw new Error('Error parsing text by Skills Classification API.');
  }

  return await response.json();
};

// Creates a set of skills for each text so that the fields (e.g. title, description, summary)
// can be used individually from each other
export const parseTextsForSkills = async (
  texts: string[]
): Promise<{ allSkills: PreprocessedSkill[]; skillsForTexts: PreprocessedSkill[][] }> => {
  // Sends all texts (e.g. title, description, summary) and processes it all together.
  const skills = await parseTextForSkills(texts.join(' '));
  let offset = 0;
  return {
    allSkills: skills,
    skillsForTexts: texts.map(text => {
      const offsetSkills = skills
        .map(skill => {
          return {
            ...skill,
            highlights: applyOffsetToForms(offset, text.length, skill.highlights),
            contexts: applyOffsetToForms(offset, text.length, skill.contexts)
          };
        })
        .filter(skill => !(skill.highlights.length === 0 && skill.contexts.length === 0));

      // reset the offset for the next text to apply to their forms
      offset += text.length + 1;
      return offsetSkills;
    })
  };
};

/**
 * filters out forms that fall in the range outside of the given offset and text length and returns an array of adjusted forms with
 * the source start and source ends according to input or text area to which they belong.
 * @param offset - The length of the previous text plus one (already calculated in parseTextsForSkills)
 * @param textLength - length of the text containing the parsed skills
 * @param forms - an array of preprocessed skills
 * @returns an adjusted array of preprocessed skills
 */
const applyOffsetToForms = (offset: number, textLength: number, forms: Form[]) =>
  forms
    .filter(form => form.sourceEnd > offset && form.sourceStart < offset + textLength)
    .map(form => ({
      ...form,
      sourceStart: form.sourceStart < offset ? 0 : form.sourceStart - offset,
      sourceEnd:
        form.sourceEnd > offset + textLength ? offset + textLength : form.sourceEnd - offset
    }));

export const parseTextForSkills = async (text: string): Promise<PreprocessedSkill[]> => {
  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/skills/versions/latest/extract/trace`,
    {
      method: 'POST',
      body: JSON.stringify({ text, includeNormalizedText: true }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json'
      }
    }
  );

  if (!response.ok) {
    throw new Error('Could not parse text for skills');
  }

  const data = await response.json();

  return preprocessTaggedSkills(text, data);
};

interface RawSkill {
  confidence: number;
  skill: {
    id: string;
    name: string;
    infoUrl: string;
  };
}

export interface PreprocessedRawSkill extends RawSkill {
  highlights: Form[];
  contexts: Form[];
}

export interface PreprocessedSkill extends Skill {
  highlights: Form[];
  contexts: Form[];
  confidence: number;
}

export interface Form {
  sourceStart: number;
  sourceEnd: number;
  type?: 'context' | 'highlight';
  value?: string;
}
interface SkillTraceResponse {
  data: {
    skills: RawSkill[];
    trace: {
      classificationData: {
        contextForms: Form[];
        skills: RawSkill[];
      };
      surfaceForm: Form;
    }[];
  };
}

const getByteOffsetTable = (text: string) => {
  const table: Record<number, number> = {};
  let bytes = 0;
  for (let i = 0; i < text.length; i++) {
    table[bytes] = i;
    const char = text.codePointAt(i);
    if (char) {
      if (char <= 0x007f) {
        bytes += 1;
      } else if (char <= 0x07ff) {
        bytes += 2;
      } else if (char <= 0xffff) {
        bytes += 3;
      } else {
        bytes += 4;
      }
    }
  }
  return table;
};

const getByteCountConverter = (text: string) => {
  const byteOffsetTable = getByteOffsetTable(text);
  return (form: Form): Form => {
    form.sourceStart = byteOffsetTable[form.sourceStart];
    form.sourceEnd = byteOffsetTable[form.sourceEnd];
    return form;
  };
};

const preprocessTaggedSkills = (
  text: string,
  { data: { skills, trace } }: SkillTraceResponse
): PreprocessedSkill[] => {
  const byteCountConverter = getByteCountConverter(`${text} `);
  const processed = skills.map(skill => {
    const matchedTraces = trace.filter(traceData =>
      traceData.classificationData.skills.find(innerSkill => innerSkill.skill.id === skill.skill.id)
    );
    const highlights: Form[] = [];
    const contexts: Form[] = [];

    matchedTraces.forEach(traceData => {
      highlights.push(byteCountConverter(traceData.surfaceForm));
      contexts.push(...traceData.classificationData.contextForms.map(byteCountConverter));
    });
    return { ...skill.skill, highlights, contexts, confidence: skill.confidence };
  });
  return processed;
};

type SkillFields = 'id' | 'type' | 'name' | 'isSoftware' | 'infoUrl' | 'category' | 'subcategory';

export const fetchSkillsById = async (
  skillIds: readonly string[],
  skillFields?: SkillFields[]
): Promise<Skill[]> => {
  if (!skillIds.length) {
    return [];
  }
  const fields = skillFields?.length ? `?fields=${skillFields.join(',')}` : '';
  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/skills/versions/latest/skills${fields}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: skillIds })
    }
  );

  if (!response.ok) {
    throw new Error('Could not match skills to names');
  }

  let { data } = await response.json();

  if (skillFields?.includes('category')) {
    data = data.map((skill: Skill) => {
      return {
        ...skill,
        category:
          skill.category?.name === 'NULL' ? { id: 0, name: 'Uncategorized' } : skill.category,
        subcategory:
          skill.subcategory?.name === 'NULL' ? { id: 0, name: 'Uncategorized' } : skill.subcategory
      };
    });
  }
  return data;
};
