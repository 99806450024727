import React from 'react';
import styled from '@emotion/styled';

import Button from 'components/atoms/Button';

import { white } from 'utils/colors';
import LoadingButton from 'components/atoms/LoadingButton';

const SubmitButton = styled(LoadingButton)`
  margin: 0 4rem 0 1rem;
  min-width: 8.5rem;
  justify-content: center;
`;

const BarWrapper = styled.div`
  height: 6rem;
  bottom: 0;
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  justify-content: center;
  will-change: transform;
`;

const StickyBar = styled.div`
  flex-shrink: 0;
  width: 100vw;
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: var(--gray-box-shadow);
`;

interface StickyButtonBarProps {
  deleteOption?: React.ReactNode;
  submitText: string;
  onSubmit?: () => void;
  cancelText?: string;
  isSubmitting: boolean;
  onCancel?: () => void;
  className?: string;
  disabled?: boolean;
}

const StickyButtonBar: React.FC<StickyButtonBarProps> = ({
  submitText,
  onSubmit,
  cancelText = 'Cancel',
  deleteOption,
  isSubmitting,
  onCancel,
  className,
  disabled
}) => {
  return (
    <BarWrapper>
      <StickyBar className={className}>
        {deleteOption}
        <Button
          data-cy="sticky-button-bar_discard-button"
          type="button"
          scheme="outline"
          onClick={onCancel}
        >
          {cancelText}
        </Button>
        <SubmitButton
          data-cy="sticky-button-bar_save-button"
          isLoading={isSubmitting}
          onClick={onSubmit}
          type={onSubmit ? 'button' : 'submit'}
          scheme="default"
        >
          {submitText}
        </SubmitButton>
      </StickyBar>
    </BarWrapper>
  );
};

export default StickyButtonBar;
