import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import Button from '../Button';

import { ReactComponent as Download } from 'images/cloudDownload.svg';

import { parseDocForSkills } from 'services/skills';
import { ahoy, white } from 'utils/colors';

interface Props {
  onFileParse?: (parsedText: string) => void;
}
const SyllabusUploadInput: React.FC<Props> = ({ onFileParse }) => {
  const [error, setError] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <HiddenInput
        type={'file'}
        ref={inputRef}
        accept=".pdf, .docx, .txt, application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        onChange={e => {
          if (!e.target.files?.length) {
            return;
          }
          const [fileToParse] = e.target.files;
          if (fileToParse.size > 10e6) {
            setError('Max file size is 10MB');
            return;
          }
          setError(undefined);
          const docType = fileToParse.name.split('.').pop();
          parseDocForSkills(fileToParse, docType).then(parsedText =>
            onFileParse?.(parsedText.data.normalizedText)
          );
        }}
      />
      <Button type="button" onClick={() => inputRef.current?.click()}>
        <StyledDownload /> Upload Document
      </Button>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

const HiddenInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`;

const ErrorMessage = styled.div`
  color: ${ahoy};
  font-weight: normal;
  font-size: 1.4rem;
`;

const StyledDownload = styled(Download)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;

  path {
    stroke: ${white};
  }
`;

export default SyllabusUploadInput;
