import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import throttle from 'lodash/throttle';

import Card from 'components/atoms/Card';
import InputLabel from 'components/atoms/InputLabel';
import HighlightedSingleLine from 'components/atoms/HighlightedSingleLine';
import HighlightedTextDiv from 'components/atoms/HighlightedTextDiv';
import { parseTextsForSkills, PreprocessedSkill } from 'services/skills';

import { CourseInput, CourseTextArea } from 'components/organisms/CourseForm';

import { bgLightGray } from 'utils/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 2;
  align-items: left;
`;

const HeaderStyles = css`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
`;

const TextWrapper = styled.div`
  margin-right: auto;
  white-space: nowrap;
  @media (min-width: 1025px) {
    display: flex;

    & > div:nth-of-type(2) {
      margin: 0 2rem;
    }
  }
`;

const Divider = styled.hr`
  position: relative;
  border: 1px solid ${bgLightGray};
  bottom: 1rem;
  width: 100%;
`;

const StyledInfo = styled.span`
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  padding: 1rem 0rem;
`;

const throttledParse = throttle(
  (text: string[], onParsedText) => onParsedText(parseTextsForSkills(text)),
  500
);

const GeneralCourseInfo: React.FC<{
  className?: string;
  onParseSkills: (skills: PreprocessedSkill[]) => void;
  onParseError?: React.Dispatch<React.SetStateAction<string | undefined>>;
  revealedSkill?: string;
  inputs?: CourseInput[];
  textAreas?: CourseTextArea[];
  course?: Omit<Course, 'site' | 'createdAt' | 'updatedAt' | 'subdomain'>;
}> = ({
  className,
  onParseSkills,
  onParseError,
  revealedSkill,
  inputs = [],
  textAreas = [],
  course
}) => {
  const [highlights, setHighlights] = React.useState<PreprocessedSkill[][]>([
    [],
    ...textAreas.map(() => [])
  ]);

  const handleParsedText = async (
    parsedTextPromise: Promise<{
      allSkills: PreprocessedSkill[];
      skillsForTexts: PreprocessedSkill[][];
    }>
  ) => {
    try {
      const parsedInformation = await parsedTextPromise;
      onParseSkills(parsedInformation.allSkills);
      setHighlights(parsedInformation.skillsForTexts);
    } catch (err) {
      onParseError && onParseError(JSON.stringify(err));
    }
  };

  const courseText = textAreas.map(textArea => course?.[textArea.key] || '');
  const highlightedTexts = [course?.title || '', ...courseText];

  React.useEffect(() => {
    if (highlightedTexts.some(text => text.length >= 3)) {
      throttledParse(highlightedTexts, handleParsedText);
    } else {
      onParseSkills([]);
    }
  }, highlightedTexts);

  return (
    <Card className={className}>
      <h3 css={HeaderStyles}>General Info</h3>
      <InputLabel htmlFor="title">Title</InputLabel>
      <HighlightedSingleLine highlights={highlights[0]} revealedSkill={revealedSkill}>
        {course?.title}
      </HighlightedSingleLine>
      <TextWrapper>
        {inputs.map(({ key, ...input }) => (
          <Wrapper key={key}>
            <InputLabel>{input.labelText}</InputLabel>
            <StyledInfo>{course?.[key]}</StyledInfo>
          </Wrapper>
        ))}
      </TextWrapper>
      {textAreas.map(({ key, ...textArea }, i) => (
        <div key={key}>
          <Divider />
          <label css={HeaderStyles} htmlFor={key}>
            {textArea.title}
          </label>
          <HighlightedTextDiv
            revealedSkill={revealedSkill}
            highlights={highlights[i + 1]}
            resize="none"
            text={course?.[key] || textArea.undefinedTextField}
            {...textArea}
          />
        </div>
      ))}
    </Card>
  );
};

export default GeneralCourseInfo;
