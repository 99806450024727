import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as CaretIcon } from 'images/caret.svg';

import { darkBlue } from 'utils/colors';

export interface CollapsibleProps {
  label?: string;
  buttonText?: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  headerContent?: (
    getButtonProps: () => React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    isOpen: boolean
  ) => React.ReactNode;
  name: string;
  className?: string;
  defaultIsOpen?: boolean;
  isControllerOpen?: boolean;
  children?: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  name,
  label,
  buttonText,
  headerContent,
  className,
  children,
  defaultIsOpen = false,
  isControllerOpen,
  ...props
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = React.useState(
    isControllerOpen || defaultIsOpen
  );
  const [contentHeight, setContentHeight] = React.useState(0);
  const collapsibleRef = React.useRef<HTMLDivElement>(null);

  // We need to observe the change in size when the collapsible is hidden and the height is set to 0.
  // In order to use the scroll height we need to subscribe to the scroll height changes.
  // You can see more about resize observer here: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver
  React.useEffect(() => {
    const elem = collapsibleRef.current;
    const observer = new ResizeObserver(entries => {
      const lastEntry = entries[entries.length - 1];
      setContentHeight(lastEntry.target.scrollHeight);
    });
    if (elem) {
      observer.observe(elem);
    }
    return () => {
      if (elem) {
        return observer.unobserve(elem);
      }
    };
  }, [collapsibleRef.current]);

  React.useEffect(() => {
    if (isControllerOpen !== undefined) {
      setIsCollapsibleOpen(isControllerOpen);
    }
  }, [isControllerOpen]);

  const buttonProps: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > = {
    type: 'button',
    onClick: () => setIsCollapsibleOpen(!isCollapsibleOpen),
    'aria-expanded': isCollapsibleOpen,
    'aria-controls': `${name}-region`,
    id: `${name}-button`
  };

  return (
    <div className={className}>
      <Flex className="collapsible" {...props}>
        {headerContent ? (
          headerContent(() => buttonProps, isCollapsibleOpen)
        ) : (
          <>
            <span>{label}</span>
            <StyledButton {...buttonProps}>
              {typeof buttonText === 'function' ? buttonText(isCollapsibleOpen) : buttonText}
              <StyledCaret isOpen={isCollapsibleOpen}>
                <CaretIcon />
              </StyledCaret>
            </StyledButton>
          </>
        )}
      </Flex>
      <Collapse
        id={`${name}-region`}
        role="region"
        aria-labelledby={`${name}-button`}
        maxHeight={contentHeight}
        className={isCollapsibleOpen ? 'isOpen' : undefined}
      >
        <div ref={collapsibleRef}> {children} </div>
      </Collapse>
    </div>
  );
};

// The 1px max height (as opposed to 0px) and the negative top margin are just a
// nasty hack to avoid a chrome rendering bug.  Once the bug is resolved the
// max-height can be set back to '0' when closed and the margin-top can be
// removed.
const Collapse = styled.div<{ maxHeight?: number }>`
  max-height: 1px;
  margin-top: -1px;
  overflow: hidden;
  ${({ maxHeight }) =>
    maxHeight && maxHeight > 0 ? 'transition: max-height 0.3s ease-in-out;' : ''};

  &.isOpen {
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'unset')};
    margin-top: unset;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
`;

export const StyledCaret = styled.span<{ isOpen: boolean }>`
  flex: 0;
  svg {
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    & > path {
      stroke: ${darkBlue};
    }
  }
`;

export default Collapsible;
