import React from 'react';

import { LocalStorageKey, AVAILABLE_KEYS } from 'hooks/useLocalStorage';

type DefaultValueGetter = (key: LocalStorageKey) => string | null;

export const DEFAULT_REGION_OBJECT: Record<JPANation, RegionFilter> = {
  us: {
    filterType: { value: 'nation', label: 'Nation' },
    regions: [{ id: 'us', name: 'United States' }]
  },
  ca: {
    filterType: { value: 'nation', label: 'Nation' },
    regions: [{ id: 'ca', name: 'Canada' }]
  },
  uk: {
    filterType: { value: 'nation', label: 'Nation' },
    regions: [{ id: 'uk', name: 'United Kingdom' }]
  }
};

const SetupLocalStorage: React.FC = () => {
  React.useMemo(() => {
    const getDefaultValue: DefaultValueGetter = key => {
      switch (key) {
        case 'regionFilters':
          return null;
        case 'currentNation':
          return null;
        case 'acceptSkillabiTermsOfService':
          return null;
      }
    };
    AVAILABLE_KEYS.forEach(key => {
      const defaultValue = getDefaultValue(key);
      if (defaultValue !== null) {
        const currentValue = localStorage.getItem(key);
        if (currentValue === null) {
          localStorage.setItem(key, defaultValue);
        }
      }
    });
  }, []);
  return <></>;
};

export default SetupLocalStorage;
