import React, { useMemo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { lightGray, navyBlue, darkBlue, blue } from 'utils/colors';
import { toTitleCase } from 'utils/case';

import {
  Container,
  ContainerPage,
  Header,
  Paragraph,
  SubHeader
} from 'helpers/pdfExport/components';
import { useCourseData } from './data';

export const Overview: React.FC = () => {
  const data = useCourseData();
  const categories: Skill[] = data?.skillsInProgram || [];

  const groupedCategories = useMemo(() => {
    const counts = categories.reduce((acc, skill) => {
      const categoryId = skill.category?.id;
      if (categoryId) {
        acc[categoryId] = (acc[categoryId] || 0) + 1;
      }
      return acc;
    }, {} as { [key: string]: number });

    const uniqueCategories = categories.reduce((acc, skill) => {
      const categoryId = skill.category?.id;
      if (categoryId && !acc.find(c => c.category?.id === categoryId)) {
        acc.push(skill);
      }
      return acc;
    }, [] as Skill[]);

    return uniqueCategories.map(skill => {
      const categoryId = skill.category?.id;
      return {
        ...skill,
        count: categoryId ? counts[categoryId] : 0
      };
    });
  }, [categories]);

  return (
    <ContainerPage>
      <Header
        text={toTitleCase(data.courseName)}
        contentsTableText={`Course Skills Overview: ${toTitleCase(data.courseName)}`}
        id={'course_skills_overview'}
        preventPropagation
      />
      <Text style={styles.subTitle}>
        Code: {data.courseCode || 'N/A'} - Credits: {categories.length}
      </Text>
      <Container height={20} />

      <SubHeader text="Course Summary" id="course_summary" preventPropagation />
      <Container height={10} />
      <Paragraph text={data.description.text} />
      <Container height={20} />

      <SubHeader text="Course Skills" id="course_skills" preventPropagation />
      <Container height={10} />
      <Paragraph
        text={
          'These market relevant skills have been identified for this course based on the course description, human input, and additional course content included at the back of this report. Skills can be updated based on feedback as needed.'
        }
      />
      <Container height={20} />

      <View style={styles.table}>
        <View style={styles.tableHeader} fixed>
          <Text style={[styles.tableHeaderText, styles.courseTitleHeader]}>Category</Text>
          <Text style={[styles.tableHeaderText, styles.skillsCell]}>Count</Text>
        </View>
        <View style={styles.table}>
          {groupedCategories.map((category, index) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                index < groupedCategories.length - 1 ? styles.rowDivider : {}
              ]}
            >
              <Text style={[styles.tableCell, styles.courseTitleCell]}>
                {category.category?.name}
              </Text>
              <Text style={[styles.tableCell, styles.skillsCell]}>{category.count}</Text>
            </View>
          ))}
        </View>
      </View>
    </ContainerPage>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: '0.25in',
    paddingBottom: '0.5in',
    paddingLeft: '0in',
    paddingRight: '0in'
  },
  pageTitle: {
    fontFamily: 'Inter',
    fontSize: 26,
    fontWeight: 'bold'
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: '0.1in',
    marginBottom: '0.1in'
  },
  segmentHeader: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: '0.1in',
    marginBottom: '0.1in',
    color: blue
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: 12,
    marginBottom: '0.25in'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderColor: lightGray
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: darkBlue,
    padding: 6
  },
  tableHeaderText: { fontFamily: 'Inter', fontSize: 12, fontWeight: 'bold', color: 'white' },
  rowDivider: {
    borderBottomWidth: 1,
    borderBottomColor: lightGray
  },
  courseTitleHeader: {
    width: '66%'
  },
  courseCodeHeader: {
    width: '17%'
  },
  skillsHeader: {
    textAlign: 'center',
    width: '17%'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 6,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: lightGray
  },
  verticalDivider: {
    height: '100%',
    borderLeftWidth: 1,
    borderLeftColor: lightGray
  },
  tableCell: {
    fontSize: 10
  },
  courseTitleCell: {
    width: '66%'
  },
  courseCodeCell: {
    width: '17%'
  },
  skillsCell: {
    textAlign: 'center',
    width: '17%'
  },
  restHeader: {
    marginBottom: '0.4in',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'bold',
    color: navyBlue
  }
});
