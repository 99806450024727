import React from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import InternalLink from 'components/atoms/InternalLink';
import Shimmer from 'components/atoms/Shimmer';

import { Crumb, getBreadCrumbPath } from 'helpers/breadcrumbs/breadcrumbs';
import { useCourse, useProgram } from 'hooks';

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const crumbs = React.useMemo(() => getBreadCrumbPath(location.pathname), [location.pathname]);
  return crumbs.length ? (
    <BreadcrumbsWrapper>
      {crumbs.map((crumb, i) => (
        <Breadcrumb key={`${crumb.name}-${i}`} crumb={crumb} isFinal={i === crumbs.length - 1} />
      ))}
    </BreadcrumbsWrapper>
  ) : null;
};

const Breadcrumb: React.FC<{ crumb: Crumb; isFinal: boolean }> = ({ crumb, isFinal }) => {
  let crumbName = crumb.name;
  const [type, crumbId] = crumb.name.split(':');
  const course = useCourse(crumbId, false, { enabled: type === 'cid' });
  const program = useProgram(crumbId, { enabled: type === 'pid' });

  if (course.data) {
    crumbName = course.data.data.attributes.title;
  }
  if (program.data) {
    crumbName = program.data.data.attributes.title;
  }

  const isLoading = course.isLoading || program.isLoading;
  return isLoading ? (
    <StyledSpan>
      <Shimmer width={20} />
      {!isFinal && <span>&gt;</span>}
    </StyledSpan>
  ) : (
    <StyledSpan>
      {crumb.href ? <StyledLink to={crumb.href}>{crumbName}</StyledLink> : crumbName}
      {!isFinal && <span>&gt;</span>}
    </StyledSpan>
  );
};

export default Breadcrumbs;

const StyledSpan = styled.span`
  font-size: 1.6rem;
  display: inline-flex;
  gap: 0.5rem;
`;

const StyledLink = styled(InternalLink)`
  font-size: 1.6rem;
  border-bottom: unset;
  max-width: 35rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
