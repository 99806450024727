import { useEffect, useRef } from 'react';

const oneDirectionScrollFinished = (
  scrollHeight: number,
  clientHeight: number,
  scrollStart: number
) => {
  const scrollDistance = scrollHeight - clientHeight;
  const thresholdScrollDistance = scrollDistance - 20;

  return scrollStart >= thresholdScrollDistance;
};

const possiblySetClass = (element: Element, className: string, shouldSet: boolean) => {
  if (shouldSet) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
};

export const scrollFinished = (element: HTMLElement) => {
  const scrollYFinished = oneDirectionScrollFinished(
    element.scrollHeight,
    element.clientHeight,
    element.scrollTop
  );
  const scrollXFinished = oneDirectionScrollFinished(
    element.scrollWidth,
    element.clientWidth,
    element.scrollLeft
  );

  possiblySetClass(element, 'scroll-y-finished', scrollYFinished);
  possiblySetClass(element, 'scroll-x-finished', scrollXFinished);

  // Because firefox cannot set styles on a parent based on a child, we add the class to the parent also
  // https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility

  if (element.parentElement) {
    possiblySetClass(element.parentElement, 'child-scroll-y-finished', scrollYFinished);
    possiblySetClass(element.parentElement, 'child-scroll-x-finished', scrollXFinished);
  }
};

export interface UseScrollFinishedParams {
  when?: boolean;
}

export interface UseScrollFinishedResult {
  // eslint-disable-next-line
  targetProps: any;
}

export function useScrollFinished(params: UseScrollFinishedParams): UseScrollFinishedResult {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!params.when || !ref.current) {
      return;
    }

    scrollFinished(ref.current);

    const globalResizeListener = () => {
      scrollFinished(ref.current as HTMLElement);
    };

    window.addEventListener('resize', globalResizeListener);

    return () => {
      window.removeEventListener('resize', globalResizeListener);
    };
  }, [params.when, ref.current]);

  if (!params.when) {
    return { targetProps: {} };
  }

  const targetProps = {
    // eslint-disable-next-line
    onScroll: (event: any) => scrollFinished(event.currentTarget),
    ref
  };

  return { targetProps };
}
