import React, { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useController } from 'react-hook-form';
import styled from '@emotion/styled';

import { ReactComponent as SkillsPlaceholderImage } from 'images/skillsByTextEmpty.svg';

import Card from 'components/atoms/Card';
import GeneralInfoForm from '../GeneralInfoForm';
import SkillsFromText from 'components/organisms/SkillsFromText';
import { mediumGray } from 'utils/colors';
import { PreprocessedSkill } from 'services/skills';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7rem;
`;

const StyledSpan = styled.span`
  display: block;
`;

const HeavySpan = styled(StyledSpan)`
  font-weight: 600;
  margin-top: 2rem;
`;

const EmptySkillsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: 6rem auto 0;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
`;

const StyledGeneralInfoForm = styled(GeneralInfoForm)`
  flex: 2;
  margin-right: 3rem;
`;

const StyledSkillsFromText = styled(SkillsFromText)`
  max-height: 52.2rem;
`;

interface TextParserProps {
  onChosenSkills: (skillIds: readonly string[]) => void;
  onParsedSkills: (skills: readonly PreprocessedSkill[]) => void;
  removeOnUnparsed?: boolean;
}

const TextParser: React.FC<TextParserProps> = ({
  onChosenSkills,
  onParsedSkills,
  removeOnUnparsed = false
}) => {
  const [revealedSkill, setRevealedSkill] = useState('');
  const [parsedSkills, setParsedSkills] = useState<readonly PreprocessedSkill[]>([]);
  const {
    field: { onChange, value: selectedSkills }
  } = useController<{ skills: readonly CurricularSkill[] }, 'skills'>({
    name: 'skills',
    defaultValue: []
  });

  useEffect(() => onChosenSkills(selectedSkills.map(s => s.id)), [selectedSkills]);
  useEffect(() => onParsedSkills(parsedSkills), [parsedSkills]);

  useDeepCompareEffect(() => {
    if (removeOnUnparsed) {
      onChange(
        selectedSkills.filter(selectedSkill =>
          parsedSkills.some(parsedSkill => parsedSkill.id === selectedSkill.id)
        )
      );
    }
  }, [parsedSkills]);

  return (
    <Row>
      <StyledGeneralInfoForm
        onParseSkills={setParsedSkills}
        revealedSkill={revealedSkill}
        textAreas={[
          {
            'data-cy': 'text-parser_job-posting-text-area',
            key: 'postingText',
            title: 'Job Posting Description',
            placeholder: 'Enter posting...',
            textareaHeight: '40rem'
          }
        ]}
        showTitleInput={false}
      />
      {!parsedSkills?.length ? (
        <Card>
          <CardHeading>Skills From Text</CardHeading>
          <EmptySkillsContents>
            <SkillsPlaceholderImage />
            <HeavySpan>No skills to show yet</HeavySpan>
            <StyledSpan>Start typing or paste in text to get started.</StyledSpan>
          </EmptySkillsContents>
        </Card>
      ) : (
        <StyledSkillsFromText
          selectedSkills={selectedSkills}
          onChange={onChange}
          parsedSkills={parsedSkills}
          revealedSkillId={revealedSkill}
          onReveal={skillId => {
            if (skillId === revealedSkill) {
              setRevealedSkill('');
            } else {
              setRevealedSkill(skillId);
            }
          }}
        />
      )}
    </Row>
  );
};

export default TextParser;
