import React from 'react';

import { Column, ContainerPage, Paragraph, SubHeader } from '../components';
import { darkGray } from 'utils/colors';

import { HighlightedText, useCourseData } from './data';

export const CourseContent: React.FC = () => {
  const data = useCourseData();

  return (
    <ContainerPage>
      <Column gap={30}>
        <SubHeader
          id="course_content"
          text="Course Content Text"
          contentsTableText="Additional Course Content Text"
        />

        <Column gap={10}>
          <Paragraph text="Summary" bold />
          <TextContent highlightedText={data.description} placeholder="No Summary" />
        </Column>

        <Column gap={10}>
          <Paragraph text="Learning Outcomes" bold />
          <TextContent highlightedText={data.learningOutcomes} placeholder="No Learning Outcomes" />
        </Column>

        <Column gap={10}>
          <Paragraph text="Description" bold />
          <TextContent highlightedText={data.syllabus} placeholder="No Description" />
        </Column>
      </Column>
    </ContainerPage>
  );
};

interface TextContentProps {
  highlightedText: HighlightedText;
  placeholder: string;
}

const TextContent: React.FC<TextContentProps> = ({ highlightedText, placeholder }) => {
  if (!highlightedText.text.length) {
    return <Paragraph text={placeholder} fontSize={10} color={darkGray} />;
  }

  return <Paragraph text={highlightedText.text} highlights={highlightedText.highlights} />;
};
