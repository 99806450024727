export type UniversalBenchmark = UniversalCustomBenchmark | UniversalOccupationBenchmark;

export interface UniversalCustomBenchmark {
  universalType: 'custom';
  attributes: Benchmark;
}

export interface UniversalOccupationBenchmark {
  universalType: 'occupation';
  attributes: LightcastOccupation;
}

export function getBenchmarkTitle(benchmark: UniversalBenchmark) {
  if (benchmark.universalType === 'custom') {
    return benchmark.attributes.title;
  }

  if (benchmark.universalType === 'occupation') {
    return benchmark.attributes.name;
  }

  throw new Error('Invalid universalType');
}

export function customBenchmarkToUniversal(benchmark: Benchmark): UniversalBenchmark {
  return { universalType: 'custom', attributes: benchmark };
}

export function occupationBenchmarkToUniversal(
  occupation: LightcastOccupation
): UniversalBenchmark {
  return { universalType: 'occupation', attributes: occupation };
}

export function ignoreUnselected<T extends BenchmarkAssociation>(associations: T[]) {
  return associations.filter(association => association.selected);
}

export function programHasBenchmarks(program: CurricularUnitResponse) {
  return !!ignoreUnselected(program.benchmarks).length || !!program.lotBenchmarks.length;
}

export function getOccupationIdsOfType(benchmarks: UniversalBenchmark[], type: LOTLevel) {
  const occupationIds: string[] = [];

  for (const benchmark of benchmarks) {
    if (benchmark.universalType !== 'occupation') {
      continue;
    }

    if (benchmark.attributes.type === type) {
      occupationIds.push(benchmark.attributes.id);
    }
  }

  return occupationIds;
}
