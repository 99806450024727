import React, { useState, useRef, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { FieldError } from 'react-hook-form';
import { useCombobox, useMultipleSelection, UseMultipleSelectionProps } from 'downshift';

import InputLabel from 'components/atoms/InputLabel';
import { Menu, MenuItem } from 'components/atoms/FlyoutMenu';

import mergeRefs from 'helpers/mergeRefs';
import { useOnKeyDownEffect } from 'hooks';
import { ReactComponent as CloseIcon } from 'images/closebtn.svg';
import { bgLightGray, borderGray, lightGray, ahoy, bgDarkGray } from 'utils/colors';
import Informative from '../Informative';

export interface MultiSelectProps<T> {
  labelText?: string;
  inputPlaceholder?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  inputOnBlur?: () => void;
  readOnly?: boolean;
  className?: string;
  dataCySelector?: string;
  items: T[];
  itemToString: (item: T | null) => string;
  itemToKey: (item: T) => string;
  useMultipleSelectionProps?: UseMultipleSelectionProps<T>;
  renderSelectedItem: (item: T) => React.ReactNode;
  renderItems: (item: T) => string | JSX.Element;
  error?: FieldError;
  keepOpenAfterSelection?: boolean;
  customFilter?: (items: T, inputValue: string) => boolean;
  onInputChange?: React.Dispatch<SetStateAction<string>>;
  renderInformativeContent?: () => React.ReactNode;
}

// eslint-disable-next-line comma-spacing
const MultiSelect = <T,>({
  labelText,
  inputPlaceholder,
  inputRef,
  inputOnBlur,
  readOnly = false,
  className,
  dataCySelector,
  useMultipleSelectionProps,
  items,
  itemToString,
  renderSelectedItem,
  renderItems,
  itemToKey,
  error,
  keepOpenAfterSelection,
  customFilter,
  onInputChange,
  renderInformativeContent
}: MultiSelectProps<T>): React.ReactElement => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  const internalInputRef = useRef<HTMLInputElement>(null);
  const {
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    getSelectedItemProps,
    getDropdownProps
  } = useMultipleSelection<T>({ ...useMultipleSelectionProps });

  const getFilteredItems = (itemsToFilter: T[]): T[] => {
    return itemsToFilter
      .filter(item => {
        return customFilter
          ? customFilter(item, inputValue)
          : itemToString(item).toLowerCase().includes(inputValue.toLowerCase());
      })
      .filter(item => {
        return (
          selectedItems.find(selectedItem => {
            return itemToKey(selectedItem) === itemToKey(item);
          }) === undefined
        );
      });
  };

  const {
    isOpen,
    getInputProps,
    getMenuProps,
    getItemProps,
    getLabelProps,
    highlightedIndex,
    openMenu,
    closeMenu,
    selectItem
  } = useCombobox({
    items: getFilteredItems(items),
    itemToString,
    inputValue,
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          // This prevent the default behavior of useCombobox to select highlighted items with the tab or shift-tab keys.
          return {
            isOpen: false,
            selectedItem: null,
            inputValue: '',
            highlightedIndex: -1
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: keepOpenAfterSelection || false // keep the menu open after selection.
          };
        default:
          return changes;
      }
    },
    onStateChange: changes => {
      const typedSelectItem = selectItem as (item: T | null) => void; // This is neccessary, since downshift typescript rules do not allow to call selectItem(null). This is probably a bug on downshift's side.
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange:
          changes.inputValue !== undefined && setInputValue(changes.inputValue);
          if (onInputChange && changes.inputValue !== undefined) {
            onInputChange(changes.inputValue);
          }
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          setInputValue('');
          if (changes.selectedItem) {
            addSelectedItem(changes.selectedItem);
            typedSelectItem(null); // We need to do this so that our item's state can change between state changes, otherwise the last selected item will become un-selectable again if removed.
          }
          break;
        default:
          break;
      }
    }
  });

  const handleEscape = React.useCallback(
    e => {
      if (isOpen) {
        closeMenu();
        e.stopPropagation();
      }
    },
    [isOpen]
  );

  useOnKeyDownEffect('Escape', handleEscape, ref);

  return (
    <MultiSelectWrapper className={className} ref={ref}>
      {labelText && (
        <LabelRow>
          <InputLabel {...getLabelProps()}>{labelText}</InputLabel>
          {renderInformativeContent && (
            <InformativeContainer>
              <Informative size="1.5rem" renderContent={renderInformativeContent} />
            </InformativeContainer>
          )}
        </LabelRow>
      )}
      <ItemsContainer
        onClick={e => {
          e.stopPropagation();
          internalInputRef.current?.focus();
        }}
        readOnly={readOnly}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        error={!!error}
        active={isActive}
      >
        {selectedItems.length > 0 &&
          selectedItems.map((item, index) => (
            <Pill key={itemToKey(item)} {...getSelectedItemProps({ selectedItem: item, index })}>
              <PillText>{renderSelectedItem(item)}</PillText>
              {!readOnly && (
                <RemoveButton
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    removeSelectedItem(item);
                  }}
                >
                  <StyledCloseIcon />
                </RemoveButton>
              )}
            </Pill>
          ))}

        <StyledInput
          {...getInputProps(
            getDropdownProps({
              preventKeyAction: isOpen,
              ref: inputRef ? mergeRefs(inputRef, internalInputRef) : internalInputRef,
              type: 'text',
              placeholder: inputPlaceholder,
              readOnly,
              onFocus: () => {
                if (!isOpen) {
                  openMenu();
                }
              },
              onBlur: inputOnBlur
            })
          )}
          data-cy={`multiselect_${dataCySelector}`}
        />
        <div {...getMenuProps()}>
          {getFilteredItems(items).length > 0 && isOpen && (
            <StyledMenu>
              {getFilteredItems(items).map((item: T, index: number) => (
                <StyledMenuItem
                  isSelected={highlightedIndex === index}
                  key={itemToKey(item)}
                  {...getItemProps({ item, index })}
                  data-cy={`multiselect_${dataCySelector}_item_${index}`}
                >
                  {renderItems(item)}
                </StyledMenuItem>
              ))}
            </StyledMenu>
          )}
        </div>
      </ItemsContainer>
      {error && <Error>{error.message}</Error>}
    </MultiSelectWrapper>
  );
};

export default MultiSelect;

const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ItemsContainer = styled.div<{ active: boolean; error: boolean; readOnly: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  max-height: 10rem;
  overflow-y: auto;
  border: ${props => (props.error ? `1px solid ${ahoy}` : `1px solid ${borderGray}`)};
  border-radius: 0.4rem;
  background: ${bgLightGray};
  padding: 0.2rem;
  ${({ active }) =>
    active &&
    `
      outline: 2px solid black;
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
  `}
  ${({ readOnly }) =>
    readOnly &&
    `
    opacity: 0.6;
    cursor: not-allowed;
  `}
`;

const LabelRow = styled.div`
  display: flex;
  gap: 0.2rem;
`;

const InformativeContainer = styled.div`
  margin: -0.2rem 0 -0.3rem 0;
`;

export const StyledMenu = styled(Menu)`
  position: absolute;
  list-style-type: none;
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  top: 100%;
  left: 0;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.09);
  padding: 0.5rem;
  z-index: 999;
  border-radius: 4px;
`;
export const StyledMenuItem = styled(MenuItem)<{ isSelected: boolean }>`
  padding: 2rem 0 2rem 2rem;
  font-size: 1.4rem;
  cursor: pointer;
  ${({ isSelected }) => isSelected && `background: ${bgDarkGray}; border-radius: 0.4rem;`}
`;

const Pill = styled.div`
  display: flex;
  background: ${lightGray};
  border-radius: 0.2rem;
  padding: 0.4rem 0.7rem;
  font-size: 1.4rem;
  margin: 0.4rem;
`;

const PillText = styled.span`
  max-width: 31rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled.button`
  padding-top: 10rem;
  margin-left: 0.6rem;
  background: ${lightGray};
  border: none;
  padding: 0 0.2rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 0.9rem;
  height: 0.9rem;
`;

const StyledInput = styled.input`
  display: flex;
  flex-grow: 1;
  height: 2.5rem;
  outline: none;
  border: none;
  background: ${bgLightGray};
  padding: 0.4rem 0.7rem;
  margin: 0.4rem;

  ::placeholder {
    font-style: italic;
  }
  :read-only,
  :disabled {
    pointer-events: none;
    opacity: 0;
  }
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: ${ahoy};
  position: absolute;
  top: 105%;
  left: 0;
`;
