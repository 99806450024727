import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { navyBlue } from 'utils/colors';

import { addContentsHeader } from '../contentsTable';

export interface HeaderProps {
  text: string;
  id: string;
  omitContentsTable?: boolean;
  preventPropagation?: boolean;
  contentsTableText?: string;
}

class HeaderAwareness {
  map = new Map<string, number[]>();

  registerHeader(id: string, pageNumber: number) {
    const headerPages = this.map.get(id);
    if (!headerPages) {
      return this.map.set(id, [pageNumber]);
    }

    headerPages.push(pageNumber);
  }

  getLowestNumber(id: string) {
    const pageNumbers = this.map.get(id) || [];

    return Math.min(...pageNumbers);
  }

  shouldWriteCont(id: string, pageNumber: number) {
    this.registerHeader(id, pageNumber);

    return pageNumber !== this.getLowestNumber(id);
  }
}

export const CenteredHeader: React.FC<HeaderProps> = props => {
  return (
    <CoreHeader
      props={props}
      level={0}
      style={{
        fontSize: 25,
        fontWeight: 'bold',
        fontFamily: 'Inter',
        textAlign: 'center'
      }}
    />
  );
};

export const Header: React.FC<HeaderProps> = props => {
  return (
    <>
      <CoreHeader
        props={props}
        level={0}
        style={{
          fontSize: 25,
          fontWeight: 'bold',
          fontFamily: 'Inter'
        }}
      />
    </>
  );
};

export const SubHeader: React.FC<HeaderProps> = props => {
  return (
    <CoreHeader
      props={props}
      level={1}
      style={{
        fontSize: 18,
        fontWeight: 'bold',
        fontFamily: 'Inter',
        color: navyBlue
      }}
    />
  );
};

const headerAwareness = new HeaderAwareness();

interface CoreHeaderProps {
  level: number;
  style: Style;
  props: HeaderProps;
}

const CoreHeader: React.FC<CoreHeaderProps> = ({ style, level, props }) => {
  return (
    <Text
      fixed={!props.preventPropagation}
      id={props.id}
      style={style}
      render={({ pageNumber }) => {
        if (!props.omitContentsTable) {
          addContentsHeader({
            label: props.contentsTableText || props.text,
            pageNumber,
            id: props.id,
            level
          });
        }

        if (!props.preventPropagation && headerAwareness.shouldWriteCont(props.id, pageNumber)) {
          return `${props.text} (cont.)`;
        }

        return props.text;
      }}
    />
  );
};
