import React from 'react';
import styled from '@emotion/styled';

import { definingColor, distinguishingColor, necessaryColor } from 'utils/colors';
import { SkillSignificance } from 'helpers/benchmarkSkillInfo';

const meta = {
  defining: { text: 'Def.', color: definingColor, textColor: 'white' },
  distinguishing: { text: 'Dis.', color: distinguishingColor, textColor: 'white' },
  necessary: { text: 'Nec.', color: necessaryColor, textColor: 'black' }
};

const SkillSignificanceBadge: React.FC<{ significance: SkillSignificance }> = ({
  significance
}) => {
  const typeMeta = meta[significance];

  return (
    <Badge color={typeMeta.color} textColor={typeMeta.textColor}>
      {typeMeta.text}
    </Badge>
  );
};

const Badge = styled.div<{ color: string; textColor: string }>`
  background: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  border-radius: 0.4rem;
  padding: 0.3rem;
`;

export default SkillSignificanceBadge;
