import React from 'react';
import styled from '@emotion/styled';

import DropdownFlyout, {
  DropdownFlyoutProps,
  DropdownSelectable
} from 'components/molecules/DropdownFlyout';
import { FieldError } from 'react-hook-form';

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))`
  width: 100%;
`;

interface UserRolesDropdownProps {
  error?: FieldError;
  defaultValue: DropdownSelectable;
  onSelect?: (selection: DropdownSelectable) => unknown;
  items: DropdownSelectable[];
}
const UserRolesDropdown: React.FC<UserRolesDropdownProps> = ({
  error,
  onSelect,
  items,
  defaultValue
}) => {
  return (
    <StyledDropdownFlyout
      dropDownName="user-roles"
      labelText="Roles"
      items={items}
      dropDownContentWidth="100%"
      error={error}
      onSelect={onSelect}
      defaultValue={defaultValue}
    />
  );
};

export default UserRolesDropdown;
