import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import CountBadge from 'components/atoms/CountBadge';
import Checkbox from 'components/atoms/Checkbox';
import { borderGray, darkGray } from 'utils/colors';

export interface Column<T, USortableColumnId = unknown> {
  id: keyof T;
  renderHeader: (props: {
    onSort?(columnId?: USortableColumnId): void;
    sortedColumn?: { sortType: USortableColumnId; sortOrder: 'ascending' | 'descending' };
  }) => JSX.Element;
  renderCell(row: T, i: number): JSX.Element;
}

interface TableProps<T, USortableColumnId> {
  tableCaption: string;
  columns: Column<T, USortableColumnId>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (T | any)[];
  className?: string;
  onSort?: (column: USortableColumnId) => void;
  sortedColumn?: { sortType: USortableColumnId; sortOrder: 'ascending' | 'descending' };
  onSelect?: (id: string) => void;
  selected?: string[];
  selectPage?: () => void;
  onRowClick?: (row: T) => void;
  monitorTbodyScroll?: boolean;
  EmptyComponent?: React.ReactNode;
  rowAriaLabel?: string;
  tableProps?: React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >;
  renderCustomRow?: (
    row: T
  ) => React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > | void;
}

const Table = <T extends { id: string; title?: string }, USortableColumnId>({
  columns,
  data,
  className,
  onSort,
  sortedColumn,
  onSelect,
  selected,
  tableCaption,
  selectPage,
  onRowClick,
  EmptyComponent,
  rowAriaLabel,
  renderCustomRow,
  tableProps
}: TableProps<T, USortableColumnId>): React.ReactElement => {
  return (
    <table
      {...tableProps}
      role="table"
      className={className}
      data-cy={`table_${tableCaption.toLowerCase().replaceAll(' ', '-')}`}
    >
      <caption style={{ visibility: 'hidden', height: 0 }}>{tableCaption}</caption>
      <thead>
        <tr role="row">
          {onSelect && selected && selectPage && (
            <th>
              <Checkbox
                id="select-all"
                onChange={() => {
                  selectPage();
                }}
                title={`select all`}
                aria-label={`select all`}
                checked={data.length > 0 && !data.filter(({ id }) => !selected.includes(id)).length}
              />
            </th>
          )}
          {columns.map(column => column.renderHeader({ onSort, sortedColumn }))}
        </tr>
      </thead>
      <tbody>
        {data.length ? (
          data.map(row => {
            return (
              <>
                {(renderCustomRow && renderCustomRow(row)) || (
                  <tr
                    aria-label={rowAriaLabel}
                    key={row.id}
                    onClick={onRowClick && (() => onRowClick(row))}
                    data-cy={`table_row_${row.id}`}
                  >
                    {onSelect && selected && (
                      <td>
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={e => e.stopPropagation()}
                          onChange={() => {
                            onSelect(row.id);
                          }}
                          title={row.title}
                          aria-label={`checkbox for ${row.title}`}
                          checked={selected.includes(row.id)}
                        />
                      </td>
                    )}
                    {columns.map((column, i) => column.renderCell(row, i))}
                  </tr>
                )}
              </>
            );
          })
        ) : (
          <tr className="empty-table">
            <td colSpan={100}>{EmptyComponent}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;

export const svgStyles = css`
  margin-right: 0.5rem;
`;

export const InvisibleButton = styled.button`
  background: inherit;
  border: none;
`;

export const HeaderCell = styled.th<{ width?: string }>`
  min-width: ${props => props.width || 'inherit'};
  padding: 0 1.5rem;
`;

export const Cell = styled.td`
  padding: 3rem 1.5rem;
  color: ${darkGray};
`;

export const TableNumber = styled(CountBadge)`
  font-weight: normal;
  font-size: 1.4rem;
  background: ${borderGray};
  padding: 0.3rem 0.7rem;
  margin: 0;
  display: inline;
`;
