import { DropdownSelectable } from 'hooks/useDropdown';
import makeStore from './makeStore';

export interface ProgramDataState {
  chartSettings: ChartSettings;
  gapTrackerSettings: SkillGapTrackerSettings;
}

export interface ChartSettings {
  selectedSkillLevels: SkillClassification[];
  isDisplayingCategories: boolean;
  filterPostingsByBenchmarks?: boolean;
  skillTagFilter?: SkillTagsFilterOptions;
}

export interface SkillGapTrackerSettings {
  numberOfSkillsToShow: DropdownSelectable<number>;
  hypotheticalSkills: DropdownSelectable<string>[];
  chartMetric?: JPATotalsMetric;
}

export type SkillTagsFilterOptions = CurricularSkillTags | undefined;

export const NUMBER_OF_SKILLS_OPTIONS: DropdownSelectable<number>[] = [
  {
    label: 'Simple View',
    value: 0
  },
  {
    label: 'Detailed View',
    value: 10
  }
];

const DEFAULT_STATE: ProgramDataState = {
  chartSettings: {
    selectedSkillLevels: [],
    isDisplayingCategories: false,
    filterPostingsByBenchmarks: true,
    skillTagFilter: undefined
  },
  gapTrackerSettings: {
    numberOfSkillsToShow: NUMBER_OF_SKILLS_OPTIONS[1],
    hypotheticalSkills: [],
    chartMetric: 'unique_postings'
  }
};

export type ProgramDataStateAction =
  | ProgramDataSetChartSettings
  | ProgramDataSetGapTrackerSettings
  | ProgramDataReset;

interface ProgramDataSetChartSettings {
  type: 'SET_CHART_SETTINGS';
  value: ChartSettings;
}

interface ProgramDataSetGapTrackerSettings {
  type: 'SET_GAP_TRACKER_SETTINGS';
  value: SkillGapTrackerSettings;
}

interface ProgramDataReset {
  type: 'RESET';
}

export const [ProgramDataProvider, useProgramDataDispatch, useProgramData] = makeStore<
  ProgramDataState,
  ProgramDataStateAction
>(
  (state, action) => {
    if (action.type === 'SET_CHART_SETTINGS') {
      return {
        ...state,
        chartSettings: action.value
      };
    }

    if (action.type === 'SET_GAP_TRACKER_SETTINGS') {
      return {
        ...state,
        gapTrackerSettings: action.value
      };
    }

    if (action.type === 'RESET') {
      return { ...DEFAULT_STATE };
    }

    throw new Error('Unknown action type');
  },
  { ...DEFAULT_STATE }
);
