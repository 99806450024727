import React from 'react';
import dayjs from 'dayjs';

import gradientImage from 'images/gradient.png';
import { ahoy, black, white } from 'utils/colors';

import {
  Container,
  FullImage,
  Paragraph,
  Row,
  Padding,
  Column,
  LightcastLogo,
  ContainerPage
} from './components';
import { Stack, StackItem } from './components/Stack';

export interface CoverProps {
  institution: string;
  subtitle: string;
  image: string;
}

export const Cover: React.FC<CoverProps> = ({ subtitle, institution, image }) => {
  return (
    <ContainerPage omitFooter omitPadding>
      <Container height={400}>
        <Stack>
          <StackItem inset={0}>
            <FullImage src={gradientImage} />
          </StackItem>

          <StackItem right={30} top={30}>
            <FullImage src={image} width={235} />
          </StackItem>
        </Stack>
      </Container>

      <Container color={black} height={null}>
        <Padding paddingVertical={45}>
          <Column gap={20} align="center" justify="center">
            <Row gap={5}>
              <Paragraph text="Skillabi shows how the" color={white} fontSize={15} bold />
              <Paragraph text="skills you teach" color={ahoy} fontSize={15} bold />
              <Paragraph text="align with the" color={white} fontSize={15} bold />
              <Paragraph text="skills employers want" color={ahoy} fontSize={15} bold />
            </Row>

            <Paragraph
              text="So you can optimize curriculum to meet the evolving needs of learners."
              color="white"
            />
          </Column>
        </Padding>
      </Container>

      <Padding paddingTop={60}>
        <Column gap={80}>
          <Column align="center" gap={15}>
            <Column align="center">
              <Paragraph text={institution} fontSize={20} />
              <Paragraph text={subtitle} fontSize={10} />
            </Column>

            <Paragraph text={dayjs(Date.now()).format('M/DD/YY')} fontSize={10} />
          </Column>

          <LightcastLogo />
        </Column>
      </Padding>
    </ContainerPage>
  );
};
