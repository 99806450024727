import React from 'react';
import styled from '@emotion/styled';

import LoadingButton from 'components/atoms/LoadingButton';
import Button from 'components/atoms/Button';
import MessageModal from 'components/molecules/MessageModal';

import { useDeleteProgramTypes } from 'hooks/mutateHooks';

const StyledMessageModal = styled(MessageModal)`
  white-space: pre-line;
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 6.5rem;
`;

const DeleteProgramTypeModal: React.FC<{
  programType: string;
  closeModal: () => void;
  associatedGroups: number;
}> = ({ programType, closeModal, associatedGroups }) => {
  const modalTitle = associatedGroups > 0 ? 'Cannot Delete Program Type' : 'Delete Program Type?';
  const modalMessage =
    associatedGroups > 0
      ? 'You cannot delete a program type that is currently associated with your programs. \nPlease update any programs that use this program type before deleting.'
      : 'This program type will no longer be an option when creating or editing programs.';
  const modalButtonText = associatedGroups > 0 ? 'Back to Program Types' : 'Cancel';

  const { mutate: removeProgramType, isLoading } = useDeleteProgramTypes(programType);

  return (
    <StyledMessageModal title={modalTitle} message={modalMessage}>
      <ButtonsWrapper>
        <StyledButton scheme="outline" type="button" onClick={closeModal}>
          {modalButtonText}
        </StyledButton>
        {associatedGroups === 0 && (
          <>
            <StyledLoadingButton
              type="button"
              scheme="danger"
              isLoading={isLoading}
              onClick={() => {
                removeProgramType();
              }}
            >
              Delete Program Type
            </StyledLoadingButton>
          </>
        )}
      </ButtonsWrapper>
    </StyledMessageModal>
  );
};

export default DeleteProgramTypeModal;
