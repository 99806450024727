import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import InternalLink from 'components/atoms/InternalLink';
import BenchmarkCarousel from 'components/organisms/BenchmarkCarousel';
import SkillInsights from 'components/molecules/SkillInsights';

import { ReactComponent as Pencil } from 'images/pencil-mini.svg';
import { navyBlue } from 'utils/colors';
import { useFilteredTaughtVsSoughtSkills, useSkillDetails } from 'hooks/programInsights';
import { useProgramCustomBenchmarks } from 'hooks/benchmarks';
import { useProgramData, useProgramDataDispatch } from 'store/programData';

const getFacetForLOTLevel = (level: LOTLevel) => {
  return `lot_${level}` as
    | 'lot_career_area'
    | 'lot_occupation_group'
    | 'lot_occupation'
    | 'lot_specialized_occupation';
};

export const applyBenchmarkToFilters = (
  [skillsFilter, filters]: [JPAOptionsFilter | undefined, JPAOptionsFilter[]],
  benchmark: LightcastOccupation | Benchmark,
  baseFilter: JPAOptionsFilter
): [JPAOptionsFilter | undefined, JPAOptionsFilter[]] => {
  switch (benchmark.type) {
    case 'skillsFromTextBenchmark': {
      skillsFilter = skillsFilter || baseFilter;
      const { skills: oldSkills = [] } = skillsFilter;
      const skills = new Set([...benchmark.facets.skills, ...oldSkills]);
      return [{ ...skillsFilter, skills: [...skills] }, filters];
    }
    case 'customJPABenchmark':
      return [skillsFilter, [...filters, { ...baseFilter, ...benchmark.facets }]];
    default:
      return [
        skillsFilter,
        [...filters, { ...baseFilter, [getFacetForLOTLevel(benchmark.type)]: [benchmark.id] }]
      ];
  }
};

export const noSkillsWithFiltersMessage = {
  headerText: 'No skills found',
  contentText: 'Change selections above to expand search'
};

const ProgramInsights: React.FC<{
  program: SingleCurriculum<CurricularUnitResponse>;
  setRegionFilter: (region: RegionFilter) => void;
  regionFilter?: RegionFilter;
  setJPAFilter: React.Dispatch<React.SetStateAction<JPAOptionsFilter>>;
  postingsQuery: JPAOptionsFilter;
  jpaFilter: JPAOptionsFilter;
  currentTimeframe: number;
  setCurrentTimeframe(timeframe: number): void;
}> = ({
  program,
  setRegionFilter,
  regionFilter,
  setJPAFilter,
  postingsQuery,
  jpaFilter,
  currentTimeframe,
  setCurrentTimeframe
}) => {
  const programId = program.data.id;
  const location = useLocation();
  const { chartSettings } = useProgramData();
  const programDataDispatch = useProgramDataDispatch();

  const { data: customBenchmarks, isLoading: customBenchmarksIsLoading } =
    useProgramCustomBenchmarks(programId);

  const savedBenchmarksLength =
    program.data.attributes.lotBenchmarks.length + customBenchmarks.length;

  useEffect(() => {
    savedBenchmarksLength > 10
      ? programDataDispatch({
          type: 'SET_CHART_SETTINGS',
          value: { ...chartSettings, filterPostingsByBenchmarks: false }
        })
      : programDataDispatch({
          type: 'SET_CHART_SETTINGS',
          value: { ...chartSettings, filterPostingsByBenchmarks: true }
        });
  }, []);

  const { data: taughtAndSoughtData, isLoading: taughtSoughtFilteredSkillsLoading } =
    useFilteredTaughtVsSoughtSkills(program.data.id, postingsQuery, chartSettings);

  const { skills: taughtAndSoughtSkills, skillsLength: taughtAndSoughtSkillsLength } =
    taughtAndSoughtData;

  const { data: skillDetails, isLoading: skillDetailsIsLoading } = useSkillDetails({
    chartSettings,
    programId,
    currentTimeframe,
    jpaFilters: postingsQuery
  });

  const isTaughtVsSoughtChartLoading =
    taughtSoughtFilteredSkillsLoading || customBenchmarksIsLoading;

  const isSkillDetailsChartLoading =
    isTaughtVsSoughtChartLoading || skillDetailsIsLoading || customBenchmarksIsLoading;

  return (
    <>
      <Flex>
        <InsightHeading>Compare Program Skills Against Market Benchmarks:</InsightHeading>
      </Flex>

      <CardContainer>
        <StyledBenchmarksLink
          data-cy="program-insights_edit-benchmarks-link"
          to={location.pathname.concat('/benchmarks/')}
        >
          <StyledPencil />
          Change Benchmarks
        </StyledBenchmarksLink>

        <BenchmarkCarousel programId={programId} />
      </CardContainer>

      <SkillInsights
        filteredSkills={taughtAndSoughtSkills}
        savedBenchmarksLength={savedBenchmarksLength}
        setRegionFilter={setRegionFilter}
        setRawTimeframe={setCurrentTimeframe}
        regionFilter={regionFilter}
        chartSettings={chartSettings}
        setChartSettings={settings =>
          programDataDispatch({
            type: 'SET_CHART_SETTINGS',
            value: settings
          })
        }
        postingsQuery={postingsQuery}
        setJPAFilter={setJPAFilter}
        jpaFilter={jpaFilter}
        reducedSkillsLength={{
          taught: taughtAndSoughtSkillsLength.taughtSkills,
          sought: taughtAndSoughtSkillsLength.soughtSkills
        }}
        isTaughtVsSoughtChartLoading={isTaughtVsSoughtChartLoading}
        isSkillDetailChartLoading={isSkillDetailsChartLoading}
        skillDetails={skillDetails}
        programId={programId}
      />
    </>
  );
};

const Flex = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: flex-end;
`;

const CardContainer = styled(Flex)`
  align-items: stretch;
  min-height: 14.8rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
  position: relative;
`;

const StyledPencil = styled(Pencil)`
  margin-right: 1rem;
`;

const InsightHeading = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  margin-right: 1.5rem;
`;

const StyledBenchmarksLink = styled(InternalLink)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  border: 2px dashed #62b3ed;
  color: ${navyBlue};
  border-radius: 0.4rem;
  font-size: 1.4rem;
  min-width: 25rem;
  height: 16.5rem;
  background: inherit;
  padding: 0;

  transition: background 0.2s ease-in;
  &:hover {
    background: #d6f0ff;
  }
`;

export default ProgramInsights;
