import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { DropdownSelectable, useDropdown } from 'hooks';
import { bgDarkGray, darkGray } from 'utils/colors';
import RoundSelectionFlyoutButton from 'components/atoms/RoundSelectionFlyoutButton';

interface SelectTimeframeDropdownProps {
  onChange: (value: { rawValue: number; range: { start: string; end: string } }) => void;
  options?: {
    granularity: 'days' | 'months' | 'years';
    values: number[];
    defaultValue?: number;
  };
  floating?: boolean;
  dropdownName?: string;
}

interface InnerTimeframeDropdownProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectTimeframeDropdown: React.FC<SelectTimeframeDropdownProps> = ({
  onChange,
  options = { granularity: 'months', values: [3, 6, 12, 18, 24], defaultValue: 12 },
  floating,
  dropdownName = `${options.granularity}-timeframe-dropdown`
}) => {
  const dropdownOptions = options.values.map(o => {
    return { label: `${o} ${options.granularity}`, value: o };
  });

  const [currentValue, setCurrentValue] = React.useState<DropdownSelectable<number>>(
    dropdownOptions.find(o => o.value === options.defaultValue) || dropdownOptions[0]
  );

  const getTimeframeRange = (value: number, granularity: 'days' | 'months' | 'years') => {
    const today = dayjs();
    return {
      start: today.subtract(value, granularity).format('YYYY-MM-DD'),
      end: today.format('YYYY-MM-DD')
    };
  };

  const InnerDropdown: React.FC<InnerTimeframeDropdownProps> = ({ setIsOpen }) => {
    const onSelect = (val: DropdownSelectable<number>) => {
      setCurrentValue(val);
      setIsOpen(false);
      onChange({ rawValue: val.value, range: getTimeframeRange(val.value, options.granularity) });
    };

    const { getItemProps, getListboxProps } = useDropdown(dropdownName, onSelect, currentValue);

    return (
      <FixedHeightContainer {...getListboxProps()}>
        {dropdownOptions.map((item, index) => (
          <DropDownItem
            data-cy={`timeframe-dropdown-flyout_item_${item.value}`}
            key={`Menu-item-${index}`}
            {...getItemProps(item, index)}
          >
            {item.label}
          </DropDownItem>
        ))}
      </FixedHeightContainer>
    );
  };

  return (
    <StyledRoundedFlyoutButton
      aria-label="select timeframe"
      data-cy="select-timeframe-dropdown_flyout-button"
      buttonText="Timeframe: "
      selectionText={`Last ${currentValue.label}`}
      floating={floating}
    >
      {({ setIsOpen }) => <InnerDropdown setIsOpen={setIsOpen} />}
    </StyledRoundedFlyoutButton>
  );
};

export default SelectTimeframeDropdown;

const StyledRoundedFlyoutButton = styled(RoundSelectionFlyoutButton)<{ floating?: boolean }>`
  border-radius: 2rem;
  box-shadow: ${({ floating }) => (floating ? '0 0 4px rgba(0, 0, 0, 10%)' : 'none')};
`;

const FixedHeightContainer = styled.ul`
  padding: 0;
  margin: 0;
  max-height: 25rem;
  overflow-y: auto;
`;

const DropDownItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0.7rem 1rem;
  font-size: 1.4rem;
  color: ${darkGray};
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${bgDarkGray};
    border-radius: 0.4rem;
  }
`;
