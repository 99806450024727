import styled from '@emotion/styled';

import { darkSaphire, white, ahoy } from 'utils/colors';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'button' | 'submit' | 'reset';
  // If we use a button as a `Link` (from react-router-dom) we need to pass it a `to` prop
  to?: string;
  scheme?: keyof typeof themes;
}

const themes = {
  default: { background: darkSaphire, color: white, border: 'none' },
  outline: {
    background: 'transparent',
    color: darkSaphire,
    border: `1px solid ${darkSaphire}`
  },
  danger: { background: ahoy, color: white, border: 'none' }
};

const Button = styled.button<ButtonProps>`
  background: ${({ scheme }) => (scheme ? themes[scheme].background : themes.default.background)};
  color: ${({ scheme }) => (scheme ? themes[scheme].color : themes.default.color)};
  border-radius: 4px;
  height: 4rem;
  padding: 1rem 1.6rem;
  align-items: center;
  font-size: 1.4rem;
  border: ${({ scheme }) => (scheme ? themes[scheme].border : themes.default.border)};
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default Button;
