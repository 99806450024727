import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { ReactComponent as CheckedIcon } from 'images/checkbox.svg';
import { ReactComponent as UncheckedIcon } from 'images/uncheckedbox.svg';

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelText?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ labelText, ...props }) => {
  return (
    <label
      css={css`
        position: relative;
        height: 1.6rem;
        display: flex;
        align-items: center;
      `}
    >
      <input
        css={css`
          position: absolute;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          margin: 0;
          width: 1.6rem;
          height: 1.6rem;
        `}
        type="checkbox"
        aria-checked={props.checked}
        {...props}
      />
      {props.checked ? (
        <CheckedIcon
          css={css`
            width: 1.6rem;
            pointer-events: none;
            input[type='checkbox']:active ~ & {
              opacity: 0.8;
            }
            input[type='checkbox']:disabled ~ & {
              opacity: 0.8;
            }
          `}
        />
      ) : (
        <UncheckedIcon
          css={css`
            width: 1.6rem;
            pointer-events: none;
            input[type='checkbox']:active ~ & {
              filter: brightness(0.6);
              background-color: rgba(0, 0, 0, 0.1);
            }
          `}
        />
      )}
      {labelText && (
        <span
          css={css`
            margin-left: 0.5rem;
            display: inline-block;
          `}
        >
          {labelText}
        </span>
      )}
    </label>
  );
};

export default Checkbox;
