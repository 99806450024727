import React, { useEffect } from 'react';

import { useProgram, useSkillsInProgram } from 'hooks';
import { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { useProgramBenchmarks } from 'hooks/benchmarks';
import { useProgramDataDispatch } from 'store/programData';

interface Props {
  programId: string;
  children: (props: {
    program?: SingleCurriculum<CurricularUnitResponse>;
    skillsInProgram: Record<string, string>;
    skillCounts?: Record<string, number>;
    categorizedSkills?: CategorizedSkills;
    requiredCourseSkillArray: string[] | undefined;
    isLoading: boolean;
  }) => React.ReactElement;
}

const ProgramInfoFetch: React.FC<Props> = ({ children, programId }) => {
  const programDataDispatch = useProgramDataDispatch();

  // reset provider data when switching between programs
  useEffect(() => {
    programDataDispatch({
      type: 'RESET'
    });
  }, [programId]);

  const { isLoading: benchmarksIsLoading } = useProgramBenchmarks(programId);
  const { data: program, isLoading: programIsLoading } = useProgram(programId);
  const { data: skills, isLoading: skillsIsLoading } = useSkillsInProgram(programId);

  const requiredSkillsInProgram = !skills ? [] : skills.requiredSkillsInProgram;
  const skillsInProgramNameMapping = !skills ? {} : skills.skillsInProgramNameMapping;

  const isLoading = benchmarksIsLoading || programIsLoading || skillsIsLoading;

  return children({
    skillsInProgram: skillsInProgramNameMapping,
    program,
    skillCounts: skills?.skillFrequency,
    categorizedSkills: skills?.categorizedSkills,
    requiredCourseSkillArray: requiredSkillsInProgram,
    isLoading
  });
};

export default ProgramInfoFetch;
