import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm, useController } from 'react-hook-form';
import styled from '@emotion/styled';

import { ReactComponent as Envelope } from 'images/envelope.svg';
import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import UserRolesDropdown from 'components/atoms/UserRolesDropdown';
import FormInput from 'components/molecules/FormInput';
import MessageModal from 'components/molecules/MessageModal';
import MultiSelect from 'components/molecules/MultiSelect';
import DeleteUserModal from 'components/molecules/DeleteUserModal';
import ModalButton from 'components/molecules/ModalButton';
import Loading from 'components/atoms/Loading';

import { DropdownSelectable } from 'hooks';
import { useUpdateUser, useCreateUser, useDeleteUser, useResendUserInvite } from 'hooks/mutateUser';
import { useSites } from 'hooks/siteHooks';
import { useProfileState } from 'store/profileStore';
import { ReactComponent as Trashcan } from 'images/trash.svg';
import { ReactComponent as Remove } from 'images/InternationalNo.svg';
import { ahoy, darkEmerald, navyBlue } from 'utils/colors';

import Checkbox from 'components/atoms/Checkbox';
import { getProfileByEmail } from 'services/profiles';

interface Inputs {
  name: string;
  email: string;
  schools: DropdownSelectable[];
  isInternalOnly: boolean;
}

export interface UserFormDefaultValues {
  id?: string;
  name: string;
  email: string;
  schools: School[];
  role: DropdownSelectable;
  accessLevels: AccessLevels;
  isInternalOnly: boolean;
}

interface UserFormProps {
  closeModal: () => void;
  defaultValues?: UserFormDefaultValues;
  submitButtonText?: string;
  formTitleText?: string;
  isEditForm?: boolean;
}

const UserForm: React.FC<UserFormProps> = ({
  closeModal,
  defaultValues,
  submitButtonText = 'Create New User',
  formTitleText,
  isEditForm
}) => {
  const [retrievedProfile, setRetrievedProfile] = useState<SingleProfileResponse>();
  const [selectedUserRole, setSelectedUserRole] = useState<DropdownSelectable>(
    defaultValues?.role || {
      value: 'editor',
      label: 'Editor'
    }
  );

  const { isInternalAdmin, accessLevels } = useProfileState();
  const { data: sitesObject } = useSites();

  const loggedInUserAdminSites = Object.entries(accessLevels)
    .filter(([, role]) => role === 'admin')
    .map(([site]) => site);
  const isAnyAdmin = isInternalAdmin || loggedInUserAdminSites.length > 0;

  const [selectableSchools, selectableRoles] = React.useMemo(() => {
    let selectableSites: School[] = [];
    let allSites: School[] = [];
    let sitesThatUserIsAnAdminOf: School[] = [];

    if (sitesObject) {
      sitesThatUserIsAnAdminOf = Object.values(sitesObject)
        .filter(site => site.accessLevel === 'admin')
        .map(site => {
          return { value: site.site, label: site.name };
        });

      allSites = Object.values(sitesObject)
        .sort((itemA, itemB) => {
          return itemA.name.toLowerCase().localeCompare(itemB.name.toLowerCase());
        })
        .map(sitesAttributes => {
          return {
            value: sitesAttributes.site,
            label: sitesAttributes.name
          };
        });
    }

    selectableSites = sitesThatUserIsAnAdminOf;
    const accessibleUserRoles = [
      { value: 'viewer', label: 'Viewer' },
      { value: 'editor', label: 'Editor' },
      { value: 'admin', label: 'Admin' }
    ];

    if (isInternalAdmin) {
      accessibleUserRoles.push({ value: 'admin', label: 'Lightcast Admin' });
      selectableSites = allSites;
    }

    return [selectableSites, accessibleUserRoles];
  }, [sitesObject, isInternalAdmin]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    defaultValues: defaultValues || {
      schools: selectableSchools.length > 1 ? [] : selectableSchools
    }
  });

  const {
    field: schools,
    fieldState: { error: schoolError }
  } = useController({
    name: 'schools',
    control,
    rules: { required: { value: true, message: 'At least one school is required' } }
  });

  const { field: internalOnly } = useController({
    name: 'isInternalOnly',
    control,
    defaultValue: false
  });

  useEffect(() => {
    if (defaultValues?.email) {
      getProfileByEmail(defaultValues.email).then(resp => {
        setRetrievedProfile(resp);
      });
    }
  }, []);

  useEffect(() => {
    if (defaultValues?.isInternalOnly || defaultValues?.role.label === 'EBG Admin') {
      internalOnly.onChange(true);
    }
  }, [defaultValues]);

  const {
    mutate: updateUser,
    isLoading: isUpdateLoading,
    isSuccess: isUserUpdated
  } = useUpdateUser();
  const { mutate: createUser, isLoading: isCreateLoading, data: newUserData } = useCreateUser();
  const {
    mutate: deleteUser,
    isLoading: isDeleteLoading,
    isSuccess: isUserDeleted
  } = useDeleteUser();

  const {
    mutate: resendUserInvite,
    isSuccess: isInviteResent,
    isLoading: isInviteResendLoading
  } = useResendUserInvite();

  const handelResendUserInvite = () => {
    resendUserInvite({
      email: retrievedProfile?.data.attributes.email || ''
    });
  };

  const onSubmit: SubmitHandler<Inputs> = formInputs => {
    const desiredAccessLevels = formInputs.schools.reduce((acc: PatchableAcessLevels, school) => {
      acc[school.value] = selectedUserRole.value as Role;
      return acc;
    }, {});
    const copiedDesiredAccessLevels = { ...desiredAccessLevels };
    const currentAccessLevels = { ...defaultValues?.accessLevels };
    let accessLevelsForPatch: PatchableAcessLevels = {};
    if (defaultValues) {
      for (const property in currentAccessLevels) {
        if (Object.prototype.hasOwnProperty.call(desiredAccessLevels, property)) {
          accessLevelsForPatch[property] = selectedUserRole.value as Role;
          delete copiedDesiredAccessLevels[property];
        } else {
          accessLevelsForPatch[property] = null;
        }
      }
    }
    accessLevelsForPatch = { ...accessLevelsForPatch, ...copiedDesiredAccessLevels };
    const profile: Partial<ProfileAttributes | PatchableProfileAttributes> = {
      name: formInputs.name,
      isInternalOnly: formInputs.isInternalOnly,
      accessLevels: desiredAccessLevels
    };
    if (isInternalAdmin) {
      if (selectedUserRole.label === 'Lightcast Admin') {
        profile.isInternalAdmin = true;
      } else {
        profile.isInternalAdmin = false;
      }
    }
    if (defaultValues) {
      profile.accessLevels = accessLevelsForPatch;
      updateUser({ email: formInputs.email, attributes: profile });
    } else {
      profile.email = formInputs.email;
      profile.id = formInputs.email;
      profile.accessLevels = desiredAccessLevels;
      createUser({ attributes: profile });
    }
  };

  const revokeAccessForUsers = React.useCallback(
    (email: string) => {
      updateUser({
        email,
        attributes: {
          accessLevels: loggedInUserAdminSites.reduce<PatchableAcessLevels>((levels, site) => {
            levels[site] = null;
            return levels;
          }, {})
        }
      });
    },
    [loggedInUserAdminSites]
  );

  useEffect(() => {
    if (isUserUpdated) {
      closeModal();
    }
  }, [isUserUpdated]);

  useEffect(() => {
    if (isUserDeleted) {
      closeModal();
    }
  }, [isUserDeleted]);

  useEffect(() => {
    if (isInviteResent) {
      closeModal();
    }
  }, [isInviteResent]);

  const accountLink = newUserData
    ? `${window.location.protocol}//${window.location.host}/auth/change-password?tmp-password=${
        newUserData.data.attributes.temporaryPassword
      }&email=${encodeURIComponent(newUserData.data.attributes.email)}`
    : '';
  return newUserData ? (
    <StyledMessageModal
      title="User Created Successfully"
      message="Users will automatically receive an email to set up their account. Copy the link below if the user needs a direct link to create their account. For security reasons, you will no longer be able to access this link once you close this modal."
    >
      <StyledAccountEmail>
        User creation link for {newUserData.data.attributes.email}:
      </StyledAccountEmail>
      <StyledAccountLink href={accountLink}>{accountLink}</StyledAccountLink>
    </StyledMessageModal>
  ) : (
    <FormWrapper>
      {formTitleText && <FormTitle isEditForm={isEditForm}>{formTitleText}</FormTitle>}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {retrievedProfile?.data.attributes.cognitoStatus ? (
          <InviteWrapper>
            <Envelope />
            {retrievedProfile?.data.attributes.cognitoStatus === 'FORCE_CHANGE_PASSWORD' ? (
              <>
                <span>Invitation Not Accepted - </span>
                {isInviteResendLoading ? (
                  <StyledLoading size={2} color={'gray'} />
                ) : (
                  <StyledInviteResendButton type="button" onClick={handelResendUserInvite}>
                    Resend
                  </StyledInviteResendButton>
                )}
              </>
            ) : (
              <InviteAccepted>Invitation Accepted</InviteAccepted>
            )}
          </InviteWrapper>
        ) : (
          <Placeholder />
        )}

        <FormInput
          type="text"
          labelText="Name"
          placeholder="Enter name of user..."
          {...register('name', {
            required: 'Name Required',
            maxLength: {
              value: 100,
              message: 'The maximum length for a name is 100 characters'
            },
            pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please enter a valid name' }
          })}
          error={errors.name}
          required
        />
        <FormInput
          type="text"
          labelText="Email"
          placeholder="Enter email of user..."
          {...register('email', {
            required: 'Email Required',
            maxLength: {
              value: 254,
              message: 'The maximum length for an email is 254 characters'
            }, // Max length derived from https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
            pattern: { value: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email address' }
          })}
          readOnly={!!defaultValues}
          error={errors.email}
          required
        />
        <MultiSelect
          useMultipleSelectionProps={{
            selectedItems: selectableSchools.length < 2 ? selectableSchools : schools.value || [],
            onSelectedItemsChange: ({ selectedItems }) => schools.onChange(selectedItems)
          }}
          itemToString={(item: School | null) => (item ? item.label : '')}
          itemToKey={(item: School) => item.value}
          renderSelectedItem={(item: School) => item.label}
          renderItems={(item: School) => (
            <SchoolItem>
              <SchoolName>{item.label}</SchoolName>
              {isInternalAdmin && <SchoolSubdomain>{item.value}</SchoolSubdomain>}
            </SchoolItem>
          )}
          labelText="School"
          inputPlaceholder="Search for a school..."
          items={selectableSchools}
          customFilter={(item, inputValue) =>
            item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            item.value.toLowerCase().includes(inputValue.toLowerCase())
          }
          readOnly={selectableSchools.length < 2}
          error={schoolError}
          keepOpenAfterSelection
        />
        <UserRolesDropdown
          defaultValue={selectedUserRole}
          onSelect={selectedRole => {
            selectedRole.label === 'Lightcast Admin'
              ? internalOnly.onChange(true)
              : internalOnly.onChange(false);
            setSelectedUserRole(selectedRole);
          }}
          items={selectableRoles}
        />
        {isInternalAdmin && (
          <StyledCheckbox>
            <Checkbox
              disabled={selectedUserRole.label === 'Lightcast Admin'}
              labelText="Internal User"
              checked={internalOnly.value}
              onChange={() => internalOnly.onChange(!internalOnly.value)}
            />
          </StyledCheckbox>
        )}
        <ButtonContainer>
          {defaultValues && isAnyAdmin && (
            <DeleteButton
              buttonText={
                <>
                  {isInternalAdmin ? <StyledTrashIcon /> : <StyledRemoveIcon />}
                  {isInternalAdmin ? 'Delete' : 'Remove'} User
                </>
              }
              type="button"
            >
              {closeButtonModal => {
                return (
                  <DeleteUserModal
                    isInternalAdmin={isInternalAdmin}
                    handleUserAction={() => {
                      if (isInternalAdmin) {
                        deleteUser(defaultValues.email);
                      } else {
                        revokeAccessForUsers(defaultValues.email);
                      }
                    }}
                    closeModal={closeButtonModal}
                    isSuccess={isUserDeleted}
                    isLoading={isDeleteLoading}
                  />
                );
              }}
            </DeleteButton>
          )}
          <Button scheme="outline" type="button" onClick={closeModal}>
            Cancel
          </Button>
          <StyledLoadingButton isLoading={isCreateLoading || isUpdateLoading} type="submit">
            {submitButtonText}
          </StyledLoadingButton>
        </ButtonContainer>
      </form>
    </FormWrapper>
  );
};

const Placeholder = styled.div`
  padding: 0.97rem;
`;

const InviteWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  button {
    color: ${navyBlue};
  }
  svg {
    margin-right: 1rem;
  }
`;

const StyledInviteResendButton = styled.button`
  border: none;
  background: none;
`;

const StyledLoading = styled(Loading)`
  margin-left: 1rem;
`;

const InviteAccepted = styled.span`
  color: ${darkEmerald};
`;

const FormWrapper = styled.div`
  width: 66.6rem;
  padding: 3.3rem;
`;

const FormTitle = styled.div<{ isEditForm: boolean | undefined }>`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: ${({ isEditForm }) => (isEditForm ? '0rem' : '2rem')};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
`;

const StyledCheckbox = styled.div`
  position: absolute;
  bottom: 40px;
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin-left: 1rem;
`;

const StyledTrashIcon = styled(Trashcan)`
  margin-right: 1rem;

  & > path {
    stroke: ${ahoy};
  }
`;
const StyledRemoveIcon = styled(Remove)`
  margin-right: 0.5rem;

  path {
    stroke: ${ahoy};
  }
`;

const DeleteButton = styled(ModalButton)`
  display: flex;
  align-items: center;
  color: ${ahoy};
  background: transparent;
  margin-right: 1rem;
  border: none;
  padding: 1rem 0.3rem;
  cursor: pointer;
  font-size: 1.4rem;
`;

const SchoolItem = styled.div`
  min-height: 3rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
`;
const SchoolName = styled.span`
  flex-grow: 1;
  font-size: 1.4rem;
  font-weight: 400;
`;

const SchoolSubdomain = styled.span`
  margin: 0 1rem;
  flex-shrink: 0;
  font-size: 1.2rem;
  font-weight: 400;
  font-style: italic;
`;

const StyledMessageModal = styled(MessageModal)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 67rem;
  height: 25rem;
`;
const StyledAccountEmail = styled.span`
  font-size: 1.4rem;
  margin-top: 2.5rem;
  flex-shrink: 0;
`;
const StyledAccountLink = styled.a`
  font-size: 1.4rem;
  margin-top: 2.5rem;
  flex-shrink: 0;
`;

export default UserForm;
