import { useSite } from 'hooks/siteHooks';
import React, { useEffect, useState } from 'react';
import { DEFAULT_REGION_OBJECT } from '../SetupLocalStorage';
import SiteForm, { Nation, SiteFormInputs } from '../SiteForm';

export interface EditSiteFormDefaultValues {
  name: string;
  site: string;
  salesforceId?: string; // Make this NOT optional
  nations: string;
  defaultRegion: string;
}

interface EditSiteFormProps {
  closeModal: () => void;
  siteData: EditSiteFormDefaultValues;
}

const EditSiteForm: React.FC<EditSiteFormProps> = ({ siteData, ...otherProps }) => {
  const { data: siteObject } = useSite(siteData.site);
  const [defaultValues, setDefaultValues] = useState<SiteFormInputs>();

  const nationsInputForm: Nation[] = [];
  useEffect(() => {
    if (siteData.nations.includes('us')) {
      nationsInputForm.push({ value: 'us', label: 'United States' });
    }
    if (siteData.nations.includes('ca')) {
      nationsInputForm.push({ value: 'ca', label: 'Canada' });
    }
    if (siteData.nations.includes('uk')) {
      nationsInputForm.push({ value: 'uk', label: 'United Kingdom' });
    }

    let region: Partial<Record<AllowableNation, RegionFilter>> = DEFAULT_REGION_OBJECT;
    if (siteObject?.defaultRegion) {
      region = siteObject.defaultRegion;
    }

    const defaultSiteValues: SiteFormInputs = {
      siteName: siteData.name,
      siteKey: siteData.site,
      salesforceId: siteData?.salesforceId || '',
      nations: nationsInputForm,
      regions: region
    };

    siteObject && setDefaultValues(defaultSiteValues);
  }, [siteData, siteObject]);

  return defaultValues ? (
    <SiteForm formTitleText="Edit Site" defaultValues={defaultValues} {...otherProps} />
  ) : null;
};

export default EditSiteForm;
