import React from 'react';
import styled from '@emotion/styled';

import Button, { ButtonProps } from 'components/atoms/Button';
import Loading from 'components/atoms/Loading';

const StyledButton = styled(Button)`
  justify-content: center;
  min-width: 6.8rem;

  img {
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
  }

  > div {
    svg {
      vertical-align: bottom;
    }
  }
`;

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  loaderColor?: 'white' | 'gray';
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  children,
  loaderColor = 'white',
  ...props
}) => (
  <StyledButton disabled={isLoading} {...props}>
    {isLoading ? <Loading color={loaderColor} /> : <div>{children}</div>}
  </StyledButton>
);

export default LoadingButton;
