import React from 'react';
import { View } from '@react-pdf/renderer';

export interface ContainerProps {
  width?: number | null;
  height?: number | null;
  color?: string;
}

export const Container: React.FC<ContainerProps> = ({ width, height, children, color }) => {
  return (
    <View
      style={{
        width: width === null ? undefined : width ?? '100%',
        height: height === null ? undefined : height ?? '100%',
        backgroundColor: color
      }}
    >
      {children}
    </View>
  );
};
