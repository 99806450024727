import makeStore from './makeStore';
import {
  login,
  decodeCurrentToken,
  logout,
  submitPasswordReset,
  submitInitialPassword
} from 'services/cognito';

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  isInitialized: false
};

const setLoginToken = (dispatch: Dispatch<AuthAction>, email: string, password: string) =>
  login(email, password).then(() => setCurrentUser(dispatch));

export const authLogin = async (
  dispatch: Dispatch<AuthAction>,
  { email, password }: { email: string; password: string }
): Promise<void> => {
  dispatch({ type: 'LOGIN_REQUEST' });
  try {
    await setLoginToken(dispatch, email, password);
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE' });
    throw err;
  }
};

export const authResetPassword = async (
  dispatch: Dispatch<AuthAction>,
  { email, code, password }: { email: string; code: string; password: string }
) => {
  dispatch({ type: 'LOGIN_REQUEST' });
  try {
    await submitPasswordReset(email, code, password);
    await setLoginToken(dispatch, email, password);
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE' });
    throw err;
  }
};

export const authInitializePassword = async (
  dispatch: Dispatch<AuthAction>,
  { email, oldPassword, newPassword }: { email: string; oldPassword: string; newPassword: string }
) => {
  dispatch({ type: 'LOGIN_REQUEST' });
  try {
    await submitInitialPassword(oldPassword, newPassword, email);
    await setLoginToken(dispatch, email, newPassword);
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE' });
    throw err;
  }
};

export const authLogout = (): Promise<void> => logout();

export const setCurrentUser = async (dispatch: Dispatch<AuthAction>): Promise<void> => {
  try {
    const payload = await decodeCurrentToken();
    if (payload) {
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload
      });
    }
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE' });
    throw err;
  }
};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'LOGIN_SUCCESS': {
      if (!action.payload) {
        throw new Error('No Payload provided.');
      }
      const { email, authSites, editorSites, viewerSites, consumerSites } = action.payload;
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        email,
        sites: [...authSites, ...editorSites, ...viewerSites, ...consumerSites],
        isInitialized: true
      };
    }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoading: false,
        isInitialized: true
      };
    case 'LOGOUT':
      return {
        ...initialState,
        isInitialized: true
      };
    default:
      return state;
  }
};

const [AuthProvider, useAuthDispatch, useAuthState] = makeStore<AuthState, AuthAction>(
  authReducer,
  initialState
);

export { AuthProvider, useAuthDispatch, useAuthState };
