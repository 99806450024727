export function getTypeSafeKeys<T extends Record<string, unknown>>(array: T): (keyof T)[] {
  return Object.keys(array);
}

export function keepWithinRange(min: number, max: number, num: number) {
  if (num < min) {
    return min;
  }
  if (num > max) {
    return max;
  }

  return num;
}
