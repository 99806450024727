import React from 'react';
import styled from '@emotion/styled';
import { useLocation, useHistory } from 'react-router-dom';
import { History, LocationState } from 'history';
import debounce from 'lodash/debounce';

import SearchWithDropdownInput, {
  DropdownMenuItem
} from 'components/atoms/SearchWithDropdownInput';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import { getSkillCounts } from 'services/curricularSkills';
import { fetchSkillsByTerm } from 'services/skills';

import { ReactComponent as SearchIcon } from 'images/magnifying-glass.svg';
import { useProfileState } from 'store/profileStore';
import { useCourseSearch, useProgramSearch } from 'hooks/curricularSkills';

import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import { bgLightGray, lightGray, darkGray } from 'utils/colors';
import { programTypeClassShort } from 'utils/programTypeClassShortening';

import SearchedItems from 'components/organisms/SearchedItems';
import Loading from 'components/atoms/Loading';
import InfoPill from 'components/atoms/InfoPill';

const FILTER_OPTIONS = {
  Program: ['keyword', 'skills', 'courses'],
  Course: ['keyword', 'skills', 'programs'],
  ProgramType: []
};

const setDebouncedHistory = debounce(
  (
    history: History<LocationState>,
    searchText: string,
    trackHistory: boolean,
    previousState: CurricularRouteSearchState<ProgramSortOption | CourseSortOption> | undefined,
    fields: TextQuery['filterQuery']['fields'],
    locationStateKey: string
  ) => {
    const historyFunc = trackHistory ? history.push : history.replace;

    historyFunc(`${history.location.pathname}?page=1`, {
      ...previousState,
      [locationStateKey]: {
        filter: {
          ...previousState?.filter,
          filterType: 'text',
          filterQuery: {
            query: searchText,
            fields
          }
        },
        sort: {
          ...previousState?.sort,
          sortType: 'title',
          sortOrder: 'ascending'
        }
      }
    });
  },
  400
);

const TextAndSkillFilters: React.FC<{
  curricularType: 'Course' | 'Program' | 'ProgramType';
  hideDivider?: boolean;
  isRequesting?: boolean;
  trackHistory?: boolean;
  locationStateKey: string;
}> = ({
  curricularType,
  children,
  hideDivider,
  isRequesting,
  trackHistory = true,
  locationStateKey
}) => {
  const [searchText, setSearchText] = React.useState('');
  const [skillCounts, setSkillCounts] = React.useState<{ [x: string]: number }>({});
  const { currentSite } = useProfileState();
  const { getCoursesData } = useCourseSearch();
  const { getProgramData } = useProgramSearch();
  const history = useHistory();
  const location = useLocation<{
    [x: string]: CurricularRouteSearchState<ProgramSortOption | CourseSortOption> | undefined;
  }>();

  const locationState = {
    [locationStateKey]: defaultCurricularRouteSearchState(),
    ...(location.state || {})
  };
  const localLocationState = locationState[locationStateKey];

  const historyFunc = trackHistory ? history.push : history.replace;

  React.useEffect(() => {
    if (localLocationState?.filter.filterType === 'text') {
      setSearchText(localLocationState.filter.filterQuery.query || '');
    }
  }, [location.state]);

  const skillSearch = async (text: string) => {
    const skills = await fetchSkillsByTerm(text);
    getSkillCounts(
      curricularType === 'Course' ? 'course' : 'program',
      skills.map(skill => skill.id),
      currentSite
    )
      .then(({ data }) => setSkillCounts(data.counts))
      .catch(err => {
        // Do something
      });
    return skills.map(({ name, id }) => ({ id, name }));
  };

  let selectValue = 'keyword';
  switch (localLocationState?.filter.filterType) {
    case 'courses': {
      selectValue = 'courses';
      break;
    }
    case 'skills': {
      selectValue = 'skills';
      break;
    }
    case 'programs': {
      selectValue = 'programs';
      break;
    }
    default: {
      break;
    }
  }

  return (
    <>
      <Flex>
        <Multiselect data-cy="text-and-skill-filters_multiselect-header">
          {curricularType !== 'ProgramType' && (
            <Select
              data-cy="test-and-skill-filters_filter-select-button"
              ariaLabel={`Search curriculum by ${localLocationState?.filter.filterType}`}
              value={selectValue}
              minWidth="11rem"
              onChange={({ target: { value: type } }) => {
                if (
                  type === 'keyword' ||
                  type === 'skills' ||
                  type === 'courses' ||
                  type === 'programs'
                ) {
                  let filterType = 'text';
                  let filterQuery: string[] | { query: string; fields: ['title'] } = {
                    query: '',
                    fields: ['title']
                  };
                  let filterOperator;
                  if (type === 'skills') {
                    filterType = 'skills';
                    filterOperator = 'any';
                    filterQuery = [];
                  } else if (type === 'courses') {
                    filterType = 'courses';
                    filterQuery = [];
                  } else if (type === 'programs') {
                    filterType = 'programs';
                    filterQuery = [];
                  }
                  historyFunc(`${location.pathname}`, {
                    ...locationState,
                    [locationStateKey]: {
                      filter: {
                        ...localLocationState?.filter,
                        filterType,
                        filterOperator,
                        filterQuery
                      },
                      sort: {
                        ...localLocationState?.sort,
                        sortType: 'title',
                        sortOrder: 'ascending'
                      }
                    }
                  });
                }
              }}
              options={FILTER_OPTIONS[curricularType]}
            />
          )}
          <StyledSearchIcon />
          {localLocationState?.filter.filterType === 'skills' ? (
            <SearchWithDropdownInput
              data-cy="text-and-skill-filters_search-with-dropdown-input-skills"
              aria-label="type to search for skills in your curriculum"
              labelText="Search for a skill"
              placeholder="Search for a skill..."
              search={skillSearch}
              selectedItems={localLocationState?.filter.filterQuery || []}
              onSelectItem={skills => {
                localLocationState.filter.filterType === 'skills' &&
                  history.replace(`${location.pathname}?page=1`, {
                    [locationStateKey]: {
                      ...localLocationState,
                      filter: {
                        ...localLocationState.filter,
                        filterType: 'skills',
                        filterQuery: [...localLocationState.filter.filterQuery, skills]
                      }
                    }
                  });
              }}
            >
              {({ getItemProps, highlightedIndex, inputItems }) =>
                inputItems.map((item, i) => (
                  <DropdownMenuItem
                    key={item.id}
                    highlighted={i === highlightedIndex}
                    {...getItemProps({ item, index: i })}
                  >
                    <span>{item.name}</span>
                    <SkillCount visible={skillCounts[item.id] !== undefined}>
                      {skillCounts[item.id]}&nbsp;{curricularType}
                      {skillCounts[item.id] !== 1 ? 's' : ''}
                    </SkillCount>
                  </DropdownMenuItem>
                ))
              }
            </SearchWithDropdownInput>
          ) : localLocationState?.filter.filterType === 'courses' ? (
            <SearchWithDropdownInput
              data-cy="text-and-skill-filters_search-with-dropdown-input-courses"
              aria-label="type to search for courses in your curriculum"
              labelText="Search for a course"
              placeholder="Search for a course..."
              search={query =>
                getCoursesData(query).then(courses =>
                  courses?.data.map(course => ({
                    id: course.id,
                    name: course.attributes.title,
                    courseId: course.attributes.courseId
                  }))
                )
              }
              selectedItems={localLocationState?.filter.filterQuery || []}
              onSelectItem={course => {
                if (course.courseId) {
                  course.name = `${course.name} (${course.courseId})`;
                }
                localLocationState.filter.filterType === 'courses' &&
                  history.replace(`${location.pathname}?page=1`, {
                    [locationStateKey]: {
                      ...localLocationState,
                      filter: {
                        ...localLocationState.filter,
                        filterType: 'courses',
                        filterQuery: [...localLocationState.filter.filterQuery, course]
                      }
                    }
                  });
              }}
            >
              {({ getItemProps, highlightedIndex, inputItems }) =>
                inputItems.map((course, i) => (
                  <DropdownMenuItem
                    key={course.id}
                    highlighted={i === highlightedIndex}
                    {...getItemProps({ item: course, index: i })}
                  >
                    <span>{course.name}</span>
                    {course.courseId && (
                      <StyledInfoPill scheme="green">{course.courseId}</StyledInfoPill>
                    )}
                  </DropdownMenuItem>
                ))
              }
            </SearchWithDropdownInput>
          ) : localLocationState?.filter.filterType === 'programs' ? (
            <SearchWithDropdownInput
              data-cy="text-and-skill-filters_search-with-dropdown-input-programs"
              aria-label="type to search for programs in your curriculum"
              labelText="Search for a program"
              placeholder="Search for a program..."
              search={query =>
                getProgramData(query).then(programs =>
                  programs?.data.map(program => ({
                    id: program.id,
                    name: program.attributes.title,
                    label: program.attributes.groupType.groupTypeClass.name
                  }))
                )
              }
              selectedItems={localLocationState?.filter.filterQuery || []}
              onSelectItem={program => {
                if (program.id) {
                  program.name = `${program.name}`;
                }
                localLocationState.filter.filterType === 'programs' &&
                  history.replace(`${location.pathname}?page=1`, {
                    [locationStateKey]: {
                      ...localLocationState,
                      filter: {
                        ...localLocationState.filter,
                        filterType: 'programs',
                        filterQuery: [...localLocationState.filter.filterQuery, program]
                      }
                    }
                  });
              }}
            >
              {({ getItemProps, highlightedIndex, inputItems }) =>
                inputItems.map((program, i) => (
                  <DropdownMenuItem
                    key={program.id}
                    highlighted={i === highlightedIndex}
                    {...getItemProps({ item: program, index: i })}
                  >
                    <span>{program.name}</span>
                    <StyledInfoPill scheme="blue">
                      {programTypeClassShort[program.label as keyof typeof programTypeClassShort] ||
                        program.label}
                    </StyledInfoPill>
                  </DropdownMenuItem>
                ))
              }
            </SearchWithDropdownInput>
          ) : (
            <>
              <Input
                data-cy="text-and-skill-filters_search-with-dropdown-input"
                placeholder={`Search for a ${curricularType.toLowerCase()} title...`}
                value={searchText}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const fields: TextQuery['filterQuery']['fields'] = ['title'];
                  curricularType === 'Course' && fields.push('courseId');
                  setSearchText(e.currentTarget.value);
                  setDebouncedHistory(
                    history,
                    e.currentTarget.value,
                    trackHistory,
                    locationState[locationStateKey],
                    fields,
                    locationStateKey
                  );
                }}
              />
              {isRequesting && <StyledLoading color="gray" size={3} />}
            </>
          )}
        </Multiselect>
        {children}
      </Flex>
      {!hideDivider && <Divider />}
      {localLocationState?.filter.filterType === 'skills' && (
        <SearchedItems
          searchType={'skills'}
          selectedItems={localLocationState?.filter.filterQuery}
          setSelectedItems={skills =>
            history.replace(`${location.pathname}?page=1`, {
              [locationStateKey]: {
                ...localLocationState,
                filter: {
                  ...localLocationState?.filter,
                  filterType: 'skills',
                  filterQuery: skills
                }
              }
            })
          }
          locationStateKey={locationStateKey}
          trackHistory={trackHistory}
        />
      )}
      {localLocationState?.filter.filterType === 'courses' && (
        <SearchedItems
          searchType={'courses'}
          selectedItems={localLocationState?.filter.filterQuery}
          setSelectedItems={courses =>
            history.replace(`${location.pathname}?page=1`, {
              [locationStateKey]: {
                ...localLocationState,
                filter: {
                  ...localLocationState?.filter,
                  filterType: 'courses',
                  filterQuery: courses
                }
              }
            })
          }
          locationStateKey={locationStateKey}
          trackHistory={trackHistory}
        />
      )}
      {localLocationState?.filter.filterType === 'programs' && (
        <SearchedItems
          searchType={'programs'}
          selectedItems={localLocationState?.filter.filterQuery}
          setSelectedItems={programs =>
            history.replace(`${location.pathname}?page=1`, {
              [locationStateKey]: {
                ...localLocationState,
                filter: {
                  ...localLocationState?.filter,
                  filterType: 'programs',
                  filterQuery: programs
                }
              }
            })
          }
          locationStateKey={locationStateKey}
        />
      )}
    </>
  );
};

const Multiselect = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  background: ${bgLightGray};
  padding: 0.5rem;
  border: 0.1rem solid #edf2f6;
  border-radius: 0.4rem;
  max-width: 50rem;
  width: 100%;

  input {
    border: none;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${lightGray};
  margin-top: 3rem;
  margin-bottom: 0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const SkillCount = styled.span<{ visible: boolean }>`
  font-style: italic;
  font-size: 1.2rem;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.1s ease-in;
`;

const StyledSearchIcon = styled(SearchIcon)`
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-left: 1.7rem;
  fill: ${darkGray};
`;

const StyledLoading = styled(Loading)`
  margin-left: -3rem;
`;

const StyledInfoPill = styled(InfoPill)`
  align-self: center;
  margin-left: 1rem;
`;
export default TextAndSkillFilters;
