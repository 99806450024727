import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import AddSkillPlaceholderLoading from 'components/atoms/AddSkillPlaceholderLoading';
import Tabs from 'components/atoms/Tabs';
import KeywordSearch from 'components/atoms/SearchInput';
import JobTitleSearchWithDropdown from 'components/molecules/JobTitleSearchWithDropdown';
import { fetchSkillsByJobTitleRank } from 'services/emsiProfiles';
import { fetchSkillsByTerm, fetchSkillsById } from 'services/skills';
import PillContainer from 'components/molecules/PillContainer';
import SkillsByCategory from 'components/organisms/SkillsByCategory';

import { darkGray, darkSaphire, mediumGray, darkerBorderGray } from 'utils/colors';
import { useRelatedSkills } from 'hooks/skills';

const AddSkillsHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 3rem;
`;

const SkillsMenu = styled.div`
  display: flex;
  flex: 2;
  margin-right: 8rem;

  flex-direction: column;
`;

const SkillSelectionCard = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span`
  font-weight: 400;
  font-style: italic;
  color: ${mediumGray};
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  line-height: 1;
  display: block;
`;

const TabBar = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${darkerBorderGray};
  width: fit-content;
`;

const Tab = styled.div<{ isActive?: boolean }>`
  color: ${props => (props.isActive ? darkSaphire : darkGray)};
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1rem;
  border: none;
  margin-bottom: -1px;
  margin-right: 2.4rem;
  cursor: pointer;
  border-bottom: ${props =>
    props.isActive ? `2px solid ${darkSaphire}` : `2px solid transparent`};
  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledKeywordSearch = styled(KeywordSearch)`
  margin-right: 4rem;
  max-width: 75rem;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
`;

const TabPanel = styled.div`
  margin: -0.5rem;
  padding: 0.5rem;
  margin-top: 0;
  padding-top: 0;
`;

const PillContainerMarginTop = styled(PillContainer)`
  margin-top: 2.4rem;
`;

const AddMoreSkills: React.FC<{
  selectedSkills: readonly CurricularSkill[];
  learningOutcomeSkillIds: readonly string[];
  onChange: (...events: unknown[]) => void;
}> = ({ selectedSkills, learningOutcomeSkillIds, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('related-skills');
  const [foundSkills, setFoundSkills] = useState<Skill[]>([]);
  const [titleSkills, setTitleSkills] = useState<Skill[]>([]);
  const [categorySkills, setCategorySkills] = useState<Skill[]>([]);

  const [skillIdsToGetRelatedOf, setSkillIdsToGetRelatedOf] = useState<string[]>([]);
  const [lastChangeWasRelated, setLastChangeWasRelated] = useState(false);

  const { data: relatedSkills } = useRelatedSkills(skillIdsToGetRelatedOf);

  useEffect(() => {
    if (lastChangeWasRelated) {
      setLastChangeWasRelated(false);
      return;
    }

    setSkillIdsToGetRelatedOf(selectedSkills.map(s => s.id) as string[]);
  }, [selectedSkills]);

  const addOrRemoveSkill = (skill: Skill) => {
    const selectedSkill: CurricularSkill = learningOutcomeSkillIds?.includes(skill.id)
      ? { id: skill.id, isLearningObjective: true }
      : { id: skill.id, isLearningObjective: false };
    onChange(
      selectedSkills.some(s => s.id === skill.id)
        ? selectedSkills.filter(s => skill.id !== s.id)
        : ([...selectedSkills, selectedSkill] as CurricularSkill[])
    );
  };

  return (
    <SkillsMenu>
      <AddSkillsHeading>Add More Skills</AddSkillsHeading>
      <SkillSelectionCard>
        <Tabs onChange={tabName => setSelectedTab(tabName)}>
          {({ getTabProps, getPanelProps }) => {
            return (
              <>
                <TabBar role="tablist">
                  <Tab {...getTabProps('related-skills', selectedTab)}>Related Skills</Tab>
                  <Tab {...getTabProps('skill-name', selectedTab)}>Skill Name</Tab>
                  <Tab {...getTabProps('category', selectedTab)}>Skill Category</Tab>
                  <Tab {...getTabProps('job-title', selectedTab)}>Job Title</Tab>
                </TabBar>
                <TabPanel {...getPanelProps('related-skills')}>
                  {relatedSkills.length > 0 ? (
                    <PillContainerMarginTop
                      addOrRemoveSkill={skill => {
                        setLastChangeWasRelated(true);
                        addOrRemoveSkill(skill);
                      }}
                      selectableSkills={relatedSkills}
                      selectedSkills={selectedSkills.map(s => s.id)}
                    />
                  ) : (
                    <>
                      <StyledSpan>Add more skills above to view related skills.</StyledSpan>
                      <AddSkillPlaceholderLoading />
                    </>
                  )}
                </TabPanel>
                <TabPanel {...getPanelProps('skill-name')}>
                  <StyledKeywordSearch
                    onChange={async searchTerm => {
                      setIsLoading(true);
                      const results = await fetchSkillsByTerm(searchTerm);
                      setFoundSkills(results.slice(0, 60));
                      setIsLoading(false);
                    }}
                    placeholder="Search for skills by name..."
                  />
                  {foundSkills.length > 0 && !isLoading ? (
                    <PillContainer
                      addOrRemoveSkill={addOrRemoveSkill}
                      selectableSkills={foundSkills}
                      selectedSkills={selectedSkills.map(s => s.id)}
                    />
                  ) : (
                    <AddSkillPlaceholderLoading isLoading={isLoading} />
                  )}
                </TabPanel>
                <TabPanel {...getPanelProps('category')}>
                  <>
                    <SkillsByCategory
                      setCategorySkills={setCategorySkills}
                      setIsLoading={setIsLoading}
                    />
                    {categorySkills.length > 0 && !isLoading ? (
                      <PillContainer
                        selectableSkills={categorySkills}
                        addOrRemoveSkill={addOrRemoveSkill}
                        selectedSkills={selectedSkills.map(s => s.id)}
                      />
                    ) : (
                      <AddSkillPlaceholderLoading isLoading={isLoading} />
                    )}
                  </>
                </TabPanel>
                <TabPanel {...getPanelProps('job-title')}>
                  <JobTitleSearchWithDropdown
                    onSearch={job => {
                      setIsLoading(true);
                      fetchSkillsByJobTitleRank(job.id)
                        .then(skills => fetchSkillsById(skills.map(skill => skill.name)))
                        .then(skills => {
                          setIsLoading(false);
                          setTitleSkills(skills);
                        });
                    }}
                  />
                  {titleSkills.length > 0 && !isLoading ? (
                    <PillContainer
                      selectableSkills={titleSkills}
                      addOrRemoveSkill={addOrRemoveSkill}
                      selectedSkills={selectedSkills.map(s => s.id)}
                    />
                  ) : (
                    <AddSkillPlaceholderLoading isLoading={isLoading} />
                  )}
                </TabPanel>
              </>
            );
          }}
        </Tabs>
      </SkillSelectionCard>
    </SkillsMenu>
  );
};

export default AddMoreSkills;
