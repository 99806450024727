import { LegacyRef, MutableRefObject } from 'react';

const mergeRefs =
  <T>(...refs: (LegacyRef<T> | undefined)[]) =>
  (target: T) => {
    for (const ref of refs) {
      // ignore null refs and old refs that we hopefully aren't using anywhere
      if (ref && typeof ref !== 'string') {
        if (typeof ref === 'function') {
          ref(target);
        } else {
          (ref as MutableRefObject<T>).current = target;
        }
      }
    }
  };

export default mergeRefs;
