import React, { useState } from 'react';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import AuthorizationForm from 'components/organisms/AuthorizationForm';
import FormInput from 'components/molecules/FormInput';

import { useAuthDispatch, authLogin, useAuthState } from 'store/authStore';
import { useProfileState } from 'store/profileStore';
import { ReactComponent as SkillInfo } from 'images/skillInfo.svg';

const FirstTimeLoginMessage = styled.div`
  border-radius: 0.4rem;
  margin: 2.4rem 0;
  padding: 1.4rem;
  font-size: 1.6rem;

  @media (max-width: 1600px) {
    font-size: 1.2rem;
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
`;

const StyledSkillInfo = styled(SkillInfo)`
  margin-right: 1rem;
`;
const MessageTitle = styled.span`
  font-weight: 700;
`;

const StyledSpan = styled.div`
  margin-top: 2rem;
`;

interface Inputs {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Inputs>({
    shouldFocusError: false,
    mode: 'onTouched'
  });
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
  const authDispatch = useAuthDispatch();
  const { email } = useAuthState();
  const { currentSite } = useProfileState();
  // Temporary function until we know what we are doing with this. It will take one data argument.
  const history = useHistory();
  const location = useLocation<
    | {
        redirectTarget: { pathname: string; state: unknown; hash: string; search: string };
      }
    | undefined
  >();

  const onSubmit: SubmitHandler<Inputs> = formInputs => {
    setSubmissionError(() => undefined);
    authLogin(authDispatch, formInputs)
      .then(() => {
        const { pathname, search, hash, state } = location.state?.redirectTarget || {};

        if (pathname) {
          history.push(`${pathname}${search || ''}${hash || ''}`, state);
        } else {
          history.push('/dashboard');
        }
      })
      .catch(err => {
        reset();
        setSubmissionError(err.message);
      });
  };

  if (email && currentSite) {
    return <Redirect to="/dashboard/courses" />;
  }
  return (
    <>
      <AuthorizationForm
        headerText="Login"
        submitButtonText="Login"
        linkText="Forgot Your Password?"
        linkTo="/auth/forgot-password"
        onSubmit={handleSubmit(onSubmit)}
        errorMessage={submissionError}
      >
        <FormInput
          type="text"
          labelText="Email"
          placeholder="Enter email..."
          {...register('email', {
            required: 'Email Required',
            pattern: { value: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email address' }
          })}
          error={errors.email}
          required
        />
        <FormInput
          type="password"
          labelText="Password"
          placeholder="Enter password..."
          {...register('password', { required: 'Required' })}
          error={errors.password}
          required
        />
      </AuthorizationForm>
      <FirstTimeLoginMessage>
        <Flex>
          <StyledSkillInfo role="img" aria-label="important message" />
          <MessageTitle>First time logging into this site?</MessageTitle>
        </Flex>
        <div>
          Email verification is required for first-time users. If you have access to Skillabi, then
          you will have received a &ldquo;Welcome to Skillabi&ldquo; email from
          no-reply@skillabi.com at some point in time. Search your inbox for said email and click
          the link within to verify your account.
        </div>
        <StyledSpan>
          For further support, please contact{' '}
          <a href="mailto:skillabi-help@lightcast.io">skillabi-help@lightcast.io</a>.
        </StyledSpan>
      </FirstTimeLoginMessage>
    </>
  );
};

export default LoginForm;
