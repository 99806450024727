import React from 'react';
import styled from '@emotion/styled';

import { bgDarkGray } from 'utils/colors';

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  padding-bottom: 6rem;
  flex-direction: column;
  width: 72rem;
`;

const StyledTitle = styled.h2`
  align-self: flex-start;
`;

const StyledHr = styled.hr`
  border: 2px solid ${bgDarkGray};
  background: ${bgDarkGray};
  width: 100%;
`;

interface FooterModalProps {
  children?: React.ReactNode;
  titleText: string;
}

const FooterModal: React.FC<FooterModalProps> = ({ children, titleText }) => {
  return (
    <ModalBody>
      <StyledTitle>{titleText}</StyledTitle>
      <StyledHr />
      {children}
    </ModalBody>
  );
};

export default FooterModal;
