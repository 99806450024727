import { SheetOptions } from 'excellentexport';
import { QueryClient } from 'react-query';
import { searchCourses } from 'services/curricularSkills';
import { fetchSkillsById } from 'services/skills';
import { depaginate } from 'utils/depaginate';

const EXPORT_COLUMNS = [
  'UID',
  'Course Code',
  'Title',
  'Description',
  'URL',
  'Credits',
  '# of Skills'
];

interface CoursesExportData {
  courses: string[][];
  skillsWithNames: Record<
    string,
    {
      id: string;
      name?: string | undefined;
    }
  >;
}

const getAllCoursesExportData = (
  currentSite: string,
  queryClient: QueryClient,
  coursesFilter?: CourseSearchFilters
): Promise<CoursesExportData> =>
  depaginate(
    queryClient,
    'courses',
    {
      limit: 100,
      filter: { site: { in: [currentSite] }, ...coursesFilter }
    },
    searchCourses
  )
    .then(async courses => {
      const allSkills = [
        ...new Set(courses.data.flatMap(course => course.attributes.skills || []))
      ];
      const skillsWithNames = await fetchSkillsById(allSkills.map(s => s.id)).then(skills =>
        skills.reduce<Record<string, { id: string; name?: string }>>((acc, skill) => {
          acc[skill.id] = { id: skill.id, name: skill.name };
          return acc;
        }, {})
      );
      return {
        courses: courses.data.map(({ id, attributes }) => [
          id,
          attributes.courseId || '',
          attributes.title,
          attributes.description || '',
          attributes.url || '',
          attributes.credits?.toString() || '',
          attributes.skills?.length.toString() || '0',
          ...(attributes.skills
            ?.filter(skill => !!skillsWithNames[skill.id])
            .map(skill => skill.id) || '')
        ]),
        skillsWithNames
      };
    })
    .catch(() => {
      throw new Error('Export Failed');
    });

export const exportAllCourses =
  (currentSite: string, queryClient: QueryClient, coursesFilter?: CourseSearchFilters) =>
  async (): Promise<SheetOptions[]> => {
    const { courses, skillsWithNames } = await getAllCoursesExportData(
      currentSite,
      queryClient,
      coursesFilter
    );

    const coursesWithSkillNames = courses.map(course => {
      const copiedCourse = [...course];
      const skillIds = copiedCourse.splice(7);
      const skillNames = skillIds.map(skillId => skillsWithNames[skillId].name);
      return [...copiedCourse, ...skillNames];
    });

    return [
      {
        name: 'Courses',
        from: {
          array: [EXPORT_COLUMNS.concat(['Skills -->']), ...coursesWithSkillNames]
        }
      },
      {
        name: 'Skill IDs',
        from: {
          array: [EXPORT_COLUMNS.concat(['Skill IDs -->']), ...courses]
        }
      }
    ];
  };
