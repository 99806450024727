import useLocalStorage from './useLocalStorage';
import { useCurrentSiteState } from 'store/currentSiteStore';

const useCurrentNation = (): [data: JPANation, setCurrentNation: (nation: JPANation) => void] => {
  const { nations } = useCurrentSiteState();

  const [localStorageNation, setCurrentNation] = useLocalStorage<JPANation>('currentNation');

  const currentNation =
    localStorageNation && nations.includes(localStorageNation) ? localStorageNation : nations[0];

  return [currentNation, setCurrentNation];
};

export default useCurrentNation;
