import styled from '@emotion/styled';
import { VictoryCoordinate } from 'helpers/skillGapTrackerTable/graphDataFormatter';
import React from 'react';
import { blue } from 'utils/colors';

interface InfoPanelProps {
  totalTaughtSkillCoordinates: VictoryCoordinate[];
}

const ChartInfoPanel: React.FC<InfoPanelProps> = ({ totalTaughtSkillCoordinates }) => {
  return (
    <Panel>
      <Title>
        Current Taught Skill Coverage:{' '}
        <Percentage>
          {totalTaughtSkillCoordinates.length
            ? `${Math.round(
                totalTaughtSkillCoordinates[totalTaughtSkillCoordinates.length - 1].y
              )}%`
            : '0%'}
        </Percentage>
      </Title>
      <Description>
        Percentage of in-demand skills for selected benchmarks <br /> that are currently represented
        in this program.
      </Description>
    </Panel>
  );
};

const Panel = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  padding: 1rem 1.5rem 1rem 1.5rem;
`;

const Title = styled.span`
  font-size: 1.4rem;
  color: black;
  font-weight: bold;
`;

const Percentage = styled.span`
  color: ${blue};
`;

const Description = styled.span`
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: black;
  display: block;
`;

export default ChartInfoPanel;
