import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as EmptyTablePlaceholderImage } from 'images/skillsByTextEmpty.svg';

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50rem;
`;

const StyledSpan = styled.span`
  display: block;
`;

const HeavySpan = styled(StyledSpan)`
  font-weight: 600;
  margin-top: 2rem;
`;

interface EmptyDashboardTableProps {
  label: string;
  title?: string;
  description?: string;
}

const EmptyDashboardTable: React.FC<EmptyDashboardTableProps> = ({ label, title, description }) => {
  return (
    <EmptyState>
      <EmptyTablePlaceholderImage />
      <HeavySpan>{title ? title : `No ${label}s to show yet`}</HeavySpan>
      <StyledSpan>{description ? description : `Get started by adding a ${label}.`}</StyledSpan>
    </EmptyState>
  );
};

export default EmptyDashboardTable;
