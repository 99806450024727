import { getHighestAccessLevel } from 'utils/getHighestAccessLevel';
import { getOwnProfile } from 'services/profiles';

const getPendoUserData = async (currentSite: string): Promise<pendo.Identity> => {
  const profile = await getOwnProfile();
  const {
    id,
    attributes: { accessLevels, createdAt, updatedAt, isInternalAdmin }
  } = profile.data;
  const highestAccessLevel = getHighestAccessLevel(accessLevels);

  return {
    visitor: {
      // RESERVED KEYS:
      id,
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
      highestAccessLevel,
      isInternalAdmin: !!isInternalAdmin,
      sites: Object.keys(accessLevels),
      createdAt: createdAt || '',
      updatedAt: updatedAt || ''
    },
    account: {
      // RESERVED KEYS:
      id: `skillabi-${currentSite}` // Required if using Pendo Feedback
      // name:         // Optional TODO: Add when we get real site names back
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    }
  };
};

export const initializePendoUserData = async (currentSite: string): Promise<void> =>
  pendo.initialize(await getPendoUserData(currentSite));

export const updatePendoUserData = async (currentSite: string): Promise<void> =>
  pendo.identify(await getPendoUserData(currentSite));
