import getCountDistribution from 'helpers/countDistribution';

export const getFrequencyValues = (
  skillCounts: Record<string, number>,
  skillsInProgram: Record<string, string>
) => {
  // We do this first step to eliminate any old skills that no longer are in the taxonomy (id still exists but no valid name)
  // Otherwise we get inaccurate counts and empty skill chips in the display.
  const filteredCounts: Record<string, number> = Object.entries(skillCounts)
    .filter(([skillId]) => skillsInProgram[skillId])
    .reduce((filteredSkillCounts: Record<string, number>, [skillId, count]) => {
      filteredSkillCounts[skillId] = count;
      return filteredSkillCounts;
    }, {});

  const frequency = getCountDistribution(filteredCounts);

  const highFrequency = Object.entries(frequency.highFrequency).reverse();
  const mediumFrequency = Object.entries(frequency.mediumFrequency).reverse();
  const lowFrequency = Object.entries(frequency.lowFrequency).reverse();

  const highFrequencyCount = Object.values(frequency?.highFrequency || {})
    .map(skillArray => skillArray)
    .flat().length;
  const mediumFrequencyCount = Object.values(frequency?.mediumFrequency || {})
    .map(skillArray => skillArray)
    .flat().length;
  const lowFrequencyCount = Object.values(frequency?.lowFrequency || {})
    .map(skillArray => skillArray)
    .flat().length;

  return {
    frequency: [highFrequency, mediumFrequency, lowFrequency],
    counts: [highFrequencyCount, mediumFrequencyCount, lowFrequencyCount],
    highFrequencyCount,
    mediumFrequencyCount,
    lowFrequencyCount
  };
};

export const segmentChartDataIntoColumns = (skills: string[], numColumns: number): string[][] => {
  if (numColumns <= 0) {
    throw new Error('numColumns must be greater than 0');
  }

  const numRows = Math.ceil(skills.length / numColumns);
  const result: string[][] = [];

  for (let i = 0; i < numColumns; i++) {
    const start = i * numRows;
    const end = (i + 1) * numRows;
    const segment = i < numColumns - 1 ? skills.slice(start, end) : skills.slice(start);
    result.push(segment);
  }

  return result;
};
