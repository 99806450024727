import React, { useState } from 'react';
import { Tab, TabProps, PanelProps } from 'hooks/useTabs';

interface TabsProps {
  onChange: (tabName: string) => void;
  children: (args: ChildrenFn) => React.ReactElement;
}

type ChildrenFn = {
  getTabProps: (tabName: string, activeTabName: string) => TabProps | void;
  getPanelProps: (tabName: string) => PanelProps | Record<string, never>;
};

const Tabs: React.FC<TabsProps> = ({ onChange, children }) => {
  const [tabs, setTabs] = useState<Tab[]>([]);

  const changeTabs = (e: React.KeyboardEvent) => {
    const activeTabIndex = tabs.findIndex(tab => tab.isActive);

    let nextIndex = activeTabIndex;

    if (e.key === 'ArrowRight') {
      nextIndex = (activeTabIndex + 1) % tabs.length;
    }

    if (e.key === 'ArrowLeft') {
      nextIndex = activeTabIndex === 0 ? tabs.length - 1 : activeTabIndex - 1;
    }

    if (nextIndex !== activeTabIndex) {
      onChange(tabs[nextIndex].tabName);
      document.getElementById(`tab-${tabs[nextIndex].id}`)?.focus();
    }
  };

  const getTabProps = (tabName: string, activeTabName: string): TabProps | void => {
    if (tabs.findIndex(tab => tab.tabName === tabName) === -1) {
      setTabs(prevTabs => [
        ...prevTabs,
        { id: tabName, isActive: activeTabName === tabName, tabName }
      ]);
    }
    const currentTab = tabs.find(tab => tab.tabName === tabName);

    if (currentTab) {
      if (currentTab.tabName === activeTabName && !currentTab.isActive) {
        const newTabs = tabs.map(tab => ({ ...tab, isActive: tab.tabName === currentTab.tabName }));
        setTabs(() => newTabs);
      }
      return {
        'aria-controls': `panel-${currentTab.id}`,
        'aria-selected': currentTab.isActive,
        id: `tab-${currentTab.id}`,
        onClick: () => onChange(currentTab.tabName),
        role: 'tab',
        tabIndex: currentTab.isActive ? 0 : -1,
        onKeyDown: e => changeTabs(e),
        isActive: currentTab.isActive
      };
    }
  };

  const getPanelProps = (tabName: string): PanelProps | Record<string, never> => {
    const currentTab = tabs.find(tab => tab.tabName === tabName);

    if (!currentTab) {
      return {};
    }
    return {
      'aria-labelledby': `tab-${currentTab.id}`,
      hidden: !currentTab.isActive,
      id: `panel-${currentTab.id}`,
      role: 'tabpanel',
      tabIndex: currentTab.isActive ? 0 : -1
    };
  };

  return children({ getTabProps, getPanelProps });
};

export default Tabs;
