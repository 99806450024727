import styled from '@emotion/styled';

import { mediumGray } from 'utils/colors';

const InputLabel = styled.label`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${mediumGray};
`;

export default InputLabel;
