export const defaultCurricularRouteSearchState = (): CurricularRouteSearchState<'title'> => ({
  filter: {
    filterType: 'text',
    filterQuery: { query: '', fields: ['title'] },

    // These fields are bandaids until we have a better route state solution in place
    programTypeFilter: '',
    programTypeLabel: 'All Program Types',
    programTypeClassFilter: '',
    programTypeClassLabel: 'All Categories'
  },
  sort: {
    sortType: 'title',
    sortOrder: 'ascending'
  }
});
