import React from 'react';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import CurricularSelectModalStyles from 'components/atoms/CurricularSelectModalStyles';
import SelectCoursesProgramForm from 'components/organisms/SelectCoursesProgramForm';
import StickyButtonBar from 'components/molecules/StickyButtonBar';

import { addSkillToCourse, invalidateProgramCaches } from 'services/curricularSkills';
import { getToastFnThatWeTreatLikeHook } from 'hooks';

import { fog } from 'utils/colors';

const ModalWrapper = styled.div`
  width: 110rem;
  background: ${fog};
`;

const StyledBar = styled(StickyButtonBar)`
  width: 100%;
`;

const AddCoursesWithSkillToProgramModal: React.FC<{
  closeModal: () => void;
  skillToAdd: { id: string; name: string };
  onSuccessfulSubmit?: () => void;
  program: { id: string; title?: string };
}> = ({ closeModal, skillToAdd, onSuccessfulSubmit, program }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const queryClient = useQueryClient();
  const { control } = useForm<{
    selectedCourses: { id: string; subtitle: string; title: string }[];
  }>();

  const { field: selectedCourses } = useController({
    name: 'selectedCourses',
    control,
    defaultValue: []
  });

  const queries = React.useMemo(() => ['jpa-ranking', 'course', 'courses'], [program.id]);

  const notification = getToastFnThatWeTreatLikeHook();
  const onAddSkillToCourses = async () => {
    setIsSubmitting(true);
    try {
      await Promise.all(
        selectedCourses.value.map(course => addSkillToCourse(course.id, skillToAdd.id))
      );
      await Promise.all(queries.map(cache => queryClient.invalidateQueries(cache)));
      await invalidateProgramCaches(program.id, queryClient);
    } catch (err) {
      // send something to Sentry
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        await onAddSkillToCourses()
          .then(() => {
            notification('Done! Skill Added.', 'success');
            closeModal();
            onSuccessfulSubmit && onSuccessfulSubmit();
          })
          .catch(err => {
            // Error should be sent to sentry eventually
          });
      }}
    >
      <ModalWrapper>
        <SelectCoursesProgramForm
          css={CurricularSelectModalStyles}
          headers={{
            left: `Add ${skillToAdd.name} to these courses in ${program.title}`,
            right: 'Selected Courses'
          }}
          additionalFilters={{ associatedGroups: [program.id] }}
          onChange={selectedCourses.onChange}
          locationStateKey="addCoursesWithSkillToProgramModal"
        />
        <StyledBar
          onCancel={closeModal}
          submitText="Save"
          isSubmitting={isSubmitting}
          disabled={!selectedCourses.value.length}
        />
      </ModalWrapper>
    </form>
  );
};

export default AddCoursesWithSkillToProgramModal;
