import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import DropdownFlyout, {
  DropdownSelectable,
  DropdownFlyoutProps
} from 'components/molecules/DropdownFlyout';
import { white, lightGray } from 'utils/colors';
import { useClassificationSkillsConcepts } from 'hooks/classificationHooks';

const SkillsByCategory: React.FC<{
  className?: string;
  setCategorySkills: React.Dispatch<React.SetStateAction<Skill[]>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ className, setCategorySkills, setIsLoading }) => {
  const [selectedCategory, setSelectedCategory] = useState<DropdownSelectable | undefined>(
    undefined
  );
  const [selectedSubcategory, setSelectedSubcategory] = useState<DropdownSelectable | undefined>(
    undefined
  );

  const { data: categories } = useClassificationSkillsConcepts({
    filter: {
      level: ['0']
    },
    limit: 1000
  });
  const categoryOptions = useMemo(() => {
    return categories
      ? categories.filter(c => c.name !== 'NULL').map(c => ({ label: c.name, value: c.id }))
      : [];
  }, [categories]);

  const { data: subcategories } = useClassificationSkillsConcepts(
    {
      filter: {
        level: ['1'],
        parentId: selectedCategory ? [selectedCategory.value] : []
      },
      limit: 1000
    },
    {
      queryOptions: { enabled: !!categories?.length && !!selectedCategory }
    }
  );
  const subcategoryOptions = useMemo(() => {
    return subcategories
      ? subcategories.filter(s => s.name !== 'NULL').map(c => ({ label: c.name, value: c.id }))
      : [];
  }, [subcategories]);

  const { data: skills, isFetching: isLoadingSkills } = useClassificationSkillsConcepts(
    {
      filter: {
        level: ['2'],
        parentId: selectedSubcategory ? [selectedSubcategory.value] : []
      },
      limit: 1000
    },
    {
      queryOptions: { enabled: !!selectedSubcategory }
    }
  );

  useEffect(() => {
    isLoadingSkills && setIsLoading && setIsLoading(true);
    if (skills) {
      setCategorySkills(skills.sort((a, b) => a.name.localeCompare(b.name)));
    }
    !isLoadingSkills && setIsLoading && setIsLoading(false);
  }, [isLoadingSkills, skills]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedSubcategory(undefined);
      setCategorySkills([]);
    }
  }, [selectedCategory]);

  return (
    <>
      <Flex>
        <CategoryDropdownFlyout
          dropDownName="skill-by-category"
          items={categoryOptions}
          menuPlaceholder="Select Skill Category"
          dropDownContentWidth="calc(100% - 2rem)"
          dropdownPlaceholder="Select Skill Category"
          onSelect={setSelectedCategory}
          selected={selectedCategory}
          className={className}
        />
        <SubCategoryDropdownFlyout
          dropDownName="skill-by-subcategory"
          items={subcategoryOptions}
          menuPlaceholder="Select Skill Sub-Category..."
          dropDownContentWidth="100%"
          dropdownPlaceholder={
            subcategoryOptions.length === 1
              ? `${subcategoryOptions[0].label}`
              : 'Select Skill Sub-Category...'
          }
          onSelect={setSelectedSubcategory}
          selected={selectedSubcategory}
          className={className}
          isDisabled={!selectedCategory || (categories && categories.length === 1)}
          isCategorySelected={!!selectedCategory}
        />
      </Flex>
    </>
  );
};
export default SkillsByCategory;

const Flex = styled.div`
  display: flex;
  margin: 2rem 0;
`;

const CategoryDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))`
  width: 34rem;
  background: ${white};
  margin-right: 2rem;
  font-weight: 400;
  font-size: 1.4rem;
`;

const SubCategoryDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))<{ isCategorySelected?: boolean }>`
  height: 100%;
  width: 34rem;
  background: ${white};
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ isCategorySelected }) => (isCategorySelected ? '' : `${lightGray}`)};

  &:disabled {
    opacity: 0.7;
    cursor: auto;
  }
`;
