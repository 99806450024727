import React from 'react';
import { View } from '@react-pdf/renderer';

import { Column, Expanded, Row } from './Flex';
import { HorizontalLine, VerticalLine } from './Line';
import { ReactElement } from 'react';
import { Box } from './Box';

export interface GridProps {
  rowsCount: number;
  cellHeight: number;
  cells: ReactElement[];
}

export const Grid: React.FC<GridProps> = ({ cells, cellHeight, rowsCount }) => {
  const rows = splitIntoRows(rowsCount, cells);

  return (
    <Box border>
      <Column>
        {fillBetween(
          rows.map(rowCells => (
            <Row key={`row-${rowCells.map(cell => cell.key).join('&')}`}>
              {fillBetween(
                rowCells.map(cell => (
                  <Expanded key={`cell-${cell.key}`}>
                    <View style={{ height: cellHeight }}>{cell}</View>
                  </Expanded>
                )),
                previous => (
                  <VerticalLine key={`line-${previous.key}`} />
                )
              )}
            </Row>
          )),
          previous => (
            <HorizontalLine key={`line-${previous.key}`} />
          )
        )}
      </Column>
    </Box>
  );
};

function splitIntoRows(rowsCount: number, cells: ReactElement[]) {
  const rows: ReactElement[][] = [];

  cells.forEach((cell, index) => {
    const rowIndex = Math.floor(index / rowsCount);
    const columnIndex = index % rowsCount;

    if (!rows[rowIndex]) {
      rows[rowIndex] = [];
    }

    rows[rowIndex][columnIndex] = cell;
  });

  return rows;
}

/** Returns a new array where the result of `filler` has been inserted between every item of `array` */
function fillBetween<T, N = T>(array: T[], filler: (previous: T, next: T) => N): (N | T)[] {
  const newArray: (N | T)[] = [];

  array.forEach((item, index) => {
    newArray.push(item);

    const nextValue = array[index + 1];
    if (!nextValue) {
      return;
    }

    newArray.push(filler(item, nextValue));
  });

  return newArray;
}
