import React, { useCallback, useState } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import Loading from 'components/atoms/Loading';
import { useCourse } from 'hooks';

import Card from 'components/atoms/Card';

import Header from 'components/molecules/Header';
import SkillsFromText from 'components/organisms/SkillsFromText';
import GeneralCourseInfo from 'components/organisms/GeneralCourseInfo';

import AdditionalSkillsCard from 'components/molecules/AdditionalSkillsCard';

import { ReactComponent as SkillsPlaceholderImage } from 'images/skillsByTextEmpty.svg';
import { mediumGray, white } from 'utils/colors';
import { ReactComponent as CourseIcon } from 'images/courseIcon.svg';
import { ReactComponent as Download } from 'images/cloudDownload.svg';

import { PreprocessedSkill } from 'services/skills';

import useExport from 'hooks/useExport';
import LoadingButton from 'components/atoms/LoadingButton';
import { INPUTS, getTextAreas } from 'components/organisms/CourseForm';
import { formatCourseExportData } from 'helpers/exports/exportCourse';

const StyledDownload = styled(Download)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;

  path {
    stroke: ${white};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 45rem;
`;

const CourseContainer = styled.div`
  max-width: 100%;
`;

const StyledSpan = styled.span`
  display: block;
`;

const HeavySpan = styled(StyledSpan)`
  font-weight: 600;
  margin-top: 2rem;
`;

const EmptySkillsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: 6rem auto 0;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
`;

const ViewCourse: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useCourse(id, true);
  const [revealedSkill, setRevealedSkill] = useState<string>();
  const [parsedSkills, setParsedSkills] = useState<readonly PreprocessedSkill[]>([]);
  const [, setParsingError] = useState<string | undefined>();

  const formatCourseExport = useCallback(() => {
    if (!course) {
      throw new Error("Course should never be exported if id doesn't exist");
    }

    return formatCourseExportData({
      formValues: course,
      selectedSkills: course.skills
    });
  }, [data?.data.attributes, data?.data.attributes.skills]);

  const [exportCourse, { exportIsLoading }] = useExport({
    exportDataFn: formatCourseExport,
    filename: data?.data.attributes.title || 'unnamed_program',
    isPreloading: false
  });

  const handleParseSkill = useCallback(
    (skills: PreprocessedSkill[]) => {
      if (skills && !skills.some(skill => skill.id === revealedSkill)) {
        setRevealedSkill(undefined);
      }
      setParsedSkills(skills);
    },
    [revealedSkill]
  );

  if (!data) {
    return (
      <Loading
        css={css`
          height: 100vh;
          width: 100vw;
          position: absolute;
          top: 0;
          right: 0;
        `}
      />
    );
  }

  const course = data.data.attributes;
  const { skills } = course;

  return (
    <>
      <Header
        css={css`
          margin: 1rem auto;
        `}
        headerText={`${course.title}`}
        Icon={CourseIcon}
      >
        <LoadingButton
          type="button"
          isLoading={exportIsLoading}
          onClick={() => {
            exportCourse();
          }}
        >
          <StyledDownload title="cloud download icon" />
          Export
        </LoadingButton>
      </Header>
      <CourseContainer>
        <Row>
          <GeneralCourseInfo
            revealedSkill={revealedSkill}
            onParseSkills={handleParseSkill}
            onParseError={setParsingError}
            css={css`
              margin-right: 2rem;
              flex: 2;
            `}
            inputs={INPUTS}
            textAreas={getTextAreas({ isReadOnly: true })}
            course={course}
          />
          <Column>
            {!parsedSkills?.length ? (
              <Card
                css={css`
                  max-height: 40rem;
                `}
              >
                <CardHeading>Skills From Text</CardHeading>
                <EmptySkillsContents>
                  <SkillsPlaceholderImage />
                  <HeavySpan>No skills to show yet</HeavySpan>
                  <StyledSpan>
                    Start typing, or paste in text to see what skills are in your curriculum
                  </StyledSpan>
                </EmptySkillsContents>
              </Card>
            ) : (
              <SkillsFromText
                selectedSkills={skills || []}
                parsedSkills={parsedSkills}
                revealedSkillId={revealedSkill}
                onReveal={skillId => {
                  if (skillId === revealedSkill) {
                    setRevealedSkill(undefined);
                  } else {
                    setRevealedSkill(skillId);
                  }
                }}
              />
            )}
            <AdditionalSkillsCard
              selectedSkills={skills || []}
              displaySkills={
                skills?.filter(
                  skill => !parsedSkills.some(parsedSkill => parsedSkill.id === skill.id)
                ) || []
              }
              css={css`
                margin-top: 2.5rem;
              `}
            />
          </Column>
        </Row>
      </CourseContainer>
    </>
  );
};

export default ViewCourse;
