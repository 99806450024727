import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Loading from 'components/atoms/Loading';
import MonitoredRoute from 'components/atoms/MonitoredRoute';
import GlobalHeader from 'components/molecules/GlobalHeader';
import Header from 'components/molecules/Header';
import Footer from 'components/molecules/GlobalFooter';
import Admin from 'components/pages/Admin';
import Course from 'components/pages/Course';
import CreateProgram from 'components/pages/CreateProgram';
import Dashboard from 'components/pages/Dashboard';
import Program from 'components/pages/Program';
import ViewCourse from 'components/pages/ViewCourse';
import SiteSelect from 'components/molecules/SiteSelectModal';
import SetupLocalStorage from 'components/organisms/SetupLocalStorage';
import Maintainance from 'components/pages/Maintainance';
import Breadcrumbs from 'components/molecules/Breadcrumbs';

import { getSite } from 'services/sites';
import { useProfileDispatch, useProfileState, getProfile } from 'store/profileStore';
import { CurrentSiteProvider } from 'store/currentSiteStore';
import { initializePendoUserData } from 'utils/pendo';
import { setSentryUser } from 'utils/sentry';

const MainLayout: React.FC = () => {
  const { maintainance } = useFlags();
  const location = useLocation();
  const { currentSite, isLoading } = useProfileState();
  const [siteInitialState, setSiteInitialState] = useState<SiteAttributes | undefined>();
  const profileDispatch = useProfileDispatch();

  useEffect(() => {
    getProfile(profileDispatch).then(profileAttributes => {
      setSentryUser(profileAttributes);
      initializePendoUserData(profileAttributes.currentSite);
    });
  }, []);

  useEffect(() => {
    if (currentSite) {
      getSite(currentSite).then(site => setSiteInitialState(site));
    }
  }, [currentSite]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <AppContainer>
      {maintainance ? (
        <Maintainance />
      ) : (
        <>
          <GlobalHeader />
          <Main>
            <ErrorBoundary
              beforeCapture={scope => {
                scope.setTag('location', 'main-layout');
              }}
              fallback={() => (
                <div>
                  <Header headerLevel="h3" headerText="Oh no, something went wrong!" />
                  <p>Please try refreshing the page or logging back in.</p>
                </div>
              )}
            >
              {!isLoading && !currentSite ? (
                <SiteSelect message="" height={30} closeButton={false} />
              ) : siteInitialState ? (
                <CurrentSiteProvider
                  initialState={siteInitialState}
                  currentSiteString={currentSite}
                >
                  <SetupLocalStorage />
                  <Breadcrumbs />
                  <Switch>
                    <MonitoredRoute path="/dashboard" component={Dashboard} />
                    <MonitoredRoute
                      path={['/duplicate/course/:duplicateId', '/add/course/', '/edit/course/:id']}
                      component={Course}
                    />
                    <MonitoredRoute path="/course/summary/:id" component={ViewCourse} />
                    <MonitoredRoute
                      path={[
                        '/duplicate/program/:duplicateId',
                        '/add/program',
                        '/edit/program/:id'
                      ]}
                      component={CreateProgram}
                    />
                    <MonitoredRoute
                      path={['/program/summary/:id', '/program/insights/:id']}
                      component={Program}
                    />
                    <MonitoredRoute path="/admin" component={Admin} />
                  </Switch>
                </CurrentSiteProvider>
              ) : (
                <PaddedLoader />
              )}
            </ErrorBoundary>
          </Main>
          <Footer />
        </>
      )}
    </AppContainer>
  );
};

export default MainLayout;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Main = styled.div`
  flex-grow: 1;
  margin: auto;
  width: 80%;

  @media (max-width: 1366px) {
    width: 90%;
  }
  @media (min-width: 1920px) {
    width: 70%;
  }
`;

const PaddedLoader = styled(Loading)`
  margin-top: 25vh;
`;
