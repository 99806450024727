import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/atoms/Modal';
import styled from '@emotion/styled';
import Button from 'components/atoms/Button';

interface Confirmation {
  header: string;
  content: string;
  cancelButtonText: string;
  continueButtonText: string;
  onContinue(): void;
}

interface ProviderState {
  showConfirmation(params: Confirmation): void;
}

const providedContext = createContext({} as ProviderState);

export const NavigationBlockerProvider: React.FC = ({ children }) => {
  const [activeConfirmations, setActiveConfirmations] = useState<Confirmation[]>([]);
  const confirmation = activeConfirmations[0] as Confirmation | undefined;

  const showConfirmation = useCallback((params: Confirmation) => {
    setActiveConfirmations(prev => [...prev, params]);
  }, []);

  const closeActiveConfirmation = useCallback(() => {
    setActiveConfirmations(prev => prev.slice(0, -1));
  }, [activeConfirmations]);

  const contextState = useMemo<ProviderState>(() => ({ showConfirmation }), [showConfirmation]);

  return (
    <providedContext.Provider value={contextState}>
      {children}
      {confirmation && (
        <Modal closeModal={closeActiveConfirmation}>
          <ModalContainer>
            <ConfirmHeader>{confirmation.header}</ConfirmHeader>
            <ConfirmContent>{confirmation.content}</ConfirmContent>
            <ActionsContainer>
              <Button type="button" scheme="outline" onClick={closeActiveConfirmation}>
                {confirmation.cancelButtonText}
              </Button>
              <Button
                type="button"
                scheme="danger"
                onClick={() => {
                  closeActiveConfirmation();
                  confirmation.onContinue();
                }}
              >
                {confirmation.continueButtonText}
              </Button>
            </ActionsContainer>
          </ModalContainer>
        </Modal>
      )}
    </providedContext.Provider>
  );
};

export interface UseNavigationBlockerParams extends Partial<Omit<Confirmation, 'onContinue'>> {
  when: boolean;
}

export const useNavigationBlocker = (params: UseNavigationBlockerParams) => {
  const history = useHistory();
  const context = useContext(providedContext);

  useEffect(() => {
    if (!params.when) {
      return;
    }

    const unblock = history.block(location => {
      context.showConfirmation({
        header: params.header || 'Are you sure you want to leave?',
        content: params.content || 'You will lose any unsaved changes',
        cancelButtonText: params.cancelButtonText || 'Cancel',
        continueButtonText: params.continueButtonText || 'Leave',
        onContinue() {
          unblock();
          history.push(location);
        }
      });

      return false;
    });

    const listener = (event: Event) => {
      event.preventDefault();

      // @ts-expect-error TS doesn't understand that we have to do this in order to get the browser modal to show up
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', listener);

    return () => {
      unblock();
      window.removeEventListener('beforeunload', listener);
    };
  }, [
    history,
    params.when,
    params.header,
    params.content,
    params.continueButtonText,
    params.continueButtonText
  ]);
};

const ModalContainer = styled.div`
  padding: 2rem;
  padding-top: 0;
  width: 60rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ConfirmHeader = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;

const ConfirmContent = styled.div`
  text-align: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
