import { QueryClient } from 'react-query';
import { fetchWithCognitoToken } from './cognito';
import { convertCurricularUnitResponseIdsToMongo } from 'utils/curricularSkills';

const domain = process.env.REACT_APP_CURRICULAR_SKILLS_API_URL;

export const searchCourses = async (
  payload: CourseSearchPayload
): Promise<CurricularData<Course>> => {
  const { filter, ...rest } = payload;

  const response = await fetchWithCognitoToken(`${domain}/courses/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'courseSearch',
        attributes: {
          options: { expand: ['skills'] },
          filter: {
            ...filter
          },
          ...rest
        }
      }
    })
  });

  if (!response.ok) {
    throw new Error('Could not fetch courses from curricular skills');
  }

  const courses = (await response.json()) as CurricularData<Course>;
  return courses;
};
export const searchPrograms = async (
  payload: ProgramSearchPayload
): Promise<CurricularData<Program>> => {
  const response = await fetchWithCognitoToken(`${domain}/groups/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'groupSearch',
        attributes: payload
      }
    })
  });
  if (!response.ok) {
    throw new Error('Could not fetch programs from curricular skills');
  }
  return await response.json();
};
export const searchProgramTypes = async (
  payload: ProgramTypeSearchPayload
): Promise<CurricularData<ProgramType>> => {
  const response = await fetchWithCognitoToken(`${domain}/group-types/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'groupTypeSearch',
        attributes: payload
      }
    })
  });

  if (!response.ok) {
    throw new Error('Could not fetch program types from curricular skills');
  }
  return await response.json();
};

export const searchBenchmarks = async (
  payload: BenchmarkSearchPayload
): Promise<CurricularData<Benchmark>> => {
  const response = await fetchWithCognitoToken(`${domain}/benchmarks/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'benchmarkSearch',
        attributes: payload
      }
    })
  });

  if (!response.ok) {
    throw new Error('Could not fetch benchmarks from curricular skills');
  }
  const { meta, data } = await response.json();
  const modifiedData = data.map(
    (benchmark: { id: string; type: string; attributes: Benchmark }) => ({
      id: benchmark.id,
      type: benchmark.type,
      attributes: { ...benchmark.attributes, id: benchmark.id }
    })
  );
  return { meta, data: modifiedData };
};

export const deleteBenchmark = async (id: string): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/benchmarks/${id}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    throw new Error(`Could not delete selected benchmark`);
  }
};

export const getAllProgramTypeClasses = async (): Promise<CurricularData<ProgramTypeClass>> => {
  const response = await fetchWithCognitoToken(`${domain}/group-type-class`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await response.json();
};

export const updateCourse = async (
  id: string,
  courseBody: Partial<Omit<Course, 'site'>>
): Promise<SingleCurriculum<Course>> => {
  const response = await fetchWithCognitoToken(`${domain}/courses/${id}?expand=skills`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'course',
        attributes: courseBody
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not update course.`);
  }

  const course = (await response.json()) as SingleCurriculum<Course>;
  return course;
};

export const createCourse = async (
  courseBody: Omit<Course, 'createdAt' | 'updatedAt'>
): Promise<SingleCurriculum<Course>> => {
  const response = await fetchWithCognitoToken(`${domain}/courses?expand=skills`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'course',
        attributes: courseBody
      }
    })
  });
  if (!response.ok) {
    throw new Error('Could not create course');
  }

  const course = (await response.json()) as SingleCurriculum<Course>;
  return course;
};

interface BulkResponse {
  data: {
    status: 200;
    title: string;
    patchedCount?: number;
    deletedCount?: number;
  };
}

export const bulkUpdateCourses = async (
  updates: { isPublished?: boolean; url?: string; credits?: number },
  filter: CourseSearchFilters
): Promise<BulkResponse> => {
  const response = await fetchWithCognitoToken(`${domain}/batch/courses`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'coursePatch',
        attributes: {
          updates,
          filter
        }
      }
    })
  });

  if (!response.ok) {
    throw new Error('Could not bulk update courses');
  }

  return await response.json();
};

export const updateCurricularUnit = async (
  id: string,
  body: CurricularUnitPatchBody
): Promise<SingleCurriculum<CurricularUnitResponse>> => {
  const response = await fetchWithCognitoToken(`${domain}/curricular-units/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'curricularUnit',
        attributes: body
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not update program.`);
  }

  const data = (await response.json()) as SingleCurriculum<CurricularUnitResponse>;

  return convertCurricularUnitResponseIdsToMongo(data);
};

export const createCurricularUnit = async (
  body: CurricularUnitPostBody
): Promise<SingleCurriculum<CurricularUnitResponse>> => {
  const response = await fetchWithCognitoToken(`${domain}/curricular-units`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'curricularUnit',
        attributes: body
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not create curricular unit.`);
  }

  const data = (await response.json()) as SingleCurriculum<CurricularUnitResponse>;

  return convertCurricularUnitResponseIdsToMongo(data);
};

export const bulkUpdatePrograms = async (
  updates: { isPublished?: boolean; url?: string; programTypeClass?: string },
  filter: ProgramSearchFilters
): Promise<BulkResponse> => {
  const response = await fetchWithCognitoToken(`${domain}/batch/groups`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'groupPatch',
        attributes: {
          updates: {
            ...updates,
            groupTypeClass: updates.programTypeClass
          },
          filter
        }
      }
    })
  });

  if (!response.ok) {
    throw new Error('Could not bulk update courses');
  }

  return await response.json();
};

export const createProgramType = async ({
  groupTypeClassId,
  label,
  site
}: {
  groupTypeClassId: string;
  label: string;
  site: string;
}): Promise<SingleCurriculum<ProgramType>> => {
  const response = await fetchWithCognitoToken(`${domain}/group-types`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'groupType',
        attributes: {
          site,
          groupTypeClass: groupTypeClassId,
          label
        }
      }
    })
  });
  if (!response.ok) {
    throw new Error(`Could not create new program type.`);
  }

  return await response.json();
};

export const updateProgramType = async (
  id: string,
  {
    groupTypeClassId,
    label
  }: {
    groupTypeClassId?: string;
    label?: string;
  }
): Promise<SingleCurriculum<ProgramType>> => {
  const response = await fetchWithCognitoToken(`${domain}/group-types/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'groupType',
        attributes: {
          groupTypeClass: groupTypeClassId,
          label
        }
      }
    })
  });
  if (!response.ok) {
    throw new Error(`Could not update this program type.`);
  }

  return await response.json();
};

export const getCourseById = async (
  id: string,
  includeSyllabus?: boolean
): Promise<SingleCurriculum<Course>> => {
  const response = await fetchWithCognitoToken(
    `${domain}/courses/${id}?expand=skills${includeSyllabus ? '&includeSyllabus=true' : ''}`
  );
  if (!response.ok) {
    throw new Error(`Could not fetch course.`);
  }
  const course = (await response.json()) as SingleCurriculum<Course>;
  return course;
};

export const getCurricularUnitById = async (
  id: string
): Promise<SingleCurriculum<CurricularUnitResponse>> => {
  const response = await fetchWithCognitoToken(`${domain}/curricular-units/${id}`);
  if (!response.ok) {
    throw new Error(`Could not fetch curricular unit.`);
  }
  const data = (await response.json()) as SingleCurriculum<CurricularUnitResponse>;

  return convertCurricularUnitResponseIdsToMongo(data);
};

export const deleteCourse = async (id: string): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/courses/${id}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    throw new Error(`Could not delete course`);
  }
};

export const bulkDeleteCourses = async (filter: CourseSearchFilters): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/courses/delete`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'courseDelete',
        attributes: {
          filter
        }
      }
    })
  });
  if (!response.ok) {
    throw new Error(`Could not delete selected courses`);
  }
  return await response.json();
};

export const bulkDeletePrograms = async (filter: ProgramSearchFilters): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/groups/delete`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'groupDelete',
        attributes: {
          filter
        }
      }
    })
  });
  if (!response.ok) {
    throw new Error(`Could not delete selected programs`);
  }
  return await response.json();
};

export const deleteProgram = async (id: string): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/groups/${id}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    throw new Error(`Could not delete program`);
  }
};

export const deleteProgramType = async (id: string): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/group-types/${id}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    throw new Error(`Could not delete selected program`);
  }
};

export interface SkillCountResponse {
  data: { resourceType: 'course' | 'group'; counts: { [skillId: string]: number } };
}
export const getSkillCounts = async (
  resourceType: 'course' | 'program',
  skillIds: string[],
  site: string
): Promise<SkillCountResponse> => {
  // deduplicate skills
  const array = [...new Set(skillIds)];
  const requests = [];
  // split into multiple requests, if needed
  while (array.length) {
    requests.push(array.splice(0, 5000));
  }
  const responses = await Promise.all(
    requests.map(someSkillIds =>
      fetchWithCognitoToken(`${domain}/skills/count`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            resourceType,
            attributes: {
              sites: {
                in: [site]
              },
              skillIds: someSkillIds
            }
          }
        })
      })
    )
  );

  if (responses.some(res => !res.ok)) {
    throw new Error('Could not get skill counts');
  }

  const responseBodys = await Promise.all(responses.map(res => res.json()));
  // combine responses into one
  return responseBodys.reduce((acc, res) => {
    acc.data.counts = { ...acc.data.counts, ...res.data.counts };
    return acc;
  }) as SkillCountResponse;
};

export const getCoursesForProgram = (
  programId: string,
  site: string
): Promise<CurricularData<Course>> => {
  return depaginateCourses({
    limit: 100,
    filter: { site: { in: [site] }, associatedGroups: { in: [programId] } }
  });
};

export const getCoursesBySkills = async (
  skills: string[],
  site: string
): Promise<CurricularData<Course>> => {
  if (!skills.length) {
    return { data: [], meta: { count: 0, totalAvailable: 0 } };
  }
  return depaginateCourses({
    limit: 100,
    filter: { site: { in: [site] }, skills: { in: skills } }
  });
};

const depaginateCourses = async (
  nextPayload: CourseSearchPayload
): Promise<CurricularData<Course>> => {
  const response = await searchCourses(nextPayload);
  const { meta } = response;
  if (meta.count >= meta.totalAvailable) {
    return response;
  }

  const nextRequests = [];
  const pages = Math.ceil(meta.totalAvailable / 100);
  for (let i = 1; i < pages; ++i) {
    const offset = i * 100;
    nextRequests.push(searchCourses({ ...nextPayload, offset }));
  }
  const allResponses = [response, ...(await Promise.all(nextRequests))];
  return {
    meta: { count: meta.totalAvailable, totalAvailable: meta.totalAvailable },
    data: allResponses.map(res => res.data).flat()
  };
};

export const getGroupTypesForProgram = async (
  site: string
): Promise<CurricularData<ProgramType>> => {
  const firstResponse = await searchProgramTypes({
    filter: { site: { in: [site] } },
    limit: 100
  });
  const { meta } = firstResponse;

  if (meta.count >= meta.totalAvailable) {
    return firstResponse;
  }
  const requests = [];
  const pages = Math.ceil(meta.totalAvailable / 100);
  for (let i = 1; i < pages; ++i) {
    const offset = i * 100;
    requests.push(searchProgramTypes({ limit: 100, offset }));
  }
  const allResponses = [firstResponse, ...(await Promise.all(requests))];
  return {
    meta: { count: meta.totalAvailable, totalAvailable: meta.totalAvailable },
    data: allResponses.map(res => res.data).flat()
  };
};

export const addSkillToCourse = async (
  courseId: string,
  skillId: string,
  isLearningObjective = false
): Promise<void> => {
  try {
    const foundCourse = await getCourseById(courseId);
    const { skills } = foundCourse.data.attributes;
    const updatedSkills = [...(skills || [])];
    updatedSkills.push({ id: skillId, isLearningObjective });
    await updateCourse(courseId, { skills: updatedSkills });
  } catch (err) {
    throw new Error('Could not add skill to all courses');
  }
};

export const invalidateProgramCaches = async (
  programId: string,
  queryClient: QueryClient
): Promise<void> => {
  const queryKeys = ['programs', 'skill-counts', programId];

  // Invalidate any cache with this program id as a key
  return queryClient.invalidateQueries({
    predicate: haystack => queryKeys.some(needle => haystack.queryKey.includes(needle))
  });
};

const createBenchmark = async (
  benchmark: Omit<Benchmark, 'id' | 'deletedAt'>
): Promise<SingleCurriculum<Benchmark>> => {
  const createdBenchmarkResponse = await fetchWithCognitoToken(`${domain}/benchmarks`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: benchmark.type,
        attributes: benchmark
      }
    })
  });

  if (!createdBenchmarkResponse.ok) {
    const e = new Error();
    e.name = `BENCHMARK_CS_API_${createdBenchmarkResponse.status}`;
    e.message = `Error creating benchmark: ${await createdBenchmarkResponse.text()}`;
    throw e;
  }

  return createdBenchmarkResponse.json();
};

export const addCustomBenchmarkToProgram = async (
  programId: string,
  benchmark: Omit<Benchmark, 'id' | 'deletedAt'>,
  selected = true
) => {
  const newBenchmark = await createBenchmark(benchmark);

  const { benchmarks: oldBenchmarks } = (await getCurricularUnitById(programId)).data.attributes;

  await updateCurricularUnit(programId, {
    benchmarks: [...oldBenchmarks, { id: newBenchmark.data.id, selected }]
  });
  return newBenchmark;
};
