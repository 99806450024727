import React from 'react';
import { View } from '@react-pdf/renderer';

export interface PaddingProps {
  padding?: number;
  paddingHorizontal?: number;
  paddingVertical?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
}

export const Padding: React.FC<PaddingProps> = props => {
  return (
    <View
      style={{
        padding: props.padding,
        paddingHorizontal: props.paddingHorizontal,
        paddingVertical: props.paddingVertical,
        paddingTop: props.paddingTop,
        paddingRight: props.paddingRight,
        paddingBottom: props.paddingBottom,
        paddingLeft: props.paddingLeft
      }}
    >
      {props.children}
    </View>
  );
};
