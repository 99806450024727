import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import { darkDarkSaphire, white } from 'utils/colors';

import { useTableDispatch, useTableState } from 'store/tableStore';

import { ReactComponent as PageArrow } from 'images/pageArrow.svg';
import { useQueryString } from 'hooks';

const Pages = styled.div`
  display: flex;
  justify-content: center;
  background: ${white};
  margin-bottom: 3rem;
  padding: 2.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0 0 0.4rem 0.4rem;
`;

const PageNumber = styled.button<{ isActive?: boolean }>`
  padding: 0.5rem;
  color: ${darkDarkSaphire};
  background: transparent;
  border: none;
  font-size: 1.4rem;
  ${({ isActive }) => isActive && `border-bottom: 1px solid ${darkDarkSaphire};`}
`;

interface PaginationProps {
  pageCount: number;
  trackPages?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({ pageCount, trackPages }) => {
  const { page: queryPage } = useQueryString();
  const history = useHistory();
  const { page } = useTableState();
  const currentPage = trackPages ? Number(queryPage) || 1 : page;
  const tableDispatch = useTableDispatch();
  const location = useLocation<
    CurricularRouteSearchState<CourseSortOption | ProgramSortOption> | undefined
  >();
  const locationState = location.state || defaultCurricularRouteSearchState();

  const addPageToHistory = (direction?: 'previous' | 'next', pageNumber?: number) => {
    if (direction) {
      history.push(
        `?page=${direction === 'previous' ? currentPage - 1 : currentPage + 1}`,
        locationState
      );
    } else if (pageNumber !== undefined) {
      history.push(`?page=${pageNumber}`, locationState);
    }
  };

  const nextPage = () => {
    trackPages ? addPageToHistory('next') : tableDispatch({ type: 'NEXT_PAGE' });
  };

  const previousPage = () => {
    trackPages ? addPageToHistory('previous') : tableDispatch({ type: 'PREVIOUS_PAGE' });
  };

  const pickPage = (newPage: number) => {
    trackPages
      ? addPageToHistory(undefined, newPage)
      : tableDispatch({ type: 'PICK_PAGE', payload: newPage });
  };

  let pageList;

  if (pageCount < 7) {
    pageList = Array.from(Array(pageCount).keys(), (_, i) => i + 1);
  } else if (currentPage < 3) {
    pageList = [1, 2, 3, 4, '...', pageCount];
  } else if (currentPage > pageCount - 3) {
    pageList = [1, '...', pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
  } else {
    pageList = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', pageCount];
  }

  return (
    <Pages>
      {currentPage !== 1 && (
        <PageNumber onClick={previousPage} aria-label="previous page">
          <PageArrow
            css={css`
              transform: scaleX(-1);
              cursor: pointer;
            `}
          />
        </PageNumber>
      )}
      {pageList.map((number, i) =>
        typeof number === 'string' ? (
          <PageNumber as="span" key={`page-${i}`}>
            {number}
          </PageNumber>
        ) : (
          <PageNumber
            as="button"
            aria-label={`page ${number} of ${pageCount}`}
            onClick={() => pickPage(number)}
            key={`page-${i}`}
            isActive={currentPage === number}
            tabIndex={0}
          >
            {number}
          </PageNumber>
        )
      )}
      {currentPage !== pageCount && pageCount !== 0 && (
        <PageNumber onClick={nextPage} aria-label="next page">
          <PageArrow
            css={css`
              cursor: pointer;
            `}
          />
        </PageNumber>
      )}
    </Pages>
  );
};

export default Pagination;
