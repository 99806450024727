/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { white } from 'utils/colors';

const CurricularSelectModalStyles = css`
  background: transparent;
  margin: 0;
  margin-top: -3.5rem;
  box-shadow: none;
  align-items: center;

  input {
    background: ${white};
  }

  & > div:first-of-type > div {
    & > div {
      background: ${white};
      margin-right: 0;
    }
    max-height: 34rem;
    display: block;
  }

  & > div:last-of-type {
    height: 40rem;
    overflow: auto;
    margin: 5rem 2.5rem 0;
  }
`;

export default CurricularSelectModalStyles;
