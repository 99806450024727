import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { useMutation, UseMutationResult, useQueryClient, UseMutationOptions } from 'react-query';
import {
  createProfile,
  updateProfileByEmail,
  deleteProfile,
  resendUserInvite
} from 'services/profiles';

interface UpdateUserVariables {
  email: string;
  attributes?: Partial<ProfileAttributes | PatchableProfileAttributes>;
}

type MutateUserOptions = UseMutationOptions<
  SingleProfileResponse,
  unknown,
  UpdateUserVariables,
  unknown
>;

export const useUpdateUser = (
  options?: MutateUserOptions
): UseMutationResult<SingleProfileResponse, unknown, UpdateUserVariables, unknown> => {
  const queryClient = useQueryClient();
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<SingleProfileResponse, unknown, UpdateUserVariables, unknown>({
    onError: () => toast('Sorry, we were unable to update that user. Please try again.', 'error'),
    onSuccess: () => {
      toast('Done! User updated.');
      queryClient.invalidateQueries(['profiles']);
    },
    mutationFn: ({ email, attributes }: UpdateUserVariables) =>
      updateProfileByEmail(
        email,
        attributes as Partial<ProfileAttributes | PatchableProfileAttributes>
      ),
    ...options
  });
};

export const useResendUserInvite = (
  options?: MutateUserOptions
): UseMutationResult<SingleProfileResponse, unknown, UpdateUserVariables, unknown> => {
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<SingleProfileResponse, unknown, UpdateUserVariables, unknown>({
    onError: () =>
      toast('Sorry, we were unable to resend the invitation email. Please try again.', 'error'),
    onSuccess: () => {
      toast('Done! Verification email sent.');
    },
    mutationFn: ({ email }: UpdateUserVariables) => resendUserInvite(email),
    ...options
  });
};

interface CreateProfileVariables {
  attributes: Partial<ProfileAttributes | PatchableProfileAttributes>;
}

type CreateUserOptions = UseMutationOptions<
  SingleProfileResponse,
  unknown,
  CreateProfileVariables,
  unknown
>;

export const useCreateUser = (
  options?: CreateUserOptions
): UseMutationResult<SingleProfileResponse, unknown, CreateProfileVariables, unknown> => {
  const queryClient = useQueryClient();
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<SingleProfileResponse, unknown, CreateProfileVariables, unknown>({
    onError: () => toast('Sorry, we were unable to create that user. Please try again.', 'error'),
    onSuccess: () => {
      toast('Done! User created.');
      queryClient.invalidateQueries(['profiles']);
    },
    mutationFn: ({ attributes }: CreateProfileVariables) => createProfile(attributes),
    ...options
  });
};

export const useDeleteUser = (): UseMutationResult<void, unknown, string, unknown> => {
  const queryClient = useQueryClient();
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<void, unknown, string, unknown>({
    onError() {
      toast('Sorry, we were unable to delete that user. Please try again.', 'error');
    },
    onSuccess() {
      toast('Done! User deleted.');
      queryClient.invalidateQueries(['profiles']);
    },
    mutationFn: (email: string) => deleteProfile(email)
  });
};
