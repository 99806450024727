import React, { useState } from 'react';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';

import AuthorizationForm from 'components/organisms/AuthorizationForm';
import FormInput from 'components/molecules/FormInput';
import { forgotPassword } from 'services/cognito';

import { ReactComponent as CheckCircle } from 'images/checkCircle.svg';

interface Inputs {
  email: string;
}

const SubmittedMessage = styled.span`
  font-size: 1.4rem;
  display: block;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
`;

const ForgotPasswordForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string | undefined>(undefined);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    shouldFocusError: false,
    mode: 'onTouched'
  });

  const onSubmit: SubmitHandler<Inputs> = ({ email }) => {
    forgotPassword(email)
      .then(() => {
        setIsSubmitted(true);
        setSubmissionError(undefined);
      })
      .catch(err => {
        // Unfortunately, John and I theorized this may be the best way to do this as the error is from AWS
        if (err.message === 'User password cannot be reset in the current state.') {
          setSubmissionError(
            'It looks like you still need to verify your email. To do this, search your email inbox for a "Welcome to Skillabi" email from no-reply@skillabi.com and follow the instructions to set up your account.'
          );
        } else {
          setSubmissionError(err.message);
        }
      });
  };
  return (
    <AuthorizationForm
      headerText="Password Reset"
      submitButtonText="Send Recovery Email"
      linkText="Back to Login"
      linkTo="/"
      onSubmit={handleSubmit(onSubmit)}
      isSubmitted={isSubmitted}
      errorMessage={submissionError}
    >
      {isSubmitted ? (
        <SubmittedMessage>
          <CheckCircle style={{ marginRight: '0.5rem' }} /> Check your email&apos;s inbox for a
          password reset email.
        </SubmittedMessage>
      ) : (
        <FormInput
          type="text"
          labelText="Email"
          placeholder="Enter email..."
          {...register('email', { required: 'Email Required' })}
          error={errors.email}
          required
        />
      )}
    </AuthorizationForm>
  );
};

export default ForgotPasswordForm;
