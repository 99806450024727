import styled from '@emotion/styled';
import Informative from 'components/molecules/Informative';
import React from 'react';

interface VictoryInformativeLabelProps {
  informativeContent: React.ReactElement;
  width: string;
  height: string;
  shouldClosePopup?: boolean;
  labelComponent?: React.ReactElement;
  x?: number;
  y?: number;
  dx?: number;
  dy?: number;
  text?: string;
  containerStyles?: React.CSSProperties;
}

const VictoryInformativeLabel = ({
  informativeContent,
  width,
  height,
  shouldClosePopup,
  labelComponent,
  x = 0,
  y = 0,
  dx = 0,
  dy = 0,
  text,
  containerStyles
}: VictoryInformativeLabelProps) => (
  <g>
    <foreignObject x={x + dx} y={y + dy} width={width} height={height}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.2rem',
          flexWrap: 'wrap',
          ...containerStyles
        }}
      >
        {labelComponent ? labelComponent : <DefaultLabel>{text}</DefaultLabel>}
        <InformativeContainer>
          <Informative
            size={'1.2rem'}
            shouldClose={shouldClosePopup}
            renderContent={() => informativeContent}
          />
        </InformativeContainer>
      </div>
    </foreignObject>
  </g>
);

const InformativeContainer = styled.div`
  z-index: 1;
`;

const DefaultLabel = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
`;

export default VictoryInformativeLabel;
