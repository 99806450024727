import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import EmptyDashboardTable from 'components/atoms/EmptyDashboardTable';
import LoadingDashboardTable from 'components/atoms/LoadingDashboardTable';
import DashboardTableStyles from 'components/atoms/DashboardTableStyles';
import Table, { HeaderCell, Cell, Column } from 'components/molecules/Table';
import Pagination from 'components/molecules/Pagination';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import EditUserForm, { EditUserFormDefaultValues } from 'components/organisms/EditUserForm';

import { ReactComponent as Clock } from 'images/clock.svg';
import { ReactComponent as SearchIcon } from 'images/magnifying-glass.svg';
import { ReactComponent as SortArrow } from 'images/sortArrow.svg';
import { darkBlue, darkGray, lightGray, white } from 'utils/colors';
import { TableProvider, useTableState, useTableDispatch } from 'store/tableStore';
import { DEFAULT_DASHBOARD_TABLE_PAGE_SIZE } from 'hooks';
import { useProfileState } from 'store/profileStore';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultProfileRouteSearchState } from 'utils/defaultProfileRouteSearchState';

export function paginate<T>(arr: T[], currentPageNum: number) {
  return arr.slice(
    (currentPageNum - 1) * DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    DEFAULT_DASHBOARD_TABLE_PAGE_SIZE * currentPageNum
  );
}

const columns: Column<PreppedProfile>[] = [
  {
    id: 'personal',
    renderHeader: ({ onSort, sortedColumn }) => (
      <HeaderCell width="30%" key="user-header">
        <SortableHeader onClick={() => onSort?.('user')}>
          User
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'user'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={row.id}>
        <PersonalInfo>
          <Name>{row.personal.name}</Name>
          <Email>{row.personal.email}</Email>
        </PersonalInfo>
      </Cell>
    )
  },
  {
    id: 'userReadableSites',
    renderHeader: () => (
      <HeaderCell width="30%" key="sites-header">
        Sites
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`sites-${row.id}`}>
        <div>{row.userReadableSites}</div>
      </Cell>
    )
  },
  {
    id: 'role',
    renderHeader: ({ onSort, sortedColumn }) => (
      <HeaderCell width="20%" key="role-header">
        <SortableHeader onClick={() => onSort?.('role')}>
          Role
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'role'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`role-${row.id}`}>
        <div>{row.role}</div>
      </Cell>
    )
  },
  {
    id: 'createdAt',
    renderHeader: ({ onSort, sortedColumn }) => (
      <StyledHeaderCell key="created-header">
        <SortableHeader onClick={() => onSort?.('createdAt')}>
          Created
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'createdAt'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </StyledHeaderCell>
    ),
    renderCell: row => (
      <Cell key={`created-at-${row.id}`}>
        <Flex>
          {row.createdAt} <StyledClock />
        </Flex>
      </Cell>
    )
  }
];

interface UsersAdminTableProps {
  profiles?: PreppedProfile[];
}

const LOCATION_STATE_KEY = 'userDashboardFilters';

const UsersAdminTable: React.FC<UsersAdminTableProps> = ({ profiles }) => {
  const profileState = useProfileState();
  const hasMultiSiteAccess = Object.keys(profileState.accessLevels || {}).length > 1;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userData, setUserData] = React.useState<EditUserFormDefaultValues>();
  const [inputValue, setInputValue] = React.useState('');
  const closeModal = () => setIsModalOpen(false);

  function filterProfile(profile: PreppedProfile) {
    if (hasMultiSiteAccess) {
      return (
        profile.userReadableSites.toLowerCase().includes(inputValue.toLowerCase()) ||
        profile.personal.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        profile.personal.email.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      return (
        profile.personal.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        profile.personal.email.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  }
  const filteredProfiles = profiles?.filter(filterProfile) || [];

  const { page: currentPage } = useTableState();
  const tableDispatch = useTableDispatch();

  const pageCount = profiles
    ? Math.ceil(filteredProfiles.length / DEFAULT_DASHBOARD_TABLE_PAGE_SIZE)
    : 0;

  const history = useHistory();
  const location = useLocation<{
    [x: string]: ProfileRouteSearchState<UserSortOption> | undefined;
  }>();
  const state = location?.state?.[LOCATION_STATE_KEY] || defaultProfileRouteSearchState();

  function compareProfiles(a: PreppedProfile, b: PreppedProfile) {
    switch (state.sort.sortType) {
      case 'user': {
        const nameA = a.personal.name.toUpperCase();
        const nameB = b.personal.name.toUpperCase();
        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      }
      case 'role': {
        let comparison = 0;
        if (a.role > b.role) {
          comparison = 1;
        } else if (a.role < b.role) {
          comparison = -1;
        }
        return comparison;
      }
      case 'createdAt': {
        let comparison = 0;
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
        return comparison;
      }
    }
  }

  const sortedProfiles = React.useMemo(
    () =>
      state.sort.sortOrder === 'ascending'
        ? filteredProfiles.sort(compareProfiles)
        : filteredProfiles.sort(compareProfiles).reverse(),
    [state, filteredProfiles]
  );

  const sortTable = React.useCallback(
    (column: UserSortOption) => {
      const { sortOrder, sortType } = state.sort;
      let newSortOrder: 'ascending' | 'descending' = 'ascending';
      if (column === sortType) {
        newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
      }
      history.push(location.pathname, {
        [LOCATION_STATE_KEY]: {
          sort: { sortOrder: newSortOrder, sortType: column }
        }
      });
    },
    [state]
  );

  return (
    <TableWrapper>
      <SearchInputContainer>
        <InputWrapper>
          <StyledSearchIcon />
          <StyledInput
            id="user-search"
            type="text"
            aria-label={
              hasMultiSiteAccess
                ? `Search for a user, email, or site`
                : `Search for a user or email`
            }
            placeholder={
              hasMultiSiteAccess
                ? `Search for a user, email, or site`
                : `Search for a user or email`
            }
            onChange={({ target: { value } }) => {
              tableDispatch({ type: 'PICK_PAGE', payload: 1 });
              setInputValue(value);
            }}
            value={inputValue}
          />
        </InputWrapper>
      </SearchInputContainer>
      <StyledHr />
      {profiles ? (
        <>
          <Table
            tableCaption="admin dashboard table"
            columns={columns}
            data={paginate(sortedProfiles, currentPage)}
            onSort={sortTable}
            sortedColumn={state.sort}
            css={css`
              ${DashboardTableStyles}
              tr > td:last-of-type,
              th:last-of-type {
                text-align: right;
                padding-right: 5rem;
              }
            `}
            EmptyComponent={
              <EmptyDashboardTable
                label="profile"
                title="We couldn't find any results."
                description="Please try a different search."
              />
            }
            onRowClick={usersData => {
              setUserData(usersData);
              setIsModalOpen(true);
            }}
          />
          {isModalOpen && userData && (
            <Modal closeModal={closeModal} noX>
              <EditUserForm profileData={userData} closeModal={closeModal} />
            </Modal>
          )}
          {pageCount > 0 && <Pagination {...{ pageCount }} />}
        </>
      ) : (
        <LoadingDashboardTable />
      )}
    </TableWrapper>
  );
};

const UsersAdminTableWrapper: React.FC<UsersAdminTableProps> = props => {
  return (
    <TableProvider>
      <UsersAdminTable {...props} />
    </TableProvider>
  );
};

export default UsersAdminTableWrapper;

const PersonalInfo = styled.div`
  position: relative;
`;

const Name = styled.div`
  color: ${darkBlue};
`;

const Email = styled.div`
  font-size: 1.2rem;
  position: absolute;
  bottom: -2rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
`;

const StyledClock = styled(Clock)`
  margin-left: 0.5rem;
`;

const StyledHeaderCell = styled(HeaderCell)`
  margin-left: auto;
`;

const TableWrapper = styled.div`
  background: ${white};
  border-radius: 0.4rem;
  box-shadow: var(--gray-box-shadow);
  margin-bottom: 8rem;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding-left: 3rem;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 3rem;
  height: 100%;
  position: absolute;
  fill: ${darkGray};
`;

const SearchInputContainer = styled.div`
  padding: 3rem;
  max-width: 49rem;
`;

const StyledHr = styled.hr`
  border: none;
  border-bottom: 1px solid ${lightGray};
  margin: 0 3rem;
`;

const SortableHeader = styled(Flex)`
  display: inline-flex;
  cursor: pointer;
`;

const StyledSortArrow = styled.div<{ isHidden: boolean; sortOrder?: 'ascending' | 'descending' }>`
  margin-left: 0.3rem;
  & > svg {
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    transform: ${({ sortOrder }) => (sortOrder === 'ascending' ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;
