import styled from '@emotion/styled';
import React from 'react';
import InfoPill, { PillProps } from 'components/atoms/InfoPill';
import Card from 'components/atoms/Card';
import AlignmentBars from 'components/molecules/AlignmentBars';
import { ahoy, borderBlue, mediumLightGray } from 'utils/colors';
import ShowDetailButton from 'components/atoms/ShowDetailButton';
import { ReactComponent as CheckIcon } from 'images/courseTileCheckmark.svg';
import ModalButton from 'components/molecules/ModalButton';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import DeleteBenchmarkModal from '../DeleteBenchmarkModal';

export interface DetailedBenchmarkCardProps {
  id: string;
  title: string;
  pills: PillProps[];
  alignmentStrength?: 'high' | 'medium' | 'low';
  tabIndex: number;

  isCustom?: boolean;
  isSelected?: boolean;
  allowCardSelection?: boolean;
  isCipCard?: boolean;

  renderDetailView(): JSX.Element;
  onSelectionChange?(selected: boolean): void;

  className?: string;
}

const DetailedBenchmarkCard: React.FC<DetailedBenchmarkCardProps> = ({
  id,
  allowCardSelection,
  isSelected,
  onSelectionChange,
  pills,
  alignmentStrength,
  renderDetailView,
  title,
  tabIndex,
  isCustom,
  isCipCard,
  className
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <Container
      className={className}
      isSelected={isSelected || false}
      isSelectable={allowCardSelection || false}
      onClick={() => onSelectionChange?.(!isSelected)}
      ref={ref}
      data-cy={`detailed-benchmark-card_container_${tabIndex}`}
    >
      {alignmentStrength ? (
        <AlignmentBars alignmentStrength={alignmentStrength} />
      ) : (
        <NoAlignmentSpacer />
      )}
      {isCipCard && (
        <div>
          <InfoPill scheme="blue" key="cipCard">
            CIP Code Match
          </InfoPill>
        </div>
      )}
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>

      <PillsContainer>
        {pills.map(pill => (
          <InfoPill scheme={pill.scheme} key={pill.label}>
            {pill.label}
          </InfoPill>
        ))}
      </PillsContainer>

      <ShowDetailButtonContainer>
        {isCustom ? (
          <div onClick={e => e.stopPropagation()}>
            <TrashButton
              buttonText={<StyledTrashIcon />}
              data-cy={`detailed-benchmark-card_trash-button_${tabIndex}`}
            >
              {closeModal => {
                return <DeleteBenchmarkModal closeModal={closeModal} benchmarkID={id} />;
              }}
            </TrashButton>
          </div>
        ) : (
          <div />
        )}
        <ShowDetailButton anchorRef={ref} renderDetails={renderDetailView} tabIndex={tabIndex} />
      </ShowDetailButtonContainer>

      {isSelected && <StyledCheckIcon />}
    </Container>
  );
};

export default DetailedBenchmarkCard;

const Container = styled(Card)<{ isSelected: boolean; isSelectable: boolean }>`
  width: 24rem;
  height: 16.6rem;
  border-radius: 0.4rem;
  position: relative;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;

  border: 0.2rem solid ${({ isSelected }) => (isSelected ? borderBlue : 'transparent')};

  button {
    opacity: 0;
  }

  &:hover {
    border: 0.2rem solid ${({ isSelectable }) => (isSelectable ? borderBlue : 'transparent')};

    button {
      opacity: 1;
    }
  }
`;

const NoAlignmentSpacer = styled.div``;

const TitleContainer = styled.div`
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PillsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const ShowDetailButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding-left: 1rem;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: -0.6rem;
  left: -0.6rem;
  background: white;
  border-radius: 1rem;
`;

const TrashButton = styled(ModalButton)`
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  background: inherit;
  color: ${mediumLightGray};
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-left: 1 rem;
`;

const StyledTrashIcon = styled(TrashIcon)`
  stroke: ${mediumLightGray};
  &:hover {
    path {
      stroke: ${ahoy};
    }
  }
`;
