import React from 'react';

import styled from '@emotion/styled';

import { ReactComponent as CloseIcon } from 'images/closebtn.svg';
import { lightGray } from 'utils/colors';

import { useOnKeyDownEffect } from 'hooks';
import { keyframes } from '@emotion/react';

type Side = 'left' | 'right';

const getPopupLocation = (
  renderSide: Side | undefined,
  anchorPointRef: React.RefObject<HTMLElement>,
  relativeParentRef: React.RefObject<HTMLElement>
) => {
  if (!anchorPointRef.current || !relativeParentRef.current || !renderSide) {
    return { left: `${-4 * window.innerWidth}px` };
  }
  const anchorPoint = anchorPointRef.current.getBoundingClientRect();
  const relativeParentLocation = relativeParentRef.current.getBoundingClientRect();
  const popupLocation: Record<string, string | number> = {
    top: `${anchorPoint.top + anchorPoint.height / 2 - relativeParentLocation.top}px`
  };
  if (renderSide === 'left') {
    popupLocation.right = `calc(${relativeParentLocation.right - anchorPoint.left}px + 2rem)`;
    popupLocation.left = 'auto';
  } else {
    popupLocation.left = `calc(${anchorPoint.right - relativeParentLocation.left}px + 2rem)`;
    popupLocation.right = 'auto';
  }
  return popupLocation;
};

export interface PopupOptions {
  omitCloseBtn?: boolean;
  omitAnchorDisplay?: boolean;
}

export interface PopupProps extends PopupOptions {
  children: React.ReactNode;
  dimensions: { height: string; width: string };
  anchorPointRef: React.RefObject<HTMLElement>;
  onClosePopup: () => void;
  className?: string;
}

const Popup: React.FC<PopupProps> = ({
  children,
  dimensions,
  anchorPointRef,
  onClosePopup,
  className,
  omitCloseBtn,
  omitAnchorDisplay
}) => {
  const [renderSide, setRenderSide] = React.useState<Side | undefined>(undefined);
  const ref = React.useRef<HTMLDivElement>(null);
  const relativeParentRef = React.useRef<HTMLDivElement>(null);
  const anchorLocation = React.useMemo(() => getAnchorLocation(renderSide), [renderSide]);
  const popupLocation = React.useMemo(
    () => getPopupLocation(renderSide, anchorPointRef, relativeParentRef),
    [renderSide, anchorPointRef.current, relativeParentRef.current]
  );
  useOnKeyDownEffect('Escape', () => onClosePopup());

  React.useEffect(() => {
    if (!anchorPointRef.current?.offsetParent) {
      onClosePopup();
      return;
    }
    if (ref.current && relativeParentRef.current && anchorPointRef.current) {
      const bounds = ref.current.getBoundingClientRect();
      const anchorPoint = anchorPointRef.current.getBoundingClientRect();
      if (anchorPoint.right + bounds.width - window.innerWidth < -10) {
        setRenderSide('right');
      } else {
        setRenderSide('left');
      }
    }
  }, [
    window.innerWidth,
    window.innerHeight,
    ref.current,
    relativeParentRef.current,
    anchorPointRef.current
  ]);

  return (
    <div
      onClick={e => e.stopPropagation()}
      style={{ position: 'relative' }}
      ref={relativeParentRef}
      className={className}
    >
      <PopupWrapper
        ref={ref}
        popupLocation={popupLocation}
        dimensions={dimensions}
        role="dialog"
        id="popup-content"
      >
        {!omitAnchorDisplay && (
          <PopupAnchor renderSide={renderSide} anchorLocation={anchorLocation} />
        )}
        <PopupContent>
          {!omitCloseBtn && (
            <CloseButton onClick={onClosePopup}>
              <CloseIcon />
            </CloseButton>
          )}
          {children}
        </PopupContent>
      </PopupWrapper>
    </div>
  );
};

const fadeInAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const PopupWrapper = styled.div<{
  popupLocation: Record<string, string | number>;
  dimensions: { height: string; width: string };
}>`
  position: absolute;
  background: white;
  border-radius: 0.4rem;
  height: ${({ dimensions }) => dimensions.height};

  z-index: 100;
  max-width: 50rem;
  width: ${({ dimensions }) => dimensions.width};
  min-width: 40rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: auto;
  animation: ${fadeInAnimation};
  animation-duration: 200ms;
  transform: translateY(-50%);
  ${({ popupLocation }) =>
    Object.entries(popupLocation).map(
      ([key, value]) => `${key}: ${value};
    `
    )};
`;

const PopupAnchor = styled.div<{
  anchorLocation: Record<string, string | number>;
  renderSide: Side | undefined;
}>`
  height: 3.2rem;
  width: 3.2rem;
  transform: rotate(45deg);
  position: absolute;
  margin: auto;
  margin-left: -4px;
  background: white;
  ${({ anchorLocation }) =>
    Object.entries(anchorLocation).map(([key, value]) => `${key}: ${value};`)}
`;

const PopupContent = styled.div`
  padding: 3rem;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 0.9rem;
  margin-top: 1rem;
  border: none;
  background: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  &:hover {
    background: ${lightGray};
  }
`;

const getAnchorLocation = (renderSide: Side | undefined) => ({
  top: 0,
  bottom: 0,
  right: renderSide === 'left' ? '-1rem' : 'auto',
  left: renderSide === 'right' ? '-1rem' : 'auto'
});

export default Popup;
