import React from 'react';
import styled from '@emotion/styled';

import SearchWithDropdownInput, {
  DropdownMenuItem
} from 'components/atoms/SearchWithDropdownInput';

import { searchJPAFacet } from 'services/jpa';
import useCurrentNation from 'hooks/useCurrentNation';
import { mediumGray } from 'utils/colors';
import { useQueryClient } from 'react-query';

export interface CipSearchProps {
  defaultCode?: string;
  showLabel?: boolean;
  onSelect(cip: string): void;
}

const CipSearch = ({ defaultCode, showLabel, onSelect }: CipSearchProps) => {
  const [currentNation] = useCurrentNation();
  const queryClient = useQueryClient();

  const searchForCipCodes = async (searchText: string) => {
    const data = await searchJPAFacet(currentNation, 'cip6', { q: searchText });

    // cache the cip code names so that future
    for (const cipItem of data) {
      queryClient.setQueryData(['cip-code-name', cipItem.id], cipItem.name);
    }

    return data;
  };

  return (
    <StyledSearchWithDropdown
      visibleLabel={showLabel}
      aria-label="program cip code name input"
      data-cy="cip-search"
      labelText="CIP Code"
      placeholder="Search for a CIP name..."
      onSelectItem={item => onSelect(item.id)}
      // This will get called when an item is selected and may throw a warning from react, but this is a problem with downshift
      search={searchForCipCodes}
      defaultValue={defaultCode}
    >
      {({ getItemProps, inputItems, highlightedIndex, selectItem }) => (
        <div>
          {inputItems.map((item, i) => (
            <StyledMenuItem
              {...getItemProps({ item, index: i })}
              highlighted={i === highlightedIndex}
              key={item.id}
              onClick={() => selectItem(item)}
              data-cy={`cip-search_cip-item-${item.name}`}
            >
              {item.name} ({item.id})
            </StyledMenuItem>
          ))}
        </div>
      )}
    </StyledSearchWithDropdown>
  );
};

export default CipSearch;

const StyledSearchWithDropdown = styled(SearchWithDropdownInput)<{ showLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: ${mediumGray};
  }

  ul {
    top: ${({ visibleLabel }) => (visibleLabel ? '5rem' : '3.5rem')};
    right: auto;
    & > div {
      width: 100%;
    }
  }

  @media (min-width: 1025px) {
    width: 48rem;
  }
`;

const StyledMenuItem = styled(DropdownMenuItem)`
  font-size: 1.4rem;
  font-weight: 400;
  display: block;
`;
