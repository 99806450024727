import React from 'react';

import RoundSelectionFlyoutButton from 'components/atoms/RoundSelectionFlyoutButton';
import RegionPickerFlyout from 'components/molecules/RegionPickerFlyout';
import { DropdownSelectable } from 'components/molecules/DropdownFlyout';
import { DEFAULT_REGION_OBJECT } from 'components/organisms/SetupLocalStorage';
import styled from '@emotion/styled';
import { bgLightGray, darkerBorderGray, darkGray, white } from 'utils/colors';
import { useCurrentSiteState } from 'store/currentSiteStore';

interface SelectRegionPickerFlyoutButtonProps {
  value?: RegionFilter;
  onSave: (regionFilter: RegionFilter) => unknown;
  currentNation: JPANation;
  floating?: boolean;
}

const formatSelectedRegionText = (
  filterType: DropdownSelectable,
  selectedRegions: JPARegionData[]
): string => {
  if (!selectedRegions.length) {
    return filterType.value === 'county'
      ? 'Select counties to filter by'
      : `Select ${filterType.label}s to filter by`;
  } else {
    return selectedRegions.length === 1
      ? selectedRegions[0].name
      : filterType.value === 'county'
      ? `${selectedRegions.length} Counties`
      : `${selectedRegions.length} ${filterType.label}s`;
  }
};

const RegionPickerFlyoutButton: React.FC<SelectRegionPickerFlyoutButtonProps> = ({
  value = undefined,
  onSave,
  currentNation,
  floating
}) => {
  const { defaultRegion: currentSiteDefaultRegion } = useCurrentSiteState();
  const initialValue =
    value || currentSiteDefaultRegion?.[currentNation] || DEFAULT_REGION_OBJECT[currentNation];
  return (
    <StyledRoundedButton
      floating={floating}
      aria-label="select region"
      data-cy="region-picker-flyout-button_flyout-button"
      buttonText="Job Posting Region:"
      selectionText={
        !!initialValue && formatSelectedRegionText(initialValue.filterType, initialValue.regions)
      }
    >
      {({ setIsOpen }: { setIsOpen: (newIsOpen: boolean) => void }) => {
        return (
          <FlyoutWrapper>
            <StyledRegionPickerFlyout
              onClose={() => setIsOpen(false)}
              {...{ value: initialValue, onSave }}
              headerLabel={<StyledLabel>Select Geography</StyledLabel>}
            />
          </FlyoutWrapper>
        );
      }}
    </StyledRoundedButton>
  );
};

export default RegionPickerFlyoutButton;

const StyledRoundedButton = styled(RoundSelectionFlyoutButton)<{ floating?: boolean }>`
  border-radius: 2rem;
  box-shadow: ${({ floating }) => (floating ? '0 0 4px rgba(0, 0, 0, 10%)' : 'none')};
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 1.4rem;
`;

const FlyoutWrapper = styled.div`
  width: 40rem;
`;

const StyledRegionPickerFlyout = styled(RegionPickerFlyout)`
  & .RegionContainer {
    border: 1px solid ${darkerBorderGray};
    border-radius: 0.4rem;
    background: ${bgLightGray};
    color: ${darkGray};
  }
  & .RegionContainerWrapper {
    margin: 2rem 0;
  }
  & .StyledDropdownFlyout {
    border: 1px solid ${darkerBorderGray};
    border-radius: 4rem;
    background: ${white};
    width: 10rem;
    font-weight: 500;
  }
  & .DropdownWrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
`;
