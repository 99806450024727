import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import DropdownFlyout, { DropdownFlyoutProps } from 'components/molecules/DropdownFlyout';
import ModalButton from 'components/molecules/ModalButton';
import ProgramTypeModal from 'components/organisms/ProgramTypeModal';
import { darkSaphire, lightGray } from 'utils/colors';

import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';

import { ReactComponent as PlusCircle } from 'images/plusCircle.svg';
import { ReactComponent as Edit } from 'images/edit.svg';
import { ReactComponent as TagCircle } from 'images/tagsCircle.svg';
import { FieldError } from 'react-hook-form';

import { DropdownSelectable, useProgramTypes } from 'hooks';

const StyledPlus = styled(PlusCircle)`
  stroke: ${darkSaphire};
  margin-right: 0.8rem;
`;

const StyledEditPencil = styled(Edit)`
  stroke: ${darkSaphire};
  margin-right: 0.8rem;
`;

const CenteredText = styled.div`
  text-align: center;
`;

const StyledModalButton = styled(ModalButton)`
  background: white;
  border-radius: 0 0 0.4rem 0.4rem;
  border-top: 1px solid ${lightGray};
  border: none;
  color: ${darkSaphire};
  cursor: pointer;
  font-size: 1.4rem;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Flex = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))`
  /***
    The class name is on the drop down button (so we can style it for other uses).
    We grab the sibling id of the menu and then grab the child div in order to
    custom style the dropdown section
  */
  & ~ #program-types-menu > div {
    top: 0rem;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  background: white;
  border-radius: 0 0 0.4rem 0.4rem;
  border-top: 1px solid ${lightGray};
  color: ${darkSaphire};
  cursor: pointer;
  font-size: 1.4rem;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const ProgramTypeDropdownFlyout: React.FC<{
  dropdownPlaceholder?: string;
  onChange?(selectedItem: DropdownSelectable): void;
  error?: FieldError;
  showLabel?: boolean;
  labelText?: string;
  className?: string;
  selected?: DropdownSelectable;
  allowSelectAll?: boolean;
}> = ({ dropdownPlaceholder, onChange, error, labelText, className, selected, allowSelectAll }) => {
  const [addTypeModalOpen, setAddTypeModalOpen] = useState<boolean>(false);
  const { currentSite, ...rest } = useProfileState();
  const hasEditorAccess = hasSiteAccessLevel(currentSite, 'editor', { currentSite, ...rest });

  const { data } = useProgramTypes({ limit: 100, filter: { site: { in: [currentSite] } } });

  const programTypes =
    data?.data.map(({ id, attributes: { label } }) => ({
      value: id,
      label
    })) || [];

  if (allowSelectAll) {
    programTypes.unshift({ value: '', label: 'All Program Types' });
  }

  const hasProgramTypes = allowSelectAll ? programTypes.length > 1 : programTypes.length > 0;

  return (
    <StyledDropdownFlyout
      className={className}
      onSelect={onChange}
      selected={selected}
      dropDownName="program-types"
      labelText={labelText}
      items={programTypes}
      menuPlaceholder={
        <CenteredText>
          <TagCircle />
          <div>You haven&apos;t created any Program Types yet</div>
        </CenteredText>
      }
      dropDownFooter={
        hasEditorAccess ? (
          hasProgramTypes ? (
            <StyledLink to="/dashboard/program-types">
              <StyledEditPencil />
              Edit Program Types
            </StyledLink>
          ) : (
            <StyledModalButton
              buttonText={
                <Flex>
                  <StyledPlus />
                  Add Program Type
                </Flex>
              }
              onModalChange={setAddTypeModalOpen}
            >
              {closeModal => <ProgramTypeModal closeModal={closeModal} />}
            </StyledModalButton>
          )
        ) : undefined
      }
      dropDownContentWidth="20rem"
      dropdownPlaceholder={dropdownPlaceholder}
      error={error}
      preventOnClickOutside={addTypeModalOpen}
    />
  );
};

export default ProgramTypeDropdownFlyout;
