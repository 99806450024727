import React from 'react';
import { useQuery } from 'react-query';
import { getAllSites, getSite } from 'services/sites';
import { Hook } from './curricularSkills';

import { useProfileState } from 'store/profileStore';

export interface SiteWithPermissions extends SiteAttributes {
  accessLevel?: PatchableRole;
}

export const useSites = (): Hook<Record<string, SiteWithPermissions>> => {
  const {
    data: allSites,
    error,
    isLoading
  } = useQuery<MultiSiteResponse, Error>(['sites'], () => getAllSites());

  const { accessLevels } = useProfileState();

  const sitesObject = React.useMemo(
    () =>
      allSites?.data.reduce((acc: Record<string, SiteWithPermissions>, sitesResponse) => {
        const { subdomain, nations, isDisabled, ...newSiteAttributes } = sitesResponse.attributes;
        acc[subdomain] = {
          nations,
          site: subdomain,
          isDisabled,
          ...newSiteAttributes,
          accessLevel: accessLevels[sitesResponse.attributes.subdomain] || null
        };
        return acc;
      }, {}),
    [allSites, accessLevels]
  );

  return {
    data: sitesObject,
    error,
    isLoading
  };
};

export const useSite = (site: string): Hook<SiteAttributes> => {
  return useQuery<SiteAttributes, Error>(['sites', site], () => getSite(site));
};
