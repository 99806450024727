import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import FocusLock from 'react-focus-lock';

import mergeRefs from 'helpers/mergeRefs';
import { useOnKeyDownEffect, useOnClickOutside } from 'hooks';
import { ReactFocusLockProps } from 'react-focus-lock/interfaces';

const StyledFocusLock = styled(FocusLock)`
  position: absolute;
  z-index: 1000;
  top: calc(100% + 1rem);
  left: 1rem;
`;

interface UseFlyoutProps {
  clickBoundary?: React.RefObject<HTMLElement>;
}

const createFlyoutContainer: <TFlyoutElement extends HTMLElement>(
  internalRef: React.LegacyRef<TFlyoutElement>
) => React.FC<ReactFocusLockProps> =
  internalRef =>
  ({ children, ref, ...props }) => {
    return (
      <StyledFocusLock ref={mergeRefs(ref, internalRef)} returnFocus {...props}>
        {children}
      </StyledFocusLock>
    );
  };

const useFlyout = <TFlyoutElement extends HTMLElement>({ clickBoundary }: UseFlyoutProps = {}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const flyoutRef = React.useRef<TFlyoutElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (e: Event) => {
    // If the button (or something withing the button) is clicked, we don't
    // need to close it here.  Without this check, the flyout will close and
    // reopen if the button is press while the flyout is open
    if (!(e.target instanceof Node) || !buttonRef.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(flyoutRef, handleClose, clickBoundary);
  useOnKeyDownEffect('Escape', handleClose);

  const FlyoutContainer = useMemo(() => {
    return createFlyoutContainer(flyoutRef);
  }, []);

  const getButtonProps = (
    props: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > = {}
  ) => {
    const { ref, onClick } = props;
    return {
      ...props,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(o => !o);
        onClick && onClick(e);
      },
      ref: mergeRefs<HTMLButtonElement>(ref, buttonRef)
    };
  };

  return { FlyoutContainer, getButtonProps, isOpen, setIsOpen };
};

export default useFlyout;
