import React, { useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ExpandIcon } from 'images/expand.svg';
import { ReactComponent as ContractIcon } from 'images/contract.svg';
import FlipCard from 'components/atoms/FlipCard';
import BarChart, { BarChartProps } from 'components/organisms/BarChart';
import CategoryBarChart from 'components/organisms/CategoryBarChart';
import ChartSkillSummary from 'components/molecules/ChartSkillSummary';

import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';
import { CustomErrorMessage } from '../SkillInsights';

let waitingToggle: NodeJS.Timeout;
const JobPostingsChart: React.FC<
  Omit<BarChartProps, 'columnHeaders'> & {
    isExpanded: boolean;
    changeExpansion(): void;
    className?: string;
    allowAddSkill?: boolean;
    displayType?: 'default' | 'category';
    customChartErrorMessage?: CustomErrorMessage;
  }
> = ({
  className,
  allowAddSkill,
  displayType = 'default',
  data,
  isExpanded,
  changeExpansion,
  query,
  customChartErrorMessage,
  ...props
}) => {
  const [focusedSkill, setFocusedSkill] = useState<{ id: string; name: string }>();
  const profileState = useProfileState();
  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  const height = isExpanded ? '80rem' : '54rem';
  return (
    <StyledFlipCard
      height={height}
      className={className}
      front={setFlipCard =>
        displayType === 'category' ? (
          <ExpandButton isExpanded={isExpanded} onExpansionChange={changeExpansion}>
            <CategoryBarChart
              query={query}
              disabled={!!focusedSkill}
              data={data}
              columnHeaders={['skill', 'job postings with this skill']}
              {...props}
              onRowClick={skill => {
                waitingToggle && clearTimeout(waitingToggle);
                waitingToggle = setTimeout(() => setFocusedSkill(skill), 200);
                setFlipCard(true);
              }}
              onUpdate={() => {
                if (focusedSkill) {
                  setFocusedSkill(undefined);
                  setFlipCard(false);
                }
              }}
              customChartErrorMessage={customChartErrorMessage}
            />
          </ExpandButton>
        ) : (
          <ExpandButton isExpanded={isExpanded} onExpansionChange={changeExpansion}>
            <StyledBarChart
              disabled={!!focusedSkill}
              data={data}
              columnHeaders={['skill', 'job postings with this skill']}
              {...props}
              onRowClick={skill => {
                waitingToggle && clearTimeout(waitingToggle);
                waitingToggle = setTimeout(() => setFocusedSkill(skill), 200);
                setFlipCard(true);
              }}
              onUpdate={() => {
                if (focusedSkill) {
                  setFocusedSkill(undefined);
                  setFlipCard(false);
                }
              }}
              customChartErrorMessage={customChartErrorMessage}
            />
          </ExpandButton>
        )
      }
      back={setFlipCard =>
        focusedSkill && (
          <ChartSkillSummary
            allowAddSkill={hasEditorPrivileges && allowAddSkill}
            skill={focusedSkill}
            onExit={() => {
              waitingToggle && clearTimeout(waitingToggle);
              waitingToggle = setTimeout(() => setFocusedSkill(undefined), 200);
              setFlipCard(false);
            }}
          />
        )
      }
    />
  );
};

const ExpandButton: React.FC<{ isExpanded: boolean; onExpansionChange(): void }> = ({
  isExpanded,
  children,
  onExpansionChange
}) => {
  return (
    <ShowExpandedContainer>
      {children}

      <ResizeButton onClick={() => onExpansionChange()}>
        {isExpanded ? <ContractIcon /> : <ExpandIcon />}
      </ResizeButton>
    </ShowExpandedContainer>
  );
};

const ResizeButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;

  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  transition: opacity 300ms;

  &:hover {
    opacity: 0.7;
  }
`;

const ShowExpandedContainer = styled.div`
  position: relative;
  height: 100%;
`;

const StyledBarChart = styled(BarChart)`
  box-shadow: none;
  height: 100%;
`;

const StyledFlipCard = styled(FlipCard)<{ height: string }>`
  width: 100%;
  height: ${({ height }) => height};
  transition: height 300ms;

  & > div {
    border-radius: 0.4rem;
    box-shadow: var(--gray-box-shadow);

    &.flipped {
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.09);
    }

    & > div {
      border-radius: 0.4rem;
    }
  }
`;

export default JobPostingsChart;
