import { DdnSkillsResponse, fetchDdnSkills } from 'services/ddnSkills';
import {
  fetchSalaryBoostingSkills,
  SalaryBoostingSkillsResponse
} from 'services/salaryBoostingSkills';
import { UniversalBenchmark } from './benchmarks/universal';

export type SkillSignificance = 'defining' | 'distinguishing' | 'necessary';

export interface BenchmarkedSkillSignificance {
  significance: SkillSignificance;
  benchmarks: UniversalBenchmark[];
}

export async function associateDdnWithSkills(benchmarks: UniversalBenchmark[]) {
  const record: Record<string, BenchmarkedSkillSignificance[]> = {};
  const plan = planByOccupationType(benchmarks);

  const pushToRecord = (skillId: string, benchmarkId: string, significance: SkillSignificance) => {
    const benchmark = benchmarks.find(b => b.attributes.id === benchmarkId);
    if (!benchmark) {
      throw new Error('Response returned a benchmark that was not supplied in input');
    }

    if (!record[skillId]) {
      record[skillId] = [];
    }

    const benchmarkedArray = record[skillId];
    const currentSignificance = benchmarkedArray.find(item => item.significance === significance);

    if (currentSignificance) {
      return currentSignificance.benchmarks.push(benchmark);
    }

    benchmarkedArray.push({ benchmarks: [benchmark], significance });
  };

  const registerResponses = (responses: DdnSkillsResponse[]) => {
    for (const occupationInfo of responses) {
      for (const skill of occupationInfo.definingSkills) {
        pushToRecord(skill.id, occupationInfo.id, 'defining');
      }

      for (const skill of occupationInfo.distinguishingSkills) {
        pushToRecord(skill.id, occupationInfo.id, 'distinguishing');
      }

      for (const skill of occupationInfo.necessarySkills) {
        pushToRecord(skill.id, occupationInfo.id, 'necessary');
      }
    }
  };

  if (plan.occupations.length) {
    const response = await fetchDdnSkills(
      'occupation',
      plan.occupations.map(occupation => occupation.attributes.id)
    );

    registerResponses(response);
  }

  if (plan.specOccupations.length) {
    const response = await fetchDdnSkills(
      'occupation',
      plan.specOccupations.map(occupation => occupation.attributes.id)
    );

    registerResponses(response);
  }

  return record;
}

export async function associateSalaryBoostingWithSkills(benchmarks: UniversalBenchmark[]) {
  const record: Record<string, UniversalBenchmark[]> = {};
  const plan = planByOccupationType(benchmarks);

  const pushToRecord = (skillId: string, benchmarkId: string) => {
    const benchmark = benchmarks.find(b => b.attributes.id === benchmarkId);
    if (!benchmark) {
      throw new Error('Response returned a benchmark that was not supplied in input');
    }

    if (!record[skillId]) {
      record[skillId] = [benchmark];
      return;
    }

    record[skillId].push(benchmark);
  };

  const registerResponses = (responses: SalaryBoostingSkillsResponse[]) => {
    for (const occupation of responses) {
      for (const skill of occupation.skills) {
        pushToRecord(skill.id, occupation.id);
      }
    }
  };

  if (plan.occupations.length) {
    const responses = await fetchSalaryBoostingSkills(
      'occupation',
      plan.occupations.map(occupation => occupation.attributes.id)
    );

    registerResponses(responses);
  }

  if (plan.specOccupations.length) {
    const responses = await fetchSalaryBoostingSkills(
      'specialized_occupation',
      plan.specOccupations.map(occupation => occupation.attributes.id)
    );

    registerResponses(responses);
  }

  return record;
}

function planByOccupationType(benchmarks: UniversalBenchmark[]) {
  const occupations: UniversalBenchmark[] = [];
  const specOccupations: UniversalBenchmark[] = [];

  for (const benchmark of benchmarks) {
    if (benchmark.universalType !== 'occupation') {
      continue;
    }

    if (benchmark.attributes.type === 'occupation') {
      occupations.push(benchmark);
    }

    if (benchmark.attributes.type === 'specialized_occupation') {
      specOccupations.push(benchmark);
    }
  }

  return { occupations, specOccupations };
}
