import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { useOnKeyDownEffect, useOnClickOutside } from 'hooks';

import { white, bgLightGray } from 'utils/colors';

type ItemOpts = { firstOrLastPosition: 'first' | 'middle' | 'last' };

const FlyoutMenu: React.FC<{
  className?: string;
  clickBoundary?: React.RefObject<HTMLElement>;
  buttonContent?: React.ReactNode;
  ariaLabel: string;
  disabled?: boolean;
  renderCustomButton?(props: React.HTMLAttributes<HTMLButtonElement>): ReactNode;
  children: (opts: {
    handleClose: () => void;
    getItemProps: (
      options?: ItemOpts,
      props?: Record<string, unknown>
    ) => {
      onKeyDown?: (e: React.KeyboardEvent) => void;
      role: string;
    };
  }) => React.ReactNode;
}> = ({
  children,
  className,
  buttonContent,
  renderCustomButton,
  ariaLabel,
  disabled,
  clickBoundary = { current: document.getElementById('root') }
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const handleClose = () => setIsOpen(false);

  const handleTab = (e: React.KeyboardEvent, firstPosition: string) => {
    let ShouldCloseDropdown;
    switch (firstPosition) {
      case 'first':
        ShouldCloseDropdown = e.key === 'Tab' && e.shiftKey;
        break;
      case 'middle':
        ShouldCloseDropdown = false;
        break;
      case 'last':
        ShouldCloseDropdown = e.key === 'Tab' && !e.shiftKey;
        break;
    }
    ShouldCloseDropdown && handleClose();
  };

  useOnClickOutside(ref, handleClose, clickBoundary);
  useOnKeyDownEffect('Escape', handleClose);

  const getItemProps = (options?: ItemOpts, props?: Record<string, unknown>) => ({
    onKeyDown: options?.firstOrLastPosition
      ? (e: React.KeyboardEvent) => handleTab(e, options.firstOrLastPosition)
      : undefined,
    role: 'menuitem',
    ...props
  });

  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    disabled,
    className: 'invisibleButton',
    'aria-label': ariaLabel,
    onClick: () => setIsOpen(!isOpen),
    'aria-expanded': isOpen,
    'aria-haspopup': true
  };

  return (
    <FlyoutMenuWrapper onClick={e => e.stopPropagation()} ref={ref} className={className}>
      {renderCustomButton ? (
        renderCustomButton(buttonProps)
      ) : (
        <InvisibleButton {...buttonProps}>{buttonContent}</InvisibleButton>
      )}

      {isOpen && (
        <Menu className="flyoutMenu" role="menu">
          {children({ getItemProps, handleClose })}
        </Menu>
      )}
    </FlyoutMenuWrapper>
  );
};

const FlyoutMenuWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const InvisibleButton = styled.button`
  background: inherit;
  border: none;
  font-size: 1.4rem;
  position: relative;
  cursor: pointer;
  height: 100%;
`;

export const Menu = styled.ul`
  z-index: 100;
  position: absolute;
  padding: 0.8rem 0;
  background: ${white};
  border-radius: 4px;
  margin: 0;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.0713505);
  min-width: 13rem;

  @media (max-width: 1366px) {
    left: auto;
    right: 30px;
  }
`;

export const MenuItem = styled.li`
  list-style-type: none;
  margin: 0 0.8rem;

  & > button,
  a {
    width: 100%;
    list-style: none;
    font-size: 1.4rem;
    background: white;
    padding: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 0;
    margin: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${bgLightGray};
      border-radius: 0.4rem;
    }
  }
`;

export default FlyoutMenu;
