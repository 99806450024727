import { fetchWithCognitoToken } from './cognito';

const domain = process.env.REACT_APP_PROXY_URL;

const getTaxonomyUrl = (taxonomy: ClassificationTaxonomies, version: string, endpoint: string) => {
  const url = `${domain}/classification-api/taxonomies/${taxonomy}/versions/${version}/${endpoint}`;
  return url;
};
const getMappingUrl = (mapping?: string) => {
  const url = `${domain}/classification-api/mappings/${mapping || ''}`;
  return url;
};

/** https://api.lightcast.io/apis/classification#get-get-latest-version-metadata */
export const fetchTaxonomyMeta = async (
  taxonomy: ClassificationTaxonomies
): Promise<ClassificationTaxonomyMeta> => {
  const response = await fetchWithCognitoToken(
    `${domain}/classification-api/taxonomies/${taxonomy}/`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response.ok) {
    throw Error(`There was an error requesting ${taxonomy} metadata from the classification api.`);
  }

  return ((await response.json()) as ClassificationTaxonomyMetaResponse).data;
};

/** https://api.lightcast.io/apis/classification#post-list-requested-taxonomy-concepts */
export const fetchTaxonomyConcepts = async (
  taxonomy: ClassificationTaxonomies,
  version: string,
  body?: ClassificationConceptsBody
): Promise<ClassificationSkill[]> => {
  const response = await fetchWithCognitoToken(getTaxonomyUrl(taxonomy, version, `concepts`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  if (!response.ok) {
    throw Error(`There was an error requesting ${taxonomy} concepts from the classification api.`);
  }

  return ((await response.json()) as ClassificationSkillsResponse).data;
};

export const fetchMappingsMeta = async (): Promise<ClassificationMappingsMeta[]> => {
  const response = await fetchWithCognitoToken(getMappingUrl(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw Error(`There was an error requesting mappings metadata from the classification api.`);
  }

  return ((await response.json()) as ClassificationMappingsMetaResponse).data;
};

/** Reduces the classification api mappings list to the mapping with the latest versions of the passed taxonomies */
export const getLatestMappingVersion = (
  mapping: UnversionedClassificationMappings,
  allMappings: ClassificationMappingsMetaResponse['data']
) => {
  const newest = allMappings.reduce(
    (newestMapping: ClassificationMappingsMeta | null, currentMapping) => {
      if (
        currentMapping.sourceName === mapping.sourceName &&
        currentMapping.destinationName === mapping.destinationName
      ) {
        if (newestMapping === null) {
          return currentMapping;
        }
        if (
          currentMapping.sourceVersion.localeCompare(newestMapping.sourceVersion, undefined, {
            numeric: true,
            sensitivity: 'base'
          }) === 1 ||
          currentMapping.destinationVersion.localeCompare(
            newestMapping.destinationVersion,
            undefined,
            { numeric: true, sensitivity: 'base' }
          ) === 1
        ) {
          return currentMapping;
        }
      }
      return newestMapping;
    },
    null
  );
  return newest?.name;
};

/** https://api.lightcast.io/apis/classification#post-map-concepts */
export const fetchMappingConcepts = async (
  mapping: string,
  body: ClassificationMappingsBody
): Promise<ClassificationMappingsResponse['data']> => {
  const response = await fetchWithCognitoToken(getMappingUrl(mapping), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  if (!response.ok) {
    throw Error(`There was an error requesting ${mapping} mappings from the classification api.`);
  }

  return ((await response.json()) as ClassificationMappingsResponse).data;
};
