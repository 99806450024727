import React from 'react';
import styled from '@emotion/styled';

import RoundSelectionFlyoutButton from 'components/atoms/RoundSelectionFlyoutButton';
import Toggle from 'components/atoms/Toggle';
import { ReactComponent as Info } from 'images/skillInfo.svg';
import Popup from 'components/atoms/Popup';
import { DropdownSelectable, useOnKeyDownEffect } from 'hooks';
import DropdownFlyout, { DropdownFlyoutProps } from '../DropdownFlyout';
import { borderGray, darkGray, white } from 'utils/colors';

interface SelectExperienceRequiredFlyoutButtonProps {
  onChange: (experienceRange?: Required<JPAOptionsFilter>['min_years_experience']) => void;
  value?: Required<JPAOptionsFilter>['min_years_experience'];
  floating?: boolean;
}

const rangeDropdownOptions: DropdownSelectable<number>[] = [];
for (let i = 0; i <= 10; i++) {
  if (i === 1) {
    rangeDropdownOptions.push({ label: `${i} year`, value: i });
  } else if (i === 10) {
    rangeDropdownOptions.push({ label: `${i}+ years`, value: i });
  } else {
    rangeDropdownOptions.push({ label: `${i} years`, value: i });
  }
}

const SelectExperienceRequiredFlyoutButton: React.FC<SelectExperienceRequiredFlyoutButtonProps> = ({
  onChange,
  value,
  floating
}) => {
  const [isInfoTextOpen, setIsInfoTextOpen] = React.useState<boolean>(false);
  const [lowerBoundSelection, setLowerBoundSelection] = React.useState<number>(1);
  const [upperBoundSelection, setUpperBoundSelection] = React.useState<number>(3);

  useOnKeyDownEffect('Escape', () => setIsInfoTextOpen(false));

  const selectionText = value
    ? `${rangeDropdownOptions[value.lower_bound].value} to ${
        rangeDropdownOptions[value.upper_bound].label
      }`
    : 'All';

  const infoHeaderRef = React.useRef<HTMLDivElement>(null);

  return (
    <StyledRoundSelectionFlyoutButton
      floating={floating}
      aria-label="select experience required"
      data-cy="select-experience-required-dropdown-button_flyout-button"
      buttonText="Experience Required: "
      selectionText={selectionText}
    >
      <FlyoutPanel>
        <Header ref={infoHeaderRef}>
          <h1> Select Experience Range</h1>
          <StyledInfoButton
            type="button"
            onClick={e => {
              e.stopPropagation();
              setIsInfoTextOpen(!isInfoTextOpen);
            }}
          >
            <Info />
          </StyledInfoButton>
        </Header>
        <StyledToggleText>
          Any
          <StyledToggle
            data-cy="select-experience-required-flyout-button_range-toggle"
            value={!!value}
            onValueChange={toggleValue => {
              if (!toggleValue) {
                onChange(undefined);
              } else {
                onChange({
                  lower_bound: lowerBoundSelection,
                  upper_bound: upperBoundSelection
                });
              }
            }}
          />
          Select Range
        </StyledToggleText>
        <RangeSelectorWrapper>
          <StyledDropdownFlyout
            dropDownName="minimum-experience-required-lower-bound"
            dropDownContentWidth="13rem"
            items={rangeDropdownOptions}
            isDisabled={!value}
            selected={
              typeof value !== 'undefined'
                ? rangeDropdownOptions[value.lower_bound]
                : rangeDropdownOptions[lowerBoundSelection]
            }
            onSelect={selection => {
              setLowerBoundSelection(selection.value);
              if (value) {
                if (selection.value <= value.upper_bound) {
                  onChange({
                    lower_bound: selection.value,
                    upper_bound: value.upper_bound
                  });
                } else {
                  onChange({
                    lower_bound: selection.value,
                    upper_bound: selection.value
                  });
                  setUpperBoundSelection(selection.value);
                }
              }
            }}
          />
          <StyledText> to </StyledText>
          <StyledDropdownFlyout
            dropDownName="minimum-experience-required-upper-bound"
            dropDownContentWidth="13rem"
            items={rangeDropdownOptions}
            isDisabled={!value}
            selected={
              typeof value !== 'undefined'
                ? rangeDropdownOptions[value.upper_bound]
                : rangeDropdownOptions[upperBoundSelection]
            }
            onSelect={selection => {
              setUpperBoundSelection(selection.value);
              if (value) {
                if (selection.value >= value.lower_bound) {
                  onChange({
                    lower_bound: value.lower_bound,
                    upper_bound: selection.value
                  });
                } else {
                  onChange({
                    lower_bound: selection.value,
                    upper_bound: selection.value
                  });
                  setLowerBoundSelection(selection.value);
                }
              }
            }}
          />
        </RangeSelectorWrapper>
      </FlyoutPanel>
      {isInfoTextOpen && (
        <StyledPopup
          dimensions={{ height: 'fit-content', width: 'fit-content' }}
          anchorPointRef={infoHeaderRef}
          onClosePopup={() => {
            setIsInfoTextOpen(false);
          }}
        >
          <InfoText>
            Minimum Experience Required
            <StyledInfoText>
              The minimum experience required as specified within the job postings. Not all postings
              include an experience level; the unspecified postings will not be displayed when this
              filter is applied.
            </StyledInfoText>
            Example: If you filter by 0-2 years, your results will include postings that accept
            candidates with at least 0, 1, or 2 years of experience.
          </InfoText>
        </StyledPopup>
      )}
    </StyledRoundSelectionFlyoutButton>
  );
};

export default SelectExperienceRequiredFlyoutButton;

const StyledRoundSelectionFlyoutButton = styled(RoundSelectionFlyoutButton)<{ floating?: boolean }>`
  border-radius: 2rem;
  box-shadow: ${({ floating }) => (floating ? '0 0 4px rgba(0, 0, 0, 10%)' : 'none')};
`;

const FlyoutPanel = styled.section`
  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }
  width: auto;
  min-width: 25rem;
`;

const StyledToggleText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-size: 1.4rem;
`;

const StyledToggle = styled(Toggle)`
  margin: 1rem;
`;

const StyledInfoButton = styled.button`
  background: none;
  border: none;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoText = styled.div`
  font-size: 1.4rem;
`;

const StyledInfoText = styled.div`
  margin: 1rem 0;
`;

const StyledPopup = styled(Popup)`
  margin-right: 1rem;
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<number>) => (
  <DropdownFlyout {...props} />
))`
  border: 1px solid ${borderGray};
  border-radius: 3.5rem;
  width: 13rem;
  background: ${white};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  & > svg {
    right: 1.9rem;
    top: 1.5rem;
  }

  & > svg {
    right: 1.9rem;
    top: 1.5rem;
  }

  & > select {
    color: ${darkGray};
    font-size: 1.4rem;
    padding: 1rem 1.5rem 1rem 0.5rem;
  }

  * > path {
    stroke: ${darkGray};
  }
`;

const RangeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: ${darkGray};
  font-size: 1.4rem;
  margin-left: 0.5rem;
`;

const StyledText = styled.div`
  margin: 0 1rem;
`;
