import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { History } from 'history';

export const initializeSentry = (history: History<unknown>): void => {
  if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENV,
      release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
      attachStacktrace: true,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      tracesSampleRate: 0.5
    });
  }
};

export const setSentryUser = (profile: ProfileAttributes): void => {
  if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
    const { accessLevels, isInternalAdmin, currentSite, id } = profile;
    Sentry.setUser({ id, site: currentSite, accessLevels, isInternalAdmin });
  }
};

export const logHandledError = (error: Error) => {
  Sentry.captureException(error);
};
