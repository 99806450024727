import React from 'react';

import DropdownFlyout, { DropdownSelectable } from 'components/molecules/DropdownFlyout';
import { useProgramTypeClasses } from 'hooks';

const ProgramTypeClassDropdown: React.FC<{
  className?: string;
  icon?: React.ReactNode;
  dropdownPlaceholder?: string;
  selected?: DropdownSelectable;
  onSelect?: (selection: DropdownSelectable) => unknown;
  allowSelectAll?: boolean;
}> = ({ dropdownPlaceholder, className, icon, selected, onSelect, allowSelectAll }) => {
  const { data } = useProgramTypeClasses();

  const programTypeClasses =
    data?.data.map(programTypeClass => ({
      label: programTypeClass.attributes.name,
      value: programTypeClass.id
    })) || [];

  if (allowSelectAll) {
    programTypeClasses.unshift({ label: 'All Categories', value: '' });
  }

  return (
    <DropdownFlyout
      dropDownName="program-type-classes"
      items={programTypeClasses}
      icon={icon}
      selected={selected}
      menuPlaceholder="Select Category"
      dropDownContentWidth="100%"
      dropdownPlaceholder={dropdownPlaceholder}
      onSelect={onSelect}
      className={className}
    />
  );
};

export default ProgramTypeClassDropdown;
