import React, { useCallback, useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useFormContext, RegisterOptions, FieldError } from 'react-hook-form';
import throttle from 'lodash/throttle';

import Card from 'components/atoms/Card';
import InputLabel from 'components/atoms/InputLabel';
import FormInput from 'components/molecules/FormInput';
import HighlightedTextArea from 'components/atoms/HighlightedTextArea';
import HighlightedFormInput from 'components/atoms/HighlightedFormInput';
import { parseTextsForSkills, PreprocessedSkill } from 'services/skills';
import { replaceBadChars } from 'helpers/highlightParsedSkills';

const HeaderStyles = css`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
`;

const InputsWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;

    & > div:nth-of-type(2) {
      margin: 0 2rem;
    }
  }
`;

const throttledParse = throttle(
  (text: string[], onParsedText) => onParsedText(parseTextsForSkills(text)),
  500
);

interface Input extends React.InputHTMLAttributes<HTMLInputElement> {
  key: string;
  labelText: string;
  placeholder: string;
  registerOptions?: RegisterOptions;
}

interface TextArea extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'title'> {
  ref?: React.Ref<HTMLTextAreaElement>;
  'data-cy'?: string;
  key: string;
  title: React.ReactNode;
  placeholder: string;
  textareaHeight: string;
  registerOptions?: RegisterOptions;
}

const GeneralInfoForm: React.FC<{
  className?: string;
  inputsTitle?: string;
  onParseSkills: (skills: PreprocessedSkill[], skillsByTextArea: PreprocessedSkill[][]) => void;
  onParseError?: React.Dispatch<React.SetStateAction<string | undefined>>;
  revealedSkill?: string;
  inputs?: Input[];
  textAreas?: TextArea[];
  showTitleInput?: boolean;
  textFromFile?: string;
}> = ({
  className,
  inputsTitle,
  onParseSkills,
  onParseError,
  revealedSkill,
  textFromFile,
  inputs = [],
  textAreas = [],
  showTitleInput = true
}) => {
  const [highlights, setHighlights] = React.useState<PreprocessedSkill[][]>([
    [],
    ...textAreas.map(() => [])
  ]);
  const {
    setValue,
    register,
    formState: { errors },
    watch
  } = useFormContext();

  useEffect(() => {
    if (textFromFile) {
      setValue('syllabusText', textFromFile);
    }
  }, [textFromFile, setValue]);

  const title = showTitleInput ? watch('title') : '';
  const descriptions = watch(textAreas.map(({ key }) => key));

  const handleParsedText = useCallback(
    async (
      parsedTextPromise: Promise<{
        allSkills: PreprocessedSkill[];
        skillsForTexts: PreprocessedSkill[][];
      }>
    ) => {
      try {
        const parsedInformation = await parsedTextPromise;
        onParseSkills(parsedInformation.allSkills, parsedInformation.skillsForTexts);
        setHighlights(parsedInformation.skillsForTexts);
      } catch (err) {
        onParseError && onParseError(JSON.stringify(err));
      }
    },
    [onParseError, onParseSkills, setHighlights]
  );

  React.useEffect(() => {
    const texts = showTitleInput ? [title, ...descriptions] : descriptions;
    if (texts.some(text => text?.length >= 3)) {
      throttledParse(texts, handleParsedText);
    } else {
      onParseSkills(
        [],
        texts.map(_ => [])
      );
    }
  }, [title, ...descriptions, onParseSkills, handleParsedText, showTitleInput]);

  return (
    <Card className={className}>
      {inputsTitle && <h3 css={HeaderStyles}>{inputsTitle}</h3>}
      {showTitleInput && (
        <>
          <InputLabel htmlFor="title">Title</InputLabel>
          <HighlightedFormInput
            revealedSkill={revealedSkill}
            highlights={highlights[0]}
            placeholder="e.g. Fundamentals of Public Speaking..."
            type="text"
            data-cy="general-info-form_title-input"
            {...register('title', { required: 'Required', setValueAs: replaceBadChars })}
            error={errors.title as FieldError | undefined}
            required
          />
        </>
      )}
      <InputsWrapper>
        {inputs.map(({ key, registerOptions, ...input }) => (
          <FormInput
            key={key}
            type="text"
            {...input}
            {...register(key, registerOptions)}
            error={errors[key] as FieldError | undefined}
          />
        ))}
      </InputsWrapper>
      {textAreas.map(({ key, registerOptions, title: textAreaTitle, ...textArea }, i) => (
        <div key={key}>
          <label css={HeaderStyles} htmlFor={key}>
            {textAreaTitle}
          </label>
          <HighlightedTextArea
            revealedSkill={revealedSkill}
            highlights={highlights[i + Number(showTitleInput)]}
            {...register(key, { setValueAs: replaceBadChars, ...registerOptions })}
            resize="vertical"
            {...textArea}
            error={errors[key] as FieldError | undefined}
          />
        </div>
      ))}
    </Card>
  );
};

export default GeneralInfoForm;
