import React from 'react';
import styled from '@emotion/styled';

import Modal from 'components/atoms/Modal';
import Button from 'components/atoms/Button';
import TOSText from 'components/atoms/TOSText';

import { useLocalStorage } from 'hooks/useLocalStorage';

const StyledModal = styled(Modal)`
  max-width: 85rem;
  padding: 5rem 7rem;

  h2 {
    margin-top: 0;
    font-size: 1.8rem;
  }

  ul {
    padding: 0 5rem;
    margin-bottom: 0;
    li {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  }
  button {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-size: 1.6rem;
    margin: 2.5rem;
  }
`;

const TOSModal: React.FC = () => {
  const [acceptedTOS, setAcceptedTOS] = useLocalStorage('acceptSkillabiTermsOfService');

  return acceptedTOS === 'Accept' ? null : (
    <StyledModal noX>
      <div>
        <h2>You must agree to the Terms of Use below to access Skillabi.</h2>
        <TOSText />
      </div>
      <Button type="button" onClick={() => setAcceptedTOS('Accept')}>
        I Agree
      </Button>
      <em>
        (Seeing this message more than once? Ensure cookies are enabled or{' '}
        <a href="mailto:skillabi-help@lightcast.io">request help</a>.)
      </em>
    </StyledModal>
  );
};

export default TOSModal;
