import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import RoundSelectionFlyoutButton from 'components/atoms/RoundSelectionFlyoutButton';
import Checkbox from 'components/atoms/Checkbox';

import { getEducationLevels, skillabiEducationLevelsToJpa } from 'helpers/dataFilters';

interface SelectEducationLevelDropdownButtonProps {
  onChange: (educationLevels: string[]) => void;
  floating?: boolean;
}

const SelectEducationLevelDropdownButton: React.FC<SelectEducationLevelDropdownButtonProps> = ({
  onChange,
  floating
}) => {
  const [selectedEducationLevels, setSelectedEducationLevels] = useState<string[]>([]);
  const buttonText = selectedEducationLevels.length < 2 ? 'Education Levels: ' : 'Showing: ';
  const selectionText =
    selectedEducationLevels.length === 0
      ? 'All'
      : selectedEducationLevels.length === 1
      ? `[ ${selectedEducationLevels[0]} ]`
      : `${selectedEducationLevels.length} Education Levels`;

  useEffect(() => {
    onChange(skillabiEducationLevelsToJpa(selectedEducationLevels));
  }, [selectedEducationLevels]);

  const selectLevel = (selectedLevel: string) =>
    setSelectedEducationLevels(oldLevels =>
      oldLevels.includes(selectedLevel) ? oldLevels : [...oldLevels, selectedLevel]
    );

  const deselectLevel = (deselectedLevel: string) =>
    setSelectedEducationLevels(oldLevels => oldLevels.filter(level => level !== deselectedLevel));

  return (
    <StyledRoundSelectionFlyoutButton
      floating={floating}
      aria-label="Select Education Levels"
      data-cy="select-education-level-dropdown-button_flyout-button"
      buttonText={buttonText}
      selectionText={selectionText}
    >
      <FlyoutPanel>
        <h1> Select Education Level</h1>
        <ul>
          {getEducationLevels().map(educationLevel => (
            <li key={educationLevel}>
              <Checkbox
                data-cy={`select-education-level-dropdown-button_checkbox-${educationLevel}`}
                labelText={educationLevel}
                checked={selectedEducationLevels.includes(educationLevel)}
                onChange={event =>
                  event.target.checked ? selectLevel(educationLevel) : deselectLevel(educationLevel)
                }
              />
            </li>
          ))}
        </ul>
      </FlyoutPanel>
    </StyledRoundSelectionFlyoutButton>
  );
};

export default SelectEducationLevelDropdownButton;

const StyledRoundSelectionFlyoutButton = styled(RoundSelectionFlyoutButton)<{ floating?: boolean }>`
  border-radius: 2rem;
  box-shadow: ${({ floating }) => (floating ? '0 0 4px rgba(0, 0, 0, 10%)' : 'none')};
`;

const FlyoutPanel = styled.section`
  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }

  ul {
    list-style-type: none;
    display: grid;
    padding: 0;
    font-size: 1.4rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0;
  }

  li {
    padding: 0;
    margin: 0.4rem 0 0.8rem 1rem;
    min-width: 18rem;
    width: 100%;
  }

  svg {
    margin-right: 0.5rem;
  }
`;
