import React from 'react';
import { View } from '@react-pdf/renderer';

export interface BasicFlexProps {
  gap?: number;
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  wrap?: boolean;
}

export interface FlexProps extends BasicFlexProps {
  direction: 'row' | 'column';
}

export const Flex: React.FC<FlexProps> = props => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: props.direction,
        gap: props.gap,
        alignItems: props.align,
        justifyContent: props.justify,
        flexWrap: props.wrap ? 'wrap' : 'nowrap'
      }}
    >
      {props.children}
    </View>
  );
};

export const Row: React.FC<BasicFlexProps> = props => {
  return <Flex {...props} direction="row" />;
};

export const Column: React.FC<BasicFlexProps> = props => {
  return <Flex {...props} direction="column" />;
};

export const Expanded: React.FC = props => {
  return <View style={{ flexBasis: 1, flexGrow: 1 }}>{props.children}</View>;
};
