import React from 'react';
import styled from '@emotion/styled';

import Loading from 'components/atoms/Loading';
import Pill from 'components/atoms/InfoPill';
import { useJPARankingByFacet, useJPATotals } from 'hooks/jpaHooks';

import { lightGray, darkBlue } from 'utils/colors';
import formatLargeNumber from 'utils/formatLargeNumber';

const nextLevelDown = {
  career_area: 'occupation_group_name',
  occupation_group: 'occupation_name',
  occupation: 'specialized_occupation_name',
  specialized_occupation: 'specialized_occupation_name'
};

const nextLevelDownLabel = {
  career_area: 'Occupation Groups',
  occupation_group: 'Occupations',
  occupation: 'Specialized Occupations',
  specialized_occupation: 'Specialized Occupations'
};

const levelLabel = {
  career_area: 'Career Area',
  occupation_group: 'Occupation Group',
  occupation: 'Occupation',
  specialized_occupation: 'Specialized Occupations'
};

type Filter = {
  filter: {
    [facet: string]: { include: string[] } | { exclude: string[] };
  };
  rank: {
    limit: number;
  };
};

const getJPAFilter = (occupation: LightcastOccupation, facet?: string) => {
  const base: Filter = {
    filter: {
      [occupation.type]: { include: [occupation.id] }
    },
    rank: { limit: 5 }
  };
  if (facet) {
    base.filter[facet] = { exclude: ['Unclassified'] };
  }
  return base;
};

const LOTBenchmarkPopup: React.FC<{
  occupation: LightcastOccupation;
}> = ({ occupation }) => {
  const { data: skillsNames, isFetching: isSkillsNamesFetching } = useJPARankingByFacet(
    'skills_name',
    getJPAFilter(occupation, 'skills_name')
  );
  const { data: titleNames, isFetching: isTitleNamesFetching } = useJPARankingByFacet(
    'title_name',
    getJPAFilter(occupation, 'title_name')
  );
  const { data: companyNames, isFetching: isCompanyNamesFetching } = useJPARankingByFacet(
    'company_name',
    getJPAFilter(occupation, 'company_name')
  );
  const { data: salaryObj } = useJPATotals(getJPAFilter(occupation).filter, ['median_salary']);
  const { data: nextLevelDownNames, isFetching: isNextLevelDownNamesFetching } =
    useJPARankingByFacet(
      nextLevelDown[occupation.type],
      getJPAFilter(occupation, nextLevelDown[occupation.type]),
      {
        queryOptions: {
          enabled: occupation.type !== 'specialized_occupation'
        }
      }
    );

  return (
    <div>
      <BenchmarkTitle data-cy={`lot-benchmark-popup_title_${occupation.name}`}>
        {occupation.name}
      </BenchmarkTitle>
      <PillContainer>
        <Pill scheme="red">{levelLabel[occupation.type]}</Pill>
        {salaryObj?.median_salary && (
          <Pill scheme="green">${formatLargeNumber(salaryObj.median_salary)}</Pill>
        )}
      </PillContainer>
      <div>
        <SectionTitles>Job Titles</SectionTitles>
        {!isTitleNamesFetching ? (
          <ChipContainer>
            {titleNames &&
              titleNames.buckets.map(title => (
                <InformationChip key={title.name}>{title.name}</InformationChip>
              ))}
          </ChipContainer>
        ) : (
          <StyledLoading />
        )}
      </div>
      <div>
        <SectionTitles>Companies</SectionTitles>
        {!isCompanyNamesFetching ? (
          <ChipContainer>
            {companyNames &&
              companyNames.buckets.map(company => (
                <InformationChip key={company.name}>{company.name}</InformationChip>
              ))}
          </ChipContainer>
        ) : (
          <StyledLoading />
        )}
      </div>
      <div>
        <SectionTitles>Skills</SectionTitles>
        {!isSkillsNamesFetching ? (
          <ChipContainer>
            {skillsNames &&
              skillsNames.buckets.map(skills => (
                <InformationChip key={skills.name}>{skills.name}</InformationChip>
              ))}
          </ChipContainer>
        ) : (
          <StyledLoading />
        )}
      </div>
      {occupation.type !== 'specialized_occupation' && (
        <div>
          <SectionTitles>{nextLevelDownLabel[occupation.type]}</SectionTitles>
          {!isNextLevelDownNamesFetching ? (
            <ChipContainer>
              {nextLevelDownNames &&
                nextLevelDownNames.buckets.map(skills => (
                  <InformationChip key={skills.name}>{skills.name}</InformationChip>
                ))}
            </ChipContainer>
          ) : (
            <StyledLoading />
          )}
        </div>
      )}
    </div>
  );
};

const BenchmarkTitle = styled.span`
  font-size: 1.4rem;
  color: ${darkBlue};
  font-weight: 500;
  display: block;
  margin: 1rem 0;
`;

const PillContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const SectionTitles = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${darkBlue};
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  min-width: 0;

  @media (max-height: 1000px) {
    max-height: 5.8rem;
    overflow: hidden;
  }
`;

const InformationChip = styled.div`
  align-items: center;
  background-color: ${lightGray};
  border: none;
  border-radius: 2px;
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0.5rem;
  margin-left: 0;
  max-width: 30rem;
  min-width: 0;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledLoading = styled(Loading)`
  height: 6rem;
  align-items: center;
  justify-content: center;
`;

export default LOTBenchmarkPopup;
