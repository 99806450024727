import React, { useMemo } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
  searchCourses,
  searchPrograms,
  searchProgramTypes,
  getCourseById,
  getCurricularUnitById,
  getAllProgramTypeClasses,
  searchBenchmarks
} from 'services/curricularSkills';

import useCategorizedSkills, { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { useProfileState } from 'store/profileStore';
import { logHandledError } from 'utils/sentry';
import { fetchSkillsById } from 'services/skills';
import useCurrentNation from './useCurrentNation';
import { getProgramSkillInfo } from 'utils/curricularSkills';
import { useCurrentSiteState } from 'store/currentSiteStore';
import { useCustomBenchmarksTotals } from './jpaHooks';
import { useProgramData } from 'store/programData';

export interface Hook<T> {
  isLoading: boolean;
  isPreviousData?: boolean;
  error: Error | null;
  data: T | undefined;
}

export const DEFAULT_DASHBOARD_TABLE_PAGE_SIZE = 8;

const getCoursesQuery = (query: CourseSearchPayload, currentSite: string) => {
  query = { ...query };
  if (!query.limit) {
    query.limit = DEFAULT_DASHBOARD_TABLE_PAGE_SIZE;
  }
  if (!query.filter?.site) {
    query.filter = { ...query.filter, site: { in: [currentSite] } };
  }

  return {
    queryKey: ['courses', query, ...(query.filter.associatedGroups?.in || [])],
    queryFn: () => searchCourses(query)
  };
};

export const useProgramCourses = (program?: SingleCurriculum<CurricularUnitResponse>) => {
  const allCourseIds = program?.data.attributes.children.map(course => course.id) || [];

  return useCourses(
    {
      limit: 100,
      filter: { ids: { in: allCourseIds } }
    },
    { keepPreviousData: false, staleTime: 10000, enabled: !!allCourseIds.length }
  );
};

export const useCourses = (
  query: CourseSearchPayload,
  useQueryOptions?: UseQueryOptions<CurricularData<Course>, Error>
): Hook<CurricularData<Course>> => {
  const { currentSite } = useProfileState();
  const coursesQuery = getCoursesQuery(query, currentSite);

  const enabled =
    useQueryOptions && Object.prototype.hasOwnProperty.call(useQueryOptions, 'enabled')
      ? useQueryOptions.enabled
      : true;
  const { isLoading, error, data, isPreviousData } = useQuery<CurricularData<Course>, Error>(
    coursesQuery.queryKey,
    coursesQuery.queryFn,
    {
      keepPreviousData: true,
      ...(useQueryOptions || {}),
      enabled: !!currentSite && enabled
    }
  );

  return {
    isLoading,
    error,
    data,
    isPreviousData
  };
};

export const useCourse = (
  id?: string,
  includeSyllabus?: boolean,
  options?: UseQueryOptions<SingleCurriculum<Course> | undefined, Error>
): Hook<SingleCurriculum<Course>> => {
  return useQuery<SingleCurriculum<Course> | undefined, Error>(
    [`course`, id, includeSyllabus, options],
    () => {
      if (id) {
        return getCourseById(id, includeSyllabus);
      }
    },
    options
  );
};

const getProgramQuery = (query: ProgramSearchPayload, currentSite: string) => {
  query = { ...query };
  if (!query.limit) {
    query.limit = DEFAULT_DASHBOARD_TABLE_PAGE_SIZE;
  }
  if (!query.filter?.site) {
    query.filter = { ...query.filter, site: { in: [currentSite] } };
  }

  return {
    queryKey: ['programs', query],
    queryFn: () => searchPrograms(query)
  };
};

export const usePrograms = (
  query: ProgramSearchPayload,
  useQueryOptions?: UseQueryOptions<CurricularData<Program>, Error>
): Hook<CurricularData<Program>> => {
  const { currentSite } = useProfileState();
  const customQueryOptions = useQueryOptions || {};

  const programQuery = getProgramQuery(query, currentSite);

  const { isLoading, error, data, isPreviousData } = useQuery<CurricularData<Program>, Error>(
    programQuery.queryKey,
    programQuery.queryFn,
    {
      keepPreviousData: true,
      ...customQueryOptions,
      enabled:
        ('enabled' in customQueryOptions ? customQueryOptions.enabled : true) && !!currentSite
    }
  );

  return {
    isLoading,
    error,
    data,
    isPreviousData
  };
};

export const useProgramTypes = (
  query: ProgramTypeSearchPayload
): Hook<CurricularData<ProgramType>> => {
  const { currentSite } = useProfileState();
  query = { ...query };
  if (!query.limit) {
    query.limit = DEFAULT_DASHBOARD_TABLE_PAGE_SIZE;
  }
  if (!query.filter?.site) {
    query.filter = { ...query.filter, site: { in: [currentSite] } };
  }
  const { isLoading, error, data, isPreviousData } = useQuery<CurricularData<ProgramType>, Error>(
    ['program-types', query],
    () => searchProgramTypes(query),
    {
      enabled: !!currentSite,
      keepPreviousData: true
    }
  );
  return {
    isLoading,
    error,
    data,
    isPreviousData
  };
};

export const useCustomBenchmarks = (
  query: BenchmarkSearchPayload,
  useQueryOptions?: UseQueryOptions<CurricularData<Benchmark>, Error>
): Hook<CurricularData<Benchmark>> => {
  const { currentSite } = useProfileState();
  query = { ...query };
  if (!query.limit) {
    query.limit = DEFAULT_DASHBOARD_TABLE_PAGE_SIZE;
  }
  if (!query.filter?.site) {
    query.filter = { ...query.filter, site: { in: [currentSite] } };
  }
  const enabled =
    useQueryOptions && Object.prototype.hasOwnProperty.call(useQueryOptions, 'enabled')
      ? useQueryOptions.enabled
      : true;
  const { isLoading, error, data, isPreviousData } = useQuery<CurricularData<Benchmark>, Error>(
    ['benchmarks', query, ...(query.filter.associatedGroups?.in || [])],
    () => searchBenchmarks(query),
    {
      keepPreviousData: true,
      ...(useQueryOptions || {}),
      enabled: !!currentSite && enabled
    }
  );

  return {
    isLoading,
    error,
    data,
    isPreviousData
  };
};

export const useProgramTypeClasses = (): Hook<CurricularData<ProgramTypeClass>> => {
  const { isLoading, error, data } = useQuery<CurricularData<ProgramTypeClass>, Error>(
    'programTypeClasses',
    () => getAllProgramTypeClasses()
  );
  return {
    isLoading,
    error,
    data
  };
};

interface SkillsInProgramData {
  skillsInProgram: string[];
  skillFrequency: Record<string, number>;
  categorizedSkills: CategorizedSkills;
  requiredSkillsInProgram: string[];
  skillsInProgramNameMapping: Record<string, string>;
  programCourses?: CurricularData<Course>;
}

export const useSkillsInProgram = (
  programId: string
): { data: SkillsInProgramData; isLoading: boolean; error: Error | null } => {
  const { chartSettings } = useProgramData();
  const { data: program, isLoading: programIsLoading, error: programError } = useProgram(programId);

  const {
    data: programCourses,
    isLoading: coursesIsLoading,
    error: coursesError
  } = useProgramCourses(program);

  const { skillsInProgram, requiredSkillsInProgram, skillFrequency } = useMemo(() => {
    if (!program) {
      return { skillFrequency: {}, skillsInProgram: [], requiredSkillsInProgram: [] };
    }

    return getProgramSkillInfo({
      courses: programCourses?.data || [],
      program: program.data,
      learningOutcomesOnly: chartSettings.skillTagFilter === 'isLearningObjective'
    });
  }, [programCourses, program, chartSettings.skillTagFilter]);

  const skillIdsInProgram = [...new Set(skillsInProgram.map(skill => skill.id))];
  const requiredSkillIdsInProgram = requiredSkillsInProgram.map(skill => skill.id);

  const {
    skills,
    isLoading: skillsIsLoading,
    error: skillsError
  } = useSkillsByIds(skillIdsInProgram);

  const skillsInProgramNameMapping = useMemo<Record<string, string>>(() => {
    if (!skills) {
      return {};
    }

    return skills.reduce((skillObj: Record<string, string>, { id, name }) => {
      skillObj[id] = name;
      return skillObj;
    }, {});
  }, [skills]);

  const categorizedSkills = useCategorizedSkills(skills);

  const error = programError || coursesError || skillsError;
  const isLoading = programIsLoading || coursesIsLoading || skillsIsLoading;

  return {
    data: {
      skillFrequency,
      skillsInProgram: skillIdsInProgram,
      requiredSkillsInProgram: requiredSkillIdsInProgram,
      categorizedSkills,
      skillsInProgramNameMapping,
      programCourses
    },
    error,
    isLoading
  };
};

export const useSkillsByIds = (ids: string[], options: HookOptions = { enabled: true }) => {
  const { isLoading, data, error } = useQuery<Skill[], Error>(
    ['skills', ...ids],
    () => fetchSkillsById(ids, ['category', 'subcategory', 'name', 'id', 'type', 'isSoftware']),
    { enabled: !!ids.length && options.enabled }
  );

  return { isLoading, skills: data || [], error };
};

export const useProgram = (
  id?: string,
  options?: UseQueryOptions<SingleCurriculum<CurricularUnitResponse> | undefined, Error>
): Hook<SingleCurriculum<CurricularUnitResponse>> => {
  return useQuery<SingleCurriculum<CurricularUnitResponse> | undefined, Error>(
    ['program', id],
    async () => {
      if (id) {
        return await getCurricularUnitById(id);
      }
    },
    options
  );
};

export const useCourseSearch = () => {
  const queryClient = useQueryClient();
  const { currentSite } = useProfileState();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const getCoursesData = React.useCallback(
    async (searchText: string) => {
      if (!currentSite.length) {
        return;
      }
      setIsLoading(true);

      const coursesQuery = getCoursesQuery(
        {
          limit: 100,
          filter: {
            textFilter: {
              query: searchText,
              fields: ['title', 'courseId']
            }
          }
        },
        currentSite
      );

      try {
        const coursesData = await queryClient.fetchQuery(
          coursesQuery.queryKey,
          coursesQuery.queryFn
        );

        setIsLoading(false);
        setError(undefined);
        return coursesData;
      } catch (e) {
        setIsLoading(false);
        if (e instanceof Error) {
          setError(e);
          logHandledError(e);
        }
      }
    },
    [currentSite]
  );

  return {
    isLoading,
    error,
    getCoursesData
  };
};

export const useProgramSearch = () => {
  const queryClient = useQueryClient();
  const { currentSite } = useProfileState();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const getProgramData = React.useCallback(
    async (searchText: string) => {
      if (!currentSite.length) {
        return;
      }
      setIsLoading(true);

      const programsQuery = getProgramQuery(
        {
          limit: 100,
          filter: {
            textFilter: {
              query: searchText,
              fields: ['title']
            }
          }
        },
        currentSite
      );

      try {
        const programsData = await queryClient.fetchQuery(
          programsQuery.queryKey,
          programsQuery.queryFn
        );
        setIsLoading(false);
        setError(undefined);
        return programsData;
      } catch (e) {
        setIsLoading(false);
        if (e instanceof Error) {
          setError(e);
          logHandledError(e);
        }
      }
    },
    [currentSite]
  );

  return {
    isLoading,
    error,
    getProgramData
  };
};

type ProgramAndCourseSearchItem = {
  id: string;
  name: string;
  type?: string;
  label?: string;
};

export const useProgramAndCourseSearch = () => {
  const queryClient = useQueryClient();
  const { currentSite } = useProfileState();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const getData = React.useCallback(
    async (searchText: string) => {
      if (!currentSite.length) {
        return;
      }
      setIsLoading(true);

      const programsQuery = getProgramQuery(
        {
          limit: 100,
          filter: {
            textFilter: {
              query: searchText,
              fields: ['title']
            }
          }
        },
        currentSite
      );

      const coursesQuery = getCoursesQuery(
        {
          limit: 100,
          filter: {
            textFilter: {
              query: searchText,
              fields: ['title', 'courseId']
            }
          }
        },
        currentSite
      );

      try {
        const coursesData = await queryClient.fetchQuery(
          coursesQuery.queryKey,
          coursesQuery.queryFn
        );
        const programsData = queryClient.fetchQuery(programsQuery.queryKey, programsQuery.queryFn);

        const programList =
          (await programsData).data.map(program => ({
            id: program.id,
            name: program.attributes.title,
            type: 'program',
            label: program.attributes.groupType.groupTypeClass.name
          })) || [];
        const courseList =
          coursesData.data.map(course => ({
            id: course.id,
            name: course.attributes.title,
            type: 'course',
            label: course.attributes.courseId
          })) || [];
        const combinedList: ProgramAndCourseSearchItem[] = [...programList, ...courseList];

        setIsLoading(false);
        setError(undefined);
        return combinedList
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              sensitivity: 'base',
              ignorePunctuation: true
            })
          )
          .slice(0, 100);
      } catch (e) {
        setIsLoading(false);
        if (e instanceof Error) {
          setError(e);
          logHandledError(e);
        }
      }
    },
    [currentSite]
  );

  return {
    isLoading,
    error,
    getData
  };
};

export interface CustomBenchmark {
  id: string;
  attributes: Benchmark;
  type: string;
  selected: boolean;
  totals?: Partial<JPATotals>;
}

export const useCustomBenchmarksByProgramId = (programId: string, keepPreviousData?: boolean) => {
  const [formattedBenchmarks, setFormattedBenchmarks] = React.useState<CustomBenchmark[]>([]);
  const [nation] = useCurrentNation();
  const { site } = useCurrentSiteState();
  const { data: program, isLoading: isProgramLoading } = useProgram(programId);
  const { data: rawCustomBenchmarks, isLoading: isBenchmarksLoading } = useCustomBenchmarks(
    {
      filter: { site: { in: [site] }, associatedGroups: { in: [program?.data.id || ''] } },
      limit: 100
    },
    { enabled: !!program, keepPreviousData }
  );

  const customBenchmarks = useMemo(() => {
    if (!rawCustomBenchmarks) {
      return [];
    }

    return rawCustomBenchmarks.data.map(benchmark => benchmark.attributes);
  }, [rawCustomBenchmarks]);

  const { data: benchmarkTotals, isLoading: isCustomTotalsLoading } = useCustomBenchmarksTotals(
    customBenchmarks,
    ['median_salary'],
    { enabled: !!customBenchmarks.length }
  );

  React.useEffect(() => {
    if (customBenchmarks && program) {
      const selectedBenchmarkIds = program.data.attributes.benchmarks
        .filter(custom => custom.selected)
        .map(custom => custom.id);

      const benchmarksWithSelected: CustomBenchmark[] = customBenchmarks
        .filter(
          benchmark => benchmark.type === 'skillsFromTextBenchmark' || benchmark.nation === nation
        )
        .map(benchmark => ({
          id: benchmark.id,
          type: benchmark.type,
          attributes: benchmark,
          selected: selectedBenchmarkIds.includes(benchmark.id),
          totals: benchmarkTotals && benchmarkTotals[benchmark.id]
        }));

      setFormattedBenchmarks(benchmarksWithSelected);
    }
  }, [customBenchmarks, program, nation, benchmarkTotals]);

  return {
    customBenchmarks: formattedBenchmarks,
    isLoading: isProgramLoading || isBenchmarksLoading || isCustomTotalsLoading,
    error: null // TODO error should be inferred from the subordinate hooks
  };
};

export function useProgramHasLearningOutcomes(programId: string) {
  const {
    data: courses,
    error: coursesError,
    isLoading: coursesIsLoading
  } = useCourses({ filter: { associatedGroups: { in: [programId] } }, limit: 100 });

  const { data: program, error: programError, isLoading: programIsLoading } = useProgram(programId);

  const courseSkills = useMemo(
    () => courses?.data.flatMap(course => course.attributes.skills || []) || [],
    [courses]
  );

  const programSkills = useMemo(() => program?.data.attributes.skills || [], [program]);

  const hasLearningOutcome = useMemo(() => {
    for (const skill of courseSkills) {
      if (skill.isLearningObjective) {
        return true;
      }
    }

    for (const skill of programSkills) {
      if (skill.isLearningObjective) {
        return true;
      }
    }

    return false;
  }, [courseSkills, programSkills]);

  return {
    data: hasLearningOutcome,
    isLoading: coursesIsLoading || programIsLoading,
    error: coursesError || programError
  };
}
