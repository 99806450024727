import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

import {
  navyBlue,
  darkFreqBlue,
  midFreqBlue,
  lightFreqBlue,
  freqBoxBorderGray,
  white,
  midFreqTextBlue,
  black,
  midFreqPillTextBlue,
  lowFreqPillTextGray,
  tableLineGray,
  courseCountBlue
} from 'utils/colors';
import { getFrequencyValues, segmentChartDataIntoColumns } from './helpers';
import { useProgramData } from '../data';
import { Header } from '../../components';
import { Footer } from '../../components/Footer';
import { ContainerPage } from '../../components/Page';
import { addContentsHeader } from 'helpers/pdfExport/contentsTable';

export const SkillFrequencyPage: React.FC = () => {
  const { skillsInProgram, skillCounts } = useProgramData();

  const { frequency, highFrequencyCount, mediumFrequencyCount, lowFrequencyCount, counts } =
    getFrequencyValues(skillCounts, skillsInProgram);

  const total = lowFrequencyCount + mediumFrequencyCount + highFrequencyCount;

  const frequencyValues = [
    {
      name: 'High Frequency',
      backgroundColor: darkFreqBlue,
      freq: highFrequencyCount,
      barTextColor: white,
      tableSubHeaderText: '8 Courses or more',
      pillTextColor: white
    },
    {
      name: 'Mid Frequency',
      backgroundColor: midFreqBlue,
      freq: mediumFrequencyCount,
      barTextColor: midFreqTextBlue,
      tableSubHeaderText: '4 to 7 Courses',
      pillTextColor: midFreqPillTextBlue
    },
    {
      name: 'Low Frequency',
      backgroundColor: lightFreqBlue,
      freq: lowFrequencyCount,
      barTextColor: black,
      tableSubHeaderText: '3 Courses or fewer',
      pillTextColor: lowFreqPillTextGray
    }
  ];

  return (
    <ContainerPage>
      <View>
        <Header
          text="Program Skills by Frequency"
          id="program_skills_by_frequency"
          preventPropagation
        />

        <Text
          fixed
          style={styles.headerText}
          render={({ subPageNumber }) => {
            if (subPageNumber > 1) {
              return 'Program Skills by Frequency (Cont.)';
            }
          }}
        />

        <Text style={styles.subHeaderText}>
          The graph and list of skills below show the full list of skills identified for this
          program, grouped by how frequently skills appear in courses associated with this program.
        </Text>
        <View style={styles.freqChartKeys}>
          {frequencyValues.map(({ name, backgroundColor: color }) => (
            <React.Fragment key={name}>
              <View style={[styles.freqKey, { backgroundColor: color }]} />
              <Text style={styles.legendLlabel}>{name}</Text>
            </React.Fragment>
          ))}
        </View>
        <View style={styles.freqChartContainer}>
          {frequencyValues.map(({ freq, backgroundColor: color, barTextColor }, i) => (
            <View
              key={i}
              style={[
                styles.freqBoxes,
                { width: `${(freq / total) * 100}%`, backgroundColor: color }
              ]}
            >
              <Text style={[styles.freqBoxText, { color: barTextColor }]}>{freq}</Text>
            </View>
          ))}
        </View>
        <View style={styles.frequencyTableContainer}>
          <View style={styles.courseRowsContainer}>
            {frequency.map((freq, index) => {
              return (
                <View key={index}>
                  <View style={styles.frequencyHeadersContainer}>
                    <Text
                      id={`skill_frequency_${index}`}
                      style={[
                        styles.frequencyPill,
                        {
                          backgroundColor: `${frequencyValues[index].backgroundColor}`,
                          color: `${frequencyValues[index].pillTextColor}`
                        }
                      ]}
                      render={({ pageNumber }) => {
                        addContentsHeader({
                          label: frequencyValues[index].name,
                          pageNumber,
                          id: `skill_frequency_${index}`,
                          level: 1
                        });
                        return `${frequencyValues[index].name} (${counts[index]})`;
                      }}
                    />
                    <Text style={styles.frequencyHeaderDef}>
                      - {frequencyValues[index].tableSubHeaderText}
                    </Text>
                  </View>
                  {freq.map(([count, skills], i) => {
                    return (
                      <View key={i}>
                        <Text style={styles.courseCountSubHeader}>
                          {count}
                          {Number(count) > 1 ? ' Courses' : ' Course'}
                        </Text>
                        <View style={styles.skillContainer}>
                          {segmentChartDataIntoColumns(
                            skills.sort((a, b) =>
                              skillsInProgram[b] > skillsInProgram[a] ? -1 : 1
                            ),
                            3
                          ).map((column, columnIndex) => (
                            <View key={columnIndex} style={styles.column}>
                              {column.map((skill: string, skillIndex: number) => (
                                <Text key={skillIndex} style={styles.skill}>
                                  {`\u2022 ${skillsInProgram[skill]}`}
                                </Text>
                              ))}
                            </View>
                          ))}
                        </View>
                      </View>
                    );
                  })}
                  {index < 2 && <View style={styles.tableLine} />}
                </View>
              );
            })}
          </View>
        </View>
      </View>
      <Footer />
    </ContainerPage>
  );
};

const styles = StyleSheet.create({
  headerText: {
    color: navyBlue,
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Inter',
    marginBottom: '0.25in'
  },
  subHeaderText: {
    fontFamily: 'Inter',
    fontSize: 12
  },
  gradientBgImage: {
    height: '5.5in',
    width: '4in'
  },
  freqChartKeys: {
    marginTop: '0.4in',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  freqKey: {
    height: '0.1in',
    width: '0.1in',
    marginRight: '0.1in',
    marginLeft: '0.1in'
  },
  freqChartContainer: {
    marginTop: '0.2in',
    marginLeft: '0.25in',
    display: 'flex',
    flexDirection: 'row'
  },
  freqBoxes: {
    height: '1in',
    border: `1 solid ${freqBoxBorderGray}`,
    display: 'flex',
    alignItems: 'stretch',
    minWidth: '0.3in' // this is set so that text in box doesn't get cut off
  },
  freqBoxText: {
    paddingTop: '0.4in',
    textAlign: 'center'
  },
  frequencyTableContainer: {
    display: 'flex',
    marginTop: '0.8in'
  },
  frequencyHeadersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '0.2in'
  },
  legendLlabel: {
    fontSize: '12',
    fontFamily: 'Inter'
  },
  frequencyPill: {
    borderRadius: '0.5in',
    width: '2in',
    border: '1 solid',
    padding: '0.01in',
    fontSize: '10',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  tableLine: {
    border: `1px solid ${tableLineGray}`,
    width: '515px',
    marginBottom: '0.2in'
  },
  frequencyHeaderDef: {
    paddingLeft: '0.1in',
    fontSize: '12'
  },
  courseRowsContainer: {
    display: 'flex',
    marginTop: '0.1in'
  },
  courseCountSubHeader: {
    color: courseCountBlue,
    fontWeight: 'bold',
    fontSize: '12',
    paddingLeft: `0.2in`
  },
  skillContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: '0.2in',
    paddingLeft: `0.2in`
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%'
  },
  skill: {
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 'normal',
    marginRight: '0.2in',
    textOverflow: 'ellipsis',
    maxLines: 1,
    padding: 5
  }
});
