import makeStore from './makeStore';
import { getOwnProfile } from 'services/profiles';
import { getHighestAccessLevel } from 'utils/getHighestAccessLevel';

interface ProfileState extends Omit<ProfileAttributes, 'defaultSite'> {
  isLoading?: boolean;
  hasAdminPanelAccess: boolean;
  hasCurrentSiteEditorAccess: boolean;
}
interface SetCurrentSiteAction {
  type: 'SET_CURRENT_SITE';
  payload: string;
}

interface SetProfileLoadingAction {
  type: 'SET_PROFILE_LOADING';
}
interface SetProfileLoadedAction {
  type: 'SET_PROFILE_LOADED';
  payload: ProfileAttributes;
}

type ProfileAction = ProfileStateAction | SetProfileLoadingAction | SetProfileLoadedAction;

type ProfileStateAction = SetCurrentSiteAction;

const levels: Record<Role, number> = {
  consumer: 0,
  viewer: 1,
  editor: 2,
  admin: 3
};

export const hasSiteAccessLevel = (
  site: string,
  accessLevel: keyof typeof levels,
  state: ProfileState
) => levels[state.accessLevels[site]] >= levels[accessLevel];

export const getProfile = async (dispatch: Dispatch<ProfileAction>): Promise<ProfileAttributes> => {
  dispatch({ type: 'SET_PROFILE_LOADING' });

  const profile = await getOwnProfile();
  const { attributes } = profile.data;
  let currentSite = attributes.defaultSite;
  if (!attributes.defaultSite && Object.keys(attributes.accessLevels).length === 1) {
    currentSite = Object.keys(attributes.accessLevels)[0];
  }

  dispatch({
    type: 'SET_PROFILE_LOADED',
    payload: { id: profile.data.id, currentSite, ...attributes }
  });
  return { ...profile.data.attributes, id: profile.data.id, currentSite };
};

const initialState: ProfileState = {
  currentSite: '',
  accessLevels: {},
  name: '',
  email: '',
  isLoading: false,
  hasAdminPanelAccess: false,
  hasCurrentSiteEditorAccess: false,
  cognitoStatus: ''
};

const profileReducer = (state: ProfileState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case 'SET_CURRENT_SITE':
      return {
        ...state,
        currentSite: action.payload
      };
    case 'SET_PROFILE_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'SET_PROFILE_LOADED': {
      const { defaultSite, ...rest } = action.payload;
      const hasAdminPanelAccess = getHighestAccessLevel(rest.accessLevels) === 'admin';
      return {
        ...state,
        ...rest,
        isLoading: false,
        hasAdminPanelAccess: hasAdminPanelAccess || !!rest.isInternalAdmin
      };
    }
    default:
      return state;
  }
};
const [ProfileProvider, useProfileDispatch, useProfileState] = makeStore<
  ProfileState,
  ProfileAction
>(profileReducer, initialState);

export { ProfileProvider, useProfileDispatch, useProfileState };
