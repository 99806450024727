import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { throttle } from 'lodash';

import FlyoutMenu, { MenuItem } from 'components/atoms/FlyoutMenu';
import HeaderLink from 'components/atoms/HeaderLink';
import InternalLink from 'components/atoms/InternalLink';
import GlobalSearchInput from 'components/molecules/GlobalSearchInput';
import SiteSelectModal, { getSiteName } from 'components/molecules/SiteSelectModal';

import { useSites } from 'hooks/siteHooks';
import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';

import { borderGray, darkSaphire, white, lightGray, darkBlue, sailorRed } from 'utils/colors';
import { logout } from 'services/cognito';

import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as Courses } from 'images/courses.svg';
import { ReactComponent as Programs } from 'images/programs.svg';
import { ReactComponent as Account } from 'images/account.svg';
import { ReactComponent as Caret } from 'images/caret.svg';
import ProgramSearchDropdown from '../ProgramSearchDropdown';
import { ReactComponent as XRemove } from 'images/x-remove.svg';
import { useLocation } from 'react-router-dom';

const GlobalHeader: React.FC = () => {
  const profileState = useProfileState();
  const { data: sitesData } = useSites();
  const hasMultiSites = Object.keys(profileState.accessLevels || {}).length > 1;

  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onMarketAlignmentPage = useLocation().pathname.includes('/program/insights/');

  const { limitedContent } = useFlags();

  const [windowWidthSize, setWindowWidthSize] = React.useState<number>(window.innerWidth);

  const onWindowResizeThrottled = throttle(() => {
    setWindowWidthSize(window.innerWidth);
  }, 100);

  React.useEffect(() => {
    window.addEventListener('resize', onWindowResizeThrottled);
    return () => window.removeEventListener('resize', onWindowResizeThrottled);
  });

  const siteButtonRef = React.useRef<HTMLButtonElement & HTMLSpanElement>(null);

  const shouldCollapseButton = (buttonName: keyof typeof WHEN_TO_COLLAPSE_BUTTONS) => {
    if (limitedContent) {
      return false;
    }
    const remainingPageWidth = WHEN_TO_COLLAPSE_BUTTONS[buttonName];
    const siteButton = siteButtonRef.current;
    if (!siteButton) {
      return false;
    }
    return windowWidthSize < siteButton.getBoundingClientRect().right + remainingPageWidth;
  };

  return (
    <Header>
      <LogoLink href="/dashboard/courses/" aria-label="Skillabi Home">
        <StyledLogo />
        <Skillabi>Skillabi</Skillabi>
      </LogoLink>
      <ShrinkableFlex>
        <SiteSelectContainer hasMultiSites={hasMultiSites}>
          <StyledButton
            data-cy="global-header_site-select-button"
            as={hasMultiSites ? undefined : 'div'}
            onClick={
              hasMultiSites
                ? () => {
                    setIsModalOpen(true);
                  }
                : undefined
            }
          >
            {sitesData?.[profileState.currentSite]?.isDisabled && <StyledDisabledSiteX />}
            <div>
              <span ref={siteButtonRef}>{getSiteName(sitesData, profileState.currentSite)}</span>
            </div>
            {hasMultiSites && <StyledSiteCaret />}
          </StyledButton>
          {isModalOpen && (
            <SiteSelectModal
              message="Switch Institutions"
              closeButton
              height={20}
              closeModal={() => setIsModalOpen(false)}
            />
          )}
        </SiteSelectContainer>
        <GlobalSearchInput />
      </ShrinkableFlex>
      <Nav role="navigation">
        <NavList>
          {!shouldCollapseButton('courses') && (
            <li>
              <HeaderLink
                isActive={(match, { pathname }) =>
                  !!match ||
                  !!pathname.match(/(^\/edit\/course\/|^\/course\/summary\/|^\/add\/course$)/)
                }
                href="/dashboard/courses"
              >
                <Courses
                  css={css`
                    ${svgBase}
                  `}
                />
                Courses
              </HeaderLink>
            </li>
          )}
          {!shouldCollapseButton('programs') && (
            <li>
              <HeaderLink
                isActive={(match, { pathname }) =>
                  !!match ||
                  !!pathname.match(/(^\/edit\/program\/|^\/add\/program$|^\/program\/summary)/)
                }
                href="/dashboard/programs"
              >
                <Programs
                  css={css`
                    ${svgBase}
                  `}
                />
                Programs
              </HeaderLink>
            </li>
          )}
          {!limitedContent && (
            <NavListItem
              data-cy="global-header_program-search-dropdown"
              active={onMarketAlignmentPage}
            >
              <StyledFlyoutMenu
                ariaLabel="search for a program"
                buttonContent={
                  <Flex active={onMarketAlignmentPage}>
                    Market Alignment
                    <StyledSpan active={onMarketAlignmentPage}>
                      <StyledSiteCaret />
                    </StyledSpan>
                  </Flex>
                }
              >
                {({ getItemProps, handleClose }) => (
                  <ProgramSearchDropdown getItemProps={getItemProps} handleClose={handleClose} />
                )}
              </StyledFlyoutMenu>
            </NavListItem>
          )}
          {!limitedContent && (
            <li>
              <StyledFlyoutMenu
                ariaLabel="explore more of skillabi"
                buttonContent={
                  <Flex>
                    More <StyledSiteCaret />
                  </Flex>
                }
              >
                {({ getItemProps, handleClose }) => (
                  <>
                    {shouldCollapseButton('courses') && (
                      <MenuItem {...getItemProps(undefined, { onClick: handleClose })}>
                        <StyledInternalLink to="/dashboard/courses">Courses</StyledInternalLink>
                      </MenuItem>
                    )}
                    {shouldCollapseButton('programs') && (
                      <MenuItem {...getItemProps(undefined, { onClick: handleClose })}>
                        <StyledInternalLink to="/dashboard/programs">Programs</StyledInternalLink>
                      </MenuItem>
                    )}
                    {!limitedContent && (
                      <MenuItem
                        {...getItemProps(
                          { firstOrLastPosition: 'first' },
                          { onClick: handleClose }
                        )}
                      >
                        <StyledInternalLink to="/dashboard/posting">
                          Start With a Job Posting
                        </StyledInternalLink>
                      </MenuItem>
                    )}
                    {/* {!limitedContent && (
                      <MenuItem {...getItemProps()}>
                        <StyledLoadingButton
                          type="button"
                          loaderColor="gray"
                          isLoading={exportIsLoading}
                          onClick={async () => {
                            exportSkills(
                              `Skillabi_${profileState.currentSite}_Marketable_Skills_Data`,
                              undefined
                            );
                          }}
                        >
                          Export Marketable Skills
                        </StyledLoadingButton>
                      </MenuItem>
                    )} */}
                    {hasEditorPrivileges && !limitedContent && (
                      <MenuItem
                        {...getItemProps({ firstOrLastPosition: 'last' }, { onClick: handleClose })}
                      >
                        <StyledInternalLink to="/dashboard/program-types">
                          Edit Program Types
                        </StyledInternalLink>
                      </MenuItem>
                    )}
                  </>
                )}
              </StyledFlyoutMenu>
            </li>
          )}
          <li>
            <StyledFlyoutMenu
              ariaLabel="account menu"
              buttonContent={
                <Account
                  css={css`
                    ${svgBase}
                  `}
                />
              }
            >
              {({ getItemProps, handleClose }) => (
                <>
                  {profileState.hasAdminPanelAccess && (
                    <MenuItem
                      {...getItemProps(
                        { firstOrLastPosition: 'first' },
                        { onClick: () => handleClose() }
                      )}
                    >
                      <StyledInternalLink to="/admin">Admin</StyledInternalLink>
                    </MenuItem>
                  )}
                  <MenuItem
                    {...getItemProps(
                      { firstOrLastPosition: 'last' },
                      {
                        onClick: () => {
                          logout();
                          handleClose();
                        }
                      }
                    )}
                  >
                    <HeaderActionButton>Log Out</HeaderActionButton>
                  </MenuItem>
                </>
              )}
            </StyledFlyoutMenu>
          </li>
        </NavList>
      </Nav>
    </Header>
  );
};

const NavListItem = styled.li<{ active: boolean }>`
  padding-top: 0.5rem;
  border-bottom: 0.3rem solid ${({ active }) => (active ? darkSaphire : 'transparent')};
`;

const Header = styled.header`
  height: 7rem;
  background: ${white};
  display: flex;
  align-items: center;
  padding: 0 4rem;
  justify-content: space-between;
  margin-bottom: 5rem;
  font-weight: 600;
`;

const Flex = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ active }) => (active ? darkSaphire : 'inherit')};
`;

const LogoLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: inherit;
  padding: 1.5rem 1rem;
  text-decoration: none;
`;

const StyledLogo = styled(Logo)`
  width: 2.3rem;
  height: 2.3rem;
`;

const Skillabi = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  margin-left: 1rem;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const Nav = styled.nav`
  height: 100%;
`;

const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  height: 100%;
  padding-left: 0;
  & > li {
    font-size: 1.4rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;

    /* This is the thin gray line between Explore and Account */
    &:last-of-type::before {
      content: '';
      width: 2px;
      height: 3.2rem;

      background: ${borderGray};
      margin: 0 2.8rem;
    }

    & > a {
      height: 100%;
      border-bottom: 0.3rem solid transparent;
      color: inherit;
      text-decoration: none;
    }

    & > .active-nav {
      color: ${darkSaphire};
      border-color: ${darkSaphire};
    }
  }
`;

const StyledInternalLink = styled(InternalLink)`
  color: unset;
`;

const svgBase = css`
  margin-right: 0.5rem;
`;

const HeaderActionButton = styled.button`
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 0.5rem;
  font-weight: 600;
  font-size: 1.4rem;

  &:hover,
  &:focus {
    background: ${lightGray};
    border-radius: 4px;
  }
`;

const StyledFlyoutMenu = styled(FlyoutMenu)`
  border-bottom: 3px solid transparent;
  width: max-content;
  .flyoutMenu {
    width: max-content;
    right: 0;
  }
`;

const SiteSelectContainer = styled.div<{ hasMultiSites: boolean }>`
  font-size: 1.5rem;
  font-weight: normal;
  padding-left: 2.4rem;
  padding-right: ${({ hasMultiSites }) => (hasMultiSites ? '4rem' : '2rem')};
  position: relative;
  white-space: nowrap;

  > button {
    background: none;
    border: none;
    text-align: left;

    > div {
      display: inline-grid;
      width: fit-content;
      ${({ hasMultiSites }) => (hasMultiSites ? 'cursor: pointer;' : 'cursor: unset;')}

      > span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        min-width: 2rem;
      }
    }
  }
`;

const StyledSpan = styled.span<{ active?: boolean }>`
  svg {
    stroke: ${({ active }) => (active ? darkSaphire : darkBlue)};
  }
`;

const StyledSiteCaret = styled(Caret)`
  margin-left: 1rem;
  margin-top: 0.5rem;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  stroke: ${darkBlue};
`;

const StyledDisabledSiteX = styled(XRemove)`
  margin-right: 0.5rem;
  margin-bottom: -0.1rem;
  width: 1.2rem;
  height: 1.2rem;
  & > path {
    stroke: ${sailorRed};
  }
`;

const ShrinkableFlex = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const WHEN_TO_COLLAPSE_BUTTONS = {
  programs: 800,
  courses: 900
};

const StyledButton = styled.button`
  border: none;
`;

export default GlobalHeader;
