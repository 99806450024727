import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { ReactComponent as ArrowRight } from 'images/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'images/arrowLeft.svg';
import { lightGray } from 'utils/colors';

const PlaceholderSpan = styled.span<{ size: number }>`
  display: inline-block;
  background: ${lightGray};
  border-radius: 0.4rem;
  height: 4rem;
  margin: 0 1.5rem 1.3rem 0;
  width: ${({ size }) => size}rem;
`;

const ShimmerKeyFrame = keyframes`
  0% {
    background-position: -468px 0;
  }

  100% {
    backgroundPosition: 468px 0;
  }
`;

const PlaceholderSpansWrapper = styled.div`
  max-width: 62rem;
  span.isLoading {
    background-image: linear-gradient(to right, #e2e8ef 0%, #f3f4f8 20%, #e2e8ef 40%, #e2e8ef 100%);
    background-repeat: no-repeat;
    background-size: 800px 100px;

    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: ${ShimmerKeyFrame};
    animation-name: ${ShimmerKeyFrame};
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
`;

const PlaceholderArrowsContainer = styled.div`
  margin-top: 4.5rem;
  display: flex;
`;

const PlaceholderArrow = styled.span`
  display: inline;
  background: ${lightGray};
  border-radius: 4px;
  width: 5rem;
  height: 4rem;
  margin: 2rem 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddSkillPlaceholderLoading: React.FC<{ isLoading?: boolean }> = ({ isLoading = false }) => (
  <>
    <PlaceholderSpansWrapper>
      {[13, 15, 8, 13, 10, 9, 16, 12, 7, 14, 9, 16, 8].map((num, i) => (
        <PlaceholderSpan
          className={isLoading ? 'isLoading' : 'notLoading'}
          size={num}
          key={`placeholder-span-${i}`}
        />
      ))}
    </PlaceholderSpansWrapper>
    <PlaceholderArrowsContainer>
      <PlaceholderArrow>
        <ArrowLeft />
      </PlaceholderArrow>
      <PlaceholderArrow>
        <ArrowRight />
      </PlaceholderArrow>
    </PlaceholderArrowsContainer>
  </>
);

export default AddSkillPlaceholderLoading;
