import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'components/atoms/Button';
import BenchmarksBrowser from 'components/molecules/BenchmarksBrowser';
import { DetailedBenchmarkCardProps } from 'components/molecules/DetailedBenchmarkCard';
import CreateCustomBenchmarkModal from 'components/molecules/CreateCustomBenchmarkModal';
import { BenchmarkPopup } from 'components/molecules/BenchmarkPopup';

import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { mediumLightGray, navyBlue, borderBlue, skyBlue } from 'utils/colors';
import { UniversalBenchmark } from 'helpers/benchmarks';
import formatLargeNumber from 'utils/formatLargeNumber';
import { useCustomBenchmarksByProgramId } from 'hooks/curricularSkills';
import { PillProps } from 'components/atoms/InfoPill';

interface Props {
  programId: string;
  selectedBenchmarks: UniversalBenchmark[];
  onSelectBenchmark(benchmark: UniversalBenchmark): void;
  onDeselectBenchmark(benchmark: UniversalBenchmark): void;
}

const CustomBenchmarks: React.FC<Props> = ({
  programId,
  selectedBenchmarks,
  onSelectBenchmark,
  onDeselectBenchmark
}) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const { customBenchmarks, isLoading: customBenchmarksIsLoading } = useCustomBenchmarksByProgramId(
    programId,
    true
  );

  const formattedBenchmarkCards = useMemo(() => {
    return customBenchmarks.map((benchmark, index) => {
      const pills: PillProps[] = [
        {
          label: benchmark.type === 'skillsFromTextBenchmark' ? 'Text' : 'Custom',
          scheme: 'yellow'
        }
      ];

      if (benchmark.totals?.median_salary) {
        pills.push({
          label: `$${formatLargeNumber(benchmark.totals?.median_salary)}`,
          scheme: 'green'
        });
      }

      const formattedBenchmark: DetailedBenchmarkCardProps = {
        id: benchmark.id,
        title: benchmark.attributes.title,
        isCustom: true,
        pills,
        isSelected: !!selectedBenchmarks.find(
          selectedBench => selectedBench.attributes.id === benchmark.id
        ),
        allowCardSelection: true,
        renderDetailView: () => (
          <BenchmarkPopup
            benchmark={{
              universalType: 'custom',
              attributes: benchmark.attributes
            }}
          />
        ),
        onSelectionChange: selectionVal => {
          return selectionVal
            ? onSelectBenchmark({
                universalType: 'custom',
                attributes: { ...benchmark.attributes }
              })
            : onDeselectBenchmark({
                universalType: 'custom',
                attributes: { ...benchmark.attributes }
              });
        },
        tabIndex: index + 1
      };

      return formattedBenchmark;
    });
  }, [customBenchmarks, selectedBenchmarks]);

  const methods = useForm({
    defaultValues: {
      skills: [],
      name: '',
      text: ''
    }
  });

  const closeModal = () => {
    setCreateModalOpen(false);
    methods.reset();
  };

  const noBenchmarksView = (
    <NoBenchmarksContainer>
      <BoldSpan>No custom benchmarks have been created yet.</BoldSpan>
      <Span>Click the button below to get started.</Span>
      <OutlineCreateButton type="button" onClick={() => setCreateModalOpen(true)}>
        <StyledPlus color={navyBlue} />
        Create New
      </OutlineCreateButton>
    </NoBenchmarksContainer>
  );

  return (
    <Container>
      <BenchmarkLabelContainer>
        <span>Created Benchmarks:</span>
        <Button
          type="button"
          onClick={() => setCreateModalOpen(true)}
          data-cy="custom-benchmarks-browser_new-benchmark-button"
        >
          <StyledPlus color="white" />
          New Benchmark
        </Button>
      </BenchmarkLabelContainer>

      {isCreateModalOpen ? (
        <FormProvider {...methods}>
          <CreateCustomBenchmarkModal programId={programId} onCloseModal={closeModal} />
        </FormProvider>
      ) : null}

      <BenchmarksBrowser
        benchmarks={formattedBenchmarkCards}
        noBenchmarksView={noBenchmarksView}
        isLoading={customBenchmarksIsLoading}
      />
    </Container>
  );
};

export default CustomBenchmarks;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BenchmarkLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const NoBenchmarksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
`;

const Span = styled.span`
  display: block;
  font-size: 1.4rem;
  color: ${mediumLightGray};
  margin-bottom: 3rem;
`;

const BoldSpan = styled(Span)`
  font-weight: bold;
  margin: 0;
`;

const OutlineCreateButton = styled(Button)`
  width: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed ${borderBlue};
  color: ${navyBlue};
  border-radius: 0.4rem;
  font-size: 1.4rem;
  height: 14rem;
  background: inherit;

  transition: background 0.2s ease-in;
  &:hover {
    background: ${skyBlue};
  }

  @media (max-width: 1366px) {
    margin: 0rem 15rem 0rem 15rem;
  }
`;

const StyledPlus = styled(Plus)<{ color?: string }>`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  stroke: ${({ color }) => color};
`;
