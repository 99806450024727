import React, { ReactNode, useRef } from 'react';
import styled from '@emotion/styled';
import { usePopup } from 'hooks/usePopup';

const HoverPopover: React.FC<{
  renderContent(): ReactNode;
  onClose?(): unknown;
}> = ({ renderContent, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [Popup, , isShowingDetail, { openPopup, closePopup }] = usePopup({
    omitCloseBtn: true,
    omitAnchorDisplay: true
  });

  return (
    <Container ref={ref} onMouseEnter={() => openPopup()} onMouseLeave={() => closePopup()}>
      {children}
      {isShowingDetail && <Popup anchorPointRef={ref}>{renderContent()}</Popup>}
    </Container>
  );
};

export default HoverPopover;

const Container = styled.div`
  cursor: default;
`;
