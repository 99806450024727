import React from 'react';
import { useQueryClient } from 'react-query';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import Header from 'components/molecules/Header';
import ProgramsDashboardTable, {
  LOCATION_STATE_KEY,
  buildSearchPayload
} from 'components/organisms/ProgramsDashboardTable';
import { usePrograms, useExport } from 'hooks';
import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import { ReactComponent as ProgramIcon } from 'images/programIcon.svg';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { ReactComponent as Download } from 'images/cloudDownload.svg';
import ExportDropdownFlyout from 'components/molecules/ExportDropdownFlyout';

import { exportAllPrograms } from 'helpers/exports/exportAllPrograms';
import { hasSiteAccessLevel, useProfileState } from 'store/profileStore';
import { darkSaphire, white } from 'utils/colors';
import { TableProvider, useTableState } from 'store/tableStore';
import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
`;

const StyledDownload = styled(Download)<{ color: string }>`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  stroke: ${({ color }) => color};
`;

const StyledLoadingButton = styled(LoadingButton)`
  min-width: 10rem;
`;

const NewProgramButton = styled(Button)`
  margin-left: 1rem;
`;

const Programs: React.FC = () => {
  const { data } = usePrograms({ limit: 0 });
  const queryClient = useQueryClient();
  const { currentSite, ...rest } = useProfileState();
  const { mode, selectedOrDeselected } = useTableState();

  const location = useLocation<{ [x: string]: ProgramSearchState | undefined }>();
  const state = location?.state?.[LOCATION_STATE_KEY] || {
    ...defaultCurricularRouteSearchState(),
    filter: {
      ...defaultCurricularRouteSearchState().filter,
      programTypeFilter: '',
      programTypeLabel: 'All Program Types'
    }
  };

  const programIdFilter = {
    ids:
      mode === 'selecting' && selectedOrDeselected.length !== 0
        ? { in: selectedOrDeselected }
        : { exclude: selectedOrDeselected }
  };

  const { filter } = buildSearchPayload(state);

  const programsFilter = mode === 'selecting' ? programIdFilter : { ...filter, ...programIdFilter };

  const [exportAllData, { exportIsLoading: exportAllIsLoading }] = useExport({
    filename: `${currentSite}_program_export`,
    exportDataFn: exportAllPrograms(currentSite, queryClient),
    isPreloading: false
  });
  const hasEditorPrivileges = hasSiteAccessLevel(currentSite, 'editor', { currentSite, ...rest });

  const [exportSelectedData, { exportIsLoading: exportSelectedIsLoading }] = useExport({
    filename: `${currentSite}_program_export`,
    exportDataFn: exportAllPrograms(currentSite, queryClient, programsFilter),
    isPreloading: false
  });

  return (
    <>
      <Header headerText="Programs" count={data && data.meta.totalAvailable} Icon={ProgramIcon}>
        {selectedOrDeselected.length > 0 || mode === 'deselecting' ? (
          <ExportDropdownFlyout
            className={'export-courses-dropdown-select'}
            isExportAllLoading={exportAllIsLoading}
            isExportSelectedLoading={exportSelectedIsLoading}
            exportAllFunc={() => exportAllData()}
            exportSelectedFunc={() => exportSelectedData()}
            buttonAriaLabel="Export Programs"
          />
        ) : (
          <StyledLoadingButton
            isLoading={exportAllIsLoading}
            loaderColor="gray"
            type="button"
            scheme={hasEditorPrivileges ? 'outline' : 'default'}
            onClick={() => exportAllData()}
          >
            <StyledDownload
              title="cloud download icon"
              color={hasEditorPrivileges ? darkSaphire : white}
            />
            Export
          </StyledLoadingButton>
        )}
        {hasEditorPrivileges && (
          <NewProgramButton as={Link} to="/add/program" type="button">
            <StyledPlus title="create new plus icon" />
            New Program
          </NewProgramButton>
        )}
      </Header>
      <ProgramsDashboardTable trackPages hasEditorPrivileges={hasEditorPrivileges} />
    </>
  );
};
const ProgramsWrapper: React.FC = () => {
  return (
    <TableProvider>
      <Programs />
    </TableProvider>
  );
};

export default ProgramsWrapper;
