import React from 'react';
import styled from '@emotion/styled';

import InputLabel from 'components/atoms/InputLabel';
import { Menu } from 'components/atoms/FlyoutMenu';

import { ReactComponent as Caret } from 'images/caret.svg';
import { borderGray, bgLightGray, darkBlue, ahoy, bgDarkGray } from 'utils/colors';
import { useOnClickOutside, useDropdown, DropdownSelectable } from 'hooks';
import { FieldError } from 'react-hook-form';

export type { DropdownSelectable } from 'hooks';

const DropdownButton = styled.button<{ error?: boolean }>`
  position: relative;
  font-size: 1.4rem;
  padding: 1rem 1.6rem;
  border: ${props => (props.error ? `1px solid ${ahoy}` : `1px solid ${borderGray}`)};
  border-radius: 0.4rem;
  background: ${bgLightGray};
  width: 18.8rem;
  display: flex;
  text-align: left;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropDownItem = styled.li`
  list-style-type: none;
  margin: 0 0.8rem;
  padding: 0.7rem 1rem;
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${bgDarkGray};
    border-radius: 0.4rem;
  }
`;

const Placeholder = styled.li`
  list-style-type: none;
  margin: 0 0.8rem;
  padding: 0.7rem 1rem;
  font-size: 1.4rem;
`;

const StyledMenu = styled(Menu)<{ menuWidth?: string }>`
  width: ${({ menuWidth }) => (menuWidth ? menuWidth : 'auto')};
  padding-bottom: 0.6rem;
  border-radius: 0 0 0.4rem 0.4rem;

  @media (max-width: 1366px) {
    right: auto;
  }
`;

const FixedHeightContainer = styled.ul`
  padding: 0;
  margin: 0;
  max-height: 25rem;
  overflow-y: auto;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCaret = styled(Caret)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  stroke: ${darkBlue};
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: ${ahoy};
  bottom: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export type DropdownFlyoutProps<T = string> = Omit<
  React.HTMLAttributes<HTMLButtonElement>,
  'defaultValue' | 'onSelect'
> & {
  dropDownName: string;
  items: DropdownSelectable<T>[];
  labelText?: string;
  menuPlaceholder?: React.ReactNode;
  dropDownFooter?: React.ReactNode;
  defaultValue?: DropdownSelectable<T>;
  dropDownContentWidth?: string;
  dropdownPlaceholder?: React.ReactNode;
  onSelect?: (item: DropdownSelectable<T>) => void;
  isDisabled?: boolean;
  clearOnItemsChange?: boolean;
  icon?: React.ReactNode;
  selected?: DropdownSelectable<T>;
  error?: FieldError;
  preventOnClickOutside?: boolean;
};

function DropdownFlyout<T = string>({
  dropDownName,
  items,
  labelText,
  menuPlaceholder,
  dropDownFooter,
  dropDownContentWidth,
  defaultValue,
  dropdownPlaceholder,
  onSelect,
  isDisabled,
  icon,
  selected,
  error,
  preventOnClickOutside,
  ...buttonProps
}: DropdownFlyoutProps<T>): React.ReactElement {
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const {
    getItemProps,
    getToggleButtonProps,
    getListboxProps,
    getLabelProps,
    isOpen,
    handleClose,
    selectedItem
  } = useDropdown(dropDownName, onSelect, selected, defaultValue);

  useOnClickOutside(dropdownRef, () => !preventOnClickOutside && handleClose());

  // This is a zero width space which forces the span to be line height
  // without actually displaying anything in the span or hard coding a
  // height
  const lineHeightEmptyString = '​';

  return (
    <Wrapper>
      <DropdownWrapper ref={dropdownRef}>
        {labelText && <InputLabel {...getLabelProps()}>{labelText}</InputLabel>}
        <DropdownButton
          data-cy={`dropdown-flyout_button-${dropDownName}`}
          {...getToggleButtonProps()}
          disabled={isDisabled}
          error={!!error}
          {...buttonProps}
        >
          {icon && icon}
          <StyledSpan>
            {selectedItem?.label || dropdownPlaceholder || lineHeightEmptyString}
          </StyledSpan>
          <StyledCaret />
        </DropdownButton>
        <MenuWrapper>
          {isOpen && (
            <StyledMenu as="div" menuWidth={dropDownContentWidth}>
              <FixedHeightContainer {...getListboxProps()}>
                {items && items.length ? (
                  items.map((item, index) => (
                    <DropDownItem
                      data-cy={`dropdown-flyout_item_${item.value}`}
                      key={`Menu-item-${index}`}
                      {...getItemProps(item, index)}
                    >
                      {item.label}
                    </DropDownItem>
                  ))
                ) : (
                  <Placeholder>{menuPlaceholder}</Placeholder>
                )}
              </FixedHeightContainer>
              {dropDownFooter}
            </StyledMenu>
          )}
        </MenuWrapper>
      </DropdownWrapper>
      {error && <Error>{error.message}</Error>}
    </Wrapper>
  );
}

export default DropdownFlyout;
