import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import LoadingDashboardTable from 'components/atoms/LoadingDashboardTable';
import DashboardTableStyles from 'components/atoms/DashboardTableStyles';
import EmptyDashboardTable from 'components/atoms/EmptyDashboardTable';
import Pagination from 'components/molecules/Pagination';

import Table, {
  svgStyles,
  InvisibleButton,
  HeaderCell,
  Cell,
  TableNumber,
  Column
} from 'components/molecules/Table';
import SelectText from 'components/molecules/SelectText';
import TableFilters from 'components/organisms/TableFilters';
import ButtonFlyoutMenu from 'components/organisms/ButtonFlyoutMenu';
import { preparePrograms } from 'helpers/prepareTables';

import { ReactComponent as Check } from 'images/greenCheck.svg';
import { ReactComponent as Document } from 'images/document.svg';
import { ReactComponent as Clock } from 'images/clock.svg';
import { ReactComponent as SortArrow } from 'images/sortArrow.svg';

import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import { darkDarkEmerald } from 'utils/colors';

import { useTableState, useTableDispatch } from 'store/tableStore';
import {
  DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
  DropdownSelectable,
  usePrograms,
  useQueryString
} from 'hooks';
import { isPageSelected, getSelectedOnPage } from 'store/tableStore';

const getTableColumns = (hasEditorAccess: boolean) => {
  const columns: Column<PreppedProgram, ProgramSortOption>[] = [
    {
      id: 'title',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell width="100%" key="title-header">
          <SortableHeader onClick={() => onSort?.('title')}>
            Program Title{' '}
            {sortedColumn && (
              <StyledSortArrow
                isHidden={sortedColumn.sortType !== 'title'}
                sortOrder={sortedColumn.sortOrder}
              >
                <SortArrow />
              </StyledSortArrow>
            )}
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`title-${row.id}`}>
          <InvisibleButton>{row.title}</InvisibleButton>
        </Cell>
      )
    },
    {
      id: 'programType',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="program-type-header"
          css={css`
            min-width: 20rem;
            @media (min-width: 1700px) {
              padding-right: 5rem;
              min-width: 40rem;
            }
            @media (min-width: 1920px) {
              min-width: 30rem;
            }
          `}
        >
          <SortableHeader onClick={() => onSort?.('groupType')}>
            Program Type{' '}
            {sortedColumn && (
              <StyledSortArrow
                isHidden={sortedColumn.sortType !== 'groupType'}
                sortOrder={sortedColumn.sortOrder}
              >
                <SortArrow />
              </StyledSortArrow>
            )}
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => <Cell key={`program-type-${row.id}`}>{row.programType}</Cell>
    },
    {
      id: 'courses',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="courses-header"
          css={css`
            min-width: 10rem;
            @media (min-width: 1700px) {
              padding-right: 10rem;
            }
          `}
        >
          <SortableHeader onClick={() => onSort?.('courses')}>
            Courses{' '}
            {sortedColumn && (
              <StyledSortArrow
                isHidden={sortedColumn.sortType !== 'courses'}
                sortOrder={sortedColumn.sortOrder}
              >
                <SortArrow />
              </StyledSortArrow>
            )}
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`courses-${row.id}`}>
          <TableNumber>{row.courses}</TableNumber>
        </Cell>
      )
    },
    {
      id: 'isPublished',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="status-header"
          css={css`
            min-width: 14rem;
            @media (min-width: 1700px) {
              padding-right: 10rem;
            }
            @media (min-width: 1920px) {
              padding-right: 15rem;
            }
          `}
        >
          <SortableHeader onClick={() => onSort?.('isPublished')}>
            Status{' '}
            {sortedColumn && (
              <StyledSortArrow
                isHidden={sortedColumn.sortType !== 'isPublished'}
                sortOrder={sortedColumn.sortOrder}
              >
                <SortArrow />
              </StyledSortArrow>
            )}
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`status-${row.id}`}>
          {row.isPublished ? (
            <Flex>
              <Check
                css={css`
                  ${svgStyles}
                  stroke: ${darkDarkEmerald};
                `}
              />
              <span
                css={css`
                  color: ${darkDarkEmerald};
                `}
              >
                Published
              </span>
            </Flex>
          ) : (
            <Flex>
              <Document css={svgStyles} />
              Draft
            </Flex>
          )}
        </Cell>
      )
    },
    {
      id: 'updatedAt',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="updated-at-header"
          css={css`
            min-width: 16rem;
            @media (min-width: 1700px) {
              padding-right: 5rem;
            }
          `}
        >
          <SortableHeader onClick={() => onSort?.('updatedAt')}>
            Last Edited{' '}
            {sortedColumn && (
              <StyledSortArrow
                isHidden={sortedColumn.sortType !== 'updatedAt'}
                sortOrder={sortedColumn.sortOrder}
              >
                <SortArrow />
              </StyledSortArrow>
            )}
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`updated-at-${row.id}`}>
          <Flex>
            <Clock css={svgStyles} />
            {row.updatedAt}
          </Flex>
        </Cell>
      )
    }
  ];
  if (hasEditorAccess) {
    columns.push({
      id: 'edit',
      renderHeader: () => (
        <HeaderCell
          className="edit-column"
          key="edit-header"
          css={css`
            min-width: 8rem;
          `}
        >
          Edit
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`edit-${row.id}`} className="edit-column">
          <ButtonFlyoutMenu row={row} tableType="program" />
        </Cell>
      )
    });
  }
  return columns;
};

interface ProgramsDashboardTableProps {
  trackPages?: boolean;
  hasEditorPrivileges?: boolean;
}
type SortQuery = {
  sortType: ProgramSortOption;
  sortOrder: 'ascending' | 'descending';
};

export const buildSearchPayload = ({
  filter,
  sort
}: {
  filter: (TextQuery | SkillQuery | CourseQuery | ProgramQuery) & {
    programTypeFilter: string;
    programTypeLabel: string;
  };
  sort?: SortQuery;
}) => {
  const sorts: Sort<ProgramSortOption> = {};
  const programSearchFilters: ProgramSearchFilters = {};
  if (sort) {
    sorts.sort = [[sort.sortType, sort.sortOrder]];
  }

  if (filter.filterType === 'text' && filter.filterQuery.query.length > 1) {
    programSearchFilters.textFilter = filter.filterQuery;
  }
  if (filter.filterType === 'skills' && filter.filterQuery.length) {
    if (filter.filterOperator === 'all') {
      programSearchFilters.skills = { all: filter.filterQuery.map(skill => skill.id) };
    } else {
      programSearchFilters.skills = { in: filter.filterQuery.map(skill => skill.id) };
    }
  }
  if (filter.filterType === 'courses' && filter.filterQuery.length) {
    if (filter.filterOperator === 'all') {
      programSearchFilters.courses = { all: filter.filterQuery.map(skill => skill.id) };
    } else {
      programSearchFilters.courses = { in: filter.filterQuery.map(skill => skill.id) };
    }
  }

  if (filter.programTypeFilter) {
    programSearchFilters.groupType = { in: [filter.programTypeFilter] };
  }

  return { filter: programSearchFilters, sort: sorts.sort };
};

export const LOCATION_STATE_KEY = 'programDashboardFilters';
const ProgramsDashboardTable: React.FC<ProgramsDashboardTableProps> = ({
  trackPages,
  hasEditorPrivileges = false
}) => {
  const { page: queryPage } = useQueryString();
  const location = useLocation<{ [x: string]: ProgramSearchState | undefined }>();
  const state = location?.state?.[LOCATION_STATE_KEY] || {
    ...defaultCurricularRouteSearchState(),
    filter: {
      ...defaultCurricularRouteSearchState().filter,
      programTypeFilter: '',
      programTypeLabel: 'All Program Types'
    }
  };
  const { selectedOrDeselected, mode, page } = useTableState();
  const currentPage = trackPages ? Number(queryPage) || 1 : page;
  const tableDispatch = useTableDispatch();
  const history = useHistory();
  const { filter, sort } = buildSearchPayload(state);
  const { data, isLoading, isPreviousData } = usePrograms({
    limit: DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    offset: (currentPage - 1) * DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    filter,
    sort
  });
  const isRequesting = isLoading || isPreviousData;

  const programs = React.useMemo(() => data && preparePrograms(data), [data]);

  const idsOnPage = programs?.map(program => program.id) || [];

  const allItemsOnPageChecked = isPageSelected(mode, selectedOrDeselected, idsOnPage);

  const selectedOnPage = getSelectedOnPage(mode, selectedOrDeselected, idsOnPage);

  const pageCount = Math.ceil((data?.meta.totalAvailable || 0) / DEFAULT_DASHBOARD_TABLE_PAGE_SIZE);

  const programTypeFilter: DropdownSelectable = {
    value: state.filter.programTypeFilter,
    label: state.filter.programTypeLabel
  };

  const selectPage = () => {
    if (allItemsOnPageChecked) {
      tableDispatch({ type: 'DESELECT_PAGE', payload: idsOnPage });
    } else {
      tableDispatch({ type: 'SELECT_PAGE', payload: idsOnPage });
    }
  };

  const sortTable = React.useCallback(
    (column: ProgramSortOption) => {
      const { sortOrder, sortType } = state.sort;
      let newSortOrder: 'ascending' | 'descending' = 'ascending';
      if (column === sortType) {
        newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
      }
      history.push(location.pathname, {
        ...location.state,
        [LOCATION_STATE_KEY]: { ...state, sort: { sortOrder: newSortOrder, sortType: column } }
      });
    },
    [state]
  );

  const handleProgramTypeChange = (item: DropdownSelectable) => {
    history.push(location.pathname, {
      ...location.state,
      [LOCATION_STATE_KEY]: {
        ...state,
        filter: {
          ...state.filter,
          programTypeFilter: item.value,
          programTypeLabel: item.label
        }
      }
    });
  };

  return (
    <>
      <TableFilters
        dataCount={data && data.meta.totalAvailable}
        tableType="Program"
        isRequesting={isRequesting}
        onProgramTypeSelect={handleProgramTypeChange}
        selectedProgramType={programTypeFilter}
        locationStateKey={LOCATION_STATE_KEY}
      />
      {programs ? (
        <>
          <SelectText
            tableItemLabel="program"
            allItemsOnPageChecked={allItemsOnPageChecked}
            selectableCount={data && data.meta.totalAvailable}
            onSelectAll={() => {
              tableDispatch({ type: 'SELECT_ALL' });
            }}
            clearSelection={() => tableDispatch({ type: 'DESELECT_ALL' })}
            selectPage={selectPage}
          />
          <Table
            tableCaption="Programs Table"
            rowAriaLabel="click this row to edit this program"
            css={DashboardTableStyles}
            columns={getTableColumns(hasEditorPrivileges)}
            data={programs}
            onSort={sortTable}
            sortedColumn={state.sort}
            onSelect={id =>
              hasEditorPrivileges
                ? selectedOnPage.includes(id)
                  ? tableDispatch({ type: 'DESELECT_ROW', payload: id })
                  : tableDispatch({ type: 'SELECT_ROW', payload: id })
                : undefined
            }
            selected={hasEditorPrivileges ? selectedOnPage : undefined}
            selectPage={selectPage}
            onRowClick={row => {
              history.push(`/program/summary/${row.id}`);
            }}
            EmptyComponent={<EmptyDashboardTable label="program" />}
          />

          {<Pagination {...{ pageCount, trackPages }} />}
        </>
      ) : (
        <LoadingDashboardTable />
      )}
    </>
  );
};

export default ProgramsDashboardTable;

const Flex = styled.div`
  display: flex;
`;

const SortableHeader = styled(Flex)`
  display: inline-flex;
  cursor: pointer;
`;

const StyledSortArrow = styled.div<{ isHidden: boolean; sortOrder?: 'ascending' | 'descending' }>`
  margin-left: 0.3rem;
  & > svg {
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    transform: ${({ sortOrder }) => (sortOrder === 'ascending' ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;
