import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';

import Loading from 'components/atoms/Loading';
import NothingPlaceholder from 'components/atoms/NothingPlaceholder';

import formatLargeNumber from 'utils/formatLargeNumber';
import {
  bgTableBlue,
  darkSaphire,
  hoverTableBlue,
  hoverTableBorderBlue,
  mediumGray,
  white
} from 'utils/colors';
import { CustomErrorMessage } from 'components/molecules/SkillInsights';
import TaughtSoughtBar from 'components/atoms/TaughtSoughtBar';
import { EnhancedSkill } from 'hooks/programInsights';

export interface BarChartProps<T extends EnhancedSkill = EnhancedSkill> {
  disabled?: boolean;
  className?: string;
  onRowClick?: (item: { id: string; name: string }) => void;
  legend?: React.ReactElement;
  headingText: string;
  subheader?: React.ReactNode;
  columnHeaders: [string, string];
  data: Record<string, T>;
  isLoading?: boolean;
  onUpdate?(): void;
  query?: JPAOptionsFilter;
  customChartErrorMessage?: CustomErrorMessage;
}

const BarChart: React.FC<BarChartProps> = ({
  disabled,
  className,
  data,
  headingText,
  subheader,
  columnHeaders,
  legend,
  onRowClick,
  isLoading,
  onUpdate,
  customChartErrorMessage
}) => {
  const maxValue = Math.max(...Object.values(data).map(({ count }) => count));
  const [leftHeader, rightHeader] = columnHeaders;
  const rows = React.useMemo(
    () =>
      Object.entries(data)
        .sort(([aId, aInfo], [bId, bInfo]) => bInfo.count - aInfo.count)
        .map(([id, info], i) => {
          const formattedCount = formatLargeNumber(info.count);

          // We want to leave space for the label
          const countPercentage = (info.count / maxValue) * 90;

          // We don't want the bar to get too small, lest it hide the difference between taught_other and sought
          const regulatedCountPercentage = Math.max(countPercentage, 8);

          return (
            <ChartRow
              aria-label={`${info.name} skill. ${
                formattedCount.endsWith('M')
                  ? `${formattedCount.slice(0, -1)} million`
                  : formattedCount.endsWith('K')
                  ? `${formattedCount.slice(0, -1)} thousand`
                  : formattedCount
              } job postings.`}
              isOddRow={!(i % 2)}
              key={id}
              onClick={() => {
                onRowClick && onRowClick({ id, name: info.name });
              }}
            >
              <RowLabel data-cy={`bar-chart_row-label_${info.id}`}>{info.name}</RowLabel>
              <StyledSpan>
                <SizedBox width={regulatedCountPercentage}>
                  <TaughtSoughtBar state={info.taughtState} />
                </SizedBox>

                <BarLabel data-cy={`bar-chart_row-formatted-count_${info.id}`}>
                  {formattedCount}
                </BarLabel>
              </StyledSpan>
            </ChartRow>
          );
        }),
    [data, disabled, onRowClick]
  );

  useDeepCompareEffect(() => {
    if (data && onUpdate) {
      onUpdate();
    }
  }, [data]);

  return (
    <ChartWrapper className={className}>
      <ChartHeadRows>
        <ChartTitleAndLedgerRow>
          <ChartTitleAndLedgerItem>
            <GraphCardHeading>{headingText}</GraphCardHeading>
            {subheader && <GraphCardSubheading>{subheader}</GraphCardSubheading>}
          </ChartTitleAndLedgerItem>

          {legend && <ChartTitleAndLedgerItem>{legend}</ChartTitleAndLedgerItem>}
        </ChartTitleAndLedgerRow>

        <HeaderRow>
          <HeaderItem>{leftHeader}</HeaderItem>
          <HeaderItem>{rightHeader}</HeaderItem>
        </HeaderRow>
      </ChartHeadRows>

      <Chart>
        {isLoading ? (
          <ChartContainer>
            <Loading />
          </ChartContainer>
        ) : Object.entries(data).length ? (
          rows
        ) : (
          <NothingPlaceholder
            header={customChartErrorMessage?.headerText || 'No skills to show yet'}
            content={
              customChartErrorMessage?.contentText || 'Get started by selecting skills above.'
            }
          />
        )}
      </Chart>
    </ChartWrapper>
  );
};

const ChartHeadRows = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 0 2rem;
`;

const HeaderRow = styled.div`
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.27em;
  font-size: 1rem;
  font-weight: bold;
  color: ${mediumGray};
  display: flex;
  gap: 2rem;
`;

const ChartTitleAndLedgerRow = styled.div`
  display: flex;
  gap: 2rem;
`;

const ChartTitleAndLedgerItem = styled.div`
  flex: 1;
  min-width: 0;
`;

const HeaderItem = styled.div`
  flex: 1;
`;

const ChartRow = styled.button<{ isOddRow?: boolean }>`
  display: flex;
  text-align: left;
  border: none;
  background: ${({ isOddRow }) => (isOddRow ? bgTableBlue : white)};
  padding: 1.7rem 2rem;
  gap: 2rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:hover {
    border-top: 1px solid ${hoverTableBorderBlue};
    border-bottom: 1px solid ${hoverTableBorderBlue};

    cursor: pointer;
    background: ${hoverTableBlue};
    color: ${darkSaphire};
    & > span:first-of-type {
      text-decoration: underline;
    }
  }
`;

const BarLabel = styled.span`
  margin-right: 1rem;
  margin-left: 0.8rem;
  width: 10%;
`;

const StyledSpan = styled.span`
  width: 50%;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1.4rem;
`;

const RowLabel = styled(StyledSpan)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ChartWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const GraphCardHeading = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const GraphCardSubheading = styled.div`
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
`;

const Chart = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  height: 100%;
`;

const SizedBox = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}%`};
`;

export default BarChart;
