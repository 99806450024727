import React, { createContext, useContext, useReducer } from 'react';

function makeStore<T, A>(
  reducer: Reducer<T, A>,
  initialState: T
): [React.FC, () => Dispatch<A>, () => T] {
  // This return type is very nessesary, do not remove.

  const dispatchContext = createContext<Dispatch<A> | undefined>(undefined);
  // The initialState passed to createContext doesn't actually do anything
  // unless the context is used outside of it's Provider
  const storeContext = createContext(initialState);
  const StoreProvider: React.FC = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);
    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  const useDispatch = () => {
    const dispatch = useContext(dispatchContext);
    if (!dispatch) {
      // This should probably never be reached, but we need to account for an undefined state.
      throw new Error('No dispatch provider found');
    }
    return dispatch;
  };

  const useStore = () => useContext(storeContext);

  return [StoreProvider, useDispatch, useStore];
}

export interface AsyncStoreProps<T> {
  initialState: T;
  children: React.ReactNode;
}
// This version of makeStore is useful if the initialState for the store
// reducer is not defined at runtime, but instead will need to be loaded
// asynchronously.  The initialState is provided directly to the provider
// instead of the
export function asyncMakeStore<T, A>(
  reducer: Reducer<T, A>
): [React.FC<AsyncStoreProps<T>>, () => Dispatch<A>, () => T] {
  const dispatchContext = createContext<Dispatch<A> | undefined>(undefined);
  // We cast initialState as T here because the typescript typing incorrectly
  // requires the initialState param.  This is not actually required and is
  // only used when the context is not used within a Provider according to the
  // React docs.
  const storeContext = createContext({} as T);
  const StoreProvider: React.FC<AsyncStoreProps<T>> = ({ children, initialState }) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  const useDispatch = () => {
    const dispatch = useContext(dispatchContext);
    if (!dispatch) {
      // This should probably never be reached, but we need to account for an undefined state.
      throw new Error('No dispatch provider found');
    }
    return dispatch;
  };

  const useStore = () => useContext(storeContext);

  return [StoreProvider, useDispatch, useStore];
}

export default makeStore;
