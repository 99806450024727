import React from 'react';
import styled from '@emotion/styled';

import LoadingButton from 'components/atoms/LoadingButton';
import Button from 'components/atoms/Button';
import MessageModal from 'components/molecules/MessageModal';

import { useDeleteCustomBenchmark } from 'hooks/mutateHooks';

const StyledMessageModal = styled(MessageModal)`
  white-space: pre-line;
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 6.5rem;
`;
const DeleteBenchmarkModal: React.FC<{
  benchmarkID: string;
  closeModal: (e?: React.MouseEvent | Event) => void;
  onDelete?: (id: string) => void;
}> = ({ benchmarkID, closeModal, onDelete }) => {
  const { mutate: markBenchmarkAsDeleted, isLoading } = useDeleteCustomBenchmark(benchmarkID, {
    onSettled: () => {
      closeModal();
    },
    onSuccess: () => {
      onDelete && onDelete(benchmarkID);
    }
  });
  return (
    <StyledMessageModal
      title="Delete custom benchmark?"
      message="Are you sure you want to delete this benchmark? This action cannot be undone."
    >
      <ButtonsWrapper>
        <StyledButton scheme="outline" type="button" onClick={closeModal}>
          Cancel
        </StyledButton>
        <StyledLoadingButton
          scheme="danger"
          type="button"
          isLoading={isLoading}
          onClick={e => {
            markBenchmarkAsDeleted();
          }}
          data-cy="delete-benchmark-modal_delete-button"
        >
          Delete
        </StyledLoadingButton>
      </ButtonsWrapper>
    </StyledMessageModal>
  );
};

export default DeleteBenchmarkModal;
