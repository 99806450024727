import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { ReactComponent as CircularCheckedIcon } from 'images/circularCheckboxIcon.svg';

interface CircularCheckedBadgeProps extends React.SVGAttributes<SVGElement> {
  labelText?: string;
}
const CircularCheckedBadge: React.FC<CircularCheckedBadgeProps> = ({ labelText, ...props }) => {
  return (
    <label
      css={css`
        display: flex;
        align-items: center;
        position: relative;
        width: 1.6rem;
        height: 1.6rem;
      `}
    >
      {labelText}
      <CircularCheckedIcon
        css={css`
          pointer-events: none;
        `}
        {...props}
      />
    </label>
  );
};

export default CircularCheckedBadge;
