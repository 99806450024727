import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { hoverTableBorderBlue, hoverTableBlue, darkSaphire } from 'utils/colors';
import formatLargeNumber from 'utils/formatLargeNumber';
import { TaughtState } from 'hooks/programInsights';
import TaughtSoughtBar from 'components/atoms/TaughtSoughtBar';

interface ChartRowProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  count?: number;
  maxValue?: number;
  taughtState: TaughtState;
  barBorderColor?: string;
}

const CategoryBarChartRow: React.FC<ChartRowProps> = ({
  count,
  maxValue,
  children,
  barBorderColor,
  className,
  taughtState,
  ...props
}) => {
  const wrap = (internals: ReactNode) => (
    <RowButton className={className} {...props}>
      <LabelWrapper>{children}</LabelWrapper>
      {internals}
    </RowButton>
  );

  if (count === undefined || maxValue === undefined) {
    return wrap(<></>);
  }

  // we want to leave room for the count label
  const sizedBoxPercentage = (count / maxValue) * 90;

  // We don't want the bars to get too small though because then you can't tell the difference between taught_other and sought
  const regulatedSizedBoxPercentage = Math.max(sizedBoxPercentage, 8);

  return wrap(
    <BarWrapper>
      <SizedBox width={regulatedSizedBoxPercentage}>
        <TaughtSoughtBar state={taughtState} />
      </SizedBox>
      <BarLabel>{formatLargeNumber(count)}</BarLabel>
    </BarWrapper>
  );
};

const RowButton = styled.button`
  display: flex;
  align-items: center;
  min-height: 5.6rem;
  width: 100%;
  overflow: hidden;
  text-align: left;
  border: none;
  background: none;
  padding: 0 2.5rem;
  gap: 2rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:hover {
    border-top: 1px solid ${hoverTableBorderBlue};
    border-bottom: 1px solid ${hoverTableBorderBlue};

    cursor: pointer;
    background: ${hoverTableBlue};
    color: ${darkSaphire};
  }
`;

const LabelWrapper = styled.span`
  min-width: 0;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 1rem;
`;

const BarWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 1rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1.4rem;
`;

const BarLabel = styled.span`
  margin-right: 1rem;
  margin-left: 0.8rem;
  width: 10%;
`;

const SizedBox = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}%`};
`;

export default CategoryBarChartRow;
