import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';
import styled from '@emotion/styled';

import DropdownFlyout, {
  DropdownFlyoutProps,
  DropdownSelectable
} from 'components/molecules/DropdownFlyout';
import { ReactComponent as Info } from 'images/skillInfo.svg';

import { bgLightGray, lightGray, mediumLightGray, black } from 'utils/colors';
import Popup from 'components/atoms/Popup';
import { SkillTagsFilterOptions } from 'store/programData';

interface SkillTagsFilterProps {
  onChange: (value: SkillTagsFilterOptions) => void;
  value: SkillTagsFilterOptions;
  disabled?: boolean;
  disabledText?: string;
}

const SkillTagsFilter: React.FC<SkillTagsFilterProps> = ({
  onChange,
  value,
  disabled,
  disabledText
}) => {
  const skillTagsFilterRef = React.useRef<HTMLLabelElement>(null);

  const [isInfoTextOpen, setIsInfoTextOpen] = React.useState<boolean>(false);

  const skillTagsFilterId = useMemo(() => uniqueId('job-postings-totals-by-benchmarks-input-'), []);

  const skillTagsFilterOptions: DropdownSelectable<SkillTagsFilterOptions>[] = [
    { value: undefined, label: 'All Skills' },
    { value: 'isLearningObjective', label: 'Learning Outcome Skills' }
  ];

  return (
    <>
      <StyledHeaderAndBorder
        htmlFor={skillTagsFilterId}
        isDisabledAndShowingWarning={!!disabledText}
        ref={skillTagsFilterRef}
      >
        Show Taught Skills For:
        <StyledInfoButton
          type="button"
          onClick={e => {
            e.stopPropagation();
            setIsInfoTextOpen(!isInfoTextOpen);
          }}
        >
          <Info />
        </StyledInfoButton>
      </StyledHeaderAndBorder>
      <div>
        <StyledDropdownFlyout
          id={skillTagsFilterId}
          dropDownName="skillTagsFilterDropdown"
          items={skillTagsFilterOptions}
          onSelect={selection => onChange(selection.value)}
          selected={skillTagsFilterOptions.find(o => o.value === value)}
          isDisabled={disabled}
        />
      </div>
      {isInfoTextOpen && (
        <StyledPopup
          dimensions={{ height: 'fit-content', width: 'fit-content' }}
          anchorPointRef={skillTagsFilterRef}
          onClosePopup={() => {
            setIsInfoTextOpen(false);
          }}
        >
          <InfoText>
            This option is available for programs where Learning Outcome content has been added to a
            course or program. Selecting “Learning Outcome Skills” filters the taught skills list to
            skills found in Learning Outcome text.
          </InfoText>
        </StyledPopup>
      )}
    </>
  );
};

export default SkillTagsFilter;

const StyledHeaderAndBorder = styled.label<{ isDisabledAndShowingWarning?: boolean }>`
  display: flex;
  margin: 1rem 0 1rem 0;
  border-top: ${props => (props.isDisabledAndShowingWarning ? 'none' : `solid ${lightGray}`)};
  border-width: thin;
  padding-top: ${props => (props.isDisabledAndShowingWarning ? '0' : '1.5rem')};
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => (props.isDisabledAndShowingWarning ? mediumLightGray : black)};
  justify-content: space-between;
`;

const StyledInfoButton = styled.button`
  background: none;
  border: none;
  float: right;
`;

const InfoText = styled.div`
  font-size: 1.4rem;
  text-align: center;
`;

const StyledPopup = styled(Popup)`
  margin-right: 1rem;
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<SkillTagsFilterOptions>) => (
  <DropdownFlyout {...props} />
))`
  width: 100%;
  background: ${props => (props.isDisabled ? lightGray : bgLightGray)};
  color: ${props => (props.isDisabled ? mediumLightGray : black)}; ;
`;
