import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';

import { useProfileState } from 'store/profileStore';
import ProgramTypeClassDropdown from 'components/atoms/ProgramTypeClassDropdown';
import LoadingButton from 'components/atoms/LoadingButton';
import { DropdownSelectable } from 'hooks';
import { useCreateProgramType, useUpdateProgramType } from 'hooks/mutateHooks';

const ModalTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
`;

const StyledInput = styled(Input)`
  margin-bottom: 2.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

const LoadingSubmitButton = styled(LoadingButton)`
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledProgramTypeClassDropdown = styled(ProgramTypeClassDropdown)`
  width: 100%;
`;

const Container = styled.div`
  width: 72rem;
  padding: 0 3rem 3rem;
`;

interface ProgramTypeModalProps {
  defaultValues?: {
    programTypeId: string;
    programTypeLabel?: string;
    programTypeClass: { value: string; label: string };
  };
  closeModal: () => void;
}

const ProgramTypeModal: React.FC<ProgramTypeModalProps> = ({ defaultValues, closeModal }) => {
  const [programTypeId] = useState(defaultValues?.programTypeId || '');
  const [programTypeTitle, setProgramTypeTitle] = useState(defaultValues?.programTypeLabel || '');
  const [programTypeClass, setProgramTypeClass] = useState<DropdownSelectable>({
    value: defaultValues?.programTypeClass.value || '',
    label: defaultValues?.programTypeClass.label || ''
  });

  const { currentSite } = useProfileState();

  const {
    mutate: createProgramType,
    isSuccess: isCreateSuccess,
    isLoading: isCreatingLoading
  } = useCreateProgramType({
    groupTypeClassId: programTypeClass.value,
    label: programTypeTitle,
    site: currentSite
  });
  const {
    mutate: updateProgramType,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdatingLoading
  } = useUpdateProgramType(programTypeId, {
    groupTypeClassId: programTypeClass.value,
    label: programTypeTitle
  });

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      closeModal();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (defaultValues) {
      updateProgramType();
    } else {
      createProgramType();
    }
  };
  return (
    <Container>
      <ModalTitle>{defaultValues ? 'Edit Program Type' : 'Add New Program Type'}</ModalTitle>
      <StyledInput
        placeholder="Enter Program Type name..."
        onChange={e => setProgramTypeTitle(e.target.value)}
        value={programTypeTitle}
      />
      <form
        onSubmit={(e: React.FormEvent) => {
          handleSubmit(e);
        }}
      >
        <StyledProgramTypeClassDropdown
          dropdownPlaceholder="Select a Category"
          onSelect={setProgramTypeClass}
          selected={programTypeClass}
        />
        <ButtonContainer>
          <Button scheme="outline" type="button" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingSubmitButton isLoading={isCreatingLoading || isUpdatingLoading} type="submit">
            {defaultValues ? 'Save Changes' : 'Add New Program Type'}
          </LoadingSubmitButton>
        </ButtonContainer>
      </form>
    </Container>
  );
};

export default ProgramTypeModal;
