import React from 'react';
import styled from '@emotion/styled';
import Card from 'components/atoms/Card';
import CountBadge from 'components/atoms/CountBadge';

import { ReactComponent as Remove } from 'images/x-remove.svg';
import { ReactComponent as ItemPlaceholder } from 'images/skillsByTextEmpty.svg';
import { ReactComponent as SelectAllIcon } from 'images/selectAllCheckboxesButton.svg';
import Pill from 'components/atoms/InfoPill';

import { mediumGray, darkBlue, lightGray, mediumLightGray } from 'utils/colors';

import { useScrollToBottomCallback } from 'hooks';
import Checkbox from 'components/atoms/Checkbox';
import { Item as Course } from 'components/molecules/SelectedItemsCard';

const SelectedCourseCard: React.FC<{
  className?: string;
  pluralLabel: string;
  selectedCourses: readonly Course[];
  setSelectedCourses?: React.Dispatch<React.SetStateAction<readonly Course[]>>;
  removeCourse(course: Course): void;
  removeAll(): void;
  headerText: string;
}> = ({
  className,
  pluralLabel,
  selectedCourses,
  removeCourse,
  headerText,
  setSelectedCourses
}) => {
  const ref = React.useRef<HTMLUListElement>(null);

  const coursesSelected = !!selectedCourses.length;

  const scrollToBottom = useScrollToBottomCallback(ref);

  const handleSelectedRequiredCourses = (course: Course, checkboxVal: boolean) => {
    setSelectedCourses?.(
      selectedCourses.map(selectedCourse => {
        if (selectedCourse.id === course.id) {
          return { ...selectedCourse, isRequired: checkboxVal };
        }
        return selectedCourse;
      })
    );
  };

  const selectDeselectAll = () => {
    const allSelected = selectedCourses.every(course => course.isRequired === true);
    setSelectedCourses?.(
      selectedCourses.map(course => {
        return { ...course, isRequired: !allSelected };
      })
    );
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [selectedCourses.length]);

  return (
    <StyledCard className={className}>
      <CardHeadingWrapper>
        <Flex>
          <CardHeading>{headerText}</CardHeading>
          {coursesSelected && <StyledCount>{selectedCourses.length}</StyledCount>}
        </Flex>
        {coursesSelected && (
          <RequiredContainer>
            <LightText>REQUIRED COURSES</LightText>
            <SelectAllCheckboxesButton
              type="button"
              aria-pressed={false}
              aria-label={'Check all skills'}
              onClick={selectDeselectAll}
            >
              <SelectAllIcon />
            </SelectAllCheckboxesButton>
          </RequiredContainer>
        )}
      </CardHeadingWrapper>
      {coursesSelected ? (
        <SelectedCoursesContainer ref={ref}>
          {[...selectedCourses]
            .sort((a: Course, b: Course) => a.title.localeCompare(b.title))
            .map((course: Course) => (
              <ListCourse
                key={course.id}
                data-cy={`selected-courses-card_list-course_${course.id}`}
              >
                <RemoveCourseButton
                  type="button"
                  aria-label={`remove ${course.title} from list`}
                  onClick={() => removeCourse(course)}
                >
                  <Remove />
                </RemoveCourseButton>
                <CourseInformationContainer>
                  <div>
                    <CourseTitle>{course.title}</CourseTitle>
                    {course.subtitle && <StyledPill scheme={'blue'}>{course.subtitle}</StyledPill>}
                  </div>
                  <StyledCheckBox
                    checked={
                      selectedCourses.find(selectedCourse => selectedCourse.id === course.id)
                        ?.isRequired
                    }
                    onChange={e => handleSelectedRequiredCourses(course, e.target.checked)}
                  />
                </CourseInformationContainer>
              </ListCourse>
            ))}
        </SelectedCoursesContainer>
      ) : (
        <EmptyCoursesContents>
          <ItemPlaceholder />
          <HeavyText>No {pluralLabel.toLowerCase()} added yet</HeavyText>
          <div>Use the list to the left to search and add {pluralLabel.toLowerCase()}.</div>
        </EmptyCoursesContents>
      )}
    </StyledCard>
  );
};

const StyledCheckBox = styled(Checkbox)`
  padding-right: 2rem;
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  display: inline;
`;

const EmptyCoursesContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: auto;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 0.25rem;
  height: 24.8rem;
`;

const StyledCard = styled(Card)`
  padding-top: 4rem;
  padding-bottom: 0;
`;

const CardHeadingWrapper = styled.div`
  justify-content: space-between;
  margin-bottom: 2rem;
  white-space: nowrap;
  @media (min-width: 85.625rem) {
    display: flex;
  }
`;

const SelectedCoursesContainer = styled.ul`
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  list-style-type: none;
  padding: 0 0 0 0.1rem;
`;

const RemoveCourseButton = styled.button`
  background: white;
  display: inline;
  border: none;
  padding: 0.3rem;
  cursor: pointer;
`;

const SelectAllCheckboxesButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: inherit;
  cursor: pointer;
  margin-right: 1rem;
  line-height: 0;

  &:focus {
    outline-offset: 0.2rem;
  }
`;

const StyledCount = styled(CountBadge)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
  align-self: baseline;
`;

const HeavyText = styled.span`
  display: block;
  font-weight: 600;
  margin-top: 2rem;
`;

const LightText = styled.span`
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.27rem;
  line-height: 1rem;
  display: inline;
  text-transform: uppercase;
  padding-right: 1rem;
  color: ${mediumLightGray};
`;

const RequiredContainer = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

const ListCourse = styled.li`
  font-size: 1.4rem;
  display: flex;
  min-height: 5rem;
  padding: 1rem 0;
  align-items: center;
  border-bottom: 0.06rem solid ${lightGray};
  & > span {
    margin-left: 1.2rem;
  }
`;

const StyledPill = styled(Pill)`
  margin-left: 0.8rem;
`;

const Flex = styled.div`
  display: flex;
`;

const CourseTitle = styled.span`
  color: ${darkBlue};
  font-weight: 500;
  font-size: 1.4rem;
  margin-left: 1.5rem;
`;

const CourseInformationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 2rem;
  align-items: center;
`;
export default SelectedCourseCard;
