import React from 'react';
import styled from '@emotion/styled';
import { FieldError } from 'react-hook-form';

import Card from 'components/atoms/Card';

import { ReactComponent as SkillsPlaceholderImage } from 'images/skillsByTextEmpty.svg';
import { mediumGray, ahoy } from 'utils/colors';

const StyledCard = styled(Card)<{ error?: FieldError }>`
  ${({ error }) =>
    error &&
    `
    outline: 1px solid red;
  `}
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
`;

const EmptySkillsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: 6rem auto 0;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
`;

const StyledSpan = styled.span`
  display: block;
`;
const HeavySpan = styled(StyledSpan)`
  font-weight: 600;
  margin-top: 2rem;
`;

const ErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  color: ${ahoy};
`;

const EmptySkillsCard = ({
  cardHeadingText,
  heavyText,
  subText,
  error
}: {
  cardHeadingText: string;
  heavyText: string;
  subText: string;
  error?: FieldError;
}) => {
  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <StyledCard error={error}>
        <CardHeading>{cardHeadingText}</CardHeading>
        <EmptySkillsContents>
          <SkillsPlaceholderImage />
          <HeavySpan>{heavyText}</HeavySpan>
          <StyledSpan>{subText}</StyledSpan>
        </EmptySkillsContents>
      </StyledCard>
    </>
  );
};

export default EmptySkillsCard;
