import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as CheckedIcon } from 'images/checkCircle.svg';
import { ReactComponent as UncheckedIcon } from 'images/x-remove.svg';

const BarChartSubheader: React.FC<{ skillType: 'taught' | 'sought' }> = ({ skillType }) => {
  return (
    <Container>
      {skillType === 'taught' ? (
        <SubContainer>
          <StyledCheckedIcon />
          {'In This Program'}
        </SubContainer>
      ) : (
        <SubContainer>
          <StyledUncheckedIcon />
          {'Not In This Program'}
        </SubContainer>
      )}
      <StyledCheckedIcon />
      {'In-Demand'}
    </Container>
  );
};

export default BarChartSubheader;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SubContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
`;

const StyledCheckedIcon = styled(CheckedIcon)`
  height: 1.3rem;
  width: 1.3rem;
  margin-right: 0.5rem;
`;

const StyledUncheckedIcon = styled(UncheckedIcon)`
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
`;
