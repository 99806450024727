import React from 'react';

import { useOnKeyDownEffect } from 'hooks';

type DropdownControls<T> = {
  getLabelProps: () => React.LabelHTMLAttributes<HTMLLabelElement>;
  getToggleButtonProps: (
    isRequired?: boolean
  ) => React.ButtonHTMLAttributes<HTMLButtonElement> & { error?: boolean };
  getListboxProps: () => Record<string, unknown>;
  getItemProps: (item: T, index: number) => Record<string, unknown>;
  isOpen: boolean;
  selectedItem?: T;
  handleClose: () => void;
};

export interface DropdownSelectable<T = string> {
  label?: string;
  value: T;
}

export const useDropdown = <T>(
  dropDownName: string,
  onSelect?: (item: T) => void,
  selected?: T,
  defaultValue?: T
): DropdownControls<T> => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [internalSelectedItem, setInternalSelectedItem] = React.useState<T | undefined>(
    defaultValue
  );

  const selectedItem = selected || internalSelectedItem;

  const [touched, setTouched] = React.useState(false);
  const refs = React.useRef(new Map()).current;
  const toggleButtonRef = React.useRef<HTMLButtonElement>(null);
  const menuRef = React.useRef<HTMLElement>(null);

  const handleEscape = React.useCallback(
    e => {
      if (isOpen) {
        setIsOpen(false);
        e.stopPropagation();
      }
    },
    [isOpen]
  );

  useOnKeyDownEffect('Escape', handleEscape, menuRef);
  useOnKeyDownEffect('Escape', handleEscape, toggleButtonRef);

  React.useEffect(() => {
    if (selected && internalSelectedItem) {
      setInternalSelectedItem(undefined);
    }
  }, [selected]);

  React.useEffect(() => {
    if (defaultValue && !selected) {
      setInternalSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  const handleClose = () => setIsOpen(false);
  const handleSelect = (item: T) => {
    setInternalSelectedItem(item);
    onSelect && onSelect(item);
  };

  const getLabelProps = (): React.LabelHTMLAttributes<HTMLLabelElement> => ({
    id: `dropdown-label-${dropDownName}`,
    htmlFor: `${dropDownName}-toggle-button`
  });

  const getToggleButtonProps = (
    isRequired?: boolean
  ): React.ButtonHTMLAttributes<HTMLButtonElement> & {
    ref: React.RefObject<HTMLButtonElement>;
    error?: boolean;
  } => {
    const isError = isRequired && touched && !selectedItem && !isOpen;
    return {
      type: 'button',
      id: `${dropDownName}-toggle-button`,
      'aria-haspopup': 'listbox',
      'aria-expanded': isOpen,
      'aria-label': `dropdown-label-${dropDownName}`,
      ref: toggleButtonRef,
      onClick: () => {
        document.getElementById(`${dropDownName}-toggle-button`)?.focus();
        setIsOpen(prev => !prev);
      },
      onKeyDown: e => {
        if (e.key === 'ArrowDown') {
          setIsOpen(true);
        }
      },
      onBlur: () => {
        setTouched(true);
      },
      error: isError
    };
  };

  const getListboxProps = () => ({
    id: `${dropDownName}-menu`,
    role: 'listbox',
    'aria-labelledby': `dropdown-label-${dropDownName}`,
    tabIndex: -1,
    ref: menuRef
  });

  const getItemProps = (item: T, index: number) => ({
    ref: (el: HTMLElement) => {
      refs.set(index, el);
    },
    role: 'option',
    id: `${dropDownName}-option-${index + 1}`,
    onClick: () => {
      handleSelect(item);
      handleClose();
    },
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSelect(item);
        handleClose();
        e.preventDefault();
      }
    },
    tabIndex: 0
  });

  return {
    getLabelProps,
    getToggleButtonProps,
    getListboxProps,
    getItemProps,
    isOpen,
    selectedItem,
    handleClose
  };
};
