import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useCombobox, UseComboboxGetItemPropsOptions } from 'downshift';

import Input from 'components/atoms/Input';
import { Menu } from 'components/atoms/FlyoutMenu';

import { bgLightGray } from 'utils/colors';

import debounce from 'lodash/debounce';

interface SearchWithDropdownProps<TItems>
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  labelText: string;
  search: (query: string) => Promise<TItems[] | void>;
  selectedItems?: TItems[];
  limit?: number;
  onSelectItem?: (item: TItems) => void;
  visibleLabel?: boolean;
  clearOnSelect?: boolean;
  defaultValue?: string;
  onClear?: () => void;
  children: ({
    getItemProps,
    highlightedIndex,
    inputItems
  }: {
    getItemProps: (options: UseComboboxGetItemPropsOptions<TItems>) => Record<string, unknown>;
    highlightedIndex: number;
    inputItems: TItems[];
    selectItem: (item: TItems) => void;
    closeMenu: () => void;
  }) => React.ReactNode;
}

const SearchWithDropdownInput = <T extends { id: string; name: string; type?: string }>({
  className,
  labelText,
  search,
  selectedItems,
  onSelectItem,
  children,
  defaultValue,
  onClear,
  clearOnSelect = true,
  limit = 20,
  visibleLabel = false,
  ...inputProps
}: SearchWithDropdownProps<T>): React.ReactElement => {
  const [inputItems, setInputItems] = React.useState<T[]>([]);

  const latestSearch = React.useRef<string | undefined>();

  const debouncedSearch = React.useCallback(
    debounce(
      async inputValue =>
        search(inputValue).then(items => {
          if (items && latestSearch.current === inputValue) {
            setInputItems(items.filter(x => !selectedItems?.some(({ id }) => x.id === id)));
          }
        }),
      250
    ),
    [search, setInputItems, latestSearch, selectedItems]
  );

  const {
    isOpen,
    closeMenu,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    setInputValue,
    selectItem
  } = useCombobox({
    items: inputItems,

    itemToString: item => (item ? item.name : ''),
    onInputValueChange: ({ inputValue }) => {
      latestSearch.current = inputValue;
      if (!inputValue) {
        setInputItems([]);
        return;
      }
      debouncedSearch(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }
      onSelectItem?.(selectedItem);
      // clearOnSelect && setInputValue('');
    }
  });

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ComboboxWrapper className={className}>
      <ComboboxLabel {...getLabelProps()} visible={visibleLabel}>
        {labelText}
      </ComboboxLabel>
      <div>
        <Input
          {...getInputProps({
            onClick: () => {
              onClear?.();
              setInputValue('');
            },
            ...inputProps
          })}
        />
      </div>
      <DropdownList {...getMenuProps()} show={isOpen}>
        {children({
          getItemProps,
          highlightedIndex,
          inputItems: inputItems
            .filter(item => !selectedItems?.some(({ id }) => item.id === id))
            .slice(0, limit),
          selectItem,
          closeMenu
        })}
      </DropdownList>
    </ComboboxWrapper>
  );
};

const ComboboxWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ComboboxLabel = styled.label<{ visible: boolean }>`
  ${({ visible }) => !visible && `display: block; visibility: hidden; height: 0;`}
`;

const DropdownList = styled(Menu)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inherit' : 'none')};
  margin-top: 0.5rem;
  width: 100%;
  padding: 0 0.5rem;
  max-height: 26rem;
  overflow-y: auto;
`;

export const DropdownMenuItem = styled.li<{ highlighted: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
  ${({ highlighted }) => highlighted && `background: ${bgLightGray};`}

  &:first-of-type {
    margin-top: 0.5rem;
  }

  &:last-of-type {
    margin-bottom: 0.5rem;
  }
`;

export default SearchWithDropdownInput;
