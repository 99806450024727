import makeStore from './makeStore';
interface TableState {
  readonly selectedOrDeselected: readonly string[];
  readonly mode: 'selecting' | 'deselecting';
  page: number;
}

interface SelectRowAction {
  type: 'SELECT_ROW';
  payload: string;
}

interface DeselectRowAction {
  type: 'DESELECT_ROW';
  payload: string;
}

interface SelectPageAction {
  type: 'SELECT_PAGE';
  payload: readonly string[];
}

interface DeselectPageAction {
  type: 'DESELECT_PAGE';
  payload: readonly string[];
}

interface DeselectAllAction {
  type: 'DESELECT_ALL';
}

interface SelectAllAction {
  type: 'SELECT_ALL';
}

interface NextPageAction {
  type: 'NEXT_PAGE';
}

interface PreviousPageAction {
  type: 'PREVIOUS_PAGE';
}

interface PickPageAction {
  type: 'PICK_PAGE';
  payload: number;
}

type TableAction = TableStateAction;

type TableStateAction =
  | SelectRowAction
  | DeselectRowAction
  | SelectAllAction
  | DeselectPageAction
  | DeselectAllAction
  | SelectPageAction
  | NextPageAction
  | PreviousPageAction
  | PickPageAction;

const initialState: TableState = {
  selectedOrDeselected: [],
  mode: 'selecting',
  page: 1
};

export const isPageSelected = (
  mode: TableState['mode'],
  selectedOrDeselected: TableState['selectedOrDeselected'],
  idsOnPage: readonly string[]
): boolean =>
  !idsOnPage.filter(id => {
    const isInArray = selectedOrDeselected.includes(id);
    return mode === 'selecting' ? !isInArray : isInArray;
  }).length;

export const getSelectedOnPage = (
  mode: TableState['mode'],
  selectedOrDeselected: TableState['selectedOrDeselected'],
  idsOnPage: readonly string[]
): string[] =>
  idsOnPage.filter(id => {
    const isInArray = selectedOrDeselected.includes(id);
    return mode === 'selecting' ? isInArray : !isInArray;
  });

const addId = (selectedOrDeselected: TableState['selectedOrDeselected'], id: string) =>
  selectedOrDeselected.includes(id) ? selectedOrDeselected : [...selectedOrDeselected, id];

const addPage = (
  selectedOrDeselected: TableState['selectedOrDeselected'],
  ids: readonly string[]
) => ids.reduce((newIds, id) => addId(newIds, id), selectedOrDeselected);

const tableReducer = (state: TableState, action: TableAction): TableState => {
  switch (action.type) {
    case 'SELECT_ROW':
      if (state.mode === 'selecting') {
        return {
          ...state,
          selectedOrDeselected: addId(state.selectedOrDeselected, action.payload)
        };
      } else {
        return {
          ...state,
          selectedOrDeselected: state.selectedOrDeselected.filter(row => row !== action.payload)
        };
      }
    case 'DESELECT_ROW':
      if (state.mode === 'selecting') {
        return {
          ...state,
          selectedOrDeselected: state.selectedOrDeselected.filter(row => row !== action.payload)
        };
      }
      return {
        ...state,
        selectedOrDeselected: addId(state.selectedOrDeselected, action.payload)
      };
    case 'SELECT_PAGE':
      if (state.mode === 'selecting') {
        return {
          ...state,
          selectedOrDeselected: addPage(state.selectedOrDeselected, action.payload)
        };
      } else {
        return {
          ...state,
          selectedOrDeselected: state.selectedOrDeselected.filter(
            id => !action.payload.includes(id)
          )
        };
      }
    case 'DESELECT_PAGE':
      if (state.mode === 'selecting') {
        return {
          ...state,
          selectedOrDeselected: state.selectedOrDeselected.filter(
            id => !action.payload.includes(id)
          )
        };
      } else {
        return {
          ...state,
          selectedOrDeselected: addPage(state.selectedOrDeselected, action.payload)
        };
      }
    case 'DESELECT_ALL':
      return initialState;
    case 'SELECT_ALL':
      return {
        ...state,
        mode: 'deselecting',
        selectedOrDeselected: []
      };
    case 'NEXT_PAGE':
      return { ...state, page: state.page + 1 };
    case 'PREVIOUS_PAGE':
      return { ...state, page: state.page - 1 };
    case 'PICK_PAGE':
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

const [TableProvider, useTableDispatch, useTableState] = makeStore<TableState, TableAction>(
  tableReducer,
  initialState
);

export { TableProvider, useTableDispatch, useTableState };
