import { fetchWithCognitoToken } from './cognito';

const domain = process.env.REACT_APP_PROXY_URL;

export interface SalaryBoostingSkillsResponse {
  id: string;
  name: string;
  skills: { name: string; id: string }[];
}

// Reference to the projected salary boosting skills endpoints
// https://api.lightcast.io/apis/salary-boosting-skills
export const fetchSalaryBoostingSkills = async (
  occupationLevel: GranularLotLevel,
  occupationIds: string[]
): Promise<SalaryBoostingSkillsResponse[]> => {
  const happyName = occupationLevel === 'occupation' ? 'lotocc' : 'lotspecocc';

  return await fetchWithCognitoToken(
    `${domain}/emsi-services/salary-boosting-skills/dimensions/${happyName}/bulk`,
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ ids: occupationIds })
    }
  ).then(async res => {
    const json = await res.json();
    if (!res.ok) {
      throw new Error(
        `Failed to get salary boosting skills: ${json.message || json.errors?.[0]?.detail}`
      );
    }

    return json.data;
  });
};
