import React from 'react';
import styled from '@emotion/styled';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Loading from 'components/atoms/Loading';
import Pill from 'components/atoms/InfoPill';

import { useJPAFacetLookup } from 'hooks/jpaHooks';
import useCurrentNation from 'hooks/useCurrentNation';
import { getFacetType } from 'utils/curricularSkills';
import { lightGray, darkBlue } from 'utils/colors';
import { useJPATotals } from 'hooks/jpaHooks';
import formatLargeNumber from 'utils/formatLargeNumber';

const JpaBenchmarkPopup: React.FC<{ benchmark: Benchmark; isCarouselPopup?: boolean }> = ({
  benchmark,
  isCarouselPopup = false
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [nation] = useCurrentNation();
  const benchmarkNation = benchmark.type === 'customJPABenchmark' ? benchmark.nation : nation;
  const allResponses = useJPAFacetLookup(benchmark.facets, benchmarkNation);

  const { data: totals } = useJPATotals(benchmark.facets, ['median_salary'], {
    nation: benchmarkNation,
    queryOptions: {
      enabled: benchmark.type === 'customJPABenchmark'
    }
  });

  useDeepCompareEffect(() => {
    if (!allResponses.some(response => response.isLoading)) {
      setIsLoaded(true);
    }
  }, [allResponses]);

  const facets = allResponses.map(data => {
    return data.data;
  });

  const median_salary = totals && totals.median_salary;

  return (
    <PopupBody>
      <BenchmarkTitle>{benchmark.title}</BenchmarkTitle>
      <PillBox>
        <StyledPill scheme="yellow">
          {benchmark.type === 'skillsFromTextBenchmark' ? 'Text' : 'Custom'}
        </StyledPill>
        {totals && (
          <StyledPillWithMargin aria-label="Median Salary" scheme="green">
            {median_salary ? `$${formatLargeNumber(median_salary)}` : 'N/A'}
          </StyledPillWithMargin>
        )}
      </PillBox>
      <Flex isCarouselPopup={isCarouselPopup}>
        {isLoaded ? (
          facets.map(facet =>
            facet?.data.length ? (
              <div key={facet.type}>
                <SectionTitles>{getFacetType(facet.type, benchmarkNation)}</SectionTitles>
                <ChipContainer>
                  {facet.data.map(unit => {
                    return (
                      <InformationChip key={`${facet.type}-${unit.id}`}>
                        <StyledSpan>{unit.name}</StyledSpan>
                        {facet.type !== 'title' &&
                        facet.type !== 'company' &&
                        facet.type !== 'skills' ? (
                          <SpanWithMargin>{`(${unit.id})`}</SpanWithMargin>
                        ) : null}
                      </InformationChip>
                    );
                  })}
                </ChipContainer>
              </div>
            ) : null
          )
        ) : (
          <StyledLoading />
        )}
      </Flex>
    </PopupBody>
  );
};

export default JpaBenchmarkPopup;

const PopupBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
  padding-right: 1rem;
`;

const StyledSpan = styled.div`
  display: inline-block;
  max-width: 25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const InformationChip = styled.div`
  background-color: ${lightGray};
  border-radius: 2px;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0.5rem;
  margin-left: 0;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
`;

const Flex = styled.div<{ isCarouselPopup?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ isCarouselPopup }) =>
    isCarouselPopup &&
    `
  max-height: 38rem;
  `}
  min-height: 30rem;
  flex-grow: 1;
`;

const PillBox = styled.div`
  margin-bottom: 2rem;
`;
const StyledPill = styled(Pill)`
  width: fit-content;
`;

const StyledPillWithMargin = styled(Pill)`
  width: fit-content;
  margin-left: 0.5rem;
`;

const StyledLoading = styled(Loading)`
  margin-top: auto;
  margin-bottom: auto;
`;

const SectionTitles = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${darkBlue};
`;

const BenchmarkTitle = styled.span`
  font-size: 1.4rem;
  color: ${darkBlue};
  font-weight: 500;
  display: block;
  margin: 1rem 0;
`;

const SpanWithMargin = styled.span`
  margin-left: 0.2rem;
`;
