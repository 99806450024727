import React, { useState } from 'react';
import styled from '@emotion/styled';
import { NavLink, Switch, useParams, useLocation, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import MonitoredRoute from 'components/atoms/MonitoredRoute';
import Loading from 'components/atoms/Loading';
import PublishedStatusBadge from 'components/atoms/PublishedStatusBadge';
import Header from 'components/molecules/Header';
import { ProgramExports } from 'components/molecules/ProgramExports';
import ProgramSummary from 'components/organisms/ProgramSummary';
import ProgramInsights from 'components/organisms/ProgramInsights';
import ProgramInfoFetch from 'components/organisms/ProgramInfoFetch';
import ProgramBenchmarks from 'components/organisms/ProgramBenchmarks';

import { ReactComponent as ProgramIcon } from 'images/programIcon.svg';
import { ReactComponent as TextAlignIcon } from 'images/textAlignIcon.svg';
import { ReactComponent as BarsIcon } from 'images/barsIcon.svg';

import { white, navyBlue, darkBlue, lightGray } from 'utils/colors';
import { useJPAFilterQuery } from 'hooks/jpaHooks';
import { SkillDetailFlowControlProvider } from 'hooks/skillDetailTableDataFlowControlProvider';
import { ProgramDataProvider } from 'store/programData';

const Program: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { limitedContent } = useFlags();

  const [currentTimeframe, setCurrentTimeframe] = useState<number>(12);

  const {
    setRegionFilter,
    regionFilter,
    setJPAFilter,
    query: jpaQuery,
    jpaFilter
  } = useJPAFilterQuery();

  const sliderClass = location.pathname.includes('insights') ? 'alignment' : '';
  const isOnBenchmarksPage = location.pathname.includes('benchmarks');

  return (
    <ProgramDataProvider>
      <ProgramInfoFetch programId={id}>
        {({
          program,
          skillCounts,
          skillsInProgram,
          categorizedSkills,
          requiredCourseSkillArray,
          isLoading
        }) => {
          return isLoading || !program || !regionFilter ? (
            <PaddedLoader />
          ) : (
            <>
              <Header headerText={program.data.attributes.title} Icon={ProgramIcon}>
                {!isOnBenchmarksPage && (
                  <>
                    <StyledPublishedStatusBadge isPublished={program.data.attributes.isPublished} />
                    <ProgramExports
                      jpaQuery={jpaQuery}
                      program={program}
                      regionFilter={regionFilter}
                      currentTimeframe={currentTimeframe}
                    />
                  </>
                )}
              </Header>
              {!limitedContent && !isOnBenchmarksPage && (
                <div>
                  <NavLinkWrapper>
                    <Slider className={sliderClass} />
                    <StyledNavLink to={`/program/summary/${id}`} data-cy="program_summary-nav-link">
                      <TextAlignIcon />
                      Program Contents
                    </StyledNavLink>
                    <StyledNavLink
                      to={`/program/insights/${id}`}
                      data-cy="program_market-alignment-link"
                    >
                      <BarsIcon />
                      Market Alignment
                    </StyledNavLink>
                  </NavLinkWrapper>
                </div>
              )}
              <Switch>
                <MonitoredRoute
                  exact
                  path={`/program/summary/:id`}
                  render={props => (
                    <ProgramSummary
                      program={program}
                      skillsInProgram={skillsInProgram}
                      skillCounts={skillCounts}
                      categorizedSkills={categorizedSkills}
                      {...props}
                    />
                  )}
                />
                <MonitoredRoute
                  exact
                  path={`/program/insights/:id`}
                  render={props =>
                    limitedContent ? (
                      <Redirect to={`/program/summary/${id}`} />
                    ) : (
                      <ProgramInsights
                        program={program}
                        setRegionFilter={setRegionFilter}
                        regionFilter={regionFilter}
                        setJPAFilter={setJPAFilter}
                        postingsQuery={jpaQuery}
                        jpaFilter={jpaFilter}
                        currentTimeframe={currentTimeframe}
                        setCurrentTimeframe={setCurrentTimeframe}
                        {...props}
                      />
                    )
                  }
                />
                <MonitoredRoute
                  exact
                  path={`/program/insights/:id/benchmarks`}
                  render={() => (
                    <ProgramBenchmarks
                      program={program}
                      requiredSkillsInProgram={requiredCourseSkillArray}
                      skillsInProgram={Object.keys(skillsInProgram)}
                    />
                  )}
                />
              </Switch>
            </>
          );
        }}
      </ProgramInfoFetch>
    </ProgramDataProvider>
  );
};

const ProgramWithProvider: React.FC = () => (
  <SkillDetailFlowControlProvider>
    <Program />
  </SkillDetailFlowControlProvider>
);

export default ProgramWithProvider;

const PaddedLoader = styled(Loading)`
  margin-top: 25vh;
`;

const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  padding: 1.6rem;
  cursor: pointer;

  svg {
    margin-right: 0.8rem;
    stroke: ${darkBlue};
  }

  &.active {
    color: ${navyBlue};

    svg {
      stroke: ${navyBlue};
    }
  }
`;

const StyledPublishedStatusBadge = styled(PublishedStatusBadge)`
  margin-right: 2.4rem;
`;

const Slider = styled.div`
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  z-index: 0;
  background: ${white};
  border-radius: 4px;
  height: 5.6rem;
  width: 19.5rem;
  transition: all 0.35s;

  &.alignment {
    width: 19rem;
    left: 20.2rem;
  }
`;

const NavLinkWrapper = styled.div`
  position: relative;
  display: inline-block;
  background: ${lightGray};
  padding: 0.8rem;
  border-radius: 0.4rem;
`;
