import { SheetOptions } from 'excellentexport';
import exportExcel from 'helpers/exports/excelExports';
import { useEffect, useState } from 'react';
import { getToastFnThatWeTreatLikeHook } from '.';

type ExportHook = [
  () => void,
  {
    exportIsLoading: boolean;
    error?: Error;
  }
];

export interface UseExportParams {
  isPreloading: boolean;
  filename: string;
  exportDataFn(): Promise<SheetOptions[] | void>;
  onFinished?(): void;
}

function useExport(params: UseExportParams): ExportHook {
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!isExporting || params.isPreloading) {
      return;
    }

    let isStale = false;

    params
      .exportDataFn()
      .then(data => {
        if (isStale) {
          return;
        }

        if (data && data.length) {
          exportExcel({ filename: params.filename }, data);
        }

        setIsExporting(false);
        params.onFinished?.();
      })
      .catch(err => {
        if (isStale) {
          return;
        }

        setError(err);
        setIsExporting(false);
        getToastFnThatWeTreatLikeHook()('Could not export. Please try again.', 'error');
        params.onFinished?.();

        throw err;
      });

    return () => {
      isStale = true;
    };
  }, [isExporting, params.exportDataFn, params.filename, params.isPreloading]);

  return [
    () => setIsExporting(true),
    {
      exportIsLoading: isExporting,
      error
    }
  ];
}

export default useExport;
