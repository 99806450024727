import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import uniqueId from 'lodash/uniqueId';

import RectangleFlyoutButton from 'components/atoms/RectangleChartSettingsFlyoutButton';
import Checkbox from 'components/atoms/Checkbox';
import Toggle from 'components/atoms/Toggle';
import SkillTagsFilter from 'components/molecules/SkillTagsFilter';
import { black, lightGray, mediumLightGray } from 'utils/colors';
import JobPostingByBenchmarkFilter from '../JobPostingByBenchmarkFilter';
import { ChartSettings } from 'store/programData';

const SKILL_LEVEL_DISPLAY_NAMES: Record<SkillClassification, string> = {
  'Specialized Skill': 'Specialized Skills',
  'Common Skill': 'Common Skills',
  'Software Skill': 'Software Skills',
  Certification: 'Certifications'
};

interface ChartSettingsDropdownButtonProps {
  onChange: (value: ChartSettings) => void;
  chartSettings: ChartSettings;
  isJobPostingByBenchmarkDisabled?: boolean;
  isSkillTagsFilterDisabled?: boolean;
}

const ChartSettingsDropdownButton: React.FC<ChartSettingsDropdownButtonProps> = ({
  onChange,
  chartSettings,
  isJobPostingByBenchmarkDisabled,
  isSkillTagsFilterDisabled
}) => {
  const skillsByCategoryInputId = useMemo(() => uniqueId('skills-by-category-input-'), []);

  const selectSkillLevel = (selectedLevel: SkillClassification) =>
    onChange({
      ...chartSettings,
      selectedSkillLevels: chartSettings.selectedSkillLevels.length
        ? [...chartSettings.selectedSkillLevels, selectedLevel]
        : [selectedLevel]
    });

  const deselectSkillLevel = (deselectedLevel: SkillClassification) =>
    onChange({
      ...chartSettings,
      selectedSkillLevels: chartSettings.selectedSkillLevels.filter(
        type => type !== deselectedLevel
      )
    });

  return (
    <RectangleFlyoutButton
      buttonText="Chart Settings"
      data-cy="chart-settings-dropdown-button_button"
    >
      <FlyoutPanel>
        <h1>Taught vs Sought Settings</h1>
        <InnerContainer>
          <h2>Select Skill Levels:</h2>
          <GridChecklist>
            {(Object.keys(SKILL_LEVEL_DISPLAY_NAMES) as SkillClassification[]).map(skillLevel => (
              <li key={skillLevel}>
                <Checkbox
                  labelText={SKILL_LEVEL_DISPLAY_NAMES[skillLevel]}
                  checked={chartSettings.selectedSkillLevels.includes(skillLevel)}
                  onChange={event =>
                    event.target.checked
                      ? selectSkillLevel(skillLevel)
                      : deselectSkillLevel(skillLevel)
                  }
                />
              </li>
            ))}
          </GridChecklist>
          <>
            <StyledHeaderAndBorder htmlFor={skillsByCategoryInputId}>
              Display Skills by Category:
            </StyledHeaderAndBorder>
            <StyledToggleText>
              Off
              <StyledToggle
                data-cy="chart-settings-dropdown-button_skill-category-toggle"
                id={skillsByCategoryInputId}
                value={chartSettings.isDisplayingCategories}
                onValueChange={() =>
                  onChange({
                    ...chartSettings,
                    isDisplayingCategories: !chartSettings.isDisplayingCategories
                  })
                }
                aria-label="DisplaySkillbyCategory"
              />
              On
            </StyledToggleText>
            {typeof chartSettings.filterPostingsByBenchmarks !== 'undefined' && (
              <JobPostingByBenchmarkFilter
                isJobPostingByBenchmarkDisabled={isJobPostingByBenchmarkDisabled}
                onChange={onChange}
                settings={chartSettings}
              />
            )}
            <SkillTagsFilter
              onChange={value => onChange({ ...chartSettings, skillTagFilter: value })}
              value={chartSettings.skillTagFilter}
              disabled={isSkillTagsFilterDisabled}
              disabledText={'No Tagged Skills Available'}
            />
          </>
        </InnerContainer>
      </FlyoutPanel>
    </RectangleFlyoutButton>
  );
};

const InnerContainer = styled.div`
  background-color: white;
  padding: 2rem 1.2rem;
`;

const FlyoutPanel = styled.section`
  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 400;
    padding-bottom: 0.7rem;
  }

  h2 {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
  }
`;

const GridChecklist = styled.ul`
  list-style-type: none;
  display: grid;
  padding: 0;
  font-size: 1.4rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;
  margin-top: 1rem;

  li {
    padding: 0;
    margin: 0.4rem 0 0.8rem 1rem;
    min-width: 15rem;
  }
`;

export const StyledHeaderAndBorder = styled.label<{ isDisabledAndShowingWarning?: boolean }>`
  display: flex;
  margin: 1rem 0 1rem 0;
  border-top: ${props => (props.isDisabledAndShowingWarning ? 'none' : `solid ${lightGray}`)};
  border-width: thin;
  padding-top: ${props => (props.isDisabledAndShowingWarning ? '0' : '1.5rem')};
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => (props.isDisabledAndShowingWarning ? mediumLightGray : black)};
  justify-content: space-between;
`;

const StyledToggleText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const StyledToggle = styled(Toggle)`
  margin: 0 1rem 0 1rem;
`;

export default ChartSettingsDropdownButton;
