/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  white,
  mediumGray,
  darkSaphire,
  bgTableBlue,
  hoverTableBlue,
  hoverTableBorderBlue
} from 'utils/colors';

const DashboardTableStyles = css`
  width: 100%;
  background: ${white};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-top: none;
  border-bottom: none;
  font-size: 1.4rem;
  border-spacing: 0;

  thead tr > th {
    padding-top: 3rem;
    padding-bottom: 0.6rem;
  }

  tr {
    > *:first-of-type {
      padding-left: 3rem;
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
      &:nth-of-type(odd):not(.empty-table) {
        background: ${bgTableBlue};
      }

      td > button {
        font-size: 1.4rem;
        text-align: left;
        padding: 0.3rem;
      }

      &:hover:not(.empty-table) {
        td {
          border-top: 1px solid ${hoverTableBorderBlue};
          border-bottom: 1px solid ${hoverTableBorderBlue};
        }
        cursor: pointer;
        background: ${hoverTableBlue};

        td > button:first-of-type {
          color: ${darkSaphire};
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  th {
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.27em;
    font-size: 1rem;
    color: ${mediumGray};
  }

  /* If this is annoying in the future, move this to the render functions in the appropriate table components */
  .edit-column {
    text-align: right;
  }
`;

export default DashboardTableStyles;
