import { useQueryClient } from 'react-query';

import { useProfileState } from 'store/profileStore';
import { useProgramData } from 'store/programData';
import { ProgramExcelData } from 'helpers/exports/exportProgram/types';

import { useFilteredTaughtVsSoughtSkills, useSkillDetails } from './programInsights';
import { useSkillDetailFlowControls } from './skillDetailTableDataFlowControlProvider';
import useCurrentNation from './useCurrentNation';
import { useProgramBenchmarks } from './benchmarks';
import { useSkillDetailTableDataFlowControl } from './skillDetailTableDataFlowControl';

export interface UseProgramExcelDataParams {
  programId: string;
  jpaQuery: JPAOptionsFilter;
  currentTimeframe: number;
  regionFilter: RegionFilter;
}

export function useProgramExcelData(params: UseProgramExcelDataParams) {
  const { chartSettings } = useProgramData();
  const queryClient = useQueryClient();
  const { currentSite } = useProfileState();
  const [currentNation] = useCurrentNation();

  const { activeFilters: activeSkillDetailFilters } = useSkillDetailFlowControls();

  const {
    data: skillDetails,
    isLoading: skillDetailsIsLoading,
    error: skillDetailsError
  } = useSkillDetails({
    chartSettings,
    currentTimeframe: params.currentTimeframe,
    jpaFilters: params.jpaQuery,
    programId: params.programId
  });

  const { data: controlledSkillDetails } = useSkillDetailTableDataFlowControl(skillDetails);

  const {
    data: benchmarks,
    isLoading: benchmarksIsLoading,
    error: programBenchmarksError
  } = useProgramBenchmarks(params.programId);

  const {
    data: taughtAndSoughtSkills,
    isLoading: taughtAndSoughtSkillsIsLoading,
    error: taughtAndSoughtSkillsError
  } = useFilteredTaughtVsSoughtSkills(params.programId, params.jpaQuery, chartSettings);

  const isLoading = skillDetailsIsLoading || benchmarksIsLoading || taughtAndSoughtSkillsIsLoading;
  const error = skillDetailsError || programBenchmarksError || taughtAndSoughtSkillsError;

  const data: ProgramExcelData = {
    benchmarks,
    programId: params.programId,
    queryClient,
    site: currentSite,
    skillDetails: controlledSkillDetails.uncategorizedSkills,
    activeSkillDetailFilters,
    marketAlignmentFilters: {
      nation: currentNation,
      regionFilters: params.regionFilter,
      jpaFilters: params.jpaQuery,
      chartSettings
    },
    timeframe: params.currentTimeframe,
    taughtAndSoughtSkills: taughtAndSoughtSkills.skills
  };

  return { data, isLoading, error };
}
