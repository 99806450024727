import React from 'react';
import styled from '@emotion/styled';
import { FieldError } from 'react-hook-form';

import InputLabel from 'components/atoms/InputLabel';
import { bgLightGray, borderGray, ahoy } from 'utils/colors';

type InputProps = {
  error?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

const StyledInput = styled.input<InputProps>`
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  padding: 1rem 1.6rem;
  border: ${props => (props.error ? `1px solid ${ahoy}` : `1px solid ${borderGray}`)};
  border-radius: 0.4rem;
  background: ${bgLightGray};
  width: 100%;
  :disabled,
  :read-only {
    opacity: 0.6;
    cursor: not-allowed;
  }
  ::placeholder {
    font-style: italic;
  }
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: ${ahoy};
  position: absolute;
  bottom: 0.5rem;
`;

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  error?: FieldError;
  dataCy?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ name, labelText, error, className, dataCy, ...props }, ref) => {
    return (
      <Wrapper className={className}>
        <InputLabel htmlFor={name}>{labelText}</InputLabel>
        <StyledInput id={name} name={name} ref={ref} data-cy={dataCy} error={!!error} {...props} />
        {error && <Error>{error.message}</Error>}
      </Wrapper>
    );
  }
);

export default FormInput;
