import React from 'react';
import { useQueryClient } from 'react-query';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import Header from 'components/molecules/Header';
import CoursesDashboardTable, {
  buildSearchPayload,
  LOCATION_STATE_KEY
} from 'components/organisms/CoursesDashboardTable';
import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import ExportDropdownFlyout from 'components/molecules/ExportDropdownFlyout';
import { ReactComponent as CourseIcon } from 'images/courseIcon.svg';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { ReactComponent as Download } from 'images/cloudDownload.svg';

import { useCourses, useExport } from 'hooks';
import { exportAllCourses } from 'helpers/exports/exportAllCourses';
import { hasSiteAccessLevel, useProfileState } from 'store/profileStore';
import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import { TableProvider, useTableState } from 'store/tableStore';
import { darkSaphire, white } from 'utils/colors';

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
`;

const StyledDownload = styled(Download)<{ color: string }>`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  stroke: ${({ color }) => color};
`;

const StyledLoadingButton = styled(LoadingButton)`
  min-width: 10rem;
`;

const NewCourseButton = styled(Button)`
  margin-left: 1rem;
`;

const Courses: React.FC = () => {
  const { data } = useCourses({ limit: 0 });
  const queryClient = useQueryClient();
  const profileState = useProfileState();
  const { mode, selectedOrDeselected } = useTableState();

  const courseIdFilter = {
    ids:
      mode === 'selecting' && selectedOrDeselected.length !== 0
        ? { in: selectedOrDeselected }
        : { exclude: selectedOrDeselected }
  };

  const location = useLocation<{
    [x: string]: CurricularRouteSearchState<CourseSortOption> | undefined;
  }>();
  const state = location?.state?.[LOCATION_STATE_KEY] || defaultCurricularRouteSearchState();

  const { filter } = buildSearchPayload(state);

  const coursesFilter = mode === 'selecting' ? courseIdFilter : { ...filter, ...courseIdFilter };

  const [exportAllData, { exportIsLoading: exportAllIsLoading }] = useExport({
    filename: `${profileState.currentSite}_courses_export`,
    exportDataFn: exportAllCourses(profileState.currentSite, queryClient),
    isPreloading: false
  });

  const [exportSelectedData, { exportIsLoading: exportSelectedIsLoading }] = useExport({
    filename: `${profileState.currentSite}_courses_export`,
    exportDataFn: exportAllCourses(profileState.currentSite, queryClient, coursesFilter),
    isPreloading: false
  });

  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  return (
    <>
      <Header
        data-cy="courses_header"
        headerText="Courses"
        count={data && data.meta.totalAvailable}
        Icon={CourseIcon}
      >
        {selectedOrDeselected.length > 0 || mode === 'deselecting' ? (
          <ExportDropdownFlyout
            className={'export-courses-dropdown-select'}
            isExportAllLoading={exportAllIsLoading}
            isExportSelectedLoading={exportSelectedIsLoading}
            exportAllFunc={() => exportAllData()}
            exportSelectedFunc={() => exportSelectedData()}
            buttonAriaLabel="Export Courses"
          />
        ) : (
          <StyledLoadingButton
            isLoading={exportAllIsLoading}
            loaderColor="gray"
            type="button"
            scheme={hasEditorPrivileges ? 'outline' : 'default'}
            onClick={() => exportAllData()}
          >
            <StyledDownload
              title="cloud download icon"
              color={hasEditorPrivileges ? darkSaphire : white}
            />
            Export
          </StyledLoadingButton>
        )}
        {hasEditorPrivileges && (
          <NewCourseButton as={Link} to="/add/course" type="button">
            <StyledPlus title="create new plus icon" />
            New Course
          </NewCourseButton>
        )}
      </Header>
      <CoursesDashboardTable hasEditorPrivileges={hasEditorPrivileges} trackPages />
    </>
  );
};
const CoursesWrapper: React.FC = () => {
  return (
    <TableProvider>
      <Courses />
    </TableProvider>
  );
};

export default CoursesWrapper;
