import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import EmptyDashboardTable from 'components/atoms/EmptyDashboardTable';
import LoadingDashboardTable from 'components/atoms/LoadingDashboardTable';
import DashboardTableStyles from 'components/atoms/DashboardTableStyles';
import Table, { HeaderCell, Cell, Column } from 'components/molecules/Table';
import Pagination from 'components/molecules/Pagination';
import Modal from 'components/atoms/Modal';
import Input from 'components/atoms/Input';
import EditSiteForm, { EditSiteFormDefaultValues } from 'components/organisms/EditSiteForm';
import { paginate } from 'components/pages/UsersAdminTable';

import { ReactComponent as Clock } from 'images/clock.svg';
import { ReactComponent as SortArrow } from 'images/sortArrow.svg';
import { ReactComponent as SearchIcon } from 'images/magnifying-glass.svg';
import { darkBlue, darkGray, lightGray, white } from 'utils/colors';
import { TableProvider, useTableState, useTableDispatch } from 'store/tableStore';
import { DEFAULT_DASHBOARD_TABLE_PAGE_SIZE } from 'hooks';
import { useProfileState } from 'store/profileStore';
import { ReactComponent as Check } from 'images/greenCheck.svg';
import { ReactComponent as XRemove } from 'images/closebtn.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultSiteRouteSearchState } from 'utils/defaultSiteRouteSearchState';

const columns: Column<PreppedSite>[] = [
  {
    id: 'site',
    renderHeader: ({ onSort, sortedColumn }) => (
      <StyledHeaderCell width="30%" key="site-header">
        <SortableHeader onClick={() => onSort?.('site')}>
          Site
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'site'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </StyledHeaderCell>
    ),
    renderCell: row => (
      <Cell key={row.site}>
        <SiteInfo>
          <Name>{row.name} </Name>
          <SiteName>{row.site}</SiteName>
        </SiteInfo>
      </Cell>
    )
  },
  {
    id: 'nations',
    renderHeader: () => (
      <HeaderCell width="10%" key="nation-header">
        Nation
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`nation-${row.site}`}>
        <div>{row.nations}</div>
      </Cell>
    )
  },
  {
    id: 'active',
    renderHeader: ({ onSort, sortedColumn }) => (
      <HeaderCell width="8%" key="active-header">
        <SortableHeader onClick={() => onSort?.('active')}>
          Active
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'active'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`nation-${row.active}`}>
        {row.active ? (
          <StyledDiv>
            <Check />
            Yes
          </StyledDiv>
        ) : (
          <StyledDiv>
            <XRemove />
            No
          </StyledDiv>
        )}
      </Cell>
    )
  },
  {
    id: 'defaultRegion',
    renderHeader: () => (
      <HeaderCell width="20%" key="default-region-header">
        Default Region
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`default-region-${row.site}`}>
        <div>{row.defaultRegion}</div>
      </Cell>
    )
  },
  {
    id: 'userCount',
    renderHeader: ({ onSort, sortedColumn }) => (
      <StyledHeaderCell width="10%" key="user-count-header">
        <SortableHeader onClick={() => onSort?.('userCount')}>
          Users
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'userCount'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </StyledHeaderCell>
    ),
    renderCell: row => (
      <Cell key={`user-count-${row.site}`}>
        <div>{row.userCount}</div>
      </Cell>
    )
  },
  {
    id: 'internalUserCount',
    renderHeader: ({ onSort, sortedColumn }) => (
      <StyledHeaderCell width="13%" key="internal-user-count-header">
        <SortableHeader onClick={() => onSort?.('internalUserCount')}>
          Lightcast Users
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'internalUserCount'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </StyledHeaderCell>
    ),
    renderCell: row => (
      <Cell key={`internalUser-count-${row.site}`}>
        <div>{row.internalUserCount}</div>
      </Cell>
    )
  },
  {
    id: 'createdAt',
    renderHeader: ({ onSort, sortedColumn }) => (
      <StyledHeaderCell key="created-header">
        <SortableHeader onClick={() => onSort?.('createdAt')}>
          Created
          {sortedColumn && (
            <StyledSortArrow
              isHidden={sortedColumn.sortType !== 'createdAt'}
              sortOrder={sortedColumn.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          )}
        </SortableHeader>
      </StyledHeaderCell>
    ),
    renderCell: row => (
      <Cell key={`created-at-${row.site}`}>
        <Flex>
          {row.createdAt} <StyledClock />
        </Flex>
      </Cell>
    )
  }
];

interface SitesAdminTableProps {
  sites?: PreppedSite[];
}

const LOCATION_STATE_KEY = 'siteAdminTableFilters';

const SitesAdminTable: React.FC<SitesAdminTableProps> = ({ sites }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [siteData, setSiteData] = React.useState<EditSiteFormDefaultValues>();
  const { isInternalOnly } = useProfileState();
  const closeModal = () => setIsModalOpen(false);

  function filterSite(site: PreppedSite) {
    return (
      site.site.toLowerCase().includes(inputValue.toLowerCase()) ||
      site.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  }
  const filteredSites = sites?.filter(filterSite) || [];

  const { page: currentPage } = useTableState();
  const tableDispatch = useTableDispatch();

  const history = useHistory();
  const location = useLocation<{
    [x: string]: SiteRouteSearchState<SiteSortOption> | undefined;
  }>();
  const state = location?.state?.[LOCATION_STATE_KEY] || defaultSiteRouteSearchState();

  const pageCount = sites ? Math.ceil(filteredSites.length / DEFAULT_DASHBOARD_TABLE_PAGE_SIZE) : 0;

  const filteredColumns = isInternalOnly
    ? columns
    : columns.filter(column => column.id !== 'internalUserCount');

  const sortTable = React.useCallback(
    (column: SiteSortOption) => {
      const { sortOrder, sortType } = state.sort;
      let newSortOrder: 'ascending' | 'descending' = 'ascending';
      if (column === sortType) {
        newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
      }
      history.push(location.pathname, {
        [LOCATION_STATE_KEY]: {
          sort: { sortOrder: newSortOrder, sortType: column }
        }
      });
    },
    [state]
  );

  function compareSites(a: PreppedSite, b: PreppedSite) {
    switch (state.sort.sortType) {
      case 'active': {
        let comparison = 0;
        const activeA = a.active;
        const activeB = b.active;
        if (activeA === activeB) {
          comparison = 0;
        } else if (activeA) {
          comparison = -1;
        } else {
          comparison = 1;
        }
        return comparison;
      }
      case 'internalUserCount': {
        let comparison = 0;
        const countA = Number(a.internalUserCount);
        const countB = Number(b.internalUserCount);
        if (countA > countB) {
          comparison = 1;
        } else if (countB > countA) {
          comparison = -1;
        }
        return comparison;
      }
      case 'site': {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      }
      case 'userCount': {
        let comparison = 0;
        const countA = Number(a.userCount);
        const countB = Number(b.userCount);
        if (countA > countB) {
          comparison = 1;
        } else if (countB > countA) {
          comparison = -1;
        }
        return comparison;
      }
      case 'createdAt': {
        let comparison = 0;
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
        return comparison;
      }
    }
  }

  const sortedSites: PreppedSite[] = React.useMemo(
    () =>
      state.sort.sortOrder === 'ascending'
        ? filteredSites.sort(compareSites)
        : filteredSites.sort(compareSites).reverse(),
    [state, filteredSites]
  );

  return (
    <TableWrapper>
      <SearchInputContainer>
        <InputWrapper>
          <StyledSearchIcon />
          <StyledInput
            id="site-search"
            type="text"
            aria-label={`Search for a site`}
            placeholder={`Search for a site`}
            onChange={({ target: { value } }) => {
              tableDispatch({ type: 'PICK_PAGE', payload: 1 });
              setInputValue(value);
            }}
            value={inputValue}
          />
        </InputWrapper>
      </SearchInputContainer>
      <StyledHr />
      {sites ? (
        <>
          <Table
            tableCaption="admin dashboard table"
            columns={filteredColumns}
            data={paginate(sortedSites, currentPage)}
            onSort={sortTable}
            sortedColumn={state.sort}
            css={css`
              ${DashboardTableStyles}
              tr > td:last-of-type,
              th:last-of-type {
                text-align: right;
                padding-right: 5rem;
              }
            `}
            EmptyComponent={
              <EmptyDashboardTable
                label="site"
                title="We couldn't find any results."
                description="Please try a different search."
              />
            }
            onRowClick={sitesData => {
              setSiteData(sitesData);
              setIsModalOpen(true);
            }}
          />
          {isModalOpen && siteData && (
            <Modal closeModal={closeModal} noX>
              <EditSiteForm closeModal={closeModal} siteData={siteData} />
            </Modal>
          )}
          {pageCount > 0 && <Pagination {...{ pageCount }} />}
        </>
      ) : (
        <LoadingDashboardTable />
      )}
    </TableWrapper>
  );
};

const SitesAdminTableWrapper: React.FC<SitesAdminTableProps> = props => {
  return (
    <TableProvider>
      <SitesAdminTable {...props} />
    </TableProvider>
  );
};

export default SitesAdminTableWrapper;

const SiteInfo = styled.div`
  position: relative;
`;

const Name = styled.div`
  color: ${darkBlue};
`;

const SiteName = styled.div`
  font-size: 1.2rem;
  position: absolute;
  bottom: -2rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
`;

const StyledClock = styled(Clock)`
  margin-left: 0.5rem;
`;

const StyledHeaderCell = styled(HeaderCell)`
  margin-left: auto;
`;

const TableWrapper = styled.div`
  background: ${white};
  border-radius: 0.4rem;
  box-shadow: var(--gray-box-shadow);
  margin-bottom: 8rem;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding-left: 3rem;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 3rem;
  height: 100%;
  position: absolute;
  fill: ${darkGray};
`;

const SearchInputContainer = styled.div`
  padding: 3rem;
  max-width: 49rem;
`;

const StyledHr = styled.hr`
  border: none;
  border-bottom: 1px solid ${lightGray};
  margin: 0 3rem;
`;

const SortableHeader = styled(Flex)`
  display: inline-flex;
  cursor: pointer;
`;

const StyledSortArrow = styled.div<{ isHidden: boolean; sortOrder?: 'ascending' | 'descending' }>`
  margin-left: 0.3rem;
  & > svg {
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    transform: ${({ sortOrder }) => (sortOrder === 'ascending' ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;

const StyledDiv = styled.div`
  gap: 0.5rem;
  align-items: center;
  display: flex;
`;
