import React, { useEffect, useMemo, useState } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FieldError } from 'react-hook-form';

import Card from 'components/atoms/Card';
import Checkbox from 'components/atoms/Checkbox';
import CircularCheckedBadge from 'components/atoms/CircularCheckedBadge';
import Toggle from 'components/atoms/Toggle';
import SkillCategoryCollapsible from 'components/molecules/SkillCategoryCollapsible';
import { categorizeSkills } from 'hooks/useCategorizedSkills';
import {
  borderGray,
  darkGray,
  white,
  ahoy,
  black,
  mediumGray,
  mediumLightGray,
  darkSaphire,
  fog,
  lightGray
} from 'utils/colors';

import { ReactComponent as Caret } from 'images/caret.svg';
import { ReactComponent as SelectAllIcon } from 'images/selectAllCheckboxesButton.svg';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { ReactComponent as SkillInfo } from 'images/skillInfo.svg';
import CountBadge from 'components/atoms/CountBadge';
import AriaAlert from 'components/atoms/AriaAlert';
import DropdownFlyout, {
  DropdownSelectable,
  DropdownFlyoutProps
} from 'components/molecules/DropdownFlyout';
import { PreprocessedSkill, fetchSkillsById } from 'services/skills';

const sortTypeOptionsWithCategory = ['Category', 'Confidence Level', 'Name', 'Selected'];
type CategorizedSkill = [string, Record<string, Skill[]>];

const sortCategorizedSkills = (
  categorizedSkillsObject: Record<string, Record<string, Skill[]>>,
  categorizedSelectedCounts: Record<string, number>
) => {
  return Object.entries(categorizedSkillsObject).sort(
    ([categoryA], [categoryB]) =>
      categorizedSelectedCounts[categoryB] - categorizedSelectedCounts[categoryA]
  );
};

const sortSkills = (
  sortType: string,
  skills: Skill[],
  selectedSkills: readonly CurricularSkill[]
) => {
  switch (sortType) {
    case 'Confidence Level':
      return [...skills].sort((a, b) => {
        if (a.confidence && b.confidence) {
          return b?.confidence - a?.confidence;
        } else {
          return 1;
        }
      });
    case 'Name':
      return [...skills].sort(sortByName);
    case 'Selected':
      return [
        ...skills
          .filter(skill => selectedSkills.map(s => s.id).includes(skill.id))
          .sort(sortByName),
        ...skills
          .filter(skill => !selectedSkills.map(s => s.id).includes(skill.id))
          .sort(sortByName)
      ];
    default:
      return [];
  }
};

const sortByName = (a: Skill, b: Skill) => a.name.localeCompare(b.name);

interface SkillsFromTextProps {
  className?: string;
  parsedSkills: readonly PreprocessedSkill[];
  learningOutcomeSkillIds?: readonly string[];
  revealedSkillId: string | undefined;
  onReveal: (id: string) => void;
  selectedSkills: readonly CurricularSkill[];
  onChange?(skills: readonly CurricularSkill[]): void;
  error?: FieldError;
  maxHeight?: string;
}

const SkillsFromText: React.FC<SkillsFromTextProps> = ({
  className,
  parsedSkills,
  revealedSkillId,
  onReveal,
  selectedSkills,
  onChange,
  error,
  maxHeight,
  learningOutcomeSkillIds
}) => {
  const [sortType, setSortType] = useState<DropdownSelectable>({
    label: 'Confidence Level',
    value: 'Confidence Level'
  });

  const [skillsList, setSkillsList] = useState<Skill[]>([]);
  const [learningOutcomeToggle, setLearningOutcomeToggle] = useState(false);

  const [isAllOpen, setIsAllOpen] = React.useState(true);
  const isReadOnly = !onChange;

  const collapseAll = () => {
    setIsAllOpen(!isAllOpen);
  };

  const categorizedSelectedCounts = useMemo(() => {
    const skills = Object.entries(categorizeSkills(skillsList));
    return skills.reduce<Record<string, number>>((counts, [category, subcategories]) => {
      counts[category] = Object.values(subcategories)
        .flat()
        .filter(skill => selectedSkills.map(s => s.id).includes(skill.id)).length;
      return counts;
    }, {});
  }, [selectedSkills, skillsList]);

  const [filteredAndSortedUncategorizedSkills, filteredAndSortedCategorySkills] = useMemo<
    [Skill[], CategorizedSkill[]]
  >(() => {
    const skills: Skill[] = learningOutcomeToggle
      ? skillsList.filter(skill => learningOutcomeSkillIds?.includes(skill.id))
      : skillsList;
    if (sortType.value === 'Category') {
      return [[], sortCategorizedSkills(categorizeSkills(skills), categorizedSelectedCounts)];
    } else {
      return [sortSkills(sortType.value, skills, selectedSkills), []];
    }
  }, [learningOutcomeToggle, skillsList, selectedSkills, sortType]);

  useEffect(() => {
    fetchSkillsById(
      parsedSkills.map(skill => skill.id),
      ['id', 'name', 'subcategory', 'category', 'infoUrl']
    ).then(fetchedSkills => {
      const joinedSkills = fetchedSkills.reduce<Skill[]>((skillList, currentSkill) => {
        const foundSkill = parsedSkills.find(skill => skill.id === currentSkill.id);
        if (foundSkill) {
          skillList.push({ ...currentSkill, confidence: foundSkill.confidence });
        }
        return skillList;
      }, []);
      setSkillsList(joinedSkills);
    });
  }, [parsedSkills]);

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <StyledCard className={className} error={error} maxHeight={maxHeight}>
        <AriaAlert>{filteredAndSortedUncategorizedSkills.length} skills parsed.</AriaAlert>
        <HeadingMargin>
          <HeadingWrapper>
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                margin-right: 2rem;
              `}
            >
              <SkillsHeader>Skills From Text</SkillsHeader>
              <CountBadge>{filteredAndSortedUncategorizedSkills.length}</CountBadge>
            </div>
            <div
              css={css`
                margin-bottom: 2rem;
              `}
            >
              <StyledDropdownFlyout
                dropDownName="skill-sort-options"
                dropDownContentWidth="20rem"
                items={sortTypeOptionsWithCategory.map(option => ({
                  label: option,
                  value: option
                }))}
                dropdownPlaceholder="Confidence Level"
                onSelect={sort => {
                  setSortType(sort);
                }}
              />
            </div>
            {learningOutcomeSkillIds && (
              <LoToggleWrapper>
                <Toggle
                  data-cy="skills-from-text_learning-outcomes-toggle"
                  value={learningOutcomeToggle}
                  onValueChange={() => {
                    setLearningOutcomeToggle(!learningOutcomeToggle);
                  }}
                  aria-label={'Select Only Skills from Learning Outcomes'}
                />
                Select Only Skills from Learning Outcomes
              </LoToggleWrapper>
            )}
          </HeadingWrapper>
        </HeadingMargin>
        <SelectAllHeaderWrapper>
          {!isReadOnly && (
            <SelectAllCheckboxesButton
              data-cy="skills-from-text_select-all-skills-checkbox-button"
              type="button"
              aria-pressed={false}
              aria-label={'Check all skills'}
              onClick={() =>
                onChange(
                  handleSelectAll(
                    parsedSkills,
                    learningOutcomeSkillIds || [],
                    selectedSkills,
                    learningOutcomeToggle
                  )
                )
              }
            >
              <SelectAllIcon />
            </SelectAllCheckboxesButton>
          )}
          <ColumnHeader>skills from text</ColumnHeader>
          {sortType.value === 'Category' && (
            <StyledCollapseAllButton type="button" onClick={collapseAll}>
              {isAllOpen ? `Collapse All` : `Expand All`}
              <StyledCaret isOpen={isAllOpen}>
                <Caret />
              </StyledCaret>
            </StyledCollapseAllButton>
          )}
          <RevealInTextHeader>reveal in text</RevealInTextHeader>
        </SelectAllHeaderWrapper>
        <SkillsContainer>
          {sortType.value !== 'Category'
            ? filteredAndSortedUncategorizedSkills.map(skill => (
                <SingleSkillContainer key={skill.id}>
                  {isReadOnly ? (
                    <CircularCheckedBadge
                      aria-label={`checked badge for ${skill.name}`}
                      visibility={
                        selectedSkills.map(s => s.id).includes(skill.id) ? `visible` : `hidden`
                      }
                    />
                  ) : (
                    <Checkbox
                      id={`checkbox-${skill.id}`}
                      onChange={e => {
                        const selectedSkill: CurricularSkill = learningOutcomeSkillIds?.includes(
                          skill.id
                        )
                          ? { id: skill.id, isLearningObjective: true }
                          : { id: skill.id, isLearningObjective: false };
                        onChange(
                          e.target.checked
                            ? [...selectedSkills, selectedSkill]
                            : selectedSkills.filter(s => s.id !== skill.id)
                        );
                      }}
                      key={`checkbox-${skill.id}`}
                      title={`${skill.name} checkbox`}
                      aria-label={`checkbox for ${skill.name}`}
                      checked={selectedSkills.map(s => s.id).includes(skill.id)}
                    />
                  )}
                  <StyledSkillName data-cy={`skills-from-text_skill-name_${skill.id}`}>
                    {skill.name}
                    <Link href={skill.infoUrl} target="_blank">
                      <StyledSkillInfo aria-label={`external ${skill.name} page`} />
                    </Link>
                  </StyledSkillName>
                  <StyledToggle
                    value={revealedSkillId === skill.id}
                    onValueChange={() => onReveal(skill.id)}
                    aria-label={skill.name}
                  />
                </SingleSkillContainer>
              ))
            : filteredAndSortedCategorySkills.map(([category, subcategoryObj]) => (
                <StyledSkillCategoryCollapsible
                  isControllerOpen={isAllOpen}
                  key={`category-${category}`}
                  buttonText={
                    <CategoryButtonContainer>
                      {category}
                      <StyledCountBadge>{categorizedSelectedCounts[category]}</StyledCountBadge>
                    </CategoryButtonContainer>
                  }
                  name={category}
                  subcategories={subcategoryObj}
                  renderSkillContent={skill => (
                    <SubcategorySkill
                      {...{
                        isReadOnly,
                        selectedSkills,
                        revealedSkillId,
                        skill,
                        learningOutcomeSkillIds
                      }}
                      onCheck={skills => onChange?.(skills)}
                      onToggle={() => onReveal(skill.id)}
                    />
                  )}
                />
              ))}
        </SkillsContainer>
        {!isReadOnly && (
          <SubmitSkill href="https://skills.emsidata.com/submit" target="_blank">
            <StyledPlus />
            Suggest a Skill
          </SubmitSkill>
        )}
      </StyledCard>
    </>
  );
};

export function handleSelectAll(
  parsedSkills: readonly PreprocessedSkill[],
  learningOutcomeSkillIds: readonly string[],
  selectedSkills: readonly CurricularSkill[],
  selectOnlyLOSkills: boolean
): readonly CurricularSkill[] {
  const skillIsSelected = (skill: PreprocessedSkill) =>
    selectedSkills.some(selectedSkill => skill.id === selectedSkill.id);

  const loSkills = parsedSkills.filter(parsedSkill =>
    learningOutcomeSkillIds.includes(parsedSkill.id)
  );

  const isAllParsedSkillSelected = !!parsedSkills.every(skillIsSelected);
  const isAllLOSkillSelected = !!loSkills.every(skillIsSelected);

  if (isAllLOSkillSelected && selectOnlyLOSkills) {
    return selectedSkills.filter(
      selectedSkill => !loSkills.some(loSkill => loSkill.id === selectedSkill.id)
    );
  } else if (isAllParsedSkillSelected) {
    return selectedSkills.filter(
      selectedSkill => !parsedSkills.some(parsedSkill => parsedSkill.id === selectedSkill.id)
    );
  }

  let allSelectedSkills: readonly CurricularSkill[];

  if (!selectOnlyLOSkills) {
    const allSelectedParsedSkills: readonly CurricularSkill[] = parsedSkills.map(parsedSkill => ({
      id: parsedSkill.id,
      isLearningObjective: learningOutcomeSkillIds.includes(parsedSkill.id)
    }));

    allSelectedSkills = Object.values(
      allSelectedParsedSkills
        .concat(selectedSkills)
        .reduce<Record<string, CurricularSkill>>((acc, skill) => {
          if (!acc[skill.id]) {
            acc[skill.id] = skill;
          }
          return acc;
        }, {})
    );
  } else {
    allSelectedSkills = selectedSkills.concat(
      loSkills.map(loParsedSkill => ({
        id: loParsedSkill.id,
        isLearningObjective: true
      }))
    );
  }

  return allSelectedSkills;
}

const SubcategorySkill: React.FC<{
  isReadOnly: boolean;
  selectedSkills: readonly CurricularSkill[];
  skill: Skill;
  onCheck: (skills: CurricularSkill[]) => void;
  revealedSkillId?: string;
  learningOutcomeSkillIds?: readonly string[];
  onToggle: (skillId: string) => void;
}> = ({
  isReadOnly,
  selectedSkills,
  skill,
  onCheck,
  revealedSkillId,
  onToggle,
  learningOutcomeSkillIds
}) => (
  <>
    {isReadOnly ? (
      <CircularCheckedBadge
        aria-label={`checked badge for ${skill.name}`}
        visibility={selectedSkills?.map(s => s.id).includes(skill.id) ? `visible` : `hidden`}
      />
    ) : (
      <Checkbox
        id={`checkbox-${skill.id}`}
        onChange={e => {
          const selectedSkill: CurricularSkill = learningOutcomeSkillIds?.includes(skill.id)
            ? { id: skill.id, isLearningObjective: true }
            : { id: skill.id, isLearningObjective: false };
          onCheck(
            e.target.checked
              ? [...selectedSkills, selectedSkill]
              : selectedSkills.filter(s => s.id !== skill.id)
          );
        }}
        key={`checkbox-${skill.id}`}
        title={`${skill.name} checkbox`}
        aria-label={`checkbox for ${skill.name}`}
        checked={selectedSkills.map(s => s.id).includes(skill.id)}
      />
    )}
    <StyledCategorySkillName>
      {skill.name}
      <Link href={skill.infoUrl} target="_blank">
        <StyledSkillInfo aria-label={`external ${skill.name} page`} />
      </Link>
    </StyledCategorySkillName>
    <Toggle
      value={revealedSkillId === skill.id}
      onValueChange={() => onToggle(skill.id)}
      aria-label={skill.name}
    />
  </>
);

const StyledCaret = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  svg {
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    & > path {
      stroke: ${darkSaphire};
    }
  }
`;

const StyledCollapseAllButton = styled.button`
  display: flex;
  margin-left: 1rem;
  align-items: center;
  background-color: ${fog};
  font-weight: 500;
  border: 1px solid ${lightGray};
  border-radius: 2px;
  color: ${darkSaphire};
  white-space: nowrap;
`;
const StyledCard = styled(Card)<{ error?: FieldError; maxHeight?: string }>`
  height: fit-content;

  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight};
  `}
  ${({ error }) =>
    error &&
    `
    outline: 1px solid ${ahoy};
  `}
`;

const ColumnHeader = styled.span`
  font-size: 1rem;
  color: ${mediumGray};
  font-weight: bold;
  letter-spacing: 0.27rem;
  line-height: 1rem;
  display: inline;
  text-transform: uppercase;
  margin-left: 1rem;
`;

const RevealInTextHeader = styled(ColumnHeader)`
  text-align: right;
  margin-left: auto;
  padding-right: 1rem;
`;

const StyledSkillInfo = styled(SkillInfo)`
  font-size: 1.4rem;
  margin-right: 1rem;
`;

const Link = styled.a`
  margin-left: 1rem;
  height: 1.6rem;
  display: inline-block;
  margin: 0.15rem 0 0 1rem;
`;

const SubmitSkill = styled.a`
  text-decoration: none;
  border: 1px solid ${borderGray};
  width: 15.5rem;
  border-radius: 3.5rem;
  color: ${darkGray};
  margin: 0;
  margin-top: 1rem;
  height: 4rem;
  padding: 1rem 1.6rem;
  align-items: center;
  font-size: 1.4rem;
  display: inline-flex;

  a:visited {
    text-decoration: none;
    color: ${darkGray};
  }
`;

const SkillsContainer = styled.div`
  max-height: 71rem;
  overflow: auto;
  padding-right: 1rem;
`;

const HeadingMargin = styled.div`
  margin-bottom: 1.75rem;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SkillsHeader = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  padding: 0;
  white-space: nowrap;
`;

const SelectAllHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
`;

const StyledPlus = styled(Plus)`
  margin-right: 0.8rem;
  & > path {
    stroke: ${darkGray};
  }
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))`
  border: 1px solid ${borderGray};
  border-radius: 3.5rem;
  width: 20rem;
  background: ${white};
  font-weight: 700;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  &:before {
    content: 'By ';
    font-weight: 500;
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }

  & > svg {
    right: 1.9rem;
    top: 1.5rem;
  }

  & > svg {
    right: 1.9rem;
    top: 1.5rem;
  }

  & > select {
    color: ${darkGray};
    font-weight: 700;
    font-size: 1.4rem;
    padding: 1rem 1.5rem 1rem 0.5rem;
  }

  * > path {
    stroke: ${darkGray};
  }
`;

const SelectAllCheckboxesButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: inherit;
  cursor: pointer;
  margin-right: 1rem;
  line-height: 0;

  &:focus {
    outline-offset: 0.2rem;
  }
`;

const SingleSkillContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.6rem;
  justify-content: space-between;
`;

const StyledToggle = styled(Toggle)`
  margin-right: 2rem;
`;

const ErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  color: ${ahoy};
`;

const StyledSkillName = styled.span`
  margin-left: 1.5rem;
  font-weight: 400;
  color: ${black};
  flex: 1;
  font-size: 1.4rem;
`;

const StyledCategorySkillName = styled.span`
  margin-left: 1rem;
  flex: 1;
  font-weight: 400;
  color: ${black};
  flex: 1;
  font-size: 1.4rem;
`;

const CategoryButtonContainer = styled.div`
  display: flex;
  text-align: left;
`;

const StyledCountBadge = styled(CountBadge)`
  max-height: 2rem;
`;

const StyledSkillCategoryCollapsible = styled(SkillCategoryCollapsible)`
  & .collapsible {
    justify-content: flex-start;

    button {
      color: ${black};
      font-weight: bold;
      font-size: 1.4rem;
      padding: 0;
      padding-bottom: 1rem;
      border: none;
      background-color: transparent;
    }
  }

  & > div:nth-of-type(2) {
    justify-content: flex-start;
    button {
      margin-left: 1.3rem;
      color: ${mediumLightGray};
      text-align: left;
    }
  }

  .subcategory {
    & > div:nth-of-type(2) {
      margin-left: 3.5rem;
    }
  }

  .skill {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
`;

const LoToggleWrapper = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export default SkillsFromText;
