import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Footer, ContainerPage } from '../components';

import { DataSet, LineBarChart } from './barChart';

import {
  ChartOrder,
  calculateChartHeight,
  getDatasetChartTicks,
  orderChartData,
  segmentChartData
} from './helpers';
import { mediumGray, navyBlue } from 'utils/colors';

export interface BarChartChartPagesProps {
  data: DataSet[];
  order?: ChartOrder;
  label?: string;
  prepend?: JSX.Element;
  firstPageMaxBars?: number;
  append?: React.ReactNode;
  restPagesHeader?: string;
  barColor?: string;
  defaultTicks?: number[];
}

export const BarChartPages: React.FC<BarChartChartPagesProps> = ({
  data,
  order = 'none',
  prepend,
  firstPageMaxBars,
  append,
  restPagesHeader,
  barColor,
  defaultTicks
}) => {
  const ticks = defaultTicks ? defaultTicks : getDatasetChartTicks(data);
  const restHeader = <Text style={styles.restHeader}>{restPagesHeader}</Text>;
  const segments = segmentChartData(orderChartData(data, order), firstPageMaxBars);

  return (
    <>
      {segments.map((segment, index) => {
        return (
          <Page key={index}>
            <View style={styles.prependContainer} fixed>
              {(!prepend || index !== 0) && restHeader}
            </View>
            {index === 0 && prepend}
            <LineBarChart
              data={segment}
              chartHeight={calculateChartHeight(segment)}
              barColor={barColor || mediumGray}
              ticks={ticks}
            />
            <Footer />
          </Page>
        );
      })}
      {append && (
        <ContainerPage>
          <View fixed>{restHeader}</View>
          {React.Children.map(append, (child, index) => (
            <View key={index}>{child}</View>
          ))}
          <Footer />
        </ContainerPage>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  prependContainer: {
    marginLeft: '0.5in',
    marginTop: '0.5in'
  },
  restHeader: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'bold',
    color: navyBlue
  }
});

export default BarChartPages;
