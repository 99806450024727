import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

import { FilterObject } from './skillDetailTableDataFlowControl';

interface FlowControlState {
  activeFilters: FilterObject;
  setActiveFilters(fn: (old: FilterObject) => FilterObject): void;
}

const SkillDetailContext = createContext<FlowControlState>({
  activeFilters: {},
  setActiveFilters() {
    throw new Error(
      'The detailed skill insights were used without a <SillDetailFlowControlProvider>'
    );
  }
});

export const useSkillDetailFlowControls = () => useContext(SkillDetailContext);

export const SkillDetailFlowControlProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [activeFilters, setActiveFilters] = useState<FilterObject>({});

  return (
    <SkillDetailContext.Provider value={{ activeFilters, setActiveFilters }}>
      {children}
    </SkillDetailContext.Provider>
  );
};
