import React from 'react';
import styled from '@emotion/styled';

import ShowDetailButton from 'components/atoms/ShowDetailButton';
import SelectedBenchmarkPills from 'components/molecules/SelectedBenchmarkPills';
import { BenchmarkPopup } from 'components/molecules/BenchmarkPopup';

import { getBenchmarkTitle, UniversalBenchmark } from 'helpers/benchmarks';

import { ReactComponent as Remove } from 'images/x-remove.svg';

const CompactBenchmark: React.FC<{ benchmark: UniversalBenchmark; onRemove(): void }> = ({
  benchmark,
  onRemove
}) => {
  const title = getBenchmarkTitle(benchmark);
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <StyledBenchmarkContainer ref={ref}>
      <RemoveItemButton
        type="button"
        aria-label={`remove ${title} from list`}
        onClick={onRemove}
        data-cy={`compact-benchmark_remove-button_${title}`}
      >
        <Remove />
      </RemoveItemButton>
      <ContentContainer>
        <SelectedBenchmarkPills benchmark={benchmark} />
        <Heading>{title}</Heading>
      </ContentContainer>

      <CompactBenchmarkCover id="compact-benchmark-cover">
        <Gradient />
        <ButtonContainer>
          <StyledShowDetailButton
            aria-label={`show ${title} details`}
            anchorRef={ref}
            renderDetails={() => <BenchmarkPopup benchmark={benchmark} />}
            dataCy={`compact-benchmark_show-detail-button_${title}`}
          />
        </ButtonContainer>
      </CompactBenchmarkCover>
    </StyledBenchmarkContainer>
  );
};

export default CompactBenchmark;

const StyledBenchmarkContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;

  &:hover #compact-benchmark-cover {
    opacity: 1;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
`;

const CompactBenchmarkCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transition: opacity 100ms;
  pointer-events: none;

  opacity: 0;
`;

const Gradient = styled.div`
  height: 100%;
  flex-grow: 1;
  background: linear-gradient(to right, transparent, white);
`;

const Heading = styled.h5`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const ButtonContainer = styled.div`
  background: white;
  display: flex;
  align-items: center;
`;

const StyledShowDetailButton = styled(ShowDetailButton)`
  pointer-events: auto;
`;

const RemoveItemButton = styled.button`
  background: white;
  display: inline;
  border: none;
  padding: 0.3rem;
  cursor: pointer;
`;
