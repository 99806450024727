import dayjs from 'dayjs';
import { formatEducationLevelName } from './educationLevel';
import { ChartSettings } from 'store/programData';

export interface PresentationalDataFilters {
  region: string[];
  educationLevel: string;
  experienceLevel: string;
  timeframe: string;
  skillLevel: string;
  jobPostings: string;
  isLearningOutcome: string;
}

export function formatDataFilters(
  jpaFilter: JPAOptionsFilter,
  regionFilter: RegionFilter,
  chartSettings: ChartSettings
): PresentationalDataFilters {
  return {
    region: formatRegion(regionFilter),
    educationLevel: formatEducationLevel(jpaFilter),
    experienceLevel: formatExperienceLevel(jpaFilter),
    timeframe: formatTimeframe(jpaFilter),
    skillLevel: formatSkillLevel(chartSettings),
    jobPostings: formatJobPostingsSetting(chartSettings),
    isLearningOutcome: formatIsLearningOutcome(chartSettings)
  };
}

function formatRegion(filter: RegionFilter): string[] {
  return filter.regions.map(region => region.name);
}

function formatEducationLevel(filter: JPAOptionsFilter) {
  if (!filter.edulevels_name) {
    return 'Any level of education required';
  }

  const educationLevels = filter.edulevels_name as string[];

  return humanFriendlyJoin(educationLevels.map(formatEducationLevelName), 'or');
}

function formatExperienceLevel(filter: JPAOptionsFilter) {
  if (!filter.min_years_experience) {
    return 'Any years of experience required';
  }

  return `${filter.min_years_experience.lower_bound} to ${filter.min_years_experience.upper_bound} years of experience required`;
}

function formatTimeframe(filter: JPAOptionsFilter) {
  if (!filter.when) {
    return 'Last 12 Months';
  }

  if (typeof filter.when === 'string') {
    return 'Unknown';
  }

  const elapsedMonths = dayjs(filter.when.end).diff(filter.when.start, 'months');
  const label = elapsedMonths === 1 ? 'month' : 'months';

  return `Last ${elapsedMonths} ${label}`;
}

function formatSkillLevel(chartSettings: ChartSettings) {
  if (!chartSettings.selectedSkillLevels.length) {
    return 'All skill levels';
  }

  return humanFriendlyJoin(chartSettings.selectedSkillLevels, 'or');
}

function formatJobPostingsSetting(chartSettings: ChartSettings) {
  if (chartSettings.filterPostingsByBenchmarks) {
    return 'Only those for selected benchmark occupations';
  }

  return 'All job postings';
}

function humanFriendlyJoin(array: string[], connectorWord: string) {
  if (!array.length) {
    return '';
  }

  if (array.length === 1) {
    return array[0];
  }

  if (array.length === 2) {
    return `${array[0]} ${connectorWord} ${array[1]}`;
  }

  const firstItems = array.slice(0, -1).join(', ');
  const lastItem = array[array.length - 1];

  return `${firstItems}, ${connectorWord} ${lastItem}`;
}

function formatIsLearningOutcome(chartSettings: ChartSettings) {
  return chartSettings?.skillTagFilter === 'isLearningObjective'
    ? 'Learning Outcome Skills'
    : 'All Program Skills';
}
