import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Card from 'components/atoms/Card';
import TopMatchesByCipCode from 'components/molecules/TopMatchesByCipCode';
import DropdownFlyout, {
  DropdownFlyoutProps,
  DropdownSelectable
} from 'components/molecules/DropdownFlyout';
import SelectedBenchmarks from 'components/molecules/SelectedBenchmarks';
import TopOccupationsByProgramSkills from 'components/molecules/TopOccupationsByProgramSkills';
import Informative from 'components/molecules/Informative';
import BrowseAllBenchmarksBrowser from 'components/molecules/BrowseAllBenchmarksBrowser';
import FormContainer from 'components/organisms/FormContainer';
import CustomBenchmarksBrowser from 'components/molecules/CustomBenchmarksBrowser';

import {
  customBenchmarkToUniversal,
  occupationBenchmarkToUniversal,
  UniversalBenchmark,
  UniversalOccupationBenchmark
} from 'helpers/benchmarks';
import { hasSiteAccessLevel, useProfileState } from 'store/profileStore';
import { updateCurricularUnit } from 'services/curricularSkills';
import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { useNavigationBlocker } from 'hooks/useNavigationBlocker';
import { useProgramCustomBenchmarks, useProgramOccupations } from 'hooks/benchmarks';
import { useCustomBenchmarksByProgramId } from 'hooks/curricularSkills';
import { useRelatedOccupations } from 'hooks/similarityHooks';
import useCurrentNation from 'hooks/useCurrentNation';

export const benchmarkPageDropdownOptions: DropdownSelectable<string>[] = [
  {
    label: 'Top Occupation Matches by Program Skills',
    value: 'top'
  },
  {
    label: 'Browse All',
    value: 'all'
  },
  {
    label: 'Create Custom Benchmarks',
    value: 'custom'
  },
  {
    label: 'Top Matches by CIP Code',
    value: 'cip'
  }
];

type FormData = {
  selectedBenchmarks: UniversalBenchmark[];
  cipCode: string | undefined;
  previousCustomBenchmarkIds: string[] | undefined;
};

const ProgramBenchmarks: React.FC<{
  program: SingleCurriculum<CurricularUnitResponse>;
  skillsInProgram: string[] | undefined;
  requiredSkillsInProgram: string[] | undefined;
}> = ({ program, skillsInProgram, requiredSkillsInProgram }) => {
  const [nation] = useCurrentNation();
  const programId = program.data.id;

  const programSkills =
    requiredSkillsInProgram && requiredSkillsInProgram.length
      ? requiredSkillsInProgram
      : skillsInProgram || [];

  const { customBenchmarks: allCustomBenchmarks, isLoading: allCustomBenchmarksIsLoading } =
    useCustomBenchmarksByProgramId(programId);
  const { data: customBenchmarks, isLoading: customBenchmarksIsLoading } =
    useProgramCustomBenchmarks(programId);

  const { data: occupations, isLoading: occupationsIsLoading } = useProgramOccupations(programId);

  const { data: relatedLightcastOccupations, isLoading: relatedLightcastOccupationsIsLoading } =
    useRelatedOccupations('occupation', programSkills, 4);

  const { handleSubmit, setValue, control, formState, reset, getValues } = useForm<FormData>({
    defaultValues: {
      selectedBenchmarks: [],
      cipCode: program.data.attributes.cipCode,
      previousCustomBenchmarkIds: undefined
    }
  });

  const { remove, append } = useFieldArray({
    name: 'selectedBenchmarks',
    control
  });

  const selectedBenchmarks = useWatch({ control, name: 'selectedBenchmarks' });
  const currentCipCode = useWatch({ control, name: 'cipCode' });
  const previousCustomBenchmarkIds = useWatch({ control, name: 'previousCustomBenchmarkIds' });

  const queryClient = useQueryClient();
  const history = useHistory();

  const isLoading =
    allCustomBenchmarksIsLoading ||
    customBenchmarksIsLoading ||
    occupationsIsLoading ||
    relatedLightcastOccupationsIsLoading;

  const [activeBenchmarkPageDropdownSelection, setActiveBenchmarkPageDropdownSelection] =
    React.useState(benchmarkPageDropdownOptions[0]);
  const [selectedBenchmarksIsSaving, setSelectedBenchmarksIsSaving] = React.useState(false);

  useNavigationBlocker({
    when: formState.isDirty,
    header: 'Unsaved Benchmark Changes',
    content:
      'You have unsaved changes to the selected benchmarks for this program. If you leave now, all unsaved changes will be lost.'
  });

  const toast = getToastFnThatWeTreatLikeHook();

  useDeepCompareEffect(() => {
    if (isLoading) {
      return;
    }

    // test against customBenchmarks state
    if (previousCustomBenchmarkIds) {
      const newCustomBenchmarks = customBenchmarks
        .filter(c => !previousCustomBenchmarkIds.includes(c.id))
        .map(benchmark => customBenchmarkToUniversal(benchmark));
      const deletedCustomBenchmarkIds = previousCustomBenchmarkIds.filter(
        i => !customBenchmarks.find(c => c.id === i)
      );
      const shouldDirty = !!newCustomBenchmarks.length || !!deletedCustomBenchmarkIds.length;

      // remove any deleted/append any created custom benchmarks
      setValue(
        'selectedBenchmarks',
        [
          ...selectedBenchmarks.filter(s => !deletedCustomBenchmarkIds.includes(s.attributes.id)),
          ...newCustomBenchmarks
        ],
        { shouldDirty }
      );

      // update base list of customBenchmarkIds
      setValue(
        'previousCustomBenchmarkIds',
        customBenchmarks.map(c => c.id),
        { shouldDirty }
      );
      return;
    }

    reset({
      selectedBenchmarks: [
        ...occupations.map(occupationBenchmarkToUniversal),
        ...customBenchmarks
          .filter(benchmark => benchmark)
          .map(benchmark => customBenchmarkToUniversal(benchmark))
      ],
      cipCode: program.data.attributes.cipCode,
      previousCustomBenchmarkIds: customBenchmarks.map(c => c.id)
    });
  }, [occupations, customBenchmarks, isLoading]);

  const deselect = (benchmark: UniversalBenchmark) => {
    // Both the type and id must correspond
    const removeIndex = selectedBenchmarks.findIndex(
      b =>
        b.universalType === benchmark.universalType && benchmark.attributes.id === b.attributes.id
    );

    if (!~removeIndex) {
      throw new Error(
        `Cannot deselect benchmark ${benchmark.attributes.id} of type ${benchmark.universalType} because it is not selected`
      );
    }

    remove(removeIndex);
  };

  const selectDefaultBenchmarks = () => {
    setValue(
      'selectedBenchmarks',
      relatedLightcastOccupations?.map(
        occupation =>
          ({
            universalType: 'occupation',
            attributes: {
              id: occupation.id,
              name: occupation.name,
              type: 'occupation'
            }
          } as UniversalOccupationBenchmark)
      ) || [],
      { shouldDirty: true }
    );
  };

  const onSubmit = handleSubmit(inputs => {
    setSelectedBenchmarksIsSaving(true);
    updateCurricularUnit(program.data.id, {
      benchmarks: allCustomBenchmarks.map(customBenchmark => ({
        id: customBenchmark.id,
        selected: !!inputs.selectedBenchmarks.find(
          selected => selected.attributes.id === customBenchmark.id
        )
      })),
      lotBenchmarks: inputs.selectedBenchmarks.reduce(
        (result: CurricularUnitLOTBenchmark[], benchmark) => {
          if (benchmark.universalType === 'occupation') {
            // peel off name
            const { name, ...attributes } = benchmark.attributes;
            result.push({ ...attributes });
          }
          return result;
        },
        []
      ),
      cipCode: inputs.cipCode
    })
      .then(async () => {
        toast('Done! Benchmarks updated.', 'success');
        reset(getValues());

        // give react a little bit of time to update before redirecting.
        setTimeout(() => {
          history.push(`/program/insights/${program.data.id}`);

          queryClient.invalidateQueries(['program', program.data.id]);
          queryClient.invalidateQueries('benchmarks');
        }, 50);
      })
      .catch(async () => {
        toast('Could not update benchmarks. Please try again.', 'error');
        await queryClient.invalidateQueries(['program', program.data.id]);
        await queryClient.invalidateQueries('benchmarks');
        setSelectedBenchmarksIsSaving(false);
      });
  });

  const hasProgramSiteEditorAccess = hasSiteAccessLevel(
    program?.data.attributes.site,
    'editor',
    useProfileState()
  );

  return (
    <FormContainer onSubmit={onSubmit}>
      <HeadingText>Select Benchmarks for Market Alignment</HeadingText>
      <BodyText>
        Use the dropdown below to view different methods for choosing relevant occupations, or other
        benchmarks, for comparison against this program. The skills from all selected benchmarks
        will be included in the Market Alignment analysis.
      </BodyText>
      <CardsRow>
        <SelectableBenchmarksCardContainer>
          <SelectableBenchmarksCard>
            <CardHeaderRow>
              <StyledDropdownFlyout
                items={
                  nation !== 'us'
                    ? benchmarkPageDropdownOptions.filter(option => option.value !== 'cip')
                    : benchmarkPageDropdownOptions
                }
                dropDownName="benchmark-types"
                selected={activeBenchmarkPageDropdownSelection}
                onSelect={setActiveBenchmarkPageDropdownSelection}
                dropDownContentWidth="32rem"
              />

              {activeBenchmarkPageDropdownSelection?.value === 'top' && (
                <Informative
                  renderContent={() => (
                    <InfoPopupContainer>
                      <div>
                        This section displays the Occupation benchmarks that best match this program
                        based on the program&apos;s selected skills.
                      </div>
                      <div>
                        Only program skills from Required Courses will be used for this process,
                        allowing matches to be determined by the subject relevant course skills.
                      </div>
                    </InfoPopupContainer>
                  )}
                />
              )}
              {activeBenchmarkPageDropdownSelection?.value === 'cip' && (
                <Informative
                  renderContent={() => (
                    <InfoPopupContainer>
                      <div>
                        This section displays the occupation benchmarks aligned to this program
                        based on the <em>Classification of Instructional Programs</em> (CIP) code.
                      </div>
                      <div>
                        Colleges and universities assign CIP codes to their academic programs and
                        other educational offerings.
                      </div>
                      <div>
                        Selecting matches based on a CIP code allows you to pick from occupations
                        known to be related to that program type.
                      </div>
                    </InfoPopupContainer>
                  )}
                />
              )}
            </CardHeaderRow>

            {activeBenchmarkPageDropdownSelection.value === 'top' && (
              <TopOccupationsByProgramSkills
                programId={programId}
                programHasRequiredCourses={!!requiredSkillsInProgram?.length}
                userCanEditProgram={hasProgramSiteEditorAccess}
                selectedBenchmarks={selectedBenchmarks}
                select={append}
                deselect={deselect}
                skillIds={
                  requiredSkillsInProgram?.length ? requiredSkillsInProgram : skillsInProgram || []
                }
              />
            )}
            {activeBenchmarkPageDropdownSelection.value === 'cip' && (
              <TopMatchesByCipCode
                programCipCode={currentCipCode}
                changeProgramCipCode={code => {
                  setValue('cipCode', code, { shouldDirty: true });
                }}
                selectedBenchmarks={
                  selectedBenchmarks.filter(
                    benchmark => benchmark.universalType === 'occupation'
                  ) as UniversalOccupationBenchmark[]
                }
                selectBenchmark={append}
                deselectBenchmark={deselect}
              />
            )}
            {activeBenchmarkPageDropdownSelection.value === 'all' && (
              <BrowseAllBenchmarksBrowser
                selectedLOTBenchmarks={
                  selectedBenchmarks.filter(
                    benchmark => benchmark.universalType === 'occupation'
                  ) as UniversalOccupationBenchmark[]
                }
                select={append}
                deselect={deselect}
              />
            )}
            {activeBenchmarkPageDropdownSelection.value === 'custom' && (
              <CustomBenchmarksBrowser
                programId={program.data.id}
                selectedBenchmarks={selectedBenchmarks}
                onSelectBenchmark={append}
                onDeselectBenchmark={deselect}
              />
            )}
          </SelectableBenchmarksCard>
        </SelectableBenchmarksCardContainer>
        <SelectedBenchmarksCardContainer>
          <SelectedBenchmarks
            isLoading={isLoading}
            isSaving={selectedBenchmarksIsSaving}
            canSave={formState.isDirty}
            benchmarks={selectedBenchmarks}
            onRemoveItem={deselect}
            onRemoveAll={selectDefaultBenchmarks}
            onCancel={() => history.push(`/program/insights/${program.data.id}`)}
          />
        </SelectedBenchmarksCardContainer>
      </CardsRow>
    </FormContainer>
  );
};

export default ProgramBenchmarks;

const HeadingText = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 600;
`;

const BodyText = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`;

const CardsRow = styled.div`
  display: flex;
  align-items: start;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const CardHeaderRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const SelectableBenchmarksCard = styled(Card)`
  gap: 2rem;
  width: 100%;
`;

const SelectableBenchmarksCardContainer = styled.div`
  flex-basis: 61.5rem;
  flex-shrink: 0;
  flex-grow: 0;

  @media screen and (min-width: 1280px) {
    flex-basis: 88.5rem;
  }
`;

const SelectedBenchmarksCardContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))`
  &#benchmark-types-toggle-button {
    width: fit-content;
    padding: 0.5rem 1rem;
  }

  &#benchmark-types-toggle-button > span {
    font-size: 1.8rem;
    overflow: visible;
    padding-right: 2rem;
  }

  &#benchmark-types-toggle-button > svg {
    top: 1.1rem;
  }
`;

const InfoPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
