import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useFormContext, useController, FieldError, useWatch } from 'react-hook-form';
import throttle from 'lodash/throttle';

import Card from 'components/atoms/Card';
import InputLabel from 'components/atoms/InputLabel';
import FormInput from 'components/molecules/FormInput';
import ProgramTypeDropdownFlyout from 'components/organisms/ProgramTypeDropdownFlyout';
import { darkBlue } from 'utils/colors';
import useCurrentNation from 'hooks/useCurrentNation';
import CipSearch from 'components/molecules/CipSearch';
import InfoChip from 'components/atoms/InfoChip';
import { Inputs } from '../CreateProgramForm';
import { useCipCodeName } from 'hooks/jpaHooks';
import { PreprocessedSkill, parseTextsForSkills } from 'services/skills';
import HighlightedTextArea from 'components/atoms/HighlightedTextArea';
import HighlightedFormInput from 'components/atoms/HighlightedFormInput';

const throttledParse = throttle(
  (text: string[], onParsedText) => onParsedText(parseTextsForSkills(text)),
  500
);

export interface ProgramGeneralInfoFormProps {
  className?: string;
  defaultValues?:
    | Partial<Omit<CurricularUnitResponse, 'site' | 'createdAt' | 'updatedAt'>>
    | undefined;
  onParseSkills(skills: PreprocessedSkill[], learningOutcomeSkills: PreprocessedSkill[]): void;
  onParseError?: React.Dispatch<React.SetStateAction<string | undefined>>;
  revealedSkill: string | undefined;
}

const ProgramGeneralInfoForm: React.FC<ProgramGeneralInfoFormProps> = ({
  className,
  defaultValues,
  onParseSkills,
  onParseError,
  revealedSkill
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<Inputs>();

  const [highlights, setHighlights] = React.useState<PreprocessedSkill[][]>([[], [], []]);

  const { field: programTypeController } = useController({
    name: 'programType',
    control,
    defaultValue: defaultValues?.unitType
      ? { label: defaultValues.unitType.label, value: defaultValues.unitType.id }
      : undefined,
    rules: { required: 'Required' }
  });

  const [currentNation] = useCurrentNation();

  const { title, cipCode, description, learningObjectiveText } = useWatch({ control });
  const { data: cipCodeName } = useCipCodeName(cipCode);

  const { field: cipCodeController } = useController({
    name: 'cipCode',
    control,
    defaultValue: defaultValues?.cipCode
  });

  const handleParsedText = useCallback(
    async (
      parsedTextPromise: Promise<{
        allSkills: PreprocessedSkill[];
        skillsForTexts: PreprocessedSkill[][];
      }>
    ) => {
      try {
        const parsedInformation = await parsedTextPromise;
        onParseSkills(
          parsedInformation.allSkills,
          parsedInformation.skillsForTexts[2].filter(skill => skill.highlights.length > 0)
        );
        setHighlights(parsedInformation.skillsForTexts);
      } catch (err) {
        onParseError && onParseError(JSON.stringify(err));
      }
    },
    [onParseSkills, setHighlights, onParseError]
  );

  React.useEffect(() => {
    const texts = [title || '', description || '', learningObjectiveText || ''];

    if (texts.some(text => text?.length >= 3)) {
      throttledParse(texts, handleParsedText);
    } else {
      onParseSkills([], []);
    }
  }, [title, description, learningObjectiveText, onParseSkills]);

  return (
    <Card className={className}>
      <Header>General Info</Header>

      <InputsRow>
        <div>
          <InputLabel htmlFor="title">Title</InputLabel>
          <HighlightedFormInput
            type="text"
            highlights={highlights[0]}
            revealedSkill={revealedSkill}
            placeholder="e.g. Accounting"
            aria-label="Program title input"
            data-cy="program-general-info-form_title-input"
            {...register('title', { required: 'Required' })}
            error={errors.title as FieldError | undefined}
            required
          />
        </div>

        <ProgramTypeDropdownFlyout
          onChange={programTypeController.onChange}
          labelText="Program Type"
          selected={programTypeController.value}
          error={errors.programType as FieldError | undefined}
          dropdownPlaceholder="Select Program Type"
        />
      </InputsRow>

      <InputsRow>
        <FormInput
          labelText="URL"
          type="text"
          placeholder="e.g. https://institution.edu/accounting"
          {...register('url')}
          error={errors.url as FieldError | undefined}
          aria-label="Program type dropdown"
          data-cy="program-general-info-form_url-input"
        />

        {currentNation === 'us' && (
          <CipWrapper>
            {cipCode ? (
              <CipChipContainer>
                <InputLabel>CIP Code</InputLabel>
                <InfoChip onClick={() => cipCodeController.onChange('')} id={cipCode}>
                  {cipCodeName}
                </InfoChip>
              </CipChipContainer>
            ) : (
              <CipSearch
                showLabel
                onSelect={newCipCode => cipCodeController.onChange(newCipCode)}
              />
            )}
          </CipWrapper>
        )}
      </InputsRow>

      <InputLabel htmlFor="summary">Summary</InputLabel>
      <HighlightedTextArea
        highlights={highlights[1]}
        revealedSkill={revealedSkill}
        {...register('description')}
        placeholder="Enter a brief summary..."
        error={(errors as { summary?: FieldError }).summary}
        aria-label="Program summary input"
        textareaHeight="18.4rem"
        textareaMargin="0 0 2rem 0"
      />

      <>
        <InputLabel htmlFor="learningOutcomes">Learning Outcomes</InputLabel>
        <HighlightedTextArea
          highlights={highlights[2]}
          revealedSkill={revealedSkill}
          {...register('learningObjectiveText')}
          placeholder="Enter learning outcomes..."
          error={(errors as { learningOutcomesText?: FieldError }).learningOutcomesText}
          aria-label="Learning outcomes input"
          textareaHeight="18.4rem"
          textareaMargin="0"
        />
      </>
    </Card>
  );
};

const Header = styled.h3`
  color: ${darkBlue};
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
`;

const InputsRow = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    gap: 1rem;

    & > div {
      min-width: 0;

      &:first-of-type {
        flex-grow: 1;
      }

      &:last-of-type {
      }

      input {
        margin-bottom: 0;
      }
    }
  }
`;

const CipChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 5.4rem;

  > div {
    width: 100%;
    flex-grow: 1;
  }

  @media (min-width: 1025px) {
    width: 48rem;
  }
`;

const CipWrapper = styled.div`
  padding-bottom: 1rem;
  & > div {
    width: 30rem;
  }
`;

export default ProgramGeneralInfoForm;
