import { SheetOptions } from 'excellentexport';
import { exportProgramCsv } from './exportProgram/index';
import { ProgramExcelData } from './exportProgram/types';

const getProgramExportData =
  (params: ProgramExcelData) => async (): Promise<SheetOptions[] | void> => {
    const csvs = await exportProgramCsv(params);

    return csvs;
  };

export default getProgramExportData;
