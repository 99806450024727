import React, { useState, useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as RightArrow } from 'images/carouselArrow.svg';

const ButtonWrapper = styled.div<{ position: string }>`
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: absolute;
  background: transparent;
  border: none;
  top: 36%;
  left: ${({ position }) => (position === 'left' ? '-1rem' : 'auto')};
  right: ${({ position }) => (position === 'right' ? '10rem' : 'auto')};
`;

const StyledRightArrow = styled(RightArrow)`
  cursor: pointer;
`;

const LeftArrow = styled(StyledRightArrow)`
  transform: scaleX(-1);
`;

const NoWrap = styled.div`
  margin-right: -10rem;
  padding-right: 10rem;
  position: relative;
  width: 87%;

  @media (max-width: 1366px) {
    width: 83%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: hidden;
`;

const CardsWrapper = styled.div`
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  margin: 0;
  width: calc(100% - 1rem);
`;

const AnimatedWrapper = styled.div<{ left: number }>`
  display: flex;
  left: ${({ left }) => left}px;
  position: relative;
  transition: left 0.5s ease;

  > div:nth-of-type(2) {
    margin-left: 0;
  }
`;

const Gradient = styled.div<{ right?: number; isVisible: boolean }>`
  background: linear-gradient(
    ${({ right }) => (right ? '270' : '90')}deg,
    #f0f2f5 21.35%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  position: absolute;
  width: 4rem;
  ${({ right }) => (right ? `right: 10rem;` : 'left: 0rem;')}
  top: 0;
  ${({ isVisible }) => (isVisible ? '' : 'visibility: hidden;')}
`;

const Spacer = styled.div<{ scrollPosition: number }>`
  ${({ scrollPosition }) => (scrollPosition ? `min-width: 3rem;` : `min-width: 0;`)}
  transition: min-width 0.5s ease;
`;

const Carousel: React.FC<{
  children: React.ReactNode[];
  gradientLeft: boolean;
  onCarouselScroll?: (scrollDirection: string) => void;
}> = ({ children, gradientLeft, onCarouselScroll }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollLength, setScrollLength] = useState(0);
  const [maxScrollDistance, setMaxScrollDistance] = useState<number>();
  const [containerDimensions, setContainerDimensions] = useState<DOMRect>();
  const [canContinueRight, setCanContinueRight] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const containerEl = useRef<HTMLDivElement>(null);
  const cardRefs = useRef(new Map<number, HTMLElement | null>()).current;

  const lastCardKey = React.useMemo(
    () => React.Children.count(children) - 1,
    [React.Children.count(children)]
  );
  const cardRef = cardRefs.get(lastCardKey);

  React.useLayoutEffect(() => {
    setScrollPosition(0);
  }, [React.Children.count(children)]);

  const calculateNewDimensions = () => {
    if (cardRef && containerEl.current) {
      const cardWidth = cardRef.getBoundingClientRect().width;
      const scrollCardContainerDimensions = containerEl.current.getBoundingClientRect();
      const maxFullCardsInInnerWidth = Math.floor(
        (scrollCardContainerDimensions.width - 70) / cardWidth
      );
      const newScrollLength = maxFullCardsInInnerWidth * cardWidth;
      const childCount = React.Children.count(children);
      const maxNumScrolls = Math.ceil(childCount / maxFullCardsInInnerWidth);
      const newMaxScrollDistance = newScrollLength * maxNumScrolls * -1;

      return {
        scrollCardContainerDimensions,
        scrollLength: scrollPosition === 0 ? newScrollLength - 30 : newScrollLength,
        maxScrollDistance: newMaxScrollDistance
      };
    }
  };
  const setScrollStats = () => {
    if (containerEl.current && cardRef) {
      const dimensions = calculateNewDimensions();
      if (dimensions) {
        setMaxScrollDistance(dimensions.maxScrollDistance);
        setScrollLength(dimensions.scrollLength);
        setContainerDimensions(dimensions.scrollCardContainerDimensions);
      }
    }
  };

  React.useEffect(() => {
    setScrollStats();
    setScrollPosition(0);
    setScrollLength(0);
  }, [React.Children.count(children)]);

  React.useEffect(() => {
    if (!maxScrollDistance) {
      setCanContinueRight(false);
    } else {
      setCanContinueRight(!!(-maxScrollDistance + scrollPosition - scrollLength >= scrollLength));
    }
  }, [scrollPosition, scrollLength]);

  useLayoutEffect(setScrollStats, [containerEl, cardRef, React.Children.count(children)]);
  useLayoutEffect(() => {
    window.addEventListener('resize', setScrollStats);
    return () => window.removeEventListener('resize', setScrollStats);
  });

  const animateScroll = (direction: string) => {
    if (direction === 'left' && scrollPosition >= 0) {
      return;
    }

    const dimensions = calculateNewDimensions();
    if (dimensions) {
      let newPosition =
        direction === 'left'
          ? scrollPosition + dimensions.scrollLength
          : scrollPosition - dimensions.scrollLength;
      // Ensure that we never end up scrolling the starting card right of the starting point
      if (newPosition > 0) {
        newPosition = 0;
      }

      setScrollPosition(newPosition);
    }
  };

  const isTabbable = (element?: HTMLElement | null) => {
    if (!sliderRef.current || !containerDimensions || !element) {
      return -1;
    }
    if (
      element.getBoundingClientRect().left - sliderRef.current.getBoundingClientRect().left >=
        scrollPosition * -1 &&
      element.getBoundingClientRect().right - sliderRef.current.getBoundingClientRect().left <=
        scrollPosition * -1 + containerDimensions.width
    ) {
      return 0;
    }
    return -1;
  };

  return (
    <NoWrap>
      {scrollPosition < 0 && (
        <ButtonWrapper
          tabIndex={0}
          position="left"
          onClick={() => {
            onCarouselScroll && onCarouselScroll('left');
            animateScroll('left');
          }}
          as="button"
        >
          <LeftArrow />
        </ButtonWrapper>
      )}
      <ContentWrapper>
        <CardsWrapper ref={containerEl}>
          <AnimatedWrapper left={scrollPosition} ref={sliderRef}>
            <Spacer scrollPosition={scrollPosition} />
            {React.Children.map(children, (child, i) => {
              const element = cardRefs.get(i);
              return (
                child && (
                  <div
                    key={i}
                    tabIndex={-1}
                    ref={el => {
                      cardRefs.set(i, el);
                    }}
                    data-cy={`carousel_child-element_${i}`}
                  >
                    {React.isValidElement(child)
                      ? React.cloneElement(child, {
                          tabIndex: isTabbable(element)
                        })
                      : null}
                  </div>
                )
              );
            })}
          </AnimatedWrapper>
          <Gradient isVisible={!!scrollPosition && gradientLeft} />
          <Gradient
            isVisible={canContinueRight}
            right={containerDimensions && containerDimensions.width}
          />
        </CardsWrapper>
      </ContentWrapper>
      {canContinueRight && (
        <ButtonWrapper
          tabIndex={0}
          position="right"
          onClick={() => {
            onCarouselScroll && onCarouselScroll('right');
            animateScroll('right');
          }}
          as="button"
        >
          <StyledRightArrow />
        </ButtonWrapper>
      )}
    </NoWrap>
  );
};

export default Carousel;
