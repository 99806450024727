import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { keyBy } from 'lodash';

import Button from 'components/atoms/Button';
import Header from 'components/molecules/Header';
import JobPostingsChart from 'components/molecules/JobPostingsChart';
import ModalButton from 'components/molecules/ModalButton';
import ParsePostingExportButton from 'components/molecules/ParsePostingExportButton';
import SelectRegionDropdownButton from 'components/molecules/RegionPickerFlyoutButton';
import ProgramSelectModal from 'components/molecules/ProgramSelectModal';
import { Item } from 'components/molecules/SelectedItemsCard';
import SelectExperienceRequiredDropdownButton from 'components/molecules/SelectExperienceRequiredFlyoutButton';
import SelectEducationLevelDropdownButton from 'components/molecules/SelectEducationLevelDropdownButton';
import ChartSettingsDropdownButton from 'components/molecules/ChartSettingsDropdownButton';
import TextParser from 'components/organisms/TextParser';
import SelectCoursesProgramForm from 'components/organisms/SelectCoursesProgramForm';

import { PreprocessedSkill, fetchSkillsById } from 'services/skills';
import { getSkillCounts, searchCourses } from 'services/curricularSkills';
import { getRankingByFacet } from 'services/jpa';
import useCurrentNation from 'hooks/useCurrentNation';
import { useJPAFilterQuery } from 'hooks/jpaHooks';
import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';
import { white, darkSaphire, lightGray, fog, black } from 'utils/colors';

import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { ReactComponent as BriefcaseIcon } from 'images/briefcase.svg';
import { ReactComponent as EditIcon } from 'images/edit.svg';
import { EnhancedSkill } from 'hooks/programInsights';
import { ChartSettings } from 'store/programData';

interface Program {
  title: string;
  id: string;
}

type ChartData = Record<string, EnhancedSkill>;

const ParsePosting: React.FC = () => {
  const [chosenSkills, setChosenSkills] = useState<readonly string[]>([]);
  const [parsedSkills, setParsedSkills] = useState<readonly PreprocessedSkill[]>([]);
  const [skillOverlapData, setSkillOverlapData] = useState<ChartData>({});
  const [skillGapsData, setSkillGapsData] = useState<ChartData>({});
  const [selectedPrograms, setSelectedPrograms] = useState<Program[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobChartsAreExpanded, setJobChartsAreExpanded] = useState(false);
  const [chartSettings, setChartSettings] = useState<ChartSettings>({
    selectedSkillLevels: [],
    isDisplayingCategories: false
  });
  const profileState = useProfileState();
  const [selectedCourses, setSelectedCourses] = useState<readonly Item[]>([]);
  const history = useHistory();
  const [currentNation] = useCurrentNation();

  const [associatedPrograms, setAssociatedPrograms] = useState<readonly Item[]>([]);

  const { query, setRegionFilter, regionFilter, setJPAFilter, jpaFilter } = useJPAFilterQuery();

  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  const exportData = {
    skillGapsData,
    skillOverlapData,
    chosenSkills,
    selectedCourses,
    selectedPrograms,
    chartSettings,
    regionFilter,
    jpaFilter
  };

  const onParsedSkills = (skills: readonly PreprocessedSkill[]) => {
    if (!skills.length) {
      setChosenSkills([]);
      setParsedSkills([]);
      return;
    }
    setChosenSkills(prevChosenSkills =>
      prevChosenSkills.filter(skillId => skills.some(skill => skill.id === skillId))
    );
    setParsedSkills(skills);
  };

  const soughtSkills = useMemo(() => {
    return chosenSkills.reduce<{ [key: string]: { name: string } }>((skillMap, skillId) => {
      const parsedSkill = parsedSkills.find(skill => skill.id === skillId);
      if (parsedSkill) {
        skillMap[skillId] = { name: parsedSkill.name };
      }
      return skillMap;
    }, {});
  }, [chosenSkills]);

  const filteredSkills = React.useMemo(() => {
    if (!chartSettings.selectedSkillLevels?.length) {
      return { skillOverlapData, skillGapsData };
    }
    const isSoftwareSelected = chartSettings.selectedSkillLevels?.includes('Software Skill');

    const data = {
      skillOverlapData: keyBy(
        Object.values(skillOverlapData)
          .filter(skill => (isSoftwareSelected ? true : !skill.isSoftware))
          .filter(
            skill =>
              (skill.type && chartSettings.selectedSkillLevels?.includes(skill.type.name)) ||
              (isSoftwareSelected && skill.isSoftware)
          ),
        'id'
      ),
      skillGapsData: keyBy(
        Object.values(skillGapsData)
          .filter(skill => (isSoftwareSelected ? true : !skill.isSoftware))
          .filter(
            skill =>
              (skill.type && chartSettings.selectedSkillLevels?.includes(skill.type.name)) ||
              (isSoftwareSelected && skill.isSoftware)
          ),
        'id'
      )
    };
    return data;
  }, [chartSettings, skillOverlapData, skillGapsData]);

  useEffect(() => {
    (async () => {
      if (!chosenSkills.length) {
        setSkillOverlapData({});
        setSkillGapsData({});
        return;
      }
      setIsLoading(true);
      let skillsInSelectedPrograms: CurricularSkill[];

      if (selectedPrograms.length) {
        const { data: coursesInSelectedPrograms } = await searchCourses({
          limit: 100,
          filter: {
            site: { in: [profileState.currentSite] },
            associatedGroups: { in: selectedPrograms.map(program => program.id) }
          }
        });

        skillsInSelectedPrograms = [
          ...new Set(coursesInSelectedPrograms.map(course => course.attributes.skills || []).flat())
        ];
      }

      const copiedChosenSkills = [...chosenSkills];

      const [{ data: skillCountObject }, { buckets: skillRankings }, selectedSkills] =
        await Promise.all([
          getSkillCounts('course', copiedChosenSkills, profileState.currentSite),
          getRankingByFacet(currentNation, 'skills', {
            filter: query,
            rank: { include: copiedChosenSkills, limit: copiedChosenSkills.length }
          }),
          fetchSkillsById(copiedChosenSkills, [
            'id',
            'name',
            'type',
            'category',
            'subcategory',
            'isSoftware'
          ]).then(skills => {
            return skills;
          })
        ]);

      const skillsData = selectedSkills.reduce(
        (acc: { overlapSkills: ChartData; gapSkills: ChartData }, currentSkill) => {
          const rankingForSkill = skillRankings.find(ranking => {
            return currentSkill.id === ranking.name;
          });

          const skillCountForSkill = Object.entries(skillCountObject.counts).find(tuple => {
            return currentSkill.id === tuple[0];
          });

          if (rankingForSkill) {
            if (selectedPrograms.length) {
              if (skillsInSelectedPrograms.some(skill => skill.id === currentSkill.id)) {
                acc.overlapSkills[currentSkill.id] = {
                  id: currentSkill.id,
                  name: currentSkill.name,
                  count: rankingForSkill?.unique_postings,
                  taughtState: 'taught',
                  type: currentSkill.type,
                  isSoftware: currentSkill.isSoftware
                };
              } else {
                acc.gapSkills[currentSkill.id] = {
                  id: currentSkill.id,
                  name: currentSkill.name,
                  count: rankingForSkill?.unique_postings,
                  taughtState: 'taught_other',
                  type: currentSkill.type,
                  isSoftware: currentSkill.isSoftware
                };
              }

              return acc;
            }

            if (skillCountForSkill?.length) {
              if (skillCountForSkill[1] === 0) {
                acc.gapSkills[currentSkill.id] = {
                  id: currentSkill.id,
                  name: currentSkill.name,
                  count: rankingForSkill?.unique_postings,
                  taughtState: 'taught_other',
                  type: currentSkill.type,
                  isSoftware: currentSkill.isSoftware
                };
              } else {
                acc.overlapSkills[currentSkill.id] = {
                  id: currentSkill.id,
                  name: currentSkill.name,
                  count: rankingForSkill?.unique_postings,
                  taughtState: 'taught',
                  type: currentSkill.type,
                  isSoftware: currentSkill.isSoftware
                };
              }
            }
          }

          return acc;
        },
        { overlapSkills: {}, gapSkills: {} }
      );

      const sortSkillDataByCount = (chartData: ChartData) =>
        Object.entries(chartData)
          .sort((a: [string, EnhancedSkill], b: [string, EnhancedSkill]) => {
            return b[1].count - a[1].count;
          })
          .reduce(
            (acc, [skillId, skillAttributes]) => ({ ...acc, [skillId]: skillAttributes }),
            {}
          );
      setSkillOverlapData(sortSkillDataByCount(skillsData.overlapSkills));
      setSkillGapsData(sortSkillDataByCount(skillsData.gapSkills));
      setIsLoading(false);
    })();
  }, [chosenSkills, selectedPrograms, query]);

  return (
    <>
      <Header headerText="Start With a Job Posting" Icon={BriefcaseIcon}>
        <ParsePostingExportButton {...exportData} />
      </Header>
      <TextParser
        onChosenSkills={setChosenSkills}
        onParsedSkills={onParsedSkills}
        removeOnUnparsed
      />
      <FiltersRow>
        <FiltersHeader headerText="Compare to Curriculum" />
        <SelectRegionDropdownButton
          onSave={setRegionFilter}
          value={regionFilter}
          currentNation={currentNation}
        />
        <SelectEducationLevelDropdownButton
          onChange={levels => {
            setJPAFilter(old => ({
              ...old,
              edulevels_name: levels.length ? levels : undefined
            }));
          }}
        />
        <SelectExperienceRequiredDropdownButton
          onChange={experienceRange => {
            setJPAFilter(old => ({ ...old, min_years_experience: experienceRange }));
          }}
          value={jpaFilter?.min_years_experience}
        />
        <StyledModalButton
          modalStyles={{ background: fog }}
          buttonText={
            <ButtonContent>
              <span>
                Comparing to:{' '}
                <b>
                  {associatedPrograms.length === 1
                    ? associatedPrograms[0].title
                    : associatedPrograms.length > 1
                    ? `${associatedPrograms.length} Programs`
                    : 'All Programs'}
                </b>
              </span>
              <StyledEditIcon />
            </ButtonContent>
          }
        >
          {closeModal => (
            <ProgramSelectModal
              closeModal={closeModal}
              defaultValue={associatedPrograms}
              onSubmit={programs => {
                setSelectedPrograms([...programs]);
                setAssociatedPrograms(programs);
              }}
              locationStateKey="postingsProgramSearch"
            />
          )}
        </StyledModalButton>
        <ChartSettingsDropdownButton chartSettings={chartSettings} onChange={setChartSettings} />
      </FiltersRow>

      <GraphContainer>
        <JobPostingsChart
          isExpanded={jobChartsAreExpanded}
          changeExpansion={() => setJobChartsAreExpanded(!jobChartsAreExpanded)}
          headingText="Skill Overlap"
          subheader={'In Your Curriculum, In Job Posting'}
          data={filteredSkills.skillOverlapData}
          isLoading={isLoading}
          displayType={chartSettings.isDisplayingCategories ? 'category' : 'default'}
        />
        <JobPostingsChart
          isExpanded={jobChartsAreExpanded}
          changeExpansion={() => setJobChartsAreExpanded(!jobChartsAreExpanded)}
          headingText="Skill Gaps"
          subheader={'Not In Your Curriculum, In Job Posting'}
          data={filteredSkills.skillGapsData}
          isLoading={isLoading}
          displayType={chartSettings.isDisplayingCategories ? 'category' : 'default'}
        />
      </GraphContainer>
      <Header headerText="Create a New Offering with Existing Skills" />
      <StyledSelectCoursesProgramForm
        onChange={setSelectedCourses}
        soughtSkills={soughtSkills}
        displaySkillsAddedByCoursesButton
        locationStateKey="programCourseSearch"
        isEditor={hasEditorPrivileges}
      />
      {hasEditorPrivileges && (
        <StyledSelectedCoursesFooter>
          <StyledSelectedCoursesFooterContents>
            <StyledButton
              type="button"
              onClick={() => {
                history.push(`/add/program`, {
                  formValues: { courses: selectedCourses }
                });
              }}
            >
              <StyledPlus title="create new plus icon" />
              Create New Program
            </StyledButton>
          </StyledSelectedCoursesFooterContents>
        </StyledSelectedCoursesFooter>
      )}
    </>
  );
};

const WrappedParsePosting = () => {
  const methods = useForm({
    shouldFocusError: false,
    mode: 'onTouched'
  });
  return (
    <FormProvider {...methods}>
      <ParsePosting />
    </FormProvider>
  );
};

const GraphContainer = styled.div`
  display: flex;
  margin-bottom: 6rem;

  & > div:first-of-type {
    margin-right: 2rem;
  }
`;

const FiltersHeader = styled(Header)`
  flex: 1;
`;

const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
`;

const StyledModalButton = styled(ModalButton)`
  background: ${white};
  border-radius: 3rem;
  padding: 1rem 1.1rem;
  display: flex;
  color: ${black};
`;

const StyledEditIcon = styled(EditIcon)`
  stroke: ${black};
  margin-left: 0.8rem;
`;

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
`;
const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  stroke: ${darkSaphire};
`;

const StyledButton = styled(Button)`
  background: ${white};
  border: ${darkSaphire} solid 1px;
  color: ${darkSaphire};
`;

const StyledSelectedCoursesFooter = styled.div`
  background: white;
  margin-bottom: 6rem;
`;
const StyledSelectedCoursesFooterContents = styled.div`
  background: white;
  display: flex;
  justify-content: flex-end;
  margin: 0 2.5rem 0 0;
  padding: 2.5rem 0;
`;

const StyledSelectCoursesProgramForm = styled(SelectCoursesProgramForm)<{ isEditor?: boolean }>`
  border-bottom: 1px solid ${lightGray};
  padding-bottom: 0;
  margin-bottom: ${props => (props.isEditor ? `0` : `6rem`)};
`;

export default WrappedParsePosting;
