import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { getHighestAccessLevel } from 'utils/getHighestAccessLevel';

dayjs.extend(advancedFormat);

export const prepareCourses = (
  courses: CurricularData<Course>,
  requiredCourses: string[]
): PreppedCourse[] =>
  courses.data.map(
    ({ attributes: { title, courseId, skills, isPublished, updatedAt, credits }, id }) => ({
      id,
      title,
      courseId,
      credits,
      skills: skills?.length,
      isPublished,
      isRequired: requiredCourses.includes(id),
      updatedAt: dayjs(updatedAt).format('MMM Do, YYYY'),
      edit: ''
    })
  );

export const preparePrograms = (programs: CurricularData<Program>): PreppedProgram[] =>
  programs.data.map(
    ({ attributes: { title, groupType, courses, isPublished, updatedAt }, id }) => ({
      id,
      title,
      programType: groupType.label,
      courses: courses.length,
      isPublished,
      updatedAt: dayjs(updatedAt).format('MMM Do, YYYY'),
      edit: ''
    })
  );

export const prepareProgramTypes = (
  programTypes: CurricularData<ProgramType>
): PreppedProgramType[] =>
  programTypes.data.map(({ attributes: { label, groupTypeClass, groups }, id }) => ({
    id,
    title: label,
    category: groupTypeClass,
    groupCount: groups?.length || 0,
    edit: ''
  }));

export const prepareProfiles = (
  profiles: ProfilesResponse,
  sitesObject: Record<string, SiteAttributes>
): PreppedProfile[] =>
  profiles.data.map(
    ({ attributes: { name, email, accessLevels, createdAt, isInternalAdmin, isInternalOnly } }) => {
      const userReadableSites = Object.keys(accessLevels)
        .reduce<string[]>((sites, site) => {
          if (sitesObject[site]) {
            sites.push(sitesObject[site].name);
          }
          return sites;
        }, [])
        .join(', ');

      return {
        id: email,
        personal: { name, email },
        userReadableSites,
        accessLevels,
        role: getHighestAccessLevel(accessLevels),
        createdAt: dayjs(createdAt).format('MMM Do, YYYY'),
        isInternalAdmin: isInternalAdmin || false,
        isInternalOnly: isInternalOnly || false
      };
    }
  );

export const prepareSites = (
  sitesObject: Record<string, SiteAttributes>,
  profiles: ProfilesResponse
): PreppedSite[] => {
  const siteUserCounts = profiles.data.reduce<Record<string, Record<string, number>>>(
    (siteCounts, currentProfile) => {
      Object.keys(currentProfile.attributes.accessLevels).forEach(siteKey => {
        if (!siteCounts[siteKey]) {
          if (currentProfile.attributes.isInternalOnly) {
            siteCounts[siteKey] = { users: 0, internalUsers: 1 };
          } else {
            siteCounts[siteKey] = { users: 1, internalUsers: 0 };
          }
        } else if (currentProfile.attributes.isInternalOnly) {
          ++siteCounts[siteKey].internalUsers;
        } else {
          ++siteCounts[siteKey].users;
        }
      });
      return siteCounts;
    },
    {}
  );

  return Object.values(sitesObject).map(site => {
    const defaultRegionNations = site.defaultRegion ? Object.keys(site.defaultRegion) : [];

    const defaultRegionString = defaultRegionNations
      .map(
        nation =>
          `${
            defaultRegionNations.length > 1 ? `${nation.toUpperCase()}: ` : ''
          } ${site.defaultRegion[nation as AllowableNation]?.regions
            .map(region => region.name)
            .join(', ')}`
      )
      .join(', ');
    return {
      id: site.site,
      site: site.site,
      name: site.name,
      defaultRegion: defaultRegionString,
      nations: site.nations ? site.nations.join(', ') : 'us',
      createdAt: dayjs(site.createdAt).format('MMM Do, YYYY'),
      userCount: siteUserCounts[site.site]?.users.toString() || '0',
      internalUserCount: siteUserCounts[site.site]?.internalUsers.toString() || '0',
      active: !site.isDisabled,
      salesforceId: site.salesforceId
    };
  });
};
