import React from 'react';
import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as ToastCheck } from 'images/toasterCheck.svg';
import { ReactComponent as ToastError } from 'images/toasterError.svg';
import { white } from 'utils/colors';

type ToastType = 'success' | 'error';

interface ToasterProps {
  message: string;
  type: ToastType;
}

const Container = styled.div<{ type?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Message = styled.span`
  margin-left: 1rem;
  color: ${white};
`;

const StyledToastError = styled(ToastError)`
  width: 2rem;
`;

const StyledToastCheck = styled(ToastCheck)`
  width: 2rem;
`;

const Toast: React.FC<ToasterProps> = ({ type, message }) => {
  return (
    <Container>
      {type === 'success' ? <StyledToastCheck /> : <StyledToastError />}
      <Message>{message}</Message>
    </Container>
  );
};

export default Toast;
