import { getOwnProfile } from 'services/profiles';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';

const CLIENT_SIDE_ID = process.env.REACT_APP_LD_CLIENT_KEY;

interface User {
  key: string;
  email: string;
  name: string;
  custom: {
    highestLevelOfAccess: string;
    sites: string[];
    defaultSite: string;
    isInternalAdmin: boolean;
  };
  privateAttributeNames: string[];
}

export const initializeFeatureFlagging = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: CLIENT_SIDE_ID ? CLIENT_SIDE_ID : ''
  });
  return LDProvider;
};

const getFeatureFlaggingUser = async (): Promise<User> => {
  const profile = await getOwnProfile();
  const {
    id,
    attributes: { accessLevels, email, defaultSite, isInternalAdmin, name }
  } = profile.data;
  const sites = Object.keys(accessLevels);

  const accessLevelValues = accessLevels && Object.values(accessLevels);
  const uniqueAccessLevels = [...new Set(accessLevelValues)] as string[];

  const accessTypes = ['consumer', 'editor', 'admin'];
  let highestAccessLevel = '';
  accessTypes.forEach(type =>
    uniqueAccessLevels.some(level => (level.includes(type) ? (highestAccessLevel = type) : ''))
  );

  const user = {
    key: id,
    email: email || '',
    name: name || '',
    custom: {
      highestLevelOfAccess: isInternalAdmin ? 'internal admin' : highestAccessLevel,
      sites: sites || [],
      defaultSite: defaultSite || '',
      isInternalAdmin: isInternalAdmin ? isInternalAdmin : false
    },
    privateAttributeNames: ['email', 'name']
  };
  return user;
};

export const setFeatureFlaggingUser = (client: LDClient): Promise<LDFlagSet> =>
  getFeatureFlaggingUser().then(user => client.identify(user));
