import React from 'react';
import styled from '@emotion/styled';
import Pill from 'components/atoms/InfoPill';

const AlignmentBars: React.FC<{
  alignmentStrength?: 'high' | 'medium' | 'low' | undefined;
}> = ({ alignmentStrength, ...props }) => {
  return (
    <AlignmentContainer>
      <PillTitle>
        {alignmentStrength === 'high' ? 'Best ' : alignmentStrength === 'medium' ? 'Well ' : null}
        Aligned
      </PillTitle>
      <PillContainer>
        <AlignmentPill scheme="alignmentBlue" />
        {alignmentStrength !== 'low' && <AlignmentPill scheme="alignmentBlue" />}
        {alignmentStrength === 'high' && <AlignmentPill scheme="alignmentBlue" />}
      </PillContainer>
    </AlignmentContainer>
  );
};

const AlignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PillTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 0.25rem;
  margin-bottom: 1rem;
`;

const PillContainer = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 0.8rem;
`;
const AlignmentPill = styled(Pill)`
  margin: 0rem;
  height: 0.7rem;
  width: 5.5rem;
`;

export default AlignmentBars;
