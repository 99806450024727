import { useQuery, UseQueryOptions } from 'react-query';
import { fetchRelatedOccupations } from 'services/similarity';

export const useRelatedOccupations = (
  lotLevel: GranularLotLevel,
  skills: string[],
  responseLimit: number,
  useQueryOptions?: UseQueryOptions<SimilarityModelResponse[], Error>
) => {
  const enabled =
    useQueryOptions && Object.prototype.hasOwnProperty.call(useQueryOptions, 'enabled')
      ? useQueryOptions.enabled
      : true;

  return useQuery<SimilarityModelResponse[], Error>(
    [lotLevel, skills, responseLimit],
    () => fetchRelatedOccupations(lotLevel, skills, responseLimit),
    { ...(useQueryOptions || {}), enabled: skills && !!skills.length && enabled }
  );
};
