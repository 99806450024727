import React from 'react';
import styled from '@emotion/styled';

import Tabs from 'components/atoms/Tabs';
import Collapsible from 'components/atoms/Collapsible';

import { ReactComponent as CircleCheck } from 'images/checkInCircle.svg';
import { ReactComponent as MissingSkills } from 'images/InternationalNo.svg';

import { lightGray, darkSaphire, darkBlue, white } from 'utils/colors';

const Wrapper = styled.div`
  width: 80rem;
  height: 50rem;
  margin: auto;
  padding: 0 2rem 0 3rem;
`;

const Heading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
`;

const TabBar = styled.div`
  position: relative;
  margin: 1.6rem 0;
  display: inline-flex;
  background: ${lightGray};
  font-size: 1.4rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
`;

const Tab = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  padding-right: 1.3rem;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? darkSaphire : 'inherit')};
  background: ${({ isActive }) => (isActive ? white : 'inherit')};
  border-radius: 0.4rem;
  border: none;
  z-index: 1;

  svg {
    path {
      stroke: ${({ isActive }) => (isActive ? darkSaphire : darkBlue)};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const TabPanel = styled.div`
  margin-top: 1rem;
  overflow: auto;
  height: calc(100% - 12rem);

  & > div:last-of-type {
    border-bottom: none;
  }
`;

const MissingSkill = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 3rem 0;
  border-bottom: 1px solid #d9d9d9;
`;

const CourseList = styled.ul`
  list-style: none;
  font-weight: normal;
`;

const CourseSnippet = styled.li`
  padding: 1.4rem 0;
  border-bottom: 1px solid #e2e8ef;
  display: flex;
  align-items: center;
`;

const CourseTitle = styled.span`
  margin-right: 5rem;
`;

const CourseId = styled.span`
  margin-right: 30rem;
  margin-left: auto;
  font-size: 1.2rem;
`;

const AddedSkillCollapsible = styled(Collapsible)`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 3rem;

  button {
    border: none;
    color: ${darkSaphire};
    background: none;
    padding: 0.3rem;

    svg > path {
      stroke: ${darkSaphire};
    }
  }
`;

const AddedSkillsIcon = styled(CircleCheck)`
  margin-right: 0.5rem;
`;

const MissingSkillsIcon = styled(MissingSkills)`
  margin-right: 0.5rem;
`;

const JobPostingSkillsModal: React.FC<{
  addedSkills: Record<string, { title: string; courseId?: string }[]>;
  missingSkills?: string[];
}> = ({ addedSkills = {}, missingSkills = [] }) => {
  const [selectedTab, setSelectedTab] = React.useState('added-skills');
  const addedSkillEntries = Object.entries(addedSkills);
  return (
    <Wrapper>
      <Heading>Job Posting Skills</Heading>
      <Tabs onChange={tabName => setSelectedTab(tabName)}>
        {({ getTabProps, getPanelProps }) => (
          <>
            <TabBar role="tablist">
              <Tab {...getTabProps('added-skills', selectedTab)}>
                <AddedSkillsIcon />
                Added Skills ({Object.keys(addedSkills).length})
              </Tab>
              <Tab disabled={!missingSkills.length} {...getTabProps('missing-skills', selectedTab)}>
                <MissingSkillsIcon />
                Missing Skills ({missingSkills?.length})
              </Tab>
            </TabBar>
            <TabPanel {...getPanelProps('added-skills')}>
              {addedSkillEntries.length > 0 ? (
                addedSkillEntries.map(([skill, courses]) => {
                  const name = skill.replaceAll(' ', '-');
                  return (
                    <AddedSkillCollapsible
                      key={name}
                      name={name}
                      label={skill}
                      buttonText={isOpen => `${isOpen ? 'Hide' : 'Show'} Courses`}
                    >
                      <CourseList>
                        {courses.map(course => (
                          <CourseSnippet key={course.title.replaceAll(' ', '-')}>
                            <CourseTitle>{course.title}</CourseTitle>
                            <CourseId>{course.courseId}</CourseId>
                          </CourseSnippet>
                        ))}
                      </CourseList>
                    </AddedSkillCollapsible>
                  );
                })
              ) : (
                <span>Select Courses to Add Skills</span>
              )}
            </TabPanel>
            <TabPanel {...getPanelProps('missing-skills')}>
              {missingSkills?.map(skill => (
                <MissingSkill key={skill.replaceAll(' ', '-')}>{skill}</MissingSkill>
              ))}
            </TabPanel>
          </>
        )}
      </Tabs>
    </Wrapper>
  );
};

export default JobPostingSkillsModal;
