import { SheetOptions } from 'excellentexport';
import {
  EXPORT_COLUMNS,
  GROWTH_PERCENTAGE_FORMATTED_FILTER_TITLES,
  SALARY_BOOSTING_FORMATTED_FILTER_TITLES,
  SIGNIFICANCE_FORMATTED_FILTER_TITLES,
  TAUGHT_SOUGHT_FORMATTED_FILTER_TITLES
} from './constants';
import { getProgramAndCourseData, enrichProgramBenchmarks } from './services';
import { getFrequencyLevel, padArray } from './utils';
import { ProgramExcelData, Exporter } from './types';
import { LOT_LABELS } from 'utils/curricularSkills';
import { getBenchmarkTitle } from 'helpers/benchmarks';
import { TaughtState } from 'hooks/programInsights';
import { SkillSignificance } from 'helpers/benchmarkSkillInfo';
import formatNumberWithDecimal from 'utils/formatNumberWithDecimal';
import { DataFilterFn } from 'hooks/skillDetailTableDataFlowControl';

export async function getExporters({
  marketAlignmentFilters,
  programId,
  queryClient,
  site,
  skillDetails,
  activeSkillDetailFilters,
  benchmarks,
  timeframe,
  taughtAndSoughtSkills
}: ProgramExcelData) {
  const { nation, regionFilters, jpaFilters, chartSettings } = marketAlignmentFilters;
  const { courses, program, skills } = await getProgramAndCourseData(queryClient, programId, site);

  const loSkillIds: string[] = [];
  skills.skillsWithMetaData.forEach(skill => {
    if (skill.isLearningObjective) {
      loSkillIds.push(skill.id);
    }
  });

  const { customBenchmarks, lightcastOccupations } = await enrichProgramBenchmarks(
    queryClient,
    site,
    nation,
    {
      learningOutcomesSkills: loSkillIds,
      requiredSkills: skills.requiredSkillsInProgram.map(skill => skill.id),
      skillsInProgram: skills.skillsInProgram.map(skill => skill.id)
    },
    marketAlignmentFilters,
    {
      custom: program.attributes.benchmarks,
      lightcastOccupations: program.attributes.lotBenchmarks
    }
  );

  function exportProgramInfo(): Exporter {
    const {
      id,
      type,
      attributes: { title, description, url, cipCode }
    } = program;
    return {
      format: () =>
        createSheetOptions('Program Info', [[id, title, description, url, type, cipCode]])
    };
  }

  function exportFilters(): Exporter {
    const { min_years_experience, edulevels_name } = jpaFilters;
    const allRegions = regionFilters.regions.map(region => region.name).join('|');
    const educationLevel = edulevels_name?.join('|') || 'All Levels';
    let experienceLevel = 'All Years';

    if (min_years_experience) {
      const grammaticalYears = min_years_experience.upper_bound > 1 ? 'years' : 'year';
      experienceLevel = `${min_years_experience.lower_bound} to ${min_years_experience.upper_bound} ${grammaticalYears}`;
    }

    const sureGet = (map: Map<DataFilterFn, string>, filter: DataFilterFn) => {
      const string = map.get(filter);
      if (!string) {
        throw new Error(
          `Filter fn "${filter.name}" was included in the filters, but not mapped in the export`
        );
      }

      return string;
    };

    const skillLevels = chartSettings.selectedSkillLevels?.length
      ? chartSettings.selectedSkillLevels.join(' | ')
      : 'All Levels';

    const taughtOrSought =
      activeSkillDetailFilters.taughtState
        ?.map(fn => sureGet(TAUGHT_SOUGHT_FORMATTED_FILTER_TITLES, fn))
        .join(' | ') || 'Taught and Sought';

    const growthPercentage =
      activeSkillDetailFilters.growthPercentage
        ?.map(fn => sureGet(GROWTH_PERCENTAGE_FORMATTED_FILTER_TITLES, fn))
        .join(' | ') || 'All';

    const salaryBoosting =
      activeSkillDetailFilters.salaryBoostingSkillFor
        ?.map(fn => sureGet(SALARY_BOOSTING_FORMATTED_FILTER_TITLES, fn))
        .join(' | ') || 'All';

    const significance =
      activeSkillDetailFilters.significance
        ?.map(fn => sureGet(SIGNIFICANCE_FORMATTED_FILTER_TITLES, fn))
        .join(' | ') || 'All';

    const isLearningOutcomeToggle = chartSettings.skillTagFilter ? 'Yes' : 'No';

    const rows = benchmarks.map((benchmark, index) => {
      const isFirstBenchmark = index === 0;
      const label = `${getBenchmarkTitle(benchmark)} | ${
        benchmark.universalType === 'custom' ? 'Custom' : 'Occupation'
      }`;

      const cells = [
        '', // The empty cell for the page filters arrow header
        label
      ];

      if (!isFirstBenchmark) {
        return cells;
      }

      cells.push(
        allRegions,
        educationLevel,
        experienceLevel,
        skillLevels,
        `${timeframe} months`,
        isLearningOutcomeToggle,
        '', // empty cell for the skill details arrow header,
        taughtOrSought,
        growthPercentage,
        salaryBoosting,
        significance
      );

      return cells;
    });

    return { format: () => createSheetOptions('Filters', rows) };
  }

  function exportProgramSkills(): Exporter {
    const { skillsWithMetaData } = skills;

    const skillRows = skillsWithMetaData.map(skill => [
      skill.name,
      skill.id,
      skill.category?.name,
      skill.subcategory?.name,
      skill.isLearningObjective,
      skill.frequency,
      getFrequencyLevel(skill.frequency)
    ]);
    return {
      format: () => createSheetOptions('Program Skills', skillRows)
    };
  }

  function exportCoursesAndSkills(): Exporter {
    const requiredCourses = program.attributes.children
      .filter(course => course.attributes.isRequired)
      .map(course => course.id);
    const courseRows = courses.map(({ id, attributes }) => {
      const skillNames =
        attributes.skills?.map(skillId => skills.skillIdsToNames[skillId.id]) || [];
      return [
        id,
        attributes.courseId,
        attributes.title,
        attributes.description,
        attributes.url,
        attributes.credits || '',
        requiredCourses.includes(id),
        attributes.skills?.length || 0,
        ...skillNames
      ];
    });
    return {
      format: () => createSheetOptions('Courses and Skills', courseRows)
    };
  }

  function exportCoursesAndSkillIds(): Exporter {
    const requiredCourses = program.attributes.children
      .filter(course => course.attributes.isRequired)
      .map(course => course.id);
    const courseRows = courses.map(({ id, attributes }) => [
      id,
      attributes.courseId,
      attributes.title,
      attributes.description,
      attributes.url,
      attributes.credits || '',
      requiredCourses.includes(id),
      attributes.skills?.length || 0,
      ...(attributes.skills?.map(skill => skill.id) || [])
    ]);
    return {
      format: () => createSheetOptions('Courses and Skill ID', courseRows)
    };
  }

  function exportSkillDetails(): Exporter {
    const rows = skillDetails.map(skill => {
      const getFormattedSkillSignificance = (significance: null | SkillSignificance) => {
        return skill.significance
          .filter(benchmarked => {
            if (!significance) {
              return true;
            }

            return significance === benchmarked.significance;
          })
          .map(benchmarked => benchmarked.benchmarks.map(getBenchmarkTitle).join(' | '))
          .join(' | ');
      };

      const getSkillSignificanceExists = (significance: SkillSignificance) => {
        for (const benchmarked of skill.significance) {
          if (benchmarked.significance === significance) {
            return true;
          }
        }

        return false;
      };

      const getFormattedTaughtState = (state: TaughtState) => {
        switch (state) {
          case 'taught':
            return 'Taught';
          case 'sought':
            return 'Not Taught';
          case 'taught_other':
            return 'Taught in other offerings';
        }
      };

      // job counts aren't matching up on tvs charts or skill detail table with market alignment tab

      return [
        skill.id,
        skill.name,
        skill.category?.name,
        skill.subcategory?.name,
        skill.name, // skill level: can grab off skillsWithCategory on services and then add to skill in the addDetail() function
        getFormattedTaughtState(skill.taughtState),
        skill.count,
        `${formatNumberWithDecimal(skill.growthPercentage ?? 0)}%`,
        // future TODO: projected growth will go here
        !!skill.salaryBoostingSkillFor.length,
        skill.salaryBoostingSkillFor.map(getBenchmarkTitle).join(' | '), // switch this to benchmark name
        getFormattedSkillSignificance(null),
        getSkillSignificanceExists('necessary'),
        getFormattedSkillSignificance('necessary'),
        getSkillSignificanceExists('defining'),
        getFormattedSkillSignificance('defining'),
        getSkillSignificanceExists('distinguishing'),
        getFormattedSkillSignificance('distinguishing')
      ];
    });

    return {
      format: () => createSheetOptions('Skills Detail', rows)
    };
  }

  function exportLOTBenchmarks(): Exporter {
    const lightcastOccupationRows = lightcastOccupations.map(occ => [
      occ.occupation.id,
      occ.occupationName,
      LOT_LABELS[occ.occupation.type],
      `$${occ.salary}`,
      ...padArray(occ.titles, 3, undefined)
        .slice(0, 3)
        .map(title => title?.name),
      ...padArray(occ.companies, 3, undefined)
        .slice(1, 3)
        .map(com => com?.name),
      ...padArray(occ.skills, 3, undefined).map(skill => skill?.name)
    ]);
    return {
      format: () => createSheetOptions('Lightcast Occupation Benchmarks', lightcastOccupationRows)
    };
  }

  function exportCustomBenchmarks(): Exporter {
    const jpaBenchmarkRows = customBenchmarks.jpaBenchmarks.map(benchmark => [
      benchmark.title,
      false,
      benchmark.medianSalary,
      benchmark.facets.title?.join('|'),
      benchmark.facets.company?.join('|'),
      benchmark.facets.soc5?.join('|'),
      benchmark.facets.naics6?.join('|')
    ]);

    const textBenchmarkRows = customBenchmarks.textBenchmarks.map(benchmark => [
      benchmark.title,
      true,
      'N/A',
      'N/A',
      'N/A',
      'N/A',
      'N/A',
      ...benchmark.facets.skills
    ]);

    return {
      format: () =>
        createSheetOptions('Custom Benchmarks', [...jpaBenchmarkRows, ...textBenchmarkRows])
    };
  }

  function exportTaughtSkills(): Exporter {
    const taughtSkillRows = Object.values(taughtAndSoughtSkills.taughtSkillsInTargetOutcomes)
      .sort((a, b) => b.count - a.count)
      .map(skill => [
        skill.id,
        skill.name,
        skill.category?.name,
        skill.subcategory?.name,
        skill.count
      ]);

    return {
      format: () => createSheetOptions('Taught Skills', taughtSkillRows)
    };
  }

  function exportSoughtSkills(): Exporter {
    const soughtSkillRows = Object.values(taughtAndSoughtSkills.skillsNotTaughtInTargetOutcomes)
      .sort((a, b) => b.count - a.count)
      .map(skill => [
        skill.id,
        skill.name,
        skill.category?.name,
        skill.subcategory?.name,
        skill.type?.name,
        skill.taughtState === 'taught_other' ? 'Taught in other offerings' : 'Not taught',
        skill.count
      ]);

    return {
      format: () => createSheetOptions('Sought Skills', soughtSkillRows)
    };
  }

  return [
    exportProgramInfo,
    exportFilters,
    exportProgramSkills,
    exportCoursesAndSkills,
    exportCoursesAndSkillIds,
    exportLOTBenchmarks,
    exportCustomBenchmarks,
    exportTaughtSkills,
    exportSoughtSkills,
    exportSkillDetails
  ];
}

export async function exportProgramCsv(data: ProgramExcelData) {
  const exporters = await getExporters(data);
  return exporters.map(fn => fn().format());
}

function createSheetOptions(
  sheetName: keyof typeof EXPORT_COLUMNS,
  data: (string | boolean | number | undefined | null)[][]
): SheetOptions {
  return {
    name: sheetName,
    from: {
      array: [EXPORT_COLUMNS[sheetName], ...data]
    }
  };
}
