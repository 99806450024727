import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import useTabs from 'hooks/useTabs';
import { bgDarkGray, bgLightGray, lightGray, white } from 'utils/colors';
import { css, Keyframes, keyframes } from '@emotion/react';

interface DefinitionTabsProps {
  options: DefinitionTabsOption[];
  changeOnHover?: boolean;
}

const SELECTED_TAB_INDEX: Record<string, number> = {
  'Taught or Sought': 0,
  '# Job Postings': 1,
  '% Growth': 2,
  // '% Projected Growth': 3,
  'Salary Boosting Skill': 3,
  'Skill Significance': 4
};
export interface DefinitionTabsOption {
  label: string;
  definitionRender: React.ReactNode;
  imageRender?: React.ReactNode;
}

const DefinitionTabs: React.FC<DefinitionTabsProps> = ({ options, changeOnHover = false }) => {
  const [selectedTab, setSelectedTab] = useState(options[4].label);
  const { getTabProps, getPanelProps } = useTabs(tabName => setSelectedTab(tabName));

  const prevTabRef = useRef<number>(4);
  useEffect(() => {
    prevTabRef.current = SELECTED_TAB_INDEX[selectedTab];
  }, [selectedTab]);

  return (
    <MainContainer>
      <HeadersContainer>
        {'Definitions'}
        <TabsContainer role="tablist">
          {options.map((option, i) => (
            <Tab
              key={`definitions-tab-${i}`}
              {...getTabProps(option.label, selectedTab, changeOnHover)}
            >
              {option.label}
            </Tab>
          ))}
        </TabsContainer>
      </HeadersContainer>
      <StyledHr />
      <ContentContainer>
        {options.map((option, i) => {
          return selectedTab === option.label ? (
            <Panel
              slideValue={
                prevTabRef.current > SELECTED_TAB_INDEX[selectedTab]
                  ? slideInRight
                  : prevTabRef.current === SELECTED_TAB_INDEX[selectedTab]
                  ? undefined
                  : slideInLeft
              }
              key={`definitions-tab-panel-${i}`}
              {...getPanelProps(option.label)}
            >
              <ImageContainer>{option.imageRender}</ImageContainer>
              <DefinitionContainer>{option.definitionRender}</DefinitionContainer>
            </Panel>
          ) : null;
        })}
      </ContentContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${bgLightGray};
  border-radius: 0.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid ${lightGray};
`;

const HeadersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${bgDarkGray};
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-left: 1rem;
`;

const StyledHr = styled.hr`
  border: 0.1rem solid ${lightGray};
  background: ${bgDarkGray};
  width: 100%;
  margin: 0;
`;

const Tab = styled.div<{ isActive?: boolean }>`
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem;
  border: none;
  margin-bottom: -1px;
  margin-right: 2.4rem;
  cursor: pointer;
  background-color: ${props => (props.isActive ? white : undefined)};
`;

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    left: 0rem;
    transform: translateX(0%);
  }
`;

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    left: 0rem;
    transform: translateX(0%);
  }
`;

const ContentContainer = styled.div`
  min-height: 35rem;
  padding: 1rem;
  display: flex;
  overflow: hidden;
`;

const Panel = styled.div<{ slideValue?: Keyframes }>`
  display: flex;
  flex-direction: row;
  animation: ${props =>
    css`
      ${props.slideValue} 0.5s
    `};
`;

const ImageContainer = styled.div`
  margin: auto;
  padding: 5rem;
`;

const DefinitionContainer = styled.div`
  display: flex;
`;

export default DefinitionTabs;
