import { useQuery } from 'react-query';
import { fetchRelatedSkills } from 'services/skills';
import { useSkillsByIds } from './curricularSkills';

export function useRelatedSkills(skillIds: string[], options: HookOptions = {}) {
  const {
    data: relatedSkillIds,
    isLoading: relatedSkillIdsIsLoading,
    error: relatedSkillIdsError
  } = useQuery(
    ['related-skills', skillIds],
    () => fetchRelatedSkills(skillIds).then(skills => skills.data.map(({ id }) => id)),
    { enabled: !!skillIds.length && options.enabled !== false }
  );

  const {
    skills,
    isLoading: skillsIsLoading,
    error: skillsError
  } = useSkillsByIds(relatedSkillIds || [], options);

  const isLoading = relatedSkillIdsIsLoading || skillsIsLoading;
  const error = relatedSkillIdsError || skillsError;

  return { data: skills, isLoading, error };
}
