import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { darkSaphire } from 'utils/colors';

interface HeaderLinkProps extends Omit<NavLinkProps, 'to'> {
  href: string;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({ children, href, ...props }) => (
  <NavLink
    css={css`
      display: flex;
      align-items: center;
      padding: 0 1.3rem;

      & > svg {
        stroke: #4b596c;
      }

      &.active-nav > svg {
        stroke: ${darkSaphire};
      }
    `}
    to={href}
    activeClassName="active-nav"
    {...props}
  >
    {children}
  </NavLink>
);

export default HeaderLink;
