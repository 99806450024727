import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Checkmark } from 'images/courseTileCheckmark.svg';
import { borderBlue } from 'utils/colors';

const CourseTile = styled.button<{ selected?: boolean }>`
  cursor: pointer;
  background: white;
  display: block;
  width: 100%;
  text-align: left;
  box-shadow: ${props =>
    props.selected ? `0 0 0 2px ${borderBlue}` : '0 0 0 1px rgba(0, 0, 0, .05)'};
  border: none;
  border-radius: 0.4rem;
  padding: 0.8rem 2.5rem;
  position: relative;
  &:hover {
    box-shadow: 0 0 0 2px ${borderBlue};
  }
`;

const CourseTileWrapper = styled.div<{ selected?: boolean }>`
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.11);
  border-radius: 0.4rem;
  margin-bottom: 1.6rem;
`;

const StyledCheckmark = styled(Checkmark)`
  position: absolute;
  top: -0.8rem;
  left: -0.8rem;
`;

const CheckmarkWrapper = styled.div<{ show?: boolean }>`
  display: ${props => (props.show ? 'block' : 'none')};
  border: 0.8rem solid white;
  border-radius: 50%;
  height: 0;
  width: 0;
  position: absolute;
  top: -0.8rem;
  left: -0.8rem;
`;

interface SelectableTileProps {
  selectable?: boolean;
  selected?: boolean;
  newRef?: (node: React.ReactNode) => void;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  dataCy?: string;
}

const SelectableTile: React.FC<SelectableTileProps> = ({
  selected,
  newRef,
  onClick,
  children,
  className,
  selectable = true,
  dataCy
}) => (
  <CourseTileWrapper className={className} selected={selected} ref={newRef}>
    <CourseTile
      {...{ selected, onClick, type: 'button' }}
      tabIndex={selectable ? 0 : -1}
      data-cy={dataCy}
    >
      <CheckmarkWrapper show={selected}>
        <StyledCheckmark />
      </CheckmarkWrapper>
      {children}
    </CourseTile>
  </CourseTileWrapper>
);

export default SelectableTile;
