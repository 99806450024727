import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { darkSaphire } from 'utils/colors';

const InternalLink = styled(Link)`
  color: ${darkSaphire};
  text-decoration-line: none;
  border-bottom: 1px solid ${darkSaphire};
  font-size: 1.4rem;
  display: inline-block;
`;

export default InternalLink;
