import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'index.css';
import App from 'components/pages/App';
import { initializeFeatureFlagging } from 'utils/featureFlagging';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'store/authStore';
import { ProfileProvider } from 'store/profileStore';
import { initializeSentry } from 'utils/sentry';
import { NavigationBlockerProvider } from 'hooks/useNavigationBlocker';

import 'fonts/style.css';

(async () => {
  const FeatureFlaggingProvider = await initializeFeatureFlagging();
  const history = createBrowserHistory();
  initializeSentry(history);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5
      }
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <FeatureFlaggingProvider>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <ProfileProvider>
              <NavigationBlockerProvider>
                <Router history={history}>
                  <App />
                </Router>
              </NavigationBlockerProvider>
            </ProfileProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AuthProvider>
      </FeatureFlaggingProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
