import { QueryClient } from 'react-query';

export async function throttlePromises<T>(functions: (() => Promise<T>)[]): Promise<T[]> {
  const functionCount = functions.length;
  let i = 0;
  const responses: T[] = [];
  const requestNextFunction = async (): Promise<void> => {
    if (i >= functionCount) {
      return;
    }
    const index = i++;
    responses[index] = await functions[index]();
    return requestNextFunction();
  };

  await Promise.all([requestNextFunction(), requestNextFunction(), requestNextFunction()]);
  return responses;
}

type Offset = { offset?: number };

interface SearchResponse<T> {
  data: T[];
  meta: Meta;
}

export async function depaginate<T extends Offset, U>(
  queryClient: QueryClient,
  queryKeyPrefix: string,
  searchPayload: T,
  queryFunction: (payload: T) => Promise<SearchResponse<U>>
): Promise<SearchResponse<U>> {
  const response = await queryClient
    .fetchQuery([queryKeyPrefix, searchPayload], () => queryFunction(searchPayload))
    .catch(err => {
      throw new Error(err);
    });

  const { meta } = response;
  if (meta.count >= meta.totalAvailable) {
    return response;
  }

  const pageCount = Math.ceil(meta.totalAvailable / 100);
  let finalData: SearchResponse<U> = response;

  const funcs: (() => Promise<SearchResponse<U>>)[] = [];

  for (let index = 0; index < pageCount; ++index) {
    funcs.push(() =>
      queryClient
        .fetchQuery([queryKeyPrefix, { ...searchPayload, offset: index * 100 }], () =>
          queryFunction({ ...searchPayload, offset: index * 100 })
        )
        .catch(err => {
          throw new Error(err);
        })
    );
  }

  const responses = await throttlePromises(funcs);
  finalData = {
    data: responses.flatMap(res => res.data),
    meta: { count: meta.totalAvailable, totalAvailable: meta.totalAvailable }
  };

  return finalData;
}
