import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchJPATotals } from 'services/jpa';
import { throttlePromises } from 'utils/depaginate';
import useCurrentNation from './useCurrentNation';

export type Subcategories = Record<string, Skill[]>;
export type CategorizedSkills = Record<string, Subcategories>;

export const categorizeSkills = <T extends Skill>(skills: T[]) => {
  return skills.reduce<Record<string, Record<string, T[]>>>((skillsObj, currentSkill) => {
    if (currentSkill.category && currentSkill.subcategory) {
      if (skillsObj[currentSkill.category.name]) {
        if (skillsObj[currentSkill.category.name][currentSkill.subcategory.name]) {
          skillsObj[currentSkill.category.name][currentSkill.subcategory.name].push(currentSkill);
        } else {
          skillsObj[currentSkill.category.name][currentSkill.subcategory.name] = [currentSkill];
        }
      } else {
        skillsObj[currentSkill.category.name] = {
          [currentSkill.subcategory.name]: [currentSkill]
        };
      }
    } else if (skillsObj.Uncategorized && skillsObj.Uncategorized.Uncategorized) {
      skillsObj.Uncategorized.Uncategorized.push(currentSkill);
    } else {
      skillsObj.Uncategorized = { Uncategorized: [currentSkill] };
    }
    return skillsObj;
  }, {});
};

const useCategorizedSkills = <T extends Skill>(skills: T[]) =>
  useMemo(() => categorizeSkills(skills), [skills]);

export default useCategorizedSkills;

export function getCategorizedSkillCount(subcategories: Subcategories) {
  return Object.values(subcategories).reduce((count, subcategory) => {
    count += subcategory.length;
    return count;
  }, 0);
}

function getCategoriesAndSkillIds<T extends Skill>(skills: T[]) {
  return skills.reduce<Record<string, string[]>>((acc, skill) => {
    if (skill.category) {
      if (acc[skill.category.name]) {
        acc[skill.category.name].push(skill.id);
      } else {
        acc[skill.category.name] = [skill.id];
      }
    }
    return acc;
  }, {});
}

export function useCategoryPostingsBySkills(
  skills: Skill[],
  jpaQuery?: JPAOptionsFilter,
  enabled?: boolean
) {
  const [currentNation] = useCurrentNation();
  const categories = getCategoriesAndSkillIds(skills);

  const {
    data: rawData,
    isLoading,
    error
  } = useQuery(
    ['categories', Object.keys(categories), skills.map(skill => skill.id), jpaQuery, currentNation],
    () =>
      throttlePromises(
        Object.entries(categories).map(([categoryName, skillIds]) => async () => {
          const totals = await fetchJPATotals(currentNation, { ...jpaQuery, skills: skillIds }, [
            'unique_postings'
          ]);
          return {
            [categoryName]: totals.unique_postings
          };
        })
      ).then(data => data.reduce((acc, cat) => ({ ...acc, ...cat }), {})),
    {
      enabled
    }
  );

  return { data: rawData || {}, isLoading, error };
}

export function useSubcategoryPostingsSkills(skills: Skill[], jpaQuery?: JPAOptionsFilter) {
  const [currentNation] = useCurrentNation();
  const categorizedSkills = useCategorizedSkills(skills);

  const {
    data: rawData,
    isLoading,
    error
  } = useQuery(['subcategories', categorizedSkills, jpaQuery, currentNation], async () => {
    const data: Record<string, Record<string, number>> = {};
    for (const [category, subcategories] of Object.entries(categorizedSkills)) {
      const subcategoryData = await throttlePromises(
        Object.entries(subcategories).map(([subcategoryName, skillz]) => async () => {
          const skillIds = skillz.map(skill => skill.id);
          const totals = await fetchJPATotals(currentNation, { ...jpaQuery, skills: skillIds }, [
            'unique_postings'
          ]);
          return {
            [subcategoryName]: totals.unique_postings
          };
        })
      ).then(d => d.reduce((acc, cat) => ({ ...acc, ...cat }), {}));
      data[category] = subcategoryData;
    }
    return data;
  });

  return { data: rawData || {}, isLoading, error };
}
