import React from 'react';
import styled from '@emotion/styled';
import Pill from 'components/atoms/InfoPill';

const CourseTitle = styled.h4`
  line-height: 2rem;
  margin: 0 0 1rem 0;
  font-size: 1.4rem;
  font-weight: 500;
`;

const PillBox = styled.div`
  margin-bottom: 0.8rem;
  display: flex;
  gap: 0.5rem;
`;

interface CourseCardProps {
  title: string;
  courseId?: string;
  skillCount?: number;
  credits?: number | null;
}

const CourseCard: React.FC<CourseCardProps> = ({ title, courseId, skillCount, credits }) => (
  <>
    <CourseTitle>{title}</CourseTitle>
    <PillBox>
      {courseId && <Pill scheme={'blue'}>{courseId}</Pill>}
      <Pill scheme={'green'}>{skillCount || 0} Skills</Pill>
      {credits !== undefined && credits !== null && (
        <Pill scheme={'gray'}>
          {credits} Credit{credits === 1 ? '' : 's'}
        </Pill>
      )}
    </PillBox>
  </>
);

export default CourseCard;
