import React from 'react';
import styled from '@emotion/styled';

import InfoPill from 'components/atoms/InfoPill';

const Wrapper = styled.div`
  padding: 0.8rem 0;
`;

const Title = styled.div`
  margin-bottom: 1.1rem;
`;

const ProgramTile: React.FC<{ title: string; programType: string; numCourses: number }> = ({
  title,
  programType,
  numCourses
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <div>
      <InfoPill scheme="blue">{programType}</InfoPill>
      <InfoPill scheme="green">
        {numCourses} Course{numCourses !== 1 && 's'}
      </InfoPill>
    </div>
  </Wrapper>
);

export default ProgramTile;
