import { useProfileState } from 'store/profileStore';
import { useCourses, useProgram, useSkillsInProgram } from './curricularSkills';
import { ProgramPdfExportData } from 'helpers/pdfExport/program/data';
import { useCipCodeName } from './jpaHooks';
import { useFilteredTaughtVsSoughtSkills } from './programInsights';
import { formatDataFilters } from 'helpers/dataFilters';
import { usePresentationBenchmarks, useProgramBenchmarks } from './benchmarks';
import { useSites } from './siteHooks';
import { useProgramData } from 'store/programData';

export interface UseProgramPdfDataParams extends HookOptions {
  programId: string;
  jpaQuery: JPAOptionsFilter;
  regionFilter: RegionFilter;
}

export function useProgramPdfData(params: UseProgramPdfDataParams) {
  const { currentSite, isLoading: rawProfileStateIsLoading } = useProfileState();
  const { data: sitesObject, isLoading: sitesObjectIsLoading, error: siteObjectError } = useSites();
  const { chartSettings } = useProgramData();
  const profileStateIsLoading = rawProfileStateIsLoading || false;

  const siteName = sitesObject?.[currentSite].name || ''; // Need to verify that it is not empty when site finishes loading

  const {
    data: benchmarks,
    isLoading: benchmarksIsLoading,
    error: benchmarksError
  } = useProgramBenchmarks(params.programId);

  const {
    data: program,
    isLoading: programIsLoading,
    error: programError
  } = useProgram(params.programId);

  const {
    data: cipCodeName,
    isLoading: cipCodeNameIsLoading,
    error: cipCodeNameError
  } = useCipCodeName(program?.data.attributes.cipCode);

  const {
    data: { skillsInProgramNameMapping, categorizedSkills, skillFrequency },
    isLoading: skillsInProgramIsLoading,
    error: skillsInProgramError
  } = useSkillsInProgram(params.programId);

  const skillIdList = Object.keys(skillsInProgramNameMapping || {});
  const {
    data: programCourses,
    isLoading: programCoursesIsLoading,
    error: programCoursesError
  } = useCourses({
    limit: 100,
    filter: { skills: { in: skillIdList }, associatedGroups: { in: [program?.data.id || ''] } }
  });

  const {
    data: taughtAndSoughtData,
    isLoading: taughtAndSoughtIsLoading,
    error: taughtAndSoughtError
  } = useFilteredTaughtVsSoughtSkills(params.programId, params.jpaQuery, chartSettings);

  const { skills: taughtAndSoughtSkills } = taughtAndSoughtData;

  const taughtSkills = Object.values(taughtAndSoughtSkills.taughtSkillsInTargetOutcomes).map(
    skill => ({ label: skill.name, value: skill.count })
  );

  const soughtSkills = Object.values(taughtAndSoughtSkills.skillsNotTaughtInTargetOutcomes).map(
    skill => {
      return { label: skill.name, value: skill.count };
    }
  );

  const {
    data: presentationalBenchmarks,
    isLoading: presentationalBenchmarksIsLoading,
    error: presentationalBenchmarksError
  } = usePresentationBenchmarks(benchmarks, { includeSalaries: true });

  const isLoading =
    benchmarksIsLoading ||
    profileStateIsLoading ||
    programIsLoading ||
    cipCodeNameIsLoading ||
    programCoursesIsLoading ||
    presentationalBenchmarksIsLoading ||
    sitesObjectIsLoading ||
    !siteName.length ||
    skillsInProgramIsLoading ||
    taughtAndSoughtIsLoading;

  const error =
    siteObjectError ||
    benchmarksError ||
    programError ||
    cipCodeNameError ||
    programCoursesError ||
    presentationalBenchmarksError ||
    skillsInProgramError ||
    taughtAndSoughtError;

  if (isLoading || error) {
    return { data: undefined, isLoading, error };
  }

  if (!program) {
    throw new Error('Expected program to not be undefined');
  }

  if (!programCourses) {
    throw new Error('Expected program courses to be defined once they finish loading');
  }

  const data: ProgramPdfExportData = {
    siteId: currentSite,
    siteName,
    benchmarks: presentationalBenchmarks,
    cipCodeLabel: cipCodeName ? `${cipCodeName} (${program.data.attributes.cipCode})` : null,
    programName: program.data.attributes.title,
    groupTypeName: program.data.attributes.unitType?.label ?? null,
    taughtSkills,
    soughtSkills,
    programCourses,
    programSummary: program.data.attributes.description || '',
    programLearningOutcomes: program.data.attributes.learningObjectiveText || '',
    categorizedSkills,
    skillCounts: skillFrequency,
    skillsInProgram: skillsInProgramNameMapping,
    dataFilters: formatDataFilters(params.jpaQuery, params.regionFilter, chartSettings)
  };

  return {
    data,
    error,
    isLoading
  };
}
