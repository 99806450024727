import { Image } from '@react-pdf/renderer';
import React from 'react';

export interface FullImageProps {
  src: string;
  width?: number | null;
  height?: number | null;
}

export const FullImage: React.FC<FullImageProps> = ({ src, width, height }) => {
  return (
    <Image
      src={src}
      style={{
        width: width ?? undefined,
        height: height ?? undefined
      }}
    />
  );
};
