import { fetchWithCognitoToken } from './cognito';

const domain = process.env.REACT_APP_PROXY_URL;

const objectToQuerystring = (options: Record<string, string | number | boolean>) =>
  Object.entries(options)
    .filter(([, value]) => !!value)
    .reduce((qs, [key, value], i) => `${qs}${i !== 0 ? '&' : ''}${key}=${value}`, '?');

const getJPAUrl = (nation: JPANation, endpoint: string) => {
  if (!nation) {
    throw new Error('No nation found.');
  }

  const nationSection = nation === 'us' ? '' : `${nation}-`;
  const url = `${domain}/emsi-services/${nationSection}jpa/${endpoint}`;
  return url;
};

export const convertFilterFacetToAdvanced = (
  filter: string[] | JPAAdvancedFilterOptions
): JPAAdvancedFilterOptions => {
  if (Array.isArray(filter)) {
    return {
      include: filter
    };
  }
  return filter;
};

export const getSocMetadataByCodes = async (soc5ids: string[]): Promise<JPAData[]> => {
  const response = await fetchWithCognitoToken(
    `${domain}/emsi-services/jpa/taxonomies/soc5/lookup`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ ids: soc5ids })
    }
  );

  if (!response.ok) {
    throw Error(`There was an error requesting soc data from the jpa api.`);
  }

  const { data } = await response.json();
  return data;
};

const getPreviousYearFromDate = (date: Date) => ({
  start: `${date.getFullYear() - 1}-${`0${date.getMonth() + 1}`.slice(-2)}`,
  end: `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}`
});

export type JPARankingByFacet = Awaited<ReturnType<typeof getRankingByFacet>>;
// Reference to the ranking by facet JPA endpoint:
// https://api.emsidata.com/apis/job-postings#post-rankings-rankingfacet
export const getRankingByFacet = async (
  nation: JPANation,
  facet: string,
  { filter, rank }: JPAOptions
): Promise<{ buckets: JPAResponseBucket[]; totals: Partial<JPATotals> }> => {
  facet = convertGOTtoLOTFacet(facet);
  const filterArray = !filter ? [{}] : !Array.isArray(filter) ? [filter] : filter;

  const modifiedFilters = filterArray.map(f => {
    if (f.occupation) {
      f.lot_occupation = f.occupation;
      delete f.occupation;
    }
    if (f.career_area) {
      f.lot_career_area = f.career_area;
      delete f.career_area;
    }
    if (f.occupation_group) {
      f.lot_occupation_group = f.occupation_group;
      delete f.occupation_group;
    }
    if (f.specialized_occupation) {
      f.lot_specialized_occupation = f.specialized_occupation;
      delete f.specialized_occupation;
    }
    const modifiedFilter = { ...f };

    // Company "Unknown" isn't very helpful, so we always filter it when ranking by company_name
    if (facet === 'company_name') {
      const companyAdvancedFilter = f.company_name
        ? convertFilterFacetToAdvanced(f.company_name)
        : {};
      companyAdvancedFilter.exclude = [...(companyAdvancedFilter?.exclude || []), '[Unknown]'];
      modifiedFilter.company_name = companyAdvancedFilter;
    }
    return {
      // default to data from the last year
      when: getPreviousYearFromDate(new Date()),
      ...modifiedFilter
    };
  });

  const requestBody: Required<JPAOptions> = {
    filter: modifiedFilters,
    rank: {
      ...rank
    }
  };

  const response = await fetchWithCognitoToken(getJPAUrl(nation, `rankings/${facet}`), {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(requestBody)
  });

  if (!response.ok) {
    throw new Error(`Error retrieving top ${facet} from jpa`);
  }

  const result: JPARankResponse = await response.json();
  return {
    buckets: result.data.ranking.buckets,
    totals: result.data.totals
  };
};

export type JPATimeseriesRankingByFacet = Awaited<ReturnType<typeof getTimeseriesRankingByFacet>>;
export const getTimeseriesRankingByFacet = async (
  nation: JPANation,
  facet: string,
  { filter, rank, timeseries }: JPATimeseriesOptions
): Promise<{
  buckets: JPATimeseriesRankResponse['data']['ranking']['buckets'];
  totals: JPATimeseriesRankResponse['data']['totals'];
}> => {
  facet = convertGOTtoLOTFacet(facet);
  const requestBody = {
    filter,
    rank,
    timeseries
  };
  const response = await fetchWithCognitoToken(getJPAUrl(nation, `rankings/${facet}/timeseries`), {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(requestBody)
  });

  if (!response.ok) {
    throw new Error(`Error retrieving ${facet} timeseries from jpa`);
  }

  const result: JPATimeseriesRankResponse = await response.json();
  return {
    buckets: result.data.ranking.buckets,
    totals: result.data.totals
  };
};

export const searchJPAFacet = async (
  nation: JPANation,
  facet: string,
  options?: {
    q?: string;
    limit?: number;
  }
): Promise<JPAResponse[]> => {
  if (!facet) {
    return [];
  }
  facet = convertGOTtoLOTFacet(facet);

  const request = await fetchWithCognitoToken(
    getJPAUrl(nation, `taxonomies/${facet}${objectToQuerystring({ ...(options || {}) })}`),
    {
      method: 'GET',
      headers: { 'content-type': 'application/json' }
    }
  );

  if (!request.ok) {
    throw Error('Error requesting regions from jpa');
  }
  const { data: result }: { data: JPAResponse[] } = await request.json();
  return result.map(f => ({ ...f, id: f.id.toString() }));
};

export const fetchJPATotals = async (
  nation: JPANation,
  filter: JPAOptionsFilter,
  metrics: JPATotalsMetric[]
): Promise<JPATotals> => {
  const body = {
    filter: { when: getPreviousYearFromDate(new Date()), ...modifyFilter(filter) },
    metrics
  };

  const response = await fetchWithCognitoToken(getJPAUrl(nation, 'totals'), {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    throw new Error('Error retrieving skills from JPA');
  }
  const {
    data: { totals }
  } = await response.json();
  return totals;
};

export const jpaFacetLookupById = async (
  nation: JPANation,
  facet: string,
  ids: readonly string[]
): Promise<{ data: Omit<JPAResponse, 'score'>[] }> => {
  if (!ids.length) {
    return { data: [] };
  }
  facet = convertGOTtoLOTFacet(facet);
  const endpoint = `taxonomies/${facet}/lookup`;
  const response = await fetchWithCognitoToken(getJPAUrl(nation, endpoint), {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ ids })
  });

  if (!response.ok) {
    throw new Error(`Error retrieving metadata for ${facet} facet in ${nation} JPA`);
  }

  return response.json();
};

export const getCipCodeName = async (nation: JPANation, cipCode: string) => {
  const [cipInfo] = (await jpaFacetLookupById(nation, 'cip6', [cipCode])).data;

  return cipInfo?.name;
};

function convertGOTtoLOTFacet(facet: string) {
  switch (facet) {
    case 'career_area':
      return 'lot_career_area';
    case 'occupation':
      return 'lot_occupation';
    case 'occupation_group':
      return 'lot_occupation_group';
    case 'specialized_occupation':
      return 'lot_specialized_occupation';
    default:
      return facet;
  }
}

function modifyFilter(filter: JPAOptionsFilter): JPAOptionsFilter {
  const f: JPAOptionsFilter = { ...filter };
  if (f.occupation) {
    f.lot_occupation = f.occupation;
    delete f.occupation;
  }
  if (f.career_area) {
    f.lot_career_area = f.career_area;
    delete f.career_area;
  }
  if (f.occupation_group) {
    f.lot_occupation_group = f.occupation_group;
    delete f.occupation_group;
  }
  if (f.specialized_occupation) {
    f.lot_specialized_occupation = f.specialized_occupation;
    delete f.specialized_occupation;
  }
  return f;
}
