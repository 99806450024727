import React from 'react';
import styled from '@emotion/styled';
import { NavLink, Switch, Redirect, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Header from 'components/molecules/Header';
import ModalButton from 'components/molecules/ModalButton';
import UsersAdminTable from 'components/pages/UsersAdminTable';
import UserForm from 'components/organisms/UserForm';
import SiteForm from 'components/organisms/SiteForm';
import MonitoredRoute from 'components/atoms/MonitoredRoute';
import SitesAdminTable from 'components/pages/SitesAdminTable';

import { ReactComponent as UsersIcon } from 'images/users.svg';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { ReactComponent as AdminIcon } from 'images/account.svg';
import { ReactComponent as SitesIcon } from 'images/edit.svg';

import { useProfileState } from 'store/profileStore';
import { useProfiles } from 'hooks/profileHooks';
import { useSites } from 'hooks/siteHooks';
import { prepareProfiles, prepareSites } from 'helpers/prepareTables';
import { darkSaphire, white, lightGray, black } from 'utils/colors';

const Admin: React.FC = () => {
  const { addNewSite } = useFlags();
  const { data } = useProfiles();
  const { data: sitesObject } = useSites();
  const { isInternalAdmin } = useProfileState();
  const location = useLocation();

  const { sites, profiles } = React.useMemo(() => {
    if (data && sitesObject) {
      return {
        sites: prepareSites(sitesObject, data),
        profiles: prepareProfiles(data, sitesObject)
      };
    }
    return { sites: [], profiles: [] };
  }, [data, sitesObject]);

  const isSites = location.pathname.includes('sites');

  return (
    <div>
      <StyledHeader headerText="Skillabi Admin" Icon={StyledAdminIcon}>
        {isInternalAdmin && (
          <NavLinkWrapper>
            <Slider className={isSites ? 'sites' : ''} />
            <StyledNavLink to={`/admin/users`}>
              <StyledUsersIcon />
              User Admin
            </StyledNavLink>
            <StyledNavLink to={`/admin/sites`}>
              <StyledSitesIcon />
              Sites Admin
            </StyledNavLink>
          </NavLinkWrapper>
        )}
        <ButtonWrapper>
          {!isSites ? (
            <StyledModalButton
              buttonText={
                <>
                  <StyledPlus />
                  Add New User
                </>
              }
              type="button"
            >
              {closeModal => <UserForm formTitleText="Add New User" closeModal={closeModal} />}
            </StyledModalButton>
          ) : (
            addNewSite && (
              <>
                <StyledModalButton
                  buttonText={
                    <>
                      <StyledPlus />
                      Add New Site
                    </>
                  }
                  type="button"
                >
                  {closeModal => <SiteForm formTitleText="Add New Site" closeModal={closeModal} />}
                </StyledModalButton>
              </>
            )
          )}
        </ButtonWrapper>
      </StyledHeader>
      <Switch>
        <MonitoredRoute exact path={`/admin`} render={() => <Redirect to="/admin/users" />} />
        <MonitoredRoute
          exact
          path={`/admin/users`}
          render={props => <UsersAdminTable profiles={profiles} {...props} />}
        />
        <MonitoredRoute
          exact
          path={`/admin/sites`}
          render={props => <SitesAdminTable sites={sites} {...props} />}
        />
      </Switch>
    </div>
  );
};

const StyledHeader = styled(Header)`
  justify-content: space-between;

  & > *:nth-of-type(2) {
    margin-left: 3rem;
    display: flex;
    flex-grow: 1;
  }
`;

const StyledAdminIcon = styled(AdminIcon)`
  stroke: black;
  width: 3.2rem;
  height: 3.2rem;
`;
const StyledUsersIcon = styled(UsersIcon)`
  height: 2rem;
  width: unset;
`;

const StyledSitesIcon = styled(SitesIcon)`
  height: 2rem;
  width: unset;
`;

const StyledNavLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  white-space: nowrap;

  svg {
    stroke: ${black};
    margin-right: 0.8rem;
  }
`;

const Slider = styled.div`
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  z-index: 0;
  background: ${white};
  border-radius: 4px;
  height: 4rem;
  width: 15rem;
  transition: all 0.35s;

  &.sites {
    width: 15.3rem;
    left: 15.6rem;
  }
`;

const NavLinkWrapper = styled.div`
  position: relative;
  display: inline-block;
  background: ${lightGray};
  padding: 0.8rem;
  border-radius: 0.4rem;
`;
const ButtonWrapper = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const StyledModalButton = styled(ModalButton)`
  background: ${darkSaphire};
  color: ${white};
  border: none;
  border-radius: 4px;
  height: 4rem;
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  padding: 1rem 1.6rem;
  cursor: pointer;
  justify-content: center;
`;

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
`;

export default Admin;
