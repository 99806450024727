import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { mediumLightGray, darkSaphire, white } from 'utils/colors';

interface ToggleProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> {
  heightRem?: number;

  value: boolean;
  onValueChange: (newValue: boolean) => void;
  unselectedColor?: string;
  selectedColor?: string;
  className?: string;
  'data-cy'?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  heightRem = 1.6,
  value,
  onValueChange,
  unselectedColor = mediumLightGray,
  selectedColor = darkSaphire,
  className,
  'data-cy': dataCy,
  ...props
}) => {
  return (
    <div
      className={className}
      data-cy={dataCy}
      css={css`
        :focus-within {
          outline: auto 2px Highlight;
          outline: auto 5px -webkit-focus-ring-color;
          outline-offset: 0.2rem;
        }
        display: inline-block;
        position: relative;
        height: ${heightRem}rem;
        width: ${heightRem * 2}rem;
      `}
    >
      <input
        type="checkbox"
        {...props}
        checked={value}
        css={css`
          position: absolute;
          opacity: 0;
          margin: 0;
          height: 100%;
          width: 100%;
        `}
        onChange={e => onValueChange(e.target.checked)}
      />
      <div
        css={css`
          height: 100%;
          width: 100%;
          background-color: ${value ? selectedColor : unselectedColor};
          transition: background 0.2s;
          border-radius: ${heightRem / 2}rem;
          pointer-events: none;
        `}
      >
        <div
          css={css`
            left: ${value ? `calc(100% - ${heightRem}rem)` : '0'};
            position: absolute;
            height: ${heightRem}rem;
            width: ${heightRem}rem;
            border-radius: 50%;
            background-color: ${white};
            border: ${heightRem / 10}rem solid ${value ? selectedColor : unselectedColor};
            transition: left 0.35s, border 0.2s;
          `}
        />
      </div>
    </div>
  );
};

export default Toggle;
