import React from 'react';
import styled from '@emotion/styled';
import Card from 'components/atoms/Card';
import CountBadge from 'components/atoms/CountBadge';

import { ReactComponent as Remove } from 'images/x-remove.svg';
import { ReactComponent as ItemPlaceholder } from 'images/skillsByTextEmpty.svg';

import { white, borderGray, mediumGray, darkGray, darkBlue, lightGray } from 'utils/colors';

import { useScrollToBottomCallback } from 'hooks';
export interface Item {
  title: string;
  id: string;
  isRequired?: boolean;
  subtitle?: string;
  type?: string;
}

const SelectedItemsCard: React.FC<{
  className?: string;
  pluralLabel: string;
  selectedItems: readonly Item[];
  removeItem(item: Item): void;
  removeAll(): void;
  headerText: string;
}> = ({ className, pluralLabel, selectedItems, removeItem, removeAll, headerText }) => {
  const ref = React.useRef<HTMLUListElement>(null);

  const itemsSelected = !!selectedItems.length;

  const scrollToBottom = useScrollToBottomCallback(ref);

  React.useEffect(() => {
    scrollToBottom();
  }, [selectedItems]);

  return (
    <StyledCard className={className}>
      <CardHeadingWrapper>
        <Flex>
          <CardHeading>{headerText}</CardHeading>
          {itemsSelected && <StyledCount>{selectedItems.length}</StyledCount>}
        </Flex>
        {itemsSelected && (
          <PillButton
            data-cy="selected-items-card_remove-all-button"
            type="button"
            onClick={removeAll}
          >
            <StyledRemove />
            Remove All
          </PillButton>
        )}
      </CardHeadingWrapper>
      {itemsSelected ? (
        <SelectedItemsContainer ref={ref}>
          {[...selectedItems]
            .sort((a: Item, b: Item) => a.title.localeCompare(b.title))
            .map((item: Item) => (
              <ListItem key={item.id}>
                <RemoveItemButton
                  type="button"
                  aria-label={`remove ${item.title} from list`}
                  onClick={() => removeItem(item)}
                >
                  <Remove />
                </RemoveItemButton>
                <ItemInformationContainer>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemId>{item.subtitle}</ItemId>
                </ItemInformationContainer>
              </ListItem>
            ))}
        </SelectedItemsContainer>
      ) : (
        <EmptyItemsContents>
          <ItemPlaceholder />
          <HeavyText>No {pluralLabel.toLowerCase()} added yet</HeavyText>
          <div>Use the list to the left to search and add {pluralLabel.toLowerCase()}.</div>
        </EmptyItemsContents>
      )}
    </StyledCard>
  );
};

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  display: inline;
`;

const EmptyItemsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: auto;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
  height: 24.8rem;
`;

const StyledCard = styled(Card)`
  padding-top: 4rem;
  padding-bottom: 0;
`;

const CardHeadingWrapper = styled.div`
  margin-bottom: 2rem;
  white-space: nowrap;
  @media (min-width: 1370px) {
    display: flex;
  }
`;

const SelectedItemsContainer = styled.ul`
  margin: 0 0 0 -0.4rem;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  list-style-type: none;
  padding: 0 0 0 0.1rem;
`;

const RemoveItemButton = styled.button`
  background: white;
  display: inline;
  border: none;
  padding: 0.3rem;
  cursor: pointer;
`;

const PillButton = styled.button`
  background: ${white};
  border: 1px solid ${borderGray};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3rem;
  padding: 1rem 1.1rem;
  display: flex;
  align-items: center;
  color: ${darkGray};
  font-weight: 500;
  margin: 1rem 0 0 -0.8rem;
  font-size: 1.4rem;
  cursor: pointer;

  transform: scale(1);
  :active {
    transform: scale(0.95);
  }

  @media (min-width: 1370px) {
    margin: -0.5rem 0 0 auto;
  }
`;

const StyledCount = styled(CountBadge)`
  display: flex;
  margin-top: 0.2rem;
  justify-content: center;
  align-items: center;
  align-self: baseline;
`;

const HeavyText = styled.span`
  display: block;
  font-weight: 600;
  margin-top: 2rem;
`;

const ListItem = styled.li`
  font-size: 1.4rem;
  display: flex;
  min-height: 5rem;
  padding: 1rem 0;
  align-items: center;
  border-bottom: 1px solid ${lightGray};
  & > span {
    margin-left: 1.2rem;
  }
`;

const StyledRemove = styled(Remove)`
  margin-right: 0.85rem;
  height: 1.4rem;
  width: 1.4rem;
  stroke: ${darkGray};
`;

const Flex = styled.div`
  display: flex;
  margin-right: 1rem;
`;

const ItemId = styled.span`
  font-weight: 400;
  font-size: 1rem;
  color: ${darkBlue};
`;

const ItemTitle = styled.span`
  color: ${darkBlue};
  font-weight: 500;
  font-size: 1.4rem;
  margin-left: 1.5rem;
`;

const ItemInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 1rem;
  align-items: center;
`;

export default SelectedItemsCard;
