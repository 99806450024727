import React from 'react';

import {
  Grid,
  Footer,
  Column,
  Expanded,
  Row,
  ContainerPage,
  Header,
  HorizontalLine,
  Padding,
  Paragraph,
  Pill,
  SubHeader
} from '../components';

import { useProgramData } from './data';
import { View } from '@react-pdf/renderer';

export const AlignmentAnalysis: React.FC = () => {
  const { benchmarks, programName, cipCodeLabel, dataFilters } = useProgramData();

  const metaTextSections = [programName];

  if (cipCodeLabel) {
    metaTextSections.push('-', cipCodeLabel);
  }

  const cells = [
    <DataFilterCell
      key="education-level"
      header="Education Level"
      content={dataFilters.educationLevel}
    />,
    <DataFilterCell
      key="experience-level"
      header="Experience Level"
      content={dataFilters.experienceLevel}
    />,
    <DataFilterCell key="timeframe" header="Timeframe" content={dataFilters.timeframe} />,
    <DataFilterCell key="skill-level" header="Skill Level" content={dataFilters.skillLevel} />,
    <DataFilterCell key="job-postings" header="Job Postings" content={dataFilters.jobPostings} />,
    <DataFilterCell
      key="learning-outcome"
      header="Skills"
      content={dataFilters.isLearningOutcome}
    />,
    <DataFilterCell key="region" header="Region" content={dataFilters.region} />
  ];

  return (
    <ContainerPage>
      <Column gap={20}>
        <Column>
          <Header text="Market Alignment Analysis" id="alignment_analysis" />
          <Paragraph text={metaTextSections.join(' ')} bold />
        </Column>
        <Paragraph
          text={
            "This section of the report shows how well aligned this program's course content is to labor market outcomes by comparing the top skills demanded by employers for these occupations against the skills identified in this program."
          }
        />
        <SubHeader text="Labor Market Benchmarks" id="market_benchmarks" preventPropagation />
        <Paragraph
          text={
            "The following occupations, or other benchmarks, were used to determine the Taught and Sought skills seen in the following pages. The analysis compares the top in-demand skills from each benchmark against the skills identified in this program's curriculum."
          }
        />
        <Column>
          {benchmarks.map((benchmark, benchmarkIndex) => (
            <Column key={benchmarkIndex}>
              <Padding paddingVertical={10}>
                <Row gap={15}>
                  <Paragraph text={benchmark.name} />
                  <Expanded />
                  {benchmark.pills.map((pill, pillIndex) => (
                    <Pill {...pill} key={pillIndex} />
                  ))}
                </Row>
              </Padding>
              <HorizontalLine />
            </Column>
          ))}
        </Column>
        <View break>
          <Column gap={20}>
            <SubHeader text="Data Filters" id="data_filters" preventPropagation />
            <Paragraph text="The following filter options have been applied to determine both the job postings and skills used for the Market Alignment Analysis." />
            <Grid cellHeight={100} rowsCount={3} cells={cells} />
          </Column>
        </View>
      </Column>
      <Footer />
    </ContainerPage>
  );
};

const DataFilterCell: React.FC<{ header: string; content: string | string[] }> = ({
  header,
  content
}) => {
  return (
    <Padding padding={10}>
      <Column gap={10}>
        <Paragraph text={header} bold />
        {Array.isArray(content) ? (
          <Row gap={5} wrap>
            {content.map((item, index) => (
              <Pill key={index} label={item} scheme="gray" />
            ))}
          </Row>
        ) : (
          <Paragraph text={content} />
        )}
      </Column>
    </Padding>
  );
};
