import React from 'react';
import styled from '@emotion/styled';
import { darkBlue } from 'utils/colors';

const StyledLink = styled.a`
  color: ${darkBlue};
  text-decoration-line: none;
  font-size: 1.4rem;
`;

const ExternalLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  href,
  ...props
}) => {
  return (
    <StyledLink
      target="_blank"
      rel="noreferrer noopener"
      href={`${
        href?.startsWith('http://') || href?.startsWith('https://') ? '' : 'https://'
      }${href}`}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default ExternalLink;
