import React from 'react';
import styled from '@emotion/styled';

import { getBenchmarkTitle, UniversalBenchmark } from 'helpers/benchmarks';
import { lightGray, mediumGray } from 'utils/colors';
import InfoPill from '../InfoPill';

const getBenchmarkBadge = (benchmark: UniversalBenchmark) => {
  if (benchmark.universalType !== 'occupation') {
    return <></>;
  }

  if (benchmark.attributes.type === 'occupation') {
    return <InfoPill scheme="red">Occupation</InfoPill>;
  }

  if (benchmark.attributes.type === 'specialized_occupation') {
    return <InfoPill scheme="red">Specialized Occupation</InfoPill>;
  }

  return <></>;
};

export interface BenchmarkListInfoProps {
  title: string;
  benchmarks: UniversalBenchmark[];
}

const BenchmarkListInfo: React.FC<BenchmarkListInfoProps> = ({ title, benchmarks }) => {
  return (
    <Container>
      <TitleRow>
        <Title>{title}:</Title>
        <BenchmarksCount>{benchmarks.length}</BenchmarksCount>
      </TitleRow>
      <BenchmarksContainer>
        {benchmarks.map(benchmark => (
          <BenchmarkRow key={benchmark.attributes.id}>
            <BenchmarkNameCell>{getBenchmarkTitle(benchmark)}</BenchmarkNameCell>
            <BenchmarkTypeCell>{getBenchmarkBadge(benchmark)}</BenchmarkTypeCell>
          </BenchmarkRow>
        ))}
      </BenchmarksContainer>
    </Container>
  );
};

export default BenchmarkListInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const TitleRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
`;

const BenchmarksCount = styled.div`
  padding: 0 1.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  color: ${mediumGray};
  background: ${lightGray};
`;

const BenchmarksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
`;

const BenchmarkRow = styled.div`
  display: flex;
  align-items: center;
`;

const BenchmarkNameCell = styled.div`
  flex-grow: 1;
  font-size: 1.4rem;
`;

const BenchmarkTypeCell = styled.div`
  width: 17rem;
  display: flex;
  justify-content: end;
`;
