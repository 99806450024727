import { fetchWithCognitoToken } from './cognito';
const domain = process.env.REACT_APP_SITES_API_URL;

export const getSite = async (site: string): Promise<SiteAttributes> => {
  const response = await fetchWithCognitoToken(`${domain}/${site}`);

  if (!response.ok) {
    throw new Error(`Could not retrieve site.`);
  }

  const {
    data: {
      attributes: { subdomain, nations, ...attributes }
    }
  } = (await response.json()) as SingleSiteResponse;

  return {
    ...attributes,
    site: subdomain,
    nations
  };
};

export const getAllSites = async (): Promise<MultiSiteResponse> => {
  const response = await fetchWithCognitoToken(`${domain}`);

  if (!response.ok) {
    throw new Error(`Could not retrieve all sites.`);
  }

  return await response.json();
};

export const createNewSite = async (attributes: Attributes): Promise<SiteAttributes> => {
  const response = await fetchWithCognitoToken(`${domain}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'site',
        attributes
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not create a new sites.`);
  }

  return await response.json();
};

interface Attributes {
  name: string;
  subdomain: string;
  nations: string[];
  defaultRegion: DefaultRegionConverted;
}

export const updateSite = async (
  siteKey: string,
  attributes: Omit<Attributes, 'subdomain'>
): Promise<SiteAttributes> => {
  const response = await fetchWithCognitoToken(`${domain}/${siteKey}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'site',
        attributes
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not update existing site.`);
  }

  return await response.json();
};
