import React from 'react';
import styled from '@emotion/styled';

import { highlightParsedSkills, highlightRevealedSkill } from 'helpers/highlightParsedSkills';
import { PreprocessedSkill } from 'services/skills';

import { highlightYellow, contextBlue } from 'utils/colors';

const Highlights = styled.div`
  margin-bottom: 2.4rem;
  padding: 0rem 0rem;
  border-radius: 0.4rem;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  white-space: pre;
  border-radius: 0.3rem;
  position: absolute;
  line-height: 1.6;
  top: 0;
  left: 0;
`;

const HighlightableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2.4rem;
`;

const Highlight = styled.span`
  background-color: ${highlightYellow};
  padding: 0 0.1rem;
  margin: 0 -0.1rem;
`;

const OuterWrapper = styled.div`
  position: relative;
  padding-bottom: 2rem;
`;

const Context = styled.span`
  background-color: ${contextBlue};
  padding: 0 0.1rem;
  margin: 0 -0.1rem;
`;

const highlightText = (text: string, key: string) => (
  <Highlight key={`highlight-${key}`}>{text}</Highlight>
);
const contextText = (text: string, key: string) => <Context key={`context-${key}`}>{text}</Context>;
const plainText = (text: string, key: string) => <span key={`plainText-${key}`}>{text}</span>;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: string;
  highlights: PreprocessedSkill[];
  revealedSkill?: string;
  emptyText?: string;
}

const HighlightedSingleLine = React.forwardRef<HTMLInputElement, Props>(
  ({ children = '', emptyText, highlights, revealedSkill, ...props }, ref) => {
    const highlightNodes = React.useMemo(() => {
      if (!revealedSkill) {
        return highlightParsedSkills(children, highlightText, plainText, highlights);
      }
      return highlightRevealedSkill(
        children,
        plainText,
        highlightText,
        contextText,
        revealedSkill,
        highlights
      );
    }, [highlights, children, revealedSkill]);

    return (
      <OuterWrapper>
        <HighlightableWrapper>
          <Highlights {...props}>{highlightNodes}</Highlights>
        </HighlightableWrapper>
      </OuterWrapper>
    );
  }
);

export default HighlightedSingleLine;
