import React from 'react';
import { Document } from '@react-pdf/renderer';

import { Cover } from '../cover';
import { About } from '../about';
import { ContentsTable } from '../contentsTable';
import coverImage from 'images/pdfProgramCover.png';
import { toTitleCase } from 'utils/case';

import { SkillCategoryPage } from './skillCategory';
import { ProgramContentsPage } from './programContents';
import { SkillFrequencyPage } from './skillFrequency';
import { AlignmentAnalysis } from './alignmentAnalysis';
import { ProgramDataProvider, ProgramPdfExportData } from './data';
import { TvsChartPage } from './taughtAndSoughtCharts';

export interface ProgramPdfExportProps {
  data: ProgramPdfExportData;
}

export const ProgramPdfExport: React.FC<ProgramPdfExportProps> = ({ data }) => {
  return (
    <Document>
      <ProgramDataProvider data={data}>
        <Cover
          subtitle="Skillabi Program Alignment Report"
          institution={toTitleCase(data.siteName)}
          image={coverImage}
        />
        <ContentsTable />
        <AlignmentAnalysis />
        <TvsChartPage taughtOrSought="taught" />
        <TvsChartPage taughtOrSought="sought" />
        <ProgramContentsPage />
        <SkillFrequencyPage />
        <SkillCategoryPage />
        <About
          aboutText={`Skillabi translates your course content (learning outcomes, syllabi, etc.) into work-relevant skills that are recognized and valued by employers. This translation (i.e. “skillification") enables a direct apples-to-apples comparison of the skills taught at your institution and the skills sought by employers (and the learners who want to work for them).`}
          whatIsData={`At Lightcast we're passionate about providing meaningful labor market data for educational institutions and their students. Our labor market dataset includes data from three categories: government sources, online job posting activity, and professional profiles/resumes. This report leverages both our job postings data and the Lightcast Occupation Taxonomy to allow a direct comparison of curricular content to employer demand through the common language of skills.\n\nThe millions of job postings put up worldwide every day are a rich source of information about what employers are looking for: specific skills, education, and responsibilities. Lightcast pioneered the collection and big-data analysis of job postings, and gathers postings from more than 51,000 sources daily, including job boards, company websites, and other sources.\n\nThe proprietary Lightcast Occupation Taxonomy covers nearly 2,000 occupations and over 30,000 skills, allowing us to make apples-to-apples comparisons between roles, whether they're across occupations or across nations.`}
        />
      </ProgramDataProvider>
    </Document>
  );
};

export type { ProgramPdfExportData };
