import { CategorizedSkills, Subcategories } from 'hooks/useCategorizedSkills';

export interface pdfChartData {
  label: string;
  value: number;
}

export const extractChartDataFromCategorizedSkills = (
  categorizedSkills: CategorizedSkills
): pdfChartData[] => {
  const chartData = [];
  for (const categoryName in categorizedSkills) {
    if (categorizedSkills[categoryName]) {
      const subcategories: Subcategories = categorizedSkills[categoryName];
      let skillsCount = 0;
      for (const subcategoryName in subcategories) {
        if (subcategories[subcategoryName]) {
          const skills = subcategories[subcategoryName];
          skillsCount += skills.length;
        }
      }
      chartData.push({
        label: categoryName,
        value: skillsCount
      });
    }
  }
  return chartData;
};

export const segmentChartDataIntoColumns = (
  categorizedSkills: Skill[],
  numColumns: number
): Skill[][] => {
  if (numColumns <= 0) {
    throw new Error('numColumns must be greater than 0');
  }

  const numRows = Math.ceil(categorizedSkills.length / numColumns);
  const result: Skill[][] = [];

  for (let i = 0; i < numColumns; i++) {
    const start = i * numRows;
    const end = (i + 1) * numRows;
    const segment =
      i < numColumns - 1 ? categorizedSkills.slice(start, end) : categorizedSkills.slice(start);
    result.push(segment);
  }

  return result;
};
