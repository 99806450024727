import React from 'react';
import styled from '@emotion/styled';

import Loading from 'components/atoms/Loading';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50rem;
  background: white;
  margin-bottom: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0 0 0.4rem 0.4rem;
  border-top: none;
`;

const LoadingDashboardTable: React.FC = () => (
  <LoadingWrapper>
    <Loading color="gray" />
  </LoadingWrapper>
);

export default LoadingDashboardTable;
