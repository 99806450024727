/** @jsxImportSource @emotion/react */

import React, { ReactNode, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Loading from 'components/atoms/Loading';
import HoverPopover from 'components/atoms/HoverPopover';
import { DataFlowControl, DataFlowControlParams } from 'components/atoms/DataFlowControl';
import RoundSelectionFlyoutButton from 'components/atoms/RoundSelectionFlyoutButton';
import NothingPlaceholder from 'components/atoms/NothingPlaceholder';
import BenchmarkListInfo from 'components/atoms/BenchmarkListInfo';
import TaughtSoughtBar from 'components/atoms/TaughtSoughtBar';
import SkillSignificanceBadge from 'components/atoms/SkillSignificanceBadge';
import MoneyIcon from 'components/atoms/MoneyIcon';
import Table, { Column as ColumnType } from 'components/molecules/Table';
import { ReactComponent as Caret } from 'images/caret.svg';

import { SkillDetailInformation } from 'hooks/programInsights';
import formatNumberWithDecimal from 'utils/formatNumberWithDecimal';
import { useScrollFinished } from 'utils/scrollFinished';
import {
  lightGray,
  borderGray,
  danger,
  black,
  mediumLightGray,
  hoverTableBorderBlue,
  hoverTableBlue,
  darkSaphire
} from 'utils/colors';
import { SkillSignificance } from 'helpers/benchmarkSkillInfo';
import Collapsible from 'components/atoms/Collapsible';
import { uniqueId } from 'lodash';

export interface DataFlowConfig {
  taughtState: DataFlowControlParams;
  jobPostings: DataFlowControlParams;
  growthPercentage: DataFlowControlParams;
  // projectedGrowth: DataFlowControlParams;
  salaryBoosting: DataFlowControlParams;
  significance: DataFlowControlParams;
}

export interface SkillDetails {
  uncategorizedSkills: SkillDetailInformation[];
  categorizedSkills: Record<string, Record<string, SkillDetailInformation[]>>;
}
export interface SkillDetailTableProps {
  skills: SkillDetails;
  isLoading: boolean;
  dataFlowConfig: DataFlowConfig;
  isDisplayByCategory: boolean;
  categoryPostingCounts: {
    [x: string]: number;
  };
}

interface CategoryRow {
  id: string;
  name: string;
  identifier: 'subcategory' | 'category';
}

interface BuildTableColumnParams {
  id: keyof SkillDetailInformation;
  width: number;
  headerText: string;
  renderSort?(): ReactNode;
  center?: boolean;
  renderCell(row: SkillDetailInformation): ReactNode;
  addExtraPaddingToRight?: boolean;
  isLastColumn?: boolean;
}

const SkillDetailTable: React.FC<SkillDetailTableProps> = ({
  skills,
  isLoading,
  dataFlowConfig,
  isDisplayByCategory,
  categoryPostingCounts
}) => {
  const scrollMonitor = useScrollFinished({ when: !isLoading });
  const [collapsedCategories, setCollapsedCategories] = useState<string[]>([]);
  const [collapsedSubcategories, setCollapsedSubcategories] = useState<string[]>([]);

  const expandedCategorizedSkills = useMemo(() => {
    const categoryNamesSortedByPostings = [...Object.entries(categoryPostingCounts)]
      .sort(([, countA], [, countB]) => countB - countA)
      .map(val => val[0]);

    return categoryNamesSortedByPostings.reduce(
      (acc: (CategoryRow | SkillDetailInformation)[], catKey) => {
        const subcategories = skills.categorizedSkills[catKey];

        const numberOfSkillsInCategory = Object.values(subcategories).flatMap(subSkills => [
          ...subSkills
        ]).length;

        if (numberOfSkillsInCategory > 0) {
          acc.push({
            name: catKey,
            identifier: 'category',
            id: uniqueId(catKey)
          });
        }

        const subcatAndSkills = Object.keys(subcategories || []).reduce(
          (acc1: (CategoryRow | SkillDetailInformation)[], subcatKey) => {
            if (collapsedCategories.includes(catKey)) {
              return acc1;
            }

            const subcatSkills = (subcategories && subcategories[subcatKey]) || [];

            if (subcatSkills.length > 0) {
              acc1.push({
                name: subcatKey,
                identifier: 'subcategory',
                id: uniqueId(subcatKey)
              });
            }

            if (collapsedSubcategories.includes(subcatKey)) {
              return acc1;
            }

            acc1.push(...subcatSkills);
            return acc1;
          },
          []
        );

        acc.push(...subcatAndSkills);
        return acc;
      },
      []
    );
  }, [skills, collapsedCategories, collapsedSubcategories, categoryPostingCounts]);

  if (isLoading) {
    return (
      <LoadingTableContainer>
        <Loading />
      </LoadingTableContainer>
    );
  }

  return (
    <TableContainer>
      <Table
        tableProps={scrollMonitor.targetProps}
        columns={[
          buildTableColumn({
            id: 'name',
            width: 20,
            headerText: 'In-Demand Skills',
            renderCell(row) {
              return <span title={row.name}>{row.name}</span>;
            }
          }),
          buildTableColumn({
            id: 'taughtState',
            width: 8,
            center: true,
            headerText: 'Taught or Sought',
            renderCell(row) {
              return <TaughtSoughtBar state={row.taughtState} boxed />;
            },
            renderSort() {
              return <DataFlowControl {...dataFlowConfig.taughtState} />;
            }
          }),
          buildTableColumn({
            id: 'count',
            width: 7,
            center: true,
            headerText: '# Job Postings',
            renderCell(row) {
              return <>{row.count.toLocaleString()}</>;
            },
            renderSort() {
              return <DataFlowControl {...dataFlowConfig.jobPostings} />;
            }
          }),
          buildTableColumn({
            id: 'growthPercentage',
            width: 8,
            center: true,
            headerText: '% Growth',
            renderCell(row) {
              return row.growthPercentage ? createCutePercentage(row.growthPercentage) : <>-</>;
            },
            renderSort() {
              return <DataFlowControl {...dataFlowConfig.growthPercentage} />;
            }
          }),
          // buildTableColumn({
          //   id: 'projectedGrowth',
          //   width: 17,
          //   center: true,
          //   headerText: '% Projected Growth',
          //   renderCell(row) {
          //     return row.projectedGrowth ? (
          //       createCutePercentage(row.projectedGrowth.percentage, row.projectedGrowth.note)
          //     ) : (
          //       <>-</>
          //     );
          //   },
          //   renderSort() {
          //     return <DataFlowControl {...dataFlowConfig.projectedGrowth} />;
          //   }
          // }),
          buildTableColumn({
            id: 'salaryBoostingSkillFor',
            width: 7,
            center: true,
            headerText: 'Salary Boosting',
            renderCell(row) {
              if (!row.salaryBoostingSkillFor.length) {
                return <>-</>;
              }

              return (
                <Center>
                  <HoverPopover
                    renderContent={() => (
                      <BenchmarkListInfo
                        title="Salary Boosting Skill for"
                        benchmarks={row.salaryBoostingSkillFor}
                      />
                    )}
                  >
                    <MoneyIcon />
                  </HoverPopover>
                </Center>
              );
            },
            renderSort() {
              return <DataFlowControl {...dataFlowConfig.salaryBoosting} />;
            }
          }),
          buildTableColumn({
            id: 'significance',
            width: 10,
            headerText: 'Skill Significance',
            addExtraPaddingToRight: true,
            center: true,
            isLastColumn: true,
            renderCell(row) {
              if (!row.significance.length) {
                return <></>;
              }

              const children = row.significance.map(entry => (
                <SkillSignificanceBadge
                  significance={entry.significance}
                  key={entry.significance}
                />
              ));

              return (
                <HoverPopover
                  renderContent={() => (
                    <Column gap={2}>
                      {row.significance.map(entry => (
                        <BenchmarkListInfo
                          key={entry.significance}
                          title={`${getProperTitleFromSignificance(entry.significance)} Skill for`}
                          benchmarks={entry.benchmarks}
                        />
                      ))}
                    </Column>
                  )}
                >
                  <Row gap={0.5}>{children}</Row>
                </HoverPopover>
              );
            },
            renderSort() {
              return <DataFlowControl {...dataFlowConfig.significance} />;
            }
          })
        ]}
        monitorTbodyScroll
        tableCaption="Skill Detail Table"
        css={SkillTableStyles}
        data={
          isDisplayByCategory
            ? [...expandedCategorizedSkills].map(row => ({
                ...row,
                title: row.name
              }))
            : skills.uncategorizedSkills.map(skill => ({
                ...skill,
                title: skill.name
              }))
        }
        EmptyComponent={
          <NothingPlaceholder
            header="No skills to show yet"
            content="Get started by selecting skills above."
          />
        }
        renderCustomRow={row => {
          if (row.identifier === 'category' || row.identifier === 'subcategory') {
            const isRowCollapsed =
              row.identifier === 'category'
                ? collapsedCategories.includes(row.name)
                : collapsedSubcategories.includes(row.name);
            return (
              <StyledCustomTableRow
                key={row.id}
                onClick={() => {
                  if (row.identifier === 'category') {
                    if (collapsedCategories.includes(row.name)) {
                      setCollapsedCategories(collapsedCategories.filter(c => c !== row.name));
                    } else {
                      setCollapsedCategories([...collapsedCategories, row.name]);
                    }
                  }
                  if (row.identifier === 'subcategory') {
                    if (collapsedSubcategories.includes(row.name)) {
                      setCollapsedSubcategories(collapsedSubcategories.filter(c => c !== row.name));
                    } else {
                      setCollapsedSubcategories([...collapsedSubcategories, row.name]);
                    }
                  }
                }}
                data-cy={`table_row_${row.id}`}
              >
                <StyledTableCellHeader isSubHeader={!!(row.identifier === 'subcategory')}>
                  {row.name}
                  <StyledCollapsible name={row.id} isControllerOpen={!isRowCollapsed} />
                </StyledTableCellHeader>
              </StyledCustomTableRow>
            );
          }
          return;
        }}
      />
    </TableContainer>
  );
};

export default SkillDetailTable;

const getProperTitleFromSignificance = (significance: SkillSignificance) => {
  return `${significance[0].toUpperCase()}${significance.slice(1)}`;
};

const buildTableColumn = (params: BuildTableColumnParams): ColumnType<SkillDetailInformation> => ({
  id: params.id,
  renderHeader() {
    const content = (
      <TableHeaderPadding paddingRight={params.addExtraPaddingToRight ? 4 : 2}>
        <SetWidthOverflowComponent width={params.width} shouldWrap shouldCenter={params.center}>
          {params.headerText}
        </SetWidthOverflowComponent>

        {params.renderSort && (
          <CaretContainer distanceRight={params.addExtraPaddingToRight ? 2.5 : 0.5}>
            <StyledSortCaret />
          </CaretContainer>
        )}
      </TableHeaderPadding>
    );

    return (
      <TableHeader key={`${params.id}-header`}>
        {params.renderSort ? (
          <FlyoutButton isLast={params.isLastColumn} buttonContent={content}>
            {params.renderSort()}
          </FlyoutButton>
        ) : (
          content
        )}
      </TableHeader>
    );
  },
  renderCell(row) {
    return (
      <td
        key={`${params.id}-row-${row.id}`}
        data-cy={`skill-detail-table_skill-${row.id}_${params.id}-cell`}
        tabIndex={0}
      >
        <SetWidthOverflowComponent
          width={params.addExtraPaddingToRight ? params.width + 2 : params.width}
          shouldCenter={params.center}
        >
          {params.renderCell(row)}
        </SetWidthOverflowComponent>
      </td>
    );
  }
});

const createCutePercentage = (percent: number, appendText: string | null = null) => {
  const children: ReactNode[] = [`${formatNumberWithDecimal(Math.abs(percent))}%`];

  if (percent < 0) {
    children[0] = `-${children[0]}`;
  }

  if (appendText) {
    children.push(<div key="dash">-</div>, <div key="text">{appendText}</div>);
  }

  return (
    <MaybeRedText shouldBeRed={percent < 0}>
      <Row gap={0.5} centerHorizontally>
        {children}
      </Row>
    </MaybeRedText>
  );
};

const LoadingTableContainer = styled.div`
  height: 55.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid ${lightGray};
`;

const TableContainer = styled.div`
  position: relative;
  border: 1px solid ${lightGray};
  border-radius: 4px;
  font-size: 1.4rem;

  &::after {
    content: ' ';
    position: absolute;
    background: linear-gradient(to right, transparent, white);
    width: 10rem;
    top: 0;
    right: 0;
    bottom: 0;
    transition: width 300ms;
    pointer-events: none;
  }

  &.child-scroll-x-finished::after {
    width: 0;
  }

  &::before {
    content: ' ';
    position: absolute;
    background: linear-gradient(to bottom, transparent, white);
    height: 10rem;
    right: 0;
    left: 0;
    bottom: 0;
    transition: height 300ms;
    pointer-events: none;
    z-index: 1;
  }

  &.child-scroll-y-finished::before {
    height: 0;
  }
`;

const SetWidthOverflowComponent = styled.div<{
  width: number;
  shouldWrap?: boolean;
  shouldCenter?: boolean;
}>`
  width: ${({ width }) => width}rem;
  overflow: hidden;
  white-space: ${({ shouldWrap }) => (shouldWrap ? 'auto' : 'nowrap')};
  text-align: ${({ shouldCenter }) => (shouldCenter ? 'center' : 'left')};
  text-overflow: ellipsis;
`;

const SkillTableStyles = css`
  display: block;
  position: relative;
  width: fit-content;
  width: 100%;
  height: 60rem;
  overflow: auto;

  tr {
    display: flex;
    height: 5rem;
    min-width: fit-content;
    justify-content: space-between;

    td,
    th {
      display: flex;
      text-align: left;
      align-items: center;
    }

    td {
      padding: 0 3rem;
    }

    td:first-of-type {
      border-right: 1px solid ${lightGray};
    }
  }

  tr.empty-table {
    height: 100%;
  }
  tr.empty-table td {
    width: 100%;
    height: 100%;
  }
  tr.empty-table div {
    width: 100%;
    height: 50rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid ${lightGray};
  }

  thead,
  tbody {
    display: block;
    min-width: fit-content;
  }

  thead {
    background: ${borderGray};
    position: sticky;
    top: 0;
    z-index: 2;

    tr {
      border-bottom: 1px solid ${lightGray};
    }
  }
`;

const MaybeRedText = styled.div<{ shouldBeRed: boolean }>`
  color: ${({ shouldBeRed }) => (shouldBeRed ? danger : 'inherit')};
`;

const Row = styled.div<{ gap?: number; centerHorizontally?: boolean }>`
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}rem` : '0')};
  justify-content: ${({ centerHorizontally }) => (centerHorizontally ? 'center' : 'start')};
`;

const Column = styled.div<{ gap?: number; centerVertically?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? `${gap}rem` : '0')};
  justify-content: ${({ centerVertically }) => (centerVertically ? 'center' : 'start')};
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CaretContainer = styled.div<{ distanceRight: number }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ distanceRight }) => `${distanceRight}rem`};
  display: flex;
  align-items: center;
`;

const StyledSortCaret = styled(Caret)`
  width: 1rem;
  height: 1rem;
  stroke: black;
`;

const TableHeader = styled.th`
  position: relative;
`;

const TableHeaderPadding = styled.div<{ paddingRight: number }>`
  padding: 0 2rem;
  padding-right: ${({ paddingRight }) => `${paddingRight}rem`} !important;
`;

const FlyoutButton = styled(RoundSelectionFlyoutButton)<{ isLast?: boolean }>`
  & > button {
    padding: 0;
    font-weight: inherit;
    width: 100%;
    border-radius: 0;
    background: inherit;
  }

  & > div {
    font-weight: 400;
    ${props =>
      props.isLast &&
      `
      left: unset;
      right: 1rem;
    `}
  }
`;

const StyledCollapsible = styled(Collapsible)`
  & > div {
    z-index: 0;
    & > button {
      color: ${black};
      background: none;
      border: none;
    }
  }
`;

const StyledCustomTableRow = styled.tr`
  border-top: 1px solid transparent;

  &:hover {
    border-top: 1px solid ${hoverTableBorderBlue};
    border-bottom: 1px solid ${hoverTableBorderBlue} !important;

    cursor: pointer;
    background: ${hoverTableBlue};
    color: ${darkSaphire};
  }
`;

const StyledTableCellHeader = styled.th<{ isSubHeader: boolean }>`
  padding-left: ${({ isSubHeader }) => (isSubHeader ? '2rem' : '1rem')};
  color: ${({ isSubHeader }) => (isSubHeader ? mediumLightGray : black)};
`;
