import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { MutationOptions, useMutation, UseMutationResult, useQueryClient } from 'react-query';
import {
  deleteProgramType,
  invalidateProgramCaches,
  updateCurricularUnit,
  updateProgramType,
  createProgramType,
  deleteBenchmark
} from 'services/curricularSkills';

export const useUpdateProgram = (
  programId: string,
  attributes: CurricularUnitPatchBody,
  options?: MutationOptions
): UseMutationResult<unknown, Error, void, unknown> => {
  const queryClient = useQueryClient();
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<unknown, Error, void, unknown>(
    () => updateCurricularUnit(programId, attributes),
    {
      ...options,
      onError: () => toast('There was a problem updating your program.', 'error'),
      onSuccess: () => toast('Done! Program updated.'),
      onSettled: () => invalidateProgramCaches(programId, queryClient)
    }
  );
};

export const useDeleteProgramTypes = (
  programType: string,
  options?: MutationOptions
): UseMutationResult<unknown, Error, void, unknown> => {
  const queryClient = useQueryClient();
  const notification = getToastFnThatWeTreatLikeHook();

  return useMutation<unknown, Error, void, unknown>(() => deleteProgramType(programType), {
    ...options,
    onError: () => notification('An unexpected error occurred.', 'error'),
    onSuccess: async () => {
      notification('Done! Program Type deleted.');
      await queryClient.invalidateQueries('program-types');
      queryClient.removeQueries(['program-types', programType], { exact: true });
    }
  });
};

export const useUpdateProgramType = (
  programTypeId: string,
  attributes: { groupTypeClassId?: string; label?: string },
  options?: MutationOptions
): UseMutationResult<unknown, Error, void, unknown> => {
  const queryClient = useQueryClient();
  const notification = getToastFnThatWeTreatLikeHook();

  return useMutation<unknown, Error, void, unknown>(
    () => updateProgramType(programTypeId, attributes),
    {
      ...options,
      onError: () => notification('There was a problem updating this program type.', 'error'),
      onSuccess: async () => {
        notification('Done! Program Type updated.');
        await queryClient.invalidateQueries('program-types');
      }
    }
  );
};

export const useCreateProgramType = (
  attributes: { groupTypeClassId: string; label: string; site: string },
  options?: MutationOptions
): UseMutationResult<unknown, Error, void, unknown> => {
  const queryClient = useQueryClient();
  const notification = getToastFnThatWeTreatLikeHook();

  return useMutation<unknown, Error, void, unknown>(() => createProgramType(attributes), {
    ...options,
    onError: () =>
      notification('Sorry, we were unable to create that program type. Please try again.', 'error'),
    onSuccess: async () => {
      notification('Done! Program Type created.', 'success');
      await queryClient.invalidateQueries('program-types');
    }
  });
};

export const useDeleteCustomBenchmark = (
  benchmarkId: string,
  options?: MutationOptions
): UseMutationResult<unknown, Error, void, unknown> => {
  const queryClient = useQueryClient();
  const notification = getToastFnThatWeTreatLikeHook();

  return useMutation<unknown, Error, void, unknown>(() => deleteBenchmark(benchmarkId), {
    ...options,
    onError: () => notification('An unexpected error occurred.', 'error'),
    onSuccess: async (...args) => {
      options?.onSuccess && options.onSuccess(...args);
      notification('Done! Custom Benchmark deleted.');
      await queryClient.invalidateQueries(['benchmarks']);
      await queryClient.invalidateQueries('programs');
    }
  });
};
