import React, { useCallback, useMemo, useState } from 'react';
import { useQueries, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import useCurrentNation from 'hooks/useCurrentNation';
import { useCurrentSiteState } from 'store/currentSiteStore';
import { useJSONLocalStorage } from 'hooks/useLocalStorage';
import { useProgram } from 'hooks/curricularSkills';
import { DEFAULT_REGION_OBJECT } from 'components/organisms/SetupLocalStorage';

import {
  fetchJPATotals,
  getCipCodeName,
  getRankingByFacet,
  getSocMetadataByCodes,
  getTimeseriesRankingByFacet,
  jpaFacetLookupById,
  JPARankingByFacet,
  JPATimeseriesRankingByFacet,
  searchJPAFacet
} from 'services/jpa';

import { logHandledError } from 'utils/sentry';

interface Options<T> {
  nation?: JPANation;
  queryOptions?: UseQueryOptions<T, Error>;
}

const JPA_RANKING_MAX_LIMIT = 1000;

export const useJPATotals = (
  filter: JPAOptionsFilter,
  metrics: JPATotalsMetric[],
  { nation, queryOptions }: Options<JPATotals> = {}
) => {
  const [currentNation] = useCurrentNation();

  const queryNation = nation || currentNation;
  return useQuery<JPATotals, Error>(
    ['jpaTotals', queryNation, filter, metrics],
    () => fetchJPATotals(queryNation, filter, metrics),
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

export const useJPARankingByFacet = (
  facet: string,
  jpaOptions: JPAOptions,
  { nation, queryOptions }: Options<JPARankingByFacet> = {}
) => {
  const [currentNation] = useCurrentNation();

  const queryNation = nation || currentNation;
  return useQuery<JPARankingByFacet, Error>(
    ['jpaRankings', currentNation, facet, jpaOptions, queryNation],
    () => getRankingByFacet(queryNation, facet, jpaOptions),
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

export const useJPATimeseriesRankingByFacet = (
  facet: string,
  jpaOptions: JPATimeseriesOptions,
  { nation, queryOptions }: Options<JPATimeseriesRankingByFacet> = {}
) => {
  const [currentNation] = useCurrentNation();
  const queryNation = nation || currentNation;

  return useQuery<JPATimeseriesRankingByFacet, Error>(
    ['jpaTimeseriesRankings', currentNation, facet, jpaOptions, queryNation],
    () => getTimeseriesRankingByFacet(queryNation, facet, jpaOptions),
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

/** Batches JPA rankings based on the include filter array and returns the rankings aggregated.
 * @param batchSize Size of batches retrieved per query. Overwrites limit. Ensure this value does not exceed the query max limit
 * @param includes Full array of include ids to be partitioned for filtering batches
 */
export const useBatchedJPARankingByFacet = (
  facets: string[],
  includes: (string | number)[],
  jpaOptions: JPAOptions,
  { nation, queryOptions }: Options<JPARankingByFacet[]>,
  batchSize: number = JPA_RANKING_MAX_LIMIT
) => {
  const [currentNation] = useCurrentNation();
  // this ensures we don't loop redundant facets
  const uniqueFacets = [...new Set(facets)];

  const queryNation = nation || currentNation;
  return useQuery<JPARankingByFacet[], Error>(
    ['jpaRankings', uniqueFacets, includes, jpaOptions, queryNation],
    async () => {
      let responses: JPARankingByFacet[] = [];

      for (let facetIndex = 0; facetIndex < uniqueFacets.length; facetIndex++) {
        for (let lotIndex = 0; lotIndex < includes.length; lotIndex += batchSize) {
          responses = responses.concat(
            await getRankingByFacet(currentNation, uniqueFacets[facetIndex], {
              ...jpaOptions,
              rank: {
                ...jpaOptions.rank,
                limit: batchSize,
                // partition the includes array for each batch
                include: includes.slice(
                  lotIndex,
                  Math.min(lotIndex + batchSize - 1, includes.length)
                )
              }
            })
          );
        }
      }

      return responses;
    },
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

export const getBucketsFromBulkRankings = (rankings: JPARankingByFacet[]) =>
  rankings.flatMap(({ buckets }) => buckets);

export const useBulkJPARankingsRequests = (
  payloads: { facet: string; jpaOptions: JPAOptions }[]
) => {
  const [currentNation] = useCurrentNation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const [finalData, setFinalData] = React.useState<JPARankingByFacet[]>([]);
  React.useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const data = await Promise.all(
          payloads.map(payload =>
            queryClient.fetchQuery([payload], () =>
              getRankingByFacet(currentNation, payload.facet, payload.jpaOptions)
            )
          )
        );
        setFinalData(data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e instanceof Error) {
          setError(e);
          logHandledError(e);
        }
      }
    })();
  }, [payloads]);

  return {
    isLoading,
    error,
    data: finalData
  };
};

export const useSocMetadataByCodes = (
  soc5ids: string[],
  queryOptions: UseQueryOptions<JPAData[], Error> = {}
) => {
  return useQuery<JPAData[], Error>(
    ['jpaSocmetadata', soc5ids],
    () => getSocMetadataByCodes(soc5ids),
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

export const useJPAFacetLookup = (facets: JPAOptionsFilter, nation?: JPANation) => {
  const [currentNation] = useCurrentNation();
  const queries = Object.entries(facets).map(([facet, ids]) => {
    return {
      queryKey: [facet, ids],
      queryFn: () =>
        jpaFacetLookupById(nation || currentNation, facet, ids).then(data => {
          return { type: facet, data: data.data };
        })
    };
  });

  const allResponses = useQueries(queries);
  return allResponses;
};

type USRegionOption = 'nation' | 'county' | 'msa' | 'state';
type CARegionOption = 'nation' | 'pr' | 'cma' | 'cd' | 'csd';
type UKRegionOption = 'nation' | 'country' | 'nuts1' | 'nuts3' | 'lau1';

type RegionOption = USRegionOption | CARegionOption | UKRegionOption;
type SavedRegions = Record<string, RegionFilter>;

export const useJPAFilterQuery = () => {
  const [currentNation] = useCurrentNation();
  const [localRegionFilters, setLocalRegionFilters] =
    useJSONLocalStorage<SavedRegions>('regionFilters');
  const [jpaFilter, setJPAFilter] = useState<JPAOptionsFilter>({});
  const { defaultRegion: siteDefaultRegion } = useCurrentSiteState();

  const regionFilters = localRegionFilters || siteDefaultRegion || DEFAULT_REGION_OBJECT;

  const query = useMemo(() => {
    const regionFilterType: RegionOption | undefined =
      regionFilters[currentNation]?.filterType.value;
    const filters: JPAOptionsFilter = {
      ...jpaFilter
    };
    if (regionFilters && regionFilterType && regionFilterType !== 'nation') {
      filters[regionFilterType] = regionFilters[currentNation]?.regions.map(region => region.id);
    }
    return filters;
  }, [regionFilters, currentNation, jpaFilter]);

  const setNationalRegion = useCallback(
    (region: RegionFilter) =>
      setLocalRegionFilters({
        ...regionFilters,
        [currentNation]: region
      }),
    [currentNation, regionFilters, setLocalRegionFilters]
  );

  return {
    query,
    jpaFilter,
    setJPAFilter,
    setRegionFilter: setNationalRegion,
    regionFilter: regionFilters?.[currentNation]
  };
};

export const useLightcastOccupations = (
  level: LOTLevel,
  searchText?: string,
  useQueryOptions?: UseQueryOptions<JPAResponse[], Error>
) => {
  const [currentNation] = useCurrentNation();
  return useQuery<JPAResponse[], Error>(
    ['lightcast-occupations', level, searchText],
    () =>
      searchJPAFacet(currentNation, level || '', { q: searchText, limit: 2000 }).then(items =>
        items.filter(item => item.name !== 'na')
      ),
    {
      ...useQueryOptions
    }
  );
};

const useLotIDQuery = (level: LOTLevel, ids: string[]) => {
  const [currentNation] = useCurrentNation();
  return useQuery(
    ['lightcast-occupations', level, ids],
    () =>
      jpaFacetLookupById(currentNation, level, ids).then(({ data }) =>
        data.map(entry => ({ type: level, id: entry.id, name: entry.name }))
      ),
    {
      enabled: !!ids.length
    }
  );
};

export const useLightcastOccupationsByProgramId = (programId: string) => {
  const { data: program, isLoading: programIsLoading, error: programError } = useProgram(programId);
  const lcOccs = React.useMemo(() => {
    if (!program?.data.attributes.lotBenchmarks) {
      return [];
    }
    const { lotBenchmarks } = program.data.attributes;
    return lotBenchmarks.map(lot => ({ level: lot.type, id: lot.id }));
  }, [program]);

  const { career_area, occupation, occupation_group, specialized_occupation } = lcOccs.reduce<
    Record<LOTLevel, string[]>
  >(
    (levels, lot) => {
      levels[lot.level].push(lot.id);
      return levels;
    },
    { career_area: [], occupation_group: [], occupation: [], specialized_occupation: [] }
  );

  const {
    data: careerAreas,
    isLoading: careerAreasIsLoading,
    error: careerAreasError
  } = useLotIDQuery('career_area', career_area);

  const {
    data: occupationGroups,
    isLoading: occupationGroupsIsLoading,
    error: occupationGroupsError
  } = useLotIDQuery('occupation_group', occupation_group);

  const {
    data: occupations,
    isLoading: occupationsIsLoading,
    error: occupationsError
  } = useLotIDQuery('occupation', occupation);

  const {
    data: specializedOccupations,
    isLoading: specializedOccupationsIsLoading,
    error: specializedOccupationsError
  } = useLotIDQuery('specialized_occupation', specialized_occupation);

  const data = useMemo(() => {
    return [
      ...(careerAreas || []),
      ...(occupationGroups || []),
      ...(occupations || []),
      ...(specializedOccupations || [])
    ];
  }, [careerAreas, occupationGroups, occupations, specializedOccupations]);

  return {
    data,
    isLoading:
      programIsLoading ||
      careerAreasIsLoading ||
      occupationGroupsIsLoading ||
      occupationsIsLoading ||
      specializedOccupationsIsLoading,
    error:
      programError ||
      careerAreasError ||
      occupationGroupsError ||
      occupationsError ||
      specializedOccupationsError
  };
};

export const useLightcastOccupationsSalary = (type: LOTLevel, ids: string[]) => {
  const {
    data: rawData,
    isLoading,
    error
  } = useBatchedJPARankingByFacet(
    [type],
    ids,
    {
      rank: {
        extra_metrics: ['median_salary']
      }
    },
    {
      queryOptions: { enabled: !!ids.length && type === 'occupation' }
    }
  );

  const data = useMemo(() => {
    if (!rawData) {
      return {};
    }

    const salaries: Record<string, number> = {};

    for (const bucket of rawData[0].buckets) {
      // name is actually the occupation id
      salaries[bucket.name] = bucket.median_salary;
    }

    return salaries;
  }, [rawData]);

  return { data, isLoading, error };
};

/** Get occupations with rankings from 'ids' or all occupations if 'ids' is undefined*/
export const useLightcastOccupationsWithMeta = (
  type: LOTLevel,
  ids?: string[],
  extraMetrics?: JPATotalsMetric[]
) => {
  const {
    data: occupationsFromIds,
    isLoading: occupationsFromAllIdsIsLoading,
    error: occupationsFromAllIdsError
  } = useLotIDQuery(type, ids || []);
  const {
    data: allOccupations,
    isLoading: allOccupationsIsLoading,
    error: allOccupationsError
  } = useLightcastOccupations(type, undefined, { enabled: !ids?.length });

  const occupations: LightcastOccupation[] = useMemo(() => {
    if (ids) {
      return (occupationsFromIds || []).map(occupation => ({
        id: occupation.id,
        name: occupation.name,
        type
      }));
    } else {
      return (allOccupations || []).map(occupation => ({
        id: occupation.id,
        name: occupation.name,
        type
      }));
    }
  }, [occupationsFromIds, allOccupations]);

  const {
    data: extraMetricRankings,
    isLoading: extraMetricRankingsIsLoading,
    error: extraMetricRankingsError
  } = useBatchedJPARankingByFacet(
    [type],
    occupations.map(occupation => occupation.id),
    {
      rank: {
        extra_metrics: extraMetrics
      }
    },
    {
      queryOptions: { enabled: !!occupations.length }
    }
  );

  const occupationsWithMeta: LightcastOccupationWithExtraMetrics[] | undefined = useMemo(() => {
    return occupations.map(occupation => ({
      ...occupation,
      metrics: {
        ...extraMetricRankings?.[0].buckets.find(obj => obj.name === occupation.id)
      }
    }));
  }, [occupations, extraMetricRankings]);

  return {
    data: occupationsWithMeta,
    isLoading:
      occupationsFromAllIdsIsLoading || allOccupationsIsLoading || extraMetricRankingsIsLoading,
    error: occupationsFromAllIdsError || allOccupationsError || extraMetricRankingsError
  };
};

/** appends JPA totals data to an array of customJPABenchmarks */
export const useCustomBenchmarksTotals = (
  customJPABenchmarks: Benchmark[],
  includedTotals: JPATotalsMetric[],
  queryOptions: UseQueryOptions<CustomBenchmarkTotals, Error>
) => {
  return useQuery<CustomBenchmarkTotals, Error>(
    ['benchmarks', customJPABenchmarks],
    async () => {
      const response: CustomBenchmarkTotals = {};
      for (const benchmark of customJPABenchmarks) {
        if (benchmark.type === 'customJPABenchmark') {
          const totals = await fetchJPATotals(benchmark.nation, benchmark.facets, includedTotals);
          response[benchmark.id] = totals;
        }
      }
      return response;
    },
    { keepPreviousData: true, ...queryOptions }
  );
};

export const useCipCodeName = (cipCode?: string) => {
  const [nation] = useCurrentNation();

  return useQuery(['cip-code-name', cipCode], () => getCipCodeName(nation, cipCode || ''), {
    enabled: !!cipCode
  });
};
