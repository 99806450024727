import React from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import LoadingButton from 'components/atoms/LoadingButton';
import Button from 'components/atoms/Button';
import MessageModal from 'components/molecules/MessageModal';
import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { useProfileState } from 'store/profileStore';

import { bulkDeletePrograms } from 'services/curricularSkills';

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 11.4rem;
`;

const DeleteProgramModal: React.FC<{
  programIds: readonly string[];
  closeModal: () => void;
  forwardOnComplete?: boolean;
  mode?: 'selecting' | 'deselecting';
  selectedCount?: number;
  onDelete?: () => void;
}> = ({
  forwardOnComplete,
  closeModal,
  programIds,
  mode = 'selecting',
  selectedCount = 1,
  onDelete
}) => {
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const { currentSite } = useProfileState();
  const queryClient = useQueryClient();
  const history = useHistory();
  const notification = getToastFnThatWeTreatLikeHook();

  const idQuery = mode === 'selecting' ? { in: programIds } : { exclude: programIds };

  return (
    <MessageModal
      title={`Delete ${selectedCount !== 1 ? `${selectedCount} Programs` : 'this Program'}?`}
      message={`All data associated with ${
        selectedCount !== 1 ? 'these Programs' : 'this Program'
      } will be deleted. This action cannot be undone.`}
    >
      <ButtonsWrapper>
        <StyledButton scheme="outline" type="button" onClick={closeModal}>
          Cancel
        </StyledButton>
        <StyledLoadingButton
          type="button"
          scheme="danger"
          data-cy="delete-program-modal_delete-button"
          isLoading={isDeleting}
          onClick={() => {
            setIsDeleting(true);
            bulkDeletePrograms({
              site: {
                in: [currentSite]
              },
              ids: idQuery
            })
              .then(() => {
                setIsDeleting(false);
                queryClient.invalidateQueries('programs');
                programIds.forEach(programId =>
                  queryClient.removeQueries(['program', programId], { exact: true })
                );
                onDelete?.();
                closeModal();
                forwardOnComplete && history.push('/dashboard/programs');
                notification('Done! Program deleted.', 'success');
              })
              .catch(err => {
                setIsDeleting(false);
                notification(
                  'Sorry, we were unable to delete that program. Please try again.',
                  'error'
                );
                closeModal();
              });
          }}
        >
          Delete {selectedCount !== 1 ? 'Programs' : 'Program'}
        </StyledLoadingButton>
      </ButtonsWrapper>
    </MessageModal>
  );
};

export default DeleteProgramModal;
