import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';

import {
  useDropdown,
  useOnClickOutside,
  useOnKeyDownEffect,
  getToastFnThatWeTreatLikeHook
} from 'hooks';
import { SiteWithPermissions, useSites } from 'hooks/siteHooks';
import { bgLightGray, darkBlue, sailorRed, darkEmerald } from 'utils/colors';
import { updatePendoUserData } from 'utils/pendo';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as CheckMark } from 'images/schoolCheckMark.svg';
import { ReactComponent as Caret } from 'images/caret.svg';
import { ReactComponent as MagnifyingGlass } from 'images/magnifying-glass.svg';
import { ReactComponent as XRemove } from 'images/x-remove.svg';
import { useProfileDispatch, useProfileState } from 'store/profileStore';
import { updateOwnProfile } from 'services/profiles';
import { useAuthState } from 'store/authStore';
import { setFeatureFlaggingUser } from 'utils/featureFlagging';
import { useLDClient } from 'launchdarkly-react-client-sdk';

interface SiteSelectModalProps {
  message: string;
  height: number;
  className?: string;
  closeButton: boolean;
  closeModal?: () => void;
}

const SiteSelectModal: React.FC<SiteSelectModalProps> = ({
  message,
  className,
  closeButton,
  closeModal
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState('');
  const { data: sitesObject } = useSites();
  const { currentSite, isInternalAdmin } = useProfileState();
  const profileDispatch = useProfileDispatch();
  const history = useHistory();
  const notification = getToastFnThatWeTreatLikeHook();
  const { sites } = useAuthState();

  const client = useLDClient();
  const onSelectSchool = (school: School) => {
    profileDispatch({ type: 'SET_CURRENT_SITE', payload: school.value });
    updateOwnProfile({ defaultSite: school.value }).then(() => {
      updatePendoUserData(school.label);
      client && setFeatureFlaggingUser(client);
    });
    history.push('/dashboard');
    setInputValue('');
    closeModal && closeModal();
    notification('Done! Site updated.', 'success');
  };

  const { getItemProps, getListboxProps, getToggleButtonProps, handleClose, isOpen } = useDropdown(
    'sites-dropdown',
    onSelectSchool
  );

  useOnClickOutside(ref, handleClose);
  useOnKeyDownEffect('Escape', handleClose);

  return (
    <Modal closeModal={closeModal} className={className} noX={!closeButton}>
      <ModalContent>
        {message ? (
          <>
            <Welcome>{message}</Welcome>
            <Message>Which institution would you like to view?</Message>
          </>
        ) : (
          <>
            <Welcome>Welcome to</Welcome>
            <LogoContainer>
              <Logo aria-label="Skillabi Logo" />
              <Title>Skillabi</Title>
            </LogoContainer>
            <Message>Which institution would you like to view?</Message>
          </>
        )}
        <div ref={ref}>
          <DropdownButton
            {...getToggleButtonProps()}
            data-cy="site-select-modal_dropdown-button"
            aria-label="Select Institution dropdown"
          >
            {sitesObject && sitesObject[currentSite] && sitesObject[currentSite].isDisabled ? (
              <StyledX />
            ) : (
              <StyledCheckMark />
            )}
            {getSiteName(sitesObject, currentSite)}
            <StyledCaret />
          </DropdownButton>
          <div>
            {isOpen && (
              <DropdownWrapper>
                <StyledMagnifyingGlass />
                <StyledInput
                  type="text"
                  value={inputValue}
                  data-cy="site-select-modal_text-input"
                  onChange={e => setInputValue(e.target.value)}
                  placeholder="Search for an institution..."
                />

                <SchoolList
                  data-cy="site-select-modal_school-list"
                  aria-label="sorting by options"
                  {...getListboxProps()}
                >
                  {sitesObject &&
                    sites
                      ?.filter(site => !!sitesObject?.[site])
                      .sort((itemA, itemB) => {
                        return sitesObject[itemA].name
                          .toLowerCase()
                          .localeCompare(sitesObject[itemB].name.toLowerCase());
                      })
                      .filter(site => {
                        return (
                          site.toLowerCase().includes(inputValue.toLowerCase()) ||
                          sitesObject[site].name.toLowerCase().includes(inputValue.toLowerCase())
                        );
                      })
                      .map((school, index) => {
                        return (
                          <SchoolItem
                            key={sitesObject[school].site}
                            {...getItemProps(
                              {
                                value: sitesObject[school].site,
                                label: sitesObject[school].name
                              },
                              index
                            )}
                            aria-label={`select ${sitesObject[school].name}`}
                          >
                            {sitesObject[school].isDisabled ? <StyledX /> : <StyledCheckMark />}
                            <SchoolName>{getSiteName(sitesObject, school)}</SchoolName>
                            {isInternalAdmin && (
                              <SchoolSubdomain>{sitesObject[school].site}</SchoolSubdomain>
                            )}
                          </SchoolItem>
                        );
                      })}
                </SchoolList>
              </DropdownWrapper>
            )}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default SiteSelectModal;

export const getSiteName = (
  sitesObject: Record<string, SiteWithPermissions> | undefined,
  siteKey?: string
) => {
  if (!sitesObject) {
    return '';
  } else if (!siteKey) {
    return 'Click to select an institution...';
  } else if (!sitesObject[siteKey]) {
    return siteKey;
  }

  const { name, isDisabled } = sitesObject[siteKey];
  const disabledText = isDisabled ? ' (disabled)' : '';

  return `${name}${disabledText}`;
};

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  padding-bottom: 6rem;
  flex-direction: column;
  width: 72rem;
  z-index: 2;
`;

const Welcome = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Title = styled.span`
  font-size: 3rem;
  font-weight: 700;
  margin-left: 1rem;
`;

const Message = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
`;

const SchoolList = styled.ul`
  list-style: none;
  background: white;
  margin: 0;
  overflow-y: auto;
  height: 12rem;
  padding: 1rem 0 0 0;
`;

const StyledInput = styled(Input)`
  width: 33rem;
  margin-bottom: 2rem;
  position: relative;
  padding-left: 4rem;
`;

const StyledMagnifyingGlass = styled(MagnifyingGlass)`
  position: absolute;
  z-index: 1;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  height: 3rem;
  width: 3rem;
  fill: ${darkBlue};
`;

const DropdownButton = styled.button`
  background: ${bgLightGray};
  border: 0.1rem solid #edf2f6;
  border-radius: 0.4rem;
  padding: 1rem;
  width: 38rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  ::placeholder {
    font-style: italic;
  }
`;

const DropdownWrapper = styled.div`
  width: 38rem;
  background: white;
  padding: 3rem 2rem;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  position: absolute;
  z-index: 2;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const SchoolItem = styled.li`
  min-height: 3rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  &:hover {
    background: ${bgLightGray};
    border-radius: 0.4rem;
  }
`;

const StyledCheckMark = styled(CheckMark)`
  margin-right: 1rem;
  & > path {
    stroke: ${darkEmerald};
  }
`;

const StyledX = styled(XRemove)`
  margin-right: 1rem;
  & > path {
    stroke: ${sailorRed};
  }
`;

const StyledCaret = styled(Caret)`
  height: 0.5rem;
  width: 1rem;
  margin-left: auto;
  margin-right: 1rem;
  & > path {
    stroke: ${darkBlue};
  }
`;

const SchoolName = styled.span`
  flex-grow: 1;
  font-size: 1.4rem;
  font-weight: 400;
`;

const SchoolSubdomain = styled.span`
  margin: 0 1rem;
  flex-shrink: 0;
  font-size: 1.2rem;
  font-weight: 400;
  font-style: italic;
`;
