import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  fetchMappingConcepts,
  fetchMappingsMeta,
  fetchTaxonomyConcepts,
  fetchTaxonomyMeta,
  getLatestMappingVersion
} from 'services/classification';
import { useLightcastOccupationsWithMeta } from './jpaHooks';

type Options<T> = {
  version?: string;
  queryOptions?: UseQueryOptions<T, Error>;
};

export const useClassificationTaxonomyMeta = (
  taxonomy: ClassificationTaxonomies,
  { queryOptions }: Options<ClassificationTaxonomyMeta> = {}
) => {
  return useQuery<ClassificationTaxonomyMeta, Error>(
    ['classification', taxonomy, 'metaData'],
    () => fetchTaxonomyMeta(taxonomy),
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

/**  Get skills concepts. If version is not specified in options, uses latest */
export const useClassificationSkillsConcepts = (
  body?: ClassificationConceptsBody,
  { version, queryOptions }: Options<ClassificationSkill[]> = {}
) => {
  // get and cache the newest skills taxonomy version if no explicit version is passed
  const { data: skillsMeta } = useClassificationTaxonomyMeta('skills', {
    queryOptions: { enabled: !version }
  });
  const versionToUse = version || skillsMeta?.version;

  return useQuery<ClassificationSkill[], Error>(
    ['classification', versionToUse, body],
    () => fetchTaxonomyConcepts('skills', versionToUse || '', body),
    {
      keepPreviousData: true,
      ...queryOptions,
      enabled: !!versionToUse && queryOptions?.enabled !== false
    }
  );
};

export const useClassificationMappingsMeta = ({
  queryOptions
}: Options<ClassificationMappingsMetaResponse['data']> = {}) => {
  return useQuery<ClassificationMappingsMetaResponse['data'], Error>(
    ['classification', 'mappings', 'metaData'],
    () => fetchMappingsMeta(),
    {
      keepPreviousData: true,
      ...queryOptions
    }
  );
};

export const useClassificationMappings = (
  mapping: ClassificationMappings | UnversionedClassificationMappings,
  body: ClassificationMappingsBody,
  { queryOptions }: Options<ClassificationMappingsResponse['data']> = {}
) => {
  // determine typing
  const unversionedMapping = (mapping as UnversionedClassificationMappings).sourceName
    ? (mapping as UnversionedClassificationMappings)
    : undefined;
  // get and cache all mappings versions if no explicit mapping with versions is passed
  const { data: mappingsMeta } = useClassificationMappingsMeta({
    queryOptions: { enabled: !!unversionedMapping }
  });
  const newestMappingVersion = useMemo(() => {
    if (mappingsMeta && unversionedMapping) {
      return getLatestMappingVersion(mapping as UnversionedClassificationMappings, mappingsMeta);
    }
  }, [unversionedMapping, mappingsMeta]);

  const mappingToUse = newestMappingVersion || (mapping as string);

  return useQuery<ClassificationMappingsResponse['data'], Error>(
    ['classification', mappingToUse, body],
    () => fetchMappingConcepts(mappingToUse, body),
    {
      keepPreviousData: true,
      ...queryOptions,
      enabled:
        (unversionedMapping ? !!newestMappingVersion : true) && queryOptions?.enabled !== false
    }
  );
};

/** Get occupations with metadata by cip code. If cipCode is undefined, queries won't run and an empty array will return. */
export const useCipRelatedOccupations = (cipCode?: string, extraMetrics?: JPATotalsMetric[]) => {
  const {
    data: occupationsMappings,
    isLoading: occupationsMappingsIsLoading,
    error: occupationsMappingsError
  } = useClassificationMappings(
    { sourceName: 'cip_2020', destinationName: 'occupations' },
    { ids: [cipCode || ''] },
    {
      queryOptions: { enabled: !!cipCode }
    }
  );

  const occupationIds = (cipCode ? occupationsMappings?.[cipCode] : []) || [];

  const {
    data: occupationsWithMeta,
    isLoading: occupationsWithMetaIsLoading,
    error: occupationsWithMetaError
  } = useLightcastOccupationsWithMeta('occupation', occupationIds, extraMetrics);

  return {
    data: occupationsWithMeta,
    isLoading: occupationsWithMetaIsLoading || occupationsMappingsIsLoading,
    error: occupationsWithMetaError || occupationsMappingsError
  };
};
