import React from 'react';
import styled from '@emotion/styled';
import CountBadge from 'components/atoms/CountBadge';

const StyledHeader = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  margin: 0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(Flex)<{ headerLevel?: string }>`
  justify-content: space-between;
  align-items: center;
  ${({ headerLevel }) => {
    switch (headerLevel) {
      case undefined:
      case 'h2':
        return 'margin: 2rem 0;';
      case 'h3':
        return 'margin: 1rem 0;';
      default:
        return '';
    }
  }}
`;

const HeaderTextContainer = styled(Flex)`
  align-items: center;

  & > svg {
    margin-right: 1rem;
  }
`;

const ChildContainer = styled(Flex)`
  align-self: start;
  margin-left: 1rem;
`;

const styleIcon = (Icon: React.FC) => styled(Icon)`
  height: 2.5rem;
  margin: 0 1rem -0.3rem 0;
`;

interface HeaderProps {
  className?: string;
  count?: number;
  headerText: string;
  Icon?: React.FC;
  headerLevel?: 'h2' | 'h3' | 'h4' | 'h5';
  'data-cy'?: string;
}

const Header: React.FC<HeaderProps> = ({
  headerText,
  headerLevel,
  count,
  children,
  Icon,
  className,
  'data-cy': dataCy
}) => (
  <Wrapper className={className} headerLevel={headerLevel}>
    <HeaderTextContainer>
      <StyledHeader as={headerLevel} data-cy={dataCy}>
        {Icon && React.createElement(styleIcon(Icon))}
        {headerText}
      </StyledHeader>
      {count !== undefined && <CountBadge>{count.toLocaleString()}</CountBadge>}
    </HeaderTextContainer>
    <ChildContainer>{children}</ChildContainer>
  </Wrapper>
);

export default Header;
