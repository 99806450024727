import React from 'react';
import styled from '@emotion/styled';

import Card from 'components/atoms/Card';
import { LabeledToggle } from 'components/atoms/LabeledToggle';
import CountBadge from 'components/atoms/CountBadge';
import InfoPill from 'components/atoms/InfoPill';
import Header from 'components/molecules/Header';
import Informative from 'components/molecules/Informative';
import SkillCategoryDistribution from 'components/organisms/SkillCategoryDistribution';

import getCountDistribution from 'helpers/countDistribution';

import { ReactComponent as SkillsIcon } from 'images/skillsIcon.svg';
import { ReactComponent as FrequencyIcon } from 'images/lightcastOccupation.svg';
import { ReactComponent as CategoryIcon } from 'images/textAlignIcon.svg';

import { useVisible } from 'hooks';

import {
  darkerBorderGray,
  darkGray,
  darkSaphire,
  lightGreen,
  white,
  lightGray,
  skyBlue,
  fog,
  black,
  darkBlue
} from 'utils/colors';
import useTabs from 'hooks/useTabs';
import { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { useProgramData, useProgramDataDispatch } from 'store/programData';
import { useProgramHasLearningOutcomes } from 'hooks/curricularSkills';

const mapSkillsWithCounts = (
  skillFrequency: Record<string, string[]>,
  color: 'green' | 'blue' | 'gray',
  skillsInProgram: Record<string, string>
) =>
  Object.entries(skillFrequency).map(([count, skills]) => (
    <div key={`skills-in-${count}-courses`}>
      <SkillHeader>
        {count} Course{count === '1' ? '' : 's'}
      </SkillHeader>
      <SkillList aria-label={`skills in ${count} courses`}>
        {skills.map(skill => (
          <SkillChip as="li" scheme={color} key={skill} aria-label={skillsInProgram[skill]}>
            <StyledSkillIcon />
            {skillsInProgram[skill]}
          </SkillChip>
        ))}
      </SkillList>
    </div>
  ));

const SkillDistribution: React.FC<{
  programId: string;
  skillsInProgram: Record<string, string>;
  skillCounts?: Record<string, number>;
  categorizedSkills?: CategorizedSkills;
}> = ({ programId, skillCounts, skillsInProgram, categorizedSkills }) => {
  const [isAllVisible, setIsAllVisible] = React.useState(false);
  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const [highVisible, highRef] = useVisible<HTMLDivElement>(scrollParentRef);
  const [midVisible, midRef] = useVisible<HTMLDivElement>(scrollParentRef);
  const [lowVisible, lowRef] = useVisible<HTMLDivElement>(scrollParentRef);
  const [selectedTab, setSelectedTab] = React.useState('frequency');
  const { getTabProps, getPanelProps } = useTabs(tabName => setSelectedTab(tabName));

  const { chartSettings } = useProgramData();
  const programDataDispatch = useProgramDataDispatch();

  const { data: programHasLearningOutcomes } = useProgramHasLearningOutcomes(programId);

  const frequency = React.useMemo(() => {
    if (skillCounts) {
      // We do this first step to eliminate any old skills that no longer are in the taxonomy (id still exists but no valid name)
      // Otherwise we get inaccurate counts and empty skill chips in the display.
      const filteredCounts = Object.entries(skillCounts)
        .filter(([skillId]) => skillsInProgram[skillId])
        .reduce((filteredSkillCounts: Record<string, number>, [skillId, count]) => {
          filteredSkillCounts[skillId] = count;
          return filteredSkillCounts;
        }, {});
      return getCountDistribution(filteredCounts);
    }
  }, [skillCounts, skillsInProgram]);

  const [highFrequency, mediumFrequency, lowFrequency] = React.useMemo(
    () =>
      frequency && skillsInProgram
        ? [
            mapSkillsWithCounts(frequency.highFrequency, 'green', skillsInProgram).reverse(),
            mapSkillsWithCounts(frequency.mediumFrequency, 'blue', skillsInProgram).reverse(),
            mapSkillsWithCounts(frequency.lowFrequency, 'gray', skillsInProgram).reverse()
          ]
        : [],
    [frequency, skillsInProgram]
  );

  const highFrequencyCount = Object.values(frequency?.highFrequency || {})
    .map(skillArray => skillArray)
    .flat().length;
  const mediumFrequencyCount = Object.values(frequency?.mediumFrequency || {})
    .map(skillArray => skillArray)
    .flat().length;
  const lowFrequencyCount = Object.values(frequency?.lowFrequency || {})
    .map(skillArray => skillArray)
    .flat().length;

  const handleScroll = () => {
    if (scrollParentRef.current && scrollParentRef.current.scrollTop === 0) {
      setIsAllVisible(true);
    } else {
      setIsAllVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  });

  return (
    <StyledCard>
      <StyledHeaderAndTabBar>
        <StyledHeader headerText="Skill Distribution In This Program" headerLevel="h3" />
        <TabBar role="tablist">
          <Slider {...{ selectedTab }} />
          <Tab {...getTabProps('frequency', selectedTab)}>
            <StyledFrequencyIcon className="frequency" />
            Frequency
          </Tab>
          <Tab {...getTabProps('category', selectedTab)}>
            <StyledCategoryIcon className="category" />
            Category
          </Tab>
        </TabBar>

        <Spacer />

        <LearningOutcomesRow opacity={programHasLearningOutcomes ? 1 : 0.5}>
          <LearningOutcomesToggleLabel>
            Show Only Learning Outcome Skills
          </LearningOutcomesToggleLabel>

          <LearningOutcomesToggleRow>
            <LabeledToggle
              disabled={!programHasLearningOutcomes}
              offLabel="Off"
              onLabel="On"
              value={chartSettings.skillTagFilter === 'isLearningObjective'}
              onValueChange={() =>
                programDataDispatch({
                  type: 'SET_CHART_SETTINGS',
                  value: {
                    ...chartSettings,
                    skillTagFilter: !chartSettings.skillTagFilter
                      ? 'isLearningObjective'
                      : undefined
                  }
                })
              }
            />

            <Informative
              renderContent={() => (
                <LearningOutcomesToggleExplanation>
                  {
                    'Filter by skills tagged in Learning Outcome text. This option is available if Learning Outcome text has been added for a program or courses within a program.'
                  }
                </LearningOutcomesToggleExplanation>
              )}
            />
          </LearningOutcomesToggleRow>
        </LearningOutcomesRow>
      </StyledHeaderAndTabBar>
      <div {...getPanelProps('frequency')}>
        <Flex>
          <ButtonContainer>
            <FrequencyButton
              isActive={isAllVisible}
              onClick={() => {
                scrollParentRef.current?.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
              }}
            >
              All Skills
              <ColoredCountBadge>
                {(highFrequencyCount + mediumFrequencyCount + lowFrequencyCount).toLocaleString()}
              </ColoredCountBadge>
            </FrequencyButton>
            <FrequencyButton
              isActive={!isAllVisible && highVisible}
              onClick={() => {
                scrollParentRef.current?.scrollTo({ behavior: 'smooth', top: 1, left: 0 });
              }}
            >
              High Frequency
              <ColoredCountBadge color="green">
                {highFrequencyCount.toLocaleString()}
              </ColoredCountBadge>
              <Tooltip className="toolTip">Skill appears in 8 or more courses.</Tooltip>
            </FrequencyButton>
            <FrequencyButton
              isActive={!isAllVisible && !highVisible && midVisible}
              onClick={() => {
                if (midRef.current) {
                  scrollParentRef.current?.scrollTo({
                    behavior: 'smooth',
                    top: midRef.current.offsetTop - scrollParentRef.current.offsetTop,
                    left: 0
                  });
                }
              }}
            >
              Mid Frequency
              <ColoredCountBadge color="blue">
                {mediumFrequencyCount.toLocaleString()}
              </ColoredCountBadge>
              <Tooltip className="toolTip">Skill appears in 4 to 7 courses.</Tooltip>
            </FrequencyButton>
            <FrequencyButton
              isActive={!isAllVisible && !highVisible && !midVisible && lowVisible}
              onClick={() => {
                if (lowRef.current) {
                  scrollParentRef.current?.scrollTo({
                    behavior: 'smooth',
                    top: lowRef.current.offsetTop - scrollParentRef.current.offsetTop,
                    left: 0
                  });
                }
              }}
            >
              Low Frequency
              <ColoredCountBadge>{lowFrequencyCount.toLocaleString()}</ColoredCountBadge>
              <Tooltip className="toolTip">Skill appears in 1 to 3 courses.</Tooltip>
            </FrequencyButton>
          </ButtonContainer>
          <SkillsContainer ref={scrollParentRef} tabIndex={0}>
            {frequency && skillsInProgram && (
              <>
                <section ref={highRef} aria-label="high frequency skills">
                  <InfoPill scheme="green">High Frequency</InfoPill>
                  <Indent color="green">{highFrequency}</Indent>
                </section>
                <section ref={midRef} aria-label="mid frequency skills">
                  <InfoPill scheme="blue">Mid Frequency</InfoPill>
                  <Indent color="blue">{mediumFrequency}</Indent>
                </section>
                <section ref={lowRef} aria-label="low frequency skills">
                  <InfoPill scheme="gray">Low Frequency</InfoPill>
                  <Indent>{lowFrequency}</Indent>
                </section>
              </>
            )}
          </SkillsContainer>
        </Flex>
      </div>
      <div {...getPanelProps('category')}>
        <SkillCategoryDistribution categorizedSkills={categorizedSkills} />
      </div>
    </StyledCard>
  );
};

export default SkillDistribution;

const StyledCard = styled(Card)`
  margin-bottom: 4rem;
  padding: 3rem 4rem;
`;

const StyledHeader = styled(Header)`
  padding-right: 1rem;
  h3 {
    margin-top: 0;
    font-size: 2rem;
    font-weight: 600;
  }
`;

const SkillHeader = styled.span`
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const Indent = styled.div<{ color?: 'green' | 'blue' | 'gray' }>`
  border-left: 1px solid
    ${({ color }) => (color === 'green' ? lightGreen : color === 'blue' ? skyBlue : lightGray)};
  margin: 1.5rem 0 5rem 1.5rem;
  padding-left: 1.5rem;
`;

const SkillChip = styled(InfoPill)`
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  font-weight: normal;
  margin: 0 0.7rem 0.7rem 0;
  color: ${darkGray};
  font-size: 1.4rem;
`;

const StyledSkillIcon = styled(SkillsIcon)`
  margin-right: 0.5rem;
`;

const FrequencyButton = styled.button<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  width: 19.5rem;
  text-align: left;
  padding: 1.5rem;
  margin-bottom: 0.2rem;
  background: ${({ isActive }) => (isActive ? fog : white)};
  color: ${({ isActive }) => (isActive ? darkSaphire : 'inherit')};
  border: none;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: bold;
  &:hover .toolTip {
    transition-delay: 1s;
    visibility: visible;
  }
`;
const Tooltip = styled.span`
  visibility: hidden;
  background-color: ${black};
  color: ${white};
  text-align: center;
  border-radius: 0.6rem;
  padding: 0.3rem 0;
  position: absolute;
  bottom: 80%;
  left: 0;
`;
const ColoredCountBadge = styled(CountBadge)<{ color?: 'green' | 'blue' | 'gray' }>`
  background: ${({ color }) =>
    color === 'green' ? lightGreen : color === 'blue' ? skyBlue : lightGray};
`;

const Flex = styled.div`
  display: flex;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: column;
  max-height: 40rem;
  overflow: auto;
`;

const SkillsContainer = styled.div`
  max-height: 40rem;
  overflow: auto;
  margin-left: 3.2rem;
  border-left: 1px solid ${darkerBorderGray};
  padding-left: 3.2rem;
  flex: 1;
`;

const SkillList = styled.ul`
  padding: 0;
  margin: 0;
`;

const TabBar = styled.div`
  position: relative;
  display: inline-flex;
  background: ${lightGray};
  font-size: 1.4rem;
  padding: 0.6rem;
  border-radius: 0.4rem;
`;

const Slider = styled.div<{ selectedTab: string }>`
  position: absolute;
  background: ${white};
  top: 0.4rem;
  bottom: 0.4rem;
  border-radius: 0.4rem;
  transition: all 0.2s ease-in-out;

  ${({ selectedTab }) => {
    switch (selectedTab) {
      case 'frequency': {
        return {
          left: '0.5rem',
          width: '11.2rem'
        };
      }
      case 'category': {
        return {
          left: '11.7rem',
          width: '10.7rem'
        };
      }
    }
  }}
`;

const Tab = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  padding-right: 1.3rem;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? darkSaphire : 'inherit')};
  z-index: 1;

  svg {
    &.frequency,
    &.category {
      stroke: ${({ isActive }) => (isActive ? darkSaphire : black)};
    }
  }
`;

const StyledHeaderAndTabBar = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${darkerBorderGray};
  margin-bottom: 1rem;
`;

const StyledFrequencyIcon = styled(FrequencyIcon)`
  margin-right: 0.8rem;
  height: 2.5rem;
  width: 1.6rem;
`;

const StyledCategoryIcon = styled(CategoryIcon)`
  margin-right: 0.8rem;
  height: 2.5rem;
  width: 1.6rem;
`;

const LearningOutcomesToggleLabel = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  color: ${darkBlue};
`;

const LearningOutcomesToggleExplanation = styled.div`
  font-size: 1.2rem;
`;

const LearningOutcomesToggleRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LearningOutcomesRow = styled.div<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const Spacer = styled.div`
  flex-grow: 1;
  width: 2rem;
`;
