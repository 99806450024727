import { useState } from 'react';

export interface Tab {
  tabName: string;
  id: string;
  isActive: boolean;
}

export interface TabProps {
  'aria-controls': string;
  'aria-selected': boolean;
  id: string;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  role: 'tab';
  tabIndex: number;
  onKeyDown: (e: React.KeyboardEvent) => void;
  isActive: boolean;
}

export interface PanelProps {
  'aria-labelledby': string;
  hidden: boolean;
  id: string;
  role: 'tabpanel';
  tabIndex: number;
}

const useTabs = (onChange: (tabName: string) => void) => {
  const [tabs, setTabs] = useState<Tab[]>([]);

  const changeTabs = (e: React.KeyboardEvent) => {
    const activeTabIndex = tabs.findIndex(tab => tab.isActive);

    let nextIndex = activeTabIndex;

    if (e.key === 'ArrowRight') {
      nextIndex = (activeTabIndex + 1) % tabs.length;
    }

    if (e.key === 'ArrowLeft') {
      nextIndex = activeTabIndex === 0 ? tabs.length - 1 : activeTabIndex - 1;
    }

    if (nextIndex !== activeTabIndex) {
      onChange(tabs[nextIndex].tabName);
      document.getElementById(`tab-${tabs[nextIndex].id}`)?.focus();
    }
  };

  const getTabProps = (
    tabName: string,
    activeTabName: string,
    changeOnHover?: boolean
  ): TabProps | void => {
    if (tabs.findIndex(tab => tab.tabName === tabName) === -1) {
      setTabs(prevTabs => [
        ...prevTabs,
        { id: tabName, isActive: activeTabName === tabName, tabName }
      ]);
    }
    const currentTab = tabs.find(tab => tab.tabName === tabName);

    if (currentTab) {
      if (currentTab.tabName === activeTabName && !currentTab.isActive) {
        const newTabs = tabs.map(tab => ({ ...tab, isActive: tab.tabName === currentTab.tabName }));
        setTabs(() => newTabs);
      }

      let hoverTimeout: NodeJS.Timeout;
      const handleMouseEnter = () => {
        hoverTimeout = setTimeout(() => {
          onChange(currentTab.tabName);
        }, 500);
      };

      const handleMouseLeave = () => {
        clearTimeout(hoverTimeout);
      };

      const tabProps: TabProps = {
        'aria-controls': `panel-${currentTab.id}`,
        'aria-selected': currentTab.isActive,
        id: `tab-${currentTab.id}`,
        onClick: () => onChange(currentTab.tabName),
        role: 'tab',
        tabIndex: currentTab.isActive ? 0 : -1,
        onKeyDown: e => changeTabs(e),
        isActive: currentTab.isActive
      };

      if (changeOnHover) {
        return {
          ...tabProps,
          onMouseEnter: () => handleMouseEnter(),
          onMouseLeave: () => handleMouseLeave()
        };
      } else {
        return tabProps;
      }
    }
  };

  const getPanelProps = (tabName: string): PanelProps | Record<string, never> => {
    const currentTab = tabs.find(tab => tab.tabName === tabName);

    if (!currentTab) {
      return {};
    }
    return {
      'aria-labelledby': `tab-${currentTab.id}`,
      hidden: !currentTab.isActive,
      id: `panel-${currentTab.id}`,
      role: 'tabpanel',
      tabIndex: currentTab.isActive ? 0 : -1
    };
  };

  return { getTabProps, getPanelProps };
};

export default useTabs;
