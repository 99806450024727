import React from 'react';
import { Page } from '@react-pdf/renderer';

import { Footer } from './Footer';

export interface ContainerPageProps {
  wrap?: boolean;
  omitFooter?: boolean;
  omitPadding?: boolean;
}

export const ContainerPage: React.FC<ContainerPageProps> = ({
  children,
  wrap,
  omitFooter,
  omitPadding
}) => {
  return (
    <Page
      style={{
        fontFamily: 'Inter',
        fontSize: 16,
        paddingTop: omitPadding ? 0 : '0.5in',
        paddingBottom: omitPadding ? 0 : '1in',
        paddingLeft: omitPadding ? 0 : '0.5in',
        paddingRight: omitPadding ? 0 : '0.5in'
      }}
      size="A4"
      wrap={wrap}
    >
      {children}
      {!omitFooter && <Footer />}
    </Page>
  );
};
