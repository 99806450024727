import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useCombobox, useMultipleSelection } from 'downshift';
import { throttle } from 'lodash';

import DropdownFlyout, { DropdownFlyoutProps } from 'components/molecules/DropdownFlyout';
import { Menu, MenuItem } from 'components/atoms/FlyoutMenu';
import { DEFAULT_REGION_OBJECT } from 'components/organisms/SetupLocalStorage';

import { ReactComponent as CloseIcon } from 'images/closebtn.svg';
import { ahoy, bgLightGray, lightGray } from 'utils/colors';
import { searchJPAFacet } from 'services/jpa';
import useCurrentNation from 'hooks/useCurrentNation';
import { DropdownSelectable } from 'hooks';
import { FieldError } from 'react-hook-form';

const REGION_OPTIONS: Record<JPANation, DropdownSelectable<string>[]> = {
  us: [
    { value: 'nation', label: 'Nation' },
    { value: 'state', label: 'State' },
    { value: 'county', label: 'County' },
    { value: 'msa', label: 'MSA' }
  ],
  ca: [
    { value: 'nation', label: 'Nation' },
    { value: 'pr', label: 'Province' },
    { value: 'cma', label: 'CMA' },
    { value: 'cd', label: 'Census Division' },
    { value: 'csd', label: 'Census Subdivision' }
  ],
  uk: [
    { value: 'nation', label: 'Nation' },
    { value: 'country', label: 'Country' },
    { value: 'nuts1', label: "Gov't Office Region" },
    { value: 'nuts3', label: 'County/Unitary Authority' },
    { value: 'lau1', label: 'Local Authority' }
  ]
};

type ThrottledRegionSearch = (
  nation: JPANation,
  filterType: string,
  inputValue: string,
  setResult: (results: JPARegionData[]) => void
) => void;

const throttledRegionSearch = throttle<ThrottledRegionSearch>(
  async (nation, filterType, q: string, setSearchResult) => {
    setSearchResult(await searchJPAFacet(nation, filterType, { q }));
  },
  400
);
interface RegionPickerProps {
  value: RegionFilter;
  headerLabel?: React.ReactElement;
  siteNation?: JPANation | undefined;
  className?: string;
  error?: FieldError;
  onChange?: (newValue: Partial<Record<JPANation, RegionFilter>>) => void;
}

const RegionPicker: React.FC<RegionPickerProps> = ({
  value,
  headerLabel,
  siteNation,
  className,
  error,
  onChange
}) => {
  const [filterType, setFilterType] = useState<DropdownSelectable<string>>(value.filterType);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const [selectableRegions, setSelectableRegions] = useState<JPARegionData[]>([]);

  const [isActive, setIsActive] = useState<boolean>(false);

  const [currentNation] = useCurrentNation();

  const nation = siteNation ? siteNation : currentNation;

  useEffect(() => {
    setFilterType(value.filterType);
    setSelectedItems(value.regions);
  }, [value]);

  useEffect(() => {
    if (inputValue.length) {
      throttledRegionSearch(nation, filterType.value, inputValue, setSelectableRegions);
    }
  }, [inputValue]);

  const {
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    getSelectedItemProps,
    setSelectedItems,
    getDropdownProps
  } = useMultipleSelection<JPARegionData>({ initialSelectedItems: value.regions });

  const getFilteredItems = (items: JPARegionData[]) => {
    return items.filter(
      item =>
        selectedItems.find(selectedItem => selectedItem.id === item.id) === undefined &&
        item.name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };

  const { isOpen, getInputProps, getMenuProps, getItemProps, highlightedIndex, selectItem } =
    useCombobox({
      items: getFilteredItems(selectableRegions),
      defaultHighlightedIndex: 0,
      inputValue,
      itemToString: item => (item ? item.name : ''),
      stateReducer: (_, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: false // keep the menu open after selection.
            };
        }
        return changes;
      },
      onStateChange: state => {
        const typedSelectItem = selectItem as (item: JPARegionData | null) => void; // This is neccessary, since downshift typescript rules do not allow to call selectItem(null). This is probably a bug on downshift's side.
        switch (state.type) {
          case useCombobox.stateChangeTypes.InputChange:
            state.inputValue !== undefined && setInputValue(state.inputValue);
            break;
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (state.selectedItem) {
              addSelectedItem(state.selectedItem);
              typedSelectItem(null); // We need to do this so that our item's state can change between state changes, otherwise the last selected item will become un-selectable again if removed.
              inputRef.current?.focus();
            }
            setInputValue('');
            break;
          default:
            break;
        }
      }
    });
  useEffect(() => {
    const selectedDefaultRegion = { [nation]: { filterType, regions: selectedItems } };
    onChange?.(selectedDefaultRegion);
  }, [filterType, selectedItems, onChange, nation]);

  return (
    <div className={className}>
      <RegionPickerHeader>
        {headerLabel}
        <div className="DropdownWrapper">
          <StyledDropdownFlyout
            className="StyledDropdownFlyout"
            dropDownName="regions"
            items={REGION_OPTIONS[nation]}
            selected={filterType}
            onSelect={selection => {
              if (selection.value === 'nation') {
                setFilterType(DEFAULT_REGION_OBJECT[nation].filterType);
                setSelectedItems(DEFAULT_REGION_OBJECT[nation].regions);
              } else {
                setFilterType(selection);
                if (selection.value === value.filterType.value) {
                  setSelectedItems(value.regions);
                  return;
                }
                setSelectedItems([]);
              }
            }}
          />
        </div>
      </RegionPickerHeader>
      <RegionContainerWrapper className="RegionContainerWrapper">
        <RegionContainer
          data-cy="region-picker_region-container"
          className="RegionContainer"
          onClick={e => {
            e.stopPropagation();
            inputRef.current?.focus();
          }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          active={isActive}
        >
          {selectedItems.length > 0 &&
            selectedItems.map((region, index) => (
              <RegionPill
                key={region.name}
                {...getSelectedItemProps({ selectedItem: region, index })}
              >
                <PillText>{region.name}</PillText>
                {region.id !== nation && (
                  <RemoveButton
                    onClick={() => {
                      removeSelectedItem(region);
                    }}
                  >
                    <StyledCloseIcon />
                  </RemoveButton>
                )}
              </RegionPill>
            ))}

          <StyledInput
            type="text"
            {...getInputProps(
              getDropdownProps({
                ref: inputRef,
                disabled: filterType.value === 'nation'
              })
            )}
          />
          <div {...getMenuProps()}>
            {isOpen && (
              <StyledMenu>
                {getFilteredItems(selectableRegions).map((item, index) => (
                  <StyledMenuItem
                    data-cy={`region-picker_menu-item_${item.id}`}
                    isSelected={highlightedIndex === index}
                    key={`${item.id}`}
                    {...getItemProps({ item, index })}
                  >
                    {item.name}
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            )}
          </div>
          {error && <Error>{error.message}</Error>}
        </RegionContainer>
      </RegionContainerWrapper>
    </div>
  );
};

export default RegionPicker;

const RegionPickerHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<string>) => (
  <DropdownFlyout {...props} />
))``;

export const RegionContainerWrapper = styled.div`
  position: relative;
`;

export const RegionContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-wrap: wrap;
  max-height: 18rem;
  overflow-y: auto;
  padding: 0.2rem;
  ${({ active }) =>
    active &&
    `
    outline: 2px solid black;
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  `}
`;

const StyledInput = styled.input`
  height: 2.5rem;
  outline: none;
  border: none;
  background: ${bgLightGray};
  padding: 0.4rem 0.7rem;
  margin: 0.4rem;
`;

const StyledMenu = styled(Menu)`
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.09);
  padding: 0.5rem;
`;

const StyledMenuItem = styled(MenuItem)<{ isSelected: boolean }>`
  padding: 2rem 0 2rem 2rem;
  cursor: pointer;
  ${({ isSelected }) => isSelected && `background: ${bgLightGray};`}
`;

const RegionPill = styled.div`
  display: flex;
  background: ${lightGray};
  border-radius: 0.2rem;
  padding: 0.4rem 0.7rem;
  font-size: 1.4rem;
  margin: 0.4rem;
`;

const PillText = styled.span`
  max-width: 31rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled.button`
  padding-top: 10rem;
  margin-left: 0.6rem;
  background: ${lightGray};
  border: none;
  padding: 0 0.2rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 0.9rem;
  height: 0.9rem;
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: ${ahoy};
  position: absolute;
  top: 105%;
  left: 0;
`;
