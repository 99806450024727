import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import InternalLink from 'components/atoms/InternalLink';
import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import FlyoutMenu, { MenuItem } from 'components/atoms/FlyoutMenu';
import ModalButton from 'components/molecules/ModalButton';
import MessageModal from 'components/molecules/MessageModal';
import DeleteCourseModal from 'components/molecules/DeleteCourseModal';
import DeleteProgramModal from 'components/molecules/DeleteProgramModal';
import DeleteProgramTypeModal from 'components/molecules/DeleteProgramTypeModal';
import ProgramTypeModal from 'components/organisms/ProgramTypeModal';
import { useTableDispatch } from 'store/tableStore';

import { updateCourse, updateCurricularUnit } from 'services/curricularSkills';

import { darkSaphire } from 'utils/colors';
import { getToastFnThatWeTreatLikeHook } from 'hooks';

import { ReactComponent as Check } from 'images/greenCheck.svg';
import { ReactComponent as Duplicate } from 'images/duplicate.svg';
import { ReactComponent as Trash } from 'images/trash.svg';
import { ReactComponent as Edit } from 'images/edit.svg';
import { ReactComponent as TableButton } from 'images/tableButton.svg';

const StyledTableButton = styled(TableButton)`
  cursor: pointer;
`;

const SvgStyles = css`
  stroke: ${darkSaphire};
  margin-right: 1rem;
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 11.4rem;
`;

const StyledFlyoutMenu = styled(FlyoutMenu)`
  .invisibleButton {
    padding: 0;
  }
  .flyoutMenu {
    right: 1rem;
  }
`;

const StyledModalButton = styled(ModalButton)`
  color: ${darkSaphire};
`;

interface CourseFlyoutProps {
  tableType: 'course';
  row: PreppedCourse;
}

interface ProgramFlyoutProps {
  tableType: 'program';
  row: PreppedProgram;
}

interface ProgramTypeFlyoutProps {
  tableType: 'program-type';
  row: PreppedProgramType;
}

// Screen readers do not refocus when menu is closed.
const ButtonFlyoutMenu: React.FC<
  CourseFlyoutProps | ProgramFlyoutProps | ProgramTypeFlyoutProps
> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const notification = getToastFnThatWeTreatLikeHook();
  const tableDispatch = useTableDispatch();

  return (
    <StyledFlyoutMenu
      ariaLabel={`${props.tableType} options`}
      buttonContent={<StyledTableButton />}
    >
      {({ getItemProps }) => (
        <>
          {props.tableType === 'program-type' && (
            <MenuItem {...getItemProps({ firstOrLastPosition: 'first' })}>
              <StyledModalButton
                buttonText={
                  <>
                    <Edit css={SvgStyles} />
                    Edit
                  </>
                }
              >
                {closeModal => (
                  <ProgramTypeModal
                    defaultValues={{
                      programTypeId: props.row.id,
                      programTypeLabel: props.row.title,
                      programTypeClass: {
                        value: props.row.category.id,
                        label: props.row.category.name
                      }
                    }}
                    closeModal={closeModal}
                  />
                )}
              </StyledModalButton>
            </MenuItem>
          )}
          {props.tableType !== 'program-type' && (
            <>
              <MenuItem {...getItemProps({ firstOrLastPosition: 'first' })}>
                <InternalLink to={`/edit/${props.tableType}/${props.row.id}`}>
                  <Edit css={SvgStyles} />
                  Edit
                </InternalLink>
              </MenuItem>
              <MenuItem {...getItemProps()}>
                <StyledModalButton
                  aria-label={`button to open confirmation modal for ${
                    props.row.isPublished ? 'unpublishing' : 'publishing'
                  } ${props.tableType}`}
                  buttonText={
                    <>
                      <Check css={SvgStyles} />
                      {props.row.isPublished ? 'Unpublish' : 'Publish'}
                    </>
                  }
                >
                  {closeModal => {
                    const publishVerb = props.row.isPublished ? 'Unpublish' : 'Publish';
                    const updateCurriculum: (
                      id: string,
                      updates: { isPublished: boolean }
                    ) => Promise<SingleCurriculum<Course | CurricularUnitResponse>> =
                      props.tableType === 'course' ? updateCourse : updateCurricularUnit;
                    return (
                      <MessageModal
                        title={`${publishVerb} this ${
                          props.tableType === 'course' ? 'Course' : 'Program'
                        }?`}
                        message={`This ${
                          props.tableType === 'course' ? 'course' : 'program'
                        } will ${
                          props.row.isPublished ? 'be removed from ' : 'become visible on '
                        }products that use Skillabi data.`}
                      >
                        <ButtonsWrapper>
                          <StyledButton scheme="outline" type="button" onClick={closeModal}>
                            Cancel
                          </StyledButton>
                          <StyledLoadingButton
                            type="button"
                            isLoading={isLoading}
                            onClick={() => {
                              setIsLoading(true);
                              updateCurriculum(props.row.id, {
                                isPublished: !props.row.isPublished
                              })
                                .then(() => {
                                  setIsLoading(false);
                                  queryClient.invalidateQueries(
                                    props.tableType === 'course' ? 'courses' : 'programs'
                                  );
                                  queryClient.invalidateQueries([
                                    props.tableType === 'course' ? 'courses' : 'program',
                                    props.row.id
                                  ]);
                                  notification(
                                    `Done! ${props.tableType.replace(/\b\w/g, l =>
                                      l.toUpperCase()
                                    )} updated.`,
                                    'success'
                                  );
                                  closeModal();
                                })
                                .catch(err => {
                                  notification(
                                    `Sorry, we were unable to update that ${props.tableType}. Please try again.`,
                                    'error'
                                  );
                                  setIsLoading(false);
                                });
                            }}
                          >
                            {publishVerb} {props.tableType === 'course' ? 'Course' : 'Program'}
                          </StyledLoadingButton>
                        </ButtonsWrapper>
                      </MessageModal>
                    );
                  }}
                </StyledModalButton>
              </MenuItem>
              <MenuItem {...getItemProps()}>
                <InternalLink to={`/duplicate/${props.tableType}/${props.row.id}`}>
                  <Duplicate css={SvgStyles} />
                  Duplicate
                </InternalLink>
              </MenuItem>
            </>
          )}
          <MenuItem {...getItemProps({ firstOrLastPosition: 'last' })}>
            <StyledModalButton
              buttonText={
                <>
                  <Trash css={SvgStyles} />
                  Delete
                </>
              }
            >
              {closeModal => {
                return props.tableType === 'course' ? (
                  <DeleteCourseModal
                    closeModal={closeModal}
                    courseIds={[props.row.id]}
                    onDelete={() => tableDispatch({ type: 'DESELECT_ROW', payload: props.row.id })}
                  />
                ) : props.tableType === 'program' ? (
                  <DeleteProgramModal
                    closeModal={closeModal}
                    programIds={[props.row.id]}
                    onDelete={() => tableDispatch({ type: 'DESELECT_ROW', payload: props.row.id })}
                  />
                ) : (
                  <DeleteProgramTypeModal
                    programType={props.row.id}
                    associatedGroups={props.row.groupCount}
                    closeModal={closeModal}
                  />
                );
              }}
            </StyledModalButton>
          </MenuItem>
        </>
      )}
    </StyledFlyoutMenu>
  );
};

export default ButtonFlyoutMenu;
