import { SheetOptions } from 'excellentexport';
import { fetchSkillsById } from 'services/skills';

export interface Inputs {
  title: string;
  course?: string;
  courseId?: string;
  isPublished: boolean;
  credits?: number | null;
  url?: string;
  description?: string;
  syllabusText?: string;
  skills?: readonly CurricularSkill[];
}

export const EXPORT_COURSE_INFO_COLUMNS = [
  'Course Code',
  'Title',
  'Summary',
  'Curricular Content Description',
  'URL',
  'Credits',
  '# of Skills'
];

export const EXPORT_COURSE_SKILLS_COLUMNS = [
  'Skill Name',
  'Skill ID',
  'Skill Found in Learning Outcomes',
  'Skill Category',
  'Skill Subcategory'
];

export type SkillWithLearningOutcome = Skill & { isLearningObjective: boolean };

export const formatCourseExportData = async (courseData: {
  formValues: Inputs;
  selectedSkills?: readonly CurricularSkill[];
}): Promise<SheetOptions[]> => {
  let skills: SkillWithLearningOutcome[] = [];
  if (courseData.selectedSkills) {
    const fetchedSkills = await fetchSkillsById(
      courseData.selectedSkills.map(skill => skill.id),
      ['id', 'name', 'category', 'subcategory']
    );
    const learningOutcomeSkills = courseData.selectedSkills.reduce<Record<string, boolean>>(
      (acc, skill) => {
        acc[skill.id] = skill.isLearningObjective;
        return acc;
      },
      {}
    );

    skills = fetchedSkills.map(skill => ({
      ...skill,
      isLearningObjective: !!learningOutcomeSkills[skill.id]
    }));
  }

  return formatCourseCsv(courseData.formValues, skills);
};

export function formatCourseCsv(
  course: Inputs,
  skills: SkillWithLearningOutcome[]
): SheetOptions[] {
  const { courseId, credits, description, syllabusText, title, url } = course;

  const baseCourseData = [courseId, title, description, syllabusText, url, credits, skills.length];

  const selectedSkillRows = skills.map(skill => [
    skill.name,
    skill.id,
    skill.isLearningObjective,
    skill.category?.name,
    skill.subcategory?.name
  ]);

  const sheets = [
    {
      name: 'Course Info',
      from: {
        array: [EXPORT_COURSE_INFO_COLUMNS, baseCourseData]
      }
    },
    {
      name: 'Course Skills',
      from: {
        array: [EXPORT_COURSE_SKILLS_COLUMNS, ...selectedSkillRows]
      }
    }
  ];

  return sheets;
}
