import React from 'react';
import styled from '@emotion/styled';
import { MenuItem } from 'components/atoms/FlyoutMenu';
import InternalLink from 'components/atoms/InternalLink';
import SearchInput from 'components/atoms/SearchInput';
import { usePrograms } from 'hooks';
import { useState } from 'react';
import InfoPill from 'components/atoms/InfoPill';
import { programTypeClassShort } from 'utils/programTypeClassShortening';

const ProgramSearchDropdown: React.FC<{
  handleClose: () => void;
  getItemProps: (
    options?: { firstOrLastPosition: 'first' | 'middle' | 'last' },
    props?: Record<string, unknown>
  ) => { onKeyDown?: (e: React.KeyboardEvent) => void; role: string };
}> = ({ getItemProps, handleClose }) => {
  const [searchText, setSearchText] = useState('');

  const buildProgramSearchPayload = () => {
    const filters: ProgramSearchFilters = {};
    if (searchText) {
      filters.textFilter = { query: searchText, fields: ['title'] };
    }
    return filters;
  };

  const { data: programData, isLoading: isProgramDataLoading } = usePrograms(
    {
      filter: buildProgramSearchPayload(),
      limit: 25
    },
    { keepPreviousData: true, staleTime: 1000 }
  );

  return (
    <DropdownContainer>
      <StyledSearchInput
        placeholder="Search for a Program..."
        dataCy={'program-search-dropdown_search-input'}
        searchOnEmpty
        onChange={async q => {
          setSearchText(q);
        }}
        isInitialDataLoading={isProgramDataLoading}
      />
      <ProgramContainer>
        {programData?.data.length === 0 && (
          <StyledNoMatchSpan data-cy={'program-search-dropdown_no-data-match'}>
            No Matched Programs
          </StyledNoMatchSpan>
        )}
        {programData?.data.map((program, index) => (
          <MenuItem
            data-cy={`program-search-dropdown_program-data_${index}`}
            key={program.id}
            {...getItemProps(
              {
                firstOrLastPosition:
                  index === 0 ? 'first' : index === programData.data.length - 1 ? 'last' : 'middle'
              },
              { onClick: handleClose }
            )}
          >
            <StyledInternalLink to={`/program/insights/${program.id}`}>
              {program.attributes.title}
              <StyledInfoPill scheme={'blue'}>
                {programTypeClassShort[
                  program.attributes.groupType.groupTypeClass
                    .name as keyof typeof programTypeClassShort
                ] || program.attributes.groupType.groupTypeClass.name}
              </StyledInfoPill>
            </StyledInternalLink>
          </MenuItem>
        ))}
      </ProgramContainer>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  width: 35rem;
`;

const ProgramContainer = styled.div`
  max-height: 20rem;
  min-width: 100%;
  overflow: auto;
`;

const StyledSearchInput = styled(SearchInput)`
  margin: 1px 2rem 1rem 2rem;
`;

const StyledInternalLink = styled(InternalLink)`
  color: unset;
  justify-content: space-between;
`;

const StyledNoMatchSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const StyledInfoPill = styled(InfoPill)`
  margin-left: 1rem;
`;

export default ProgramSearchDropdown;
