export function findIndicesOfTargetMonths(dates: string[]) {
  const targetMonths = [0, 3, 6, 9]; // January, April, July, October
  const indices = [];

  for (let i = 0; i < dates.length; i++) {
    const month = new Date(dates[i]).getMonth();
    if (targetMonths.includes(month)) {
      indices.push(i);
    }
  }

  return indices;
}
