export const chartStyles = {
  skillLabels: {
    style: {
      fill: 'black',
      fontSize: 7,
      fontFamily: 'Inter',
      border: 'solid white',
      cursor: 'pointer'
    }
  },
  monthAxis: {
    style: {
      tickLabels: { fontSize: 8, fontWeight: 'bold', fontFamily: 'Inter' },
      axis: { strokeWidth: 0 },
      axisLabel: { padding: 35, fontSize: 9, fontWeight: 'bold', fontFamily: 'Inter' }
    }
  },
  skillNameAxis: {
    style: {
      axis: { stroke: 'white', strokeWidth: 0 }
    }
  },
  skillScatterPercentageLabels: {
    style: {
      fontSize: 8,
      fontFamily: 'Inter',
      fontWeight: 'bold'
    }
  },
  totalTaughtSkillScatterPercentageLabels: {
    style: {
      fill: '#2E6AD1',
      fontSize: 8,
      fontFamily: 'Inter',
      fontWeight: 'bold'
    }
  },
  percentageAxis: {
    style: {
      tickLabels: { fontSize: 8, fontWeight: 'bold', fontFamily: 'Inter' },
      axis: { strokeWidth: 0 },
      axisLabel: {
        padding: 31,
        fontSize: 8,
        fontWeight: 'bold',
        fontFamily: 'Inter',
        angle: -360
      }
    }
  },
  monthlyVerticalBars: { style: { data: { opacity: 0.3, pointerEvents: 'none' } } }
};
