import { Font } from '@react-pdf/renderer';

import regular from 'fonts/inter-v3-latin-regular.ttf';
import bold from 'fonts/inter-v3-latin-600.ttf';
import light from 'fonts/inter-v3-latin-300.ttf';

Font.register({
  family: 'Inter',
  fonts: [{ src: regular }, { src: light, fontWeight: 300 }, { src: bold, fontWeight: 700 }]
});

// Don't hyphenate words on wrap
Font.registerHyphenationCallback(x => [x]);

export * from './program';
export * from './course';

export { setRenderPurpose, type RenderPurpose } from './contentsTable';
