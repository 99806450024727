import React, { useEffect } from 'react';

import Modal from 'components/atoms/Modal';
import Button from 'components/atoms/Button';

interface ModalButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonText: string | React.ReactNode;
  children: (closeModal: (e?: React.MouseEvent | Event) => void) => React.ReactNode;
  noX?: boolean;
  modalStyles?: React.CSSProperties;
  onModalChange?: (isOpen: boolean) => void;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  modalStyles = {},
  children,
  buttonText,
  noX = false,
  onModalChange,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const closeModal = (e?: React.MouseEvent | Event) => setIsOpen(false);

  useEffect(() => {
    onModalChange && onModalChange(isOpen);
  }, [isOpen]);

  return (
    <>
      <Button type="button" onClick={e => setIsOpen(true)} {...props}>
        {buttonText}
      </Button>
      {isOpen && (
        <Modal style={modalStyles} noX={noX} closeModal={closeModal}>
          {children(closeModal)}
        </Modal>
      )}
    </>
  );
};

export default ModalButton;
