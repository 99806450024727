import styled from '@emotion/styled';
import { bgLightGray, borderGray } from 'utils/colors';

const Input = styled.input`
  background: ${bgLightGray};
  border: 0.1rem solid ${borderGray};
  border-radius: 0.4rem;
  padding: 1rem;
  width: 100%;
  font-size: 1.4rem;

  ::placeholder {
    font-style: italic;
  }
`;

export default Input;
