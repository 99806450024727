import { DataSet } from '.';

export type ChartOrder = 'ascending' | 'descending' | 'none';

export function getChartTicks(max: number): number[] {
  if (max > 12) {
    return getChartTicks(max / 10).map(tick => tick * 10);
  }

  // number is between 6 and 12
  return createRangeTo(Math.ceil(max));
}

function createRangeTo(max: number) {
  const range: number[] = [];

  for (let index = 0; index <= max; index++) {
    range.push(index);
  }

  return range;
}

export function segmentChartData(data: DataSet[], initialChartBreak = 19): DataSet[][] {
  const fullChartBreak = 19;
  const chartData: DataSet[][] = [];

  let index = initialChartBreak;

  if (data.length <= initialChartBreak) {
    chartData.push(data);
  } else {
    chartData.push(data.slice(0, initialChartBreak));

    while (index < data.length) {
      chartData.push(data.slice(index, index + fullChartBreak));
      index += fullChartBreak;
    }
  }

  return chartData;
}

export const orderChartData = (data: DataSet[], order: ChartOrder) => {
  switch (order) {
    case 'ascending' || 'asc':
      return data.sort((a, b) => a.value - b.value);
    case 'descending' || 'desc':
      return data.sort((a, b) => b.value - a.value);
    default:
      return data;
  }
};

export const formatLargeNumber = (n: number): string =>
  n >= 1.0e9
    ? `${(n / 1.0e9).toFixed(0)}b`
    : n >= 1.0e6
    ? `${(n / 1.0e6).toFixed(0)}m`
    : n >= 1.0e3
    ? `${(n / 1.0e3).toFixed()}k`
    : n.toLocaleString();

export function getMaxValue(...datasets: DataSet[][]) {
  const values = datasets.flatMap(dataset => dataset.map(data => data.value));

  return Math.max(...values);
}

export function getDatasetChartTicks(...datasets: DataSet[][]) {
  return getChartTicks(getMaxValue(...datasets));
}

export const calculateChartHeight = (segments: DataSet[]) => {
  const chartHeightPerSegment = 125;
  const numSegments = segments.length;
  const scalingFactor = 2 + numSegments / 10;
  const chartHeight = chartHeightPerSegment * (numSegments / scalingFactor);
  return chartHeight;
};
