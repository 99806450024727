import React from 'react';
import {
  Container,
  ContainerPage,
  Header,
  Paragraph,
  Row,
  SkillabiLogo,
  SubHeader
} from './components';

export interface AboutProps {
  aboutText: string;
  whatIsData: string;
}

export const About: React.FC<AboutProps> = ({ aboutText, whatIsData }) => {
  return (
    <ContainerPage>
      <Row gap={10} align="center">
        <SkillabiLogo />
        <Header id="skillabi" text="Skillabi" contentsTableText="About Skillabi" />
      </Row>

      <Container height={30} />
      <SubHeader text="About Skillabi" id="about_skillabi" omitContentsTable />
      <Container height={20} />
      <Paragraph text={aboutText} />

      <Container height={30} />
      <SubHeader text="What is Lightcast Data" id="what_is_lightcast_data" omitContentsTable />
      <Container height={20} />
      <Paragraph text={whatIsData} />
    </ContainerPage>
  );
};
