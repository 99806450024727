import React from 'react';
import styled from '@emotion/styled';
import {
  lightGray,
  darkGray,
  darkGreen,
  lightGreen,
  skyBlue,
  darkDarkSaphire,
  highlightYellow,
  sailorRed,
  lightSailorRed,
  alignmentBlue
} from 'utils/colors';

export const pillThemes = {
  green: {
    color: darkGreen,
    background: lightGreen
  },
  gray: {
    color: darkGray,
    background: lightGray
  },
  blue: {
    color: darkDarkSaphire,
    background: skyBlue
  },
  alignmentBlue: {
    color: alignmentBlue,
    background: alignmentBlue
  },
  yellow: {
    color: darkGray,
    background: highlightYellow
  },
  red: {
    color: sailorRed,
    background: lightSailorRed
  }
};

export type PillScheme = keyof typeof pillThemes;

const InfoPill = styled.span<{ scheme: PillScheme }>`
  background-color: ${props => pillThemes[props.scheme].background};
  color: ${props => pillThemes[props.scheme].color};
  border-radius: 2.6rem;
  padding: 0.1rem 0.8rem;
  font-weight: bold;
  font-size: 1.2rem;
  white-space: nowrap;
`;

export default InfoPill;

export interface PillProps {
  scheme: PillScheme;
  label: string;
}

export const SimplePill: React.FC<PillProps> = ({ scheme, label }) => {
  return <InfoPill scheme={scheme}>{label}</InfoPill>;
};
