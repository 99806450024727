import { black, mistyGray } from 'utils/colors';
import { DataSet } from './barChart';
import { formatLargeNumber } from './helpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Canvas = any;

export interface PaintChartParams {
  width: number;
  height: number;
  chartLeftMargin: number;
  canvas: Canvas;
  data: DataSet[];
  ticks: number[];
  barColor: string;
}

const getTickList = (
  width: number,
  chartLeftMargin: number,
  chartRightMargin: number,
  ticks: number[]
): Array<number> => {
  const tickIntervals = (width + chartRightMargin - chartLeftMargin) / ticks.length - 3.8;
  const tickList = [chartLeftMargin];
  for (let i = 0; i < ticks.length - 1; i++) {
    tickList.push(chartLeftMargin + tickIntervals * (i + 1));
  }
  return tickList;
};

const scaleListWithinRange = (
  list: number[],
  maxChartValue: number,
  minValue: number,
  maxVal: number
): number[] => {
  const outputRange = maxVal - minValue;
  return list.map(value =>
    value === 0 ? minValue : (value * outputRange) / maxChartValue + minValue
  );
};

export function paintChart(params: PaintChartParams) {
  const barSpacingRatio = 30; // Increasing this value will decrease the spacing between bars
  const barTopMargin = 15;
  const chartRightMargin = 50;

  const getTickPositions = getTickList(
    params.width,
    params.chartLeftMargin,
    chartRightMargin,
    params.ticks
  );

  if (params.data.length === 0) {
    return;
  }

  const drawChart = () => {
    params.canvas.moveTo(params.chartLeftMargin, params.height);
    params.canvas.lineTo(params.width - chartRightMargin + 18, params.height);
    params.canvas.lineWidth(0.8).strokeColor(mistyGray).stroke();
  };

  const drawVerticalLines = () => {
    getTickPositions.forEach((pos, index) => {
      params.canvas.moveTo(pos, params.height);
      params.canvas
        .fontSize(12)
        .text(formatLargeNumber(params.ticks[index]), pos - 3, params.height + 10);
      params.canvas.lineTo(pos, 50);
      params.canvas.stroke().lineWidth(0.8).strokeColor(mistyGray);
    });
  };

  const drawBars = () => {
    scaleListWithinRange(
      params.data.map(obj => obj.value),
      params.ticks[params.ticks.length - 1],
      params.chartLeftMargin,
      params.width - chartRightMargin
    ).forEach((line, index) => {
      const yPos = (index + 1) * barSpacingRatio + barTopMargin;
      params.canvas.moveTo(params.chartLeftMargin, yPos);
      params.canvas.lineTo(line, yPos);
      params.canvas.text(`(${formatLargeNumber(params.data[index].value)})`, line + 5, yPos - 8);
      params.canvas.lineWidth(15).strokeColor(params.barColor || black);
      params.canvas.stroke();
    });
  };

  drawChart();
  drawVerticalLines();
  drawBars();
}
