import React, { useState } from 'react';

import BarChartSubheader from 'components/atoms/BarChartSubheader';
import JobPostingsChart from 'components/molecules/JobPostingsChart';

import { CustomErrorMessage } from '../SkillInsights';
import TaughtSoughtLegend from 'components/atoms/TaughtSoughtLegend';
import { EnhancedSkill } from 'hooks/programInsights';
import { ChartSettings } from 'store/programData';

type ChartType = 'sought' | 'taught';

const TaughtSoughtChart: React.FC<{
  chartType: ChartType;
  filteredSkills: {
    taughtSkillsInTargetOutcomes: Record<string, EnhancedSkill>;
    skillsNotTaughtInTargetOutcomes: Record<string, EnhancedSkill>;
  };
  chartSettings: ChartSettings;
  postingsQuery: JPAOptionsFilter;
  isLoading: boolean;
  customChartErrorMessage?: CustomErrorMessage;
}> = ({
  chartType,
  filteredSkills,
  chartSettings,
  postingsQuery,
  isLoading,
  customChartErrorMessage
}) => {
  const [jobChartsAreExpanded, setJobChartsAreExpanded] = useState(false);

  return (
    <>
      {chartType === 'taught' ? (
        <JobPostingsChart
          query={postingsQuery}
          isExpanded={jobChartsAreExpanded}
          changeExpansion={() => setJobChartsAreExpanded(!jobChartsAreExpanded)}
          headingText="Taught Skills"
          subheader={<BarChartSubheader skillType="taught" />}
          data={filteredSkills.taughtSkillsInTargetOutcomes}
          isLoading={isLoading}
          displayType={chartSettings.isDisplayingCategories ? 'category' : 'default'}
          customChartErrorMessage={customChartErrorMessage}
        />
      ) : (
        <JobPostingsChart
          query={postingsQuery}
          isExpanded={jobChartsAreExpanded}
          changeExpansion={() => setJobChartsAreExpanded(!jobChartsAreExpanded)}
          legend={<TaughtSoughtLegend show={['taught_other', 'sought']} />}
          headingText="Sought Skills"
          subheader={<BarChartSubheader skillType="sought" />}
          data={filteredSkills.skillsNotTaughtInTargetOutcomes}
          allowAddSkill
          isLoading={isLoading}
          displayType={chartSettings.isDisplayingCategories ? 'category' : 'default'}
          customChartErrorMessage={customChartErrorMessage}
        />
      )}
    </>
  );
};

export default TaughtSoughtChart;
