import React from 'react';
import styled from '@emotion/styled';

import DetailedBenchmarkCard from 'components/molecules/DetailedBenchmarkCard';
import { BenchmarkPopup } from 'components/molecules/BenchmarkPopup';
import Carousel from 'components/organisms/Carousel';

import { getBenchmarkTitle, LOT_TYPES, UniversalBenchmark } from 'helpers/benchmarks';
import formatLargeNumber from 'utils/formatLargeNumber';
import { useProgramBenchmarks, useSalaries } from 'hooks/benchmarks';
import { PillProps } from 'components/atoms/InfoPill';

function getBenchmarkMainPillInfo(benchmark: UniversalBenchmark): PillProps {
  if (benchmark.universalType === 'occupation') {
    return { label: LOT_TYPES[benchmark.attributes.type], scheme: 'red' };
  }

  if (benchmark.universalType === 'custom') {
    return {
      label: benchmark.attributes.type === 'skillsFromTextBenchmark' ? 'Text' : 'Custom',
      scheme: 'yellow'
    };
  }

  throw new Error('Unknown benchmark type');
}

const BenchmarkCarousel: React.FC<{ programId: string }> = ({ programId }) => {
  const { data: benchmarks, isLoading: benchmarksIsLoading } = useProgramBenchmarks(programId);
  const { data: benchmarkSalaries } = useSalaries(benchmarks);

  if (benchmarksIsLoading) {
    return <></>;
  }

  return (
    <>
      <Carousel gradientLeft>
        {benchmarks.map(benchmark => {
          const pills: PillProps[] = [getBenchmarkMainPillInfo(benchmark)];

          if (benchmarkSalaries[benchmark.attributes.id]) {
            pills.push({
              label: `$${formatLargeNumber(benchmarkSalaries[benchmark.attributes.id])}`,
              scheme: 'green'
            });
          }

          return (
            <DetailedBenchmarkCardWithMargin
              key={benchmark.attributes.id}
              id={benchmark.attributes.id}
              tabIndex={-1}
              title={getBenchmarkTitle(benchmark)}
              renderDetailView={() => <BenchmarkPopup benchmark={benchmark} />}
              pills={pills}
            />
          );
        })}
      </Carousel>
    </>
  );
};

export default BenchmarkCarousel;

const DetailedBenchmarkCardWithMargin = styled(DetailedBenchmarkCard)`
  margin-right: 1rem;
`;
