import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  PresentationalBenchmark,
  UniversalBenchmark,
  UniversalCustomBenchmark,
  customBenchmarkToUniversal,
  getOccupationIdsOfType,
  occupationBenchmarkToUniversal,
  programHasBenchmarks
} from 'helpers/benchmarks';
import formatLargeNumber from 'utils/formatLargeNumber';
import { PillProps } from 'components/atoms/InfoPill';

import {
  useCustomBenchmarksTotals,
  useLightcastOccupationsByProgramId,
  useLightcastOccupationsSalary
} from './jpaHooks';
import { useCustomBenchmarksByProgramId, useProgram, useSkillsInProgram } from './curricularSkills';
import { useCipRelatedOccupations } from './classificationHooks';
import { useRelatedOccupations } from './similarityHooks';

export interface UsePresentationalBenchmarksOptions {
  includeSalaries?: boolean;
}

export function usePresentationBenchmarks(
  benchmarks: UniversalBenchmark[],
  options: UsePresentationalBenchmarksOptions = {}
) {
  const {
    data: salaries,
    isLoading: salariesIsLoading,
    error: salariesError
  } = useSalaries(options.includeSalaries ? benchmarks : []);

  const presentationalBenchmarks = useMemo(() => {
    const presentational: PresentationalBenchmark[] = [];

    for (const benchmark of benchmarks) {
      const pills: PillProps[] = [];

      if (benchmark.universalType === 'occupation') {
        pills.push({ label: 'Occupation', scheme: 'red' });
      }

      if (benchmark.universalType === 'custom') {
        pills.push({
          label: benchmark.attributes.type === 'skillsFromTextBenchmark' ? 'Text' : 'Custom',
          scheme: 'yellow'
        });
      }

      const salary = salaries[benchmark.attributes.id];
      if (salary) {
        pills.push({ label: `$${formatLargeNumber(salary)}`, scheme: 'green' });
      }

      if (benchmark.universalType === 'occupation') {
        presentational.push({ name: benchmark.attributes.name, pills });
        continue;
      }

      if (benchmark.universalType === 'custom') {
        presentational.push({ name: benchmark.attributes.title, pills });
        continue;
      }
    }

    return presentational;
  }, [salaries]);

  return { data: presentationalBenchmarks, isLoading: salariesIsLoading, error: salariesError };
}

export function useOccupationBenchmarkSalaries(benchmarks: UniversalBenchmark[]) {
  const {
    data: careerAreaSalaries,
    isLoading: careerAreaSalariesIsLoading,
    error: careerAreaSalariesError
  } = useLightcastOccupationsSalary(
    'career_area',
    getOccupationIdsOfType(benchmarks, 'career_area')
  );

  const {
    data: occupationGroupSalaries,
    isLoading: occupationGroupSalariesIsLoading,
    error: occupationGroupSalariesError
  } = useLightcastOccupationsSalary(
    'occupation_group',
    getOccupationIdsOfType(benchmarks, 'occupation_group')
  );

  const {
    data: occupationSalaries,
    isLoading: occupationSalariesIsLoading,
    error: occupationSalariesError
  } = useLightcastOccupationsSalary('occupation', getOccupationIdsOfType(benchmarks, 'occupation'));

  const {
    data: specializedOccupationSalaries,
    isLoading: specializedOccupationSalariesIsLoading,
    error: specializedOccupationSalariesError
  } = useLightcastOccupationsSalary(
    'specialized_occupation',
    getOccupationIdsOfType(benchmarks, 'specialized_occupation')
  );

  const isLoading =
    careerAreaSalariesIsLoading ||
    occupationGroupSalariesIsLoading ||
    occupationSalariesIsLoading ||
    specializedOccupationSalariesIsLoading;

  const error =
    careerAreaSalariesError ||
    occupationGroupSalariesError ||
    occupationSalariesError ||
    specializedOccupationSalariesError;

  const merge = useMemo<Record<string, number>>(
    () => ({
      ...careerAreaSalaries,
      ...occupationGroupSalaries,
      ...occupationSalaries,
      ...specializedOccupationSalaries
    }),
    [careerAreaSalaries, occupationGroupSalaries, occupationSalaries, specializedOccupationSalaries]
  );

  return { data: merge, isLoading, error };
}

export function useCustomBenchmarksSalaries(benchmarks: UniversalBenchmark[]) {
  const customBenchmarks = useMemo(
    () =>
      benchmarks
        .filter((benchmark): benchmark is UniversalCustomBenchmark => {
          return benchmark.universalType === 'custom';
        })
        .map(benchmark => benchmark.attributes),
    [benchmarks]
  );

  const {
    data: benchmarkTotals,
    isLoading: benchmarkTotalsIsLoading,
    error: benchmarkTotalsError
  } = useCustomBenchmarksTotals(customBenchmarks, ['median_salary'], {
    enabled: !!customBenchmarks.length
  });

  const benchmarkSalaries = useMemo(() => {
    const salaries: Record<string, number> = {};

    if (!benchmarkTotals) {
      return salaries;
    }

    for (const id in benchmarkTotals) {
      const totals = benchmarkTotals[id];

      const salary = totals.median_salary;
      if (!salary) {
        continue;
      }

      salaries[id] = salary;
    }

    return salaries;
  }, [benchmarkTotals]);

  return {
    data: benchmarkSalaries,
    isLoading: benchmarkTotalsIsLoading,
    error: benchmarkTotalsError
  };
}

export function useSalaries(benchmarks: UniversalBenchmark[]) {
  const {
    data: occupationSalaries,
    isLoading: occupationIsLoading,
    error: occupationError
  } = useOccupationBenchmarkSalaries(benchmarks);

  const {
    data: customSalaries,
    isLoading: customIsLoading,
    error: customError
  } = useCustomBenchmarksSalaries(benchmarks);

  const salaries = useMemo(
    () => ({ ...customSalaries, ...occupationSalaries }),
    [occupationSalaries, customSalaries]
  );

  return {
    data: salaries,
    isLoading: customIsLoading || occupationIsLoading,
    error: customError || occupationError
  };
}

export function useProgramOccupations(programId: string) {
  const {
    data: benchmarks,
    isLoading: benchmarksIsLoading,
    error: benchmarksError
  } = useProgramBenchmarks(programId);

  const occupations = useMemo(() => pickOccupations(benchmarks), [benchmarks]);

  return {
    data: occupations,
    isLoading: benchmarksIsLoading,
    error: benchmarksError
  };
}

export function useProgramCustomBenchmarks(programId: string) {
  const {
    data: benchmarks,
    isLoading: benchmarksIsLoading,
    error: benchmarksError
  } = useProgramBenchmarks(programId);

  const customBenchmarks = useMemo(() => pickCustomBenchmarks(benchmarks), [benchmarks]);

  return {
    data: customBenchmarks,
    isLoading: benchmarksIsLoading,
    error: benchmarksError
  };
}

export function useProgramBenchmarks(programId: string) {
  const { data: program, isLoading: programIsLoading, error: programError } = useProgram(programId);
  const flags = useFlags();

  const {
    data: initialLightcastOccupations,
    isLoading: initialLightcastOccupationsIsLoading,
    error: initialLightcastOccupationsError
  } = useLightcastOccupationsByProgramId(programId);

  const {
    data: skills,
    isLoading: skillsIsLoading,
    error: skillsError
  } = useSkillsInProgram(programId);
  const {
    customBenchmarks,
    isLoading: customBenchmarksIsLoading,
    error: customBenchmarksError
  } = useCustomBenchmarksByProgramId(programId, false);

  const requiredSkillsInProgram = !skills ? [] : skills.requiredSkillsInProgram;
  const skillsForMatching = requiredSkillsInProgram.length
    ? requiredSkillsInProgram
    : skills?.skillsInProgram || [];
  const {
    data: cipRelatedLightcastOccupations,
    isLoading: cipRelatedLightcastOccupationsIsLoading,
    error: cipRelatedLightcastOccupationsError
  } = useCipRelatedOccupations(program?.data.attributes.cipCode, ['unique_postings']);

  const {
    data: relatedLightcastOccupations,
    isLoading: relatedLightcastOccupationsIsLoading,
    error: relatedLightcastOccupationsError
  } = useRelatedOccupations('occupation', skillsForMatching, 4);

  const error =
    programError ||
    initialLightcastOccupationsError ||
    customBenchmarksError ||
    cipRelatedLightcastOccupationsError ||
    relatedLightcastOccupationsError ||
    skillsError;

  const isLoading =
    programIsLoading ||
    initialLightcastOccupationsIsLoading ||
    customBenchmarksIsLoading ||
    cipRelatedLightcastOccupationsIsLoading ||
    relatedLightcastOccupationsIsLoading ||
    skillsIsLoading;

  const lightcastOccupations = useMemo<LightcastOccupationWithPossibleScore[]>(() => {
    // No need to compute if nothing is going to be displayed
    if (isLoading || !program) {
      return [];
    }

    const benchmarksExist = programHasBenchmarks(program.data.attributes);
    if (benchmarksExist) {
      return initialLightcastOccupations;
    }

    if (
      program.data.attributes.cipCode &&
      cipRelatedLightcastOccupations.length &&
      flags.cipMatches
    ) {
      return cipRelatedLightcastOccupations
        .sort((a, b) => (b.metrics.unique_postings || 0) - (a.metrics.unique_postings || 0))
        .slice(0, 4)
        .map(occupation => ({ id: occupation.id, name: occupation.name, type: occupation.type }));
    }

    return (
      relatedLightcastOccupations?.map(occupation => ({
        id: occupation.id,
        name: occupation.name,
        type: 'occupation'
      })) || []
    );
  }, [
    program,
    isLoading,
    initialLightcastOccupations,
    relatedLightcastOccupations,
    cipRelatedLightcastOccupations
  ]);

  const benchmarks = useMemo<UniversalBenchmark[]>(
    () => [
      ...lightcastOccupations.map(occupationBenchmarkToUniversal),
      ...customBenchmarks
        .filter(benchmark => benchmark.selected)
        .map(benchmark => customBenchmarkToUniversal(benchmark.attributes))
    ],
    [lightcastOccupations, customBenchmarks]
  );

  return { data: benchmarks, isLoading, error };
}

function pickOccupations(benchmarks: UniversalBenchmark[]) {
  const occupations: LightcastOccupation[] = [];

  for (const benchmark of benchmarks) {
    if (benchmark.universalType === 'occupation') {
      occupations.push(benchmark.attributes);
    }
  }

  return occupations;
}

function pickCustomBenchmarks(benchmarks: UniversalBenchmark[]) {
  const customBenchmarks: Benchmark[] = [];

  for (const benchmark of benchmarks) {
    if (benchmark.universalType === 'custom') {
      customBenchmarks.push(benchmark.attributes);
    }
  }

  return customBenchmarks;
}
