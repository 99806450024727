import React, { useEffect } from 'react';
import { asyncMakeStore, AsyncStoreProps } from './makeStore';

import { getSite } from 'services/sites';

interface CurrentSiteState extends SiteAttributes {
  isLoading?: boolean;
}
interface UpdateCurrentSiteAction {
  type: 'SET_CURRENT_SITE';
  payload: CurrentSiteState;
}

interface SetCurrentSiteLoadingAction {
  type: 'SET_CURRENT_SITE_LOADING';
}

type CurrentSiteAction = UpdateCurrentSiteAction | SetCurrentSiteLoadingAction;

const currentSiteReducer = (
  state: CurrentSiteState,
  action: CurrentSiteAction
): CurrentSiteState => {
  switch (action.type) {
    case 'SET_CURRENT_SITE':
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case 'SET_CURRENT_SITE_LOADING':
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

const [UnwrappedCurrentSiteProvider, useCurrentSiteDispatch, useCurrentSiteState] = asyncMakeStore<
  CurrentSiteState,
  CurrentSiteAction
>(currentSiteReducer);

interface ProviderProps extends AsyncStoreProps<CurrentSiteState> {
  currentSiteString: string;
}

// This component updates the currentSiteStore state when the currentSite prop changes.
const KeepStateUpdated: React.FC<{ currentSiteString: string }> = ({ currentSiteString }) => {
  const dispatch = useCurrentSiteDispatch();
  const currentSiteState = useCurrentSiteState();

  useEffect(() => {
    let isCurrentRequest = true;
    if (currentSiteString !== currentSiteState.site) {
      dispatch({ type: 'SET_CURRENT_SITE_LOADING' });
      getSite(currentSiteString).then(newSiteState => {
        if (isCurrentRequest) {
          dispatch({
            type: 'SET_CURRENT_SITE',
            payload: newSiteState
          });
        }
      });
    }
    return () => {
      isCurrentRequest = false;
    };
  }, [currentSiteString]);

  return null;
};

// This ensures that the currentSiteStore will always be updated when the user
// sets a different currentSite to their profile.

const CurrentSiteProvider: React.FC<ProviderProps> = ({
  children,
  currentSiteString,
  ...props
}) => {
  return (
    <UnwrappedCurrentSiteProvider {...props}>
      <KeepStateUpdated currentSiteString={currentSiteString} />
      {children}
    </UnwrappedCurrentSiteProvider>
  );
};

// We don't export the dispatch for now, since the site doesn't need to be
// updated anywhere.  Once we need to allow users to update site settings, we
// may want to think through a better way to always keep the currentSite loaded
// into state.
export { CurrentSiteProvider, useCurrentSiteState };
