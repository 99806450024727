import React from 'react';
import { View } from '@react-pdf/renderer';
import { lightGray } from 'utils/colors';

export const HorizontalLine: React.FC = () => {
  return <View style={{ height: 1, backgroundColor: lightGray }} />;
};

export const VerticalLine: React.FC = () => {
  return <View style={{ width: 1, height: '100%', backgroundColor: lightGray }} />;
};
