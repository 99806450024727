import React from 'react';
import Toggle from 'components/atoms/Toggle';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { darkGray } from 'utils/colors';

interface LabeledToggleProps {
  value: boolean;
  onLabel: string;
  offLabel: string;
  onValueChange: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

export const LabeledToggle: React.FC<LabeledToggleProps> = ({
  value,
  onLabel,
  offLabel,
  onValueChange,
  disabled
}) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        cursor: pointer;
      `}
    >
      <span
        aria-hidden
        css={css`
          color: ${value ? darkGray : 'inherit'};
          transition: color 0.5s;
          font-size: 1.4rem;
          font-weight: bold;
        `}
        onClick={() => onValueChange(oldValue => !oldValue)}
      >
        {offLabel}
      </span>
      <Toggle
        disabled={disabled}
        aria-label="published"
        value={value}
        onValueChange={onValueChange}
        css={css`
          margin: 0 0.8rem;
        `}
      />
      <span
        aria-hidden
        css={css`
          color: ${value ? 'inherit' : darkGray};
          transition: color 0.5s;
          font-size: 1.4rem;
          font-weight: bold;
        `}
        onClick={() => onValueChange(oldValue => !oldValue)}
      >
        {onLabel}
      </span>
    </div>
  );
};
