import dayjs from 'dayjs';
import { FilteredTaughtSoughtSkills, TaughtState } from 'hooks/programInsights';
import {
  black,
  white,
  citrine,
  lightCitrine,
  skillTrendsSoughtDark,
  skillTrendsSoughtLabel,
  skillTrendsSoughtLight,
  skillTrendsTaughtDark,
  skillTrendsTaughtLabel,
  skillTrendsTaughtLight
} from 'utils/colors';
import { findIndicesOfTargetMonths } from 'utils/getIndicesForTargetMonths';
import { calculateCoefficientVariation } from 'utils/getStandardDeviationForArray';

export interface SkillAreaData extends CondensedSkills {
  areaColor: string;
  areaContrastColor: string;
  data: VictoryCoordinate[];
  labelColor: string;
  labelHeight: number;
  skillId: string;
  skillName: string;
  taughtState: TaughtState;
  isDeviationSkill: boolean;
}

export interface VictoryCoordinate {
  x: number;
  y: number;
}

export interface BarChartCoordinate extends VictoryCoordinate {
  y0: number;
}

export interface CondensedSkills {
  taughtState?: TaughtState;
  condensedTaughtSkills?: { id: string; name: string }[];
  condensedSoughtSkills?: { id: string; name: string }[];
  hypotheticalSkills?: { id: string; name: string }[];
}

type CondensedBucket = JPATimeseriesResponseBucket & CondensedSkills;

export type SkillTypesFilter = 'demand' | 'variation';

export interface TimeseriesGraphData {
  skillAreaData: SkillAreaData[];
  programTaughtSoughtSkillsInfo: FilteredTaughtSoughtSkills;
  timeFrameCoordinateValues: BarChartCoordinate[];
  numberOfTimeSeries: number;
  lastTaughtSkillIndex: number;
  totalTaughtSkillCoordinates: VictoryCoordinate[];
  quarterIntervals: number[];
}

export const buildSkillData = (
  taughtTimeseriesData: JPATimeseriesResponseBucket[],
  soughtTimeseriesData: JPATimeseriesResponseBucket[],
  hypotheticalSkillIds: string[],
  yMetric: JPATotalsMetric,
  programTaughtSoughtSkillsInfo: FilteredTaughtSoughtSkills,
  numberOfSkillsToShow: number
): TimeseriesGraphData | undefined => {
  const fullTimeseriesData = [...taughtTimeseriesData, ...soughtTimeseriesData];
  if (fullTimeseriesData.length) {
    const taughtColors = {
      dark: skillTrendsTaughtDark,
      light: skillTrendsTaughtLight,
      label: skillTrendsTaughtLabel,
      contrast: white
    };
    const soughtColors = {
      dark: skillTrendsSoughtDark,
      light: skillTrendsSoughtLight,
      label: skillTrendsSoughtLabel,
      contrast: white
    };
    const hypotheticalColors = {
      dark: citrine,
      light: lightCitrine,
      label: citrine,
      contrast: black
    };

    const numberOfTimeSeries =
      fullTimeseriesData[0].timeseries.day?.length ||
      fullTimeseriesData[0].timeseries.month?.length ||
      12;
    const skillAreaData: SkillAreaData[] = [];
    const skillCoordinates: VictoryCoordinate[][] = [];
    const timeFrameCoordinateValues: BarChartCoordinate[] = [];
    const xValMonths = fullTimeseriesData[0].timeseries.month;
    const xValDays = fullTimeseriesData[0].timeseries.day;
    const xVals = xValMonths || xValDays;
    const percentThresholdToCondense = 2.5;

    const topDeviatedTaughtSkills = Object.values(
      reorderArraysByDeviation(taughtTimeseriesData).slice(0, 5)
    ).map(skill => skill.name);
    const topDeviatedSoughtSkills = Object.values(
      reorderArraysByDeviation(soughtTimeseriesData).slice(0, 5)
    ).map(skill => skill.name);

    // Condense lower ranked buckets into 'other' categories
    const [condensedBuckets, lastTaughtSkillIndex] = condenseBuckets(
      taughtTimeseriesData,
      soughtTimeseriesData,
      numberOfSkillsToShow,
      hypotheticalSkillIds,
      yMetric,
      programTaughtSoughtSkillsInfo,
      numberOfTimeSeries,
      percentThresholdToCondense
    );

    // Calculates the total metric counts across every skill by day or month.
    // Total for months expected data format = number[] where yMetricTotals[0] = first time series/ month total count, totalForMonths[1] = second time series/month total count, etc..
    const yMetricTotals = calculateTotalsPerTimeUnit(condensedBuckets, yMetric);

    let heightOfCreatedSkillAreas = 0;

    // Creates the skillAreaData object that gets used in the graph
    // Expected data structure for skillAreaData = [{}, {}, ...] where each object is the data for one area on the graph
    // The data attributes needed inside each object are -
    // skillAreaData = {
    //   areaColor: hex color val (index % 2 === 0 ? colorScheme.dark : colorScheme.light),
    //   data: skillCoordinates {x: Date, y: number (from percentage conversion for skill counts)},
    //   labelColor: hex color val (use color scheme for this)
    //   labelHeight: number (height for the right hand skill name labels to be displayed at.. this is based on the y coordinates from the last month for the current skill and skill areas below it)
    //   name: string (skill name or Other Skills for the grouped skills)
    // }
    condensedBuckets.forEach((skill, index) => {
      const yVals = skill.timeseries[yMetric];
      const isCondensedSection =
        !!skill.condensedTaughtSkills ||
        !!skill.condensedSoughtSkills ||
        !!skill.hypotheticalSkills;
      const taughtState = skill.taughtState || 'taught';
      const colorScheme =
        taughtState === 'taught'
          ? taughtColors
          : taughtState === 'hypothetical'
          ? hypotheticalColors
          : soughtColors;

      if (!skillCoordinates[index]) {
        skillCoordinates[index] = [];
      }

      if (xVals && yVals) {
        // creates the skill coordinates for the individual skill into correct data types; converts from metric total to percentage based number
        for (let i = 0; i < numberOfTimeSeries; i++) {
          skillCoordinates[index][i] = {
            x: dayjs(xVals[i]).valueOf(),
            y: (yVals[i] / yMetricTotals[i]) * 100
          };
        }

        // calculates the height of all skill areas below the current skill and is used to calculate the label height
        if (index > 0) {
          heightOfCreatedSkillAreas += skillCoordinates[index - 1][numberOfTimeSeries - 1].y;
        }

        // creates the skillAreaData object that gets used directly by graph
        skillAreaData[index] = {
          areaColor: index % 2 === 0 ? colorScheme.dark : colorScheme.light,
          areaContrastColor: colorScheme.contrast,
          data: skillCoordinates[index],
          labelColor: colorScheme.label,
          labelHeight:
            skillCoordinates[index][numberOfTimeSeries - 1].y / 2 + heightOfCreatedSkillAreas,
          skillId: skill.name,
          skillName: isCondensedSection
            ? skill.name
            : taughtState === 'taught'
            ? programTaughtSoughtSkillsInfo.taughtSkillsInTargetOutcomes[skill.name]?.name
            : programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes[skill.name]?.name,
          taughtState,
          condensedTaughtSkills: skill.condensedTaughtSkills,
          condensedSoughtSkills: skill.condensedSoughtSkills,
          hypotheticalSkills: skill.hypotheticalSkills,
          isDeviationSkill: !!(
            topDeviatedTaughtSkills.includes(skill.name) ||
            topDeviatedSoughtSkills.includes(skill.name)
          )
        };
      }
    });

    // creates the timeFrameCoordinateValues data object used directly by graph to generate vertical gray bars for where data points are
    // timeFrameCoordinateValues expected data structure = [{}, {}, ...] where {} are the coordinates
    // {x: Date (based on dates from timeseries request), y: always 0, y0: always 100}
    if (xVals) {
      xVals.forEach((date, index) => {
        if (index !== 0 && index !== numberOfTimeSeries - 1) {
          timeFrameCoordinateValues[index] = {
            x: dayjs(date).valueOf(),
            y: 0,
            y0: 100
          };
        }
      });
    }

    // create total taught skill coordinates by summing taught skill y values
    const totalTaughtSkillCoordinates = skillAreaData
      .slice(0, lastTaughtSkillIndex + 1)
      .reduce<VictoryCoordinate[]>((acc, skill, index) => {
        for (let i = 0; i < numberOfTimeSeries; i++) {
          index === 0
            ? (acc[i] = { x: skill.data[i].x, y: skill.data[i].y })
            : (acc[i].y += skill.data[i].y);
        }
        return acc;
      }, []);

    const quarterIntervals = findIndicesOfTargetMonths(xValMonths || []);

    return {
      skillAreaData,
      programTaughtSoughtSkillsInfo,
      timeFrameCoordinateValues,
      numberOfTimeSeries,
      lastTaughtSkillIndex,
      totalTaughtSkillCoordinates,
      quarterIntervals
    };
  }
};

export const calculateTotalsPerTimeUnit = (
  buckets: JPATimeseriesResponseBucket[],
  metric: JPATotalsMetric
) => {
  const totals: number[] = [];
  buckets.forEach(skill => {
    const yVals = skill.timeseries[metric];
    if (yVals) {
      yVals.forEach((value, i) => {
        if (totals[i] === undefined) {
          totals[i] = 0;
        }
        totals[i] += value;
      });
    }
  });
  return totals;
};

export const condenseBuckets = (
  taughtBuckets: (JPATimeseriesResponseBucket & CondensedSkills)[],
  soughtBuckets: (JPATimeseriesResponseBucket & CondensedSkills)[],
  numberToShow: number,
  hypotheticalSkillIds: string[],
  metric: JPATotalsMetric,
  programTaughtSoughtSkillsInfo: FilteredTaughtSoughtSkills,
  numberOfTimeSeries: number,
  additionalCondensingPercentThreshold: number
): [condensedSkills: typeof taughtBuckets, lastTaughtIndex: number] => {
  const orderedTaughtBuckets = [...taughtBuckets].reverse();
  const orderedSoughtBuckets = [...soughtBuckets].reverse();

  const postingCountTotals = [...taughtBuckets, ...soughtBuckets].reduce((acc: number, skill) => {
    acc += skill.timeseries.unique_postings?.[numberOfTimeSeries - 1] || 0;
    return acc;
  }, 0);

  const filteredSoughtBuckets = orderedSoughtBuckets.filter(
    skill => !hypotheticalSkillIds.find(id => skill.name === id)
  );
  const condensedBuckets: typeof taughtBuckets = [];
  let lastTaughtSkillIndex = -1;

  // taught section
  reduceTimeseriesSection(
    orderedTaughtBuckets,
    'taught',
    metric,
    numberToShow,
    condensedBuckets,
    programTaughtSoughtSkillsInfo,
    postingCountTotals,
    numberOfTimeSeries,
    additionalCondensingPercentThreshold
  );

  lastTaughtSkillIndex = condensedBuckets.length - 1;

  // hypothetical section
  if (hypotheticalSkillIds) {
    const hypotheticalSkillsToCondense = orderedSoughtBuckets.filter(skill =>
      hypotheticalSkillIds.find(id => skill.name === id)
    );

    if (hypotheticalSkillsToCondense.length) {
      reduceTimeseriesSection(
        hypotheticalSkillsToCondense,
        'hypothetical',
        metric,
        numberToShow,
        condensedBuckets,
        programTaughtSoughtSkillsInfo,
        postingCountTotals,
        numberOfTimeSeries,
        additionalCondensingPercentThreshold
      );

      const numberOfHypotheticalSections = condensedBuckets.reduce(
        (count, bucket) => (bucket.taughtState === 'hypothetical' ? ++count : count),
        0
      );

      // move taught totals scatter plot to last hypothetical section
      lastTaughtSkillIndex += numberOfHypotheticalSections;
    }
  }

  // sought section
  reduceTimeseriesSection(
    filteredSoughtBuckets,
    'sought',
    metric,
    numberToShow,
    condensedBuckets,
    programTaughtSoughtSkillsInfo,
    postingCountTotals,
    numberOfTimeSeries,
    additionalCondensingPercentThreshold
  );

  return [condensedBuckets, lastTaughtSkillIndex];
};

const reduceTimeseriesSection = (
  sectionBuckets: CondensedBucket[],
  sectionType: TaughtState,
  metric: JPATotalsMetric,
  numberToShow: number,
  returnArray: CondensedBucket[],
  programTaughtSoughtSkillsInfo: FilteredTaughtSoughtSkills,
  latestPostingCountsTotals: number,
  numberOfTimeSeries: number,
  additionalCondensingPercentThreshold: number
): void => {
  let bucketsToReduce: CondensedBucket[] = [];
  let condensedName = '';
  let condensedInfo: CondensedSkills = { taughtState: sectionType };
  const unreducedBuckets: CondensedBucket[] = [];
  let topDeviatedSkills: string[] = [];

  if (sectionBuckets.length) {
    switch (sectionType) {
      case 'taught':
        topDeviatedSkills = Object.values(reorderArraysByDeviation(sectionBuckets).slice(0, 5)).map(
          s => s.name
        );

        bucketsToReduce = sectionBuckets.splice(0, sectionBuckets.length - numberToShow);

        // if bucket isn't big enough to show label, group into reduced bucket
        sectionBuckets.forEach((bucket, i) => {
          const latestCount = bucket.timeseries.unique_postings?.[numberOfTimeSeries - 1] || 0;
          if (
            (latestCount / latestPostingCountsTotals) * 100 <
            additionalCondensingPercentThreshold
          ) {
            bucketsToReduce.push(bucket);
          } else {
            unreducedBuckets.push({ ...bucket, ...condensedInfo });
          }
        });

        if (bucketsToReduce.length) {
          // if reduced bucket isn't big enough to show label, group one more skill into reduced bucket
          const condensedTotals = calculateTotalsPerTimeUnit(bucketsToReduce, metric);
          if (
            (condensedTotals[numberOfTimeSeries - 1] / latestPostingCountsTotals) * 100 <
              additionalCondensingPercentThreshold &&
            unreducedBuckets.length
          ) {
            bucketsToReduce.push(unreducedBuckets[0]);
            unreducedBuckets.splice(0, 1);
          }

          condensedName = `${numberToShow === 0 ? '' : 'Other'} Taught Skills (${
            bucketsToReduce.length
          })`;
          condensedInfo = {
            ...condensedInfo,
            condensedTaughtSkills: bucketsToReduce.map(skill => ({
              id: skill.name,
              name: topDeviatedSkills.includes(skill.name)
                ? `${
                    programTaughtSoughtSkillsInfo.taughtSkillsInTargetOutcomes[skill.name]?.name
                  } *`
                : programTaughtSoughtSkillsInfo.taughtSkillsInTargetOutcomes[skill.name]?.name
            }))
          };
        }

        break;
      case 'hypothetical':
        topDeviatedSkills = Object.values(reorderArraysByDeviation(sectionBuckets).slice(0, 5)).map(
          s => s.name
        );
        // if bucket isn't big enough to show label, group into reduced bucket
        sectionBuckets.forEach((bucket, i) => {
          const latestCount = bucket.timeseries.unique_postings?.[numberOfTimeSeries - 1] || 0;
          if (
            (latestCount / latestPostingCountsTotals) * 100 <
            additionalCondensingPercentThreshold
          ) {
            bucketsToReduce.push(bucket);
          } else {
            unreducedBuckets.push({ ...bucket, ...condensedInfo });
          }
        });

        if (bucketsToReduce.length) {
          // if reduced bucket isn't big enough to show label, group one more skill into reduced bucket
          const condensedTotals = calculateTotalsPerTimeUnit(bucketsToReduce, metric);
          if (
            (condensedTotals[numberOfTimeSeries - 1] / latestPostingCountsTotals) * 100 <
              additionalCondensingPercentThreshold &&
            unreducedBuckets.length
          ) {
            bucketsToReduce.push(unreducedBuckets[0]);
            unreducedBuckets.splice(0, 1);
          }

          condensedName = `Other Hypothetical Skills (${bucketsToReduce.length})`;
          condensedInfo = {
            ...condensedInfo,
            hypotheticalSkills: bucketsToReduce.map(skill => ({
              id: skill.name,
              name: topDeviatedSkills.includes(skill.name)
                ? `${
                    programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes[skill.name]?.name
                  } *`
                : programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes[skill.name]?.name
            }))
          };
        }

        break;
      case 'sought':
        topDeviatedSkills = Object.values(reorderArraysByDeviation(sectionBuckets).slice(0, 5)).map(
          s => s.name
        );

        bucketsToReduce = sectionBuckets.splice(0, sectionBuckets.length - numberToShow);

        // if bucket isn't big enough to show label, group into reduced bucket
        sectionBuckets.forEach((bucket, i) => {
          const latestCount = bucket.timeseries.unique_postings?.[numberOfTimeSeries - 1] || 0;
          if (
            (latestCount / latestPostingCountsTotals) * 100 <
            additionalCondensingPercentThreshold
          ) {
            bucketsToReduce.push(bucket);
          } else {
            unreducedBuckets.push({ ...bucket, ...condensedInfo });
          }
        });

        if (bucketsToReduce.length) {
          // if reduced bucket isn't big enough to show label, group one more skill into reduced bucket
          const condensedTotals = calculateTotalsPerTimeUnit(bucketsToReduce, metric);
          if (
            (condensedTotals[numberOfTimeSeries - 1] / latestPostingCountsTotals) * 100 <
              additionalCondensingPercentThreshold &&
            unreducedBuckets.length
          ) {
            bucketsToReduce.push(unreducedBuckets[0]);
            unreducedBuckets.splice(0, 1);
          }

          condensedName = `${numberToShow === 0 ? '' : 'Other'} Sought Skills (${
            bucketsToReduce.length
          })`;
          condensedInfo = {
            ...condensedInfo,
            condensedSoughtSkills: bucketsToReduce.map(skill => ({
              id: skill.name,
              name: topDeviatedSkills.includes(skill.name)
                ? `${
                    programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes[skill.name]?.name
                  } *`
                : programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes[skill.name]?.name
            }))
          };
        }

        break;
    }

    if (bucketsToReduce.length) {
      const condensedTotals = calculateTotalsPerTimeUnit(bucketsToReduce, metric);
      returnArray.push({
        name: condensedName,
        timeseries: {
          month: bucketsToReduce[0].timeseries.month,
          day: bucketsToReduce[0].timeseries.day,
          [metric]: condensedTotals
        },
        [metric]: condensedTotals.reduce((partialSum, a) => partialSum + a, 0),
        ...condensedInfo
      });
    }

    returnArray.push(...unreducedBuckets);
  }
};

function reorderArraysByDeviation(
  skills: JPATimeseriesResponseBucket[]
): JPATimeseriesResponseBucket[] {
  const deviations: { array: JPATimeseriesResponseBucket; deviation: number }[] = [];

  // Calculate deviations for each array
  for (const skill of skills) {
    const skillPostings = skill.timeseries.unique_postings;
    if (skillPostings) {
      const deviation = calculateCoefficientVariation(skillPostings);
      deviations.push({ array: skill, deviation });
    }
  }

  // Sort arrays based on deviation in descending order
  deviations.sort((a, b) => b.deviation - a.deviation);

  // Return reordered arrays
  return deviations.map(item => item.array);
}
