import React from 'react';
import styled from '@emotion/styled';

import ExternalLink from 'components/atoms/ExternalLink';
import TOSText from 'components/atoms/TOSText';
import DisclaimerText from 'components/atoms/DisclaimerText';
import FooterModal from 'components/molecules/FooterModal';
import ModalButton from 'components/molecules/ModalButton';

import { ReactComponent as Logo } from 'images/lightcastRed.svg';
import { lightGray, black } from 'utils/colors';
import footerLinks from 'utils/footerLinks';

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: 6rem;
  border-top: 2px solid ${lightGray};
  font-size: 1.4rem;
  padding-top: 1rem;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${black};
  margin-bottom: 1rem;
`;

const StyledModalButton = styled(ModalButton)`
  border: none;
  height: 1.5rem;
  background: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin-bottom: 1rem;
`;

const StyledExternalLinkList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  & > span {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  & > li {
    display: flex;
  }
`;

const CopyrightInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

const StyledLogo = styled(Logo)`
  height: 2rem;
  margin-left: 1rem;
`;

const AuthFooter: React.FC = () => {
  return (
    <StyledFooter>
      <StyledExternalLinkList>
        <span>Legal</span>
        <li>
          <StyledModalButton buttonText="Terms of Use">
            {() => (
              <FooterModal titleText="Terms of Use">
                <TOSText />
              </FooterModal>
            )}
          </StyledModalButton>
        </li>
        <li>
          <StyledExternalLink href={footerLinks.privacyPolicy.linkTo}>
            {footerLinks.privacyPolicy.linkText}
          </StyledExternalLink>
        </li>
        <li>
          <StyledModalButton buttonText="Disclaimer">
            {() => (
              <FooterModal titleText="Lightcast Disclaimer">
                <DisclaimerText />
              </FooterModal>
            )}
          </StyledModalButton>
        </li>
        <li>
          <StyledExternalLink href={footerLinks.checkStatus.linkTo}>
            {footerLinks.checkStatus.linkText}
          </StyledExternalLink>
        </li>
      </StyledExternalLinkList>
      <CopyrightInformation>
        <div>
          <span>Powered by</span>
          <StyledLogo />
        </div>
        <span>Copyright {new Date().getFullYear()}. All Rights Reserved.</span>
      </CopyrightInformation>
    </StyledFooter>
  );
};

export default AuthFooter;
