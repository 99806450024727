import React, { useEffect, useMemo } from 'react';
import { uniqueId } from 'lodash';
import styled from '@emotion/styled';

import DropdownFlyout, { DropdownFlyoutProps } from 'components/molecules/DropdownFlyout';
import { StyledHeaderAndBorder } from 'components/molecules/ChartSettingsDropdownButton';
import Popup from 'components/atoms/Popup';

import { ReactComponent as Info } from 'images/skillInfo.svg';
import { ReactComponent as ToastError } from 'images/toasterError.svg';
import { bgLightGray, lightGray, mediumLightGray, darkGray } from 'utils/colors';
import { ChartSettings } from 'store/programData';

interface JobPostingByBenchmarkFilterProps {
  isJobPostingByBenchmarkDisabled?: boolean;
  onChange: (value: ChartSettings) => void;
  settings: ChartSettings;
}

const JobPostingByBenchmarkFilter: React.FC<JobPostingByBenchmarkFilterProps> = ({
  isJobPostingByBenchmarkDisabled,
  onChange,
  settings
}) => {
  const jobPostingFilterRef = React.useRef<HTMLLabelElement>(null);

  const [isInfoTextOpen, setIsInfoTextOpen] = React.useState<boolean>(false);

  const jobPostingTotalsByBenchmarksId = useMemo(
    () => uniqueId('job-postings-totals-by-benchmarks-input-'),
    []
  );

  const filterByBenchmarksSelectables = [
    { value: false, label: 'All Job Postings' },
    { value: true, label: 'Benchmark Specific Job Postings' }
  ];

  useEffect(() => {
    if (isJobPostingByBenchmarkDisabled) {
      onChange({
        ...settings,
        filterPostingsByBenchmarks: filterByBenchmarksSelectables[0].value
      });
    }
  }, [isJobPostingByBenchmarkDisabled]);

  return (
    <>
      {isJobPostingByBenchmarkDisabled && (
        <JobPostingBenchmarkMaxWarning>
          <StyledToastError />
          The option below is only available when 10 or fewer benchmarks are selected.
        </JobPostingBenchmarkMaxWarning>
      )}
      <StyledHeaderAndBorder
        htmlFor={jobPostingTotalsByBenchmarksId}
        isDisabledAndShowingWarning={isJobPostingByBenchmarkDisabled}
        ref={jobPostingFilterRef}
      >
        Show Job Postings Totals for:
        <StyledInfoButton
          type="button"
          onClick={e => {
            e.stopPropagation();
            setIsInfoTextOpen(!isInfoTextOpen);
          }}
        >
          <Info />
        </StyledInfoButton>
      </StyledHeaderAndBorder>
      <div>
        <StyledDropdownFlyout
          id={jobPostingTotalsByBenchmarksId}
          dropDownName="countPostingsByBenchmarks"
          items={filterByBenchmarksSelectables}
          onSelect={selection =>
            onChange({
              ...settings,
              filterPostingsByBenchmarks: selection.value
            })
          }
          selected={
            isJobPostingByBenchmarkDisabled
              ? filterByBenchmarksSelectables[0]
              : filterByBenchmarksSelectables[settings.filterPostingsByBenchmarks ? 1 : 0]
          }
          isDisabled={isJobPostingByBenchmarkDisabled}
        />
      </div>
      {isInfoTextOpen && (
        <StyledPopup
          dimensions={{ height: 'fit-content', width: 'fit-content' }}
          anchorPointRef={jobPostingFilterRef}
          onClosePopup={() => {
            setIsInfoTextOpen(false);
          }}
        >
          <InfoText>
            This option either limits the displayed job posting values to those from Occupations
            selected for Benchmarks only, or job postings from all occupations over the last 12
            months.
          </InfoText>
        </StyledPopup>
      )}
    </>
  );
};

export default JobPostingByBenchmarkFilter;

const JobPostingBenchmarkMaxWarning = styled.div`
  display: flex;
  font-size: 1.2rem;
  padding-top: 1.5rem;
  border-top: solid ${lightGray};
  border-width: thin;
  margin: 1rem 0 1rem 0;
  font-style: italic;
`;

const StyledToastError = styled(ToastError)`
  fill: ${darkGray};
  align-self: center;
  width: 4rem;
`;

const StyledDropdownFlyout = styled((props: DropdownFlyoutProps<boolean>) => (
  <DropdownFlyout {...props} />
))`
  width: 100%;
  background: ${props => (props.isDisabled ? mediumLightGray : bgLightGray)};
`;

const StyledInfoButton = styled.button`
  background: none;
  border: none;
  float: right;
`;

const InfoText = styled.div`
  font-size: 1.4rem;
  text-align: center;
`;

const StyledPopup = styled(Popup)`
  margin-right: 1rem;
`;
