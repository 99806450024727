import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

import { Column, Paragraph, SubHeader } from '../components';
import { useCourseData } from './data';
import { BarChartPages } from '../barChart/barChartPages';

import {
  extractChartDataFromCategorizedSkills,
  segmentChartDataIntoColumns
} from '../program/skillCategory/helper';
import { CategorizedSkills } from 'hooks/useCategorizedSkills';

import { darkGray, darkBlue, contextBlue } from 'utils/colors';

export interface BarChartChartPagesProps {
  categorizedSkills?: CategorizedSkills;
  label?: string;
}

export const SkillCategory: React.FC = () => {
  const { categorizedSkills } = useCourseData();
  if (categorizedSkills) {
    const prepend = (
      <View
        style={{
          fontFamily: 'Inter',
          fontSize: 16,
          paddingTop: '0.5in',
          paddingLeft: '0.5in',
          paddingRight: '0.5in'
        }}
      >
        <Column gap={30}>
          <SubHeader text="Course Skills by Category" id="course_skills_by_category" />
          <Paragraph
            text="The graph and list of skills below show the list of skills identified for this course,
          grouped into their respective categories and subcategories. This information provides a
          higher level view of the course skills by showing the overall “skill shape” of the course."
          />
        </Column>
      </View>
    );

    const append = Object.keys(categorizedSkills).map((category, catIndex) => (
      <React.Fragment key={catIndex}>
        <View style={appendStyles.categoryContainer}>
          <Text id={category} style={appendStyles.categoryHeader}>
            {category}
          </Text>
        </View>
        {Object.keys(categorizedSkills[category]).map((subcategory, subCatIndex) => (
          <View wrap={false} style={appendStyles.subCategoryContainer} key={subCatIndex}>
            <Text style={appendStyles.subCategoryHeader}>{subcategory}</Text>
            <View style={appendStyles.skillContainer}>
              {segmentChartDataIntoColumns(categorizedSkills[category][subcategory], 3).map(
                (column, columnIndex) => (
                  <View key={columnIndex} style={appendStyles.column}>
                    {column.map((skill, skillIndex: number) => (
                      <Text key={skillIndex} style={appendStyles.skill}>
                        • {skill.name}
                      </Text>
                    ))}
                  </View>
                )
              )}
            </View>
          </View>
        ))}
      </React.Fragment>
    ));

    return (
      <>
        <BarChartPages
          data={extractChartDataFromCategorizedSkills(categorizedSkills)}
          order="descending"
          prepend={prepend}
          append={append}
          firstPageMaxBars={19}
          restPagesHeader={'Course Skills by Category (Cont.)'}
        />
      </>
    );
  } else {
    return <></>;
  }
};
const appendStyles = StyleSheet.create({
  categoryContainer: {
    width: '7.75in',
    backgroundColor: contextBlue,
    marginBottom: '0.23in',
    marginTop: '0.23in'
  },
  categoryHeader: {
    width: '100%',
    marginLeft: '0.1in',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    color: darkGray
  },
  subCategoryContainer: {
    marginLeft: '0.1in',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '0.1in'
  },
  subCategoryHeader: {
    marginBottom: '0.05in',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: darkBlue
  },
  skillContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%'
  },
  skill: {
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 'normal',
    marginRight: '0.2in',
    textOverflow: 'ellipsis',
    maxLines: 1,
    padding: 5
  },
  bullet: {
    fontSize: 10,
    marginTop: '0.08in'
  },
  underline: {
    width: '30vw',
    marginTop: '0.1in',
    borderBottomWidth: 1,
    borderBottomColor: darkBlue
  }
});
