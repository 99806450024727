import { useCallback, useEffect, useState } from 'react';

export interface EnablerHookResult<T> {
  isLoading: boolean;
  data: T;
  error?: unknown;
}

export interface UseHookEnablerOptions<T> {
  useHook(options: HookOptions): EnablerHookResult<T>;
  onFinished?(data: T): void;
  onError?(error: unknown): void;
}

export function useHookEnabler<T>(options: UseHookEnablerOptions<T>) {
  const [enabled, setEnabled] = useState(false);

  const result = options.useHook({ enabled });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (result.isLoading) {
      return;
    }

    if (result.error && options.onError) {
      options.onError(result.error);
    }

    if (options.onFinished) {
      options.onFinished(result.data);
    }

    setEnabled(false);
  }, [enabled, result.isLoading]);

  const start = useCallback(() => {
    if (enabled) {
      return;
    }

    setEnabled(true);
  }, [enabled]);

  return { start, isLoading: enabled };
}
