import React from 'react';
import styled from '@emotion/styled';
import Pill from 'components/atoms/InfoPill';

import { lightGray, mediumGray } from 'utils/colors';

const CourseTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseTitle = styled.h4`
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0.8rem 0 0.8rem;
`;

const StyledPill = styled(Pill)`
  margin-left: 0.8rem;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.8rem;
`;

const Chip = styled.span`
  background: ${lightGray};
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  padding: 0.1rem 0.8rem;
  font-size: 1.2rem;
`;

const RemainingSkillsText = styled.div`
  color: ${mediumGray};
  font-style: italic;
`;

const MAX_SKILL_COUNT = 20;
interface Props {
  title: string;
  courseId?: string;
  soughtSkillsInCourse?: readonly string[];
}

const CourseSkillsCard: React.FC<Props> = ({ title, courseId, soughtSkillsInCourse }) => {
  const soughtSkillsRemaining =
    soughtSkillsInCourse && soughtSkillsInCourse?.length - MAX_SKILL_COUNT;

  return (
    <>
      <CourseTitleWrapper>
        <CourseTitle>{title}</CourseTitle>
        {courseId && <StyledPill scheme={'blue'}>{courseId}</StyledPill>}
      </CourseTitleWrapper>
      <ChipContainer>
        {soughtSkillsInCourse?.slice(0, MAX_SKILL_COUNT).map((skill, index) => (
          <Chip key={index}>{skill}</Chip>
        ))}
        {!!soughtSkillsRemaining && soughtSkillsRemaining > 0 && (
          <RemainingSkillsText>and {soughtSkillsRemaining} more...</RemainingSkillsText>
        )}
      </ChipContainer>
    </>
  );
};

export default CourseSkillsCard;
