export interface TextHighlighter {
  highlight(code: string, start: number, end: number): void;
  getFragments(): TextHighlighterFragment[];
}

export interface TextHighlighterFragment {
  text: string;
  codes: string[];
}

/**
 * Chunky takes in a series of highlight commands and builds them into a list of chunks
 *
 * ```ts
 * const chunky = makeChunky('some text')
 *
 * chunky.highlight('red', 0, 4) // highlights "some" with code "red"
 * chunky.highlight('blue', 7, 9) // highlights "xt" with code "blue"
 * chunky.highlight('green', 2, 6) // highlights "me t" with code "green"
 *
 * const chunks = chunky.getChunks()
 *
 * assertEquals(chunks, [
 * 	{
 * 		codes: ['red'],
 * 		text: 'so',
 * 	},
 * 	{
 * 		codes: ['red', 'green'],
 * 		text: 'me',
 * 	},
 * 	{
 * 		codes: ['green'],
 * 		text: ' t',
 * 	},
 * 	{
 * 		codes: [],
 * 		text: 'e',
 * 	},
 * 	{
 * 		codes: ['blue'],
 * 		text: 'xt',
 * 	}
 * ])
 * ``` */
export function makeTextHighlighter(text: string): TextHighlighter {
  const chars: TextHighlighterFragment[] = text.split('').map(char => ({ text: char, codes: [] }));

  /** Highlight text from `start` (inclusive) to `end` (inclusive) with `code` */
  function highlight(code: string, start: number, end: number) {
    if (start < 0) {
      throw new Error('start index must be greater than 0');
    }

    if (end > chars.length) {
      throw new Error(
        `end index is out of bounds. Text has a length of ${chars.length}, but end index was ${end}`
      );
    }

    for (let index = start; index < end; index++) {
      chars[index].codes.push(code);
    }
  }

  function getFragments() {
    const chunks: TextHighlighterFragment[] = [];

    for (const char of chars) {
      const lastChunk = chunks[chunks.length - 1];

      if (!lastChunk) {
        chunks.push({ ...char }); // don't want to deal with referential equality between `chars` and `chunks`
        continue;
      }

      if (arraysAreEqual(lastChunk.codes, char.codes)) {
        lastChunk.text += char.text;
        continue;
      }

      chunks.push({ ...char });
    }

    return chunks;
  }

  return { highlight, getFragments };
}

function arraysAreEqual<T>(arr1: T[], arr2: T[]) {
  for (const arr1Item of arr1) {
    if (!arr2.includes(arr1Item)) {
      return false;
    }
  }

  for (const arr2Item of arr2) {
    if (!arr1.includes(arr2Item)) {
      return false;
    }
  }

  return true;
}
