import { routeTree, Node } from './routeTree';

export function getBreadCrumbPath(urlPath: string): Crumb[] {
  if (urlPath.startsWith('/')) {
    urlPath = urlPath.substring(1);
  }
  const [pathname] = urlPath.split('?');
  const parts = pathname.split('/');

  return getNextBreadCrumb([], parts, routeTree);
}

const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
const oidRegex = /^[a-fA-F0-9]{24}$/;
function isId(possibleId: string): boolean {
  return uuidRegex.test(possibleId) || oidRegex.test(possibleId);
}

function getNextBreadCrumb(crumbs: Crumb[], parts: string[], tree: readonly Node[]): Crumb[] {
  const part = parts.shift();
  if (!part) {
    return crumbs;
  }

  let currentId = '';

  const nextTree = tree.find(node => {
    if (isId(part)) {
      currentId = part;
      return node.path === ':id';
    }
    return node.path === part;
  });

  if (!nextTree) {
    return crumbs;
  }

  if (nextTree.crumbs) {
    for (const crumb of nextTree.crumbs) {
      const nextCrumb: Crumb = {
        name: crumb.name,
        href: crumb.href && crumb.href.replace(':id', currentId)
      };

      if (crumb.name.startsWith('pid:') || (crumb.name.startsWith('cid:') && !!currentId.length)) {
        nextCrumb.name = `${crumb.name.startsWith('pid:') ? 'pid:' : 'cid:'}${currentId}`;
      }

      crumbs.push(nextCrumb);
    }
  }

  if (!nextTree.children) {
    return crumbs;
  }

  return getNextBreadCrumb(crumbs, parts, nextTree.children);
}

export type Crumb = {
  name: string;
  href: string | null;
};
