import React from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import LoadingButton from 'components/atoms/LoadingButton';
import Button from 'components/atoms/Button';
import MessageModal from 'components/molecules/MessageModal';
import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { useProfileState } from 'store/profileStore';

import { bulkDeleteCourses } from 'services/curricularSkills';

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const DeleteButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 11.4rem;
`;

const DeleteCourseModal: React.FC<{
  courseIds: readonly string[];
  closeModal: () => void;
  forwardOnComplete?: boolean;
  mode?: 'selecting' | 'deselecting';
  selectedCount?: number;
  onDelete?: () => void;
}> = ({
  forwardOnComplete,
  closeModal,
  courseIds,
  mode = 'selecting',
  selectedCount = 1,
  onDelete
}) => {
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const { currentSite } = useProfileState();
  const queryClient = useQueryClient();
  const history = useHistory();
  const notification = getToastFnThatWeTreatLikeHook();

  const idQuery = mode === 'selecting' ? { in: courseIds } : { exclude: courseIds };

  return (
    <MessageModal
      title={`Delete ${selectedCount !== 1 ? `${selectedCount} Courses` : 'this Course'}?`}
      message={`All data associated with ${
        selectedCount !== 1 ? 'these courses' : 'this course'
      }  will be deleted. This action cannot be undone.`}
    >
      <ButtonsWrapper>
        <StyledButton scheme="outline" type="button" onClick={closeModal}>
          Cancel
        </StyledButton>
        <DeleteButton
          type="button"
          scheme="danger"
          data-cy="delete-course-modal_delete-button"
          isLoading={isDeleting}
          onClick={() => {
            setIsDeleting(true);
            bulkDeleteCourses({
              site: {
                in: [currentSite]
              },
              ids: idQuery
            })
              .then(() => {
                setIsDeleting(false);
                queryClient.invalidateQueries('courses');
                courseIds.forEach(courseId =>
                  queryClient.removeQueries(['course', courseId], { exact: true })
                );
                onDelete?.();
                closeModal();
                forwardOnComplete && history.push('/dashboard/courses');
                notification(
                  `Done! ${selectedCount !== 1 ? 'Courses' : 'Course'} deleted`,
                  'success'
                );
              })
              .catch(err => {
                setIsDeleting(false);
                notification(
                  `Sorry, we were unable to delete ${
                    selectedCount !== 1 ? 'those courses' : 'this course'
                  }. Please try again.`,
                  'error'
                );
                closeModal();
              });
          }}
        >
          Delete {selectedCount !== 1 ? 'Courses' : 'Course'}
        </DeleteButton>
      </ButtonsWrapper>
    </MessageModal>
  );
};

export default DeleteCourseModal;
