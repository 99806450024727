const EDUCATION_LEVEL_DISPLAY_NAMES: Record<string, string> = {
  'High school or GED': 'High School or GED',
  'Associate degree': 'Associate Degree',
  "Bachelor's degree": 'Bachelor Degree',
  "Master's degree": 'Master Degree',
  'Ph.D. or professional degree': 'Doctoral Degree'
};

export function formatEducationLevelName(jpaName: string): string {
  const result = EDUCATION_LEVEL_DISPLAY_NAMES[jpaName];
  if (!result) {
    return jpaName;
  }

  return result;
}

export function getEducationLevels() {
  return Object.values(EDUCATION_LEVEL_DISPLAY_NAMES);
}

export function skillabiEducationLevelsToJpa(skillabiLevels: string[]) {
  const jpaLevels: string[] = [];

  for (const level of skillabiLevels) {
    const entry = Object.entries(EDUCATION_LEVEL_DISPLAY_NAMES).find(
      ([_, value]) => value === level
    );

    if (!entry) {
      jpaLevels.push(level);
      continue;
    }

    jpaLevels.push(entry[0]);
  }

  return jpaLevels;
}
