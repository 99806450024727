import React from 'react';
import styled from '@emotion/styled';

import Badge from 'components/atoms/NumericalBadge';
import Checkbox from 'components/atoms/Checkbox';

import { bgDarkGray } from 'utils/colors';

export interface ControlFlowSortOption {
  id: string;
  leftLabel: string;
  rightLabel: string;
}

export interface ControlFlowFilterOption {
  id: string;
  label: string;
  count: number;
}

export interface DataFlowControlParams {
  sortOptions: ControlFlowSortOption[];
  selectedSortOption: string | null;
  onSort(sortByOptionId: string): unknown;

  filterOptions: ControlFlowFilterOption[];
  selectedFilterOptions: string[];
  onToggleFilter(filterByOptionId: string): unknown;
}

export const DataFlowControl: React.FC<DataFlowControlParams> = ({
  filterOptions,
  onToggleFilter,
  selectedFilterOptions,
  sortOptions,
  selectedSortOption,
  onSort
}) => {
  return (
    <Container>
      {!!sortOptions.length && (
        <Group>
          <GroupTitle>Sort:</GroupTitle>
          <GroupList>
            {sortOptions.map(option => (
              <div key={option.id}>
                <SortButton
                  persistBackground={selectedSortOption === option.id}
                  onClick={() => onSort(option.id)}
                >
                  <div>{option.leftLabel}</div>
                  {'->'}
                  <div>{option.rightLabel}</div>
                </SortButton>
              </div>
            ))}
          </GroupList>
        </Group>
      )}
      {!!filterOptions.length && (
        <Group>
          <GroupTitle>Filter:</GroupTitle>
          <GroupList>
            {filterOptions.map(option => (
              <GroupListItem key={option.id}>
                <Checkbox
                  checked={selectedFilterOptions.includes(option.id)}
                  onChange={() => onToggleFilter(option.id)}
                />
                <div>{option.label}</div>
                <Badge number={option.count} />
              </GroupListItem>
            ))}
          </GroupList>
        </Group>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GroupTitle = styled.div`
  font-weight: bold;
`;

const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
`;

const GroupListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SortButton = styled.button<{ persistBackground: boolean }>`
  border: none;
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ persistBackground }) => (persistBackground ? bgDarkGray : 'transparent')};
  transition: background 300ms;

  &:hover {
    background: ${bgDarkGray};
  }
`;
