import React from 'react';
import styled from '@emotion/styled';
import DefinitionTabs, { DefinitionTabsOption } from 'components/molecules/DefinitionTabs';
import { ReactComponent as SkillSignificanceImage } from 'images/skillSignificancePyramid.svg';
import { ReactComponent as SalaryBoostingImage } from 'images/salaryBoostingSkill.svg';
import { ReactComponent as TaughtOrSoughtImage } from 'images/taughtOrSought.svg';
import { ReactComponent as JobPostingsImage } from 'images/jobPostings.svg';
import { ReactComponent as GrowthImage } from 'images/percentGrowth.svg';
// import { ReactComponent as ProjectedGrowthImage } from 'images/projectedGrowth.svg';
import { darkSaphire, darkEmerald, darkSkillDetailsBlue } from 'utils/colors';
import greenBar from 'images/greenBar.png';

const SkillDefinitions: React.FC = () => {
  const options: DefinitionTabsOption[] = [
    {
      label: 'Taught or Sought',
      definitionRender: (
        <DefinitionContainer>
          <DefinitionBody>
            <DefinitionHeader>{'Taught or Sought '}</DefinitionHeader>
            {`represents whether a skill is an area of alignment for this program or a potential skill gap. This is shown through one of three indications:`}
            <DefinitionList>
              <ListItem>
                <Bar background={darkSaphire} border={darkSaphire} />
                {`Skill has been identified in this program's curriculum`}
              </ListItem>
              <ListItem>
                <Bar background={darkEmerald} border={darkEmerald} />
                {`Skill has not been found in this program's curriculum, but has been identified elsewhere at this institution`}
              </ListItem>
              <ListItem>
                <Bar background={`url(${greenBar}) center`} border={darkEmerald} />
                {`Skill has not been found in this program's curriculum and has not been identified anywhere else at this institution`}
              </ListItem>
            </DefinitionList>
          </DefinitionBody>
        </DefinitionContainer>
      ),
      imageRender: <TaughtOrSoughtImage />
    },
    {
      label: '# Job Postings',
      definitionRender: (
        <DefinitionContainer>
          <DefinitionBody>
            <DefinitionHeader>{'# Job Postings '}</DefinitionHeader>
            {`is the number of job postings that include a skill over the selected timeframe. This value is updated by and varies depending on region and other filter selections.`}
          </DefinitionBody>
        </DefinitionContainer>
      ),
      imageRender: <JobPostingsImage />
    },
    {
      label: '% Growth',
      definitionRender: (
        <DefinitionContainer>
          <DefinitionBody>
            <DefinitionHeader>{'% Growth '}</DefinitionHeader>
            {`is the increase or decrease of demand for a skill, over the selected timeframe (past x months), in job postings. This value is updated by and varies depending on region and other filter selections.`}
          </DefinitionBody>
        </DefinitionContainer>
      ),
      imageRender: <GrowthImage />
    },
    // {
    //   label: '% Projected Growth',
    //   definitionRender: (
    //     <DefinitionContainer>
    //       <DefinitionBody>
    //         <DefinitionHeader>{'% Projected Growth '} </DefinitionHeader>
    //         {`is the expected growth of a skill over the next 12 months across all occupations and regions. This gives an indication of how quickly a skill is growing or declining in the Labor Market.`}
    //       </DefinitionBody>
    //     </DefinitionContainer>
    //   ),
    //   imageRender: <ProjectedGrowthImage />
    // },
    {
      label: 'Salary Boosting Skill',
      definitionRender: (
        <DefinitionContainer>
          <DefinitionBody>
            <DefinitionHeader>{'Salary Boosting Skills '}</DefinitionHeader>
            {`are the skills for a given occupation which are likely to increase a worker's salary. This information is only available for occupation and specialized occupation level benchmarks. Hover over the icon in the table above to see for which selected occupations that skill is likely to increase a worker’s salary`}
          </DefinitionBody>
        </DefinitionContainer>
      ),
      imageRender: <SalaryBoostingImage />
    },
    {
      label: 'Skill Significance',
      definitionRender: (
        <DefinitionContainer>
          <DefinitionBody>
            <DefinitionHeader>{'Distinguishing skills (Dis.) '}</DefinitionHeader>
            {
              'are the advanced skills that are called for occasionally. An employee with these skills is likely more specialized and able to differentiate themselves from others in the same role.'
            }
          </DefinitionBody>
          <DefinitionBody>
            <DefinitionHeader>{'Defining skills (Def.) '}</DefinitionHeader>
            {
              'are the skills that represent day-to-day tasks and responsibilities of the job. An employee needs these skills to qualify for and perform successfully in this occupation.'
            }
          </DefinitionBody>
          <DefinitionBody>
            <DefinitionHeader>{'Necessary skills (Nec.) '}</DefinitionHeader>
            {
              'are the specialized skills required for that job and relevant across other similar jobs. An employee needs these skills as building blocks to perform the more complex Defining Skills.'
            }
          </DefinitionBody>
        </DefinitionContainer>
      ),
      imageRender: <SkillSignificanceImage />
    }
  ];

  return <DefinitionTabs options={options} changeOnHover />;
};

const DefinitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 2.1rem;
`;

const DefinitionHeader = styled.span<{ color?: string }>`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${props => props.color || darkSkillDetailsBlue};
`;

const DefinitionBody = styled.div`
  font-size: 1.4rem;
`;

const DefinitionList = styled.ul`
  font-size: 1.4rem;
  list-style-type: none;
  padding-left: 0rem;
`;
const ListItem = styled.li`
  margin: 1rem;
`;

const Bar = styled.span<{ background: string; border: string }>`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  background: ${({ background }) => background};
  border-radius: 0.4rem;
  margin-right: 0.8rem;
  border: 2px solid ${({ border }) => border};
  vertical-align: text-bottom;
`;

export default SkillDefinitions;
