import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from 'images/toasterError.svg';
import { darkGray, saphire } from 'utils/colors';

const MatchQualityNotice: React.FC<{
  programId?: string;
  includeEditPrompt?: boolean;
  programHasRequiredCourses?: boolean;
}> = ({ includeEditPrompt, programHasRequiredCourses, programId }) => {
  return (
    <Container>
      <Content>
        <StyledInfoIcon />
        <div>
          {programHasRequiredCourses ? (
            <>Matches are determined based on skills from Required Courses only</>
          ) : includeEditPrompt ? (
            <>
              Match quality may be improved by specifying the Required Courses for this program{' '}
              <StyledInternalLink to={`/edit/program/${programId}`}>here</StyledInternalLink>
            </>
          ) : (
            <>Match quality may be improved by specifying the Required Courses for this program</>
          )}
        </div>
      </Content>
    </Container>
  );
};

export default MatchQualityNotice;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: start;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }

  div {
    font-style: italic;
    font-size: 1.4rem;
    color: ${darkGray};
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  fill: ${darkGray};
  margin-top: 0.05rem;
`;

const StyledInternalLink = styled(Link)`
  text-decoration: none;
  color: ${saphire};

  &:hover {
    text-decoration: underline;
  }
`;
