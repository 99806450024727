import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import Button from 'components/atoms/Button';
import RegionPicker from 'components/molecules/RegionPicker';
import { DEFAULT_REGION_OBJECT } from 'components/organisms/SetupLocalStorage';

import useCurrentNation from 'hooks/useCurrentNation';
import { bgLightGray, darkerBorderGray, darkSaphire } from 'utils/colors';
import { useCurrentSiteState } from 'store/currentSiteStore';

interface RegionPickerFlyoutProps {
  onSave: (regionFilter: RegionFilter) => unknown;
  onClose: () => void;
  value: RegionFilter;
  headerLabel?: React.ReactElement;
  className?: string;
}

const RegionPickerFlyout: React.FC<RegionPickerFlyoutProps> = ({
  value,
  onSave,
  onClose,
  headerLabel,
  className
}) => {
  const [currentNation] = useCurrentNation();
  const { defaultRegion: currentSiteDefaultRegion } = useCurrentSiteState();

  const [selectedDefaultRegion, setSelectedDefaultRegion] = useState<
    Partial<Record<JPANation, RegionFilter>>
  >({});
  const [pickerValue, setPickerValue] = useState<RegionFilter>(value);

  const handleSave = (onSaveCallback: (regionFilterObject: RegionFilter) => void): void => {
    const selectedRegions = selectedDefaultRegion[currentNation]?.regions;
    onSaveCallback({
      filterType: selectedDefaultRegion[currentNation]?.filterType,
      regions: selectedRegions ? selectedRegions : value.regions
    });
    onClose();
  };

  const handleChanges = useCallback(
    (newRegion: Partial<Record<JPANation, RegionFilter>>) => {
      setSelectedDefaultRegion(newRegion);
      const newVal = newRegion[currentNation];
      if (newVal) {
        setPickerValue(newVal);
      }
    },
    [setSelectedDefaultRegion, setPickerValue]
  );

  return (
    <div className={className}>
      <RegionPicker value={pickerValue} headerLabel={headerLabel} onChange={handleChanges} />
      <FlyoutMenuFooter>
        <StyledResetButton
          onClick={() => {
            const defaultRegions = currentSiteDefaultRegion
              ? currentSiteDefaultRegion[currentNation]
              : DEFAULT_REGION_OBJECT[currentNation];
            setPickerValue(defaultRegions ? defaultRegions : value);
          }}
        >
          Restore Default
        </StyledResetButton>
        <StyledCancelButton type="button" scheme="outline" onClick={() => onClose()}>
          Cancel
        </StyledCancelButton>
        <Button
          data-cy="region-picker-flyout_save-button"
          type="button"
          onClick={() => handleSave(onSave)}
          disabled={!selectedDefaultRegion[currentNation]?.regions.length}
        >
          Save
        </Button>
      </FlyoutMenuFooter>
    </div>
  );
};

export default RegionPickerFlyout;

export const RegionContainerWrapper = styled.div`
  position: relative;
`;

const FlyoutMenuFooter = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(Button)`
  border: none;
  margin-left: auto;
`;

const StyledResetButton = styled.button`
  padding: 0.2rem;
  font-size: 1.3rem;
  color: ${darkSaphire};
  border: 1px solid ${darkerBorderGray};
  background: ${bgLightGray};
  border-radius: 0.4rem;
`;
