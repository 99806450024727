import React from 'react';
import styled from '@emotion/styled';

import InfoPill from 'components/atoms/InfoPill';
import { LOT_TYPES, UniversalBenchmark } from 'helpers/benchmarks';

const BenchmarkPills: React.FC<{
  benchmark: UniversalBenchmark;
}> = ({ benchmark }) => {
  if (benchmark.universalType === 'occupation') {
    return (
      <PillsContainer>
        <PillNoMargin scheme="red">{LOT_TYPES[benchmark.attributes.type]}</PillNoMargin>
      </PillsContainer>
    );
  }

  if (benchmark.universalType === 'custom') {
    return (
      <PillsContainer>
        <PillNoMargin scheme="yellow">
          {benchmark.attributes.type === 'skillsFromTextBenchmark' ? 'Text' : 'Custom'}
        </PillNoMargin>
      </PillsContainer>
    );
  }

  throw new Error('Invalid universalType');
};

export default BenchmarkPills;

const PillsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const PillNoMargin = styled(InfoPill)`
  margin-right: 0;
  margin-left: 0;
`;
