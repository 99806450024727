import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import SearchWithDropdownInput, {
  DropdownMenuItem
} from 'components/atoms/SearchWithDropdownInput';

import { fetchTitlesByTerm } from 'services/emsiProfiles';

import { ReactComponent as SearchIcon } from 'images/magnifying-glass.svg';
import { mediumLightGray, white } from 'utils/colors';

const StyledSearchIcon = styled(SearchIcon)`
  margin: 0.2rem -0.5rem 0 0.5rem;
  height: 3.5rem;
  width: 3.5rem;
  fill: ${mediumLightGray};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${white};
  box-shadow: var(--gray-box-shadow);
  border-radius: 0.4rem;
  margin: 2.4rem 4rem 2.4rem 0;
  max-width: 75rem;
  &:focus-within {
    outline: auto 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
  }
`;

type Title = { id: string; name: string; score: number };
const JobTitleSearchWithDropdown: React.FC<{
  onSearch: (title: Title) => void;
  className?: string;
}> = ({ onSearch }) => {
  const [selectedTitle, setSelectedTitle] = React.useState<Title>();
  const titleSearch = (text: string) => fetchTitlesByTerm(text);

  React.useEffect(() => {
    if (onSearch && selectedTitle) {
      onSearch(selectedTitle);
    }
  }, [selectedTitle]);

  return (
    <Wrapper>
      <StyledSearchIcon />
      <SearchWithDropdownInput
        aria-label=""
        search={titleSearch}
        selectedItems={selectedTitle ? [selectedTitle] : []}
        onSelectItem={title => setSelectedTitle(title)}
        labelText="search for a job title"
        placeholder="Search for a job title..."
        clearOnSelect={false}
        // Styled components do not work great with generic react components (since the type is technically a react element).
        // So we style for customization with the css tag
        css={css`
          flex-grow: 1;
          & input {
            background: transparent;
            border: none;

            &:focus {
              outline: none;
            }
          }
          & ul {
            width: calc(100% + 3.5rem);
            right: 0;
          }
        `}
      >
        {({ getItemProps, highlightedIndex, inputItems }) =>
          inputItems.map((item, i) => (
            <DropdownMenuItem
              key={item.id}
              highlighted={i === highlightedIndex}
              {...getItemProps({ item, index: i })}
            >
              <span>{item.name}</span>
            </DropdownMenuItem>
          ))
        }
      </SearchWithDropdownInput>
    </Wrapper>
  );
};

export default JobTitleSearchWithDropdown;
