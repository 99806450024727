import { useEffect, useRef } from 'react';

type Effect<T> = (deps?: T) => void | (() => void);

export const useEffectWithPreviousDep = <T_Deps extends unknown[]>(
  effect: Effect<T_Deps>,
  deps: [...T_Deps]
) => {
  const previousDeps = useRef<T_Deps>();

  useEffect(() => {
    const result = effect(previousDeps.current);
    previousDeps.current = deps;
    return result;
  }, deps);
};
