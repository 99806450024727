import React from 'react';
import styled from '@emotion/styled';
import { ahoy } from 'utils/colors';

interface FormContainerProps {
  children: React.ReactNode;
  className?: string;
  errorMessage?: string;
  onSubmit(): void;
}

const Error = styled.span`
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: ${ahoy};
  display: block;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContainer: React.FC<FormContainerProps> = ({
  children,
  onSubmit,
  errorMessage,
  className
}) => {
  return (
    <>
      <Form onSubmit={onSubmit} noValidate className={className}>
        {children}
      </Form>
      {errorMessage && <Error>{errorMessage}</Error>}
    </>
  );
};

export default FormContainer;
