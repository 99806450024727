import React from 'react';
import { Document } from '@react-pdf/renderer';

import { Cover } from '../cover';
import { ContentsTable } from '../contentsTable';
import coverImage from 'images/pdfCourseCover.png';

import {
  CourseDataProvider,
  CoursePdfExportData,
  ParagraphHighlight,
  HighlightedText
} from './data';
import { CourseContent } from './courseContent';
import { SkillCategory } from './skillCategory';
import { Overview } from './overview';
import { About } from '../about';

export interface CoursePdfExportProps {
  data: CoursePdfExportData;
}

export const CoursePdfExport: React.FC<CoursePdfExportProps> = ({ data }) => {
  return (
    <Document>
      <CourseDataProvider data={data}>
        <Cover
          subtitle={`${data.courseName} Report`}
          institution={data.institutionName}
          image={coverImage}
        />
        <ContentsTable />
        <Overview />
        <SkillCategory />
        <CourseContent />
        <About
          aboutText={`Skillabi translates your course content (learning outcomes, syllabi, etc.) into work-relevant skills that are recognized and valued by employers. This translation (i.e. "skillification") enables a direct apples-to-apples comparison of the skills taught at your institution and the skills sought by employers (and the learners who want to work for them).`}
          whatIsData={`At Lightcast we're passionate about providing meaningful labor market data for educational institutions and their students. Our labor market dataset includes data from three categories: government sources, online job posting activity, and professional profiles/resumes. This report leverages the Lightcast Open Skills Taxonomy to allow a direct translation of curricular content to the common language of skills.\n\nLightcast Skills are created by a team of economists and labor market analysts who have worked with skills, traditional labor market information, and real-time labor market data for over 20 years. Their efforts maintain our open-source library of 32,000+ skills gathered from hundreds of millions of online job postings, profiles, and resumes—updated every two weeks.`}
        />
      </CourseDataProvider>
    </Document>
  );
};

export type { CoursePdfExportData, ParagraphHighlight, HighlightedText };
