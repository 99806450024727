import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FieldError } from 'react-hook-form';

import { highlightParsedSkills, highlightRevealedSkill } from 'helpers/highlightParsedSkills';
import { PreprocessedSkill } from 'services/skills';

import { darkBlue, darkGray, contextBlue, highlightYellow, ahoy } from 'utils/colors';

// The linear gradient for the background is for hiding the second scrollbar on mac browsers
const HighlightedText = styled.div<{ error?: FieldError }>`
  flex-grow: 1;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 40rem;
  caret-color: ${darkBlue};
  padding: 1.2rem 0rem;
  font-size: 1.4rem;
  border: 1px solid ${({ error }: { error?: unknown }) => (error ? ahoy : 'transparent')};
  border-radius: 0.3rem;
  line-height: 1.6;
  white-space: pre-wrap;
  overscroll-behavior-y: none;
  ::placeholder {
    color: ${darkGray};
    font-style: italic;
  }
  position: relative;
`;

const HighlightableWrapper = styled.div<{ textareaHeight?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2.4rem 0;
  min-height: ${props => (props.textareaHeight ? props.textareaHeight : 'auto')};
`;

interface HighlightedTextDivProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  textareaHeight?: string;
  highlights: PreprocessedSkill[];
  resize?: string;
  revealedSkill?: string;
  contentEditable?: boolean;
  text?: string;
  title?: React.ReactNode;
}

const Highlight = styled.span`
  background-color: ${highlightYellow};
`;

const Context = styled.span`
  background-color: ${contextBlue};
`;

const highlightText = (text: string, key: string) => (
  <Highlight key={`highlight-${key}`}>{text}</Highlight>
);
const contextText = (text: string, key: string) => <Context key={`context-${key}`}>{text}</Context>;
const plainText = (text: string, key: string) => <span key={`plainText-${key}`}>{text}</span>;

const HighlightedTextDiv = React.forwardRef<HTMLDivElement, HighlightedTextDivProps>(
  (
    {
      highlights,
      revealedSkill,
      textareaHeight,
      resize = 'none',
      contentEditable = true,
      text,
      title,
      ...props
    },
    ref
  ) => {
    const highlightNodes = React.useMemo(() => {
      if (text) {
        if (revealedSkill) {
          return highlightRevealedSkill(
            text,
            plainText,
            highlightText,
            contextText,
            revealedSkill,
            highlights
          );
        }
        return highlightParsedSkills(text, highlightText, plainText, highlights);
      }
      return [];
    }, [highlights, text, revealedSkill]);

    return (
      <HighlightableWrapper {...{ textareaHeight }} className="text-area-wrapper">
        <HighlightedText
          css={css`
            resize: ${resize};
          `}
          {...props}
        >
          {highlightNodes}
        </HighlightedText>
      </HighlightableWrapper>
    );
  }
);

export default HighlightedTextDiv;
