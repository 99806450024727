import React, { useMemo } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Loading from 'components/atoms/Loading';
import { useParams } from 'react-router-dom';
import { useProgram } from 'hooks';
import { replaceBadChars } from 'helpers/highlightParsedSkills';

import CreateProgramForm from 'components/organisms/CreateProgramForm';

const CreateProgram: React.FC = () => {
  const { id, duplicateId } = useParams<{
    id: string | undefined;
    duplicateId: string | undefined;
  }>();
  const { data } = useProgram(duplicateId || id);

  const defaultValues = useMemo(() => {
    const { site, createdAt, updatedAt, title, description, skillabiType, ...rest } =
      data?.data.attributes || {};
    return {
      title: replaceBadChars(duplicateId ? `${title} [COPY]` : title),
      description: replaceBadChars(description),
      ...rest
    };
  }, [data, duplicateId]);

  if ((id || duplicateId) && !data) {
    return (
      <Loading
        css={css`
          height: 100vh;
          width: 100vw;
          position: absolute;
          top: 0;
          right: 0;
        `}
      />
    );
  } else {
    return <CreateProgramForm editProgramId={id} defaultValues={defaultValues} />;
  }
};

export default CreateProgram;
