const formatLargeNumber = (n: number): string =>
  n >= 1.0e9
    ? `${(n / 1.0e9).toFixed(2)}b`
    : n >= 1.0e6
    ? `${(n / 1.0e6).toFixed(2)}m`
    : n >= 1.0e3
    ? `${(n / 1.0e3).toFixed()}k`
    : n.toLocaleString();

export const ariaFormattedLargeNumber = (n: number): string => {
  const formattedString = formatLargeNumber(n);
  const ariaFormattedString = formattedString.endsWith('m')
    ? `${formattedString.slice(0, -1)} million`
    : formattedString.endsWith('k')
    ? `${formattedString.slice(0, -1)} thousand`
    : formattedString;

  return ariaFormattedString;
};

export default formatLargeNumber;
