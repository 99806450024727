import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Loading from 'components/atoms/Loading';
import Input from 'components/atoms/Input';

import { white, mediumLightGray } from 'utils/colors';

import { ReactComponent as SearchIcon } from 'images/magnifying-glass.svg';

interface SearchInputProps {
  onChange: (searchTerm: string) => Promise<void>;
  placeholder: string;
  className?: string;
  throttleRate?: number;
  searchOnEmpty?: boolean;
  dataCy?: string;
  isInitialDataLoading?: boolean;
  initialValue?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  placeholder,
  throttleRate = 250,
  searchOnEmpty = false,
  dataCy,
  isInitialDataLoading = false,
  initialValue = '',
  ...props
}) => {
  const [prevValue, setPrevValue] = useState('');
  const [value, setValue] = useState(initialValue);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if ((searchOnEmpty || value.length) && value !== prevValue) {
      setIsWaiting(true);
      timeout = setTimeout(() => {
        setPrevValue(value);
        onChange(value.trim()).then(() => setIsWaiting(false));
      }, throttleRate);
    } else {
      setIsWaiting(false);
    }

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <SearchWrapper {...props}>
      <StyledSearchIcon />
      <StyledInput
        type="text"
        data-cy={dataCy}
        {...{ placeholder }}
        value={value}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
      />
      {(isInitialDataLoading || isWaiting) && <StyledLoading size={3} />}
    </SearchWrapper>
  );
};

export default SearchInput;

const SearchWrapper = styled.div`
  position: relative;
  box-shadow: var(--gray-box-shadow);
  border-radius: 0.4rem;
`;

const StyledInput = styled(Input)`
  background: ${white};
  padding-left: 4.5rem;
  padding-right: 4rem;
  border: none;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 0.5rem;
  top: 0.2rem;
  height: 3.5rem;
  width: 3.5rem;
  fill: ${mediumLightGray};
`;

const StyledLoading = styled(Loading)`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
`;
