import React from 'react';
import styled from '@emotion/styled';
import { useParams, Link } from 'react-router-dom';

import Loading from 'components/atoms/Loading';
import Button from 'components/atoms/Button';
import Card from 'components/atoms/Card';
import ExternalLink from 'components/atoms/ExternalLink';
import Header from 'components/molecules/Header';
import { CoursesDashboardTableWithProvider } from 'components/organisms/CoursesDashboardTable';
import SkillDistribution from 'components/organisms/SkillDistribution';
import Collapsible, { StyledCaret } from 'components/atoms/Collapsible';
import { ReactComponent as CaretIcon } from 'images/caret.svg';
import { ReactComponent as EditIcon } from 'images/edit.svg';
import { ReactComponent as ChainLinkIcon } from 'images/chainLink.svg';
import { ReactComponent as EditPencilIcon } from 'images/editPencil.svg';

import { darkSaphire, navyBlue, white, black, darkerBorderGray } from 'utils/colors';

import { useCourses } from 'hooks';
import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';
import { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { useCipCodeName } from 'hooks/jpaHooks';

const ProgramSummary: React.FC<{
  program: SingleCurriculum<CurricularUnitResponse>;
  skillsInProgram: Record<string, string>;
  skillCounts?: Record<string, number>;
  categorizedSkills?: CategorizedSkills;
}> = ({ program, skillsInProgram, skillCounts, categorizedSkills }) => {
  const { id } = useParams<{ id: string }>();
  const profileState = useProfileState();
  const { data: courses } = useCourses(
    {
      limit: 0,
      filter: { associatedGroups: { in: [id] } }
    },
    {
      staleTime: 1000 * 60 * 3
    }
  );

  const hasProgramSiteEditorAccess = hasSiteAccessLevel(
    program.data.attributes.site,
    'editor',
    profileState
  );

  const { title, unitType, url, description, cipCode } = program.data.attributes;

  const { data: cipCodeName } = useCipCodeName(cipCode);

  return courses ? (
    <>
      <Summary>
        <Flex>
          <div>
            <StyledHeader data-cy="program-summary_header-title">{title}</StyledHeader>
            <GroupType data-cy="program-summary_type-and-cip-text">
              {unitType?.label}
              {cipCode ? ` - ${cipCodeName ? `${cipCodeName} (${cipCode})` : cipCode}` : null}
            </GroupType>
            {url && (
              <StyledExternalLink href={url} rel="noreferrer" data-cy="program-summary_url-link">
                <ChainLinkIcon />
                {url}
              </StyledExternalLink>
            )}
          </div>
          {hasProgramSiteEditorAccess && (
            <StyledHeaderButton
              as={Link}
              to={`/edit/program/${id}`}
              type="button"
              data-cy="program-summary_header-edit-button"
              scheme="outline"
            >
              <StyledEditPencilIcon />
              Edit Summary
            </StyledHeaderButton>
          )}
        </Flex>
        {description && (
          <>
            <SummaryHeader>Summary</SummaryHeader>
            <SummaryText data-cy="program-summary_summary-text">{description}</SummaryText>
          </>
        )}
        {program.data.attributes.learningObjectiveText && (
          <LearningOutcomesTextBox>
            <StyledCollapsible
              name="learningOutcomeCollapsible"
              defaultIsOpen={false}
              headerContent={(getButtonProps, isOpen) => (
                <>
                  <SummaryHeader>Learning Outcomes</SummaryHeader>
                  <StyledCaret isOpen={isOpen} {...getButtonProps()} style={{ cursor: 'pointer' }}>
                    <CaretIcon />
                  </StyledCaret>
                </>
              )}
            >
              {program.data.attributes.learningObjectiveText}
            </StyledCollapsible>
          </LearningOutcomesTextBox>
        )}
      </Summary>
      <SkillDistribution
        skillsInProgram={skillsInProgram}
        skillCounts={skillCounts}
        categorizedSkills={categorizedSkills}
        programId={program.data.id}
      />
      <WrappedTable>
        <TableHeader
          headerText="Courses In This Program"
          headerLevel="h3"
          count={courses?.meta.totalAvailable}
        >
          {hasProgramSiteEditorAccess && (
            <StyledHeaderButton as={Link} to={`/edit/program/${id}`} type="button" scheme="outline">
              <StyledEditIcon />
              Edit Courses
            </StyledHeaderButton>
          )}
        </TableHeader>
        <StyledDashboardTable
          hasEditorPrivileges={hasProgramSiteEditorAccess}
          tableFilters={{ associatedGroups: { in: [id] } }}
          displayRequiredCourses
        />
      </WrappedTable>
    </>
  ) : (
    <Loading />
  );
};
const LearningOutcomesTextBox = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${darkerBorderGray};
`;
const StyledCollapsible = styled(Collapsible)`
  & > button {
    color: ${black};
  }
`;
const TableHeader = styled(Header)`
  padding: 3rem 3rem 0 3rem;
  background: ${white};
  border-radius: 0.4rem 0.4rem 0 0;
  margin: 0;
  h3 {
    font-size: 2rem;
  }
`;

const Summary = styled(Card)`
  margin-top: 3rem;
  margin-bottom: 4rem;
  font-size: 1.4rem;
`;

const SummaryText = styled.p`
  max-height: 30rem;
  overflow: auto;
  margin: 0;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.7rem;
`;

const StyledHeader = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const StyledHeaderButton = styled(Button)`
  margin-left: 0.5rem;
`;

const SummaryHeader = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const GroupType = styled.span`
  display: block;
`;

const StyledExternalLink = styled(ExternalLink)`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 0.5rem;
  color: ${navyBlue};

  &:hover {
    border-bottom: 1px solid ${navyBlue};
  }

  svg {
    margin-right: 0.75rem;
  }
`;

const StyledEditPencilIcon = styled(EditPencilIcon)`
  stroke: ${darkSaphire};
  margin-right: 0.8rem;
`;

const StyledEditIcon = styled(EditIcon)`
  stroke: ${darkSaphire};
  margin-right: 1rem;
`;

const StyledDashboardTable = styled(CoursesDashboardTableWithProvider)`
  & > div {
    border: none;
  }

  & > table {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const WrappedTable = styled.div`
  margin: 4rem auto;
  box-shadow: var(--gray-box-shadow);
  border-radius: 0.4rem;
`;

export default ProgramSummary;
