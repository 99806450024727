import styled from '@emotion/styled';
import React from 'react';
import { moneyGreen } from 'utils/colors';

const MoneyIcon: React.FC<{ size?: number }> = ({ size }) => {
  return <CircleContainer size={size || 2.5}>$</CircleContainer>;
};

export default MoneyIcon;

const CircleContainer = styled.div<{ size: number }>`
  width: ${({ size }) => size}rem;
  height: ${({ size }) => size}rem;
  background: ${moneyGreen};
  color: white;
  font-size: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;
