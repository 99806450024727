import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactComponent as Caret } from 'images/caret.svg';

import { borderGray, darkDarkSaphire } from 'utils/colors';

const Select: React.FC<{
  ariaLabel: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
  value: string | undefined;
  minWidth?: string;
}> = ({ options, className, ariaLabel, minWidth = '10rem', ...props }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      position: relative;
      background: ${borderGray};
      border-radius: 0.4rem;
    `}
    className={className}
  >
    <select
      aria-label={ariaLabel}
      css={css`
        cursor: pointer;
        width: 100%;
        text-transform: capitalize;
        font-size: 1.4rem;
        border: none;
        color: ${darkDarkSaphire};
        background: transparent;
        font-weight: 600;
        padding: 1rem;
        padding-right: 3rem;
        appearance: none;
        min-width: ${minWidth};
        --moz-appearance: none;
        --webkit-appearance: none;
        &::-ms-expand {
          display: none;
        }
      `}
      {...props}
    >
      {options.map(option => (
        <option key={`option-${option}`} value={option}>
          {option}
        </option>
      ))}
    </select>
    <Caret
      css={css`
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 1rem;
        pointer-events: none;
        stroke: ${darkDarkSaphire};
      `}
    />
  </div>
);

export default Select;
