import React, { useContext } from 'react';

import { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { ParagraphHighlight } from '../components';

export interface HighlightedText {
  text: string;
  highlights: ParagraphHighlight[];
}

export type { ParagraphHighlight };

export interface CoursePdfExportData {
  courseName: string;
  institutionName: string;
  courseId: string;
  courseCode: string | null;
  skillsInProgram: Skill[];
  description: HighlightedText;
  syllabus: HighlightedText;
  learningOutcomes: HighlightedText;
  categorizedSkills: CategorizedSkills;
  skillsInRecord: Skill[];
}

const CourseContext = React.createContext<CoursePdfExportData>({} as CoursePdfExportData);

export const CourseDataProvider: React.FC<{ data: CoursePdfExportData }> = ({ data, children }) => {
  return <CourseContext.Provider value={data}>{children}</CourseContext.Provider>;
};

export function useCourseData() {
  return useContext(CourseContext);
}
