import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import LoadingDashboardTable from 'components/atoms/LoadingDashboardTable';
import DashboardTableStyles from 'components/atoms/DashboardTableStyles';
import EmptyDashboardTable from 'components/atoms/EmptyDashboardTable';
import Pagination from 'components/molecules/Pagination';
import Modal from 'components/atoms/Modal';
import ProgramTypeModal from 'components/organisms/ProgramTypeModal';

import Table, {
  InvisibleButton,
  HeaderCell,
  Cell,
  TableNumber,
  Column
} from 'components/molecules/Table';
import TableFilters from 'components/organisms/TableFilters';
import ButtonFlyoutMenu from 'components/organisms/ButtonFlyoutMenu';
import { prepareProgramTypes } from 'helpers/prepareTables';

import { useTableState, TableProvider } from 'store/tableStore';
import {
  DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
  useProgramTypes,
  useQueryString,
  DropdownSelectable
} from 'hooks';

const columns: Column<PreppedProgramType>[] = [
  {
    id: 'title',
    renderHeader: () => (
      <HeaderCell width="100%" key="title-header">
        Program Type Title
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`title-${row.id}`}>
        <InvisibleButton>{row.title}</InvisibleButton>
      </Cell>
    )
  },
  {
    id: 'category',
    renderHeader: () => (
      <HeaderCell
        key="program-type-header"
        css={css`
          min-width: 20rem;
          @media (min-width: 1700px) {
            padding-right: 5rem;
            min-width: 40rem;
          }
        `}
      >
        Category
      </HeaderCell>
    ),
    renderCell: row => <Cell key={`program-type-${row.id}`}>{row.category.name}</Cell>
  },
  {
    id: 'groupCount',
    renderHeader: () => (
      <HeaderCell
        key="courses-header"
        css={css`
          min-width: 10rem;
          @media (min-width: 1700px) {
            padding-right: 10rem;
          }
        `}
      >
        Programs
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`courses-${row.id}`}>
        <TableNumber>{row.groupCount}</TableNumber>
      </Cell>
    )
  },
  {
    id: 'edit',
    renderHeader: () => (
      <HeaderCell
        className="edit-column"
        key="edit-header"
        css={css`
          min-width: 8rem;
        `}
      >
        Edit
      </HeaderCell>
    ),
    renderCell: row => (
      <Cell key={`edit-${row.id}`} className="edit-column">
        <ButtonFlyoutMenu row={row} tableType="program-type" />
      </Cell>
    )
  }
];

const StyledProgramTable = css`
  ${DashboardTableStyles}
  cursor: auto;
  tbody tr:hover:not(.empty-table) {
    cursor: auto;
  }
`;

interface ProgramTypesDashboardTableProps {
  trackPages?: boolean;
}

interface ProgramTypesDashboardRouteState {
  filter: {
    filterType: 'text';
    filterQuery: {
      query: string;
    };
    programTypeClassFilter: string;
    programTypeClassLabel: string;
  };
}

const buildSearchPayload = ({ filter }: ProgramTypesDashboardRouteState) => {
  const searchFilter: ProgramTypeSearchFilters = {};
  if (filter.programTypeClassFilter) {
    searchFilter.groupTypeClass = { in: [filter.programTypeClassFilter] };
  }
  if (filter.filterQuery.query.length > 1) {
    searchFilter.textFilter = {
      ...filter.filterQuery,
      fields: ['label']
    };
  }
  return searchFilter;
};

const LOCATION_STATE_KEY = 'programTypesDashboardFilters';
const ProgramTypesDashboardTable: React.FC<ProgramTypesDashboardTableProps> = ({ trackPages }) => {
  const { page: queryPage } = useQueryString();
  const location = useLocation<{ [x: string]: ProgramTypesDashboardRouteState | undefined }>();
  const [isOpen, setIsOpen] = React.useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const [groupTypeData, setGroupTypeData] = React.useState<{
    programTypeId: string;
    programTypeLabel?: string;
    programTypeClass: { value: string; label: string };
  }>();

  const state: ProgramTypesDashboardRouteState = location?.state?.[LOCATION_STATE_KEY] || {
    filter: {
      filterType: 'text',
      filterQuery: {
        query: ''
      },
      programTypeClassFilter: '',
      programTypeClassLabel: 'All Categories'
    }
  };

  const programTypeClassFilter = {
    value: state.filter.programTypeClassFilter,
    label: state.filter.programTypeClassLabel
  };

  const history = useHistory();

  const { page } = useTableState();
  const currentPage = trackPages ? Number(queryPage) || 1 : page;

  const { data, isLoading, isPreviousData } = useProgramTypes({
    limit: DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    offset: (currentPage - 1) * DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    filter: buildSearchPayload(state)
  });

  const handleProgramTypeClassChange = (item: DropdownSelectable) => {
    history.push(`${location.pathname}?page=1`, {
      ...location.state,
      [LOCATION_STATE_KEY]: {
        ...state,
        filter: {
          ...state.filter,
          programTypeClassFilter: item?.value || '',
          programTypeClassLabel: item?.label || ''
        }
      }
    });
  };

  const isRequesting = isLoading || isPreviousData;

  const programTypes = React.useMemo(() => data && prepareProgramTypes(data), [data]);

  const pageCount = Math.ceil((data?.meta.totalAvailable || 0) / DEFAULT_DASHBOARD_TABLE_PAGE_SIZE);

  return (
    <>
      <TableFilters
        dataCount={data?.meta.totalAvailable}
        tableType="ProgramType"
        selectedProgramTypeClass={programTypeClassFilter}
        onProgramTypeClassSelect={handleProgramTypeClassChange}
        isRequesting={isRequesting}
        locationStateKey={LOCATION_STATE_KEY}
      />
      {programTypes ? (
        <>
          <Table
            tableCaption="Program Types Table"
            css={StyledProgramTable}
            columns={columns}
            data={programTypes}
            onRowClick={groupType => {
              setIsOpen(true);
              setGroupTypeData({
                programTypeId: groupType.id,
                programTypeLabel: groupType.title,
                programTypeClass: {
                  value: groupType.category.id,
                  label: groupType.category.name
                }
              });
            }}
            EmptyComponent={<EmptyDashboardTable label="program type" />}
          />
          {isOpen && (
            <Modal closeModal={closeModal}>
              <ProgramTypeModal closeModal={closeModal} defaultValues={groupTypeData} />
            </Modal>
          )}

          {pageCount > 1 && <Pagination {...{ pageCount, trackPages }} />}
        </>
      ) : (
        <LoadingDashboardTable />
      )}
    </>
  );
};

const ProgramTypesDashboardTableWrapper: React.FC<ProgramTypesDashboardTableProps> = props => {
  return (
    <TableProvider>
      <ProgramTypesDashboardTable {...props} />
    </TableProvider>
  );
};

export default ProgramTypesDashboardTableWrapper;
