import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';

import SearchWithDropdownInput, {
  DropdownMenuItem
} from 'components/atoms/SearchWithDropdownInput';
import Loading from 'components/atoms/Loading';
import { ReactComponent as MagnifyingGlass } from 'images/magnifying-glass.svg';
import InfoPill from 'components/atoms/InfoPill';
import { useProgramAndCourseSearch } from 'hooks/curricularSkills';
import { useProfileState, hasSiteAccessLevel } from 'store/profileStore';

import { programTypeClassShort } from 'utils/programTypeClassShortening';

const GlobalSearchInput: React.FC<{ className?: string }> = ({ className = '' }) => {
  const history = useHistory();

  const { isLoading, error, getData } = useProgramAndCourseSearch();

  const profileState = useProfileState();
  const hasEditorPrivileges = hasSiteAccessLevel(profileState.currentSite, 'editor', profileState);

  return (
    <StyledWrapper className={className}>
      {isLoading ? <StyledLoading color="gray" size={2} /> : <StyledMagnifyingGlass />}
      <SearchWithDropdownInput
        // We use the css way of styling here, because Generic Component Types
        // don't play nicely with styled components
        css={SearchWithDropDownStyles}
        aria-label="search for a course or program"
        labelText="search for a course or program"
        limit={100}
        onSelectItem={item =>
          history.push(
            `/${
              item.type === 'program'
                ? 'program/summary/'
                : item.type === 'course' && hasEditorPrivileges
                ? 'edit/course/'
                : 'course/summary/'
            }${item.id}`
          )
        }
        placeholder="Search Courses or Programs…"
        search={getData}
        selectedItems={[]}
      >
        {({ getItemProps, highlightedIndex, inputItems }) =>
          error ? (
            <StyledMenuItem key={`header-search-error`} highlighted={false}>
              Sorry, we couldn&apos;t retrieve your results!
            </StyledMenuItem>
          ) : (
            inputItems.map((item, index) => (
              <StyledMenuItem
                {...getItemProps({ item, index })}
                key={`${item.type}-${item.id}`}
                highlighted={highlightedIndex === index}
              >
                <span>{item.name}</span>
                <StyledInfoPill scheme={item.type === 'course' ? 'green' : 'blue'}>
                  {item.type === 'course'
                    ? item.label || 'Course'
                    : programTypeClassShort[item.label as keyof typeof programTypeClassShort] ||
                      item.label}
                </StyledInfoPill>
              </StyledMenuItem>
            ))
          )
        }
      </SearchWithDropdownInput>
    </StyledWrapper>
  );
};

export default GlobalSearchInput;

const StyledWrapper = styled.div`
  border-bottom: solid 1px black;
  position: relative;
`;

const StyledMagnifyingGlass = styled(MagnifyingGlass)`
  height: 3rem;
  width: 3rem;
  fill: black;
  position: absolute;
`;

const SearchWithDropDownStyles = css`
  ul {
    right: 0;
    left: unset;
    width: 40rem;

    @media (max-width: 1200px) {
      right: unset;
      left: 0;
    }
  }

  input {
    background: none;
    border: unset;
    padding: 0.6rem;
    padding-left: 3rem;
  }
`;

const StyledMenuItem = styled(DropdownMenuItem)`
  font-size: 1.4rem;
  font-weight: 400;
`;

const StyledInfoPill = styled(InfoPill)`
  align-self: center;
  margin-left: 1rem;
`;

const StyledLoading = styled(Loading)`
  top: 0.5rem;
  left: 0.5rem;
  position: absolute;
`;
