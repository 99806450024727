import React, { useState } from 'react';
import styled from '@emotion/styled';

import RegionPickerFlyoutButton from 'components/molecules/RegionPickerFlyoutButton';
import SelectEducationLevelDropdownButton from 'components/molecules/SelectEducationLevelDropdownButton';
import SelectExperienceRequiredDropdownButton from 'components/molecules/SelectExperienceRequiredFlyoutButton';
import ChartSettingsDropdownButton from 'components/molecules/ChartSettingsDropdownButton';
import TaughtSoughtChart from 'components/molecules/TaughtSoughtChart';
import SelectTimeframeDropdown from 'components/molecules/SelectTimeframeDropdown';
import SkillDetailTable from 'components/molecules/SkillDetailTable';
import SkillDefinitions from 'components/molecules/SkillDefinitions';
import TabsWrapper from 'components/molecules/TabBar';
import SkillGapTrackerTable from 'components/organisms/SkillGapTrackerTable';
import Card from 'components/atoms/Card';
import TaughtSoughtLegend from 'components/atoms/TaughtSoughtLegend';
import { noSkillsWithFiltersMessage } from 'components/organisms/ProgramInsights';

import useCurrentNation from 'hooks/useCurrentNation';
import { bgDarkGray, darkSaphire } from 'utils/colors';
import { FilteredTaughtSoughtSkills, SkillDetailInformation } from 'hooks/programInsights';
import { useSkillDetailTableDataFlowControl } from 'hooks/skillDetailTableDataFlowControl';
import { useProgramHasLearningOutcomes } from 'hooks/curricularSkills';
import { useCategoryPostingsBySkills } from 'hooks/useCategorizedSkills';
import { ChartSettings } from 'store/programData';

export type CustomErrorMessage = { headerText: string; contentText: string };

export type TaughtSought = {
  taught: number;
  sought: number;
};

const SkillInsights: React.FC<{
  filteredSkills: FilteredTaughtSoughtSkills;
  savedBenchmarksLength: number;
  setRegionFilter: (region: RegionFilter) => void;
  setRawTimeframe(value: number): void;
  regionFilter?: RegionFilter;
  chartSettings: ChartSettings;
  setChartSettings: (value: ChartSettings) => void;
  postingsQuery: JPAOptionsFilter;
  setJPAFilter: React.Dispatch<React.SetStateAction<JPAOptionsFilter>>;
  jpaFilter: JPAOptionsFilter;
  reducedSkillsLength: TaughtSought;
  isTaughtVsSoughtChartLoading: boolean;
  isSkillDetailChartLoading: boolean;
  skillDetails: SkillDetailInformation[];
  programId: string;
}> = ({
  filteredSkills,
  savedBenchmarksLength,
  setRawTimeframe,
  setRegionFilter,
  regionFilter,
  chartSettings,
  setChartSettings,
  postingsQuery,
  setJPAFilter,
  jpaFilter,
  isTaughtVsSoughtChartLoading,
  isSkillDetailChartLoading,
  skillDetails,
  reducedSkillsLength,
  programId
}) => {
  const [currentNation] = useCurrentNation();
  const { data: programHasLearningOutcomes } = useProgramHasLearningOutcomes(programId);

  const { data: controlledSkillDetails, dataFlowConfig } =
    useSkillDetailTableDataFlowControl(skillDetails);

  const { data: categoryData, isLoading: isCategoryDataIsLoading } = useCategoryPostingsBySkills(
    skillDetails,
    postingsQuery,
    chartSettings.isDisplayingCategories
  );

  const [activeTableTab, setActiveTableTab] = useState<string>('skillGapTable');

  return (
    <>
      <StyledCard>
        <CardHeaderContainer>
          <CardHeader>Data Filters:</CardHeader>
          <ChartSettingsDropdownButton
            chartSettings={chartSettings}
            onChange={setChartSettings}
            isJobPostingByBenchmarkDisabled={
              savedBenchmarksLength ? savedBenchmarksLength > 10 : false
            }
            isSkillTagsFilterDisabled={!programHasLearningOutcomes}
          />
        </CardHeaderContainer>
        <CenterRow>
          <RegionPickerFlyoutButton
            floating
            onSave={setRegionFilter}
            value={regionFilter}
            currentNation={currentNation}
          />

          <SelectEducationLevelDropdownButton
            floating
            onChange={levels => {
              setJPAFilter(old => ({
                ...old,
                edulevels_name: levels.length ? levels : undefined
              }));
            }}
          />
          <SelectExperienceRequiredDropdownButton
            floating
            onChange={experienceRange => {
              setJPAFilter(old => ({ ...old, min_years_experience: experienceRange }));
            }}
            value={jpaFilter.min_years_experience}
          />
          <SelectTimeframeDropdown
            floating
            onChange={value => {
              setRawTimeframe(value.rawValue);
              setJPAFilter(old => ({ ...old, when: value.range }));
            }}
          />
        </CenterRow>
        <StyledHr />

        <InsightHeadingWrapper>
          <CardHeader>Taught vs. Sought Skills</CardHeader>
          <InsightBody>
            The following charts show the top, in-demand skills from the selected benchmarks above,
            and whether or not they are taught in the program.
          </InsightBody>
        </InsightHeadingWrapper>

        <GraphContainer>
          <TaughtSoughtChart
            chartType="taught"
            filteredSkills={filteredSkills}
            chartSettings={chartSettings}
            isLoading={isTaughtVsSoughtChartLoading}
            postingsQuery={postingsQuery}
            customChartErrorMessage={
              reducedSkillsLength.taught ? noSkillsWithFiltersMessage : undefined
            }
          />
          <TaughtSoughtChart
            chartType="sought"
            filteredSkills={filteredSkills}
            chartSettings={chartSettings}
            isLoading={isTaughtVsSoughtChartLoading}
            postingsQuery={postingsQuery}
            customChartErrorMessage={
              reducedSkillsLength.sought ? noSkillsWithFiltersMessage : undefined
            }
          />
        </GraphContainer>
        <TabsWrapper
          tabs={[
            { id: 'skillGapTable', label: 'Market Alignment Trend' },
            { id: 'skillDetailTable', label: 'Skill Detail' }
          ]}
          onTabSelected={setActiveTableTab}
          selectedTab={activeTableTab}
          customStyles={{ tabButtonContainer: TablesTabContainer, tabButton: TablesTabButton }}
        >
          {activeTableTab === 'skillDetailTable' && (
            <TableContainer>
              <CardHeaderContainer>
                <InsightHeadingWrapper>
                  <InsightBody>
                    Detailed skill information for the top, in-demand skills from the Selected
                    Benchmarks above.
                  </InsightBody>
                </InsightHeadingWrapper>
                <TaughtSoughtLegend show={['taught', 'taught_other', 'sought']} />
              </CardHeaderContainer>
              <SkillDetailContainer>
                <SkillDetailTable
                  skills={controlledSkillDetails}
                  isLoading={isSkillDetailChartLoading || isCategoryDataIsLoading}
                  dataFlowConfig={dataFlowConfig}
                  isDisplayByCategory={chartSettings.isDisplayingCategories}
                  categoryPostingCounts={categoryData}
                />
                <SkillDefinitions />
              </SkillDetailContainer>
            </TableContainer>
          )}
          {activeTableTab === 'skillGapTable' && (
            <TableContainer>
              <CardHeaderContainer>
                <InsightHeadingWrapper>
                  <InsightBody>
                    {
                      "Prepare learners with skills needed for career success by monitoring your program's taught skills over time and keeping up with the labor market's rapidly shifting demand for skills. Consider which in-demand skills to include in your program to strengthen your program's ability to support learner career mobility."
                    }
                  </InsightBody>
                  <InsightBody>
                    {
                      'Note: Data filter and chart setting selections above apply to this graph. Adjust the filters to see more targeted skill demand changes in the below graph.'
                    }
                  </InsightBody>
                </InsightHeadingWrapper>
              </CardHeaderContainer>
              <SkillGapTrackerTable programId={programId} jpaFilters={postingsQuery} />
            </TableContainer>
          )}
        </TabsWrapper>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 2rem;
`;

const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const InsightBody = styled.p`
  font-size: 1.4rem;
  font-weight: 350;
`;

const CardHeader = styled.span`
  font-size: 2rem;
  font-weight: 400;
`;

const StyledHr = styled.hr`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  border: 1px solid ${bgDarkGray};
  background: ${bgDarkGray};
  width: 100%;
`;

const InsightHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CenterRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const GraphContainer = styled.div`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 4rem;
  gap: 2rem;
`;

const SkillDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const TableContainer = styled.div`
  margin-top: 2rem;
`;

const TablesTabContainer = styled.div`
  display: inline-block;
  width: 100%;
  box-shadow: 0 -0.5rem 0 ${bgDarkGray} inset;
`;

const TablesTabButton = styled.button`
  padding: 0.8rem 0rem;
  margin-right: 2rem;
  border: none;
  background: transparent;
  font-size: 2rem;
  color: ${darkSaphire};

  &.active {
    font-weight: 800;
    border-bottom: 0.5rem solid;
  }
`;

export default SkillInsights;
