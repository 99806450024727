import { fetchWithCognitoToken } from './cognito';
const domain = process.env.REACT_APP_PROFILES_API_URL;

export const updateOwnProfile = async (
  body: Partial<ProfileAttributes>
): Promise<SingleProfileResponse> => {
  const response = await fetchWithCognitoToken(`${domain}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'profile',
        attributes: body
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not update profile.`);
  }

  return await response.json();
};

export const updateProfileByEmail = async (
  email: string,
  body: Partial<PatchableProfileAttributes>
): Promise<SingleProfileResponse> => {
  const response = await fetchWithCognitoToken(`${domain}/profiles/${email}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'profile',
        attributes: body
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not update profile.`);
  }

  return await response.json();
};

export const resendUserInvite = async (email: string): Promise<SingleProfileResponse> => {
  const response = await fetchWithCognitoToken(`${domain}/resend-invite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        attributes: {
          email
        }
      }
    })
  });

  if (!response.ok) {
    throw new Error(`Could not resend invite email`);
  }
  return await response.json();
};

export const getProfileByEmail = async (email: string): Promise<SingleProfileResponse> => {
  const response = await fetchWithCognitoToken(`${domain}/profiles/${email}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(`Could not get profile.`);
  }
  return await response.json();
};

export const getOwnProfile = async (): Promise<SingleProfileResponse> => {
  const response = await fetchWithCognitoToken(`${domain}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(`Could not fetch profile.`);
  }
  return await response.json();
};

export const getAccessibleProfiles = async (): Promise<ProfilesResponse> => {
  const response = await fetchWithCognitoToken(`${domain}/profiles`);
  if (!response.ok) {
    throw new Error(`Could not fetch profile.`);
  }
  return await response.json();
};

export const createProfile = async (
  body: Partial<ProfileAttributes | PatchableProfileAttributes>
): Promise<SingleProfileResponse> => {
  const response = await fetchWithCognitoToken(`${domain}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        type: 'profile',
        attributes: body
      }
    })
  });
  if (!response.ok) {
    throw new Error('Could not create profile.');
  }
  return await response.json();
};

export const deleteProfile = async (email: string): Promise<void> => {
  const response = await fetchWithCognitoToken(`${domain}/profiles/${email}`, {
    method: 'DELETE'
  });

  if (!response.ok) {
    throw new Error('Could not delete profile.');
  }
};
