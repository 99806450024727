import styled from '@emotion/styled';
import React from 'react';

import { darkEmerald, darkSaphire, citrine } from 'utils/colors';
import { TaughtState } from 'hooks/programInsights';
import greenBar from 'images/greenBar.png';

const stateColors = {
  taught: { background: darkSaphire, border: darkSaphire },
  taught_other: { background: darkEmerald, border: darkEmerald },
  sought: { background: `url(${greenBar}) center`, border: darkEmerald },
  hypothetical: { background: citrine, border: citrine }
};

const TaughtSoughtBar: React.FC<{ state: TaughtState; className?: string; boxed?: boolean }> = ({
  state,
  className,
  boxed
}) => {
  return (
    <Bar
      background={stateColors[state].background}
      border={stateColors[state].border}
      className={className}
      width={boxed ? '1.3rem' : '100%'}
    />
  );
};

export default TaughtSoughtBar;

const Bar = styled.div<{ background: string; border: string; width: string }>`
  width: ${({ width }) => width};
  height: 1.3rem;
  background: ${({ background }) => background};
  border-radius: 0.4rem;
  border: 2px solid ${({ border }) => border};
`;
