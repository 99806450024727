import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ArrowRight } from 'images/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'images/arrowLeft.svg';
import Button from 'components/atoms/Button';
import { useFixedHeightPageContainer } from 'hooks';
import { darkSaphire, skillBorderGray } from 'utils/colors';

const Container = styled.div`
  max-width: 80rem;
  min-height: 28.4rem;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PillBox = styled.div`
  text-align: left;
`;

const Pill = styled.button<{ selected?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0 1.5rem 1.5rem 0;
  padding: 1rem 1rem;
  border-radius: 4px;
  border: ${props => (props.selected ? darkSaphire : skillBorderGray)} solid 1px;
  background-color: white;
  color: ${props => (props.selected ? darkSaphire : 'inherit')};
  font-size: 1.4rem;
  white-space: nowrap;
`;

const Arrow = styled(Button)`
  background: ${darkSaphire};
  opacity: ${props => (props.disabled ? '.6' : '1')};
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  border: none;
  border-radius: 4px;
  width: 5rem;
  height: 4rem;
  margin: 1.6rem 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 0.8rem;
`;

interface PillContainerProps {
  addOrRemoveSkill: (id: Skill) => void;
  selectableSkills: Skill[];
  selectedSkills: readonly string[];
  className?: string;
}

const PillContainer: React.FC<PillContainerProps> = ({
  addOrRemoveSkill,
  selectableSkills,
  selectedSkills,
  className
}) => {
  const {
    isFirstPage,
    isLastPage,
    pageLeft,
    pageRight,
    getPageItemsWrapperProps,
    getPageItemProps
  } = useFixedHeightPageContainer(4, [selectableSkills]);

  return (
    <Container className={className}>
      <PillBox {...getPageItemsWrapperProps()}>
        {selectableSkills.map((skill, index) => (
          <Pill
            {...getPageItemProps(index)}
            type="button"
            key={skill.id}
            selected={selectedSkills.includes(skill.id)}
            onClick={() => {
              addOrRemoveSkill(skill);
            }}
          >
            {skill.name}
          </Pill>
        ))}
      </PillBox>
      <Buttons>
        <Arrow
          type="button"
          aria-label="next page"
          disabled={isFirstPage}
          onClick={() => pageLeft()}
        >
          <ArrowLeft />
        </Arrow>
        <Arrow
          type="button"
          aria-label="previous page"
          disabled={isLastPage}
          onClick={() => pageRight()}
        >
          <ArrowRight />
        </Arrow>
      </Buttons>
    </Container>
  );
};

export default PillContainer;
