import React from 'react';
import ReactDom from 'react-dom';
import styled from '@emotion/styled';
import FocusLock from 'react-focus-lock';

import Card from 'components/atoms/Card';
import { PopupProvider } from 'hooks/usePopup';

import { ReactComponent as CloseIcon } from 'images/closebtn.svg';
import { useOnClickOutside, useOnKeyDownEffect } from 'hooks';
import { fog } from 'utils/colors';

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  margin: 10rem auto 0;
  height: fit-content;
  position: relative;
`;

/**
 * The close button should only have 2 rem of padding so we change the cards padding here.
 * Then give the interior div (ModalContentPadding) the extra 1rem of padding to keep with typical card spec
 */
const ModalCard = styled(Card)`
  padding: 0;
  background: ${fog};
`;

const ModalContentPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  width: fit-content;

  cursor: pointer;
  padding: 0.6rem;
`;

const ButtonWrapper = styled.div`
  padding-right: 1.4rem;
  padding-top: 1.4rem;
  align-self: flex-end;
`;

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  closeModal?: (e?: React.MouseEvent | Event) => void;
  noX?: boolean;
  style?: React.CSSProperties;
}

const Modal = ({ children, closeModal, className, noX, style }: ModalProps): React.ReactPortal => {
  const ModalContentRef = React.useRef<HTMLDivElement>(null);
  const WrapperRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ModalContentRef, closeModal, WrapperRef);
  useOnKeyDownEffect('Escape', closeModal);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const body = document.querySelector('body')!;
    body.style.overflow = 'hidden';
    return () => {
      body.style.overflow = 'visible';
    };
  });

  return ReactDom.createPortal(
    <PopupProvider popupZindex={1100}>
      <FocusLock>
        <Wrapper ref={WrapperRef}>
          <Overlay />
          <ModalContent ref={ModalContentRef}>
            <ModalCard className={className} style={style}>
              {!noX && (
                <ButtonWrapper>
                  <CloseButton onClick={closeModal} aria-label="close modal">
                    <CloseIcon />
                  </CloseButton>
                </ButtonWrapper>
              )}
              <ModalContentPadding>{children}</ModalContentPadding>
            </ModalCard>
          </ModalContent>
        </Wrapper>
      </FocusLock>
    </PopupProvider>,
    document.body
  );
};

export default Modal;
