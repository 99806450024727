import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import InfoChip from 'components/atoms/InfoChip';
import Button from 'components/atoms/Button';
import BenchmarksBrowser from 'components/molecules/BenchmarksBrowser';
import CipSearch from 'components/molecules/CipSearch';
import { BenchmarkPopup } from 'components/molecules/BenchmarkPopup';
import { DetailedBenchmarkCardProps } from 'components/molecules/DetailedBenchmarkCard';

import { useCipCodeName } from 'hooks/jpaHooks';
import { useCipRelatedOccupations } from 'hooks/classificationHooks';
import formatLargeNumber from 'utils/formatLargeNumber';
import { UniversalBenchmark, UniversalOccupationBenchmark } from 'helpers/benchmarks';
import { LOT_LABELS } from 'utils/curricularSkills';
import { darkGray } from 'utils/colors';
import { occupationBenchmarkToUniversal } from 'helpers/benchmarks';
import { PillProps } from 'components/atoms/InfoPill';

export interface TopMatchesByCipCodeProps {
  changeProgramCipCode(cipCode: undefined | string): void;
  programCipCode: undefined | string;
  selectedBenchmarks: UniversalOccupationBenchmark[];
  selectBenchmark: (benchmark: UniversalBenchmark) => void;
  deselectBenchmark: (benchmark: UniversalBenchmark) => void;
}

const TopMatchesByCipCode = ({
  changeProgramCipCode,
  programCipCode,
  selectedBenchmarks,
  selectBenchmark,
  deselectBenchmark
}: TopMatchesByCipCodeProps) => {
  const [newCipCode, setNewCipCode] = useState<undefined | string>(programCipCode);
  const { data: newCipCodeName } = useCipCodeName(newCipCode);
  const { data: programCipCodeName } = useCipCodeName(programCipCode);

  const { data: matchedOccupations, isLoading } = useCipRelatedOccupations(programCipCode, [
    'median_salary',
    'total_postings'
  ]);

  const formattedBenchmarks = useMemo(() => {
    return matchedOccupations
      .sort((a, b) => (b.metrics.total_postings || 0) - (a.metrics.total_postings || 0))
      .map((benchmark, index) => {
        const { median_salary } = benchmark.metrics;

        const pills: PillProps[] = [{ label: `${LOT_LABELS[benchmark.type]}`, scheme: 'red' }];

        if (median_salary) {
          pills.push({ label: `$${formatLargeNumber(median_salary)}`, scheme: 'green' });
        }

        const formattedBenchmark: DetailedBenchmarkCardProps = {
          id: benchmark.id,
          title: benchmark.name,
          pills,
          isSelected: !!selectedBenchmarks.find(
            selectedBench => selectedBench.attributes.id === benchmark.id
          ),
          isCipCard: true,
          isCustom: false,
          allowCardSelection: true,
          renderDetailView: () => (
            <BenchmarkPopup benchmark={occupationBenchmarkToUniversal(benchmark)} />
          ),
          onSelectionChange: isSelected => {
            return isSelected
              ? selectBenchmark({
                  universalType: 'occupation',
                  attributes: { id: benchmark.id, name: benchmark.name, type: 'occupation' }
                })
              : deselectBenchmark({
                  universalType: 'occupation',
                  attributes: { id: benchmark.id, name: benchmark.name, type: 'occupation' }
                });
          },
          tabIndex: index + 1
        };

        return formattedBenchmark;
      });
  }, [matchedOccupations, selectedBenchmarks]);

  const noCipCodeView = (
    <NewCipCodeContainer>
      <NewCipCodeCard>
        <NoCipCodeHeader>No CIP Code Selected for This Program</NoCipCodeHeader>
        <NoCipCodeContentText>
          Search for and select an option below to see occupation matches
        </NoCipCodeContentText>

        <CipSearchFlexContainer>
          {newCipCode ? (
            <InfoChipSearchContainer>
              <InfoChip onClick={() => setNewCipCode('')} id={newCipCodeName && newCipCode}>
                {newCipCodeName || newCipCode}
              </InfoChip>
            </InfoChipSearchContainer>
          ) : (
            <CipSearchContainer>
              <CipSearch onSelect={setNewCipCode} />
            </CipSearchContainer>
          )}
          <Button
            type="button"
            onClick={() => changeProgramCipCode(newCipCode)}
            data-cy="top-matches-by-cip-code_update-cip-button"
          >
            Update
          </Button>
        </CipSearchFlexContainer>
      </NewCipCodeCard>
    </NewCipCodeContainer>
  );

  const noBenchmarksView = (
    <NoBenchmarksNotice>No Occupations Found Relating to the CIP Code</NoBenchmarksNotice>
  );

  return (
    <Container>
      <CipInfoContainer>
        CIP Code:
        {programCipCode ? (
          <InfoChipContainer>
            <InfoChip
              onClick={() => {
                setNewCipCode('');
                changeProgramCipCode('');
              }}
              id={programCipCodeName && programCipCode}
              dataCy="top-matches-by-cip-code_cancel-chip"
            >
              {programCipCodeName || programCipCode}
            </InfoChip>
          </InfoChipContainer>
        ) : null}
      </CipInfoContainer>

      <BenchmarksBrowser
        benchmarks={formattedBenchmarks}
        noBenchmarksView={!programCipCode ? noCipCodeView : noBenchmarksView}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default TopMatchesByCipCode;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CipInfoContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.4rem;
  height: 2.5rem;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
`;

const InfoChipContainer = styled.div`
  max-width: 40rem;

  > div {
    width: 100%;
  }
`;

const NewCipCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem 0;
`;

const NewCipCodeCard = styled.div`
  padding: 3rem;
  background: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const NoCipCodeHeader = styled.h3`
  font-weight: normal;
  padding: 0;
  margin: 0;
`;

const NoCipCodeContentText = styled.p`
  margin: 0;
  padding: 0;
  color: ${darkGray};
  text-align: center;
`;

const CipSearchFlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 42rem;
`;

const CipSearchContainer = styled.div`
  flex-grow: 1;

  > div {
    width: 100%;
  }
`;

const InfoChipSearchContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
  display: flex;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }
`;

const NoBenchmarksNotice = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${darkGray};
`;
