import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

import { getDatasetChartTicks, BarChartPages } from '../barChart';
import { Paragraph } from '../components';

import { darkEmerald, darkSaphire, navyBlue } from 'utils/colors';
import { useProgramData } from './data';

export interface TvsPageProps {
  taughtOrSought: 'taught' | 'sought';
}

const taughtValues = { name: 'Taught Skills', color: darkSaphire };
const soughtValues = { name: 'Sought Skills', color: darkEmerald };

export const TvsChartPage: React.FC<TvsPageProps> = ({ taughtOrSought }) => {
  const { taughtSkills, soughtSkills } = useProgramData();

  const title = taughtOrSought === 'taught' ? taughtValues.name : soughtValues.name;
  const barColor = taughtOrSought === 'taught' ? taughtValues.color : soughtValues.color;

  const prepend = (
    <View style={styles.prependContainer}>
      <Text
        style={styles.headerText}
        render={({ subPageNumber }) => {
          if (subPageNumber === 1) {
            return title;
          }
        }}
      />
      {taughtOrSought === 'taught' ? (
        <Paragraph
          text={`The following chart shows the top in-demand skills from the selected labor market benchmarks that have also been identified in this program's curriculum, indicating areas of alignment.`}
        />
      ) : (
        <Paragraph
          text={`The following chart shows the top in-demand skills from the selected labor market benchmarks that have not been identified in this program's curriculum. These sought skills represent areas of potential opportunity to better align with labor market needs, by incorporating these skills into the curriculum content and/or content descriptions for the program.`}
        />
      )}
      <View style={styles.skillKeyContainer}>
        <View
          style={[
            styles.skillKey,
            {
              backgroundColor: taughtOrSought === 'taught' ? taughtValues.color : soughtValues.color
            }
          ]}
        />
        <Text>{title}</Text>
      </View>
    </View>
  );

  let soughtSkillsTicks: number[] = [];
  let maxSoughtTickValue = 0;
  if (soughtSkills.length) {
    soughtSkillsTicks = getDatasetChartTicks(soughtSkills);
    maxSoughtTickValue = soughtSkillsTicks[soughtSkillsTicks.length - 1];
  }

  let taughtSkillsTicks: number[] = [];
  let maxTaughtTickValue = 0;
  if (taughtSkills.length) {
    taughtSkillsTicks = getDatasetChartTicks(taughtSkills);
    maxTaughtTickValue = taughtSkillsTicks[taughtSkillsTicks.length - 1];
  }

  const chartTickValues =
    maxSoughtTickValue > maxTaughtTickValue ? soughtSkillsTicks : taughtSkillsTicks;

  return (
    <>
      {taughtOrSought === 'taught' && taughtSkills.length && (
        <BarChartPages
          data={taughtSkills}
          prepend={prepend}
          firstPageMaxBars={15}
          barColor={barColor}
          restPagesHeader={`${title} (Cont.)`}
          order="descending"
          defaultTicks={chartTickValues}
        />
      )}
      {taughtOrSought === 'sought' && soughtSkills.length && (
        <BarChartPages
          data={soughtSkills}
          prepend={prepend}
          firstPageMaxBars={15}
          barColor={barColor}
          restPagesHeader={`${title} (Cont.)`}
          order="descending"
          defaultTicks={chartTickValues}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  prependContainer: {
    fontFamily: 'Inter',
    fontSize: '10',
    marginHorizontal: '0.5in'
  },
  headerText: {
    fontSize: '20',
    fontWeight: 'bold',
    color: navyBlue,
    marginBottom: '0.25in'
  },
  skillKeyContainer: {
    marginTop: '0.25in',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  skillKey: {
    height: '0.1in',
    width: '0.1in',
    marginRight: '0.1in',
    marginLeft: '0.1in'
  }
});
