/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Card from 'components/atoms/Card';
import CountBadge from 'components/atoms/CountBadge';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ReactComponent as Caret } from 'images/caret.svg';
import SkillCategoryCollapsible from 'components/molecules/SkillCategoryCollapsible';

import { useScrollToBottomCallback } from 'hooks';
import useFlyout from 'hooks/useFlyout';
import useCategorizedSkills, { getCategorizedSkillCount } from 'hooks/useCategorizedSkills';

import { fetchSkillsById } from 'services/skills';
import {
  white,
  borderGray,
  mediumGray,
  darkGray,
  fog,
  darkSaphire,
  mediumLightGray,
  black,
  lightGray
} from 'utils/colors';

import { ReactComponent as Remove } from 'images/x-remove.svg';
import { ReactComponent as AddedSkillsPlaceholder } from 'images/addedSkills.svg';

const AdditionalSkillsCard: React.FC<{
  onChange?: (...event: unknown[]) => void;
  displaySkills: CurricularSkill[];
  selectedSkills: readonly CurricularSkill[];
  className?: string;
}> = ({ displaySkills, selectedSkills, onChange, className }) => {
  const { FlyoutContainer, isOpen, setIsOpen, getButtonProps } = useFlyout();
  const [isAllOpen, setIsAllOpen] = React.useState(true);
  const [sortType, setSortType] = React.useState<'Category' | 'Name'>('Name');
  const ref = React.useRef<HTMLUListElement>(null);
  const [populatedSkills, setPopulatedSkills] = React.useState<Skill[]>([]);

  const scrollToBottom = useScrollToBottomCallback(ref);

  const isReadOnly = !onChange;

  useDeepCompareEffect(() => {
    let stale = false;
    fetchSkillsById(
      displaySkills.map(s => s.id),
      ['id', 'category', 'subcategory', 'name']
    )
      .then(skillsWithIds => {
        !stale && setPopulatedSkills(skillsWithIds);
        scrollToBottom();
      })
      .catch(err => {
        // console.log(err);
      });
    return () => {
      stale = true;
    };
  }, [displaySkills]);

  const categorizedSkills = useCategorizedSkills(populatedSkills);
  const skillsSelected = !!populatedSkills.length;

  const collapseAll = () => {
    setIsAllOpen(!isAllOpen);
  };

  return (
    <StyledCard className={className}>
      <CardHeadingWrapper>
        <Flex>
          <CardHeading>Added Skills</CardHeading>
          {skillsSelected && <StyledCount>{populatedSkills.length}</StyledCount>}
        </Flex>
        <ButtonBox className={sortType === 'Name' ? 'Name' : 'Category'}>
          {sortType === 'Category' && (
            <StyledCollapseAllButton type="button" onClick={collapseAll}>
              {isAllOpen ? `Collapse All` : `Expand All`}
              <StyledCaret isOpen={isAllOpen}>
                <Caret />
              </StyledCaret>
            </StyledCollapseAllButton>
          )}
          <RelativeContainer>
            <PillButton {...getButtonProps()} onClick={() => setIsOpen(!isOpen)} type="button">
              By&nbsp;<strong>{sortType}</strong>
            </PillButton>

            {isOpen && (
              <FlyoutContainer css={flyoutContainerStyles}>
                <button
                  type="button"
                  onClick={() => {
                    setSortType('Name');
                    setIsOpen(false);
                  }}
                >
                  Name
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setSortType('Category');
                    setIsOpen(false);
                  }}
                >
                  Category
                </button>
              </FlyoutContainer>
            )}
          </RelativeContainer>

          {/* Remove button hiding at the moment */}
          {/* {!isReadOnly && skillsSelected && (
            <PillButton
              type="button"
              onClick={() =>
                onChange(selectedSkills.filter(skill => !displaySkills.includes(skill)))
              }
            >
              <StyledRemove />
              Remove All
            </PillButton>
          )} */}
        </ButtonBox>
      </CardHeadingWrapper>
      {skillsSelected ? (
        <AdditionalSkillsContainer ref={ref}>
          {sortType === 'Name'
            ? populatedSkills.map(skill => (
                <ListItem key={skill.id}>
                  {!isReadOnly && (
                    <RemoveSkillButton
                      aria-label={`remove ${skill.name} skill`}
                      type="button"
                      onClick={() => {
                        onChange(selectedSkills.filter(s => s.id !== skill.id));
                      }}
                    >
                      <Remove />
                    </RemoveSkillButton>
                  )}
                  <span>{skill.name}</span>
                </ListItem>
              ))
            : Object.entries(categorizedSkills)
                .sort(([, a], [, b]) => {
                  const skillsA = Object.values(a).flat().length;
                  const skillsB = Object.values(b).flat().length;
                  return skillsB - skillsA;
                })
                .map(([category, subcategoryObj]) => (
                  <StyledSkillCategoryCollapsible
                    isControllerOpen={isAllOpen}
                    key={`category-${category}`}
                    buttonText={
                      <CategoryButtonContainer>
                        {category}
                        <StyledCountBadge>
                          {getCategorizedSkillCount(subcategoryObj)}
                        </StyledCountBadge>
                      </CategoryButtonContainer>
                    }
                    name={category}
                    subcategories={subcategoryObj}
                    renderSkillContent={skill => (
                      <CategoryListItem>
                        {!isReadOnly && (
                          <RemoveSkillButton
                            aria-label={`remove ${skill.name} skill`}
                            type="button"
                            onClick={() => {
                              onChange(selectedSkills.filter(s => s.id !== skill.id));
                            }}
                          >
                            <Remove />
                          </RemoveSkillButton>
                        )}
                        <span>{skill.name}</span>
                      </CategoryListItem>
                    )}
                  />
                ))}
        </AdditionalSkillsContainer>
      ) : (
        <EmptySkillsContents>
          <AddedSkillsPlaceholder />
          <HeavyText>Add More Skills</HeavyText>
          <div>Use the skills browser on the left to find skills related to your curriculum</div>
        </EmptySkillsContents>
      )}
    </StyledCard>
  );
};
const StyledCaret = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  svg {
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    & > path {
      stroke: ${darkSaphire};
    }
  }
`;
const StyledCollapseAllButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${fog};
  font-weight: 500;
  border: 1px solid ${lightGray};
  border-radius: 2px;
  color: ${darkSaphire};
  white-space: nowrap;
`;

const StyledCard = styled(Card)`
  margin-top: 8rem;
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  display: inline;
`;

const EmptySkillsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: 6rem auto 0;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
  height: 24.8rem;
`;

const CardHeadingWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 1650px) {
    display: flex;
    align-items: center;
  }
`;

const AdditionalSkillsContainer = styled.ul`
  margin: 0 0 0 -0.4rem;
  height: 29.8rem;
  overflow-y: auto;
  list-style-type: none;
  padding: 0 0 0 0.1rem;
`;

const RemoveSkillButton = styled.button`
  background: white;
  display: inline;
  border: none;
  padding: 0.3rem;
  cursor: pointer;
`;

const PillButton = styled.button`
  background: ${white};
  border: 1px solid ${borderGray};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3rem;
  padding: 1rem 1.1rem;
  display: flex;
  align-items: center;
  color: ${darkGray};
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;

  transform: scale(1);
  :active {
    transform: scale(0.95);
  }
`;

const StyledCount = styled(CountBadge)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
  margin-right: 1rem;
  align-self: baseline;
`;

const HeavyText = styled.span`
  display: block;
  font-weight: 600;
  margin-top: 2rem;
`;

const ListItem = styled.li<{ isReadOnly?: boolean }>`
  margin: 0.1rem 0rem 2rem 0.1rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  & > span {
    margin-left: ${isReadOnly => (isReadOnly ? `.5rem` : `1rem`)};
  }
`;

const CategoryListItem = styled(ListItem)`
  margin: 0;
`;

// Remove button styling
// const StyledRemove = styled(Remove)`
//   margin-right: 0.85rem;
//   height: 1.4rem;
//   width: 1.4rem;
//   stroke: ${darkGray};
// `;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
  &.Name {
    justify-content: flex-end;
  }
  &.Category {
    flex-basis: 50%;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const flyoutContainerStyles = css`
  padding: 1rem;
  border-radius: 0.4rem;
  background: ${white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 13rem;

  & > button {
    width: 100%;
    text-align: left;
    padding: 0.5rem 0;
    padding-left: 0.5rem;
    background: transparent;
    border: none;
    display: block;
    font-size: 1.4rem;
    border-radius: 0.4rem;

    &:hover {
      background: ${fog};
    }
  }
`;
const CategoryButtonContainer = styled.div`
  display: flex;
  text-align: left;
`;

const StyledCountBadge = styled(CountBadge)`
  max-height: 2rem;
`;

const StyledSkillCategoryCollapsible = styled(SkillCategoryCollapsible)`
  & .collapsible {
    justify-content: flex-start;

    button {
      color: ${black};
      font-weight: bold;
      font-size: 1.4rem;
      padding: 0;
      padding-bottom: 1rem;
      border: none;
      background-color: transparent;
    }
  }

  & > div:nth-of-type(2) {
    justify-content: flex-start;
    button {
      margin-left: 1.3rem;
      color: ${mediumLightGray};
      text-align: left;
    }
  }

  .subcategory {
    & > div:nth-of-type(2) {
      margin-left: 1.5rem;
    }
  }

  .skill {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
`;

export default AdditionalSkillsCard;
