import { useMemo, useState } from 'react';

import { DataFlowConfig } from 'components/molecules/SkillDetailTable';
import { SkillDetailInformation, TaughtState } from './programInsights';
import { DataFlowControlParams } from 'components/atoms/DataFlowControl';
import { SkillSignificance } from 'helpers/benchmarkSkillInfo';
import { useSkillDetailFlowControls } from './skillDetailTableDataFlowControlProvider';
import { categorizeSkills } from './useCategorizedSkills';

export type DataSortFn = (a: SkillDetailInformation, b: SkillDetailInformation) => number;
export type DataFilterFn = (entry: SkillDetailInformation) => boolean;

export type FilterObject = Partial<Record<keyof SkillDetailInformation, DataFilterFn[]>>;

export function useSkillDetailTableDataFlowControl(data: SkillDetailInformation[]) {
  const { activeFilters, setActiveFilters } = useSkillDetailFlowControls();
  const [activeSorts, setActiveSorts] = useState<DataSortFn[]>([
    sortFunctions.jobPostings.highestToLowest
  ]);

  const primarySort = activeSorts[activeSorts.length - 1];

  const addSort = (sortFn: DataSortFn) => {
    const index = activeSorts.indexOf(sortFn);
    if (index === -1) {
      return setActiveSorts([...activeSorts, sortFn]);
    }

    setActiveSorts([...activeSorts.slice(0, index), ...activeSorts.slice(index + 1), sortFn]);
  };

  const toggleFilter = (field: keyof FilterObject, filterFn: DataFilterFn) => {
    setActiveFilters(old => {
      const fieldFilters = old[field];
      if (!fieldFilters) {
        return { ...old, [field]: [filterFn] };
      }
      const index = fieldFilters.indexOf(filterFn);
      if (index === -1) {
        return { ...old, [field]: fieldFilters.concat(filterFn) };
      }
      return {
        ...old,
        [field]: [...fieldFilters.slice(0, index), ...fieldFilters.slice(index + 1)]
      };
    });
  };

  const controlledData = useMemo(() => {
    const categorizedSkills = categorizeSkills(data);
    const categorizedData = Object.keys(categorizedSkills).reduce(
      (acc: Record<string, Record<string, SkillDetailInformation[]>>, catKey) => {
        const subcategories = categorizedSkills[catKey];
        const sortedAndFilteredSubcategories = Object.keys(subcategories).reduce(
          (subAcc: Record<string, SkillDetailInformation[]>, subcatKey) => {
            const skills = subcategories[subcatKey];
            const sortedAndFilteredSkills = sortData(
              filterData(skills, activeFilters),
              activeSorts
            );
            subAcc[subcatKey] = sortedAndFilteredSkills;
            return subAcc;
          },
          {}
        );
        acc[catKey] = sortedAndFilteredSubcategories;
        return acc;
      },
      {}
    );
    const uncategorizedData = sortData(filterData(data, activeFilters), activeSorts);
    return { uncategorizedSkills: uncategorizedData, categorizedSkills: categorizedData };
  }, [data, activeFilters, activeSorts]);

  const makeDataControls = makeDataFlowControlsBuilder({
    addSort,
    allFilters: activeFilters,
    primarySort,
    data,
    toggleFilter
  });

  const highestLowestSortLabels = {
    highestToLowest: { leftLabel: 'Highest', rightLabel: 'Lowest' },
    lowestToHighest: { leftLabel: 'Lowest', rightLabel: 'Highest' }
  };
  const mostLeastSortLabels = {
    leastToMost: { leftLabel: 'Least', rightLabel: 'Most' },
    mostToLeast: { leftLabel: 'Most', rightLabel: 'Least' }
  };
  const numericalFilterLabels = { negative: 'Negative', positive: 'Positive' };

  const dataFlowConfig: DataFlowConfig = {
    taughtState: makeDataControls({
      fieldName: 'taughtState',
      filter: filterFunctions.taughtState,
      sort: sortFunctions.taughtState,
      filterLabels: {
        taught: 'Taught',
        notTaughtAnywhere: 'Not Taught Anywhere',
        taughtElsewhere: 'Taught Elsewhere'
      },
      sortLabels: {
        soughtToTaught: { leftLabel: 'Sought', rightLabel: 'Taught' },
        taughtToSought: { leftLabel: 'Taught', rightLabel: 'Sought' }
      }
    }),
    jobPostings: makeDataControls({
      fieldName: 'count',
      filter: filterFunctions.jobPostings,
      sort: sortFunctions.jobPostings,
      filterLabels: {},
      sortLabels: highestLowestSortLabels
    }),
    growthPercentage: makeDataControls({
      fieldName: 'growthPercentage',
      filter: filterFunctions.growthPercentage,
      sort: sortFunctions.growthPercentage,
      filterLabels: numericalFilterLabels,
      sortLabels: highestLowestSortLabels
    }),
    // projectedGrowth: makeDataControls({
    //   fieldName: 'projectedGrowth',
    //   filter: filterFunctions.projectedGrowth,
    //   sort: sortFunctions.projectedGrowth,
    //   filterLabels: numericalFilterLabels,
    //   sortLabels: highestLowestSortLabels
    // }),
    salaryBoosting: makeDataControls({
      fieldName: 'salaryBoostingSkillFor',
      filter: filterFunctions.salaryBoosting,
      sort: sortFunctions.salaryBoosting,
      filterLabels: { salaryBoosting: 'Salary Boosting', notSalaryBoosting: 'Not Salary Boosting' },
      sortLabels: mostLeastSortLabels
    }),
    significance: makeDataControls({
      fieldName: 'significance',
      filter: filterFunctions.significance,
      sort: sortFunctions.significance,
      filterLabels: {
        defining: 'Defining',
        distinguishing: 'Distinguishing',
        necessary: 'Necessary',
        none: 'None'
      },
      sortLabels: mostLeastSortLabels
    })
  };

  return { data: controlledData, dataFlowConfig };
}

export const sortFunctions = {
  taughtState: {
    taughtToSought: makeSortFn((a, b) =>
      sortDescending(taughtStateToNumber(a), taughtStateToNumber(b))
    ),
    soughtToTaught: makeSortFn((a, b) =>
      sortAscending(taughtStateToNumber(a), taughtStateToNumber(b))
    )
  },
  jobPostings: {
    highestToLowest: makeSortFn((a, b) => sortDescending(a.count, b.count)),
    lowestToHighest: makeSortFn((a, b) => sortAscending(a.count, b.count))
  },
  growthPercentage: {
    highestToLowest: makeSortFn((a, b) => sortDescending(a.growthPercentage, b.growthPercentage)),
    lowestToHighest: makeSortFn((a, b) => sortAscending(a.growthPercentage, b.growthPercentage))
  },
  projectedGrowth: {
    highestToLowest: makeSortFn((a, b) =>
      sortDescending(a.projectedGrowth?.percentage, b.projectedGrowth?.percentage)
    ),
    lowestToHighest: makeSortFn((a, b) =>
      sortAscending(a.projectedGrowth?.percentage, b.projectedGrowth?.percentage)
    )
  },
  salaryBoosting: {
    mostToLeast: makeSortFn((a, b) =>
      sortDescending(a.salaryBoostingSkillFor.length, b.salaryBoostingSkillFor.length)
    ),
    leastToMost: makeSortFn((a, b) =>
      sortAscending(a.salaryBoostingSkillFor.length, b.salaryBoostingSkillFor.length)
    )
  },
  significance: {
    mostToLeast: makeSortFn((a, b) =>
      sortDescending(significanceToNumber(a), significanceToNumber(b))
    ),
    leastToMost: makeSortFn((a, b) =>
      sortAscending(significanceToNumber(a), significanceToNumber(b))
    )
  }
};

export const filterFunctions = {
  taughtState: {
    taught: (entry: SkillDetailInformation) => filterTaughtState(entry, 'taught'),
    taughtElsewhere: (entry: SkillDetailInformation) => filterTaughtState(entry, 'taught_other'),
    notTaughtAnywhere: (entry: SkillDetailInformation) => filterTaughtState(entry, 'sought')
  },
  jobPostings: {},
  growthPercentage: {
    positive: (entry: SkillDetailInformation) => filterGrowthPercentage(entry, 'positive'),
    negative: (entry: SkillDetailInformation) => filterGrowthPercentage(entry, 'negative')
  },
  projectedGrowth: {
    positive: (entry: SkillDetailInformation) => filterProjectedGrowth(entry, 'positive'),
    negative: (entry: SkillDetailInformation) => filterProjectedGrowth(entry, 'negative')
  },
  salaryBoosting: {
    salaryBoosting: (entry: SkillDetailInformation) => filterSalaryBoosting(entry, true),
    notSalaryBoosting: (entry: SkillDetailInformation) => filterSalaryBoosting(entry, false)
  },
  significance: {
    distinguishing: (entry: SkillDetailInformation) => filterSignificance(entry, 'distinguishing'),
    defining: (entry: SkillDetailInformation) => filterSignificance(entry, 'defining'),
    necessary: (entry: SkillDetailInformation) => filterSignificance(entry, 'necessary'),
    none: (entry: SkillDetailInformation) => filterSignificance(entry, null)
  }
};

function makeSortFn(fn: DataSortFn): DataSortFn {
  return fn;
}

function filterTaughtState(entry: SkillDetailInformation, taughtState: TaughtState) {
  return entry.taughtState === taughtState;
}

function filterGrowthPercentage(entry: SkillDetailInformation, constraint: NumericalConstraint) {
  if (entry.growthPercentage === null) {
    return false;
  }

  return numberMatchesConstraint(entry.growthPercentage, constraint);
}

function filterProjectedGrowth(entry: SkillDetailInformation, constraint: NumericalConstraint) {
  if (!entry.projectedGrowth) {
    return false;
  }

  return numberMatchesConstraint(entry.projectedGrowth.percentage, constraint);
}

function filterSalaryBoosting(entry: SkillDetailInformation, isSalaryBoosting: boolean) {
  if (isSalaryBoosting) {
    return !!entry.salaryBoostingSkillFor.length;
  }

  return !entry.salaryBoostingSkillFor.length;
}

function filterSignificance(entry: SkillDetailInformation, significance: SkillSignificance | null) {
  if (significance === null && !entry.significance.length) {
    return true;
  }

  return !!entry.significance.find(
    significanceEntry => significanceEntry.significance === significance
  );
}

export type NumericalConstraint = 'positive' | 'negative';

function numberMatchesConstraint(number: number, constraint: NumericalConstraint) {
  if (constraint === 'positive') {
    return number >= 0;
  }

  return number < 0;
}

function sortAscending(a: number | undefined | null, b: number | undefined | null) {
  return (a ?? 0) - (b ?? 0);
}

function sortDescending(a: number | undefined | null, b: number | undefined | null) {
  return (b ?? 0) - (a ?? 0);
}

function taughtStateToNumber(entry: SkillDetailInformation) {
  if (entry.taughtState === 'taught') {
    return 3;
  }

  if (entry.taughtState === 'taught_other') {
    return 2;
  }

  return 1;
}

function significanceToNumber(entry: SkillDetailInformation) {
  return entry.significance.reduce(
    (sum, significanceEntry) => sum + significanceEntry.benchmarks.length,
    0
  );
}

function filterData(data: SkillDetailInformation[], filterObject: FilterObject) {
  return data.filter(entry => {
    const tableFilters = Object.values(filterObject);

    return tableFilters.every(
      fieldFilters => !fieldFilters.length || fieldFilters.some(filter => filter(entry))
    );
  });
}

function sortData(data: SkillDetailInformation[], sorts: DataSortFn[]): SkillDetailInformation[] {
  if (!sorts.length) {
    return data;
  }

  return sortData(data.sort(sorts[0]), sorts.slice(1));
}

function makeDataFlowControlsBuilder(state: {
  data: SkillDetailInformation[];
  allFilters: FilterObject;
  primarySort: DataSortFn;
  toggleFilter(field: keyof FilterObject, fn: DataFilterFn): void;
  addSort(fn: DataSortFn): void;
}) {
  return <ST extends Record<string, DataSortFn>, FT extends Record<string, DataFilterFn>>(params: {
    sort: ST;
    filter: FT;
    fieldName: keyof SkillDetailInformation;
    filterLabels: Record<keyof FT, string>;
    sortLabels: Record<keyof ST, { leftLabel: string; rightLabel: string }>;
  }): DataFlowControlParams => ({
    filterOptions: Object.keys(params.filter).map(id => {
      const filterFn = params.filter[id];
      return { id, count: state.data.filter(filterFn).length, label: params.filterLabels[id] };
    }),
    selectedFilterOptions: Object.entries(params.filter)
      .filter(([key, fn]) => state.allFilters[params.fieldName]?.includes(fn as DataFilterFn))
      .map(([key]) => key),
    onToggleFilter(id) {
      state.toggleFilter(params.fieldName, params.filter[id]);
    },
    sortOptions: Object.keys(params.sort).map(id => ({
      id,
      leftLabel: params.sortLabels[id].leftLabel,
      rightLabel: params.sortLabels[id].rightLabel
    })),
    selectedSortOption: findKeyOfValue(params.sort, state.primarySort),
    onSort(id) {
      state.addSort(params.sort[id]);
    }
  });
}

function findKeyOfValue<OT extends Record<string, unknown>>(
  object: OT,
  value: unknown
): string | null {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }

  return null;
}
