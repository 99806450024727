import React from 'react';
import styled from '@emotion/styled';

import tessellWhite from 'images/tessell-white.gif';
import tessellBlack from 'images/tessell-black.gif';
import tessellGray from 'images/tessell-gray.gif';
import tessellRed from 'images/tessell-red.gif';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img<{ size?: number }>`
  height: ${props => props.size || 5}rem;
  width: ${props => props.size || 5}rem;
`;

type LoaderColor = 'white' | 'black' | 'red' | 'gray';
const getLoadingGif = (color: LoaderColor) => {
  switch (color) {
    case 'black':
      return tessellBlack;
    case 'white':
      return tessellWhite;
    case 'gray':
      return tessellGray;
    case 'red':
    default:
      return tessellRed;
  }
};

const Loading: React.FC<{ className?: string; size?: number; color?: LoaderColor }> = ({
  className,
  color = 'red',
  ...rest
}) => (
  <StyledDiv className={className}>
    <Image src={getLoadingGif(color)} {...rest} />
  </StyledDiv>
);

export default Loading;
