import styled from '@emotion/styled';

import { darkGray, lightGray } from 'utils/colors';

const CountBadge = styled.span`
  background: ${lightGray};
  color: ${darkGray};
  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.2rem 0.6rem;
  border-radius: 2.6rem;
  margin-left: 1rem;
  min-width: 2.4rem;
  display: flex;
  justify-content: center;
`;

export default CountBadge;
