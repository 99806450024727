import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const Footer: React.FC = () => (
  <View
    fixed
    style={styles.footerContainer}
    render={({ pageNumber }) => (
      <View style={styles.footer}>
        <Text
          style={styles.footerContent}
        >{`©Lightcast All Rights Reserved   |   Report Created - ${new Date().toLocaleDateString()}`}</Text>
        <Text style={styles.pageNumber}>{pageNumber}</Text>
      </View>
    )}
  />
);

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  footerContent: {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: 10,
    left: 0
  },
  pageNumber: {
    position: 'absolute',
    right: 30,
    fontFamily: 'Inter',
    fontSize: 10
  }
});
