import React from 'react';
import { useQueryClient } from 'react-query';
import styled from '@emotion/styled';

import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import ModalButton from 'components/molecules/ModalButton';
import MessageModal from 'components/molecules/MessageModal';
import FlyoutMenu, { MenuItem } from 'components/atoms/FlyoutMenu';

import { bulkUpdateCourses, bulkUpdatePrograms } from 'services/curricularSkills';
import { getToastFnThatWeTreatLikeHook } from 'hooks';

import { ReactComponent as Note } from 'images/note.svg';
import { ReactComponent as Pencil } from 'images/pencil.svg';
import { ReactComponent as CircleCheck } from 'images/schoolCheckMark.svg';
import { useProfileState } from 'store/profileStore';

import { white, borderGray, darkGray, mediumLightGray, darkSaphire } from 'utils/colors';

const StyledNote = styled(Note)`
  stroke: ${mediumLightGray};
  margin-right: 1rem;
`;

const StyledCheckMark = styled(CircleCheck)`
  margin-right: 1rem;
  & > path {
    stroke: ${darkSaphire};
  }
`;

const StyledPencil = styled(Pencil)`
  margin-right: 1.2rem;
  margin-left: 0.2rem;
`;

const BlueText = styled.span`
  color: ${darkSaphire};
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 11.4rem;
`;

const StyledFlyoutMenu = styled(FlyoutMenu)`
  .invisibleButton {
    background: ${white};
    border: 1px solid ${borderGray};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3rem;
    padding: 1rem 1.1rem;
    display: flex;
    color: ${darkGray};
    margin: 0 2.4rem;
    white-space: nowrap;

    transform: scale(1);
    :active {
      transform: scale(0.95);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      transform: scale(1);

      & ~ ul {
        display: none;
      }
    }
  }

  .flyoutMenu {
    width: max-content;
    top: 4.2rem;
  }
`;

const BulkPublishFlyout: React.FC<{
  publishType: 'Course' | 'Program';
  curricularIds: readonly string[];
  mode: 'selecting' | 'deselecting';
  selectedCount: number;
  onUpdate?(): void;
}> = ({ publishType, curricularIds, mode, selectedCount, onUpdate }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const queryClient = useQueryClient();

  const { currentSite } = useProfileState();
  const notification = getToastFnThatWeTreatLikeHook();

  const publishOrUnpublishCurriculum = (isPublished: boolean) => {
    const payload = {
      site: { in: [currentSite] },
      ids: mode === 'selecting' ? { in: curricularIds } : { exclude: curricularIds }
    };
    return publishType === 'Course'
      ? bulkUpdateCourses({ isPublished }, payload)
      : bulkUpdatePrograms({ isPublished }, payload);
  };

  return (
    <StyledFlyoutMenu
      buttonContent={
        <div>
          <StyledNote />
          Update Status...
        </div>
      }
      ariaLabel="Update Status"
      disabled={!selectedCount}
    >
      {({ getItemProps }) => (
        <>
          <MenuItem {...getItemProps({ firstOrLastPosition: 'first' })}>
            <ModalButton
              buttonText={
                <>
                  <StyledCheckMark />
                  <BlueText>Change to Published</BlueText>
                </>
              }
            >
              {closeModal => (
                <MessageModal
                  title={`Publish ${
                    (mode === 'selecting' && curricularIds.length) ||
                    (mode === 'deselecting' &&
                      selectedCount &&
                      selectedCount - curricularIds.length)
                  } ${publishType}${selectedCount !== 1 ? 's' : ''}?`}
                  message={`${selectedCount !== 1 ? 'These' : 'This'} ${publishType.toLowerCase()}${
                    selectedCount !== 1 ? 's' : ''
                  } will become visible on products that use Skillabi data.`}
                >
                  <ButtonsWrapper>
                    <StyledButton scheme="outline" type="button" onClick={closeModal}>
                      Cancel
                    </StyledButton>
                    <StyledLoadingButton
                      type="button"
                      isLoading={isLoading}
                      onClick={() => {
                        setIsLoading(true);
                        publishOrUnpublishCurriculum(true)
                          .then(() => {
                            setIsLoading(false);
                            queryClient.invalidateQueries(
                              publishType === 'Course' ? 'courses' : 'programs'
                            );
                            curricularIds.forEach(curricularId =>
                              queryClient.invalidateQueries([
                                publishType.toLowerCase(),
                                curricularId
                              ])
                            );
                            onUpdate?.();
                            closeModal();
                            notification(
                              `Done! ${publishType.replace(/\b\w/g, l => l.toUpperCase())}${
                                selectedCount !== 1 ? 's' : ''
                              } updated.`,
                              'success'
                            );
                          })
                          .catch(() => {
                            setIsLoading(false);
                            notification(
                              `Sorry, we were unable to update ${
                                selectedCount !== 1 ? 'those' : 'that'
                              } ${curricularIds.length} ${publishType.toLowerCase()}${
                                selectedCount !== 1 ? 's' : ''
                              }. Please try again.`,
                              'error'
                            );
                          });
                      }}
                    >
                      Publish {publishType}s
                    </StyledLoadingButton>
                  </ButtonsWrapper>
                </MessageModal>
              )}
            </ModalButton>
          </MenuItem>
          <MenuItem {...getItemProps({ firstOrLastPosition: 'last' })}>
            <ModalButton
              buttonText={
                <>
                  <StyledPencil />
                  <BlueText>Change to Draft</BlueText>
                </>
              }
            >
              {closeModal => (
                <MessageModal
                  title={`Change ${publishType}${selectedCount !== 1 ? 's' : ''} to Draft?`}
                  message={`${selectedCount !== 1 ? 'These' : 'This'} ${publishType.toLowerCase()}${
                    selectedCount !== 1 ? 's' : ''
                  } will be removed from products that use Skillabi data.`}
                >
                  <ButtonsWrapper>
                    <StyledButton scheme="outline" type="button" onClick={closeModal}>
                      Cancel
                    </StyledButton>
                    <StyledLoadingButton
                      type="button"
                      isLoading={isLoading}
                      onClick={() => {
                        setIsLoading(true);
                        publishOrUnpublishCurriculum(false)
                          .then(() => {
                            setIsLoading(false);
                            queryClient.invalidateQueries(
                              publishType === 'Course' ? 'courses' : 'programs'
                            );
                            curricularIds.forEach(curricularId =>
                              queryClient.invalidateQueries([
                                publishType.toLowerCase(),
                                curricularId
                              ])
                            );
                            onUpdate?.();
                            closeModal();
                            notification(
                              `Done! ${publishType.replace(/\b\w/g, l => l.toUpperCase())}${
                                selectedCount !== 1 ? 's' : ''
                              } updated.`,
                              'success'
                            );
                          })
                          .catch(() => {
                            setIsLoading(false);
                            notification(
                              `Sorry, we were unable to update ${
                                selectedCount !== 1 ? 'those' : 'that'
                              } ${curricularIds.length} ${publishType.toLowerCase()}${
                                selectedCount !== 1 ? 's' : ''
                              }. Please try again.`,
                              'error'
                            );
                          });
                      }}
                    >
                      Change to Draft
                    </StyledLoadingButton>
                  </ButtonsWrapper>
                </MessageModal>
              )}
            </ModalButton>
          </MenuItem>
        </>
      )}
    </StyledFlyoutMenu>
  );
};

export default BulkPublishFlyout;
