import { DataFilterFn, filterFunctions } from 'hooks/skillDetailTableDataFlowControl';

export const LOT_SKILL_FETCH_COUNT = 70;
export const EXPORT_COLUMNS = {
  'Program Info': [
    'UID',
    'Program Title',
    'Program Summary',
    'Program URL',
    'Program Type',
    'CIP Code'
  ],
  'Program Skills': [
    'Skill Name',
    'Skill ID',
    'Skill Category',
    'Skill Subcategory',
    'Learning Outcome Skill',
    'Frequency in Program',
    'Frequency Level'
  ],
  'Courses and Skills': [
    'UID',
    'Course Code',
    'Title',
    'Description',
    'URL',
    'Credits',
    'Required Course',
    '# of Skills',
    'Skills -->'
  ],
  'Courses and Skill ID': [
    'UID',
    'Course Code',
    'Title',
    'Description',
    'URL',
    'Credits',
    'Required Course',
    '# of Skills',
    'Skill IDs -->'
  ],
  Benchmarks: [
    'Benchmark ID',
    'Benchmark Name',
    'Match Number',
    'Match Score',
    'Salary Range',
    'Job Titles',
    '',
    '',
    'Companies',
    '',
    '',
    'Occupations (SOC)',
    '',
    '',
    'Skills -->'
  ],
  'Lightcast Occupation Benchmarks': [
    'Benchmark ID',
    'Benchmark Name',
    'Benchmark Type',
    'Median Salary',
    'Job Titles',
    '',
    '',
    'Companies',
    '',
    '',
    'Skills -->'
  ],
  'Custom Benchmarks': [
    'Benchmark Name',
    'Text Benchmark',
    'Median Salary',
    'Job Titles',
    'Companies',
    'Occupations (SOC)',
    'Industries',
    'Skills -->'
  ],
  'Taught Skills': [
    'Skill ID',
    'Skill Name',
    'Skill Category',
    'Skill Subcategory',
    'Job Postings With Skill'
  ],
  'Sought Skills': [
    'Skill ID',
    'Skill Name',
    'Skill Category',
    'Skill Subcategory',
    'Skill Level',
    'Skill Association',
    'Job Postings With Skill'
  ],
  'Courses That Could Add Skills': ['Course Title', 'Course Code', 'Market Aligned Skills -->'],
  Filters: [
    'Page Filters --> ',
    'Benchmarks',
    'Region',
    'Education Level',
    'Experience Required',
    'Skill Level',
    'Timeframe',
    'Learning Outcomes Skills Only',
    'Skill Detail Filters -->',
    'Taught or Sought',
    // '# Job Postings',
    '% Growth',
    // '% Projected Growth',
    'Salary Premium',
    'Skill Significance'
  ],
  'Skills Detail': [
    'Skill ID',
    'Skill Name',
    'Skill Category',
    'Skill Subcategory',
    'Skill Level',
    'Skill Association',
    'Job Postings With Skill',
    '% Growth',
    // '% Projected Growth',
    'Salary Boosting Skill',
    'Occupation(s) Where Skill is Salary Boosting',
    'Any Skill Significance - Selected Occupations',
    'Skill Significance: Necessary',
    'Occupation(s) Where Skill is Necessary',
    'Skill Significance: Defining',
    'Occupation(s) Where Skill is Defining',
    'Skill Significance: Distinguishing',
    'Occupation(s) Where Skill is Distinguishing'
  ]
};

export const TAUGHT_SOUGHT_FORMATTED_FILTER_TITLES = makeFilterMap([
  [filterFunctions.taughtState.taught, 'Taught'],
  [filterFunctions.taughtState.taughtElsewhere, 'Taught in other offering'],
  [filterFunctions.taughtState.notTaughtAnywhere, 'Sought']
]);

export const GROWTH_PERCENTAGE_FORMATTED_FILTER_TITLES = makeFilterMap([
  [filterFunctions.growthPercentage.positive, 'Positive'],
  [filterFunctions.growthPercentage.negative, 'Negative']
]);

export const SALARY_BOOSTING_FORMATTED_FILTER_TITLES = makeFilterMap([
  [filterFunctions.salaryBoosting.salaryBoosting, 'Salary Boosting'],
  [filterFunctions.salaryBoosting.notSalaryBoosting, 'Not Salary Boosting']
]);

export const SIGNIFICANCE_FORMATTED_FILTER_TITLES = makeFilterMap([
  [filterFunctions.significance.distinguishing, 'Distinguishing'],
  [filterFunctions.significance.defining, 'Defining'],
  [filterFunctions.significance.necessary, 'Necessary'],
  [filterFunctions.significance.none, 'None']
]);

function makeFilterMap(entries: [DataFilterFn, string][]) {
  const map = new Map<DataFilterFn, string>();

  for (const [key, value] of entries) {
    map.set(key, value);
  }

  return map;
}
