import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { toast, Slide } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import throttle from 'lodash/throttle';

import Toaster from 'components/atoms/Toaster';
import { darkSaphire, ahoy } from 'utils/colors';

import useExport from './useExport';
export { useExport };
export interface Hook<T> {
  isLoading: boolean;
  isPreviousData?: boolean;
  error: Error | null;
  data: T | undefined;
}

export { useDropdown } from './useDropdown';
export type { DropdownSelectable } from './useDropdown';
export {
  useProgram,
  useCourse,
  useCourses,
  useProgramTypeClasses,
  useProgramTypes,
  usePrograms,
  useSkillsInProgram
} from './curricularSkills';

export const DEFAULT_DASHBOARD_TABLE_PAGE_SIZE = 8;

export const useQueryString = (): Record<string, string> => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return Object.fromEntries(params.entries());
};

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler?: (event: Event) => void,
  outerRef: React.RefObject<HTMLElement | Document> = { current: document.body }
): void => {
  React.useEffect(() => {
    const handleClick = (e: Event) => {
      const el = ref?.current;
      if (!el || el.contains((e.target as Node) || null)) {
        return;
      }

      handler && handler(e);
    };
    if (outerRef.current) {
      const { current } = outerRef;
      current.addEventListener('mousedown', handleClick);
      return () => {
        current.removeEventListener('mousedown', handleClick);
      };
    }
  }, [ref, handler, outerRef]);
};

export const useOnKeyDownEffect = (
  key: string,
  handler?: (e: KeyboardEvent) => void,
  ref?: React.RefObject<HTMLElement>
): void => {
  React.useEffect(() => {
    if (!handler) {
      return;
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === key) {
        handler(e);
      }
    };
    const target = ref?.current ? ref.current : document;
    target.addEventListener('keydown', handleKeyDown as EventListener);
    return () => {
      target.removeEventListener('keydown', handleKeyDown as EventListener);
    };
  }, [handler, key]);
};

export function useScrollToBottom<T extends HTMLElement>(
  ref: React.RefObject<T>,
  ...dependencies: readonly unknown[]
): void {
  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [...dependencies]);
}

export const useScrollToBottomCallback = <T extends HTMLElement>(
  ref: React.RefObject<T>
): (() => void) =>
  React.useCallback(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [ref]);

export const getToastFnThatWeTreatLikeHook =
  () =>
  (message: string, type: 'success' | 'error' = 'success'): void => {
    const toasterOptions = {
      hideProgressBar: true,
      progress: undefined,
      transition: Slide,
      position: toast.POSITION.BOTTOM_RIGHT,
      closeButton: false,
      style: {
        background: type === 'success' ? darkSaphire : ahoy,
        borderRadius: '4px',
        width: type === 'error' ? '40rem' : '26rem',
        padding: '0 2rem',
        right: type === 'error' ? '9rem' : '-5rem',
        fontSize: '1.4rem',
        height: '6rem',
        fontFamily: 'Inter'
      }
    };

    toast(Toaster({ message, type }), toasterOptions);
  };

export const useFixedHeightPageContainer = (
  displayRows: number, // The number of rows of items that the container should show on each page
  deepCompareDependencies: unknown[] // When one of these dependencies changes(deep compare) the container will reset
): {
  isFirstPage: boolean;
  isLastPage: boolean;
  pageLeft: () => void;
  pageRight: () => void;
  getPageItemsWrapperProps: () => {
    ref: (wrapperEelement: HTMLDivElement | null) => void;
  };
  getPageItemProps: (index: number) => {
    ref: (pageItemElement: HTMLElement | null) => void;
  };
  reset: () => void;
} => {
  const [scrollTop, setScrollTop] = useState(0);
  const [page, setPage] = useState<{ start: number; end: number }>();
  const [rowHeight, setRowHeight] = useState(0);

  const pageItemsWrapper = useRef<HTMLDivElement | null>(null);
  const pageItems = useRef<HTMLElement[]>([]);

  const wrapperCallbackRef = useCallback(
    (wrapperEelement: HTMLDivElement | null) => {
      if (wrapperEelement) {
        wrapperEelement.style.height = `${rowHeight * displayRows}px`;
        wrapperEelement.style.position = 'relative';
        wrapperEelement.style.overflowY = 'hidden';
        wrapperEelement.style.paddingBottom = `${rowHeight * displayRows}px`;
        wrapperEelement.style.height = `${rowHeight * displayRows}px`;
        if (!pageItemsWrapper.current) {
          pageItemsWrapper.current = wrapperEelement;
        }
      }
    },
    [rowHeight, displayRows]
  );

  useDeepCompareEffect(() => {
    setScrollTop(0);
  }, deepCompareDependencies);

  useEffect(() => {
    const wrapper = pageItemsWrapper.current;
    if (wrapper) {
      wrapper.scrollTop = scrollTop;
      const start = pageItems.current.findIndex(
        item => item.getBoundingClientRect().top >= wrapper.getBoundingClientRect().top
      );
      const offsetNextStart = pageItems.current
        .slice(start)
        .findIndex(
          item =>
            item.getBoundingClientRect().top >=
            wrapper.getBoundingClientRect().top + rowHeight * displayRows
        );
      const end =
        offsetNextStart !== -1 ? offsetNextStart + start - 1 : pageItems.current.length - 1;
      setPage({ start, end });
    }
  }, [scrollTop, rowHeight, displayRows, ...deepCompareDependencies]);

  useLayoutEffect(() => {
    if (page) {
      pageItems.current.forEach((node, index) => {
        if (index >= page.start && index <= page.end) {
          node.style.visibility = 'visible';
          node.tabIndex = 0;
        } else {
          node.style.visibility = 'hidden';
          node.tabIndex = -1;
        }
      });
    }
  }, [page]);

  const isFirstPage = page?.start === 0;
  const isLastPage = page?.end === pageItems.current.length - 1;

  const pageLeft = () => {
    !isFirstPage && setScrollTop(prev => prev - rowHeight * displayRows);
  };

  const pageRight = () => {
    !isLastPage && setScrollTop(prev => prev + rowHeight * displayRows);
  };

  const getPageItemsWrapperProps = () => {
    return { ref: wrapperCallbackRef };
  };

  const getPageItemProps = (index: number) => {
    const ref = (pageItemElement: HTMLElement | null) => {
      if (pageItemElement) {
        pageItems.current[index] = pageItemElement;
        if (index === 0) {
          const { marginTop, marginBottom } = window.getComputedStyle(pageItemElement);
          setRowHeight(
            pageItemElement.getBoundingClientRect().height +
              Math.max(parseInt(marginTop), parseInt(marginBottom))
          );
        }
      }
    };
    return { ref };
  };

  return {
    isFirstPage,
    isLastPage,
    pageLeft,
    pageRight,
    getPageItemsWrapperProps,
    getPageItemProps,
    reset: () => setScrollTop(0)
  };
};

export const useVisible = <T extends HTMLElement>(
  scrollContainerRef: React.RefObject<HTMLElement>,
  throttleTime = 100
): [boolean, React.RefObject<T>] => {
  const [isVisible, setIsVisible] = React.useState(false);
  const trackedElement = React.useRef<T>(null);

  const handleScroll = throttle(() => {
    if (!trackedElement.current || !scrollContainerRef.current) {
      if (isVisible) {
        setIsVisible(false);
      }
      return;
    }

    const trackedTop = trackedElement.current.getBoundingClientRect().top;
    const trackedBottom = trackedElement.current.getBoundingClientRect().bottom;
    const scrollContainerTop = scrollContainerRef.current?.getBoundingClientRect().top;
    const scrollContainerBottom = scrollContainerRef.current?.getBoundingClientRect().bottom;

    const topDifference = trackedBottom - scrollContainerTop;
    const bottomDifference = scrollContainerBottom - trackedTop;
    setIsVisible(topDifference > 0 && bottomDifference > 0);
  }, throttleTime);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  }, [scrollContainerRef.current, trackedElement.current]);
  return [isVisible, trackedElement];
};
