import React from 'react';
import styled from '@emotion/styled';

import FlyoutMenu, { MenuItem } from 'components/atoms/FlyoutMenu';

import LoadingButton from 'components/atoms/LoadingButton';

import { ReactComponent as Caret } from 'images/caret.svg';
import { ReactComponent as Download } from 'images/cloudDownload.svg';

import { darkGray, darkSaphire } from 'utils/colors';

const BlueText = styled.span`
  color: ${darkSaphire};
`;

const StyledFlyoutMenu = styled(FlyoutMenu)`
  border: 1px solid ${darkSaphire};
  border-radius: 4px;
  height: 4rem;
  padding: 0;
  align-items: center;
  font-size: 1.4rem;

  .invisibleButton {
    display: flex;
    color: ${darkGray};
    white-space: nowrap;
    align-items: center;
    padding: 1rem 1.6rem;
  }
  .flyoutMenu {
    width: max-content;
    top: 4.2rem;
    right: 0rem;
    left: -1rem;
  }
`;
const StyledDownload = styled(Download)<{ color: string }>`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  stroke: ${({ color }) => color};
`;

const StyledLoadingButton = styled(LoadingButton)`
  min-width: 10rem;
`;

const StyledCaret = styled(Caret)`
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-top: 0.2rem;
  pointer-events: none;
  stroke: ${darkSaphire};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ExportDropdownFlyout: React.FC<{
  className?: string;
  exportAllFunc: () => void;
  exportSelectedFunc: () => void;
  isExportAllLoading: boolean;
  isExportSelectedLoading: boolean;
  buttonAriaLabel: string;
}> = ({
  className,
  exportAllFunc,
  exportSelectedFunc,
  isExportAllLoading,
  isExportSelectedLoading,
  buttonAriaLabel
}) => {
  return (
    <StyledFlyoutMenu
      className={className}
      buttonContent={
        <Container>
          <StyledDownload title="cloud download icon" color={darkSaphire} />
          <BlueText>Export</BlueText>
          <StyledCaret />
        </Container>
      }
      ariaLabel={buttonAriaLabel}
    >
      {({ getItemProps }) => (
        <>
          <MenuItem {...getItemProps({ firstOrLastPosition: 'first' })}>
            <StyledLoadingButton
              type="button"
              isLoading={isExportAllLoading}
              loaderColor="gray"
              onClick={() => exportAllFunc()}
            >
              <BlueText>Export All</BlueText>
            </StyledLoadingButton>
          </MenuItem>
          <MenuItem {...getItemProps({ firstOrLastPosition: 'last' })}>
            <StyledLoadingButton
              type="button"
              isLoading={isExportSelectedLoading}
              loaderColor="gray"
              onClick={() => exportSelectedFunc()}
            >
              <BlueText>Export Selected</BlueText>
            </StyledLoadingButton>
          </MenuItem>
        </>
      )}
    </StyledFlyoutMenu>
  );
};

export default ExportDropdownFlyout;
