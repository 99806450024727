import { PreprocessedSkill, Form } from 'services/skills';

// Sort the forms from the last occurring to the first occurring
const sortFormsInReverse = (a: Form, b: Form) => b.sourceEnd - a.sourceEnd;

const doFormsOverlap = (a: Form, b: Form) =>
  (a.sourceEnd <= b.sourceEnd && a.sourceEnd >= b.sourceStart) ||
  (a.sourceEnd >= b.sourceEnd && a.sourceStart <= b.sourceEnd);

/**
 * Highlights can apply to multiple parsed skills. In order to keep creating multiple nodes
 * we combine the forms to start them at the beginning of the first form and the end of the last form
 */
const combineForms = (a: Form, b: Form) => {
  const result = {
    sourceStart: Math.min(a.sourceStart, b.sourceStart),
    sourceEnd: Math.max(a.sourceEnd, b.sourceEnd),
    type: a.type
  };
  return result;
};

/**
 * Takes in a two dimensional array of for forms (highlights), creates a flattened array of all the highlights from the parsed skills next sorts the forms in reverse order of appearance.
 * Next, the forms are deduplicated, and checked for overlaps among other highlights. Finally, the combined array of forms are returned
 * @param skills
 * @returns
 */
const flattenAndSortForms = (skills: Form[][]) =>
  skills
    .flat(4)
    .sort(sortFormsInReverse)
    // combine any overlapping forms
    .reduce((combined: Form[], form) => {
      const last = combined.length - 1;
      // pushes the current form to combined if it's the first form or if the form overlaps with the previous form
      if (last < 0 || !doFormsOverlap(form, combined[last])) {
        combined.push(form);
        return combined;
      }
      // If the forms do not overlap, then set the last form in the array to the combined value of the current and previous last form
      combined[last] = combineForms(combined[last], form);
      return combined;
    }, []);

export const highlightParsedSkills = (
  text: string,
  highlightText: (text: string, key: string) => JSX.Element,
  plainText: (text: string, key: string) => JSX.Element,
  parsedSkills: PreprocessedSkill[]
): JSX.Element[] => {
  // This is now our list of highlights in reverse order they appear in the text
  const highlights = flattenAndSortForms(parsedSkills.map(skill => skill.highlights));

  // initialize the remainder as the full text remainder
  let remainder = text;
  // Keep a space at the end of the highlights to prevent unwanted behavior with new lines at the end of preformatted text
  const nodes = [plainText(' ', 'fix-end-of-line')];
  while (highlights.length) {
    // Get the highlight at the front of the highlights array
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const highlight = highlights.shift()!;

    // Get the leftover text from the end of the highlight if any, and create a plaintext node with it.
    const normalText = remainder.slice(highlight.sourceEnd);
    if (normalText.length) {
      nodes.unshift(plainText(normalText, highlight.sourceEnd.toString()));
    }

    // Get the highlighted text from where the current highlight starts and ends, add it to the nodes as highlightedText.
    const highlightedText = remainder.slice(highlight.sourceStart, highlight.sourceEnd);
    nodes.unshift(highlightText(highlightedText, highlight.sourceStart.toString()));
    // Reset the remainder from the beginning to the start of the current highlight
    remainder = remainder.slice(0, highlight.sourceStart);
  }

  // If the text does not start with a highlight, create a plaintext node and add it to the front of the nodes array
  if (remainder.length) {
    nodes.unshift(plainText(remainder, 'unshifted'));
  }
  return nodes;
};

/**
 * Returns a sparse array of highlighting events indexed by their position in the text. Events store the number of highlight and context
 * forms that begin and end at that position in the text
 */
const generateEventList = ({ highlights, contexts }: PreprocessedSkill) => {
  // start and end are numbers instead of booleans so that we can handle overlaps (multiple different forms starting and ending in the same place)
  const events: {
    startContext: number;
    endContext: number;
    startHighlight: number;
    endHighlight: number;
  }[] = [];
  // creates the event at events[textPos] if it doesn't already exist, and returns it
  const getEvent = (position: number) => {
    if (!events[position]) {
      events[position] = { startContext: 0, endContext: 0, startHighlight: 0, endHighlight: 0 };
    }
    return events[position];
  };

  highlights.forEach(highlight => {
    // increment start and end count for highlight event at events[sourceStart] and events[sourceEnd]
    getEvent(highlight.sourceStart).startHighlight++;
    getEvent(highlight.sourceEnd).endHighlight++;
  });
  contexts.forEach(context => {
    // increment start and end count for context event at events[sourceStart] and events[sourceEnd]
    getEvent(context.sourceStart).startContext++;
    getEvent(context.sourceEnd).endContext++;
  });

  return events;
};

export const highlightRevealedSkill = (
  text: string,
  plainText: (text: string, key: string) => JSX.Element,
  highlightText: (text: string, key: string) => JSX.Element,
  contextText: (text: string, key: string) => JSX.Element,
  revealedSkillId: string,
  parsedSkills: PreprocessedSkill[]
): JSX.Element[] => {
  const revealedSkill = parsedSkills.find(skill => skill.id === revealedSkillId);
  if (!revealedSkill) {
    return [plainText(text, 'no-highlights-found')];
  }
  const components = {
    highlightText,
    plainText,
    contextText
  };
  const events = generateEventList(revealedSkill);
  const nodes = [];
  // get rid of the holes in the events array and sort from earliest to latest occurrence
  const eventKeys = Object.keys(events)
    .map(key => parseInt(key))
    .sort((a, b) => a - b);
  // keep track of whether we're highlighting or context highlighting for a given substring
  let highlightCount = 0;
  let contextCount = 0;
  let lastStart = 0;
  eventKeys.forEach(key => {
    const { startContext, endContext, startHighlight, endHighlight } = events[key];
    // will be the string type of the text from lastStart up to key
    let stringType: keyof typeof components = 'plainText';
    let str = '';
    // conditionals to handle context and highlight starts and ends
    if (startHighlight) {
      if (!highlightCount) {
        // if we're not already highlighting, slice the text up to this point
        str = text.slice(lastStart, key);
        // set the type for this substring based on whether we were already context highlighting or not
        stringType = contextCount ? 'contextText' : 'plainText';
      }
      // increment highlight count so next iteration knows how many highlights are going on (one or more)
      highlightCount += startHighlight;
    }
    if (startContext) {
      // we don't need to slice if we're already highlighting, since that takes precedence
      if (!contextCount && !highlightCount) {
        str = text.slice(lastStart, key);
      }
      contextCount += startContext;
    }
    if (endContext) {
      contextCount -= endContext;
      // only do something if no highlighting is going on, and there's no overlapping context highlighting
      if (!contextCount && !highlightCount) {
        // this substring gets context highlighting
        str = text.slice(lastStart, key);
        stringType = 'contextText';
      }
    }
    if (endHighlight) {
      highlightCount -= endHighlight;
      if (!highlightCount) {
        str = text.slice(lastStart, key);
        stringType = 'highlightText';
      }
    }
    if (str.length) {
      nodes.push(components[stringType](str, key.toString()));
      lastStart = key;
    }
  });
  // if there's any text left over, mark it as plain text
  const str = text.slice(lastStart);
  if (str.length) {
    nodes.push(plainText(str, 'last-string'));
  }
  nodes.push(plainText(' ', 'newline-fix'));
  return nodes;
};

export const replaceBadChars = (text?: string): string | undefined => {
  return text?.replaceAll('\t', ' ').replaceAll('\u00A0', ' ');
};
