import React from 'react';
import { View } from '@react-pdf/renderer';
import { PillScheme, pillThemes } from 'components/atoms/InfoPill';
import { Paragraph } from './Paragraph';
import { Padding } from './Padding';

export interface PillProps {
  label: string;
  scheme: PillScheme;
}

export const Pill: React.FC<PillProps> = props => {
  const theme = pillThemes[props.scheme];

  return (
    <View style={{ backgroundColor: theme.background, borderRadius: 10 }}>
      <Padding paddingHorizontal={10} paddingVertical={2}>
        <Paragraph bold text={props.label} color={theme.color} />
      </Padding>
    </View>
  );
};
