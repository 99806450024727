const getCountDistribution = (
  itemCounts: Record<string, number>
): {
  highFrequency: Record<number, string[]>;
  mediumFrequency: Record<number, string[]>;
  lowFrequency: Record<number, string[]>;
} => {
  const sortedCounts = Object.entries(itemCounts).sort(([, aCount], [, bCount]) => bCount - aCount);

  const highFrequencyMinimumValue = 8;
  const midFrequencyMinimumValue = 4;

  const skillFrequency = sortedCounts.reduce(
    (
      acc: Record<'highFrequency' | 'mediumFrequency' | 'lowFrequency', Record<string, string[]>>,
      [skillId, count]
    ) => {
      const stringCount = count.toString();
      const freq =
        count >= highFrequencyMinimumValue
          ? 'highFrequency'
          : count >= midFrequencyMinimumValue
          ? 'mediumFrequency'
          : 'lowFrequency';
      if (!acc[freq][stringCount]) {
        acc[freq][stringCount] = [skillId];
      } else {
        acc[freq][stringCount].push(skillId);
      }
      return acc;
    },
    { highFrequency: {}, mediumFrequency: {}, lowFrequency: {} }
  );

  return skillFrequency;
};

export default getCountDistribution;
