import { View } from '@react-pdf/renderer';
import React from 'react';

export interface StackItemProps {
  top?: number | null;
  right?: number | null;
  left?: number | null;
  bottom?: number | null;
  inset?: number | null;
}

export const StackItem: React.FC<StackItemProps> = ({
  top,
  right,
  left,
  bottom,
  children,
  inset
}) => {
  return (
    <View
      style={{
        position: 'absolute',
        top: top ?? inset ?? undefined,
        right: right ?? inset ?? undefined,
        left: left ?? inset ?? undefined,
        bottom: bottom ?? inset ?? undefined
      }}
    >
      {children}
    </View>
  );
};

export const Stack: React.FC = ({ children }) => {
  return <View style={{ position: 'relative', width: '100%', height: '100%' }}>{children}</View>;
};
