import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { useSkillsByIds, useSkillsInProgram } from 'hooks/curricularSkills';
import {
  getBucketsFromBulkRankings,
  useBulkJPARankingsRequests,
  useJPARankingByFacet,
  useJPATimeseriesRankingByFacet
} from 'hooks/jpaHooks';
import { applyBenchmarkToFilters } from 'components/organisms/ProgramInsights';
import { UniversalBenchmark, occupationBenchmarkToUniversal } from 'helpers/benchmarks/universal';
import { buildSkillData } from 'helpers/skillGapTrackerTable/graphDataFormatter';
import {
  associateDdnWithSkills,
  associateSalaryBoostingWithSkills,
  BenchmarkedSkillSignificance
} from 'helpers/benchmarkSkillInfo';
import { fetchProjectedSkillGrowth } from 'services/projectedSkillGrowth';
import { keyBy } from 'lodash';
import { getSkillCounts } from 'services/curricularSkills';
import { useProfileState } from 'store/profileStore';
import { useProgramCustomBenchmarks, useProgramOccupations } from './benchmarks';
import { ChartSettings } from 'store/programData';

export interface EnhancedSkill extends Skill {
  count: number;
  taughtState: TaughtState;
}

const convertSkillsToObject = (skills: Skill[]) =>
  skills.reduce<SkillsWithTypeObject>(
    (skillObject, { id, name, isSoftware, type, subcategory, category }) => {
      skillObject[id] = {
        id,
        name,
        isSoftware,
        type,
        subcategory,
        category
      };
      return skillObject;
    },
    {}
  );

type SkillsWithTypeObject = {
  [skillId: string]: Skill;
};

export const useProgramInsightSkills = (programId: string) => {
  const [topBenchmarkSkills, setTopBenchmarkSkills] = React.useState<SkillsWithTypeObject>({});
  const [lotSkills, setLotSkills] = React.useState<SkillsWithTypeObject>({});

  const {
    data: occupations,
    isLoading: occupationsIsLoading,
    error: occupationsError
  } = useProgramOccupations(programId);

  const {
    data: customBenchmarks,
    isLoading: customBenchmarksIsLoading,
    error: customBenchmarksError
  } = useProgramCustomBenchmarks(programId);

  const [jpaBenchmarks, textBenchmarks] = customBenchmarks.reduce<
    [
      { id: string; attributes: JPABenchmark }[],
      { id: string; attributes: SkillsFromTextBenchmark }[]
    ]
  >(
    ([jpa, text], benchmark) => {
      benchmark.type === 'skillsFromTextBenchmark'
        ? text.push({ id: benchmark.id, attributes: benchmark })
        : jpa.push({ id: benchmark.id, attributes: benchmark });
      return [jpa, text];
    },
    [[], []]
  );

  const jpaBenchmarkFacetPayloads = React.useMemo(() => {
    return jpaBenchmarks.map(benchmark => ({
      facet: 'skills',
      jpaOptions: { filter: { ...benchmark.attributes.facets }, rank: { limit: 20 } }
    }));
  }, [customBenchmarks]);

  const { data: jpaRankings } = useBulkJPARankingsRequests(jpaBenchmarkFacetPayloads);
  const textBenchmarkSkills = textBenchmarks.flatMap(
    textBenchmark => textBenchmark.attributes.facets.skills
  );

  const programInsightSkillIds = [
    ...new Set(
      getBucketsFromBulkRankings(jpaRankings)
        .flat()
        .map(skill => skill.name)
        .concat(textBenchmarkSkills)
    )
  ];

  const {
    isLoading: programInsightSkillsIsLoading,
    skills: rawProgramInsightSkills,
    error: programInsightSkillsError
  } = useSkillsByIds(programInsightSkillIds);

  useEffect(() => {
    if (rawProgramInsightSkills.length) {
      setTopBenchmarkSkills(
        rawProgramInsightSkills.reduce<SkillsWithTypeObject>(
          (skillObject, { id, name, type, subcategory, category }) => {
            skillObject[id] = {
              id,
              name,
              type,
              subcategory,
              category
            };
            return skillObject;
          },
          {}
        )
      );
    }
  }, [rawProgramInsightSkills]);

  const lotFacetPayloads = React.useMemo<{ facet: 'skills'; jpaOptions: JPAOptions }[]>(
    () =>
      occupations.map(lot => ({
        facet: 'skills',
        jpaOptions: {
          filter: { [lot.type]: [lot.id] },
          rank: { limit: 70, by: 'significance' }
        }
      })),
    [occupations]
  );

  const {
    data: lightcastOccupationRankings,
    isLoading: lightcastOccupationRankingsIsLoading,
    error: lightcastOccupationRankingsError
  } = useBulkJPARankingsRequests(lotFacetPayloads);

  const topTenPercentSkills = lightcastOccupationRankings.map(({ buckets, totals }) =>
    buckets.filter(
      bucket =>
        totals.unique_postings && (bucket.unique_postings / totals.unique_postings) * 100 >= 10
    )
  );

  const lightcastOccupationSkillIds = [
    ...new Set(topTenPercentSkills.flat().map(skill => skill.name))
  ];

  const {
    isLoading: lotSkillsIsLoading,
    skills: rawLotSkills,
    error: lotSkillsError
  } = useSkillsByIds(lightcastOccupationSkillIds);

  useEffect(() => {
    if (rawLotSkills.length) {
      setLotSkills(convertSkillsToObject(rawLotSkills));
    }
  }, [rawLotSkills]);

  const skills = { ...topBenchmarkSkills, ...lotSkills };

  const isLoading =
    occupationsIsLoading ||
    customBenchmarksIsLoading ||
    lightcastOccupationRankingsIsLoading ||
    lotSkillsIsLoading ||
    programInsightSkillsIsLoading ||
    !Object.keys(skills).length;

  const error =
    occupationsError ||
    customBenchmarksError ||
    lightcastOccupationRankingsError ||
    lotSkillsError ||
    programInsightSkillsError;

  if (!customBenchmarks.length && !occupations.length) {
    return { data: {}, isLoading, error };
  }

  return { data: skills, isLoading, error };
};

export type TaughtState = 'taught' | 'taught_other' | 'sought' | 'hypothetical';

export interface UseSkillDetailsSkillsInput {
  taughtSkillsInTargetOutcomes: Record<string, EnhancedSkill>;
  skillsNotTaughtInTargetOutcomes: Record<string, EnhancedSkill>;
}

export interface ProjectedSkillGrowth {
  percentage: number;
  note: string;
}

export interface SkillDetailInformation {
  id: string;
  name: string;
  category?: { id: string; name: string };
  subcategory?: { id: string; name: string };
  taughtState: TaughtState;
  count: number;
  growthPercentage: number | null;
  projectedGrowth: ProjectedSkillGrowth | null;
  salaryBoostingSkillFor: UniversalBenchmark[];
  significance: BenchmarkedSkillSignificance[];
  identifier?: string;
}

export interface SkillDetailCategoryInformation {
  id: string;
  name: string;
}

export function useJobPostingCounts(skillIds: string[], jpaFilters: JPAOptionsFilter[]) {
  const {
    data: jpaData,
    isLoading,
    error
  } = useJPARankingByFacet(
    'skills',
    {
      filter: jpaFilters,
      rank: { by: 'unique_postings', limit: skillIds.length, include: skillIds }
    },
    { queryOptions: { enabled: !!skillIds.length } }
  );

  const data = useMemo(() => {
    if (!jpaData) {
      return {};
    }

    const newData: Record<string, number> = {};

    for (const bucket of jpaData.buckets) {
      newData[bucket.name] = bucket.unique_postings;
    }

    return newData;
  }, [jpaData]);

  return { data, isLoading, error };
}

export interface UseSkillDetailsParams {
  programId: string;
  jpaFilters: JPAOptionsFilter;
  currentTimeframe: number;
  chartSettings: ChartSettings;
}

export function useSkillDetails({
  programId,
  jpaFilters,
  currentTimeframe,
  chartSettings
}: UseSkillDetailsParams) {
  const {
    data: occupations,
    isLoading: occupationsIsLoading,
    error: occupationsError
  } = useProgramOccupations(programId);

  const { data: taughtAndSoughtData, isLoading: taughtAndSoughtDataIsLoading } =
    useFilteredTaughtVsSoughtSkills(programId, jpaFilters, chartSettings);

  const {
    data: benchmarkFilteredQuery,
    isLoading: benchmarkFilteredQueryIsLoading,
    error: benchmarkFilteredQueryError
  } = useBenchmarkFilteredQuery({ programId, chartSettings, jpaQuery: jpaFilters });

  const benchmarks = useMemo(() => occupations.map(occupationBenchmarkToUniversal), [occupations]);

  const skills = useMemo(() => {
    const detail: SkillDetailInformation[] = [];

    const addDetail = (skill: EnhancedSkill) =>
      detail.push({
        id: skill.id,
        name: skill.name,
        category: skill.category,
        subcategory: skill.subcategory,
        count: skill.count,
        growthPercentage: null,
        salaryBoostingSkillFor: [],
        significance: [],
        projectedGrowth: null,
        taughtState: skill.taughtState
      });

    for (const skill of Object.values(taughtAndSoughtData.skills.skillsNotTaughtInTargetOutcomes)) {
      addDetail(skill);
    }

    for (const skill of Object.values(taughtAndSoughtData.skills.taughtSkillsInTargetOutcomes)) {
      addDetail(skill);
    }

    return detail;
  }, [taughtAndSoughtData.skills]);

  const skillIds = useMemo(() => skills.map(skill => skill.id), [skills]);

  const {
    data: rawProjectedSkillGrowth,
    isLoading: projectedSkillGrowthIsLoading,
    error: projectedSkillGrowthError
  } = useQuery(['projected-skill-growth', ...skillIds], () => fetchProjectedSkillGrowth(skillIds), {
    enabled: !!skillIds.length
  });

  const projectedSkillGrowth = useMemo(() => {
    const record: Record<string, ProjectedSkillGrowth> = {};

    if (!rawProjectedSkillGrowth) {
      return record;
    }

    for (const skill of rawProjectedSkillGrowth) {
      if (skill.growthPercent.oneYear) {
        record[skill.id] = {
          percentage: skill.growthPercent.oneYear * 100,
          note: skill.growthCategory
        };
      }
    }

    return record;
  }, [rawProjectedSkillGrowth]);

  const benchmarkIds = useMemo(
    () => benchmarks.map(benchmark => benchmark.attributes.id),
    [benchmarks]
  );

  const {
    data: ddnSkills,
    isLoading: ddnSkillsIsLoading,
    error: ddnSkillsError
  } = useQuery(['ddn-skills', ...benchmarkIds], () => associateDdnWithSkills(benchmarks));

  const {
    data: salaryBoostingSkills,
    isLoading: salaryBoostingSkillsIsLoading,
    error: salaryBoostingSkillsError
  } = useQuery(['salary-boosting-skills', ...benchmarkIds], () =>
    associateSalaryBoostingWithSkills(benchmarks)
  );

  const today = dayjs();

  const end = today.subtract(currentTimeframe, 'months').format('YYYY-MM-DD');
  const start = today.subtract(currentTimeframe * 2, 'months').format('YYYY-MM-DD');

  const {
    data: lastTimeframePostings,
    isLoading: lastTimeframePostingsIsLoading,
    error: lastTimeframeError
  } = useJobPostingCounts(
    skillIds,
    benchmarkFilteredQuery.map(filter => ({ ...filter, when: { start, end } }))
  );

  const data = useMemo(
    () =>
      skills.map<SkillDetailInformation>(skill => ({
        ...skill,
        growthPercentage: lastTimeframePostings?.[skill.id]
          ? getSkillGrowthPercentage(lastTimeframePostings[skill.id], skill.count)
          : null,
        salaryBoostingSkillFor: salaryBoostingSkills?.[skill.id] || [],
        significance: ddnSkills?.[skill.id] || [],
        projectedGrowth: projectedSkillGrowth[skill.id] || null
      })),
    [skills, projectedSkillGrowth, salaryBoostingSkills, ddnSkills, lastTimeframePostings]
  );

  const isLoading =
    taughtAndSoughtDataIsLoading ||
    occupationsIsLoading ||
    benchmarkFilteredQueryIsLoading ||
    projectedSkillGrowthIsLoading ||
    salaryBoostingSkillsIsLoading ||
    ddnSkillsIsLoading ||
    lastTimeframePostingsIsLoading;

  const error =
    occupationsError ||
    benchmarkFilteredQueryError ||
    projectedSkillGrowthError ||
    salaryBoostingSkillsError ||
    ddnSkillsError ||
    lastTimeframeError;

  return { data, isLoading, error };
}

export interface UseSkillTimeseriesTrackerParams {
  programId: string;
  jpaFilters: JPAOptionsFilter;
  chartSettings: ChartSettings;
  numberOfSkillsToShow: number;
  hypotheticalSkillIds: string[];
  chartMetric?: JPATotalsMetric;
}

export function useSkillTimeseriesTracker({
  programId,
  jpaFilters,
  chartSettings,
  numberOfSkillsToShow,
  hypotheticalSkillIds,
  chartMetric = 'unique_postings'
}: UseSkillTimeseriesTrackerParams) {
  const { data: filteredTaughtSoughtData, isLoading: taughtSoughtFilteredSkillsLoading } =
    useFilteredTaughtVsSoughtSkills(programId, jpaFilters, chartSettings);

  const {
    data: benchmarkFilteredQuery,
    isLoading: benchmarkFilteredQueryIsLoading,
    error: benchmarkFilteredQueryError
  } = useBenchmarkFilteredQuery({ programId, chartSettings, jpaQuery: jpaFilters });

  const filteredSkillIds = useMemo(() => {
    return [
      ...Object.keys(filteredTaughtSoughtData.skills.taughtSkillsInTargetOutcomes),
      ...Object.keys(filteredTaughtSoughtData.skills.skillsNotTaughtInTargetOutcomes)
    ];
  }, [filteredTaughtSoughtData.skills]);

  const timeseriesWhen: JPAWhenOption = {
    start: dayjs().subtract(3, 'year').format('YYYY-MM'),
    end: dayjs().subtract(1, 'month').format('YYYY-MM')
  };

  const {
    data: timeseriesData,
    isFetching: isTimeseriesLoading,
    error: timeseriesError
  } = useJPATimeseriesRankingByFacet(
    'skills',
    {
      // maintain other insights filters except for 'when'
      filter: benchmarkFilteredQuery.map(query => {
        return { ...query, skills: filteredSkillIds, when: timeseriesWhen };
      }),
      rank: { by: 'unique_postings', limit: filteredSkillIds.length, include: filteredSkillIds },
      timeseries: {
        when: timeseriesWhen,
        metrics: [chartMetric]
      }
    },
    {
      queryOptions: {
        enabled: Boolean(filteredSkillIds.length && benchmarkFilteredQuery.length)
      }
    }
  );

  const graphData = useMemo(() => {
    if (timeseriesData && filteredSkillIds.length) {
      // partition and concat the data back into taught and sought sections
      const taughtBuckets: JPATimeseriesResponseBucket[] = [];
      const soughtBuckets: JPATimeseriesResponseBucket[] = [];
      for (const bucket of timeseriesData.buckets) {
        if (filteredTaughtSoughtData.skills.taughtSkillsInTargetOutcomes[bucket.name]) {
          taughtBuckets.push(bucket);
        } else {
          soughtBuckets.push(bucket);
        }
      }

      return buildSkillData(
        taughtBuckets,
        soughtBuckets,
        hypotheticalSkillIds,
        chartMetric,
        filteredTaughtSoughtData.skills,
        numberOfSkillsToShow
      );
    }
    return;
  }, [timeseriesData, numberOfSkillsToShow, hypotheticalSkillIds, filteredSkillIds]);

  const isLoading =
    taughtSoughtFilteredSkillsLoading || benchmarkFilteredQueryIsLoading || isTimeseriesLoading;
  const error = benchmarkFilteredQueryError || timeseriesError;

  return { graphData, isLoading, error };
}

function getSkillGrowthPercentage(oldCount: number, newCount: number): number {
  const shouldBeNegative = newCount < oldCount;
  const difference = Math.abs(newCount - oldCount);
  const ratio = difference / oldCount;
  const percentage = ratio * 100;

  if (shouldBeNegative) {
    return percentage * -1;
  }

  return percentage;
}

export interface UseBenchmarkFilteredQueryParams extends HookOptions {
  programId: string;
  chartSettings: ChartSettings;
  jpaQuery: JPAOptionsFilter;
}

export function useBenchmarkFilteredQuery(params: UseBenchmarkFilteredQueryParams) {
  const {
    data: customBenchmarks,
    isLoading: customBenchmarksIsLoading,
    error: customBenchmarksError
  } = useProgramCustomBenchmarks(params.programId); // TODO implement enabled

  const {
    data: occupations,
    isLoading: occupationsIsLoading,
    error: occupationsError
  } = useProgramOccupations(params.programId); // TODO implement enabled

  const benchmarkFilteredQuery = React.useMemo(() => {
    if (!params.chartSettings.filterPostingsByBenchmarks) {
      return [params.jpaQuery];
    }
    const allBenchmarks = [...customBenchmarks, ...occupations];

    const [skillsFilter, filters] = allBenchmarks.reduce<
      [JPAOptionsFilter | undefined, JPAOptionsFilter[]]
    >(
      (result, benchmark) => applyBenchmarkToFilters(result, benchmark, params.jpaQuery),
      [undefined, []]
    );

    if (skillsFilter) {
      filters.push(skillsFilter);
    }
    return filters;
  }, [
    params.jpaQuery,
    customBenchmarks,
    occupations,
    params.chartSettings.filterPostingsByBenchmarks
  ]);

  return {
    data: benchmarkFilteredQuery,
    isLoading: occupationsIsLoading || customBenchmarksIsLoading,
    error: occupationsError || customBenchmarksError
  };
}

export interface FilteredTaughtSoughtSkills {
  taughtSkillsInTargetOutcomes: Record<string, EnhancedSkill>;
  skillsNotTaughtInTargetOutcomes: Record<string, EnhancedSkill>;
}

export const useFilteredTaughtVsSoughtSkills = (
  programId: string,
  postingsQuery: JPAOptionsFilter,
  chartSettings: ChartSettings
) => {
  const { currentSite } = useProfileState();

  const {
    data: skillsInProgram,
    isLoading: skillsInProgramIsLoading,
    error: skillsInProgramError
  } = useSkillsInProgram(programId);

  const {
    data: skills,
    isLoading: skillsIsLoading,
    error: skillsError
  } = useProgramInsightSkills(programId);

  const {
    data: benchmarkFilteredQuery,
    isLoading: benchmarkFilteredQueryIsLoading,
    error: benchmarkFilteredQueryError
  } = useBenchmarkFilteredQuery({ chartSettings, jpaQuery: postingsQuery, programId });

  const skillIds = Object.keys(skills);

  const {
    data: skillPostingCounts,
    isLoading: skillPostingCountsIsLoading,
    error: skillPostingCountsError
  } = useJobPostingCounts(skillIds, benchmarkFilteredQuery);

  const {
    data: skillCounts,
    isLoading: skillCountsIsLoading,
    error: skillCountsError
  } = useQuery(
    ['course-skill-counts', skillIds, currentSite],
    () => getSkillCounts('course', skillIds, currentSite).then(({ data: { counts } }) => counts),
    {
      enabled: !!skillIds.length
    }
  );

  const reduceJPADataForSkills = (ids: string[]) => {
    return ids.reduce<Record<string, EnhancedSkill>>((acc, id: string) => {
      acc[id] = {
        id,
        name: skills?.[id].name,
        isSoftware: skills?.[id].isSoftware,
        type: skills?.[id].type,
        subcategory: skills?.[id].subcategory,
        category: skills?.[id].category,
        count: skillPostingCounts[id] || 0,
        taughtState: skillsInProgram.skillsInProgramNameMapping[id]
          ? 'taught'
          : skillCounts?.[id]
          ? 'taught_other'
          : 'sought'
      };
      return acc;
    }, {});
  };

  const reducedSkills = React.useMemo(() => {
    const taughtSkills: string[] = [];
    const soughtSkills: string[] = [];

    skillIds.forEach(id =>
      skillsInProgram.skillsInProgramNameMapping[id] ? taughtSkills.push(id) : soughtSkills.push(id)
    );

    return {
      taughtSkillsInTargetOutcomes: reduceJPADataForSkills(taughtSkills),
      skillsNotTaughtInTargetOutcomes: reduceJPADataForSkills(soughtSkills)
    };
  }, [skillPostingCounts, skillCounts, skillsInProgram]);

  const reducedSkillsSoughtLength = Object.keys(
    reducedSkills.skillsNotTaughtInTargetOutcomes
  ).length;

  const reducedSkillsTaughtLength = Object.keys(reducedSkills.taughtSkillsInTargetOutcomes).length;

  const filteredSkills = React.useMemo<FilteredTaughtSoughtSkills>(() => {
    if (!chartSettings.selectedSkillLevels?.length) {
      return reducedSkills;
    }

    const { taughtSkillsInTargetOutcomes, skillsNotTaughtInTargetOutcomes } = reducedSkills;
    const isSoftwareSelected = chartSettings.selectedSkillLevels?.includes('Software Skill');

    const filterSkills = (skillsToFilter: Record<string, EnhancedSkill>) => {
      return keyBy(
        Object.values(skillsToFilter)
          .filter(skill => (isSoftwareSelected ? true : !skill.isSoftware))
          .filter(
            skill =>
              (skill.type?.name && chartSettings.selectedSkillLevels?.includes(skill.type.name)) ||
              (isSoftwareSelected && skill.isSoftware)
          ),
        'id'
      );
    };

    return {
      taughtSkillsInTargetOutcomes: filterSkills(taughtSkillsInTargetOutcomes),
      skillsNotTaughtInTargetOutcomes: filterSkills(skillsNotTaughtInTargetOutcomes)
    };
  }, [chartSettings, reducedSkills]);

  const isLoading =
    skillsInProgramIsLoading ||
    skillsIsLoading ||
    benchmarkFilteredQueryIsLoading ||
    skillPostingCountsIsLoading ||
    skillCountsIsLoading;

  const error =
    skillsInProgramError ||
    skillsError ||
    benchmarkFilteredQueryError ||
    skillPostingCountsError ||
    skillCountsError;

  return {
    data: {
      skills: filteredSkills,
      skillsLength: {
        taughtSkills: reducedSkillsTaughtLength,
        soughtSkills: reducedSkillsSoughtLength
      }
    },
    isLoading,
    error
  };
};
