import React from 'react';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { useController, useForm } from 'react-hook-form';

import CurricularSelectModalStyles from 'components/atoms/CurricularSelectModalStyles';
import SelectProgramForm from 'components/organisms/SelectProgramForm';
import StickyButtonBar from 'components/molecules/StickyButtonBar';

import { fog } from 'utils/colors';
import { Item } from '../SelectedItemsCard';

const ModalWrapper = styled.div`
  width: 110rem;
  background: ${fog};
`;

const StyledBar = styled(StickyButtonBar)`
  width: 100%;
`;

const ProgramSelectModal: React.FC<{
  closeModal: () => void;
  defaultValue?: readonly Item[];
  onSubmit?: (programs: readonly Item[]) => void;
  locationStateKey: string;
}> = ({ closeModal, defaultValue, onSubmit, locationStateKey }) => {
  const { control } = useForm<{ selectedPrograms: readonly Item[] }>();

  const { field: selectedPrograms } = useController({
    name: 'selectedPrograms',
    control,
    defaultValue
  });

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        onSubmit && onSubmit(selectedPrograms.value);
        closeModal();
      }}
    >
      <ModalWrapper>
        <SelectProgramForm
          css={CurricularSelectModalStyles}
          defaultValue={defaultValue}
          onChange={selectedPrograms.onChange}
          locationStateKey={locationStateKey}
        />
        <StyledBar onCancel={closeModal} submitText="Save" isSubmitting={false} />
      </ModalWrapper>
    </form>
  );
};

export default ProgramSelectModal;
