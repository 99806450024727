import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { LocationDescriptor } from 'history';

import { useAuthState } from 'store/authStore';
import TOSModal from 'components/molecules/TOSModal';

interface RequireAuthProps {
  target?: LocationDescriptor;
  children?: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ target, children }) => {
  const { state, pathname, search, hash } = useLocation();
  const { isLoggedIn, isInitialized } = useAuthState();
  return !isInitialized ? (
    <div />
  ) : !isLoggedIn ? (
    <Redirect
      to={{
        pathname: '/auth/login',
        state: { redirectTarget: target ? target : { pathname, search, hash, state } }
      }}
    />
  ) : (
    <>
      <TOSModal />
      <>{children}</>
    </>
  );
};

export default RequireAuth;
