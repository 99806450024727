import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useWatch, FieldError } from 'react-hook-form';

import { highlightParsedSkills, highlightRevealedSkill } from 'helpers/highlightParsedSkills';
import { PreprocessedSkill } from 'services/skills';

import {
  darkBlue,
  bgLightGray,
  borderGray,
  ahoy,
  highlightYellow,
  contextBlue
} from 'utils/colors';

const highlightText = (text: string, key: string) => (
  <Highlight key={`highlight-${key}`}>{text}</Highlight>
);
const contextText = (text: string, key: string) => <Context key={`context-${key}`}>{text}</Context>;
const plainText = (text: string, key: string) => <span key={`plainText-${key}`}>{text}</span>;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  highlights: PreprocessedSkill[];
  name: string;
  error?: FieldError;
  revealedSkill?: string;
}

const HighlightedFormInput = React.forwardRef<HTMLInputElement, Props>(
  ({ highlights, error, revealedSkill, ...props }, ref) => {
    const text = useWatch({
      name: props.name
    });

    const highlightNodes = React.useMemo(() => {
      if (text) {
        if (revealedSkill) {
          return highlightRevealedSkill(
            text,
            plainText,
            highlightText,
            contextText,
            revealedSkill,
            highlights
          );
        }
        return highlightParsedSkills(text, highlightText, plainText, highlights);
      }
      return [];
    }, [highlights, text, revealedSkill]);

    const highlightsRef = useRef<HTMLDivElement>(null);

    return (
      <OuterWrapper>
        <HighlightableWrapper>
          <Highlights error={!!error} ref={highlightsRef}>
            {highlightNodes}
          </Highlights>
          <Input ref={ref} {...props} />
        </HighlightableWrapper>
        <ErrorWrapper>{error && <Error>{error.message}</Error>}</ErrorWrapper>
      </OuterWrapper>
    );
  }
);

const Highlights = styled.div<{ error?: boolean }>`
  margin-bottom: 2.4rem;
  padding: 1rem 1.6rem;
  border: ${props => (props.error ? `1px solid ${ahoy}` : `1px solid ${borderGray}`)};
  border-radius: 0.4rem;
  background: ${bgLightGray};
  width: 100%;
  height: 100%;
  background-color: ${bgLightGray};
  font-size: 1.4rem;
  white-space: pre;
  border-radius: 0.3rem;
  position: absolute;
  line-height: 1.6;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Input = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: transparent;
  color: transparent;
  caret-color: ${darkBlue};
  padding: 1rem 1.6rem;
  font-size: 1.4rem;
  border: none;
  border-radius: 0.3rem;
  line-height: 1.6;
  position: relative;

  ::placeholder {
    font-style: italic;
    color: ${darkBlue};
  }
`;

const HighlightableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2.4rem;
`;

const Highlight = styled.span`
  background-color: ${highlightYellow};
  padding: 0 0.1rem;
  margin: 0 -0.1rem;
`;

const OuterWrapper = styled.div`
  position: relative;
`;

const ErrorWrapper = styled.div`
  position: relative;
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: ${ahoy};
  position: absolute;
  bottom: 0.5rem;
`;

const Context = styled.span`
  background-color: ${contextBlue};
  padding: 0 0.1rem;
  margin: 0 -0.1rem;
`;

export default HighlightedFormInput;
