import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import EmptyDashboardTable from 'components/atoms/EmptyDashboardTable';
import LoadingDashboardTable from 'components/atoms/LoadingDashboardTable';
import TableFilters from 'components/organisms/TableFilters';
import DashboardTableStyles from 'components/atoms/DashboardTableStyles';
import Pagination from 'components/molecules/Pagination';
import Table, {
  svgStyles,
  InvisibleButton,
  HeaderCell,
  Cell,
  TableNumber,
  Column
} from 'components/molecules/Table';
import SelectText from 'components/molecules/SelectText';
import ButtonFlyoutMenu from 'components/organisms/ButtonFlyoutMenu';
import { prepareCourses } from 'helpers/prepareTables';
import { ReactComponent as Check } from 'images/greenCheck.svg';
import { ReactComponent as XRemove } from 'images/closebtn.svg';
import { ReactComponent as Document } from 'images/document.svg';
import { ReactComponent as Clock } from 'images/clock.svg';
import { ReactComponent as SortArrow } from 'images/sortArrow.svg';
import { darkDarkEmerald, darkSaphire } from 'utils/colors';

import {
  useTableState,
  useTableDispatch,
  isPageSelected,
  getSelectedOnPage
} from 'store/tableStore';

import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import { DEFAULT_DASHBOARD_TABLE_PAGE_SIZE, useCourses, usePrograms, useQueryString } from 'hooks';

import { TableProvider } from 'store/tableStore';

const getTableColumns = (
  hasEditorAccess: boolean,
  exclude: (keyof PreppedCourse)[]
): Column<PreppedCourse, CourseSortOption>[] => {
  let columns: Column<PreppedCourse, CourseSortOption>[] = [
    {
      id: 'title',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell width="100%" key="title-header">
          <SortableHeader
            data-cy="courses-dashboard-table_course-title-sort"
            onClick={() => onSort?.('title')}
          >
            Course Title
            {sortedColumn && (
              <StyledSortArrow
                isHidden={sortedColumn.sortType !== 'title'}
                sortOrder={sortedColumn.sortOrder}
              >
                <SortArrow />
              </StyledSortArrow>
            )}
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`title-${row.id}`}>
          <InvisibleButton>{row.title}</InvisibleButton>
        </Cell>
      )
    },
    {
      id: 'courseId',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="code-header"
          css={css`
            min-width: 10rem;

            @media (min-width: 1700px) {
              padding-right: 10rem;
            }

            @media (min-width: 1920px) {
              padding-right: 15rem;
            }
          `}
        >
          <SortableHeader
            data-cy="courses-dashboard-table_code-sort"
            onClick={() => onSort?.('courseId')}
          >
            Code
            <StyledSortArrow
              isHidden={sortedColumn?.sortType !== 'courseId'}
              sortOrder={sortedColumn?.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => <Cell key={`code-${row.id}`}>{row.courseId}</Cell>
    },
    {
      id: 'skills',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="skills-header"
          css={css`
            min-width: 10rem;
            @media (min-width: 1700px) {
              padding-right: 10rem;
            }
            @media (min-width: 1920px) {
              padding-right: 15rem;
            }
          `}
        >
          <SortableHeader
            data-cy="courses-dashboard-table_skills-sort"
            onClick={() => onSort?.('skills')}
          >
            Skills
            <StyledSortArrow
              isHidden={sortedColumn?.sortType !== 'skills'}
              sortOrder={sortedColumn?.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`skills-${row.id}`}>
          <TableNumber>{row.skills}</TableNumber>
        </Cell>
      )
    },
    {
      id: 'isRequired',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="isRequired-header"
          css={css`
            min-width: 26rem;
            @media (min-width: 1700px) {
              padding-right: 10rem;
            }
            @media (min-width: 1920px) {
              padding-right: 15rem;
            }
          `}
        >
          Required Course
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`required-${row.id}`}>
          {row.isRequired ? (
            <Flex>
              <Check
                css={css`
                  ${svgStyles}
                  stroke: ${darkSaphire};
                `}
              />
              <span
                css={css`
                  color: ${darkSaphire};
                `}
              >
                Yes
              </span>
            </Flex>
          ) : (
            <Flex>
              <XRemove css={svgStyles} />
              No
            </Flex>
          )}
        </Cell>
      )
    },
    {
      id: 'isPublished',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="status-header"
          css={css`
            min-width: 14rem;
            @media (min-width: 1700px) {
              padding-right: 10rem;
            }
            @media (min-width: 1920px) {
              padding-right: 15rem;
            }
          `}
        >
          <SortableHeader onClick={() => onSort?.('isPublished')}>
            Status
            <StyledSortArrow
              isHidden={sortedColumn?.sortType !== 'isPublished'}
              sortOrder={sortedColumn?.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`status-${row.id}`}>
          {row.isPublished ? (
            <Flex>
              <Check
                css={css`
                  ${svgStyles}
                  stroke: ${darkDarkEmerald};
                `}
              />
              <span
                css={css`
                  color: ${darkDarkEmerald};
                `}
              >
                Published
              </span>
            </Flex>
          ) : (
            <Flex>
              <Document css={svgStyles} />
              Draft
            </Flex>
          )}
        </Cell>
      )
    },
    {
      id: 'updatedAt',
      renderHeader: ({ onSort, sortedColumn }) => (
        <HeaderCell
          key="updated-at-header"
          css={css`
            min-width: 16rem;
          `}
        >
          <SortableHeader onClick={() => onSort?.('updatedAt')}>
            Last Edited
            <StyledSortArrow
              isHidden={sortedColumn?.sortType !== 'updatedAt'}
              sortOrder={sortedColumn?.sortOrder}
            >
              <SortArrow />
            </StyledSortArrow>
          </SortableHeader>
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`updated-at-${row.id}`}>
          <Flex>
            <Clock css={svgStyles} />
            {row.updatedAt}
          </Flex>
        </Cell>
      )
    }
  ];
  if (hasEditorAccess) {
    columns.push({
      id: 'edit',
      renderHeader: () => (
        <HeaderCell
          className="edit-column"
          key="edit-header"
          css={css`
            min-width: 8rem;
          `}
        >
          Edit
        </HeaderCell>
      ),
      renderCell: row => (
        <Cell key={`edit-${row.id}`} className="edit-column">
          <ButtonFlyoutMenu tableType="course" row={row} />
        </Cell>
      )
    });
  }

  if (exclude.length) {
    columns = columns.filter(col => !exclude.includes(col.id));
  }

  return columns;
};

interface CoursesDashboardTableProps {
  className?: string;
  trackPages?: boolean;
  tableFilters?: Partial<Omit<CourseSearchFilters, 'skills' | 'text'>>;
  hasEditorPrivileges?: boolean;
  displayRequiredCourses?: boolean;
}

export const buildSearchPayload = (
  { filter, sort }: CurricularRouteSearchState<CourseSortOption>,
  tableFilters?: Partial<Omit<CourseSearchFilters, 'skills'>>
) => {
  const filters: CourseSearchFilters = tableFilters || {};
  const sorts: Sort<CourseSortOption> = {};

  if (filter.filterType === 'text' && filter.filterQuery.query.length > 1) {
    filters.textFilter = filter.filterQuery;
  }

  if (filter.filterType === 'skills' && filter.filterQuery.length) {
    if (filter.filterOperator === 'all') {
      filters.skills = { all: filter.filterQuery.map(skill => skill.id) };
    } else {
      filters.skills = { in: filter.filterQuery.map(skill => skill.id) };
    }
  }

  if (filter.filterType === 'programs' && filter.filterQuery.length) {
    filters.associatedGroups = { in: filter.filterQuery.map(skill => skill.id) };
  }
  if (sort) {
    sorts.sort = [[sort.sortType, sort.sortOrder]];
  }

  return { filter: filters, sort: sorts.sort };
};

export const LOCATION_STATE_KEY = 'courseDashboardFilters';
const CoursesDashboardTable: React.FC<CoursesDashboardTableProps> = ({
  className,
  trackPages,
  tableFilters,
  hasEditorPrivileges = false,
  displayRequiredCourses = false
}) => {
  const { page: queryPage } = useQueryString();
  const history = useHistory();
  const location = useLocation<{
    [x: string]: CurricularRouteSearchState<CourseSortOption> | undefined;
  }>();
  const state = location?.state?.[LOCATION_STATE_KEY] || defaultCurricularRouteSearchState();

  const tableDispatch = useTableDispatch();
  const { selectedOrDeselected, mode, page } = useTableState();
  const currentPage = trackPages ? Number(queryPage) || 1 : page;

  const { data, isLoading, isPreviousData } = useCourses({
    limit: DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    offset: (currentPage - 1) * DEFAULT_DASHBOARD_TABLE_PAGE_SIZE,
    ...buildSearchPayload(state, tableFilters)
  });

  const { data: programData } = usePrograms(
    {
      filter: { ids: { in: [tableFilters?.associatedGroups?.in[0] || ''] } },
      limit: DEFAULT_DASHBOARD_TABLE_PAGE_SIZE
    },
    { enabled: (tableFilters?.associatedGroups?.in.length || 0) > 0 }
  );

  const requiredCourses = React.useMemo(() => {
    if (!programData) {
      return [];
    }

    const [program] = programData.data;

    return program.attributes.courses.filter(course => course.isRequired).map(course => course.id);
  }, [programData]);

  const courses = React.useMemo(() => data && prepareCourses(data, requiredCourses), [data]);

  const isRequesting = isLoading || isPreviousData;

  const idsOnPage = courses?.map(course => course.id) || [];

  const allItemsOnPageChecked = isPageSelected(mode, selectedOrDeselected, idsOnPage);

  const selectedOnPage = getSelectedOnPage(mode, selectedOrDeselected, idsOnPage);

  const pageCount = Math.ceil((data?.meta.totalAvailable || 0) / DEFAULT_DASHBOARD_TABLE_PAGE_SIZE);

  const columnsToHide: (keyof PreppedCourse)[] = displayRequiredCourses ? [] : ['isRequired'];

  const selectPage = () => {
    if (allItemsOnPageChecked) {
      tableDispatch({ type: 'DESELECT_PAGE', payload: idsOnPage });
    } else {
      tableDispatch({ type: 'SELECT_PAGE', payload: idsOnPage });
    }
  };

  const sortTable = React.useCallback(
    (column: CourseSortOption) => {
      const { sortOrder, sortType } = state.sort;
      let newSortOrder: 'ascending' | 'descending' = 'ascending';
      if (column === sortType) {
        newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
      }
      history.push(location.pathname, {
        [LOCATION_STATE_KEY]: {
          filter: { ...state.filter },
          sort: { sortOrder: newSortOrder, sortType: column }
        }
      });
    },
    [state]
  );

  return (
    <div className={className}>
      <TableFilters
        dataCount={data && data.meta.totalAvailable}
        tableType="Course"
        isRequesting={isRequesting}
        locationStateKey={LOCATION_STATE_KEY}
      />
      {courses ? (
        <>
          <SelectText
            tableItemLabel="course"
            allItemsOnPageChecked={allItemsOnPageChecked}
            selectableCount={data && data.meta.totalAvailable}
            onSelectAll={() => {
              tableDispatch({ type: 'SELECT_ALL' });
            }}
            clearSelection={() => tableDispatch({ type: 'DESELECT_ALL' })}
            selectPage={selectPage}
          />
          <Table
            tableCaption="Courses Table"
            rowAriaLabel="click this row to edit this course"
            css={DashboardTableStyles}
            columns={getTableColumns(hasEditorPrivileges, columnsToHide)}
            data={courses}
            onSort={sortTable}
            sortedColumn={state.sort}
            onSelect={id =>
              hasEditorPrivileges
                ? selectedOnPage.includes(id)
                  ? tableDispatch({ type: 'DESELECT_ROW', payload: id })
                  : tableDispatch({ type: 'SELECT_ROW', payload: id })
                : undefined
            }
            selected={hasEditorPrivileges ? selectedOnPage : undefined}
            selectPage={selectPage}
            onRowClick={row => {
              hasEditorPrivileges
                ? history.push(`/edit/course/${row.id}`)
                : history.push(`/course/summary/${row.id}`);
            }}
            EmptyComponent={<EmptyDashboardTable label="course" />}
          />
          <Pagination {...{ pageCount, trackPages }} />
        </>
      ) : (
        <LoadingDashboardTable />
      )}
    </div>
  );
};

export const CoursesDashboardTableWithProvider: React.FC<CoursesDashboardTableProps> = props => {
  return (
    <TableProvider>
      <CoursesDashboardTable {...props} />
    </TableProvider>
  );
};

export default CoursesDashboardTable;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const SortableHeader = styled(Flex)`
  display: inline-flex;
  cursor: pointer;
`;

const StyledSortArrow = styled.div<{ isHidden: boolean; sortOrder?: 'ascending' | 'descending' }>`
  margin-left: 0.3rem;
  & > svg {
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    transform: ${({ sortOrder }) => (sortOrder === 'ascending' ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;
