import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Switch, Redirect, useHistory } from 'react-router-dom';

import MonitoredRoute from 'components/atoms/MonitoredRoute';

import LoginForm from 'components/organisms/LoginForm';
import ForgotPasswordForm from 'components/organisms/ForgotPasswordForm';
import ResetPasswordForm from 'components/organisms/ResetPasswordForm';
import InitialPasswordForm from 'components/organisms/InitialPasswordForm';
import Footer from 'components/molecules/AuthFooter';

import { ReactComponent as Logo } from 'images/logo.svg';
// import Glow from 'images/glow.svg';
import lightcastGradient from 'images/lightcast-gradient.jpg';
import { ReactComponent as LoginIllustration } from 'images/loginIllustration.svg';
import { white } from 'utils/colors';
import { useAuthState } from 'store/authStore';
import { ErrorBoundary } from '@sentry/react';
import Header from 'components/molecules/Header';

// This is the hard work that John did to get the glow working as the background. It is now commented out as to not lose
// the work/progress until we can figure out why the gradient svg is not working on firefox or safari
// background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 15%),
//   url(${Glow}) no-repeat center, white;
// background-size: cover;
// background-position: calc(80rem - 9vw) center;
// @media (max-width: 1600px) {
//   background-position: 58rem center;
// }
// @media (max-width: 1200px) {
//   background-position: 48rem center;
// }
// @media (max-width: 1024px) {
//   background: ${white};
// }

const Authorization: React.FC = () => {
  const { isLoggedIn } = useAuthState();
  const history = useHistory();
  React.useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  }, [isLoggedIn]);
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        background: ${white};
      `}
    >
      <div
        css={css`
          width: 50%;
          display: flex;
          flex-direction: column;
          padding-top: 6rem;
          padding-left: 8rem;
          padding-right: 8rem;
          max-width: 70rem;
          @media (max-width: 1600px) {
            padding-top: 0;
            padding-left: 4rem;
          }
          @media (max-width: 1024px) {
            width: 100%;
            max-width: none;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 3rem;
            @media (max-height: 800px) {
              margin-bottom: auto;
            }
            @media (max-width: 1024px) {
              justify-content: center;
              margin-left: 0;
            }
          `}
        >
          <Logo
            css={css`
              height: 4.4rem;
            `}
          />
          <h1
            css={css`
              font-size: 3.2rem;
              margin-left: 1.5rem;
            `}
          >
            Skillabi
          </h1>
        </div>
        <ErrorBoundary
          beforeCapture={scope => {
            scope.setTag('location', 'main-layout');
          }}
          fallback={() => (
            <div>
              <Header headerLevel="h3" headerText="Oh no, something went wrong!" />
              <p>Please try refreshing the page or coming back later.</p>
            </div>
          )}
        >
          <Switch>
            <MonitoredRoute path="/auth" exact>
              <Redirect to="/auth/login" />
            </MonitoredRoute>
            <MonitoredRoute path="/auth/login" component={LoginForm} exact />
            <MonitoredRoute
              path={['auth/initial-password', '/auth/change-password']}
              component={InitialPasswordForm}
            />
            <MonitoredRoute path="/auth/forgot-password" component={ForgotPasswordForm} />
            <MonitoredRoute path="/auth/reset-password" component={ResetPasswordForm} />
          </Switch>
        </ErrorBoundary>
        <Footer />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          background: url(${lightcastGradient}) no-repeat center center/cover;
          padding: 0 8rem 0 20rem;
          width: 50%;
          flex-grow: 1;
          @media (max-width: 1920px) {
            padding: 0 8rem 0 14rem;
          }
          @media (max-width: 1600px) {
            padding: 0 8rem;
          }
          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <h2
          css={css`
            flex-shrink: 0;
            font-size: 3.4rem;
            font-weight: 600;
            color: ${white};
            @media (max-width: 1400px) {
              font-size: 2.9rem;
            }
            @media (max-width: 1300px) {
              font-size: 2.4rem;
            }
          `}
        >
          <strong
            css={css`
              font-weight: bold;
            `}
          >
            Optimize your curriculum
          </strong>
          <br /> around in-demand skills.
        </h2>
        <LoginIllustration
          css={css`
            flex-shrink: 1;
            max-width: 55rem;
            @media (max-width: 1400px) {
              max-width: 45rem;
            }
          `}
        />
      </div>
    </div>
  );
};

export default Authorization;
