import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import InfoPill from 'components/atoms/InfoPill';
import CountBadge from 'components/atoms/CountBadge';

import { ReactComponent as SkillsIcon } from 'images/skillsIcon.svg';

import {
  CategorizedSkills,
  getCategorizedSkillCount,
  Subcategories
} from 'hooks/useCategorizedSkills';
import {
  darkerBorderGray,
  darkGray,
  darkSaphire,
  fog,
  lightGray,
  lightGreen,
  skyBlue,
  white
} from 'utils/colors';
import { useVisible } from 'hooks';
import mergeRefs from 'helpers/mergeRefs';

const SkillCategoryDistribution: React.FC<{
  categorizedSkills?: CategorizedSkills;
}> = ({ categorizedSkills }) => {
  const [isAllVisible, setIsAllVisible] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [topVisibleCategory, setTopVisibleCategory] = useState<string>();
  const scrollParentRef = useRef<HTMLDivElement>(null);
  const [visibleStack, setVisibleStack] = useState<string[]>([]);

  const categoryElementMap = new Map<string, HTMLDivElement | null>();

  const sortedCategorizedSkills = useMemo(() => {
    return Object.entries(categorizedSkills || {}).sort(
      ([, subcategoriesA], [, subcategoriesB]) => {
        const categoryASkills = Object.values(subcategoriesA).flat().length;
        const categoryBSkills = Object.values(subcategoriesB).flat().length;
        return categoryBSkills - categoryASkills;
      }
    );
  }, [categorizedSkills]);

  const handleScroll = () => {
    if (scrollParentRef.current && scrollParentRef.current.scrollTop === 0) {
      setIsAllVisible(true);
    } else {
      setIsAllVisible(false);
      setScrollHeight(() => scrollParentRef.current?.scrollTop || 0);
    }
  };

  useEffect(() => {
    setTopVisibleCategory(
      () => sortedCategorizedSkills.find(([category]) => visibleStack.includes(category))?.[0]
    );
  }, [scrollHeight, sortedCategorizedSkills, visibleStack]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  });

  return (
    <Flex>
      <ButtonContainer>
        <CategoryButton
          isActive={isAllVisible}
          onClick={() => {
            scrollParentRef.current?.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
          }}
        >
          All Skills
          <ColoredCountBadge>
            {sortedCategorizedSkills.reduce(
              (acc, [, subcategories]) => acc + Object.values(subcategories).flat().length,
              0
            )}
          </ColoredCountBadge>
        </CategoryButton>
        {sortedCategorizedSkills.map(([category, subcategories], i) => (
          <CategoryButton
            key={`${category}-button`}
            isActive={topVisibleCategory === category && !isAllVisible}
            onClick={() => {
              const categorySkillsRef = categoryElementMap.get(category);
              if (categorySkillsRef && scrollParentRef.current) {
                scrollParentRef.current.scrollTo({
                  behavior: 'smooth',
                  left: 0,
                  top: categorySkillsRef.offsetTop - scrollParentRef.current.offsetTop
                });
              }
            }}
          >
            {category}
            <ColoredCountBadge color="blue">
              {getCategorizedSkillCount(subcategories)}
            </ColoredCountBadge>
          </CategoryButton>
        ))}
      </ButtonContainer>
      <SkillsContainer ref={scrollParentRef}>
        <ExtraSpace />
        {sortedCategorizedSkills.map(([category, subcategories]) => (
          <SkilCategoryList
            refMap={categoryElementMap}
            key={`category-${category}`}
            category={category}
            subcategories={subcategories}
            scrollParentRef={scrollParentRef}
            onVisible={setVisibleStack}
          />
        ))}
      </SkillsContainer>
    </Flex>
  );
};

const SkilCategoryList: React.FC<{
  scrollParentRef: React.RefObject<HTMLElement>;
  category: string;
  subcategories: Subcategories;
  refMap: Map<string, HTMLElement | null>;
  onVisible: Dispatch<React.SetStateAction<string[]>>;
}> = ({ scrollParentRef, category, subcategories, onVisible, refMap }) => {
  const [isVisible, visibleRef] = useVisible(scrollParentRef);
  useEffect(() => {
    if (isVisible) {
      onVisible(stack => (!stack.includes(category) ? [...stack, category] : stack));
    } else {
      onVisible(stack => stack.filter(s => s !== category));
    }
  }, [isVisible, onVisible, category]);

  return (
    <div
      ref={mergeRefs(el => {
        refMap.set(category, el);
      }, visibleRef as React.LegacyRef<HTMLDivElement>)}
    >
      <InfoPill scheme="blue">{category}</InfoPill>
      <Indent>
        {Object.entries(subcategories).map(([subcategory, skills]) => (
          <div key={`subcategory-${subcategory}`}>
            <SkillHeader>{subcategory}</SkillHeader>
            <SkillList aria-label={`skills in ${subcategory} subcategory`}>
              {skills.map(skill => (
                <SkillChip
                  scheme="gray"
                  as="li"
                  key={`category-skill-${skill.id}`}
                  aria-label={skill.name}
                >
                  <StyledSkillIcon />
                  {skill.name}
                </SkillChip>
              ))}
            </SkillList>
          </div>
        ))}
      </Indent>
    </div>
  );
};

const SkillHeader = styled.span`
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const Indent = styled.div<{ color?: 'green' | 'blue' | 'gray' }>`
  border-left: 1px solid
    ${({ color }) => (color === 'green' ? lightGreen : color === 'blue' ? skyBlue : lightGray)};
  margin: 1.5rem 0 5rem 1.5rem;
  padding-left: 1.5rem;
`;

const SkillChip = styled(InfoPill)`
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  font-weight: normal;
  margin: 0 0.7rem 0.7rem 0;
  color: ${darkGray};
  font-size: 1.4rem;
`;

const StyledSkillIcon = styled(SkillsIcon)`
  margin-right: 0.5rem;
`;

const CategoryButton = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  width: 19.5rem;
  text-align: left;
  padding: 1.5rem;
  margin-bottom: 0.2rem;
  background: ${({ isActive }) => (isActive ? fog : white)};
  color: ${({ isActive }) => (isActive ? darkSaphire : 'inherit')};
  border: none;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: bold;
`;

const ColoredCountBadge = styled(CountBadge)<{ color?: 'green' | 'blue' | 'gray' }>`
  background: ${({ color }) =>
    color === 'green' ? lightGreen : color === 'blue' ? skyBlue : lightGray};
`;

const Flex = styled.div`
  display: flex;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: column;
  max-height: 40rem;
  overflow: auto;
  border-right: 1px solid ${darkerBorderGray};
  padding-right: 3.2rem;
`;

const SkillsContainer = styled.div`
  max-height: 40rem;
  overflow: auto;
  margin-left: 3.2rem;
  flex: 1;
`;

const SkillList = styled.ul`
  padding: 0;
  margin: 0;
`;

const ExtraSpace = styled.div`
  height: 0.1rem;
`;

export default SkillCategoryDistribution;
