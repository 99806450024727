import React from 'react';
import styled from '@emotion/styled';

const FlippableContent: React.FC<{
  front: (flipCard: Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
  back: (flipCard: Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
  className?: string;
}> = ({ front, back, className }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);

  return (
    <FlipCard className={className}>
      <FrontBackWrapper className={isFlipped ? 'flipped' : ''}>
        <Front isFlipped={isFlipped} tabIndex={0} aria-hidden={isFlipped}>
          {front(setIsFlipped)}
        </Front>
        <Back isFlipped={isFlipped} tabIndex={0} aria-hidden={!isFlipped}>
          {back(setIsFlipped)}
        </Back>
      </FrontBackWrapper>
    </FlipCard>
  );
};

export default FlippableContent;

const FlipCard = styled.div`
  background: transparent;
  perspective: 2000px;

  & > div.flipped {
    transform: rotateY(180deg);

    & > div:first-of-type {
      opacity: 0;
    }
  }

  & > div > div:first-of-type {
    transition: opacity 0.6s;
  }
`;

const FrontBackWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

const Front = styled.div<{ isFlipped: boolean }>`
  pointer-events: ${({ isFlipped }) => (isFlipped ? 'none' : 'auto')};
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
`;

const Back = styled.div<{ isFlipped: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: ${({ isFlipped }) => (isFlipped ? 'visible' : 'hidden')};
  backface-visibility: ${({ isFlipped }) => (isFlipped ? 'visible' : 'hidden')};
  background: white;
  transform: rotateY(180deg);
  pointer-events: ${({ isFlipped }) => (isFlipped ? 'auto' : 'none')};
`;
