import React from 'react';
import styled from '@emotion/styled';

import Collapsible, { CollapsibleProps, StyledCaret } from 'components/atoms/Collapsible';
import { ReactComponent as CaretIcon } from 'images/caret.svg';
import CategoryBarChartRow from 'components/molecules/CategoryBarChartRow';
import { bgTableBlue, black, mediumLightGray, white } from 'utils/colors';
import { EnhancedSkill, TaughtState } from 'hooks/programInsights';

interface BarChartCategoryCollapsibleProps
  extends Omit<CollapsibleProps, 'label' | 'headerContent'> {
  subcategories: Record<string, EnhancedSkill[]>;
  subcategoryJpaData?: JPAResponseBucket[];
  category: { name: string; postingCount: number };
  maxValue?: number;
  borderColor?: string;
  renderSkillContent: (skill: EnhancedSkill, i: number) => React.ReactNode;
  subcategoryPostingCounts: Record<string, number>;
}

const BarChartCategoryCollapsible: React.FC<BarChartCategoryCollapsibleProps> = ({
  buttonText,
  name,
  category,
  subcategories,
  borderColor,
  maxValue,
  renderSkillContent,
  className,
  subcategoryPostingCounts,
  ...props
}) => {
  const categoryIsTaughtOther = !!Object.values(subcategories)
    .flat()
    .find(skill => skill.taughtState === 'taught_other');

  const categoryIsTaught = Object.values(subcategories)
    .flat()
    .find(skill => skill.taughtState === 'taught');

  const taughtState: TaughtState = categoryIsTaught
    ? 'taught'
    : categoryIsTaughtOther
    ? 'taught_other'
    : 'sought';

  return (
    <StyledCollapsible
      {...props}
      name={name}
      className={className}
      defaultIsOpen={false}
      headerContent={(getButtonProps, isOpen) => (
        <>
          <CategoryChartRow
            className="category"
            count={category.postingCount}
            maxValue={maxValue}
            taughtState={taughtState}
            barBorderColor={borderColor}
            {...getButtonProps()}
          >
            <CategoryTextWrapper>
              <CategoryText>{buttonText}</CategoryText>
              <StyledCaret isOpen={isOpen}>
                <CaretIcon />
              </StyledCaret>
            </CategoryTextWrapper>
          </CategoryChartRow>
        </>
      )}
    >
      {name === 'Uncategorized'
        ? Object.values(subcategories).map(skills => {
            return skills.map((skill, i) => (
              <UncategorizedSkillContent key={skill.id}>
                {renderSkillContent(skill, i)}
              </UncategorizedSkillContent>
            ));
          })
        : Object.entries(subcategories)
            .sort?.(([a], [b]) => subcategoryPostingCounts[b] - subcategoryPostingCounts[a])
            .map(([subcategory, skills], i) => {
              const count = subcategoryPostingCounts[subcategory];

              const subcategoryIsTaughtOther = !!Object.values(skills)
                .flat()
                .find(skill => skill.taughtState === 'taught_other');

              const subcategoryIsTaught = Object.values(skills)
                .flat()
                .find(skill => skill.taughtState === 'taught');

              const subcategoryTaughtState: TaughtState = subcategoryIsTaught
                ? 'taught'
                : subcategoryIsTaughtOther
                ? 'taught_other'
                : 'sought';

              return (
                <StyledSubCollapsible
                  data-cy={`bar-chart-category-collapsible_subcategory_${i}-${subcategory}`}
                  name={subcategory}
                  key={subcategory}
                  defaultIsOpen={false}
                  headerContent={(getButtonProps, isOpen) => (
                    <>
                      <SubcategoryChartRow
                        count={count}
                        maxValue={maxValue}
                        taughtState={subcategoryTaughtState}
                        barBorderColor={borderColor}
                        {...getButtonProps()}
                      >
                        <CategoryTextWrapper>
                          <SubCategoryText>{subcategory}</SubCategoryText>
                          <StyledCaret isOpen={isOpen}>
                            <CaretIcon />
                          </StyledCaret>
                        </CategoryTextWrapper>
                      </SubcategoryChartRow>
                    </>
                  )}
                >
                  {skills.map((skill, j) => (
                    <SkillContent key={skill.id}>{renderSkillContent(skill, j)}</SkillContent>
                  ))}
                </StyledSubCollapsible>
              );
            })}
    </StyledCollapsible>
  );
};

const CategoryChartRow = styled(CategoryBarChartRow)`
  &:hover {
    color: unset;
  }
`;

const SubcategoryChartRow = styled(CategoryBarChartRow)`
  font-size: 1.4rem;
  background-color: transparent;
  &:hover {
    color: unset;
  }
`;

const StyledCollapsible = styled(Collapsible)`
  background: ${bgTableBlue};
  & > button {
    color: ${black};
  }

  .category > span:first-of-type {
    font-weight: bold;
  }
`;

const CategoryTextWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const SkillContent = styled.div`
  background-color: white;
  &:nth-of-type(odd) {
    background-color: ${bgTableBlue};
  }
`;

const UncategorizedSkillContent = styled.div`
  background-color: white;
  &:nth-of-type(even) {
    background-color: ${bgTableBlue};
  }
`;

const StyledSubCollapsible = styled(StyledCollapsible)`
  background: ${white};

  & > button {
    color: ${mediumLightGray};
  }
`;

const CategoryText = styled.span`
  flex: 1;
  font-size: 1.4rem;
`;

const SubCategoryText = styled(CategoryText)`
  font-weight: bold;
  color: ${mediumLightGray};
  padding-left: 1rem;
`;

export default BarChartCategoryCollapsible;
