import Loading from 'components/atoms/Loading';
import ChartTooltip, { ChartTooltipProps } from 'components/atoms/ChartTooltip';
import { chartStyles } from 'helpers/skillGapTrackerTable/chartTheme';
import { SkillAreaData, VictoryCoordinate } from 'helpers/skillGapTrackerTable/graphDataFormatter';
import React, { useEffect, useRef, useState } from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryPortal,
  VictoryScatter,
  VictoryStack,
  VictoryLabelProps,
  VictoryVoronoiContainer
} from 'victory';
import NothingPlaceholder from 'components/atoms/NothingPlaceholder';
import styled from '@emotion/styled';
import { useSkillTimeseriesTracker } from 'hooks/programInsights';
import DropdownFlyout, {
  DropdownFlyoutProps,
  DropdownSelectable
} from 'components/molecules/DropdownFlyout';
import TaughtSoughtLegend from 'components/atoms/TaughtSoughtLegend';
import VictoryInformativeLabel from 'components/molecules/VictoryInformativeLabel';
import ChartInfoPanel from 'components/atoms/ChartInfoPanel';
import MultiSelect from 'components/molecules/MultiSelect';
import {
  NUMBER_OF_SKILLS_OPTIONS,
  useProgramData,
  useProgramDataDispatch
} from 'store/programData';
import { truncateString } from 'utils/truncateString';

interface VictoryLabelPropsWithIndex extends VictoryLabelProps {
  index: number;
}
interface VictoryLabelPropsWithSkill extends VictoryLabelPropsWithIndex {
  labelColor: string;
  data: VictoryCoordinate[];
  textColor: string;
}

interface SkillGapTrackerTableProps {
  programId: string;
  jpaFilters: JPAOptionsFilter;
}

const SkillGapTrackerTable: React.FC<SkillGapTrackerTableProps> = ({ programId, jpaFilters }) => {
  const { gapTrackerSettings, chartSettings } = useProgramData();
  const programDataDispatch = useProgramDataDispatch();

  const [activeAreaState, setActiveAreaState] = useState<number | null>(null);
  const [chartTooltip, setChartTooltip] = useState<ChartTooltipProps>({
    isOpen: false,
    skillAreaData: undefined,
    position: { x: 0, y: 0 }
  });

  const { graphData, isLoading: isGraphDataLoading } = useSkillTimeseriesTracker({
    programId,
    jpaFilters,
    chartSettings,
    numberOfSkillsToShow: gapTrackerSettings.numberOfSkillsToShow.value,
    hypotheticalSkillIds: gapTrackerSettings.hypotheticalSkills.map(s => s.value),
    chartMetric: gapTrackerSettings.chartMetric
  });

  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const removeTooltipOnResize = () => {
      setChartTooltip({
        isOpen: false,
        skillAreaData: undefined,
        position: { x: 0, y: 0 }
      });
    };

    window.addEventListener('resize', removeTooltipOnResize);
    return () => {
      window.removeEventListener('resize', removeTooltipOnResize);
    };
  }, []);

  function getAreaStyle(areaVal: number) {
    return { data: { opacity: activeAreaState !== areaVal && activeAreaState !== null ? 0.5 : 1 } };
  }

  if (graphData && !isGraphDataLoading) {
    const {
      skillAreaData,
      programTaughtSoughtSkillsInfo,
      timeFrameCoordinateValues,
      numberOfTimeSeries,
      lastTaughtSkillIndex,
      totalTaughtSkillCoordinates
    } = graphData;

    const ScatterPlotSkillPercentageLabels = (props: VictoryLabelPropsWithSkill) =>
      graphData.quarterIntervals.includes(props.index + 1) ? (
        <VictoryLabel
          {...props}
          dy={props.data[props.index].y * 2 + 1}
          dx={props.index === numberOfTimeSeries - 1 ? -17 : -5}
          textAnchor="start"
          style={[{ ...chartStyles.skillScatterPercentageLabels.style, fill: props.textColor }]}
          backgroundPadding={[2, { left: 20, right: 20 }, { left: 20 }]}
          backgroundStyle={[{ fill: props.labelColor, opacity: '0.75' }]}
        />
      ) : (
        <React.Fragment key={props.index} />
      );

    const TotalTaughtSkillsPercentageLabels = (props: VictoryLabelPropsWithIndex) =>
      graphData.quarterIntervals.includes(props.index + 1) ? (
        <VictoryLabel
          {...props}
          dy={0}
          dx={props.index === numberOfTimeSeries - 1 ? -17 : -5}
          textAnchor="start"
          style={[chartStyles.totalTaughtSkillScatterPercentageLabels.style]}
          backgroundPadding={[2, { left: 20, right: 20 }, { left: 20 }]}
          backgroundStyle={[{ fill: 'white', opacity: '0.75' }]}
        />
      ) : (
        <React.Fragment key={props.index} />
      );

    return (
      <div>
        <TopContainer>
          <Column>
            <Row>
              <SkillsNumberDropdownFlyout
                items={NUMBER_OF_SKILLS_OPTIONS}
                dropDownName="trend-graph-number-of-skills"
                selected={gapTrackerSettings.numberOfSkillsToShow}
                onSelect={item =>
                  programDataDispatch({
                    type: 'SET_GAP_TRACKER_SETTINGS',
                    value: { ...gapTrackerSettings, numberOfSkillsToShow: item }
                  })
                }
                labelText="Skill View"
                data-cy={'skills-to-show-dropdown-flyout'}
              />
              <HypotheticalSelectContainer>
                <MultiSelect<DropdownSelectable<string>>
                  labelText="Hypothetical Skills"
                  inputPlaceholder={
                    gapTrackerSettings.hypotheticalSkills.length ? undefined : 'No Skills Selected'
                  }
                  dataCySelector="skill-trends-hypothetical-skills"
                  items={Object.values(
                    programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes
                  ).map(skill => ({
                    value: skill.id,
                    label: skill.name
                  }))}
                  itemToKey={skill => skill.value}
                  itemToString={skill => skill?.label || ''}
                  renderItems={skill => skill.label || ''}
                  renderSelectedItem={skill => skill.label || ''}
                  readOnly={
                    !Object.keys(programTaughtSoughtSkillsInfo.skillsNotTaughtInTargetOutcomes)
                      .length
                  }
                  keepOpenAfterSelection
                  useMultipleSelectionProps={{
                    selectedItems: gapTrackerSettings.hypotheticalSkills,
                    onSelectedItemsChange: ({ selectedItems: selectedSkills }) => {
                      programDataDispatch({
                        type: 'SET_GAP_TRACKER_SETTINGS',
                        value: { ...gapTrackerSettings, hypotheticalSkills: selectedSkills || [] }
                      });
                    }
                  }}
                  renderInformativeContent={() => (
                    <p>
                      Add skills not already taught in your program to see the impact that adding
                      them would have on your program&apos;s alignment to market demand.
                    </p>
                  )}
                />
              </HypotheticalSelectContainer>
            </Row>
            <StyledText>
              * indicates skills with the highest fluctuation in growth over time.
            </StyledText>
          </Column>
          <Row>
            <ChartInfoPanel totalTaughtSkillCoordinates={totalTaughtSkillCoordinates} />
            <LegendContainer>
              <TaughtSoughtLegend
                show={['sought', 'hypothetical', 'taught']}
                overrides={{
                  sought: { color: 'taught_other', label: 'Not taught in this program' }
                }}
              />
            </LegendContainer>
          </Row>
        </TopContainer>
        <ChartContainer>
          <VictoryChart
            containerComponent={<VictoryVoronoiContainer portalZIndex={0} />}
            scale={{ x: 'time', y: 'linear' }}
            maxDomain={{ y: 100 }}
            minDomain={{ y: 0 }}
            width={800}
            height={525}
            padding={{ bottom: 50, right: 100, left: 65, top: 10 }}
            data-cy={'skill-trends-chart'}
          >
            <VictoryAxis
              name="month-axis"
              label="Last 3 Years"
              axisLabelComponent={
                <VictoryInformativeLabel
                  width={'180'}
                  height={'100%'}
                  dy={-4}
                  shouldClosePopup={activeAreaState !== null}
                  informativeContent={
                    <p>
                      Information is based off of current curriculum content in Skillabi, and
                      reflects how those skills have changed in demand in the past.
                    </p>
                  }
                />
              }
              tickFormat={t => {
                const month = t.toLocaleDateString('en-us', {
                  month: 'short'
                });
                const year = t.toLocaleDateString('en-us', {
                  year: 'numeric'
                });
                return `${month}\n${year}`;
              }}
              style={chartStyles.monthAxis.style}
            />
            <VictoryAxis
              name="percentage-axis"
              label={'% of Total Skill Demand (of Skill Mentions)'}
              axisLabelComponent={
                <VictoryInformativeLabel
                  width={'60'}
                  height={'100%'}
                  dx={-35}
                  shouldClosePopup={activeAreaState !== null}
                  labelComponent={
                    <ChartLabel>
                      <b>% of Total Skill Demand</b> (of Skill Mentions)
                    </ChartLabel>
                  }
                  containerStyles={{ justifyContent: 'center', gap: 0 }}
                  informativeContent={
                    <div>
                      <p>
                        <b>Total Skill Demand</b> measures the number of job postings where each
                        in-demand skill appears, to show how much each skill accounts for overall
                        market demand.
                      </p>
                      <p>
                        The metric indicates the <b>value of any given skill over time</b>, as the
                        skill increases or decreases in demand.
                      </p>
                    </div>
                  }
                />
              }
              dependentAxis
              orientation="left"
              tickFormat={t => (t === 100 ? `${t}%` : '')}
              style={chartStyles.percentageAxis.style}
            />
            <VictoryStack name="skill-area-stack">
              {skillAreaData.map((skill, index) => (
                <VictoryGroup key={index} data={skill.data} color={skill.areaColor}>
                  <VictoryArea
                    name={`area-${index}`}
                    style={getAreaStyle(index)}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onMouseOver: () => {
                            setActiveAreaState(index);
                          },
                          onMouseOut: () => {
                            setActiveAreaState(null);
                          }
                        }
                      }
                    ]}
                  />
                  {activeAreaState === index && (
                    <VictoryPortal>
                      <VictoryScatter
                        name="scatter-plot-skill-percentages"
                        style={{ data: { fill: 'none' } }}
                        labels={({ datum }) => `${Math.round(datum.y)}%`}
                        labelComponent={
                          <ScatterPlotSkillPercentageLabels
                            labelColor={skill.areaColor}
                            data={skill.data}
                            textColor={skill.areaContrastColor}
                            index={index}
                          />
                        }
                      />
                    </VictoryPortal>
                  )}
                  {activeAreaState === null && index === lastTaughtSkillIndex && (
                    <VictoryPortal>
                      <VictoryScatter
                        data={totalTaughtSkillCoordinates}
                        name="scatter-plot-skill-percentages"
                        style={{ data: { fill: 'none' } }}
                        labels={({ datum }) => `${Math.round(datum.y)}%`}
                        labelComponent={<TotalTaughtSkillsPercentageLabels index={index} />}
                      />
                    </VictoryPortal>
                  )}
                </VictoryGroup>
              ))}
            </VictoryStack>
            <VictoryBar
              name="month-vertical-bar"
              data={timeFrameCoordinateValues}
              style={chartStyles.monthlyVerticalBars.style}
              barWidth={({ index }) => {
                return graphData.quarterIntervals.includes(Number(index) + 2) ? 2.5 : 1;
              }}
            />
            <VictoryAxis
              name="skill-name-axis"
              dependentAxis
              offsetX={715}
              tickLabelComponent={
                <SkillNameLabel
                  index={0}
                  skillAreaData={skillAreaData}
                  tooltipRef={tooltipRef}
                  setChartTooltip={setChartTooltip}
                  activeAreaState={activeAreaState}
                  setActiveAreaState={setActiveAreaState}
                />
              }
              tickValues={skillAreaData.map(skill => skill.labelHeight)}
              tickFormat={val => {
                const foundSkill = skillAreaData.find(skill => skill.labelHeight === val);
                if (foundSkill?.skillName) {
                  return foundSkill.isDeviationSkill === true
                    ? `${truncateString(foundSkill.skillName, 20)} *`
                    : truncateString(foundSkill.skillName, 24);
                }
              }}
              style={chartStyles.skillNameAxis.style}
            />
          </VictoryChart>
        </ChartContainer>
        <ChartTooltip ref={tooltipRef} {...chartTooltip} maxHeight={200} />
      </div>
    );
  } else if (isGraphDataLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  } else {
    return (
      <LoadingContainer>
        <NothingPlaceholder
          header={'No skills to show yet'}
          content={'Get started by selecting skills above.'}
        />
      </LoadingContainer>
    );
  }
};

const SkillNameLabel = ({
  skillAreaData,
  tooltipRef,
  setChartTooltip,
  activeAreaState,
  setActiveAreaState,
  ...victoryProps
}: VictoryLabelPropsWithIndex & {
  skillAreaData: SkillAreaData[];
  tooltipRef: React.RefObject<HTMLDivElement>;
  setChartTooltip: React.Dispatch<React.SetStateAction<ChartTooltipProps>>;
  activeAreaState: number | null;
  setActiveAreaState: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const isCondensedTaught = skillAreaData[victoryProps.index].condensedTaughtSkills;
  const isCondensedSought = skillAreaData[victoryProps.index].condensedSoughtSkills;
  const isCondensedHypotheticals = skillAreaData[victoryProps.index].hypotheticalSkills;

  const labelId = isCondensedSought
    ? `skill-trend-sought-label`
    : isCondensedTaught
    ? `skill-trend-taught-label`
    : isCondensedHypotheticals
    ? `skill-trend-hypothetical-label`
    : undefined;

  const calculateLabelPosition = () => {
    const labelElement = document.querySelector(`#${labelId}`);

    if (labelElement) {
      const rect = labelElement.getBoundingClientRect();
      return {
        x: rect.left + document.documentElement.scrollLeft,
        y: rect.top + document.documentElement.scrollTop
      };
    }

    return null;
  };

  const handleClickOutsideTooltip = (event: MouseEvent) => {
    const targetNode = event.target as Node;
    if (tooltipRef.current && !tooltipRef.current.contains(targetNode)) {
      setChartTooltip(prev => ({ ...prev, isOpen: false }));
      document.removeEventListener('mousedown', handleClickOutsideTooltip);
    }
  };

  const setTooltipVisibility = (isOpen: boolean) => {
    let position = null;
    if (labelId) {
      position = calculateLabelPosition();
    }

    if (
      (isCondensedTaught || isCondensedSought || isCondensedHypotheticals) &&
      victoryProps.x &&
      victoryProps.y
    ) {
      document.addEventListener('mousedown', handleClickOutsideTooltip);
      setChartTooltip({
        isOpen,
        skillAreaData: skillAreaData[victoryProps.index],
        position: {
          x: position?.x || 0,
          y: (position?.y || 0) - 110
        }
      });
    }
  };

  const activateArea = () => {
    setActiveAreaState(victoryProps.index);
  };

  const deactivateArea = () => {
    setActiveAreaState(null);
  };

  return (
    <VictoryLabel
      {...victoryProps}
      tabIndex={skillAreaData.length - victoryProps.index}
      id={labelId}
      style={[chartStyles.skillLabels.style]}
      textAnchor="start"
      backgroundPadding={{ left: 2, right: 5, top: 2, bottom: 2 }}
      backgroundStyle={[{ fill: skillAreaData[victoryProps.index].labelColor }]}
      events={{
        onFocus() {
          setTooltipVisibility(true);
          activateArea();
        },
        onBlur() {
          setTooltipVisibility(false);
          deactivateArea();
        },
        onMouseDown() {
          setTooltipVisibility(true);

          if (activeAreaState === victoryProps.index) {
            deactivateArea();
          } else {
            activateArea();
          }
        }
      }}
      data-cy={`skill-trends-chart_skill-label_${victoryProps.index}`}
    />
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

const LegendContainer = styled.div`
  border-radius: 0.8rem;
  padding: 1rem;
  box-shadow: var(--gray-box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HypotheticalSelectContainer = styled.div`
  max-width: 45rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const ChartContainer = styled.div`
  z-index: 0;
  flex: 1;
`;

const ChartLabel = styled.div`
  font-size: 0.9rem;
  text-align: center;
`;

const SkillsNumberDropdownFlyout = styled((props: DropdownFlyoutProps<number>) => (
  <DropdownFlyout {...props} />
))`
  &#trend-graph-number-of-skills-toggle-button {
    width: fit-content;
  }

  &#trend-graph-number-of-skills-toggle-button > span {
    overflow: visible;
    padding-right: 2rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.div`
  padding-top: 0.8rem;
  font-size: 1.2rem;
  font-style: italic;
`;

export default SkillGapTrackerTable;
