import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import MonitoredRoute from 'components/atoms/MonitoredRoute';
import Programs from 'components/pages/Programs';
import Courses from 'components/pages/Courses';
import ProgramTypes from 'components/pages/ProgramTypes';
import ParsePosting from 'components/pages/ParsePosting';

const Dashboard: React.FC = () => {
  return (
    <Switch>
      <MonitoredRoute path="/dashboard/" exact>
        <Redirect to="/dashboard/courses" />
      </MonitoredRoute>
      <MonitoredRoute path="/dashboard/courses" component={Courses} />
      <MonitoredRoute path="/dashboard/programs" component={Programs} />
      <MonitoredRoute path="/dashboard/program-types" component={ProgramTypes} />
      <MonitoredRoute path="/dashboard/posting" component={ParsePosting} />
    </Switch>
  );
};

export default Dashboard;
