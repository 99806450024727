import React from 'react';
import styled from '@emotion/styled';
import { lightGray } from 'utils/colors';
import { ReactComponent as CloseIcon } from 'images/closebtn.svg';

interface Props {
  onClick(): void;
  dataCy?: string;
  ariaLabel?: string;
  id?: string;
}

const InfoChip: React.FC<Props> = ({ children, onClick, dataCy, ariaLabel, id }) => (
  <Chip>
    <Content data-cy={'info-chip_content'}>{children}</Content>
    {id && <IdContent>({id})</IdContent>}
    <Grower />
    <CloseIconButton type="button" onClick={onClick} data-cy={dataCy} aria-label={ariaLabel}>
      <CloseIcon />
    </CloseIconButton>
  </Chip>
);

export default InfoChip;

const Chip = styled.div`
  border-radius: 0.2rem;
  display: flex;
  border: none;
  margin: 0;
  padding: 0;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  background-color: ${lightGray};
  font-size: 1.4rem;
  gap: 0.5rem;
  text-align: left;
`;

const Content = styled.div`
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Grower = styled.div`
  flex-grow: 1;
`;

const IdContent = styled.div`
  flex-shrink: 0;
`;

const CloseIconButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  transition: opacity 300ms;

  &:hover {
    opacity: 0.7;
  }
`;
