import React from 'react';
import styled from '@emotion/styled';

import Header from 'components/molecules/Header';
import Button from 'components/atoms/Button';
import ModalButton from 'components/molecules/ModalButton';
import ProgramTypeModal from 'components/organisms/ProgramTypeModal';
import ProgramTypesDashboardTable from 'components/organisms/ProgramTypesDashboardTable';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { ReactComponent as Tags } from 'images/tags.svg';

import { useProgramTypes } from 'hooks';

const StyledPlus = styled(Plus)`
  margin-right: 1rem;
`;

const StyledModalButton = Button.withComponent(ModalButton);

const AddProgramTypeModalButton = () => {
  return (
    <StyledModalButton
      type="button"
      buttonText={
        <>
          <StyledPlus />
          New Program Type
        </>
      }
    >
      {closeModal => <ProgramTypeModal closeModal={closeModal} />}
    </StyledModalButton>
  );
};

const ProgramTypes: React.FC = () => {
  const { data } = useProgramTypes({ limit: 0 });

  return (
    <>
      <Header headerText="Program Types" count={data && data.meta.totalAvailable} Icon={Tags}>
        <AddProgramTypeModalButton />
      </Header>
      <ProgramTypesDashboardTable trackPages />
    </>
  );
};

export default ProgramTypes;
