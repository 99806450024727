import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import Loading from 'components/atoms/Loading';
import InfoPill from 'components/atoms/InfoPill';
import Button from 'components/atoms/Button';
import ModalButton from 'components/molecules/ModalButton';
import AddCoursesWithSkillToProgramModal from 'components/molecules/AddCoursesWithSkillToProgramModal';

import { useCourses, usePrograms, useProgram } from 'hooks';
import { useJPAFilterQuery, useJPATotals } from 'hooks/jpaHooks';

import { ReactComponent as SkillInfo } from 'images/skillInfo.svg';
import { ReactComponent as CloseIcon } from 'images/closebtn.svg';
import { ReactComponent as PageArrow } from 'images/pageArrow.svg';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';

import { borderGray, fog, lightGray } from 'utils/colors';
import formatLargeNumber from 'utils/formatLargeNumber';
import { white, darkSaphire } from 'utils/colors';
import { hasSiteAccessLevel, useProfileState } from 'store/profileStore';
import { keepWithinRange } from 'utils/basic';
import { useRelatedSkills } from 'hooks/skills';

const ChartSkillSummary: React.FC<{
  skill: { id: string; name: string };
  onExit: () => void;
  allowAddSkill?: boolean;
}> = ({ skill, onExit, allowAddSkill }) => {
  const { id: programId } = useParams<{ id: string }>();
  const skillArray = skill ? [skill.id] : [];
  const { query } = useJPAFilterQuery();

  const { data: totalsData, isLoading: totalsDataIsLoading } = useJPATotals(
    { ...query, skills: skillArray },
    ['unique_postings', 'median_salary']
  );

  const { data: relatedSkills, isLoading: relatedSkillsIsLoading } = useRelatedSkills(skillArray);

  const { data: allCoursesWithSkill, isLoading: allCoursesWithSkillIsLoading } = useCourses({
    limit: 100,
    filter: { skills: { in: skillArray } }
  });

  const { data: coursesInProgramWithSkill, isLoading: coursesInProgramWithSkillIsLoading } =
    useCourses({
      limit: 100,
      filter: { skills: { in: skillArray }, associatedGroups: { in: [programId] } }
    });

  const coursesInOtherProgramsWithSkill = useMemo(() => {
    return allCoursesWithSkill?.data.filter(course => {
      return !coursesInProgramWithSkill?.data.find(c => c.id === course.id);
    });
  }, [allCoursesWithSkill]);

  const { data: allProgramsWithSkill, isLoading: allProgramsWithSkillIsLoading } = usePrograms({
    limit: 100,
    filter: { skills: { in: skillArray } }
  });

  const otherProgramsWithSkill = useMemo(() => {
    return allProgramsWithSkill?.data.filter(program => program.id !== programId) || [];
  }, [allProgramsWithSkill]);

  const { data: activeProgram } = useProgram(programId);
  const profileState = useProfileState();

  const isLoading =
    totalsDataIsLoading ||
    relatedSkillsIsLoading ||
    allCoursesWithSkillIsLoading ||
    coursesInProgramWithSkillIsLoading ||
    allProgramsWithSkillIsLoading;

  const programSite = activeProgram?.data.attributes.site;
  const hasEditorPrivileges = programSite
    ? hasSiteAccessLevel(programSite, 'editor', profileState)
    : false;

  return (
    <OuterWrapper>
      <ButtonWrapper>
        <CloseButton onClick={onExit} aria-label="back to chart">
          <CloseIcon />
        </CloseButton>
      </ButtonWrapper>
      <InnerWrapper>
        <SkillName>
          {skill?.name}
          <Link
            aria-label={`Link to emsi data ${skill?.name} page`}
            target="_blank"
            href={`https://skills.emsidata.com/skills/${skill?.id}`}
          >
            <SkillInfo />
          </Link>
        </SkillName>
        {isLoading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <>
            <PillBox>
              {totalsData && (
                <InfoPill scheme="blue">
                  {formatLargeNumber(totalsData.unique_postings)} Job Postings
                </InfoPill>
              )}
              {totalsData?.median_salary && (
                <InfoPill scheme="green">${formatLargeNumber(totalsData.median_salary)}</InfoPill>
              )}
            </PillBox>
            <SectionHeader>Related Skills</SectionHeader>
            <RelatedSkillBox>
              {relatedSkills?.map(relatedSkill => (
                <RelatedSkill key={relatedSkill.id}>{relatedSkill.name}</RelatedSkill>
              ))}
            </RelatedSkillBox>
            <SectionHeader>Taught In These Courses In This Program</SectionHeader>
            <CurriculumList count={coursesInProgramWithSkill?.data.length || 0}>
              {coursesInProgramWithSkill?.data.length ? (
                coursesInProgramWithSkill?.data.map(course => (
                  <CurriculumListItem key={course.id}>
                    <CurriculumListLink
                      href={
                        hasEditorPrivileges
                          ? `/edit/course/${course.id}`
                          : `/course/summary/${course.id}`
                      }
                      target="_blank"
                    >
                      {course.attributes.title}
                    </CurriculumListLink>
                    <CourseCode>{course.attributes.courseId}</CourseCode>
                  </CurriculumListItem>
                ))
              ) : (
                <li>
                  <i>Skill not taught in any courses in this program</i>
                </li>
              )}
            </CurriculumList>
            <SectionHeader>Taught In These Other Courses</SectionHeader>
            <CurriculumList count={coursesInOtherProgramsWithSkill?.length || 0}>
              {coursesInOtherProgramsWithSkill?.length ? (
                coursesInOtherProgramsWithSkill?.map(course => (
                  <CurriculumListItem key={course.id}>
                    <CurriculumListLink
                      href={
                        hasEditorPrivileges
                          ? `/edit/course/${course.id}`
                          : `/course/summary/${course.id}`
                      }
                      target="_blank"
                    >
                      {course.attributes.title}
                    </CurriculumListLink>
                    <CourseCode>{course.attributes.courseId}</CourseCode>
                  </CurriculumListItem>
                ))
              ) : (
                <li>
                  <i>Skill not taught in any other courses</i>
                </li>
              )}
            </CurriculumList>
            <SectionHeader>Taught in These Other Programs</SectionHeader>
            <CurriculumList count={otherProgramsWithSkill?.length || 0}>
              {otherProgramsWithSkill?.length ? (
                otherProgramsWithSkill.map(program => (
                  <CurriculumListItem key={program.id}>
                    <CurriculumListLink href={`/program/summary/${program.id}`} target="_blank">
                      {program.attributes.title}
                    </CurriculumListLink>
                  </CurriculumListItem>
                ))
              ) : (
                <li>
                  <i>Skill not taught in any other programs</i>
                </li>
              )}
            </CurriculumList>
          </>
        )}
      </InnerWrapper>
      <CardFooter>
        <BackToChartButton
          data-cy="chart-skill-summary_back-to-chart-button"
          onClick={onExit}
          type="button"
          scheme="outline"
        >
          <StyledArrow />
          Back to Chart
        </BackToChartButton>
        {allowAddSkill && (
          <StyledModalButton
            modalStyles={{ background: fog }}
            buttonText={
              <>
                <StyledPlus />
                Add Skill
              </>
            }
            type="button"
          >
            {closeModal => (
              <AddCoursesWithSkillToProgramModal
                program={{ id: programId, title: activeProgram?.data.attributes.title }}
                skillToAdd={skill}
                closeModal={closeModal}
                onSuccessfulSubmit={onExit}
              />
            )}
          </StyledModalButton>
        )}
      </CardFooter>
    </OuterWrapper>
  );
};

export default ChartSkillSummary;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 0;
  padding: 0 3rem;
  width: 100%;
`;

const SkillName = styled.span`
  align-self: start;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.3rem 0.6rem;
  background: #fffcbf;
  margin-bottom: 0.5rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Link = styled.a`
  margin-left: 1rem;
  height: 1.6rem;
  display: inline-block;
  margin: 0.15rem 0 0 1rem;
`;

const CardFooter = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  border-top: 2px solid ${lightGray};
`;

const ButtonWrapper = styled.div`
  padding-right: 1rem;
  padding-top: 1rem;
  align-self: flex-end;
  margin-bottom: -0.6rem;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  width: fit-content;

  cursor: pointer;
  padding: 0.6rem;
`;

const StyledArrow = styled(PageArrow)`
  transform: scaleX(-1);
  margin-right: 1rem;
`;

const BackToChartButton = styled(Button)`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  border: none;
`;

const PillBox = styled.div`
  min-height: 1.7rem;
  margin-bottom: 1.7rem;
  gap: 0.5rem;
  display: flex;
`;

const SectionHeader = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
`;

const RelatedSkillBox = styled.div`
  height: 5.5rem;
  overflow: hidden;
  margin-bottom: 1.4rem;
`;

const RelatedSkill = styled.span`
  background: ${lightGray};
  border-radius: 2px;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  padding: 0.2rem 0.8rem;
  display: inline-block;
`;

const CurriculumList = styled.div<{ count: number }>`
  flex: 2.9rem ${({ count }) => keepWithinRange(1, 10, count)};
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0.8rem 0 1.4rem;
  font-size: 1.2rem;

  li {
    padding-top: 0.5rem;
    padding-bottom: 0.8rem;
  }
`;

const CurriculumListLink = styled.a`
  color: ${darkSaphire};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CurriculumListItem = styled.li`
  display: flex;

  border-bottom: 1px solid ${borderGray};
  justify-content: space-between;
`;

const CourseCode = styled.span`
  font-size: 1rem;
`;

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
`;

const StyledModalButton = styled(ModalButton)`
  background: ${darkSaphire};
  color: ${white};
  border: none;
  border-radius: 4px;
  height: 4rem;
  padding: 1rem 1.6rem;
  align-items: center;
  font-size: 1.4rem;
  display: inline-flex;
  cursor: pointer;
`;
