export const getHighestAccessLevel = (accessLevels: AccessLevels): Role =>
  Object.values(accessLevels).reduce<Role>((highestLevel, currentAccessLevel) => {
    if (highestLevel === 'admin' || currentAccessLevel === 'admin') {
      return 'admin';
    } else if (highestLevel === 'editor' || currentAccessLevel === 'editor') {
      return 'editor';
    } else if (highestLevel === 'viewer' || currentAccessLevel === 'viewer') {
      return 'viewer';
    } else {
      return 'consumer';
    }
  }, 'consumer');
