export const getFacetType = (facetToFind: string, nation: JPANation) => {
  if (facetToFind === 'skills') {
    return 'Skills';
  }
  return BENCHMARK_FACET_FIELDS[nation].find(({ facet }) => facet === facetToFind)?.label;
};

interface BenchmarkFacets {
  facet: string;
  label: string;
  placeholder: string;
}

export const BENCHMARK_FACET_FIELDS: Record<JPANation, BenchmarkFacets[]> = {
  us: [
    {
      facet: 'title',
      label: 'Job Titles',
      placeholder: 'Search for a job title...'
    },
    {
      facet: 'company',
      label: 'Companies',
      placeholder: 'Search for a company...'
    },
    {
      facet: 'soc5',
      label: 'Occupations',
      placeholder: 'Search for an occupation...'
    },
    {
      facet: 'naics6',
      label: 'Industries',
      placeholder: 'Search for an industry...'
    }
  ],
  ca: [
    {
      facet: 'title',
      label: 'Job Titles',
      placeholder: 'Search for a job title...'
    },
    {
      facet: 'company',
      label: 'Companies',
      placeholder: 'Search for a company...'
    },
    {
      facet: 'noc4',
      label: 'Occupations',
      placeholder: 'Search for an occupation...'
    },
    {
      facet: 'naics6',
      label: 'Industries',
      placeholder: 'Search for an industry...'
    }
  ],
  uk: [
    {
      facet: 'title',
      label: 'Job Titles',
      placeholder: 'Search for a job title...'
    },
    {
      facet: 'company',
      label: 'Companies',
      placeholder: 'Search for a company...'
    },
    {
      facet: 'soc4',
      label: 'Occupations',
      placeholder: 'Search for an occupation...'
    },
    {
      facet: 'nuts3',
      label: 'Industries',
      placeholder: 'Search for an industry...'
    }
  ]
};

export const LOT_LABELS: Record<LOTLevel, string> = {
  career_area: 'Career Area',
  occupation_group: 'Occupation Group',
  occupation: 'Occupation',
  specialized_occupation: 'Specialized Occupation'
};

export interface CountSkillsParams {
  courses: CurricularData<Course>['data'];
  program: SingleCurriculum<CurricularUnitResponse>['data'];
  learningOutcomesOnly: boolean;
}

export function countSkills(params: CountSkillsParams): Record<string, number> {
  const skillCounts: Record<string, number> = {};

  params.courses.forEach(course => {
    (course.attributes.skills || []).forEach(skill => {
      if (params.learningOutcomesOnly && !skill.isLearningObjective) {
        return;
      }

      if (skillCounts[skill.id]) {
        skillCounts[skill.id]++;
      } else {
        skillCounts[skill.id] = 1;
      }
    });
  });

  params.program.attributes.skills.forEach(skill => {
    if (skillCounts[skill.id] || (params.learningOutcomesOnly && !skill.isLearningObjective)) {
      return;
    }

    if (skillCounts[skill.id]) {
      skillCounts[skill.id]++;
    } else {
      skillCounts[skill.id] = 1;
    }
  });

  return skillCounts;
}

export interface GetProgramRequiredSkillsParams {
  courses: CurricularData<Course>['data'];
  program: SingleCurriculum<CurricularUnitResponse>['data'];
  learningOutcomesOnly: boolean;
}

export function getProgramRequiredSkills(params: GetProgramRequiredSkillsParams) {
  return [
    ...new Set(
      params.courses
        .filter(course => {
          const courseOnProgram = params.program.attributes.children.find(({ id }) => {
            return id === course.id;
          });

          if (!courseOnProgram) {
            throw new Error(`Course ${course.id} doesn't exist on program, but we expected it to`);
          }

          return courseOnProgram.attributes.isRequired;
        })
        .flatMap(course => {
          const filteredSkills = course.attributes.skills?.filter(skill => {
            if (params.learningOutcomesOnly && skill.isLearningObjective) {
              return false;
            }

            return true;
          });

          if (filteredSkills) {
            return filteredSkills;
          }

          return [];
        })
    )
  ];
}

export interface GetProgramSkillsParams {
  courses: CurricularData<Course>['data'];
  program: SingleCurriculum<CurricularUnitResponse>['data'];
  learningOutcomesOnly: boolean;
}

export function getProgramSkills(params: GetProgramSkillsParams) {
  const uniqueSkills: CurricularSkill[] = [];

  for (const course of params.courses) {
    if (!course.attributes.skills) {
      continue;
    }

    for (const skill of course.attributes.skills) {
      if (params.learningOutcomesOnly && !skill.isLearningObjective) {
        continue;
      }

      uniqueSkills.push(skill);
    }
  }

  for (const programSkills of params.program.attributes.skills) {
    const duplicateSkill = uniqueSkills.find(s => s.id === programSkills.id);
    if (duplicateSkill && programSkills.isLearningObjective) {
      duplicateSkill.isLearningObjective = true;
    } else if (
      !duplicateSkill &&
      (!params.learningOutcomesOnly || programSkills.isLearningObjective)
    ) {
      uniqueSkills.push(programSkills);
    }
  }

  return uniqueSkills;
}
export interface GetProgramSkillInfo {
  courses: CurricularData<Course>['data'];
  program: SingleCurriculum<CurricularUnitResponse>['data'];
  learningOutcomesOnly: boolean;
}

export function getProgramSkillInfo(params: GetProgramSkillInfo) {
  return {
    skillFrequency: countSkills({
      courses: params.courses,
      program: params.program,
      learningOutcomesOnly: params.learningOutcomesOnly
    }),
    skillsInProgram: getProgramSkills({
      courses: params.courses,
      program: params.program,
      learningOutcomesOnly: params.learningOutcomesOnly
    }),
    requiredSkillsInProgram: getProgramRequiredSkills({
      courses: params.courses,
      program: params.program,
      learningOutcomesOnly: params.learningOutcomesOnly
    })
  };
}

export function convertCurricularUnitResponseIdsToMongo(
  curricularUnit: SingleCurriculum<CurricularUnitResponse>
): SingleCurriculum<CurricularUnitResponse> {
  const unpadId = (id: string): string => {
    const trimmedId = id.replace(/-/g, '');
    if (trimmedId.length === 32) {
      return trimmedId.slice(8);
    } else {
      return id;
    }
  };

  curricularUnit.data.id = unpadId(curricularUnit.data.id);
  curricularUnit.data.attributes.children = curricularUnit.data.attributes.children.map(child => ({
    ...child,
    id: unpadId(child.id)
  }));
  curricularUnit.data.attributes.benchmarks = curricularUnit.data.attributes.benchmarks.map(
    benchmark => ({ ...benchmark, id: unpadId(benchmark.id) })
  );
  curricularUnit.data.attributes.lotBenchmarks = curricularUnit.data.attributes.lotBenchmarks.map(
    benchmark => ({ ...benchmark, id: unpadId(benchmark.id) })
  );
  if (curricularUnit.data.attributes.unitType) {
    curricularUnit.data.attributes.unitType.id = unpadId(
      curricularUnit.data.attributes.unitType.id
    );
  }

  return curricularUnit;
}
