import { fetchWithCognitoToken } from './cognito';

const domain = process.env.REACT_APP_PROXY_URL;

export interface ProjectedSkillGrowthResponse {
  dimension: 'skill';
  id: string;
  name: string;
  growthCategory: string;
  growthPercent: {
    oneYear: number | null;
    twoYear: number | null;
    threeYear: number | null;
    fiveYear: number | null;
  };
}

// Reference to the projected skill growth endpoint
// https://api.lightcast.io/apis/projected-skill-growth
export const fetchProjectedSkillGrowth = async (
  skillIds: string[]
): Promise<ProjectedSkillGrowthResponse[]> => {
  return await fetchWithCognitoToken(
    `${domain}/emsi-services/projected-skill-growth/dimensions/skill/bulk`,
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ ids: skillIds })
    }
  ).then(async res => {
    const json = await res.json();
    if (!res.ok) {
      throw new Error(
        `Failed to get salary boosting skills: ${json.message || json.errors?.[0]?.detail}`
      );
    }

    return json.data;
  });
};
