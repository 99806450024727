import { Crumb } from './breadcrumbs';
export const routeTree: Node[] = [
  {
    path: 'dashboard',
    children: [
      {
        path: 'courses',
        crumbs: [{ name: 'Courses Dashboard', href: '/dashboard/courses' }]
      },
      {
        path: 'programs',
        crumbs: [{ name: 'Programs Dashboard', href: '/dashboard/programs' }]
      },
      {
        path: 'program-types',
        crumbs: [{ name: 'Program Types Dashboard', href: '/dashboard/program-types' }]
      },
      {
        path: 'posting',
        crumbs: [{ name: 'Start With a Job Posting', href: '/dashboard/posting' }]
      }
    ]
  },
  {
    path: 'edit',
    children: [
      {
        path: 'course',
        crumbs: [
          { name: 'Courses Dashboard', href: '/dashboard/courses' },
          { name: 'Edit Course', href: null }
        ],
        children: [
          {
            path: ':id',
            crumbs: [{ name: 'cid:', href: '/edit/course/:id' }]
          }
        ]
      },
      {
        path: 'program',
        crumbs: [
          { name: 'Programs Dashboard', href: '/dashboard/programs' },
          { name: 'Edit Program', href: null }
        ],
        children: [
          {
            path: ':id',
            crumbs: [{ name: 'pid:', href: '/edit/program/id:' }]
          }
        ]
      }
    ]
  },
  {
    path: 'program',
    crumbs: [{ name: 'Programs Dashboard', href: '/dashboard/programs' }],
    children: [
      {
        path: 'summary',
        children: [
          {
            path: ':id',
            crumbs: [{ name: 'pid:', href: '/program/summary/:id' }]
          }
        ]
      },
      {
        path: 'insights',
        children: [
          {
            path: ':id',
            crumbs: [
              { name: 'pid:', href: '/program/summary/:id' },
              { name: 'Market Alignment', href: '/program/insights/:id' }
            ],
            children: [
              {
                path: 'benchmarks',
                crumbs: [
                  {
                    name: 'Benchmarks',
                    href: '/program/insights/63d2a94d0001a7aba6cc36cc/benchmarks/'
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'course',
    children: [
      {
        path: 'summary',
        crumbs: [
          { name: 'Courses Dashboard', href: '/dashboard/courses' },
          { name: 'View Course', href: null }
        ],
        children: [
          {
            path: ':id',
            crumbs: [{ name: 'cid:', href: '/course/summary/:id' }]
          }
        ]
      }
    ]
  },
  {
    path: 'admin',
    crumbs: [{ name: 'Admin', href: '/admin' }],
    children: [
      {
        path: 'users',
        crumbs: [{ name: 'Users', href: '/admin/users' }]
      },
      {
        path: 'sites',
        crumbs: [{ name: 'Sites', href: '/admin/sites' }]
      }
    ]
  }
];
export type Node = {
  path: string;
  crumbs?: Crumb[];
  children?: Node[];
};
