import React from 'react';
import styled from '@emotion/styled';

import { lightGray, mediumGray } from 'utils/colors';

const NumericalBadge: React.FC<{ number: number }> = ({ number }) => (
  <Container>{number}</Container>
);

const Container = styled.div`
  padding: 0 1rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  color: ${mediumGray};
  background: ${lightGray};
`;

export default NumericalBadge;
