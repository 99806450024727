import React from 'react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { bgLightGray, darkGray, lightGray } from 'utils/colors';
import TabsWrapper, { TabsWrapperProps } from 'components/molecules/TabBar';
import DetailedBenchmarkCard, {
  DetailedBenchmarkCardProps
} from 'components/molecules/DetailedBenchmarkCard';
import Loading from 'components/atoms/Loading';

interface BenchmarksBrowserProps {
  benchmarks: DetailedBenchmarkCardProps[];
  noBenchmarksView?: ReactNode;
  isLoading?: boolean;
  prepend?: ReactNode;
  append?: ReactNode;
  tabsConfig?: TabsWrapperProps;
  threeColumnsBreakpoint?: number;
}

const BenchmarksBrowser: React.FC<BenchmarksBrowserProps> = ({
  benchmarks,
  threeColumnsBreakpoint,
  noBenchmarksView,
  isLoading,
  prepend,
  append,
  tabsConfig
}) => {
  const hasBenchmarks = !!benchmarks.length;

  const internals = (
    <Panel sharpenLeading={!!tabsConfig}>
      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          {prepend && <div>{prepend}</div>}
          {hasBenchmarks ? (
            <PanelGrid breakAt={threeColumnsBreakpoint || 1280}>
              {benchmarks.map(benchmark => (
                <BenchmarkCardContainer key={benchmark.id}>
                  <DetailedBenchmarkCard {...benchmark} />
                </BenchmarkCardContainer>
              ))}
            </PanelGrid>
          ) : noBenchmarksView ? (
            noBenchmarksView
          ) : (
            <NoBenchmarksNotice>No Benchmarks</NoBenchmarksNotice>
          )}
          {append && <div>{append}</div>}
        </>
      )}
    </Panel>
  );

  return (
    <PanelContainer>
      {tabsConfig ? <TabsWrapper {...tabsConfig}>{internals}</TabsWrapper> : internals}
    </PanelContainer>
  );
};

export default BenchmarksBrowser;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div<{ sharpenLeading?: boolean }>`
  height: 60rem;
  background: ${bgLightGray};
  padding: 2rem;
  border: 0.25rem solid ${lightGray};
  overflow: auto;
  border-radius: ${({ sharpenLeading }) => (sharpenLeading ? '0' : '0.5rem')} 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const PanelGrid = styled.div<{ breakAt: number }>`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  width: 52rem;

  @media screen and (min-width: ${({ breakAt }) => breakAt}px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 79rem;
  }
`;

const BenchmarkCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NoBenchmarksNotice = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${darkGray};
`;
