import React, { useEffect, useState } from 'react';

import { createPdf } from 'helpers/pdfExport/render';

export interface UsePdfExportOptions<HookParamsType, HookResultType> extends HookOptions {
  params: HookParamsType;
  filename: string;
  component: React.FC<{ data: HookResultType }>;
  hook(params: HookParamsType): {
    data: HookResultType | undefined;
    isLoading: boolean;
    error: unknown | null | undefined;
  };
}

export function usePdfExport<HookParamsType extends HookOptions, HookResultType>(
  options: UsePdfExportOptions<HookParamsType, HookResultType>
) {
  const { data: pdfData, isLoading: pdfDataIsLoading } = options.hook({
    ...options.params,
    enabled: options.enabled
  });

  const [isLoading, setIsLoading] = useState(true);

  const build = () => {
    if (!pdfData) {
      throw new Error('Pdf data finished loading, but no data was given');
    }

    const Component = options.component;
    return <Component data={pdfData} />;
  };

  const node = pdfDataIsLoading ? <></> : build();

  useEffect(() => {
    if (pdfDataIsLoading || options.enabled === false) {
      return;
    }

    let didInvalidate = false;

    createPdf(node, options.filename).then(() => {
      if (didInvalidate) {
        return;
      }

      setIsLoading(false);
    });

    return () => {
      didInvalidate = true;
    };
  }, [pdfDataIsLoading, options.enabled]);

  return { isLoading };
}
