import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { navyBlue, lightGray, darkBlue } from 'utils/colors';
import { useProgramData } from './data';
import { Header, SubHeader } from '../components';
import { Footer } from '../components/Footer';
import { ContainerPage } from '../components/Page';

export const ProgramContentsPage: React.FC = () => {
  const { cipCodeLabel, programCourses, groupTypeName, programSummary, programLearningOutcomes } =
    useProgramData();

  const title = groupTypeName
    ? groupTypeName.concat(cipCodeLabel ? ` - ${cipCodeLabel}` : '')
    : cipCodeLabel ?? '';

  return (
    <ContainerPage>
      <View>
        <Header text={`Program Content: ${title}`} id="program_content" />
        <Text style={styles.subTitle}>{title}</Text>
        <Text style={styles.descriptionText}>
          This section outlines details of the program, including the associated courses and full
          list of skills identified for the program. Additionally, insights are provided for the
          program skills about how frequently they appear across associated courses, and which skill
          categories they fall under.
        </Text>
      </View>
      <View>
        <SubHeader text="Program Summary and Courses" id="program_summary_and_courses" />
        <Text style={styles.descriptionHeader}>Summary</Text>
        <Text style={styles.descriptionText}>{programSummary || 'No Summary'}</Text>
        <Text style={styles.descriptionHeader}>Learning Outcomes</Text>
        <Text style={styles.descriptionText}>
          {programLearningOutcomes || 'No Learning Outcomes'}
        </Text>
      </View>
      <Text
        style={styles.restHeader}
        render={({ subPageNumber }) => {
          return subPageNumber === 1 ? '' : 'Program Courses (Cont.)';
        }}
        fixed
      />
      <View style={styles.table}>
        <View style={styles.tableHeader} fixed>
          <Text style={[styles.tableHeaderText, styles.courseTitleHeader]}>Course Title</Text>
          <Text style={[styles.tableHeaderText, styles.courseCodeHeader]}>Code</Text>
          <Text style={[styles.tableHeaderText, styles.skillsHeader]}>Skills</Text>
        </View>
        {programCourses.data.length ? (
          programCourses.data.map((course, index) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                index < programCourses?.data.length - 1 ? styles.rowDivider : {}
              ]}
            >
              <Text style={[styles.tableCell, styles.courseTitleCell]}>
                {course.attributes.title}
              </Text>
              <Text style={[styles.tableCell, styles.courseCodeCell]}>
                {course.attributes.courseId ? course.attributes.courseId : 'N/A'}
              </Text>
              <Text style={[styles.tableCell, styles.skillsCell]}>
                {course.attributes.skills ? course.attributes.skills.length + 1 : 0}
              </Text>
            </View>
          ))
        ) : (
          <Text style={styles.tableNoDataText}>No Courses in Program</Text>
        )}
      </View>
      <Footer />
    </ContainerPage>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: '0.25in',
    paddingBottom: '0.5in',
    paddingLeft: '0in',
    paddingRight: '0in'
  },
  pageTitle: {
    fontFamily: 'Inter',
    fontSize: 26,
    fontWeight: 'bold'
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: '0.1in',
    marginBottom: '0.1in'
  },
  descriptionHeader: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: '0.25in',
    marginBottom: '0.2in'
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: 12,
    marginBottom: '0.25in'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderColor: lightGray
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: darkBlue,
    padding: 6
  },
  tableHeaderText: { fontFamily: 'Inter', fontSize: 12, fontWeight: 'bold', color: 'white' },
  tableNoDataText: {
    fontFamily: 'Inter',
    fontSize: 12,
    marginTop: '0.2in',
    marginBottom: '0.2in',
    textAlign: 'center'
  },
  rowDivider: {
    borderBottomWidth: 1,
    borderBottomColor: lightGray
  },
  courseTitleHeader: {
    width: '66%'
  },
  courseCodeHeader: {
    width: '17%'
  },
  skillsHeader: {
    textAlign: 'center',
    width: '17%'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 6,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: lightGray
  },
  tableCell: {
    fontSize: 10
  },
  courseTitleCell: {
    width: '66%'
  },
  courseCodeCell: {
    width: '17%'
  },
  skillsCell: {
    textAlign: 'center',
    width: '17%'
  },
  restHeader: {
    marginBottom: '0.4in',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'bold',
    color: navyBlue
  }
});
