import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const { REACT_APP_AWS_COGNITO_USER_POOL_ID, REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID } =
  process.env;

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
  }
});

export const getCurrentToken = async (): Promise<string | void> => {
  try {
    return await Auth.currentSession().then(res => res.getIdToken().getJwtToken());
  } catch (err) {
    window.location.href = `${window.location.protocol}//${window.location.host}`;
  }
};

export const decodeCurrentToken = async (): Promise<DecodedTokenClaims | void> => {
  let session: CognitoUserSession, token: string;
  try {
    session = await Auth.currentSession();
    token = session.getIdToken().getJwtToken();
  } catch (e) {
    throw new Error('No current logged in user.');
  }
  const [, payload] = token.split('.');
  const decoded = JSON.parse(atob(payload));
  return {
    email: decoded.email,
    authSites: decoded['custom:skillabi:admin']?.split(',') || [],
    editorSites: decoded['custom:skillabi:editor']?.split(',') || [],
    viewerSites: decoded['custom:skillabi:viewer']?.split(',') || [],
    consumerSites: decoded['custom:skillabi:consumer']?.split(',') || []
  };
};

export const login = (username: string, password: string): Promise<DecodedTokenClaims | void> =>
  Auth.signIn(username, password);

export const logout = async (): Promise<void> => {
  await Auth.signOut();
  // This function triggers a redirect and also clears any app state as well as our caches.
  window.location.href = `${window.location.protocol}//${window.location.host}`;
};

export const submitInitialPassword = async (
  oldPassword: string,
  newPassword: string,
  username: string
): Promise<boolean> => {
  let currentUser;
  try {
    currentUser = await Auth.currentAuthenticatedUser();
  } catch (e) {
    currentUser = await login(username, oldPassword);
  }
  const isSuccess = await Auth.completeNewPassword(currentUser, newPassword);
  return isSuccess === 'SUCCESS';
};

export const submitPasswordReset = (email: string, code: string, newPassword: string) =>
  Auth.forgotPasswordSubmit(email, code, newPassword).catch(err => {
    throw new Error(err.message);
  });

export const forgotPassword = (username: string): Promise<void> =>
  Auth.forgotPassword(username).catch(err => {
    throw new Error(err.message);
  });

export const fetchWithCognitoToken = async (url: string, options?: RequestInit) => {
  const token = await getCurrentToken();
  return fetch(url, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${token}`
    }
  });
};
