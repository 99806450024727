import React, { useEffect, useState } from 'react';
import MultiSelect, { MultiSelectProps } from 'components/molecules/MultiSelect';

interface MultiselectSearchProps<TResponse> extends Partial<MultiSelectProps<TResponse>> {
  label: string;
  placeholder: string;
  search: (text: string, ...args: never[]) => Promise<TResponse[]>;
  renderItems: (item: TResponse) => string | JSX.Element;
  renderSelectedItem: (item: TResponse) => React.ReactNode;
}

function MultiselectSearch<TResponse extends { id: string; name: string }>({
  label,
  placeholder,
  search,
  renderItems,
  renderSelectedItem,
  ...otherProps
}: MultiselectSearchProps<TResponse>): React.ReactElement {
  const [searchString, setSearchString] = useState('');
  const [foundItems, setFoundItems] = useState<TResponse[]>([]);

  useEffect(() => {
    if (searchString.length > 1) {
      search(searchString).then(setFoundItems);
    }
  }, [searchString]);

  return (
    <MultiSelect
      labelText={label}
      inputPlaceholder={placeholder}
      renderItems={renderItems}
      renderSelectedItem={renderSelectedItem}
      items={foundItems}
      itemToKey={item => item.id}
      itemToString={item => item?.name || ''}
      onInputChange={setSearchString}
      {...otherProps}
    />
  );
}

export default MultiselectSearch;
