import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useController } from 'react-hook-form';
import styled from '@emotion/styled';

import Button from 'components/atoms/Button';
import FormInput from 'components/molecules/FormInput';
import LoadingButton from 'components/atoms/LoadingButton';
import MultiSelect from 'components/molecules/MultiSelect';

import { useCreateSite, useUpdateSite } from 'hooks/mutateSite';
import RegionPicker from 'components/molecules/RegionPicker';
import { DEFAULT_REGION_OBJECT } from 'components/organisms/SetupLocalStorage';
import { ahoy, bgLightGray, borderGray, fog, mediumGray } from 'utils/colors';
import { getSite } from 'services/sites';
import { getToastFnThatWeTreatLikeHook } from 'hooks';

export interface SiteFormInputs {
  siteName: string;
  siteKey: string;
  salesforceId: string;
  nations: Nation[];
  regions: DefaultRegion;
}

export interface Nation {
  label: string;
  value: JPANation;
}

const defaultNation: Nation[] = [{ value: 'us', label: 'United States' }];
const selectableNations: Nation[] = [
  { value: 'us', label: 'United States' },
  { value: 'uk', label: 'United Kingdom' },
  { value: 'ca', label: 'Canada' }
];

interface SiteFormProps {
  closeModal: () => void;
  submitButtonText?: string;
  formTitleText?: string;
  defaultValues?: SiteFormInputs;
}

const SiteForm: React.FC<SiteFormProps> = ({
  closeModal,
  formTitleText,
  defaultValues,
  submitButtonText = defaultValues ? 'Save' : 'Create New Site'
}) => {
  const notification = getToastFnThatWeTreatLikeHook();
  const [nationInput, setNationInput] = useState<Nation[]>(
    defaultValues ? defaultValues.nations : defaultNation
  );

  const {
    mutate: createSite,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess
  } = useCreateSite();

  const {
    mutate: updateSite,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess
  } = useUpdateSite();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<SiteFormInputs>({});

  const {
    field: nations,
    fieldState: { error: nationsError }
  } = useController({
    defaultValue: nationInput,
    name: 'nations',
    control,
    rules: { required: { value: true, message: 'At least one nation is required' } }
  });

  const {
    field: regions,
    fieldState: { error: regionsError }
  } = useController({
    name: 'regions',
    control,
    rules: { required: { value: true, message: 'At least one region is required' } }
  });

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      closeModal();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  const onSubmit: SubmitHandler<SiteFormInputs> = async formInputs => {
    const convertedRegions = nationInput.reduce<DefaultRegionConverted>(
      (formattedRegions, currentNation) => {
        const currentRegionInput = formInputs.regions[currentNation.value];
        if (currentRegionInput) {
          formattedRegions[currentNation.value] = {
            filterType: currentRegionInput.filterType,
            regions: currentRegionInput.regions.map(region => ({
              name: region.name,
              id: region.id
            }))
          };
        }
        return formattedRegions;
      },
      {}
    );

    if (defaultValues) {
      updateSite({
        name: formInputs.siteName,
        subdomain: formInputs.siteKey,
        nations: formInputs.nations.map(nation => nation?.value),
        defaultRegion: convertedRegions,
        salesforceId: formInputs.salesforceId
      });
    } else {
      let existingSite: SiteAttributes | undefined;
      try {
        existingSite = await getSite(formInputs.siteKey);
      } catch (err) {
        // Do nothing - If an error arrises from the get site, it's likely a 404.
      }

      if (existingSite) {
        if (existingSite.deletedAt) {
          notification(
            'A site with this key already exists, but has been disabled. Please contact the Skillabi Product Manager to reenable it if needed.',
            'error'
          );
        } else {
          notification('A site with this key already exists. Please try again.', 'error');
        }
      } else {
        createSite({
          name: formInputs.siteName,
          subdomain: formInputs.siteKey,
          nations: formInputs.nations.map(nation => nation?.value),
          defaultRegion: convertedRegions,
          salesforceId: formInputs.salesforceId
        });
      }
    }
  };

  let pickerValue: RegionFilter = DEFAULT_REGION_OBJECT.us;
  if (nationInput[0]) {
    pickerValue = DEFAULT_REGION_OBJECT[nationInput[0].value];
    const siteLevelPickerValue = defaultValues?.regions[nationInput[0].value];
    if (siteLevelPickerValue) {
      pickerValue = siteLevelPickerValue;
    }
  }

  return (
    <FormWrapper>
      {formTitleText && <FormTitle>{formTitleText}</FormTitle>}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormInput
          {...register('siteName', {
            required: 'Site name required',
            pattern: { value: /^[a-z0-9 ,.'-]+$/i, message: 'Please enter a valid site name' }
          })}
          error={errors.siteName}
          required
          type="text"
          labelText="Site Name"
          placeholder="Enter name of the site (client facing name)..."
          defaultValue={defaultValues?.siteName}
        />
        <FormInput
          {...register('siteKey', {
            required: 'Site key required',
            pattern: {
              value: /^([a-z0-9]+[-]?[a-z0-9]+)+$/,
              message: 'Please enter a valid site key'
            }
          })}
          error={errors.siteKey}
          required
          type="text"
          labelText="Site Key"
          placeholder="Enter a key for this site..."
          readOnly={!!defaultValues}
          defaultValue={defaultValues?.siteKey}
        />

        <FormInput
          {...register('salesforceId', {
            required: 'Salesforce ID required',
            pattern: {
              value: /^[a-zA-Z0-9]+$/,
              message: 'Please enter a valid Salesforce ID'
            }
          })}
          error={errors.salesforceId}
          required
          type="text"
          labelText="Salesforce ID"
          placeholder="Enter Salesforce ID..."
          defaultValue={defaultValues?.salesforceId}
        />

        <MultiSelect
          labelText="Site Nations"
          items={selectableNations}
          itemToString={(item: Nation | null) => (item ? item.label : '')}
          itemToKey={(item: Nation) => item?.value}
          renderSelectedItem={(item: Nation) => item.label}
          renderItems={(item: Nation) => (
            <NationItem>
              <NationLabel>{item.label}</NationLabel>
            </NationItem>
          )}
          useMultipleSelectionProps={{
            defaultSelectedItems: defaultValues ? defaultValues?.nations : defaultNation,
            onSelectedItemsChange: ({ selectedItems }) => {
              nations.onChange(selectedItems);
              setNationInput(selectedItems as Nation[]);
            }
          }}
          error={nationsError}
          readOnly={!!defaultValues}
          keepOpenAfterSelection
        />

        {nationInput[0] ? (
          <StyledRegionPicker
            value={pickerValue}
            headerLabel={<RegionHeader> Site Default Regions </RegionHeader>}
            siteNation={nationInput[0] && (nationInput[0].value as JPANation)}
            error={regionsError}
            regionsError={!!regionsError}
            onChange={regions.onChange}
          />
        ) : (
          <></>
        )}

        <ButtonContainer>
          <Button scheme="outline" type="button" onClick={closeModal}>
            Cancel
          </Button>
          <StyledLoadingButton isLoading={isCreateLoading || isUpdateLoading} type="submit">
            {submitButtonText}
          </StyledLoadingButton>
        </ButtonContainer>
      </form>
    </FormWrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
`;

const FormTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 2rem;
`;

const FormWrapper = styled.div`
  width: 66.6rem;
  padding: 3.3rem;
`;

const NationItem = styled.div`
  min-height: 3rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
`;
const NationLabel = styled.span`
  flex-grow: 1;
  font-size: 1.4rem;
  font-weight: 400;
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin-left: 1rem;
`;

const RegionHeader = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${mediumGray};
`;

const StyledRegionPicker = styled(RegionPicker)<{ regionsError: boolean }>`
  & .RegionContainer {
    background: ${bgLightGray};
    border: ${props => (props.regionsError ? `1px solid ${ahoy}` : `1px solid ${borderGray}`)};
    border-radius: 0.4rem;
    padding: 0.2rem;
  }
  & .StyledDropdownFlyout {
    width: 10rem;
    background: ${fog};
    border: none;
    text-align: center;
    padding: 1rem 1.6rem;
  }
`;

export default SiteForm;
