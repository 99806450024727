import React, { ReactNode, useRef } from 'react';

import styled from '@emotion/styled';

import { darkBlue, mediumLightGray } from 'utils/colors';

import { ReactComponent as ArrowRight } from 'images/arrowRight.svg';
import { ReactComponent as CloseIcon } from 'images/closebtn.svg';

import { usePopup } from 'hooks/usePopup';

const ShowDetailButton: React.FC<{
  renderDetails(): ReactNode;
  startingOpacity?: number;
  hideLabel?: boolean;
  showingDetailLabel?: string;
  hidingDetailLabel?: string;
  tabIndex?: number;
  className?: string;
  anchorRef?: React.RefObject<HTMLElement>;
  dataCy?: string;
}> = ({
  renderDetails,
  tabIndex,
  showingDetailLabel,
  hidingDetailLabel,
  hideLabel,
  startingOpacity,
  className,
  anchorRef,
  dataCy
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [Popup, toggleIsShowingDetail, isShowingDetail] = usePopup();

  return (
    <>
      <StyledDetailButton
        ref={ref}
        opacity={startingOpacity ?? 1}
        type="button"
        tabIndex={tabIndex ? tabIndex : 0}
        className={className}
        onClick={event => {
          event.stopPropagation();
          toggleIsShowingDetail();
        }}
        data-cy={dataCy}
      >
        {!hideLabel &&
          (isShowingDetail
            ? showingDetailLabel ?? 'Hide Detail'
            : hidingDetailLabel ?? 'Show Detail')}

        {isShowingDetail ? <StyledCloseIcon /> : <StyledRightArrow />}
      </StyledDetailButton>
      <Popup anchorPointRef={anchorRef || ref}>{renderDetails()}</Popup>
    </>
  );
};

export default ShowDetailButton;

const StyledDetailButton = styled.button<{ opacity: number }>`
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  background: inherit;
  color: ${mediumLightGray};
  display: flex;
  align-items: center;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.2s ease;

  &:hover {
    color: ${darkBlue};
    svg {
      stroke: ${darkBlue};
    }
  }
`;

const StyledRightArrow = styled(ArrowRight)`
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  stroke: ${mediumLightGray};
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  stroke: ${mediumLightGray};
`;
