import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import LoadingButton from 'components/atoms/LoadingButton';
import Button from 'components/atoms/Button';
import MessageModal from 'components/molecules/MessageModal';

const DeleteUserModal: React.FC<{
  closeModal: () => void;
  handleUserAction: () => void;
  isSuccess: boolean;
  isLoading: boolean;
  isInternalAdmin?: boolean;
}> = ({ closeModal, isSuccess, handleUserAction, isLoading, isInternalAdmin }) => {
  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return (
    <StyledMessageModal
      title={`${isInternalAdmin ? 'Delete' : 'Remove'} User?`}
      message={
        isInternalAdmin ? (
          'All user data will be deleted.'
        ) : (
          <div>
            This user will be removed from all Skillabi sites you have access to. This action cannot
            be undone.
            <br />
            Do you wish to continue?
          </div>
        )
      }
    >
      <ButtonsWrapper>
        <StyledButton scheme="outline" type="button" onClick={closeModal}>
          Cancel
        </StyledButton>
        <StyledLoadingButton
          type="button"
          scheme="danger"
          isLoading={isLoading}
          onClick={() => handleUserAction()}
        >
          {isInternalAdmin ? 'Delete' : 'Remove'}
        </StyledLoadingButton>
      </ButtonsWrapper>
    </StyledMessageModal>
  );
};

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  min-width: 11.4rem;
`;

const StyledMessageModal = styled(MessageModal)`
  width: 75rem;
`;

export default DeleteUserModal;
