import React from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';

import Button from 'components/atoms/Button';
import Modal from 'components/atoms/Modal';
import CustomBenchmarkForm from 'components/molecules/CustomBenchmarkForm';
import SkillsFromTextBenchmarkForm from 'components/molecules/SkillsFromTextBenchmarkForm';

import { addCustomBenchmarkToProgram } from 'services/curricularSkills';
import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { borderBlue, navyBlue, skyBlue } from 'utils/colors';

interface Props {
  programId: string;
  onCloseModal: () => void;
}

const CustomBenchmarksModal: React.FC<Props> = ({ programId, onCloseModal }) => {
  const queryClient = useQueryClient();

  const [componentStatus, updateComponentStatus] = React.useState<
    'selecting' | 'creating-from-jpa' | 'creating-from-text'
  >('selecting');

  const createCustomBenchmark = async (benchmark: Omit<Benchmark, 'id' | 'deletedAt'>) => {
    await addCustomBenchmarkToProgram(programId, benchmark, false);
    onCloseModal();
    await queryClient.invalidateQueries(['program', programId]);
    await queryClient.invalidateQueries('benchmarks');
  };

  let display: React.ReactNode;
  switch (componentStatus) {
    case 'creating-from-jpa': {
      display = (
        <CustomBenchmarkForm onCreateBenchmark={createCustomBenchmark} onCancel={onCloseModal} />
      );
      break;
    }
    case 'creating-from-text': {
      display = (
        <SkillsFromTextBenchmarkForm
          onCancel={onCloseModal}
          onCreateBenchmark={createCustomBenchmark}
        />
      );
      break;
    }
    case 'selecting': {
      display = (
        <>
          <ContentWrapper>
            <ModalSpan>Create New Benchmark</ModalSpan>
            <ButtonContainer>
              <BenchmarkButton onClick={() => updateComponentStatus('creating-from-jpa')}>
                <StyledPlus /> Use Benchmark Template
              </BenchmarkButton>
              <BenchmarkButton
                onClick={() => updateComponentStatus('creating-from-text')}
                data-cy="create-custom-benchmark-modal_creating-from-text-button"
              >
                <StyledPlus /> Use Text From a Job Posting
              </BenchmarkButton>
            </ButtonContainer>
          </ContentWrapper>
          <FormFooter>
            <Button
              type="button"
              scheme="outline"
              disabled={false}
              onClick={() => {
                onCloseModal();
              }}
            >
              Cancel
            </Button>
            <Button disabled type="submit">
              <div>Save Benchmarks</div>
            </Button>
          </FormFooter>
        </>
      );
      break;
    }
  }

  return <Modal closeModal={onCloseModal}>{display}</Modal>;
};

export const ContentWrapper = styled.div`
  margin: 0.5rem 5rem 0.5rem 5rem;
`;

export const ModalSpan = styled.span`
  font-size: 2rem;
`;

const ButtonContainer = styled.div`
  padding-bottom: 3rem;
`;

export const FormFooter = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3rem;
  padding: 1rem 1.6rem 1rem;
  justify-content: flex-end;
  background: white;
  gap: 1rem;
`;

const BenchmarkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60rem;
  text-align: center;
  border: 2px dashed ${borderBlue};
  color: ${navyBlue};
  border-radius: 0.4rem;
  font-size: 1.4rem;
  height: 14rem;
  background: inherit;
  padding: 0;
  margin: auto;
  margin-top: 4rem;

  transition: background 0.2s ease-in;
  &:hover {
    background: ${skyBlue};
  }

  @media (max-width: 1366px) {
    width: 50rem;
  }
`;

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  stroke: ${navyBlue};
`;

export default CustomBenchmarksModal;
