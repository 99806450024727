import React from 'react';
import styled from '@emotion/styled';

import AriaAlert from 'components/atoms/AriaAlert';
import { useTableState } from 'store/tableStore';
import { white, saphire } from 'utils/colors';

interface SelectTextProps {
  selectPage: () => void;
  allItemsOnPageChecked?: boolean;
  tableItemLabel: string;
  selectableCount?: number;
  clearSelection: () => void;
  onSelectAll: () => void;
}

const SelectionInfo = styled.span`
  margin-left: 3rem;
`;

const Wrapper = styled.div`
  background: ${white};
  min-height: 3rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-style: italic;
  border-style: solid;
  border-width: 0 1px;
  border-color: rgba(0, 0, 0, 0.05);
`;

const SelectMoreButton = styled.button`
  background-color: ${white};
  color: ${saphire};
  padding: 0px;
  cursor: pointer;
  margin: 0px 0px 0px 0.5rem;
  font-style: italic;
  border: none;

  :hover {
    text-decoration: underline;
  }
`;

const SelectText = ({
  allItemsOnPageChecked,
  onSelectAll,
  selectPage,
  tableItemLabel,
  selectableCount,
  clearSelection
}: SelectTextProps): React.ReactElement => {
  const { mode, selectedOrDeselected } = useTableState();

  const numberSelected =
    mode === 'selecting'
      ? selectedOrDeselected.length
      : (selectableCount || 0) - selectedOrDeselected.length;

  const spanText = !numberSelected
    ? `${
        selectableCount && selectableCount - selectedOrDeselected.length
      } ${tableItemLabel}s are selected.`
    : `${numberSelected} ${tableItemLabel}${numberSelected === 1 ? ' is' : 's are'} selected.`;

  return (
    <Wrapper>
      {numberSelected > 0 && (
        <>
          <AriaAlert>
            {spanText} {selectableCount} available
          </AriaAlert>
          <SelectionInfo>
            {spanText}
            {selectableCount && selectableCount > numberSelected && mode === 'selecting' && (
              <SelectMoreButton
                onClick={() => {
                  onSelectAll();
                  if (!allItemsOnPageChecked) {
                    selectPage();
                  }
                }}
              >
                Select all {selectableCount} {tableItemLabel}s available.
              </SelectMoreButton>
            )}
            {selectableCount && mode === 'deselecting' && (
              <SelectMoreButton onClick={clearSelection}>Clear selection.</SelectMoreButton>
            )}
          </SelectionInfo>
        </>
      )}
    </Wrapper>
  );
};

export default SelectText;
