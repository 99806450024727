/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import {
  useForm,
  FormProvider,
  UseFormHandleSubmit,
  useController,
  useFormContext
} from 'react-hook-form';
import { css } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useQueryClient } from 'react-query';

import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import LoadingButton from 'components/atoms/LoadingButton';
import { LabeledToggle } from 'components/atoms/LabeledToggle';
import Header from 'components/molecules/Header';
import DeleteProgramModal from 'components/molecules/DeleteProgramModal';
import ModalButton from 'components/molecules/ModalButton';
import StickyButtonBar from 'components/molecules/StickyButtonBar';
import { Item } from 'components/molecules/SelectedItemsCard';
import FormContainer from 'components/organisms/FormContainer';
import ProgramGeneralInfoForm from 'components/organisms/ProgramGeneralInfoForm';
import SelectCoursesProgramForm from 'components/organisms/SelectCoursesProgramForm';
import SkillsFromText from 'components/organisms/SkillsFromText';

import { ReactComponent as ProgramIcon } from 'images/programIcon.svg';
import { ReactComponent as Download } from 'images/cloudDownload.svg';
import { ReactComponent as Trashcan } from 'images/trash.svg';

import { createCurricularUnit, updateCurricularUnit } from 'services/curricularSkills';

import { useProfileState } from 'store/profileStore';
import { getToastFnThatWeTreatLikeHook, DropdownSelectable, useExport } from 'hooks';
import useCurrentNation from 'hooks/useCurrentNation';
import { useJPAFilterQuery } from 'hooks/jpaHooks';
import { white, ahoy, mediumGray } from 'utils/colors';
import { ReactComponent as SkillsPlaceholderImage } from 'images/skillsByTextEmpty.svg';
import { PreprocessedSkill } from 'services/skills';

import getProgramExportData from 'helpers/exports/exportProgram';
import { useProgramExcelData } from 'hooks/programExcelData';

// I know this import is dirty, so please pardon me. TODO(Elijah) figure out a good long term solution for importing constants like this
import { DEFAULT_REGION_OBJECT } from '../SetupLocalStorage';

interface ProgramFormProps {
  editProgramId?: string;
  defaultValues?: Partial<
    Omit<CurricularUnitResponse, 'site' | 'createdAt' | 'updatedAt' | 'skillabiType'>
  >;
}

export interface Inputs {
  title: string;
  programType?: DropdownSelectable;
  courses?: Item[];
  url?: string;
  description?: string;
  cipCode?: string;
  skills?: CurricularSkill[];
  learningObjectiveText?: string;
}

const CreateProgramForm: React.FC<
  ProgramFormProps & { handleSubmit: UseFormHandleSubmit<Inputs> }
> = ({ editProgramId, handleSubmit, defaultValues }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError] = useState<string | undefined>(undefined);
  const [isPublished, setIsPublished] = useState<boolean>(!!defaultValues?.isPublished);
  const [closeModalFn, setCloseModalFn] = useState<VoidFunction | null>(null);
  const [parsedSkills, setParsedSkills] = useState<PreprocessedSkill[]>([]);
  const [revealedSkill, setRevealedSkill] = useState<string>();
  const [learningOutcomeSkills, setParsedLearningOutcomeSkills] = useState<string[]>([]);
  const [, setParsingError] = useState<string | undefined>();

  const notification = getToastFnThatWeTreatLikeHook();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { currentSite } = useProfileState();
  const [currentNation] = useCurrentNation();

  const { control } = useFormContext<Inputs>();

  const { field: coursesField } = useController({
    name: 'courses',
    defaultValue: defaultValues?.children
      ? defaultValues.children.map(course => ({ id: course.id, ...course.attributes }))
      : undefined,
    control
  });

  const { field: skillsField } = useController({
    name: 'skills',
    defaultValue: [],
    control
  });

  const { regionFilter, jpaFilter } = useJPAFilterQuery();

  const { data: programExcelData, isLoading: programExcelDataIsLoading } = useProgramExcelData({
    currentTimeframe: 12,
    jpaQuery: jpaFilter,
    programId: editProgramId || 'new_program',
    regionFilter: regionFilter || DEFAULT_REGION_OBJECT[currentNation]
  });

  const [exportProgram, { exportIsLoading }] = useExport({
    filename: defaultValues?.title || 'program_download',
    isPreloading: programExcelDataIsLoading,
    exportDataFn: getProgramExportData(programExcelData),
    onFinished() {
      closeModalFn?.();
    }
  });

  const location = useLocation<
    | {
        formValues?: {
          courses?: readonly Item[];
        };
      }
    | undefined
  >();

  const defaultCourses = useMemo<Item[] | undefined>(() => {
    return defaultValues?.children?.map(course => ({ id: course.id, ...course.attributes }));
  }, [defaultValues]);

  const onSubmit = async ({ programType, ...data }: Inputs) => {
    setIsSubmitting(true);

    const submitter = (site: string) => {
      const payload: CurricularUnitPatchBody = {
        title: data.title,
        description: data.description,
        url: data.url,
        cipCode: data.cipCode,
        isPublished,
        children: data.courses?.map(course => {
          return {
            id: course.id,
            isRequired: course.isRequired
          };
        }),
        // only submit skill if skill is parsed from text; if skill was selected then removed from text, don't save
        skills: data.skills
          ?.filter(skill => parsedSkills.map(s => s.id).includes(skill.id))
          .map(skill => {
            return { id: skill.id, isLearningObjective: skill.isLearningObjective };
          }),
        learningObjectiveText: data.learningObjectiveText,
        unitType: programType?.value
      };

      return editProgramId
        ? updateCurricularUnit(editProgramId, payload)
        : createCurricularUnit({
            site,
            skillabiType: 'group',
            ...payload
          } as CurricularUnitPostBody);
    };
    try {
      const program = await submitter(currentSite);
      await queryClient.invalidateQueries(['program', editProgramId]);
      await queryClient.invalidateQueries('programs');
      await queryClient.invalidateQueries('courses');
      notification(editProgramId ? 'Done! Program updated.' : 'Done! Program created.', 'success');
      setIsSubmitting(false);
      history.push(`/program/summary/${program.data.id}`);
    } catch (err) {
      notification(
        editProgramId
          ? 'Sorry, we were unable to update that program. Please try again.'
          : 'Sorry, we were unable to create that program. Please try again.',
        'error'
      );
      setIsSubmitting(false);
    }
  };

  const handleParsedSkills = useCallback(
    (updatedParsedSkills: PreprocessedSkill[], loSkills: PreprocessedSkill[]) => {
      if (revealedSkill && !updatedParsedSkills.some(skill => skill.id === revealedSkill)) {
        // if parsed skills don't include revealed skill, set to undefined
        setRevealedSkill(undefined);
      }
      setParsedSkills(updatedParsedSkills);
      setParsedLearningOutcomeSkills(loSkills.map(skill => skill.id));
    },
    [revealedSkill]
  );

  return (
    <>
      <Header
        css={css`
          margin: 1rem auto;
        `}
        headerText={editProgramId ? defaultValues?.title || '' : 'Create New Program'}
        Icon={StyledProgramIcon}
      >
        <LabeledToggle
          offLabel="Draft"
          onLabel="Published"
          value={isPublished}
          onValueChange={setIsPublished}
        />

        <Spacer />

        {editProgramId && (
          <ModalButton
            type="button"
            buttonText={
              <>
                <StyledDownload title="cloud download icon" />
                Export
              </>
            }
          >
            {closeModal => (
              <StyledExportConfirmationModal>
                <div>This export will not include unsaved changes from this page.</div>
                <LoadingButton
                  type="button"
                  isLoading={exportIsLoading}
                  onClick={() => {
                    setCloseModalFn(closeModal);
                    exportProgram();
                  }}
                >
                  <StyledDownload title="cloud download icon" />
                  Export
                </LoadingButton>
                <Button type="button" scheme="outline" onClick={closeModal}>
                  Cancel
                </Button>
              </StyledExportConfirmationModal>
            )}
          </ModalButton>
        )}
      </Header>
      <FormContainer onSubmit={handleSubmit(onSubmit)} errorMessage={submissionError}>
        <Row>
          <ProgramGeneralInfoForm
            defaultValues={defaultValues}
            onParseSkills={handleParsedSkills}
            onParseError={setParsingError}
            revealedSkill={revealedSkill}
          />

          <SkillsFromTextWrapper>
            {!parsedSkills?.length ? (
              <Card
                css={css`
                  max-height: 40rem;
                `}
              >
                <CardHeading>Skills From Text</CardHeading>
                <EmptySkillsContents>
                  <SkillsPlaceholderImage />
                  <HeavySpan>No skills to show yet</HeavySpan>
                  <StyledSpan>
                    Start typing, or paste in text to see what skills are in your curriculum
                  </StyledSpan>
                </EmptySkillsContents>
              </Card>
            ) : (
              <SkillsFromText
                selectedSkills={skillsField.value || []}
                onChange={newSkills => {
                  skillsField.onChange(newSkills);
                }}
                parsedSkills={parsedSkills}
                learningOutcomeSkillIds={learningOutcomeSkills}
                revealedSkillId={revealedSkill}
                onReveal={skillId => {
                  if (skillId === revealedSkill) {
                    setRevealedSkill(undefined);
                  } else {
                    setRevealedSkill(skillId);
                  }
                }}
              />
            )}
          </SkillsFromTextWrapper>
        </Row>

        <div id="editCourse">
          <SelectCoursesProgramForm
            onChange={coursesField.onChange}
            defaultValues={defaultCourses || location.state?.formValues?.courses}
            headers={{ left: 'Select Courses for Your Program' }}
            locationStateKey="createProgramCourseSelect"
          />
        </div>
        <StickyButtonBar
          deleteOption={
            editProgramId && (
              <DeleteModalButton
                type="button"
                data-cy="program-form_delete-button"
                buttonText={
                  <>
                    <StyledTrashIcon />
                    Delete Program
                  </>
                }
              >
                {closeModal => (
                  <DeleteProgramModal
                    forwardOnComplete
                    closeModal={closeModal}
                    programIds={[editProgramId]}
                  />
                )}
              </DeleteModalButton>
            )
          }
          onCancel={() => history.push('/dashboard/programs')}
          submitText="Save Program"
          cancelText="Discard Changes"
          isSubmitting={isSubmitting}
          {...(editProgramId
            ? { deleteText: 'Delete Program', curricularId: editProgramId }
            : null)}
        />
      </FormContainer>
    </>
  );
};

const WrappedProgramForm: React.FC<ProgramFormProps> = ({ defaultValues, editProgramId }) => {
  const methods = useForm<Inputs>({
    shouldFocusError: false,
    mode: 'onTouched',
    defaultValues
  });
  return (
    <FormProvider {...methods}>
      <CreateProgramForm
        {...{ defaultValues, editProgramId, handleSubmit: methods.handleSubmit }}
      />
    </FormProvider>
  );
};

export default WrappedProgramForm;

const StyledProgramIcon = styled(ProgramIcon)`
  height: 2.2rem;
  width: 2rem;
`;

const StyledDownload = styled(Download)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;

  path {
    stroke: ${white};
  }
`;

const DeleteModalButton = styled(ModalButton)`
  display: flex;
  align-items: center;
  color: ${ahoy};
  background: transparent;
  margin-right: 1rem;
  border: none;
  padding: 1rem 0.3rem;
  cursor: pointer;
  font-size: 1.4rem;
`;

const StyledTrashIcon = styled(Trashcan)`
  margin-right: 1rem;

  & > path {
    stroke: ${ahoy};
  }
`;

const StyledExportConfirmationModal = styled.div`
  padding: 4rem;

  button {
    float: right;
    margin-top: 4rem;
    margin-left: 2rem;
  }
`;

export const Spacer = styled.div`
  width: 2rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 2rem;
`;

const SkillsFromTextWrapper = styled.div`
  max-width: 40rem;
`;

const EmptySkillsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: 6rem auto 0;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
`;

const StyledSpan = styled.span`
  display: block;
`;

const HeavySpan = styled(StyledSpan)`
  font-weight: 600;
  margin-top: 2rem;
`;
