import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Logo } from 'images/logo.svg';

const Maintainance = () => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
      <Skillabi>Skillabi</Skillabi>
    </LogoWrapper>
    <h1>
      Skillabi is undergoing scheduled maintainance. If this issue persists, please contact your
      account manager.
    </h1>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  h1 {
    text-align: center;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const Skillabi = styled.span`
  font-size: 5rem;
  font-weight: bold;
  margin: 0;
`;
export default Maintainance;
