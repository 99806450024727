import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { useMutation, UseMutationResult, useQueryClient, UseMutationOptions } from 'react-query';
import { createNewSite, updateSite } from 'services/sites';

interface SiteVariables {
  name: string;
  subdomain: string;
  nations: string[];
  salesforceId: string;
  defaultRegion: DefaultRegionConverted;
}

type SiteOptions = UseMutationOptions<SiteAttributes, unknown, SiteVariables, unknown>;

export const useCreateSite = (
  options?: SiteOptions
): UseMutationResult<SiteAttributes, unknown, SiteVariables, unknown> => {
  const queryClient = useQueryClient();
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<SiteAttributes, unknown, SiteVariables, unknown>({
    onError: () => toast('Sorry, we were unable to create that site. Please try again.', 'error'),
    onSuccess: () => {
      toast('Done! Site created.');
      queryClient.invalidateQueries(['sites']);
    },
    mutationFn: (attributes: SiteVariables) => createNewSite(attributes),
    ...options
  });
};

export const useUpdateSite = (
  options?: SiteOptions
): UseMutationResult<SiteAttributes, unknown, SiteVariables, unknown> => {
  const queryClient = useQueryClient();
  const toast = getToastFnThatWeTreatLikeHook();

  return useMutation<SiteAttributes, unknown, SiteVariables, unknown>({
    onError: () => toast('Sorry, we were unable to update that site. Please try again.', 'error'),
    onSuccess: () => {
      toast('Done! Site updated.');
      queryClient.invalidateQueries(['sites']);
    },
    mutationFn: ({ subdomain, ...attributes }: SiteVariables) => updateSite(subdomain, attributes),
    ...options
  });
};
