import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';

import Button from 'components/atoms/Button';
import Loading from 'components/atoms/Loading';
import FormInput from 'components/molecules/FormInput';
import MultiselectSearch from 'components/molecules/MultiselectSearch';

import { useCurrentSiteState } from 'store/currentSiteStore';
import { getToastFnThatWeTreatLikeHook } from 'hooks';
import useCurrentNation from 'hooks/useCurrentNation';
import { searchJPAFacet } from 'services/jpa';

import { ReactComponent as Plus } from 'images/createNewPlus.svg';
import { white, borderGray, darkGray } from 'utils/colors';
import { BENCHMARK_FACET_FIELDS } from 'utils/curricularSkills';

import {
  ContentWrapper,
  FormFooter,
  ModalSpan
} from 'components/molecules/CreateCustomBenchmarkModal';
import { DescriptionText } from 'components/molecules/SkillsFromTextBenchmarkForm';

interface Selectable {
  name: string;
  id: string;
}

interface FormData {
  name: string;
  facets: Record<string, Selectable[]>;
}

const getDefaultFormValues = (nation: JPANation): FormData => ({
  name: '',
  facets: Object.fromEntries(
    BENCHMARK_FACET_FIELDS[nation].map(f => [f.facet, [] as Selectable[]])
  ) as FormData['facets']
});

const mapFacetsToIDs = (facets: FormData['facets']): Record<string, string[]> =>
  Object.entries(facets).reduce<Record<string, string[]>>((f, [key, value]) => {
    if (value.length > 0) {
      f[key] = value.map(x => x.id);
    }
    return f;
  }, {});

const CustomBenchmarkForm: React.FC<{
  onCreateBenchmark: (benchmark: Omit<Benchmark, 'id' | 'deletedAt'>) => Promise<void>;
  onCancel: () => void;
}> = ({ onCreateBenchmark, onCancel }) => {
  const [nation] = useCurrentNation();
  const { site } = useCurrentSiteState();
  const [activeFields, setActiveFields] = useState<boolean[]>(
    BENCHMARK_FACET_FIELDS[nation].map(() => false)
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const toast = getToastFnThatWeTreatLikeHook();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    control,
    reset
  } = useForm<FormData>({
    defaultValues: getDefaultFormValues(nation)
  });

  const onSubmit = async ({ name, facets }: FormData) => {
    // Make sure the user has entered values for at least one facet type.
    if (!Object.values(facets).flat().length) {
      const allFieldNames = BENCHMARK_FACET_FIELDS[nation].map(f => f.label);
      const names = `${allFieldNames.slice(0, -1).join(', ')} or ${allFieldNames.slice(-1)}`;

      BENCHMARK_FACET_FIELDS[nation].forEach(({ facet }, i) => {
        activeFields[i] &&
          setError(`facets.${facet}`, {
            message: `At least one of ${names} is required.`
          });
      });
      return;
    }
    setIsSubmitting(true);
    const jpaBenchmark: Omit<JPABenchmark, 'id' | 'deletedAt'> = {
      type: 'customJPABenchmark',
      site,
      nation,
      title: name,
      facets: mapFacetsToIDs(facets)
    };

    try {
      await onCreateBenchmark(jpaBenchmark);
      toast('Done! Benchmark created', 'success');
      reset();
    } catch (e) {
      toast('Unable to save benchmark. Try again?', 'error');
      setIsSubmitting(false);
    }
  };

  return (
    <form>
      <ContentWrapper>
        <ModalSpan>Create New Benchmark</ModalSpan>
        <DescriptionText>
          Use any combination of the options below to create a custom benchmark for this program.
        </DescriptionText>
        <FormInput
          type="text"
          labelText="Name"
          placeholder="Name a benchmark..."
          disabled={isSubmitting}
          error={errors.name}
          {...register('name', { required: 'Please give your new custom benchmark a name.' })}
        />
        {BENCHMARK_FACET_FIELDS[nation].map((field, i) => {
          return (
            activeFields[i] && (
              <Controller
                key={field.facet}
                name={`facets.${field.facet}`}
                control={control}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <MultiselectSearch
                    readOnly={isSubmitting}
                    inputRef={ref}
                    inputOnBlur={onBlur}
                    label={field.label}
                    placeholder={field.placeholder}
                    search={q => searchJPAFacet(nation, field.facet, { q })}
                    renderItems={item => {
                      return (
                        <DropDownItemWrapper>
                          <span>{item.name}</span>
                          {field.label === 'Occupations' || field.label === 'Industries' ? (
                            <span>{item.id}</span>
                          ) : null}
                        </DropDownItemWrapper>
                      );
                    }}
                    renderSelectedItem={item => {
                      return (
                        <DropDownItemWrapper>
                          <StyledSpan>
                            {item.name}{' '}
                            {field.label === 'Occupations' || field.label === 'Industries'
                              ? `(${item.id})`
                              : null}
                          </StyledSpan>
                        </DropDownItemWrapper>
                      );
                    }}
                    useMultipleSelectionProps={{
                      selectedItems: value,
                      onSelectedItemsChange: ({ selectedItems: newValue }) =>
                        newValue !== value && onChange(newValue)
                    }}
                    error={error}
                  />
                )}
              />
            )
          );
        })}
        <JPAButtonsWrapper>
          {BENCHMARK_FACET_FIELDS[nation].map(
            (field, i) =>
              !activeFields[i] && (
                <JPAFieldButton
                  key={field.facet}
                  type="button"
                  disabled={isSubmitting}
                  onClick={e => {
                    e.preventDefault();
                    setActiveFields(prev => {
                      const arr = [...prev];
                      arr[i] = true;
                      return arr;
                    });
                  }}
                >
                  <DarkPlus title="plus icon" />
                  {field.label}
                </JPAFieldButton>
              )
          )}
        </JPAButtonsWrapper>
      </ContentWrapper>
      <FormFooter>
        <Button type="button" scheme="outline" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          disabled={!activeFields.some(Boolean) || isSubmitting}
          onClick={handleSubmit(onSubmit)}
          data-cy="custom-benchmark-form_add-benchmark-button"
        >
          {!isSubmitting ? (
            <StyledPlus title="plus icon" />
          ) : (
            <StyledLoading size={2.5} color="white" />
          )}
          <div>Add to Benchmarks</div>
        </Button>
      </FormFooter>
    </form>
  );
};

const StyledPlus = styled(Plus)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
`;

const DarkPlus = styled(StyledPlus)`
  stroke: ${darkGray};
`;

const StyledLoading = styled(Loading)`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
`;

const JPAFieldButton = styled.button`
  background: ${white};
  border: 1px solid ${borderGray};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3rem;
  padding: 1rem 1.1rem;
  display: flex;
  align-items: center;
  color: ${darkGray};
  font-weight: 500;
  margin: 1rem 1rem 1rem 0rem;
  font-size: 1.4rem;
  cursor: pointer;
  text-align: center;
  min-width: 12rem;
`;

const JPAButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1.4rem;
  align-self: flex-start;
  text-align: center;
  vertical-align: center;
  align-items: center;
`;

const DropDownItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSpan = styled.div`
  display: inline-block;
  max-width: 25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default CustomBenchmarkForm;
