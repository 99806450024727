import React from 'react';
import styled from '@emotion/styled';
import LoadingButton from 'components/atoms/LoadingButton';
import InternalLink from 'components/atoms/InternalLink';
import { ahoy } from 'utils/colors';

import { useAuthState } from 'store/authStore';

interface AuthorizationFormProps {
  headerText: string;
  children: React.ReactNode;
  submitButtonText: string;
  onSubmit(): void;
  linkText?: string;
  linkTo?: string;
  isSubmitted?: boolean;
  errorMessage?: string;
}

const Header = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  @media (max-width: 1600px) {
    margin-top: 0;
  }
`;

const StyledButton = styled(LoadingButton)`
  margin-right: 2.5rem;
  min-width: 6.8rem;
  justify-content: center;
  height: 4rem;
`;

const Error = styled.span`
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: ${ahoy};
  display: block;
`;

const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorizationForm: React.FC<AuthorizationFormProps> = ({
  headerText,
  children,
  submitButtonText,
  linkText,
  linkTo,
  onSubmit,
  errorMessage,
  isSubmitted = false
}) => {
  const { isLoading } = useAuthState();
  return (
    <>
      <Header>{headerText}</Header>
      <form onSubmit={onSubmit} noValidate>
        {children}
        <SubmitWrapper>
          {!isSubmitted && (
            <StyledButton isLoading={isLoading} type="submit">
              {submitButtonText}
            </StyledButton>
          )}{' '}
          {linkText && linkTo && <InternalLink to={linkTo}>{linkText}</InternalLink>}
        </SubmitWrapper>
      </form>
      {errorMessage && <Error>{errorMessage}</Error>}
    </>
  );
};

export default AuthorizationForm;
