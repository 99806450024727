import React from 'react';
import styled from '@emotion/styled';

import { ahoy } from 'utils/colors';

const Contents = styled.div`
  width: 66rem;
  position: relative;
  text-align: center;
  padding: 0 1.4rem 3rem;
`;

const ModalTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
`;

const Message = styled.span`
  font-size: 1.4rem;
`;

const Error = styled.span`
  font-size: 1.4rem;
  color: ${ahoy};
  position: absolute;
  left: 0;
`;

interface MessageModalProps {
  title: string;
  message: React.ReactNode;
  className?: string;
  error?: string;
}

const MessageModal: React.FC<MessageModalProps> = ({
  title,
  message,
  children,
  className,
  error
}) => (
  <Contents className={className}>
    <ModalTitle>{title}</ModalTitle>
    <Message>{message}</Message>

    {children}
    {error && <Error>{error}</Error>}
  </Contents>
);

export default MessageModal;
