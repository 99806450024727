import React from 'react';
import { Text } from '@react-pdf/renderer';
import { black } from 'utils/colors';
import { makeTextHighlighter } from 'helpers/textHighlighter';
import { highlightYellow } from 'utils/colors';

export interface ParagraphHighlight {
  start: number;
  end: number;
}

export interface ParagraphProps {
  text: string;
  bold?: boolean;
  color?: string;
  fontSize?: number;
  highlights?: ParagraphHighlight[];
}

export const Paragraph: React.FC<ParagraphProps> = props => {
  const highlights = props.highlights || [];
  const fontSize = props.fontSize ?? 12;
  const chunky = makeTextHighlighter(props.text);

  for (const highlight of highlights) {
    chunky.highlight('highlight', highlight.start, highlight.end);
  }

  const chunks = chunky.getFragments();

  return (
    <Text>
      {chunks.map((chunk, index) => (
        <Text
          key={`chunk-${index}`}
          style={{
            fontWeight: props.bold ? 'bold' : 'normal',
            fontFamily: 'Inter',
            lineHeight: 1.5,
            fontSize,
            color: props.color || black,
            backgroundColor: chunk.codes.includes('highlight') ? highlightYellow : undefined
          }}
          children={chunk.text}
        />
      ))}
    </Text>
  );
};
