import React, { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { escapeRegExp } from 'lodash';

import Button from 'components/atoms/Button';
import SearchInput from 'components/atoms/SearchInput';
import OccupationTaxonomyPopup from 'components/atoms/OccupationTaxonomyPopup';
import BenchmarksBrowser from 'components/molecules/BenchmarksBrowser';
import Informative from 'components/molecules/Informative';
import { DetailedBenchmarkCardProps } from 'components/molecules/DetailedBenchmarkCard';
import { BenchmarkPopup } from 'components/molecules/BenchmarkPopup';

import formatLargeNumber from 'utils/formatLargeNumber';
import { UniversalBenchmark, UniversalOccupationBenchmark } from 'helpers/benchmarks';
import { useLightcastOccupations, useLightcastOccupationsSalary } from 'hooks/jpaHooks';
import { getTypeSafeKeys } from 'utils/basic';
import { LOT_LABELS } from 'utils/curricularSkills';
import { PillProps } from 'components/atoms/InfoPill';

// The number 42 was chosen because it will completely fill up all rows when there are both 2 and 3 columns
const LOAD_LIMIT = 42;

export interface BrowseAllBenchmarksBrowserProps {
  selectedLOTBenchmarks: UniversalOccupationBenchmark[];
  select: (benchmark: UniversalBenchmark) => void;
  deselect: (benchmark: UniversalBenchmark) => void;
}

const BrowseAllBenchmarksBrowser = ({
  selectedLOTBenchmarks,
  select,
  deselect
}: BrowseAllBenchmarksBrowserProps) => {
  const [selectedType, setSelectedType] = useState<LOTLevel>('career_area');
  const [searchText, setSearchText] = useState('');
  const [limitResults, setLimitResults] = useState(true);

  const { data: lotData, isLoading: lotDataIsLoading } = useLightcastOccupations(
    selectedType,
    undefined
  );

  const lotToShow = useMemo(() => {
    return (lotData || [])
      .filter(lot => lot.name.match(new RegExp(escapeRegExp(searchText), 'i')))
      .slice(0, limitResults ? LOAD_LIMIT : undefined);
  }, [lotData, searchText, limitResults]);

  const { data: medianSalaryRankings, isLoading: medianSalaryRankingsIsLoading } =
    useLightcastOccupationsSalary(selectedType, lotData ? lotData.map(lot => lot.id) : []);

  const isLoading = lotDataIsLoading || medianSalaryRankingsIsLoading;

  const formattedBenchmarkForDetailCard = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return lotToShow.map((benchmark, index) => {
      const salary = medianSalaryRankings?.[benchmark.id];

      const pills: PillProps[] = [{ label: `${LOT_LABELS[selectedType]}`, scheme: 'red' }];

      if (salary) {
        pills.push({ label: `$${formatLargeNumber(salary)}`, scheme: 'green' });
      }

      const formattedBenchmark: DetailedBenchmarkCardProps = {
        id: benchmark.id,
        title: benchmark.name,
        pills,
        isSelected: !!selectedLOTBenchmarks.find(
          selectedBench => selectedBench.attributes.id === benchmark.id
        ),
        isCustom: false,
        allowCardSelection: true,
        renderDetailView: () => (
          <BenchmarkPopup
            benchmark={{
              universalType: 'occupation',
              attributes: { id: benchmark.id, name: benchmark.name, type: selectedType }
            }}
          />
        ),
        onSelectionChange(isSelected) {
          return isSelected
            ? select({
                universalType: 'occupation',
                attributes: { id: benchmark.id, name: benchmark.name, type: selectedType }
              })
            : deselect({
                universalType: 'occupation',
                attributes: { id: benchmark.id, name: benchmark.name, type: selectedType }
              });
        },
        tabIndex: index + 1
      };

      return formattedBenchmark;
    });
  }, [lotToShow, medianSalaryRankings, selectedLOTBenchmarks, isLoading]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <InformativeText>
        <div>Select Lightcast Occupation Level:</div>
        <Informative renderContent={() => <OccupationTaxonomyPopup />} />
      </InformativeText>

      <BenchmarksBrowser
        benchmarks={formattedBenchmarkForDetailCard}
        tabsConfig={{
          tabs: getTypeSafeKeys(LOT_LABELS).map(id => ({ id, label: LOT_LABELS[id] })),
          onTabSelected(id: LOTLevel) {
            setSelectedType(id);
            setLimitResults(true);

            if (containerRef.current) {
              containerRef.current.scrollTop = 0;
            }
          },
          selectedTab: selectedType
        }}
        prepend={
          <SearchInput
            placeholder={`Search ${LOT_LABELS[selectedType]}s...`}
            onChange={async q => {
              setSearchText(q);
            }}
            searchOnEmpty
            initialValue={searchText}
          />
        }
        append={
          limitResults && lotToShow.length && !searchText.length ? (
            <LoadMoreContainer>
              <Button scheme="outline" type="button" onClick={() => setLimitResults(false)}>
                ↓ Show More Results
              </Button>
            </LoadMoreContainer>
          ) : null
        }
        isLoading={isLoading}
      />
    </Container>
  );
};

export default BrowseAllBenchmarksBrowser;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InformativeText = styled.div`
  display: flex;
  gap: 1rem;
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`;
