import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as SkillsIcon } from 'images/skillsIcon.svg';
import { lightGray, mediumLightGray } from 'utils/colors';
import Select from 'components/atoms/Select';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultCurricularRouteSearchState } from 'utils/defaultCurricularRouteSearchState';
import InfoChip from 'components/atoms/InfoChip';

type SearchedItemsType = { name: string; id: string };

interface SearchedItemsProps {
  searchType: 'skills' | 'courses' | 'programs';
  selectedItems: SearchedItemsType[];
  setSelectedItems: React.Dispatch<React.SetStateAction<SearchedItemsType[]>>;
  locationStateKey: string;
  trackHistory?: boolean;
}

const SearchedItems: React.FC<SearchedItemsProps> = ({
  searchType,
  selectedItems,
  setSelectedItems,
  locationStateKey,
  trackHistory = true
}) => {
  const history = useHistory();
  const location = useLocation<{
    [x: string]: CurricularRouteSearchState<ProgramSortOption | CourseSortOption> | undefined;
  }>();

  const locationState = {
    [locationStateKey]: defaultCurricularRouteSearchState(),
    ...(location.state || {})
  };
  const localLocationState = locationState[locationStateKey];

  const historyFunc = trackHistory ? history.push : history.replace;

  let selectValue = 'any';

  if (localLocationState?.filter.filterType === 'skills') {
    if (localLocationState.filter.filterOperator === 'any') {
      selectValue = 'any';
    } else if (localLocationState.filter.filterOperator === 'all') {
      selectValue = 'all';
    }
  }

  if (localLocationState?.filter.filterType === 'courses') {
    if (localLocationState.filter.filterOperator === 'any') {
      selectValue = 'any';
    } else if (localLocationState.filter.filterOperator === 'all') {
      selectValue = 'all';
    }
  }

  const buildHistorySortingFn =
    (filterType: 'skills' | 'courses') => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const type = event.target.value;

      let filterOperator: 'any' | 'all';

      if (type === 'any') {
        filterOperator = 'any';
      } else if (type === 'all') {
        filterOperator = 'all';
      } else {
        throw new Error(`Unknown filter operator: ${type}`);
      }

      historyFunc(`${location.pathname}`, {
        ...locationState,
        [locationStateKey]: {
          filter: {
            ...localLocationState?.filter,
            filterType,
            filterOperator,
            filterQuery: selectedItems
          },
          sort: {
            ...localLocationState?.sort,
            sortType: 'title',
            sortOrder: 'ascending'
          }
        }
      });
    };

  return (
    <>
      <StyledDiv>
        {searchType === 'skills' ? (
          <>
            Results contain
            <StyledSelect
              ariaLabel={`select to filter by any or all skills`}
              value={selectValue}
              onChange={buildHistorySortingFn('skills')}
              options={['any', 'all']}
              minWidth="7rem"
            />
          </>
        ) : searchType === 'courses' ? (
          <>
            Results contain
            <StyledSelect
              ariaLabel={`select to filter by any or all courses`}
              value={selectValue}
              onChange={buildHistorySortingFn('courses')}
              options={['any', 'all']}
              minWidth="7rem"
            />
          </>
        ) : (
          'Results are contained in any '
        )}
        of the following {searchType}:
      </StyledDiv>
      <Flex
        css={css`
          border-radius: 4px;
          border: 1px solid ${lightGray};
          padding: 0.5rem;
          min-height: 5rem;
          align-items: center;
        `}
      >
        {selectedItems.length === 0 ? (
          <span
            css={css`
              margin: 0 auto;
              color: ${mediumLightGray};
              font-weight: 400;
              font-style: italic;
              font-size: 1.2rem;
            `}
          >
            Search for {searchType} to start filtering your list.
          </span>
        ) : (
          selectedItems.map(item => (
            <InfoChipContainer key={item.id}>
              <InfoChip
                dataCy="searched-items_selected-items"
                key={item.id}
                ariaLabel={`${item.name}, remove ${searchType}`}
                onClick={() => {
                  setSelectedItems(selectedItems.filter(s => s.id !== item.id));
                }}
              >
                <StyledSkillIcon />
                {item.name}
              </InfoChip>
            </InfoChipContainer>
          ))
        )}
      </Flex>
    </>
  );
};

export default SearchedItems;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledSkillIcon = styled(SkillsIcon)`
  margin-right: 0.5rem;
`;

const StyledSelect = styled(Select)`
  width: 7rem;
  margin: 0 0.5rem;
  padding: 0rem;
`;

const StyledDiv = styled.div`
  display: flex;
  margin-top: 2rem;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-style: italic;
  color: ${mediumLightGray};
  font-weight: 400;
  align-items: center;
`;

const InfoChipContainer = styled.div`
  padding: 0.5rem;
`;
