import { ReactNode } from 'react';
import { pdf } from '@react-pdf/renderer';

import { downloadBlob } from 'utils/download';

import { setRenderPurpose } from './contentsTable';

export async function createPdf(node: ReactNode, filename: string) {
  const render = async () => {
    // @ts-ignore must be a Document, but there is no way to verify this at runtime
    return await pdf(node).toBlob();
  };

  setRenderPurpose('outline');
  await render();

  setRenderPurpose('present');
  const blob = await render();

  downloadBlob(blob, filename);
}
