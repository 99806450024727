import styled from '@emotion/styled';
import React from 'react';

import { ReactComponent as EmptyChartPlaceholderImage } from 'images/skillsByTextEmpty.svg';
import { mediumGray } from 'utils/colors';

export interface NothingPlaceholderProps {
  header: string;
  content: string;
}

const NothingPlaceholder: React.FC<NothingPlaceholderProps> = ({ header, content }) => (
  <ChartContainer>
    <EmptyChartPlaceholderImage />
    <EmptyTextBold>{header || 'No skills to show yet'}</EmptyTextBold>
    <EmptyText>{content || 'Get started by selecting skills above'}</EmptyText>
  </ChartContainer>
);

export default NothingPlaceholder;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  height: 100%;
`;

const EmptyText = styled.span`
  font-size: 1.4rem;
  color: ${mediumGray};
`;

const EmptyTextBold = styled(EmptyText)`
  font-weight: 600;
  margin-top: 2rem;
`;
