const footerLinks = {
  privacyPolicy: {
    linkTo: 'https://www.lightcast.io/privacy-policy/',
    linkText: 'Privacy Policy'
  },
  checkStatus: {
    linkTo: 'https://status.emsidata.com/',
    linkText: 'Check Status'
  }
};

export default footerLinks;
