export function toTitleCase(text: string) {
  if (!text.trim()) {
    return '';
  }

  const words = text.trim().split(/\s+/);
  const titledWords = words.map(word => `${word[0].toUpperCase()}${word.slice(1)}`);

  return titledWords.join(' ');
}
