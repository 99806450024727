import React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { bgLightGray, darkSaphire, lightGray, white } from 'utils/colors';
import useTabs from 'hooks/useTabs';

export interface TabsWrapperProps {
  tabs: { label: string; id: string }[];
  selectedTab: string;
  onTabSelected(id: string): void;
  barBodyGap?: string;
  customStyles?: {
    tabButtonContainer?: StyledComponent<
      React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    >;
    tabButton?: StyledComponent<
      React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
    >;
  };
}

const TabsWrapper: React.FC<TabsWrapperProps> = ({
  onTabSelected,
  selectedTab,
  tabs,
  barBodyGap,
  children,
  customStyles
}) => {
  const tabsController = useTabs(tabId => {
    onTabSelected(tabId);
  });

  const localBarBodyGap = barBodyGap || '0';
  const CustomTabContainer = customStyles?.tabButtonContainer;
  const CustomButton = customStyles?.tabButton;

  const renderTabButtons = () =>
    tabs.map(tab =>
      CustomButton ? (
        <CustomButton
          type="button"
          className={selectedTab === tab.id ? 'active' : ''}
          data-cy={`tab-bar_tab-button_${tab.id}`}
          key={tab.id}
          {...tabsController.getTabProps(tab.id, selectedTab)}
        >
          {tab.label}
        </CustomButton>
      ) : (
        <TabButton
          type="button"
          className={selectedTab === tab.id ? 'active' : ''}
          data-cy={`tab-bar_tab-button_${tab.id}`}
          key={tab.id}
          {...tabsController.getTabProps(tab.id, selectedTab)}
        >
          {tab.label}
        </TabButton>
      )
    );

  return (
    <StyledTabsWrapper gap={localBarBodyGap}>
      <div>
        {CustomTabContainer ? (
          <CustomTabContainer>{renderTabButtons()}</CustomTabContainer>
        ) : (
          <TabButtonsBar flattenBottom={localBarBodyGap === '0'}>
            {renderTabButtons()}
          </TabButtonsBar>
        )}
      </div>

      <div>
        {tabs.map(tab => (
          <div key={tab.id} {...tabsController.getPanelProps(tab.id)}>
            {tab.id === selectedTab && children}
          </div>
        ))}
      </div>
    </StyledTabsWrapper>
  );
};

export default TabsWrapper;

const TabButtonsBar = styled.div<{ flattenBottom: boolean }>`
  border: 0.2rem solid ${lightGray};
  border-bottom: ${({ flattenBottom }) => (flattenBottom ? 'none' : 'unset')};
  padding: 0.4rem;
  border-radius: ${({ flattenBottom }) => (flattenBottom ? '0.4rem 0.4rem 0 0' : '0.4rem')};
  display: inline-block;
  background: ${bgLightGray};
`;

const TabButton = styled.button`
  padding: 0.8rem 1.4rem;
  border: none;
  background: transparent;
  border-radius: 0.4rem;

  &.active {
    background: ${white};
    color: ${darkSaphire};
  }
`;

const StyledTabsWrapper = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;
