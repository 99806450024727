import React from 'react';
import styled from '@emotion/styled';

const StyledSpan = styled.span`
  display: block;
  margin-top: 1.5rem;
`;

const TOSText: React.FC = () => {
  return (
    <p>
      Lightcast will use Uploaded Data to improve its understanding of skills to better serve
      customers across its offerings. Representative (but non-exhaustive) examples of
      Lightcast&lsquo;s use of Uploaded Data are: improving Lightcast&lsquo;s skill-tagging process,
      Lightcast&lsquo;s skills library, and Lightcast&lsquo;s ability to suggest “related” skills.
      Any improvements to Lightcast&lsquo;s offerings and processes derived from Uploaded Data rely
      on patterns identified in aggregated data across all Skillabi users, institutions, and
      organizations. No uses of Uploaded Data will allow any third party to identify you or your
      organization as the source of any specific data.
      <StyledSpan>
        For clarity, while you will retain all rights in the Uploaded Data, Lightcast owns all
        rights in improvements to its processes and offerings accomplished by use of Uploaded Data
        as described above.
      </StyledSpan>
    </p>
  );
};

export default TOSText;
