import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Check } from 'images/greenCheck.svg';
import { ReactComponent as Document } from 'images/document.svg';

import { lightGreen, darkGreen, darkBlue, highlightYellow } from 'utils/colors';

const Badge = styled.div`
  border-radius: 20rem;
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 800;
  svg {
    width: 1.2rem;
    stroke-width: 1.5px;
    margin-right: 0.5rem;
  }
`;

const PublishedBadge = styled(Badge)`
  background: ${lightGreen};
  color: ${darkGreen};
  & > svg {
    stroke: ${darkGreen};
  }
`;

const DraftBadge = styled(Badge)`
  background: ${highlightYellow};
  color: ${darkBlue};
  & > svg {
    stroke: ${darkBlue};
  }
`;

interface PublishedStatusBadgeProps {
  className?: string;
  isPublished: boolean;
}

const PublishedStatusBadge: React.FC<PublishedStatusBadgeProps> = ({ className, isPublished }) => {
  return isPublished ? (
    <PublishedBadge className={className}>
      <Check />
      Published
    </PublishedBadge>
  ) : (
    <DraftBadge className={className}>
      <Document />
      Draft
    </DraftBadge>
  );
};

export default PublishedStatusBadge;
