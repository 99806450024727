import React from 'react';
import styled from '@emotion/styled';

import LoadingButton from 'components/atoms/LoadingButton';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import Loading from 'components/atoms/Loading';
import CompactBenchmark from 'components/molecules/CompactBenchmark';

import { borderGray, darkGray, lightGray, white, vividRed } from 'utils/colors';
import { UniversalBenchmark } from 'helpers/benchmarks';

import { ReactComponent as ToastError } from 'images/toasterError.svg';
import { ReactComponent as Remove } from 'images/x-remove.svg';
import { ReactComponent as ItemPlaceholder } from 'images/skillsByTextEmpty.svg';
import { ReactComponent as InfoIcon } from 'images/toasterError.svg';

const SelectedBenchmarks: React.FC<{
  isLoading: boolean;
  isSaving: boolean;
  canSave: boolean;
  benchmarks: UniversalBenchmark[];
  onRemoveItem(benchmark: UniversalBenchmark): void;
  onRemoveAll(): void;
  onCancel(): void;
}> = ({ isLoading, isSaving, canSave, benchmarks, onRemoveItem, onRemoveAll, onCancel }) => {
  return (
    <SelectedBenchmarksCard className="selected-benchmarks-card">
      <CardHeaderRow>
        <GrowingHeadingText>Selected Benchmarks</GrowingHeadingText>

        <PillButton
          data-cy="selected-benchmarks_restore-default-button"
          type="button"
          onClick={onRemoveAll}
        >
          <StyledRemove />
          Restore Default
        </PillButton>
      </CardHeaderRow>

      {isLoading ? (
        <CenterContainer>
          <Loading />
        </CenterContainer>
      ) : !benchmarks.length ? (
        <>
          <CenterContainer>
            <ItemPlaceholder />
            <HeavyText data-cy="selected-benchmarks_no-benchmarks-text">
              No benchmarks added yet
            </HeavyText>
            <div>Use the list to the left to search and add benchmarks.</div>
            <Content>
              <StyledInfoIcon />
              <div> At least one benchmark must be selected to save changes</div>
            </Content>
          </CenterContainer>
        </>
      ) : (
        <BenchmarksList data-cy={'selected-benchmarks_benchmarks-list'}>
          {benchmarks.map(benchmark => (
            <BenchmarkItem key={benchmark.attributes.id}>
              <CompactBenchmark benchmark={benchmark} onRemove={() => onRemoveItem(benchmark)} />
            </BenchmarkItem>
          ))}
        </BenchmarksList>
      )}
      {benchmarks.length > 10 && !isLoading && (
        <TooManyBenchmarksMessage>
          <StyledToastError />
          Too many benchmarks selected. Select 10 or fewer benchmarks to save changes.
        </TooManyBenchmarksMessage>
      )}
      {!isLoading && (
        <ActionsBar>
          <Button
            type="button"
            scheme="outline"
            data-cy="selected-benchmarks_cancel"
            onClick={onCancel}
          >
            {canSave ? 'Discard Changes' : 'Cancel'}
          </Button>
          <LoadingButton
            type="submit"
            isLoading={isSaving}
            disabled={!canSave || benchmarks.length > 10}
            data-cy="selected-benchmarks_save"
          >
            Save Changes
          </LoadingButton>
        </ActionsBar>
      )}
    </SelectedBenchmarksCard>
  );
};

export default SelectedBenchmarks;

const TooManyBenchmarksMessage = styled.div`
  display: flex;
  font-style: italic;
  font-size: 1.4rem;
  color: ${darkGray};
`;

const StyledToastError = styled(ToastError)`
  fill: ${vividRed};
  align-self: center;
  width: 4rem;
`;

const CenterContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const SelectedBenchmarksCard = styled(Card)`
  min-height: 40rem;
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
`;

export const CardHeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
`;

export const HeadingText = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 600;
`;

const GrowingHeadingText = styled(HeadingText)`
  flex-grow: 1;
  line-height: 3.9rem;
`;

const HeavyText = styled.div`
  font-weight: 600;
  margin-top: 2rem;
`;

const PillButton = styled.button`
  background: ${white};
  border: 1px solid ${borderGray};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3rem;
  padding: 1rem 1.1rem;
  display: flex;
  align-items: center;
  color: ${darkGray};
  font-weight: 500;
  font-size: 1.4rem;
  cursor: pointer;

  transform: scale(1);
  :active {
    transform: scale(0.95);
  }
`;

const StyledRemove = styled(Remove)`
  margin-right: 0.85rem;
  height: 1.4rem;
  width: 1.4rem;
  stroke: ${darkGray};
`;

const BenchmarksList = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BenchmarkItem = styled.div`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${lightGray};
`;

const ActionsBar = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: end;
`;

const StyledInfoIcon = styled(InfoIcon)`
  fill: ${darkGray};
  margin-top: 0.05rem;
`;

const Content = styled.div`
  padding-top: 2rem;
  display: flex;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }

  div {
    font-style: italic;
    font-size: 1.4rem;
    color: ${darkGray};
  }
`;
