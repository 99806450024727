/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import Loading from 'components/atoms/Loading';
import CourseForm from 'components/organisms/CourseForm';
import { useCourse } from 'hooks';
import { replaceBadChars } from 'helpers/highlightParsedSkills';

const Course: React.FC = () => {
  const { id, duplicateId } = useParams<{
    id: string | undefined;
    duplicateId: string | undefined;
  }>();
  const { data } = useCourse(duplicateId || id, true);

  if ((id || duplicateId) && !data) {
    return (
      <Loading
        css={css`
          height: 100vh;
          width: 100vw;
          position: absolute;
          top: 0;
          right: 0;
        `}
      />
    );
  } else {
    const {
      site,
      createdAt,
      updatedAt,
      subdomain,
      title,
      description,
      syllabusText,
      learningObjectiveText,
      ...rest
    } = data?.data.attributes || {};

    return (
      <CourseForm
        editCourseId={id}
        defaultValues={{
          title: replaceBadChars(duplicateId ? `${title} [COPY]` : title),
          description: replaceBadChars(description),
          syllabusText: replaceBadChars(syllabusText),
          learningObjectiveText: replaceBadChars(learningObjectiveText),
          ...rest
        }}
      />
    );
  }
};

export default Course;
