import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { lightGray } from 'utils/colors';

const Shimmy: React.FC<{ height?: number; width: number; className?: string }> = props => (
  <ShimmerCard {...props}>
    <Shimmer />
  </ShimmerCard>
);

const ShimmerKeyFrame = keyframes`
  0% {
    background-position: -1200px 0;
  }

  100% {
    backgroundPosition: 1200px 0;
  }
`;

const ShimmerCard = styled.div<{ height?: number; width: number }>`
  position: relative;
  background-color: ${lightGray};
  overflow: hidden;
  width: ${({ width }) => width}rem;
  height: ${({ height }) => (height ? `${height}rem` : '100%')};
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const Shimmer = styled.div`
  width: 100%;
  height: 100%;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${ShimmerKeyFrame};
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
`;

export default Shimmy;
