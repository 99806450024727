import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

import ChartSegmentation from '../../barChart/barChartPages';

import { contextBlue, darkBlue, darkGray, navyBlue } from 'utils/colors';
import { CategorizedSkills } from 'hooks/useCategorizedSkills';
import { extractChartDataFromCategorizedSkills, segmentChartDataIntoColumns } from './helper';
import { white } from 'utils/colors';
import { useProgramData } from '../data';
import { Header } from '../../components';

export interface BarChartChartPagesProps {
  categorizedSkills?: CategorizedSkills;
  label?: string;
}

export const SkillCategoryPage: React.FC<BarChartChartPagesProps> = () => {
  const { categorizedSkills } = useProgramData();

  const prepend = (
    <View style={prependStyles.prependContainer}>
      <Header text="Program Skills by Category" id="program_skills_by_category" />
      <Text style={prependStyles.subHeader}>
        The graph and list of skills below show the full list of skills identified for this program,
        grouped into their respective categories and subcategories. This information provides a
        higher level view of the program skills by showing the overall “skill shape” of the program.
      </Text>
    </View>
  );

  const append = Object.keys(categorizedSkills).map((category, catIndex) => (
    <View key={catIndex}>
      <View style={appendStyles.categoryContainer}>
        <Text id={category} style={appendStyles.categoryHeader}>
          {category}
        </Text>
      </View>
      {Object.keys(categorizedSkills[category]).map((subcategory, subCatIndex) => (
        <View wrap={false} style={appendStyles.subCategoryContainer} key={subCatIndex}>
          <Text style={appendStyles.subCategoryHeader}>{subcategory}</Text>
          <View style={appendStyles.skillContainer}>
            {segmentChartDataIntoColumns(categorizedSkills[category][subcategory], 3).map(
              (column, columnIndex) => (
                <View key={columnIndex} style={appendStyles.column}>
                  {column.map((skill, skillIndex: number) => (
                    <Text key={skillIndex} style={appendStyles.skill}>
                      • {skill.name}
                    </Text>
                  ))}
                </View>
              )
            )}
          </View>
        </View>
      ))}
    </View>
  ));

  return (
    <>
      <ChartSegmentation
        data={extractChartDataFromCategorizedSkills(categorizedSkills)}
        order="descending"
        prepend={prepend}
        append={append}
        firstPageMaxBars={19}
        restPagesHeader={'Program Skills by Category (Cont.)'}
      />
    </>
  );
};

const prependStyles = StyleSheet.create({
  prependContainer: {
    marginHorizontal: '0.5in'
  },
  header: {
    fontSize: 20,
    color: navyBlue,
    marginBottom: '0.25in',
    fontFamily: 'Inter',
    fontWeight: 'bold'
  },
  subHeader: {
    marginTop: '0.25in',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.5
  }
});

const appendStyles = StyleSheet.create({
  page: {
    backgroundColor: white,
    paddingBottom: '2.5in'
  },
  header: {
    marginTop: '0.4in',
    marginLeft: '0.5in',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 'bold'
  },
  categoryContainer: {
    width: '7.75in',
    backgroundColor: contextBlue,
    marginBottom: '0.23in',
    marginTop: '0.23in'
  },
  categoryHeader: {
    width: '100%',
    marginLeft: '0.1in',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'bold',
    color: darkGray
  },
  subCategoryContainer: {
    marginLeft: '0.1in',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '0.2in'
  },
  subCategoryHeader: {
    marginBottom: '0.05in',
    display: 'flex',
    flexDirection: 'row',

    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: darkBlue
  },
  skillContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%'
  },
  skill: {
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 'normal',
    marginRight: '0.2in',
    textOverflow: 'ellipsis',
    maxLines: 1,
    padding: 5
  },
  bullet: {
    fontSize: 10,
    marginTop: '0.08in'
  },
  underline: {
    width: '30vw',
    marginTop: '0.1in',
    borderBottomWidth: 1,
    borderBottomColor: darkBlue
  }
});
