import { Filter } from './types';

export function getLightcastOccupationJPAFilter(
  occupation: LightcastOccupationAssociation,
  facet?: string
) {
  const base: Filter = {
    filter: {
      [occupation.type]: { include: [occupation.id] }
    },
    rank: { limit: 5 }
  };
  if (facet) {
    base.filter[facet] = { exclude: ['Unclassified'] };
  }
  return base;
}

export function getFrequencyLevel(frequency: number): 'High' | 'Medium' | 'Low' {
  return frequency > 7 ? 'High' : frequency > 3 ? 'Medium' : 'Low';
}

export function padArray<T, U>(
  arr: readonly (T | U)[],
  paddedLength: number,
  filler: U
): (T | U)[] {
  const arrCopy = [...arr];
  while (arrCopy.length < paddedLength) {
    arrCopy.push(filler);
  }
  return arrCopy;
}
