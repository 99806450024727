/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import {
  useForm,
  FormProvider,
  useController,
  UseFormHandleSubmit,
  UseFormGetValues
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Card from 'components/atoms/Card';
import SyllabusUploadInput from 'components/atoms/SyllabusUploadInput';
import { LabeledToggle } from 'components/atoms/LabeledToggle';
import StickyButtonBar from 'components/molecules/StickyButtonBar';
import Header from 'components/molecules/Header';
import ModalButton from 'components/molecules/ModalButton';
import DeleteCourseModal from 'components/molecules/DeleteCourseModal';
import AdditionalSkillsCard from 'components/molecules/AdditionalSkillsCard';
import { CourseExports } from 'components/molecules/CourseExports';
import SkillsFromText from 'components/organisms/SkillsFromText';
import GeneralInfoForm from 'components/organisms/GeneralInfoForm';
import FormContainer from 'components/organisms/FormContainer';
import AddMoreSkills from 'components/organisms/AddMoreSkills';

import { ReactComponent as SkillsPlaceholderImage } from 'images/skillsByTextEmpty.svg';
import { ahoy, mediumGray } from 'utils/colors';
import { ReactComponent as CourseIcon } from 'images/courseIcon.svg';
import { ReactComponent as Trashcan } from 'images/trash.svg';

import { createCourse, updateCourse } from 'services/curricularSkills';
import { useProfileState } from 'store/profileStore';
import { getToastFnThatWeTreatLikeHook } from 'hooks';
import { PreprocessedSkill } from 'services/skills';

interface CourseFormProps {
  editCourseId?: string;
  defaultValues?: Partial<Omit<Course, 'site' | 'createdAt' | 'updatedAt' | 'subdomain'>>;
}

export interface CourseInputs {
  title: string;
  course?: string;
  courseId?: string;
  isPublished: boolean;
  credits?: number | null;
  url?: string;
  description?: string;
  syllabusText?: string;
  skills: readonly CurricularSkill[];
  learningObjectiveText?: string;
}

export interface CourseInput {
  key: `courseId` | `credits` | `url`;
  labelText: string;
  placeholder: string;
  dataCy: string;
}

export interface CourseTextArea {
  key: 'description' | 'syllabusText' | 'learningObjectiveText';
  title: React.ReactNode;
  placeholder: string;
  undefinedTextField: string;
  textareaHeight: string;
  dataCy: string;
}

export const INPUTS: CourseInput[] = [
  {
    key: 'courseId',
    labelText: 'Code',
    placeholder: 'e.g. COM102...',
    dataCy: 'course-form_course-id-input'
  },
  {
    key: 'credits',
    labelText: 'Credits',
    placeholder: 'Enter number...',
    dataCy: 'course-form_credits-input'
  },
  {
    key: 'url',
    labelText: 'URL',
    placeholder: 'e.g. https://institution.edu/COM102',
    dataCy: 'course_form-url_input'
  }
];

export const getTextAreas = (options?: {
  isReadOnly?: boolean;
  onFileParse?: (parsedText: string) => void;
}): CourseTextArea[] => {
  return [
    {
      key: 'description',
      title: 'Summary',
      placeholder: 'Enter short description for students...',
      undefinedTextField: 'No summary available.',
      textareaHeight: '18.4rem',
      dataCy: 'course-form_description-input'
    },
    {
      key: 'learningObjectiveText',
      title: options?.isReadOnly ? (
        'Learning Outcomes'
      ) : (
        <Flex>
          Learning Outcomes
          <SyllabusUploadInput onFileParse={options?.onFileParse} />
        </Flex>
      ),
      placeholder: 'Enter learning outcomes for your curriculum...',
      undefinedTextField: 'No learning outcomes content available.',
      textareaHeight: '33.3rem',
      dataCy: 'course-form_learning-outcome-text-input'
    },
    {
      key: 'syllabusText',
      title: options?.isReadOnly ? (
        'Curricular Content Description'
      ) : (
        <Flex>
          Curricular Content Description
          <SyllabusUploadInput onFileParse={options?.onFileParse} />
        </Flex>
      ),
      placeholder: 'Enter detailed information about your curriculum...',
      undefinedTextField: 'No course content available.',
      textareaHeight: '33.3rem',
      dataCy: 'course-form_syllabus-text-input'
    }
  ];
};

const CourseForm: React.FC<
  CourseFormProps & {
    getFormValues: UseFormGetValues<CourseInputs>;
    handleSubmit: UseFormHandleSubmit<CourseInputs>;
  }
> = ({ editCourseId, defaultValues, getFormValues, handleSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError] = useState<string | undefined>(undefined);
  const [revealedSkill, setRevealedSkill] = useState<string>();
  const [parsedSkills, setParsedSkills] = useState<readonly PreprocessedSkill[]>([]);
  const [learningOutcomeSkills, setLearningOutcomeSkills] = useState<readonly PreprocessedSkill[]>(
    []
  );
  const [fileText, setFileText] = useState<string>();
  const [, setParsingError] = useState<string | undefined>();

  const { currentSite } = useProfileState();
  const history = useHistory();
  const queryClient = useQueryClient();
  const notification = getToastFnThatWeTreatLikeHook();

  const formValues = getFormValues();

  const {
    field: { onChange: onSkillsChange, value: selectedSkills }
  } = useController<{ skills: readonly CurricularSkill[] }, 'skills'>({
    name: 'skills',
    defaultValue: []
  });

  const {
    field: { onChange: onIsPublishedChange, value: isPublished }
  } = useController<{ isPublished: boolean }, 'isPublished'>({
    name: 'isPublished',
    defaultValue: false
  });

  const onSubmit = async ({ credits, skills, ...data }: CourseInputs) => {
    setIsSubmitting(true);

    const payload = {
      ...data,
      credits: credits ? Number(credits) : null,
      skills
    };

    try {
      editCourseId
        ? await updateCourse(editCourseId, payload)
        : await createCourse({ site: currentSite, ...payload });

      await queryClient.invalidateQueries(['course', editCourseId]);
      await queryClient.invalidateQueries('courses');

      history.push('/dashboard/courses');
      notification(editCourseId ? 'Done! Course updated.' : 'Done! Course created.', 'success');
    } catch (err) {
      notification(
        editCourseId
          ? 'Sorry, we were unable to update that course. Please try again.'
          : 'Sorry, we were unable to create that course. Please try again.',
        'error'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleParseSkill = useCallback(
    (skills: PreprocessedSkill[], skillsByTextArea: PreprocessedSkill[][]) => {
      const loSkills = skillsByTextArea[2];
      if (skills && !skills.some(skill => skill.id === revealedSkill)) {
        setRevealedSkill(undefined);
      }
      setParsedSkills(skills);

      // filters out skills that are brought in through context but are not in actual learning outcomes text
      setLearningOutcomeSkills(loSkills.filter(skill => skill.highlights.length > 0));
    },
    [revealedSkill]
  );

  return (
    <>
      <Header
        css={css`
          margin: 1rem auto;
        `}
        headerText={`${
          defaultValues?.title
            ? defaultValues.title
            : editCourseId
            ? 'Edit Course'
            : 'Create New Course'
        }`}
        Icon={CourseIcon}
      >
        <LabeledToggle
          offLabel="Draft"
          onLabel="Published"
          value={isPublished}
          onValueChange={onIsPublishedChange}
        />

        <Spacer />

        {editCourseId && <CourseExports inputs={formValues} courseId={editCourseId} />}
      </Header>
      <FormContainer onSubmit={handleSubmit(onSubmit)} errorMessage={submissionError}>
        <Row>
          <GeneralInfoForm
            revealedSkill={revealedSkill}
            onParseSkills={handleParseSkill}
            onParseError={setParsingError}
            css={css`
              margin-right: 2rem;
              flex: 2;
            `}
            inputsTitle="General Info"
            inputs={INPUTS}
            // When we fix the issue with binary files for API Gateway, we can remove the readonly flag here
            textAreas={getTextAreas({
              onFileParse: parsedText => setFileText(parsedText),
              isReadOnly: true
            })}
            textFromFile={fileText}
          />
          {!parsedSkills?.length ? (
            <Card
              css={css`
                max-height: 40rem;
              `}
            >
              <CardHeading>Skills From Text</CardHeading>
              <EmptySkillsContents>
                <SkillsPlaceholderImage />
                <HeavySpan>No skills to show yet</HeavySpan>
                <StyledSpan>
                  Start typing, or paste in text to see what skills are in your curriculum
                </StyledSpan>
              </EmptySkillsContents>
            </Card>
          ) : (
            <SkillsFromText
              selectedSkills={selectedSkills}
              onChange={onSkillsChange}
              parsedSkills={parsedSkills}
              learningOutcomeSkillIds={learningOutcomeSkills.map(skill => skill.id)}
              revealedSkillId={revealedSkill}
              onReveal={skillId => {
                if (skillId === revealedSkill) {
                  setRevealedSkill(undefined);
                } else {
                  setRevealedSkill(skillId);
                }
              }}
            />
          )}
        </Row>
        <Row>
          <AddMoreSkills
            selectedSkills={selectedSkills}
            learningOutcomeSkillIds={learningOutcomeSkills.map(skill => skill.id)}
            onChange={onSkillsChange}
          />
          <AdditionalSkillsCard
            onChange={onSkillsChange}
            selectedSkills={selectedSkills}
            displaySkills={selectedSkills.filter(
              skill => !parsedSkills.some(parsedSkill => parsedSkill.id === skill.id)
            )}
          />
        </Row>
        <StickyButtonBar
          deleteOption={
            editCourseId && (
              <DeleteButton
                type="button"
                data-cy="course-form_delete-button"
                buttonText={
                  <>
                    <StyledTrashIcon />
                    Delete Course
                  </>
                }
              >
                {closeModal => (
                  <DeleteCourseModal
                    forwardOnComplete
                    closeModal={closeModal}
                    courseIds={[editCourseId]}
                  />
                )}
              </DeleteButton>
            )
          }
          onCancel={() => history.push('/dashboard/courses')}
          submitText="Save Course"
          cancelText="Discard Changes"
          isSubmitting={isSubmitting}
          {...(editCourseId ? { deleteText: 'Delete Course', curricularId: editCourseId } : null)}
        />
      </FormContainer>
    </>
  );
};

const WrappedCourseForm: React.FC<CourseFormProps> = ({ defaultValues, ...props }) => {
  const methods = useForm<CourseInputs>({
    shouldFocusError: false,
    mode: 'onTouched',
    defaultValues
  });
  return (
    <FormProvider {...methods}>
      <CourseForm
        {...{
          ...props,
          defaultValues,
          getFormValues: methods.getValues,
          handleSubmit: methods.handleSubmit
        }}
      />
    </FormProvider>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7rem;
`;

const StyledSpan = styled.span`
  display: block;
`;

const HeavySpan = styled(StyledSpan)`
  font-weight: 600;
  margin-top: 2rem;
`;

const EmptySkillsContents = styled.div`
  text-align: center;
  max-width: 33rem;
  margin: 6rem auto 0;
  font-size: 1.4rem;
  color: ${mediumGray};
  border-radius: 4px;
`;

const CardHeading = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
`;

const DeleteButton = styled(ModalButton)`
  display: flex;
  align-items: center;
  color: ${ahoy};
  background: transparent;
  margin-right: 1rem;
  border: none;
  padding: 1rem 0.3rem;
  cursor: pointer;
  font-size: 1.4rem;
`;

const StyledTrashIcon = styled(Trashcan)`
  margin-right: 1rem;

  & > path {
    stroke: ${ahoy};
  }
`;

export const Spacer = styled.div`
  width: 2rem;
`;

export default WrappedCourseForm;
