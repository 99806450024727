// Pill Colors
export const skyBlue = '#D6F0FF';
export const navyBlue = '#2373BE';
export const borderBlue = '#62b3ed';
export const alignmentBlue = '#13BAC4';

// Lightcast Color Palette
export const ahoy = '#da324d';
export const black = '#000000';
export const white = '#ffffff';
export const fog = '#f7f7f7';
export const sailorRed = '#C4374E';
export const lightSailorRed = '#FEECEF';

// Currently unused Lightcast Color Palette
export const fire = '#ff781f';
export const amethyst = '#ca61ff';
export const turquoise = '#18e8f5';

// Blues - saphire is main blue LC color; darkSaphire is what is currently used on Skillabi
export const darkDarkSaphire = '#2E5299';
export const darkSaphire = '#3e6dcc';
export const saphire = '#4d88ff';

// Greens - Emerald is main color; darkEmerald is what is currently used on Skillabi
export const emerald = '#50C878';
export const darkEmerald = '#40a060';
export const darkDarkEmerald = '#307848';

// Yellows
export const lightCitrine = '#F3EE6C';
export const citrine = '#F6E05D';

// These greens are used for green badge / pills
export const lightGreen = '#b9e9c9';
export const darkGreen = '#205030';

// Misc
export const darkBlue = '#204354';
export const lightGray = '#e2e8ef';
export const mediumLightGray = '#718196';
export const mediumGray = '#647893';
export const darkGray = '#4B596C';
export const highlightYellow = '#FFFCBF';
export const contextBlue = '#D6F0FF';
export const bgLightGray = '#f7fafc';
export const bgDarkGray = '#f0f2f5';
export const borderGray = '#edf2f6';
export const darkerBorderGray = '#CBD5E0';
export const skillBorderGray = '#A0AFBF';
export const mistyGray = '#D8DBE1';
export const moneyGreen = '#31A872';
export const vividRed = '#FA0707';
export const blue = '#3D6DCC';

// Table Colors
export const bgTableBlue = '#f9fcfd';
export const hoverTableBlue = '#f2fbff';
export const hoverTableBorderBlue = '#8fcdf4';

// Status colors
export const danger = '#C4374E';

// Skill significance colors
export const distinguishingColor = '#1E3666';
export const necessaryColor = '#74BDF94D';
export const definingColor = blue;

// Skill Details Colors
export const darkSkillDetailsBlue = `#1E3666`;

// Skill Trends Colors
export const skillTrendsTaughtDark = `#2E6AD1`;
export const skillTrendsTaughtLight = `#4299E0`;
export const skillTrendsTaughtLabel = `#8FCDF4`;
export const skillTrendsSoughtDark = `#31A872`;
export const skillTrendsSoughtLight = `#41D592`;
export const skillTrendsSoughtLabel = `#C6F7D4`;

// Skill Frequency PDF Colors
export const darkFreqBlue = blue;
export const midFreqBlue = `#A6DCFA`;
export const lightFreqBlue = `#D9D9D9`;
export const freqBoxBorderGray = `#718196`;
export const midFreqTextBlue = `#718196`;
export const midFreqPillTextBlue = `#2E5299`;
export const lowFreqPillTextGray = `#4B596C`;
export const tableLineGray = `#D8DBE1`;
export const courseCountBlue = `#2E6AD1`;
