/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import useFlyout from 'hooks/useFlyout';
import { ReactComponent as Caret } from 'images/caret.svg';
import { white, darkBlue } from 'utils/colors';

const RelativeContainer = styled.div`
  position: relative;
`;

const FlyoutButton = styled.button<{ flyoutOpen: boolean }>`
  border-radius: 3.5rem;
  height: 4rem;
  background: ${white};
  border: none;
  ${p => p.flyoutOpen && 'filter: brightness(.8);'}
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
`;

const ButtonText = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`;

const Selection = styled.div`
  font-weight: 700;
  padding: 0.5rem;
  max-width: 25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCaret = styled(Caret)`
  height: 1.2rem;
  width: 1.3rem;
  margin: 0 1rem;
  & > path {
    stroke: ${darkBlue};
  }
`;

const flyoutContainerStyles = css`
  padding: 2rem 1.5rem;
  background: white;
  border-radius: 0.4rem;
  margin: 0;
  box-shadow: 0px 5px 14px rgb(0 0 0 / 7%);
`;

type RenderFunction = (props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => React.ReactNode;

interface RoundSelectionFlyoutButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  buttonContent?: ReactNode;
  children: React.ReactNode | RenderFunction;
  buttonText?: string;
  selectionText?: string;
  className?: string;
  clickBoundary?: React.RefObject<HTMLElement>;
  'data-cy'?: string;
}

const RoundSelectionFlyoutButton: React.FC<RoundSelectionFlyoutButtonProps> = ({
  buttonContent,
  children,
  className,
  buttonText,
  selectionText,
  clickBoundary,
  'data-cy': dataCy,
  'aria-label': ariaLabel,
  ...props
}) => {
  const { FlyoutContainer, getButtonProps, isOpen, setIsOpen } = useFlyout<HTMLDivElement>({
    clickBoundary
  });
  return (
    <RelativeContainer className={className}>
      <FlyoutButton
        data-cy={dataCy}
        {...props}
        flyoutOpen={isOpen}
        aria-label={ariaLabel}
        {...getButtonProps()}
      >
        {buttonContent ? (
          buttonContent
        ) : (
          <ButtonContent>
            <ButtonText>{buttonText}</ButtonText>
            <Selection>{selectionText}</Selection>
            <StyledCaret />
          </ButtonContent>
        )}
      </FlyoutButton>
      {isOpen && (
        <FlyoutContainer css={flyoutContainerStyles}>
          {typeof children === 'function' ? children({ isOpen, setIsOpen }) : children}
        </FlyoutContainer>
      )}
    </RelativeContainer>
  );
};

export default RoundSelectionFlyoutButton;
