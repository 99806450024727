import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import MatchQualityNotice from 'components/atoms/MatchQualityNotice';
import OccupationTaxonomyPopup from 'components/atoms/OccupationTaxonomyPopup';
import BenchmarksBrowser from 'components/molecules/BenchmarksBrowser';
import { DetailedBenchmarkCardProps } from 'components/molecules/DetailedBenchmarkCard';
import { BenchmarkPopup } from 'components/molecules/BenchmarkPopup';
import Informative from 'components/molecules/Informative';

import { useRelatedOccupations } from 'hooks/similarityHooks';
import { UniversalBenchmark } from 'helpers/benchmarks';
import { useLightcastOccupationsSalary } from 'hooks/jpaHooks';
import formatLargeNumber from 'utils/formatLargeNumber';
import { getMatchConfidence } from 'utils/matchConfidence';
import { PillProps } from 'components/atoms/InfoPill';

const TopOccupationsByProgramSkills: React.FC<{
  programId?: string;
  userCanEditProgram?: boolean;
  programHasRequiredCourses?: boolean;
  selectedBenchmarks: UniversalBenchmark[];
  select(benchmark: UniversalBenchmark): void;
  deselect(benchmark: UniversalBenchmark): void;
  skillIds: string[];
}> = ({
  skillIds,
  select,
  deselect,
  selectedBenchmarks,
  programHasRequiredCourses,
  userCanEditProgram,
  programId
}) => {
  const [occupationLevel, setOccupationLevel] = useState<GranularLotLevel>('occupation');

  const { data: occupations, isLoading: occupationsIsLoading } = useRelatedOccupations(
    occupationLevel,
    skillIds ? skillIds : [],
    24
  );

  const ids = occupations ? occupations.map(occupation => occupation.id) : [];

  const { data: occupationsSalaries, isLoading: occupationsSalariesIsLoading } =
    useLightcastOccupationsSalary(occupationLevel, ids);

  const isLoading = occupationsIsLoading || occupationsSalariesIsLoading;

  const benchmarks = useMemo<DetailedBenchmarkCardProps[]>(() => {
    if (isLoading) {
      // don't compute all these benchmarks if we aren't going to display them
      return [];
    }

    if (!occupations) {
      return [];
    }

    const topOccupationScore = Math.max(...occupations.map(occupation => occupation.score));
    const defaultOccupationPill: PillProps = {
      label: occupationLevel === 'occupation' ? 'Occupation' : 'Specialized Occupation',
      scheme: 'red'
    };

    return occupations.map((occupation, index) => {
      const pills: PillProps[] = [defaultOccupationPill];
      const salary = occupationsSalaries?.[occupation.id];

      if (salary) {
        pills.push({ label: `$${formatLargeNumber(salary)}`, scheme: 'green' });
      }

      const detail: DetailedBenchmarkCardProps = {
        id: occupation.id,
        title: occupation.name,
        tabIndex: index + 1,
        alignmentStrength: getMatchConfidence(topOccupationScore, occupation.score),
        pills,
        isCustom: false,
        allowCardSelection: true,
        isSelected: !!selectedBenchmarks.find(
          benchmark => benchmark.attributes.id === occupation.id
        ),
        onSelectionChange(shouldSelect) {
          const universalOccupation: UniversalBenchmark = {
            universalType: 'occupation',
            attributes: { id: occupation.id, name: occupation.name, type: occupationLevel }
          };

          if (shouldSelect) {
            select(universalOccupation);
          } else {
            deselect(universalOccupation);
          }
        },
        renderDetailView: () => (
          <BenchmarkPopup
            benchmark={{
              universalType: 'occupation',
              attributes: { id: occupation.id, name: occupation.name, type: occupationLevel }
            }}
          />
        )
      };

      return detail;
    });
  }, [occupations, occupationLevel, selectedBenchmarks, isLoading, occupationsSalaries]);

  const occupationsTabs = {
    selectedTab: occupationLevel,
    onTabSelected: (id: GranularLotLevel) => setOccupationLevel(id),
    tabs: [
      { id: 'occupation', label: 'Occupation' },
      { id: 'specialized_occupation', label: 'Specialized Occupation' }
    ]
  };

  return (
    <>
      <TitleRow>
        <div>Select Lightcast Occupation Level:</div>
        <Informative renderContent={() => <OccupationTaxonomyPopup />} />
      </TitleRow>

      <BenchmarksBrowser
        prepend={
          <MatchQualityNotice
            programId={programId}
            includeEditPrompt={userCanEditProgram}
            programHasRequiredCourses={programHasRequiredCourses}
          />
        }
        tabsConfig={occupationsTabs}
        benchmarks={benchmarks}
        isLoading={isLoading}
      />
    </>
  );
};

export default TopOccupationsByProgramSkills;

const TitleRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
