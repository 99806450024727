/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { white, darkSaphire, bgDarkGray, fog } from 'utils/colors';
import useFlyout from 'hooks/useFlyout';
import { ReactComponent as Edit } from 'images/edit.svg';

const RelativeContainer = styled.div`
  position: relative;
  margin-left: auto;
`;

const FlyoutButton = styled.button<{ flyoutOpen: boolean }>`
  ${p => p.flyoutOpen && 'filter: brightness(.8);'};
  background: ${white};
  color: ${darkSaphire};
  border: solid ${darkSaphire} 1px;
  border-radius: 4px;
  height: 4rem;
  align-items: center;
  font-size: 1.4rem;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
`;

const ButtonText = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`;

const StyledEditPencil = styled(Edit)`
  stroke: ${darkSaphire};
  margin-right: -0.5rem;
`;

const flyoutContainerStyles = css`
  padding: 2rem 1.5rem;
  background: ${bgDarkGray};
  border-radius: 0.4rem;
  left: -28rem;
  background: ${fog};
  box-shadow: 0px 5px 14px rgb(0 0 0 / 7%);
`;

type RenderFunction = (props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => React.ReactNode;

interface RectangleFlyoutButtonProps {
  children: React.ReactNode | RenderFunction;
  buttonText: string;
  selectionText?: string;
  className?: string;
  clickBoundary?: React.RefObject<HTMLElement>;
}

const RectangleFlyoutButton: React.FC<RectangleFlyoutButtonProps> = ({
  children,
  className,
  buttonText,
  clickBoundary,
  ...props
}) => {
  const { FlyoutContainer, getButtonProps, isOpen, setIsOpen } = useFlyout<HTMLDivElement>({
    clickBoundary
  });
  return (
    <RelativeContainer className={className}>
      <FlyoutButton flyoutOpen={isOpen} {...getButtonProps()} {...props}>
        <ButtonContent>
          <StyledEditPencil />
          <ButtonText>{buttonText}</ButtonText>
        </ButtonContent>
      </FlyoutButton>
      {isOpen && (
        <FlyoutContainer css={flyoutContainerStyles}>
          {typeof children === 'function' ? children({ isOpen, setIsOpen }) : children}
        </FlyoutContainer>
      )}
    </RelativeContainer>
  );
};

export default RectangleFlyoutButton;
