import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { PreprocessedSkill, parseTextsForSkills } from 'services/skills';

export function useParseTextsForSkills(texts: string[], options: HookOptions = {}) {
  const { data, isLoading, error } = useQuery(texts, () => parseTextsForSkills(texts), options);

  return {
    data: data || { allSkills: [], skillsForTexts: [] },
    isLoading,
    error
  };
}

export function useSkillsInRecord(record: Record<string, string>, options: HookOptions = {}) {
  const {
    data: rawData,
    isLoading,
    error
  } = useParseTextsForSkills(Object.values(record), options);
  const keys = Object.keys(record) as string[];

  const data = useMemo(() => {
    if (error) {
      return keys.reduce<Record<string, PreprocessedSkill[]>>((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
    }
    const skillsRecord: Record<string, PreprocessedSkill[]> = {};

    keys.forEach((key, index) => {
      const skills = rawData.skillsForTexts[index] || [];

      skillsRecord[key] = skills;
    });

    return skillsRecord;
  }, [rawData, keys]);

  return { data, isLoading };
}
